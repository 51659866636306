/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from '../Button';
import { RadioIcon } from '../RadioIcon';

const RadioButtonRow = ({
  label,
  onClick,
  selected,
  disabled = false,
}: {
  label: string;
  onClick: () => void;
  selected: boolean;
  disabled?: boolean;
}): JSX.Element => {
  return (
    <div
      className="flex wrap justify-between items-center my-2"
      style={{ textTransform: 'capitalize' }}
    >
      <p className="text-sm textGreyD">{label}</p>
      <Button className="bgNone grow-0 p-1" onClick={onClick}>
        <RadioIcon selected={selected} disabled={disabled} />
      </Button>
    </div>
  );
};

export default RadioButtonRow;
