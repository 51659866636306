/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { LIDAR_HUB_REST, DIAGNOSTICS_POLL_INTERVAL } from '../constants';
import { safeFetch } from '../safeFetch';

export interface Firmware {
  version: string;
  input_current_ma: number;
  input_voltage_mv: number;
  internal_temperature_deg_c: number;
  phase_lock_status: string;
  timestamp_ns: number;
}
export interface Compute {
  last_upgrade_timestamp: number | null;
  total_cpu_cores: number | null;
  cpu_utilization: number | null;
  cpu_temperature_deg_c: number | null;
  total_memory: number | null;
  available_memory: number | null;
  boot_timestamp: number | null;
}
export interface PerceptionServer {
  is_paused: boolean | null;
  num_objects: number | null;
  num_tcp_clients: number | null;
  timestamp: number | null;
}
export interface EventZoneServer {
  num_occupations: number | null;
  num_tcp_clients: number | null;
  timestamp: number | null;
}
export interface LidarHub {
  mqtt_publishers_connected: number | null;
}
export type DiagnosticsResponse = {
  hardware_id: string;
  latitude: number | null;
  longitude: number | null;
  compute: Compute;
  perception_server: PerceptionServer;
  event_zone_server: EventZoneServer;
  lidar_hub: LidarHub;
  timestamp: number | null;
};

export const getDiagnostics = async (
  timeout = DIAGNOSTICS_POLL_INTERVAL,
): Promise<DiagnosticsResponse | null> =>
  safeFetch.json.get<DiagnosticsResponse>(LIDAR_HUB_REST.diagnostics, {
    timeout,
  });

export const diagnostics = {
  get: getDiagnostics,
};
