/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { SourceType } from '../api/alerts';
import { useAppDispatch, useAppState } from '../Stores';
import TableSelectableSection from './common/TableSelectableSection';

const DiagnosticsTableSourceSubsection = ({
  name,
  type,
  onClick,
  open,
}: {
  name: string;
  type: SourceType;
  onClick: () => void;
  open: boolean;
}): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const diagnostics = state.diagnostics;
  const alertIds =
    type === 'sensor' ? diagnostics.sensorAlertIds : diagnostics.systemAlertIds;
  const sourceAlertIds = alertIds.filter(
    (id) => diagnostics.allAlerts[id].sourceInfo === name,
  );
  const isSourceSelected = sourceAlertIds.every(
    (id) => diagnostics.isSelected[id],
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'var(--r1) 0',
        padding: '0 var(--r2)',
      }}
    >
      <TableSelectableSection
        name={name}
        isSelected={isSourceSelected}
        fontSize="var(--r4)"
        selectOnClick={() => {
          dispatch({
            type: 'selectAllAlertsBySource',
            sourceType: type,
            sourceId: name,
            value: !isSourceSelected,
          });
        }}
        chevronOnClick={() => onClick()}
        chevronDisabled={sourceAlertIds.length === 0}
        open={open}
      />
    </div>
  );
};

export default DiagnosticsTableSourceSubsection;
