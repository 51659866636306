/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { REST, TELEMETRY_POLL_INTERVAL } from '../constants';
import { safeFetch } from '../safeFetch';
import { Recording } from './record';

export interface Firmware {
  input_current_ma: number;
  input_voltage_mv: number;
  phase_lock_status: string;
  timestamp_ns: number;
}

export interface Lidar {
  firmware: Firmware;
  serial_number: string;
}

export interface RootObject {
  is_paused: boolean;
  lidars: Lidar[];
  num_objects: number;
  num_tcp_clients: number;
  num_websocket_clients: number;
  recording: Recording | null;
  timestamp: number;
}

export type TelemetryResponse = RootObject;

export const getTelemetry = async (
  timeout = TELEMETRY_POLL_INTERVAL,
): Promise<TelemetryResponse | null> =>
  safeFetch.json.get<TelemetryResponse>(REST.telemetry, { timeout });

export const telemetry = {
  get: getTelemetry,
};
