/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
import { DiagnosticMutations } from '../types';

const VIEWBOX = 24;
const DiagnosticIcon = ({
  icon,
  active,
  style,
  width = '18px',
  height = '18px',
  ...rest
}: {
  icon: DiagnosticMutations;
  active: boolean;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  const tx = (VIEWBOX - Number(width.replace('px', ''))) / 2;
  const ty = (VIEWBOX - Number(width.replace('px', ''))) / 2;
  return (
    <svg
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground={`new 0 0 ${VIEWBOX} ${VIEWBOX}`}
      viewBox={`0 0 ${VIEWBOX} ${VIEWBOX}`}
      fill={active ? 'var(--greyB)' : 'var(--greyE)'}
      {...rest}
    >
      <path d="M0,0h24v24H0V0z" fill="none" />
      {
        {
          SensorActivate: (
            /*   Add a transform to center the path. Both dimensions
                 are (24px - 18px) / 2 = 3px since the bb is 24px, width and heigth are 18px.
            */
            <path
              transform={`translate(${tx}, ${ty})`}
              d="M14.6565,3.343A8,8,0,1,0,5.979,16.409L8.45,10H5.5l2-6H12L9.5,8H13L6.2365,16.5a7.9955,7.9955,0,0,0,8.42-13.157Z"
            />
          ),
          SensorUnreachable: (
            <path
              transform={`translate(${tx}, ${ty})`}
              d="M8.5635,1.2895.2,16.256A.5.5,0,0,0,.636,17H17.364a.5.5,0,0,0,.436-.744L9.4365,1.2895a.5.5,0,0,0-.873,0ZM10,14.75a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,14.75v-1.5A.25.25,0,0,1,8.25,13h1.5a.25.25,0,0,1,.25.25Zm0-3a.25.25,0,0,1-.25.25H8.25A.25.25,0,0,1,8,11.75v-6a.25.25,0,0,1,.25-.25h1.5a.25.25,0,0,1,.25.25Z"
            />
          ),
        }[icon]
      }
    </svg>
  );
};

export default DiagnosticIcon;
