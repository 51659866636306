/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { Palettes } from '../constants';
import { useAppState } from '../Stores';
import { Context, PerceptionClassifications } from '../types';
import {
  getLabelFromPool,
  putLabelToPool,
  TrackedLabel3JS,
  setColor,
} from './TrackedLabel3JS';
import {
  DEFAULT_TRACKED_LABEL_VISIBILITIES,
  DEFAULT_VELOCITY_LABEL_UNITS,
  TrackedObjectLabels,
  TrackedObjectState,
  VelocityLabelUnits,
} from './TrackedObjectsStore';

const hide = (context: Context, id: string) => {
  const label = context.instances.TrackedLabel.all[id];
  if (!label) return;
  label.visible = false;

  putLabelToPool(label);
  delete context.instances.TrackedLabel.all[id];
  label.removeFromParent();
};
const show = (
  context: Context,
  id: string,
  params: TrackedObjectState,
  labelVisibilitiesByType: Record<TrackedObjectLabels, boolean>,
  velocityUnits: VelocityLabelUnits,
) => {
  let label: TrackedLabel3JS | undefined =
    context.instances.TrackedLabel.all[id];

  if (label === undefined) {
    label = getLabelFromPool(
      params.classification,
      id,
      params.position,
      params.dimensions,
      params.rotation,
      params.velocity,
      params.creationTs,
      params.updateTs,
      params.extraText,
      labelVisibilitiesByType,
      velocityUnits,
    );
    context.instances.TrackedLabel.all[id] = label;
    context.groups3JS.TrackedObject.add(label);
  } else {
    label.set(
      params.classification,
      id,
      params.position,
      params.dimensions,
      params.rotation,
      params.velocity,
      params.creationTs,
      params.updateTs,
      params.extraText,
      labelVisibilitiesByType,
      velocityUnits,
    );
  }
};

// Synchronize all label instances with the Store's selected values.
// Use TrackedLabel3JS's pool and reflect those changes to the
// Context.instances.TrackedLabels.all reference object
//
// We set the TrackedLabel3JS.visibility to true once its selected
export const useTrackedLabelsSynch = (context: Context): void => {
  const state = useAppState();
  const palette = Palettes[state.app.palette];

  // Release
  useEffect(() => {
    state.tracked.toRelease.forEach((id) => {
      hide(context, id);
    });
  }, [state.tracked.toRelease]);

  // Invisible
  useEffect(() => {
    state.depTrackedUserInvisible.forEach((id) => hide(context, id));
  }, [state.depTrackedUserInvisible]);

  // Atomic Visibility
  useEffect(() => {
    state.depTrackedUserVisible.forEach((id) => {
      const params: TrackedObjectState | undefined = state.tracked.byId[id];
      if (params === undefined) return;
      const isVis =
        state.tracked.selected[id] && state.tracked.labelVisible[id];

      const labelVisibilitiesByType = state.app.developerMode
        ? state.tracked.labelVisibilitiesByType
        : DEFAULT_TRACKED_LABEL_VISIBILITIES;
      const velocityUnits = state.app.developerMode
        ? state.tracked.velocityLabelUnits
        : DEFAULT_VELOCITY_LABEL_UNITS;

      if (isVis) {
        show(context, id, params, labelVisibilitiesByType, velocityUnits);
      } else {
        hide(context, id);
      }
    });
  }, [
    state.depTrackedUserVisible,
    state.tracked.selected,
    state.tracked.labelVisible,
    state.tracked.labelVisibilitiesByType,
    state.tracked.velocityLabelUnits,
    state.app.developerMode,
  ]);

  // PerceptionClassifications Colors
  useEffect(() => {
    for (let i = 0; i < PerceptionClassifications.length; i++) {
      const trackedClass = PerceptionClassifications[i];
      const colorIndex = state.tracked.classificationColors[trackedClass];
      setColor(trackedClass, palette[colorIndex], context);
    }
  }, [state.tracked.classificationColors, palette]);
};
