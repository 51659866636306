/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';

export const ColorInput = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (color: string) => void;
  isChecked?: boolean;
}): JSX.Element => {
  return (
    <input
      className="m-p-0_5 p-0"
      type="color"
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
      style={{
        border: 'none',
        backgroundColor: 'transparent',
        width: 'var(--r6)',
        height: 'var(--r6)',
      }}
    />
  );
};
