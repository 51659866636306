/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { SettingsInstance } from '../types';
import { SettingsInterface } from './settingsTypes';

export type SettingState = {
  perception: {
    settings: SettingsInterface;
    // If the selectedProfile is "all" then we are operating
    // on all the perception settings profiles.
    selectedProfile: string;
    valid: boolean;
  };
  lidarHub: {
    settings: Record<string, unknown>;
    selectedProfile: string;
    valid: boolean;
  };
  selectedInstance: SettingsInstance;
};

export const SettingInitialState: SettingState = {
  perception: {
    settings: {} as SettingsInterface,
    selectedProfile: 'default_settings',
    valid: true,
  },
  lidarHub: {
    settings: {} as SettingsInterface,
    selectedProfile: 'NotUsed',
    valid: true,
  },
  selectedInstance: 'perception',
};

export type SettingActions =
  | {
      type: 'setSettings';
      instance: SettingsInstance;
      value: unknown;
    }
  | {
      type: 'setSettingsValid';
      instance: SettingsInstance;
      value: boolean;
    }
  | {
      type: 'setSettingsSelectedInstance';
      value: SettingsInstance;
    }
  | {
      type: 'setPerceptionSettingsProfile';
      value: string;
    };

export const SettingReducer = (
  state: SettingState,
  action: SettingActions,
): SettingState => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'setSettings': {
      const { instance, value } = action;
      if (state[instance].settings === value) return state;

      return { ...state, [instance]: { ...state[instance], settings: value } };
    }

    case 'setSettingsValid': {
      const { instance, value } = action;
      if (state[instance].valid === value) return state;

      return { ...state, [instance]: { ...state[instance], valid: value } };
    }

    case 'setSettingsSelectedInstance': {
      if (state.selectedInstance === action.value) return state;
      return { ...state, selectedInstance: action.value };
    }

    case 'setPerceptionSettingsProfile': {
      if (state.perception.selectedProfile === action.value) return state;
      return {
        ...state,
        perception: {
          ...state.perception,
          selectedProfile: action.value,
        },
      };
    }
    default:
      return state;
  }
};
