/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from '../../app/components/Button';
import { ChevronIcon } from '../../app/components/ChevronIcon';
import { SelectableListElem } from '../../app/components/pane/SelectableListElem';

const TableSelectableSection = ({
  name,
  isSelected,
  fontSize,
  selectOnClick,
  chevronOnClick,
  chevronDisabled,
  open,
}: {
  name: string;
  isSelected: boolean;
  fontSize: string;
  selectOnClick: () => void;
  chevronOnClick: () => void;
  chevronDisabled: boolean;
  open: boolean;
}): JSX.Element => {
  return (
    <>
      <SelectableListElem
        disabled={false}
        isSelected={isSelected}
        fontSize={fontSize}
        onClick={selectOnClick}
        grow={false}
      >
        {name}
      </SelectableListElem>
      <Button
        className="bgNone p-0"
        style={{
          flexGrow: 0,
          marginTop: 'var(--r1)',
          border: 'none',
          height: 'var(--r6)',
          padding: 'var(--r1)',
        }}
        onClick={chevronOnClick}
        disabled={chevronDisabled}
      >
        <ChevronIcon
          icon={!chevronDisabled && open ? 'up' : 'down'}
          size="24px"
          style={{
            fill: chevronDisabled
              ? 'var(--greyF)'
              : open
              ? 'var(--greyE)'
              : 'var(--greyC)',
          }}
        />
      </Button>
    </>
  );
};

export default TableSelectableSection;
