/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { ColorDot } from '../app/components/ColorDot';
import { ListContainer } from '../app/components/pane/ListContainer';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { SelectableListElem } from '../app/components/pane/SelectableListElem';
import { Palettes } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';

export const TrackedAllList = ({ ids }: { ids: string[] }): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const palette = Palettes[state.app.palette];

  return (
    <PropertiesSubGroup name="All Visible" expandedInitValue={false}>
      <ListContainer name={`Total: ${ids.length}`}>
        {ids.map((id) => {
          const isSel = state.tracked.selected[id];

          const params = state.tracked.byId[id];
          const colorIndex =
            state.tracked.classificationColors[params.classification];
          const color = palette[colorIndex];

          return (
            <div className="flex items-center justify-between " key={id}>
              <SelectableListElem
                disabled={false}
                isSelected={isSel}
                onClick={() => {
                  dispatch({
                    type: 'setTrackedInstanceBoolProp',
                    property: 'selected',
                    ids: [id],
                    value: 'toggle',
                  });
                }}
              >
                <div className="flex justify-center">
                  <p className="textGreyD text-xs mr-2">
                    {params.classification}
                  </p>
                  <ColorDot color={color} />
                  <p>{id}</p>
                </div>
              </SelectableListElem>
            </div>
          );
        })}
      </ListContainer>
    </PropertiesSubGroup>
  );
};
