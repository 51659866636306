/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({
  width = '20px',
  height = '20px',
  scale = 1,
}: {
  width?: string;
  height?: string;
  scale?: number;
}): JSX.Element => {
  return (
    <div
      className="spinner-wrapper"
      style={{
        position: 'relative',
        width,
        height,
        transform: `scale(${scale})`,
        transformOrigin: 'center',
      }}
    >
      <div className="spinner" />
    </div>
  );
};

export default LoadingSpinner;
