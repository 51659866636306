/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Quaternion, Vector3 } from 'three';
import { REST } from '../constants';
import { safeFetch } from '../safeFetch';
import { Extrinsic, Pose, TransformPayload } from '../types';
import { getTransformPayload, parseJSONExtrinsic } from '../util/misc';

export const putExtrinsic = async (
  id: string,
  pose: Pose,
  onSuccess: () => void,
  onError?: () => void,
): Promise<void> => {
  const payload = getTransformPayload(id, pose.pos, pose.rot);

  await safeFetch.put(REST.extrinsic(id), payload, {
    onSuccess,
    onError: () => {
      if (onError) onError();
      else console.error(`Can NOT set cloud's ${id} extrinsic`);
    },
  });
};

export const putExtrinsics = async (
  extrinsics: Extrinsic[],
  onSuccess?: () => void,
  onError?: () => void,
): Promise<Response | null> => {
  const transforms = extrinsics.map((e) =>
    getTransformPayload(e.id, e.pos, e.rot),
  );
  return await safeFetch.put(
    REST.extrinsic(),
    { transforms },
    {
      onSuccess,
      onError: () => {
        if (onError) onError();
        else console.error(`Can NOT set cloud's extrinsics`);
      },
    },
  );
};

export const getExtrinsic = async (
  id: string,
  onSuccess: (pose: Pose) => void,
  onError?: () => void,
  frame?: string,
): Promise<void> => {
  await safeFetch.get(REST.extrinsic(id, frame ?? undefined), {
    onSuccess: async (response) => {
      const json = await response.json();
      const pose = parseJSONExtrinsic(json);
      onSuccess(pose);
    },
    onError: () => {
      if (onError) onError();
      else console.error(`Can NOT get cloud's ${id} extrinsics`);
    },
  });
};

export const getExtrinsics = async (): Promise<Extrinsic[] | null> => {
  const response = await safeFetch.get(REST.extrinsic());
  if (response === null) return null;
  const json = await response.json();
  const transforms = json.transforms ?? [];
  const extrinsics: Extrinsic[] = transforms.map((t: TransformPayload) =>
    parseJSONExtrinsic(t),
  );
  return extrinsics;
};

export const getExtrinsicsWithCallBacks = async (
  onSuccess: (extrinsics: Extrinsic[]) => void,
  onError?: () => void,
): Promise<void> => {
  await safeFetch.get(REST.extrinsic(), {
    onSuccess: async (response) => {
      const json = await response.json();
      const transforms = json.transforms ?? [];
      const extrinsics = transforms.map((t: TransformPayload) =>
        parseJSONExtrinsic(t),
      );
      onSuccess(extrinsics);
    },
    onError: () => {
      if (onError) onError();
      else console.error(`Can NOT get extrinsics`);
    },
  });
};

export const callICP = async (
  unalignedId: string,
  unalignedWorldPos: Vector3,
  unalignedWorldRot: Quaternion,
  refId: string,
  refWorldPos: Vector3,
  refWorldRot: Quaternion,
  onSuccess: (id: string, pos: Vector3, rot: Quaternion) => void,
  onError?: () => void,
): Promise<void> => {
  const payload = {
    unaligned: getTransformPayload(
      unalignedId,
      unalignedWorldPos,
      unalignedWorldRot,
    ),
    reference: getTransformPayload(refId, refWorldPos, refWorldRot),
  };
  await safeFetch.post(REST.icp, payload, {
    onSuccess: async (response) => {
      const json = await response.json();
      const { id, pos, rot } = parseJSONExtrinsic(json);
      onSuccess(id, pos, rot);
    },
    onError: () => {
      if (onError) onError();
      else console.error(`Can NOT ICP ${unalignedId} with ${refId}`);
    },
  });
};

export const align = {
  callICP,
  getExtrinsic,
  putExtrinsic,
  getExtrinsicsWithCallBacks,
} as const;
