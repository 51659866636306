/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { isEqual } from 'lodash';
import {
  HTTPServersWithAbout,
  Services,
  ServiceVersionInfo,
} from '../../types';
import { ObjectEntries } from '../../util/misc';

export type ServiceInfo = {
  isReachable: boolean | undefined;
  version: ServiceVersionInfo;
};
export type isReachable = {
  version?: ServiceVersionInfo;
  isReachable: boolean | undefined;
};

export interface ServiceState
  extends Record<Services | 'reactClient', ServiceInfo | isReachable> {
  reactClient: ServiceInfo;
  perceptionRest: ServiceInfo;
  eventRest: ServiceInfo;
  lidarHubRest: ServiceInfo;
}

export const ServiceInitialState: ServiceState = {
  reactClient: {
    isReachable: undefined,
    version: {
      commit: process.env.REACT_APP_GIT_COMMIT_SHA ?? '',
      count: process.env.REACT_APP_GIT_VERSION_COUNT ?? '',
      tag: process.env.REACT_APP_GIT_TAG ?? '',
      commit_timestamp: {
        iso: process.env.REACT_APP_GIT_COMMIT_UTC_ISO ?? '',
        unix: process.env.REACT_APP_GIT_COMMIT_UNIX ?? '',
      },
    },
  },
  perceptionRest: {
    isReachable: undefined,
    version: {
      commit: '',
      count: '',
      tag: '',
      commit_timestamp: {
        iso: '',
        unix: '',
      },
    },
  },
  eventRest: {
    isReachable: undefined,
    version: {
      commit: '',
      count: '',
      tag: '',
      commit_timestamp: {
        iso: '',
        unix: '',
      },
    },
  },
  lidarHubRest: {
    isReachable: undefined,
    version: {
      commit: '',
      count: '',
      tag: '',
      commit_timestamp: {
        iso: '',
        unix: '',
      },
    },
  },
  discoveryRest: {
    isReachable: undefined,
    version: {
      commit: '',
      count: '',
      tag: '',
      commit_timestamp: {
        iso: '',
        unix: '',
      },
    },
  },
  agentRest: {
    isReachable: undefined,
  },
  sensorProxyRest: {
    isReachable: undefined,
  },
};

export type ReachabilitySchema = Record<Services, boolean | undefined>;
export type ServiceActions =
  | {
      type: 'setServiceVersion';
      service: HTTPServersWithAbout;
      version: ServiceVersionInfo;
    }
  | {
      type: 'setServiceReachability';
      service: keyof ServiceState;
      value: boolean;
    }
  | {
      type: 'setServiceReachabilities';
      reachabilites: ReachabilitySchema;
    };

export const ServiceReducer = (
  state: ServiceState,
  action: ServiceActions,
): ServiceState => {
  switch (action.type) {
    case 'setServiceVersion': {
      if (isEqual(state[action.service].version, action.version)) return state;
      return {
        ...state,
        [action.service]: {
          ...state[action.service],
          version: action.version,
        },
      };
    }
    case 'setServiceReachability': {
      if (state[action.service].isReachable === action.value) return state;
      return {
        ...state,
        [action.service]: {
          ...state[action.service],
          isReachable: action.value,
        },
      };
    }
    case 'setServiceReachabilities': {
      let newState = { ...state };
      let needsUpdate = false;

      ObjectEntries(action.reachabilites).forEach(
        ([service, serviceReachable]) => {
          if (newState[service].isReachable !== serviceReachable) {
            newState = {
              ...newState,
              [service]: {
                ...newState[service],
                isReachable: serviceReachable,
              },
            };
            needsUpdate = true;
          }
        },
      );

      return needsUpdate ? newState : state;
    }
    default: {
      return state;
    }
  }
};
