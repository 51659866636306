/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { getServerId, Palettes } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';
import { Context, ZoneTypes } from '../types';
import { getFromZonePool, putToZonePool, setColor } from './Zones3JS';

export const useZonesSync = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const palette = Palettes[state.app.palette];

  // TODO(emmanuel): there is something wrong with the store
  // useEffect is not updating when multiple setZone actions are fired
  // and only the last one is being processed
  // Iterating over all zones on every update for the time being
  useEffect(() => {
    for (const id of state.zones.allIds) {
      let zone3JS = context.instances.Zones.all[id];
      if (zone3JS === undefined) {
        zone3JS = getFromZonePool();
        context.groups3JS.Zone.add(zone3JS);
      }
      const params = state.zones.zoneRedefined[id] || state.zones.params[id];
      const { name, type, heightMin, heightMax, vertices } = params;
      zone3JS.set(id, type, name, heightMin, heightMax, vertices);
      context.instances.Zones.all[id] = zone3JS;
    }
  }, [
    state.zones.allIds,
    state.zones.params,
    state.zones.updated,
    state.zones.newEntries,
  ]);

  useEffect(() => {
    for (const id of state.zones.toRelease) {
      const zone = context.instances.Zones.all[id];
      if (zone === undefined) continue;
      putToZonePool(zone);
      delete context.instances.Zones.all[id];
    }
  }, [state.zones.toRelease]);

  // Zone Visibility
  useEffect(() => {
    for (const id of state.zones.allIds) {
      const isVisible = state.zones.visibilities[id];
      const type = state.zones.params[id].type;
      const isTypeVisible = state.zones.typeVisibilities[type];
      const isVisibleInMode =
        state.app.inputMode === 'playback' ||
        state.app.mode === 'viewer' ||
        state.app.mode === 'zone' ||
        state.app.mode === 'recording' ||
        state.app.mode === 'preferences' ||
        state.app.mode === 'map';
      const areBoundariesVisible = state.zones.globalVisibilities.boundaries;
      const zone3JS = context.instances.Zones.all[id];
      if (zone3JS === undefined) return;
      zone3JS.setZoneVisible(
        isVisible && isTypeVisible && isVisibleInMode && areBoundariesVisible,
      );
    }
  }, [
    state.zones.visibilities,
    state.zones.typeVisibilities,
    state.app.mode,
    state.zones.allIds,
    state.zones.globalVisibilities.boundaries,
  ]);

  // Synch all labelVisible changes
  useEffect(() => {
    const isViewer = state.app.mode === 'viewer';
    const isZone = state.app.mode === 'zone';
    const isRecording = state.app.mode === 'recording';
    const isPref = state.app.mode === 'preferences';
    const isMap = state.app.mode === 'map';
    const isInputPlayback = state.app.inputMode === 'playback';
    const areLabelsVisible = state.zones.globalVisibilities.labels;

    for (const id of state.zones.allIds) {
      const zone3JS = context.instances.Zones.all[id];
      if (zone3JS === undefined) continue;

      const isVisible = state.zones.visibilities[id];
      const type = state.zones.params[id].type;
      const isTypeVisible = state.zones.typeVisibilities[type];
      const isLabelVisible = state.zones.labelVisibilities[id];

      zone3JS.setLabelVisible(
        (isViewer ||
          isZone ||
          isRecording ||
          isPref ||
          isMap ||
          isInputPlayback) &&
          isLabelVisible &&
          isVisible &&
          isTypeVisible &&
          areLabelsVisible,
      );
    }
  }, [
    state.app.mode,
    state.zones.params,
    state.zones.labelVisibilities,
    state.zones.visibilities,
    state.zones.typeVisibilities,
    state.zones.globalVisibilities.labels,
    state.zones.allIds,
  ]);

  // Selected synch
  useEffect(() => {
    const isSelectedInMode = state.app.mode === 'zone';

    const id = state.zones.selected;
    if (id === null) return;
    const zone3JS = context.instances.Zones.all[id];
    zone3JS.isSelected = isSelectedInMode;
    if (zone3JS === undefined) return;
    return () => {
      zone3JS.isSelected = false;
    };
  }, [state.zones.selected, state.app.mode]);

  // Zone Colors
  useEffect(() => {
    for (const type of ZoneTypes) {
      const clrIndex = state.zones.zoneTypeColorIndices[type];
      const color = palette[clrIndex % palette.length];

      setColor(type, color, context);
    }
  }, [state.zones.zoneTypeColorIndices, state.app.palette]);

  // Occupations
  useEffect(() => {
    for (const id of state.zones.allIds) {
      const serverId = getServerId(id.toString());
      const occupation = state.zones.occupations[serverId];
      const total = occupation?.trackedObjectIds.length ?? 0;

      const zone = context.instances.Zones.all[id];
      if (zone) zone.setOccupations(total);
    }
  }, [state.zones.allIds, state.zones.occupations]);

  // Unsaved changes
  useEffect(() => {
    for (const zone of state.zones.allIds) {
      const zone3JS = context.instances.Zones.all[zone];
      if (zone3JS === undefined) continue;
      zone3JS.isDirty = state.zones.zoneRedefined[zone] !== undefined;
    }
    const somethingIsDirty = Object.keys(state.zones.zoneRedefined).length > 0;
    if (somethingIsDirty) {
      dispatch({
        type: 'setFeedbackMessage',
        value: {
          message:
            'Unsaved zone changes - Press save to store selected zone changes',
          type: 'warning',
          priority: 1,
          durationMs: 500,
        },
      });
    }
  }, [state.zones.zoneRedefined, state.zones.allIds]);
};
