/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { GlobalVisibility } from '../../../types';
import { ZoneGlobalVisibilities } from '../../../zone/ZoneStore';
import { TrackedGlobalVisibilities } from '../../../trackedObjects/TrackedObjectsStore';
import { VisibilitiesCluster, VisType } from './VisibilityTypes';

const tooltipText: {
  GlobalVis: Record<GlobalVisibility, string>;
  Zones: Record<ZoneGlobalVisibilities, string>;
  Tracked: Record<TrackedGlobalVisibilities, string>;
} = {
  GlobalVis: {
    Axes: 'the world axis',
    Grid: 'the ground grid',
    Clouds: 'all point clouds',
    Image: ' the 2D image',
    EventLocation: 'the event location',
    UnderlayMap: ' the reference image',
    RangeRings: 'the range rings',
    RangeRingLabels: 'the range rings range labels',
    // we are not using the following
    TrackedObjects: 'all tracked object',
    Zones: 'all zone',
  },
  Zones: {
    labels: 'the zone`s labels',
    boundaries: 'the zone`s profiles/bounding structures',
  },
  Tracked: {
    cloud: 'object points',
    labels: 'object labels',
    rings: 'object rings',
    previousPositions: 'previous positions',
    previousPositionsPath: 'previous positions path',
    bbox: 'object bounding boxes',
    corners: 'corners on "Vehicle" classes',
  },
};
export const getTip = (cluster: VisibilitiesCluster, type: VisType): string => {
  const keys = tooltipText[cluster];
  return tooltipText[cluster][type as keyof typeof keys];
};
