/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { endpoints } from '../../api/endpoints';
import useInterval from '../../app/hooks/useInterval';
import { NODE_POLL_INTERVAL } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import { ObjectEntries } from '../../util/misc';
import { NodeResponse, NodeStatus } from '../../api/node';

const { node } = endpoints;

const usePollNodes = (): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const pollNodes = async () => {
    const allNodes: NodeResponse =
      (await node.all()) ?? ({ nodes: [] as NodeStatus[] } as NodeResponse);

    const reachableNodes = {} as Record<string, boolean>;
    const serverNodes = allNodes.nodes ?? [];

    const adaptedServerNodes = serverNodes.map((node) => {
      const reachableState = true; // All nodes are always reachable, atm
      reachableNodes[node.id] = reachableState;

      return {
        id: node.id,
        hostname: node.hostname ?? '',
        isAdded:
          state.nodes.addedStates[node.id] || node.rest_client === 'connected',
        isReachable: reachableState,
        licenseInfo: {
          expirationTime: node.license_info.expiration_time,
          inUseLicenses: node.license_info.in_use_licenses,
          totalLicenses: node.license_info.total_licenses,
        },
      };
    });

    unstable_batchedUpdates(() => {
      dispatch({ type: 'setNodesDiscover', nodes: adaptedServerNodes });

      // TODO(Matt): Move into discover reducer or post-reducer. There's a desync issue
      // right now since we want to refer to nodes.reachableStates after the reducer.
      // * Do if performance is an issue *
      ObjectEntries(state.nodes.reachableStates).map(([id, wasReachable]) => {
        if (!wasReachable) return;

        const isReachable = reachableNodes[id] !== undefined;
        if (!isReachable) {
          // We had this discovered but can no longer see it.
          // If we can't see it, even if it was added previously we'll also assume it isn't
          // added anymore since we should be able to reach all added nodes.
          dispatch({ type: 'setNodeReachable', id, value: false });
        }
      });
    });
  };

  const [, setActive] = useInterval(pollNodes, NODE_POLL_INTERVAL, false);

  useEffect(() => {
    if (state.app.inputMode === 'playback') return;

    pollNodes();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode]);
};

export default usePollNodes;
