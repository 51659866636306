/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef } from 'react';
import {
  Euler,
  LoadingManager,
  MeshBasicMaterial,
  Quaternion,
  TextureLoader,
  Vector3,
} from 'three';
import { TWO_PI } from '../constants';
import { useAppState, useAppDispatch } from '../Stores';
import { Context, Mode } from '../types';

const modeVisibility: Mode[] = [
  'map',
  'setup',
  'zone',
  'viewer',
  'recording',
  'preferences',
];

// instantiate a loader
const loaderImg = new TextureLoader(new LoadingManager());

// These hooks allow the user to select zone objects
export const useUnderlayMap = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const aspectRatio = useRef(1);
  const isVisibleInMode = modeVisibility.includes(state.app.mode);

  // load image resource
  useEffect(() => {
    loaderImg.load(
      state.app.underlayMap.image,

      // onLoad callback
      (texture) => {
        const { width, height } = texture.image;
        aspectRatio.current = width / height;
        const scale = state.app.underlayMap.scale;
        context.underlayPlane.scale.set(scale * aspectRatio.current, scale, 1);
        const material = context.underlayPlane.material as MeshBasicMaterial;
        // context.underlayPlane.position.set(78.02, 9.83, 0);
        // context.underlayPlane.rotation.set(0, 0, 2.746);
        material.map = texture;
        material.needsUpdate = true;
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      (err) => {
        console.error('Error loading the underlay map:', err);
      },
    );
  }, [state.app.underlayMap.image]);

  // Add / remove
  useEffect(() => {
    if (!isVisibleInMode) return;
    if (state.app.underlayMap.image === '') return;

    context.viz.scene.add(context.underlayPlane);
    return () => {
      context.underlayPlane.removeFromParent();
    };
  }, [state.app.mode, state.app.underlayMap.image]);

  // Visibility toggle
  useEffect(() => {
    context.underlayPlane.visible =
      state.app.mode === 'map' || state.app.visibilities.UnderlayMap;
  }, [state.app.visibilities.UnderlayMap, state.app.mode]);

  // Transform Tool
  useEffect(() => {
    const isImgRefTransform = state.app.tool === 'TransformMap';
    const isActive = isVisibleInMode && isImgRefTransform;
    if (!isActive) return;

    // Inform functionality
    dispatch({
      type: 'setFeedbackMessage',
      value: {
        type: 'info',
        message:
          'Click and drag the widgets to transform, arrows moves along axis, L shaped corners along plane, disk rotates',
      },
    });

    const plane = context.underlayPlane;
    plane.visible = true;

    context.transformControls.attach(context.underlayPlane);
    context.viz.scene.add(context.transformControls);
    context.transformControls.active = true;
    context.transformControls.controls.translate.z.visible =
      context.transformControls.controls.translate.yz.visible =
      context.transformControls.controls.translate.xz.visible =
        false;

    return () => {
      context.transformControls.detach();
      context.transformControls.removeFromParent();
      context.transformControls.active = false;

      const s = new Vector3();
      const r = new Quaternion();
      const t = new Vector3();
      plane.matrixWorld.decompose(t, r, s);
      // plane.updateWorldMatrix(false, true);

      // post the transform to the server
      // const m = new Matrix4().compose(
      //   plane.position,
      //   plane.quaternion,
      //   plane.scale,
      // );

      dispatch({ type: 'seMapPosition', value: t });
      dispatch({
        type: 'seMapRotation',
        value: (new Euler().setFromQuaternion(r).z / TWO_PI) * 360,
      });
    };
  }, [state.app.tool, state.app.mode]);

  // transform
  useEffect(() => {
    context.underlayPlane.position.set(
      state.app.underlayMap.position.x,
      state.app.underlayMap.position.y,
      0,
    );

    context.underlayPlane.rotation.set(
      0,
      0,
      (state.app.underlayMap.rotation / 360) * 2 * Math.PI,
    );

    context.underlayPlane.scale.set(
      state.app.underlayMap.scale * aspectRatio.current,
      state.app.underlayMap.scale,
      1,
    );
  }, [
    state.app.underlayMap.scale,
    state.app.underlayMap.rotation,
    state.app.underlayMap.position,
  ]);

  // opacity
  useEffect(() => {
    const material = context.underlayPlane.material as MeshBasicMaterial;
    material.opacity = state.app.underlayMap.opacity;
  }, [state.app.underlayMap.opacity]);
};
