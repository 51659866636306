/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
import { COLOR_SELECTED } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';
import { AMBER } from '../util/palettes';
import { RecordingParams } from './PlaybackStore';
import './Timeline.css';

const Ticks = ({
  recording,
}: { recording: RecordingParams } & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full absolute"
      style={{
        left: 0,
        top: 0,
        width: ' 100%',
      }}
    >
      <symbol id="dot" viewBox="0 0 2 2">
        <circle cx="1" cy="1" r=".2" />
      </symbol>
      <symbol id="rect" viewBox="0 0 7 7">
        <rect x="2" y="2" width="1" height="1" />
      </symbol>
      <symbol id="rect2" viewBox="0 0 4 10">
        <rect x="2" y="0" width="4" height="10" />
      </symbol>

      {recording.timestamps.map((ts, i) => {
        const percent = (i / recording.timestamps.length) * 100;

        return (
          <use
            href="#rect2"
            x={`${percent - 50}%`}
            y="0%"
            fill={i % 2 === 0 ? 'var(--greyF)' : 'var(--greyG)'}
            key={`frm${i}`}
          />
        );
      })}
    </svg>
  );
};

const Stamps = React.memo(
  ({
    recording,
  }: { recording: RecordingParams } & SVGProps<SVGSVGElement>): JSX.Element => {
    let offenderPercent: JSX.Element | null = null;
    if (recording.offender !== null) {
      const recStartMillis = new Date(recording.timestamps[0]).getTime();
      const recEndMillis = new Date(
        recording.timestamps[recording.timestamps.length - 1],
      ).getTime();
      const eventStartMillis = new Date(
        recording.dataRecorderEvent.startTimestamp,
      ).getTime();
      const eventEndMillis = new Date(
        recording.dataRecorderEvent.endTimestamp,
      ).getTime();
      const recTotalMillis = recEndMillis - recStartMillis;
      const eventTotalMillis = eventEndMillis - eventStartMillis;
      const eventStart = eventStartMillis - recStartMillis;
      const x = (eventStart / recTotalMillis) * 100;
      const width = (eventTotalMillis / recTotalMillis) * 100;
      offenderPercent = (
        <g>
          <rect
            id="offender"
            x={`${x}%`}
            width={`${width}%`}
            height="2px"
            fill={COLOR_SELECTED}
          />
          {/* <use
            href="#target"
            x={`${x - 50}%`}
            height="20px"
            fill={COLOR_SELECTED}
          /> */}
        </g>
      );
    }
    return (
      <svg
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full absolute"
        style={{
          left: 0,
          top: 0,
          width: ' 100%',
        }}
      >
        <symbol id="dot" viewBox="0 0 2 2">
          <circle cx="1" cy="1" r=".2" />
        </symbol>
        <symbol id="rect" viewBox="0 0 7 7">
          <rect x="2" y="2" width="1" height="1" />
        </symbol>
        <symbol id="rect2" viewBox="0 0 4 10">
          <rect x="2" y="0" width="4" height="10" />
        </symbol>
        <symbol id="target" viewBox="0 0 18 18">
          <path d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM9,14.1A5.1,5.1,0,1,1,14.1,9,5.1,5.1,0,0,1,9,14.1Z" />
          <circle cx="9" cy="9" r="2" />
        </symbol>
        {offenderPercent}

        {recording.timestamps.map((ts, i) => {
          const occupations = recording.frames[ts].occupations ?? [];
          const images = Object.keys(recording.frames[ts].images ?? {});

          const percent = (i / recording.timestamps.length) * 100;
          const occCircleRadius =
            Math.pow(
              occupations.reduce((p, o) => o.trackedObjectIds.length + p, 0) /
                recording.stats.maxOccupancies,
              3,
            ) * 1;

          return (
            <g key={`frm${i}`}>
              {occCircleRadius > 0.1 && (
                <circle
                  cx={`${percent}%`}
                  cy="70%"
                  transform="translate(4,0)"
                  r={occCircleRadius}
                  fill={AMBER}
                />
              )}

              {images.length &&
                images.map((id, ii) => {
                  return (
                    <circle
                      className={`cam${id}`}
                      transform="translate(4,0)"
                      cx={`${percent}%`}
                      cy={`${ii * 2 + 35}%`}
                      r={2}
                      fill={recording.sensorColors[id]}
                      key={`imgs${ii}`}
                    />
                  );
                })}
            </g>
          );
        })}
      </svg>
    );
  },
);
Stamps.displayName = 'Stamps';

export const Timeline = ({
  recording,
}: {
  recording: RecordingParams;
}): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  return (
    <div id="Timeline" className="relative mx-4 my-2 ">
      <Ticks recording={recording} />
      <Stamps recording={recording} />
      <input
        type={'range'}
        min={0}
        max={recording.timestamps.length - 1}
        value={state.playback.timeIndex}
        onChange={(e) => {
          const index = parseInt(e.target.value);
          dispatch({
            type: 'setPlaybackTimeIndex',
            offset: 'absolute',
            value: index,
          });
        }}
        onPointerUp={(e) => {
          e.currentTarget.blur();
        }}
        className="w-full relative"
      />
    </div>
  );
};
