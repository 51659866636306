/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/cognitive-complexity */

import { useEffect } from 'react';
import { useAppDispatch, useAppState } from '../Stores';
import { Source3JS } from './Source3JS';
import { Context, Pose, Transformation } from '../types';
import { Preset } from '../app/components/pane/cloudsDisplay/CloudColorPresets';
import { Palettes, PRESET_RANGES } from '../constants';
import { endpoints } from '../api/endpoints';
import { Quaternion, Vector3 } from 'three';
import { Extrinsic } from '../types';
import { unstable_batchedUpdates } from 'react-dom';
import { SensorState } from './SensorStore';
import { concatenateTypedArrays } from '../util/misc';
import { ProcessedCloudSchema } from '../processedCloud/ProcessedCloudStore';

const {
  align: { getExtrinsicsWithCallBacks },
} = endpoints;

type NodeParams = {
  points: Float32Array;
  transformIndex: Uint8Array; // indexes of associated sensors
  signal: Uint8Array;
  reflectivity: Uint8Array;
  nearir: Uint8Array;
};

const generateNodeClouds = (
  clouds: ProcessedCloudSchema[],
): { params: NodeParams; extrinsics: Transformation[] } => {
  const nodeParams: NodeParams = {
    points: new Float32Array(),
    signal: new Uint8Array(),
    reflectivity: new Uint8Array(),
    nearir: new Uint8Array(),
    transformIndex: new Uint8Array(),
  };
  // These booleans are used to stop clouds from merging their
  // channels if one cloud has a channel and another doesn't.
  const signal = clouds.reduce((acc, next) => {
    if (!acc) return acc;
    return next.params.signal.length > 0;
  }, true);
  const reflectivity = clouds.reduce((acc, next) => {
    if (!acc) return acc;
    return next.params.reflectivity.length > 0;
  }, true);
  const nearir = clouds.reduce((acc, next) => {
    if (!acc) return acc;
    return next.params.nearir.length > 0;
  }, true);

  // Holds the extrinsics for each sensor, to be used in the GPU
  const extrinsicsList: Transformation[] = [];

  // Iterate over each cloud in the node, and concatenate all their
  // different channels together, to create one big cloud that
  // represents the node.
  for (let i = 0; i < clouds.length; i++) {
    const params = clouds[i].params;
    extrinsicsList.push({
      translation: new Float32Array(params.translation),
      rotation: new Float32Array([
        // wxyz -> xyzw, since glsl (and 3JS) use xyzw
        params.quaternionRotation[1],
        params.quaternionRotation[2],
        params.quaternionRotation[3],
        params.quaternionRotation[0],
      ]),
    });

    nodeParams.points = concatenateTypedArrays(
      nodeParams.points,
      params.points,
    ) as typeof nodeParams.points;

    const newTransformations = new Uint8Array(params.points.length / 3);
    newTransformations.fill(i);
    nodeParams.transformIndex = concatenateTypedArrays(
      nodeParams.transformIndex,
      newTransformations,
    ) as Uint8Array;

    if (signal) {
      nodeParams.signal = concatenateTypedArrays(
        nodeParams.signal,
        params.signal,
      ) as typeof nodeParams.signal;
    }
    if (reflectivity) {
      nodeParams.reflectivity = concatenateTypedArrays(
        nodeParams.reflectivity,
        params.reflectivity,
      ) as typeof nodeParams.reflectivity;
    }
    if (nearir) {
      nodeParams.nearir = concatenateTypedArrays(
        nodeParams.nearir,
        params.nearir,
      ) as typeof nodeParams.nearir;
    }
  }

  return {
    params: nodeParams,
    extrinsics: extrinsicsList,
  };
};

export const useSourceData = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const palette = Palettes[state.app.palette];

  // If the backend changes and it no longer has to init when we
  // switch modes, add a useEffect that unpauses the backend when
  // we switch to live mode.

  useEffect(() => {
    const allIds = [...state.sensors.allIds, ...state.nodes.allIds];
    for (let i = 0; i < allIds.length; i++) {
      const id = allIds[i];
      const isSensor = state.sensors.allIds.includes(id);
      const sources = isSensor ? state.sensors : state.nodes;
      const isAdded = sources.addedStates[id];
      let source = context.instances.Source.all[id];

      if (!isAdded) continue;
      // If our node has no clouds, also do not display it.
      if (!isSensor && state.nodes.clouds[id] === undefined) continue;

      if (!source) {
        source = new Source3JS(
          isSensor
            ? context.assets.sensorGeometry
            : context.assets.nodeGeometry,
          isSensor,
        );

        const { colorMode, colorMin, colorMax } = sources.display;
        const { pointSize } = sources;
        const { min, max } = PRESET_RANGES[colorMode];
        const heightMin = state.clouds.Background.display.heightMin;
        const heightMax = state.clouds.Background.display.heightMax;

        source.set(id);
        source.setColor(palette[colorMin[id]]);
        // Slightly offset icons so they wont overlap
        source.icon.position.setZ((i / allIds.length) * 0.5);

        const preset: Preset = {
          name: '',
          colorMin: palette[colorMin[id]],
          colorMax: palette[colorMax[id]],
          min,
          max,
        };
        source.cloud.setColorMode(colorMode);
        source.cloud.setPreset(preset);
        source.cloud.setHeightRange({ min: heightMin, max: heightMax });
        source.cloud.setPointSize(pointSize[id]);

        context.instances.Source.all[id] = source;
        context.groups3JS.Source.add(source);
        source.visible = false;
      } else {
        // Setting the color every time, because sensors turn white when
        // a new Node connects, for some reason.
        source.setColor(palette[sources.display.colorMin[id]]);
      }

      // Update cloud
      if (isSensor) {
        const { points, signal, reflectivity, nearir } = (
          sources as SensorState
        ).params[id];
        source.cloud.setAttributes(points, signal, reflectivity, nearir);
      } else {
        const {
          params: { points, signal, reflectivity, nearir, transformIndex },
          extrinsics: extrinsics,
        } = generateNodeClouds(state.nodes.clouds[id]);

        const flatExtrinsics = new Float32Array(extrinsics.length * 7);
        extrinsics.forEach((extrinsic, index) => {
          flatExtrinsics.set(extrinsic.translation, index * 7);
          flatExtrinsics.set(extrinsic.rotation, index * 7 + 3);
        });
        source.cloud.setExtrinsics(flatExtrinsics);

        source.cloud.setAttributes(
          points,
          signal,
          reflectivity,
          nearir,
          transformIndex,
        );
      }
    }

    // Fetch the source's extrinsic if the source pose is not dirty. We do this in case there
    // were change by a different users of the REST API, but we don't want to do it if
    // we are in the middle of changing the source parameters ourselves
    if (state.app.tool !== 'World') {
      getExtrinsicsWithCallBacks((extrinsics: Extrinsic[]) => {
        unstable_batchedUpdates(() => {
          const sourceIdsUpdated = new Set();

          // Sometimes when you transform a source it may snap back to its original position.
          // Don't know how to fully fix, so we are leaving it. It only happens once at a time.
          for (const extrinsic of extrinsics) {
            const id = extrinsic.id;
            const isSensor = state.sensors.allIds.includes(id);
            const isNode = state.nodes.allIds.includes(id);
            if (!isSensor && !isNode) {
              // Source must not be active
              continue;
            }
            const sources = isSensor ? state.sensors : state.nodes;

            // If the source is in a dirty state or extrinsics are sensor<>world, we don't update
            if (sources.transformed[id] || extrinsic.dest !== 'world') {
              continue;
            }
            const source = context.instances.Source.all[id];
            if (!source) {
              continue;
            }
            source.pose.quaternion.copy(extrinsic.rot);
            source.pose.position.copy(extrinsic.pos);
            source.pose.updateMatrixWorld();

            sourceIdsUpdated.add(id);
            dispatch({
              type: isSensor ? 'setSensorExtrinsic' : 'setNodeExtrinsic',
              id,
              value: extrinsic,
            });
          }

          // Check if a source didn't have an extrinsic; if so, set to IMU pose
          const allIds = [...state.sensors.allIds, ...state.nodes.allIds];
          for (let i = 0; i < allIds.length; i++) {
            const id = allIds[i];
            const isSensor = state.sensors.allIds.includes(id);
            const isNode = state.nodes.allIds.includes(id);
            if (!isSensor && !isNode) {
              // Source must not be active
              continue;
            }
            const sources = isSensor ? state.sensors : state.nodes;
            if (!sourceIdsUpdated.has(id)) {
              if (sources.transformed[id]) {
                continue;
              }
              const source = context.instances.Source.all[id];
              if (!source) {
                continue;
              }
              if (isSensor) {
                // Level up pose (inverse of IMU pose)
                const { quaternionRotation } = state.sensors.params[id];
                const [w, x, y, z] = quaternionRotation;
                const pose: Pose = {
                  pos: new Vector3(),
                  rot: new Quaternion(x, y, z, w),
                };
                source.pose.quaternion.copy(pose.rot);
                source.pose.position.copy(pose.pos);
                source.pose.updateMatrixWorld();
                dispatch({ type: 'setSensorExtrinsic', id, value: pose });
              } else {
                // Origin pose (zero zero zero)
                const pose: Pose = {
                  pos: new Vector3(),
                  rot: new Quaternion(),
                };
                source.pose.quaternion.copy(pose.rot);
                source.pose.position.copy(pose.pos);
                source.pose.updateMatrixWorld();
                dispatch({ type: 'setNodeExtrinsic', id, value: pose });
              }
            }
          }
        });
      });
    }

    // We don't update on pointSize or display because they are handled by
    // useSensorAppearance for sources that already exist. Here they are
    // only used for a new source, which can only occur when
    // state.sensors.params or state.nodes.clouds changes.
  }, [
    state.clouds.Background.display.heightMin,
    state.clouds.Background.display.heightMax,
    state.sensors.params,
    state.nodes.clouds,
    state.app.tool,
  ]);
};
