/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { isAlphaNumeric } from '../../../util/misc';
import { UpDownButtons } from '../pane/UpDownButtons';
import Value from './Value';

const formatValue = (v: string, precision: number, valid = true) => {
  if (!valid || v === '' || v.slice(-1) === '.' || isNaN(Number(v))) {
    return v;
  }
  return Number(v).toFixed(precision);
};

const NumberInput = ({
  isDirty = false,
  value,
  onChangeNumber,
  disabled,
  name,
  max,
  min,
  // eslint-disable-next-line  @typescript-eslint/no-empty-function
  setIsNotSyncedMsg = () => {},
  // eslint-disable-next-line  @typescript-eslint/no-empty-function
  setIsSynced = () => {},
  isSynced = true,
  forceUpdate = false,
  step = 0.1,
  precision = 2,
  ...rest
}: InputHTMLAttributes<HTMLInputElement> & {
  onChangeNumber: (v: number) => void;
  value: number;
  max?: number;
  min?: number;
  isDirty?: boolean;
  setIsNotSyncedMsg?: (v: string) => void;
  setIsSynced?: (v: boolean) => void;
  isSynced?: boolean;
  forceUpdate?: unknown;
  step?: number;
  precision?: number;
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [intmdValue, setIntmdValue] = useState(value.toString());
  const isBlur = useRef(true);

  useEffect(() => {
    if (!isBlur.current) return;
    setIntmdValue(formatValue(value.toString(), precision, isSynced));
  }, [forceUpdate, value]);

  // Revalidate on constraint change if we aren't synced
  useEffect(() => {
    if (isSynced) return;
    const v = Number(intmdValue);

    if (isNaN(v)) {
      setIsSynced(false);
      setIsNotSyncedMsg('Please input a valid number.');
      return;
    }
    if (max !== undefined && v >= max) {
      setIsSynced(false);
      setIsNotSyncedMsg(
        `Value must be smaller than ${formatValue(max.toString(), precision)}`,
      );
      return;
    }
    if (min !== undefined && v <= min) {
      setIsSynced(false);
      setIsNotSyncedMsg(
        `Value must be larger than ${formatValue(min.toString(), precision)}`,
      );
      return;
    }
    setIsSynced(true);
    onChangeNumber && onChangeNumber(v);
  }, [min, max, isSynced, intmdValue]);

  return (
    <div className="flex flex-col">
      <Value isDirty={isDirty}>
        <input
          style={{
            maxWidth: '4rem',
          }}
          ref={inputRef}
          id={name}
          className="input text-sm"
          type="text"
          value={intmdValue}
          placeholder={(0).toFixed(precision).toString()}
          max={max}
          min={min}
          disabled={disabled}
          // Prevalidate
          onKeyDown={(e) => {
            const caretPos = inputRef.current?.selectionEnd;
            if (caretPos === null || caretPos === undefined) return;

            const isArrowKey = e.key.includes('Arrow');
            const isBackspace = e.key === 'Backspace' || e.key === 'Delete';
            const isInvalidKey =
              !isAlphaNumeric(e.key) &&
              !['.', '-'].includes(e.key) &&
              !isArrowKey &&
              !isBackspace;

            const targetValue = (e.target as HTMLInputElement).value;
            const strV = isArrowKey
              ? targetValue
              : isBackspace
              ? targetValue.slice(0, Math.max(0, caretPos - 1)) +
                targetValue.slice(caretPos)
              : targetValue.slice(0, caretPos) +
                e.key +
                targetValue.slice(caretPos);
            const v = Number(strV);

            // Allow '<Number>.' and '-' as valid intermediate states
            const isNotANumber =
              isNaN(v) && !(strV.slice(-1) === '.' || strV === '-');
            const isOutOfRange =
              (max !== undefined && v > max) || (min !== undefined && v < min);
            if (isInvalidKey || isNotANumber || isOutOfRange) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const strV = e.target.value;
            const v = Number(e.target.value);

            if (
              strV === '' ||
              strV === '-' ||
              strV.slice(-1) === '.' ||
              isNaN(v)
            ) {
              setIsSynced(false);
              setIsNotSyncedMsg('Please input a valid number.');
              return setIntmdValue(strV);
            }
            if (max !== undefined && v > max) {
              setIsSynced(false);
              setIsNotSyncedMsg(
                `Value must be smaller than or equal to ${formatValue(
                  max.toString(),
                  precision,
                )}`,
              );
              return setIntmdValue(strV);
            }
            if (min !== undefined && v < min) {
              setIsSynced(false);
              setIsNotSyncedMsg(
                `Value must be larger than or equal to ${formatValue(
                  min.toString(),
                  precision,
                )}`,
              );
              return setIntmdValue(strV);
            }

            setIsSynced(true);
            // Save the string version to avoid format errors
            setIntmdValue(strV);
            onChangeNumber && onChangeNumber(v);
          }}
          onFocus={() => (isBlur.current = false)}
          onBlur={() => {
            isBlur.current = true;
            setIntmdValue(formatValue(intmdValue, precision, isSynced));
          }}
          {...rest}
        />
        <UpDownButtons
          isUpEnabled={!disabled}
          isDownEnabled={!disabled}
          onClick={(key) => {
            let v = value + (key === 'up' ? step : -step);
            v = max !== undefined ? Math.min(v, max) : v;
            v = min !== undefined ? Math.max(v, min) : v;
            setIsSynced(true);
            setIntmdValue(formatValue(v.toString(), precision));
            onChangeNumber && onChangeNumber(v);
          }}
        />
      </Value>
    </div>
  );
};

export default NumberInput;
