/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from '../app/components/Button';
import { ColorPresetPicker } from '../app/components/pane/ColorPresetPicker';
import { EyeToggle } from '../app/components/pane/eye/Visible';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { SelectableListElem } from '../app/components/pane/SelectableListElem';
import { SourceTargetIcon } from './SourceTargetIcon';
import { useAppDispatch, useAppState } from '../Stores';
import { UpDownButtons } from '../app/components/pane/UpDownButtons';
import { MAX_POINTS_SIZE, MIN_POINTS_SIZE, Palettes } from '../constants';
import { DisplayOptions } from '../app/components/pane/cloudsDisplay/DisplayOptions';
import { ListContainer } from '../app/components/pane/ListContainer';
import ColorModeMinMaxInput from '../app/components/pane/cloudsDisplay/ColorModeMinMaxInput';
import { CloudDisplayModes } from '../types';
import { trimTo } from '../util/misc';

const cleanFormatting = { padding: 'var(--r1_5)', margin: '0' };

/* eslint-disable sonarjs/cognitive-complexity */
export const SourceList = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const alignPhaseNone = state.setup.alignPhase === 'none';
  const palette = Palettes[state.app.palette];

  // Count of added sensors that we're receiving data from.
  const totalActiveSensors = state.sensors.allIds.reduce((acc, id) => {
    const isActive =
      state.sensors.visibilities[id] !== undefined &&
      state.sensors.addedStates[id];
    return isActive ? ++acc : acc;
  }, 0);

  const isSignalActive = state.sensors.allIds.some(
    (id) => state.sensors.params[id].signal.length,
  );
  const modes = isSignalActive
    ? CloudDisplayModes
    : CloudDisplayModes.filter((mode) => mode !== 'Signal');

  return (
    <PropertiesSubGroup name="Sources">
      <div className="text-sm textGreyD">{`Total: ${totalActiveSensors}`}</div>
      <DisplayOptions
        modes={modes}
        colorMode={state.sensors.display.colorMode}
        onChange={(mode) => {
          // Currently sensors and nodes have the same display mode.
          // Could be worth extracting it into the setup store, but you'll
          // have to update how each source sets its colorMin/Max etc.
          dispatch({ type: 'setSensorsDisplayMode', value: mode });
          dispatch({ type: 'setNodesDisplayMode', value: mode });
        }}
      />
      <ColorModeMinMaxInput type="sensors" />
      <ListContainer>
        {[...state.sensors.allIds, ...state.nodes.allIds].map((id) => {
          const isSensor = state.sensors.allIds.includes(id);
          const sources = isSensor ? state.sensors : state.nodes;
          const setup = state.setup;

          const isAdded = sources.addedStates[id];
          const isVis = sources.visibilities[id];
          const hasExtrinsic = sources.extrinsics[id] !== null;
          const isReachable = isSensor
            ? state.sensors.reachableStates[id]
            : true;

          if (
            !isAdded ||
            !isReachable ||
            isVis === undefined ||
            (isSensor && !hasExtrinsic) ||
            (!isSensor && state.nodes.clouds[id] === undefined)
          ) {
            return;
          }

          const isRef = setup.reference === id;
          const isSel = setup.selected === id;
          const isDirty = sources.transformed[id];

          return (
            <div className="flex flex-col my-1" key={id}>
              <div
                className="flex items-center mt-1"
                // reverse order to keep color picker on top
                style={{ flexDirection: 'row-reverse' }}
              >
                <SelectableListElem
                  tooltip={{
                    text: `${isSel ? 'Confirm' : 'Adjust'} transform`,
                    offset: 'top',
                  }}
                  disabled={!isVis || !alignPhaseNone}
                  isSelected={isSel}
                  onClick={() => {
                    dispatch({
                      type: 'setSelectedSource',
                      id: !isSel ? id : null,
                    });
                    if (!isSel && isRef) {
                      dispatch({
                        type: 'setReferenceSource',
                        id: null,
                      });
                    }
                  }}
                  style={{
                    ...cleanFormatting,
                    ...(!isVis || !alignPhaseNone
                      ? { padding: 'calc(var(--r1_5) - 1px)' }
                      : {}),
                  }}
                >
                  {`${isDirty ? '! ' : ''}${trimTo(
                    id,
                    9,
                    isSensor ? 'start' : 'end',
                  )}`}
                </SelectableListElem>
                <Button
                  tooltip={{
                    text: `${
                      isRef ? 'Unset' : isDirty ? `Save to set` : 'Set'
                    } as reference`,
                    offset: 'right',
                  }}
                  className="p-0"
                  onClick={() =>
                    dispatch({
                      type: 'setReferenceSource',
                      id: isRef ? null : id,
                    })
                  }
                  disabled={!isVis || !alignPhaseNone || isDirty}
                  style={{
                    ...cleanFormatting,
                    ...(!isVis || !alignPhaseNone || isDirty
                      ? { border: 'none' }
                      : {}),
                  }}
                >
                  <SourceTargetIcon
                    active={isRef}
                    disabled={!isVis || !alignPhaseNone}
                  />
                </Button>
                <UpDownButtons
                  tooltip={{
                    text: 'Adjust Point Size',
                    offset: 'top',
                  }}
                  isUpEnabled={sources.pointSize[id] < MAX_POINTS_SIZE}
                  isDownEnabled={sources.pointSize[id] > MIN_POINTS_SIZE}
                  onClick={(key) => {
                    dispatch({
                      type: isSensor
                        ? 'setSensorPointSize'
                        : 'setNodePointSize',
                      id,
                      value: key === 'up' ? 1 : -1,
                    });
                  }}
                />{' '}
                <EyeToggle
                  tooltip={{
                    text: `${isVis ? 'Hide' : 'Display'} Point Cloud`,
                    offset: 'top',
                  }}
                  isOn={isVis}
                  onClick={() => {
                    dispatch({
                      type: isSensor
                        ? 'setSensorVisibility'
                        : 'setNodeVisibility',
                      value: !isVis,
                      id,
                    });
                    if (isVis && isSel)
                      dispatch({
                        type: 'setSelectedSource',
                        id: null,
                      });
                  }}
                />
                {sources.display.colorMode !== 'CalRef' && (
                  <ColorPresetPicker
                    initial={palette[sources.display.colorMin[id]]}
                    onChange={(colorIndex) => {
                      dispatch({
                        type: isSensor ? 'setSensorColor' : 'setNodeColor',
                        variant: 'colorMin',
                        id,
                        colorIndex,
                      });
                    }}
                    colors={palette}
                    side="right"
                  />
                )}
                {!['Fixed', 'CalRef'].includes(sources.display.colorMode) && (
                  <ColorPresetPicker
                    initial={palette[sources.display.colorMax[id]]}
                    onChange={(colorIndex) => {
                      dispatch({
                        type: isSensor ? 'setSensorColor' : 'setNodeColor',
                        variant: 'colorMax',
                        id,
                        colorIndex,
                      });
                    }}
                    colors={palette}
                    side="right"
                  />
                )}
              </div>
            </div>
          );
        })}
      </ListContainer>
    </PropertiesSubGroup>
  );
};
