/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
import { TrackedGlobalVisibilities } from '../../../trackedObjects/TrackedObjectsStore';
import { GlobalVisibility } from '../../../types';
import { ZoneGlobalVisibilities } from '../../../zone/ZoneStore';
import Icons24px from '../IconPaths24px';
import { VisibilitiesCluster } from './VisibilityTypes';

const paths: {
  GlobalVis: Record<GlobalVisibility, JSX.Element>;
  Zones: Record<ZoneGlobalVisibilities, JSX.Element>;
  Tracked: Record<TrackedGlobalVisibilities, JSX.Element>;
} = {
  GlobalVis: {
    Grid: Icons24px.hash,
    Axes: Icons24px.trident,
    RangeRings: Icons24px.concentric,
    RangeRingLabels: Icons24px.RangeRingLabels,
    Clouds: Icons24px.cloud,
    Image: Icons24px.image,
    EventLocation: Icons24px.eventLocation,
    UnderlayMap: Icons24px.map,
    // we are not exposing these
    TrackedObjects: <text>tracked</text>,
    Zones: <text>zone</text>,
  },
  Tracked: {
    cloud: Icons24px.cloud,
    rings: Icons24px.rings,
    labels: Icons24px.textBubble,
    previousPositions: Icons24px.zaggedPoints,
    previousPositionsPath: Icons24px.zigzag,
    bbox: Icons24px.cube,
    corners: Icons24px.corners,
  },
  Zones: {
    boundaries: Icons24px.cube,
    labels: Icons24px.textBubble,
  },
};

export const VisibilityIcon = ({
  cluster,
  icon,
  active,
  size = '18px',
  ...rest
}: {
  cluster: VisibilitiesCluster;
  icon: GlobalVisibility | ZoneGlobalVisibilities | TrackedGlobalVisibilities;
  active: boolean;
  size?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  const selectedIcon = (
    paths[cluster] as Record<
      GlobalVisibility | ZoneGlobalVisibilities | TrackedGlobalVisibilities,
      JSX.Element
    >
  )[icon];

  return (
    <svg
      className={`VisibilityIcon ${active ? 'active' : ''}`}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="M0,0h24v24H0V0z" fill="none" />
      {selectedIcon}
    </svg>
  );
};
