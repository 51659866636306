/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Raycaster,
  Vector2,
} from 'three';
import {
  PointerType,
  usePointerEvents,
} from '../../app/hooks/usePointerEvents';
import { useAppDispatch, useAppState } from '../../Stores';
import { Context } from '../../types';
import { MinMaxHandle } from './MinMaxHandle';

const MIN_HEIGHT = 0.3;
const rc = new Raycaster();
const plane = new Mesh(
  new PlaneBufferGeometry(100000, 100000).rotateY(Math.PI / 2),
  new MeshBasicMaterial({
    visible: false,
    transparent: true,
    opacity: 0.1,
  }),
);
const maxHandle = new MinMaxHandle();
const minHandle = new MinMaxHandle();
minHandle.geometry = minHandle.geometry.clone().scale(1, 1, -1);

// Test mouse ray intersections with the ground plane
export const useMinMaxEdit = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const highlighted = useRef<MinMaxHandle | null>(null);
  const [selectedHandle, setSelectedHandle] = useState<MinMaxHandle | null>(
    null,
  );

  useEffect(() => {
    if (selectedHandle === null) return;
    dispatch({ type: 'setViewNavigation', value: selectedHandle === null });

    if (highlighted.current) highlighted.current.isHighlighted = false;
    highlighted.current = selectedHandle;
    selectedHandle.isHighlighted = true;
    selectedHandle.scaleFactor = 1.5;
    selectedHandle.isSelected = true;

    return () => {
      dispatch({ type: 'setViewNavigation', value: true });
      selectedHandle.scaleFactor = 1;
      selectedHandle.isSelected = false;
    };
  }, [selectedHandle]);

  const cb = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (pointer: PointerType) => {
      if (pointer.eventType === 'still') return;
      rc.setFromCamera(pointer, context.viz.camera);
      const testAgainstHandles = selectedHandle === null;
      if (!testAgainstHandles) {
        plane.position.copy(selectedHandle.position);
        plane.rotation.z = Math.atan2(
          context.viz.camera.position.y,
          context.viz.camera.position.x,
        );
      }
      const intersections = rc.intersectObjects(
        testAgainstHandles ? [minHandle, maxHandle] : [plane],
      );

      if (testAgainstHandles) {
        if (intersections.length) {
          const v = intersections[0].object as MinMaxHandle;
          if (pointer.eventType === 'pointerdown') {
            setSelectedHandle(v);
          } else if (pointer.eventType === 'pointermove') {
            highlighted.current = v;
            v.isHighlighted = true;
            v.scaleFactor = 1.5;
          }
        } else {
          // no hits
          if (highlighted.current) {
            highlighted.current.isHighlighted = false;
            highlighted.current.scaleFactor = 1;
          }
        }
      } else {
        // test against plane
        if (pointer.eventType === 'pointermove') {
          if (intersections.length) {
            let v = intersections[0].point.z;
            const isMin = selectedHandle === minHandle;
            v = isMin
              ? Math.min(maxHandle.position.z - MIN_HEIGHT, v)
              : Math.max(minHandle.position.z + MIN_HEIGHT, v);
            selectedHandle.position.z = v;
          }
          if (highlighted.current) {
            highlighted.current.scaleFactor = 1;
            highlighted.current.isHighlighted = false;
          }
        }
      }

      if (pointer.eventType === 'pointerup') {
        if (selectedHandle === null) return;
        const id = state.zones.selected;
        if (id === null) return;

        const params = state.zones.zoneRedefined[id] || state.zones.params[id];
        dispatch({
          type: 'setZoneRedefined',
          id,
          value: {
            ...params,
            heightMin: minHandle.position.z,
            heightMax: maxHandle.position.z,
          },
        });

        setSelectedHandle(null);
      }
    },
    [selectedHandle],
  );
  const setPointerEvents = usePointerEvents(context.canvas, cb);

  // io logic
  useEffect(() => {
    if (state.app.tool !== 'ZoneEdit') return;
    if (state.zones.selected === null) return;
    const id = state.zones.selected;
    if (state.zones.visibilities[id] === false) return;

    const params = state.zones.zoneRedefined[id] || state.zones.params[id];
    const { heightMin, heightMax, vertices } = params;
    const sum = vertices.reduce((p, c) => p.add(c), new Vector2());
    const center = sum.divideScalar(vertices.length);
    minHandle.position.set(center.x, center.y, heightMin);
    maxHandle.position.set(center.x, center.y, heightMax);
    context.groups3JS.Zone.add(maxHandle);
    context.groups3JS.Zone.add(minHandle);

    context.groups3JS.Zone.add(plane);

    setPointerEvents(true);

    return () => {
      maxHandle.removeFromParent();
      minHandle.removeFromParent();

      setPointerEvents(false);
    };
  }, [
    state.app.tool,
    state.zones.allIds,
    state.zones.params,
    state.zones.zoneRedefined,
    state.zones.selected,
    state.zones.visibilities,
  ]);
};
