/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';

export const PlayControlIcon = ({
  icon,
  active = true,
  size = '24px',
  ...rest
}: {
  icon:
    | 'Play'
    | 'Pause'
    | 'Next'
    | 'Prev'
    | 'Forward'
    | 'Backward'
    | 'Start'
    | 'End';
  active?: boolean;
  size?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={`PlayControlIcon ${active ? 'active' : ''}`}
      {...rest}
    >
      <rect fill="none" height="24" width="24" />
      {
        {
          Play: (
            <path d="M9.175 17.1 17.1 12 9.175 6.9ZM12 23.15q-2.325 0-4.362-.862-2.038-.863-3.55-2.375-1.513-1.513-2.375-3.551Q.85 14.325.85 12t.863-4.363q.862-2.037 2.375-3.55Q5.6 2.575 7.638 1.712 9.675.85 12 .85t4.363.862q2.037.863 3.55 2.375 1.512 1.513 2.375 3.55.862 2.038.862 4.363 0 2.325-.862 4.362-.863 2.038-2.375 3.551-1.513 1.512-3.55 2.375-2.038.862-4.363.862Z" />
          ),
          Pause: (
            <path d="M13.975 20.8V3.175h6.6V20.8Zm-10.55 0V3.175h6.6V20.8Z" />
          ),
          Next: <path d="M17.05 19.95V4.05h3.4v15.9Zm-13.5 0V4.05L15.5 12Z" />,
          Prev: (
            <path d="M3.55 19.95V4.05h3.4v15.9Zm16.9 0L8.5 12l11.95-7.95Z" />
          ),
          Forward: (
            <path d="M-.1 19.95V4.05L11.825 12Zm12.275 0V4.05L24.1 12Z" />
          ),
          Backward: (
            <path d="M24.1 19.95 12.175 12 24.1 4.05Zm-12.275 0L-.1 12l11.925-7.95Z" />
          ),
          Start: (
            <path d="M8 18.625q-2.775 0-4.7-1.925-1.925-1.925-1.925-4.7 0-2.775 1.925-4.7Q5.225 5.375 8 5.375q2.325 0 4.075 1.4T14.4 10.3h8.3v3.4h-8.3q-.575 2.125-2.325 3.525-1.75 1.4-4.075 1.4Z" />
          ),
          End: (
            <path d="M16 18.625q-2.325 0-4.075-1.4T9.6 13.7H1.3v-3.4h8.3q.575-2.125 2.325-3.525 1.75-1.4 4.075-1.4 2.775 0 4.7 1.925 1.925 1.925 1.925 4.7 0 2.775-1.925 4.7-1.925 1.925-4.7 1.925Z" />
          ),
        }[icon]
      }
    </svg>
  );
};
