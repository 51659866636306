/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppState } from '../../Stores';
import { Context } from '../../types';

const useRangeRingSynch = (context: Context): void => {
  const state = useAppState();

  /* Hide overlapping labels on orbit change */
  const updateRangeLabels = () => {
    const size = context.viz.labelRenderer?.getSize();
    if (size !== undefined) {
      context.gridPolar.hideUnreadableLabels(context.viz.camera, size);
    }
  };

  /* Init */
  useEffect(() => {
    // Use timeout to yield so the labels always mount first
    setTimeout(
      () =>
        context.gridPolar.setLabelVisibility(
          state.app.visibilities.RangeRingLabels,
        ),
      0,
    );
    setTimeout(updateRangeLabels, 0);

    context.viz.controls.addEventListener('change', updateRangeLabels);
    return () =>
      context.viz.controls.removeEventListener('change', updateRangeLabels);
  }, []);

  /* Handle global visibility */
  useEffect(() => {
    context.gridPolar.setVisibility(state.app.visibilities.RangeRings);
    // Hide overlapping labels
    if (state.app.visibilities.RangeRingLabels) {
      updateRangeLabels();
    }
  }, [state.app.visibilities.RangeRings]);

  /* Handle label visibilities */
  useEffect(() => {
    context.gridPolar.setLabelVisibility(
      state.app.visibilities.RangeRingLabels,
    );
    // Hide overlapping labels
    if (state.app.visibilities.RangeRingLabels) {
      updateRangeLabels();
    }
  }, [state.app.visibilities.RangeRingLabels]);

  /* Handle max range */
  useEffect(() => {
    context.gridPolar.setMaxRange(state.app.gridRange.polar);
    updateRangeLabels();
  }, [state.app.gridRange.polar]);

  /* Handle granularity change */
  useEffect(() => {
    context.gridPolar.setGranularity(state.app.gridSpacing.polar);
    // Enforce max range after granularity change
    context.gridPolar.setMaxRange(state.app.gridRange.polar);
    setTimeout(updateRangeLabels, 0);
  }, [state.app.gridSpacing.polar]);
};

export default useRangeRingSynch;
