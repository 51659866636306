/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { EdgesGeometry, LineBasicMaterial, LineSegments } from 'three';
import { Group, BufferGeometry } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

export class SensorPlayback3JS extends Group {
  public readonly isSource3JS = true;
  public readonly oType: string;
  public oId: string;
  public label: CSS2DObject;
  public icon: LineSegments;

  private labelElem: HTMLDivElement;
  private labelElemChild: HTMLDivElement;

  constructor(iconGeo: BufferGeometry, id: string, color: string) {
    super();
    this.oType = 'Source3JS';
    this.oId = id;
    this.name = 'none';

    this.icon = new LineSegments(
      new EdgesGeometry(iconGeo),
      new LineBasicMaterial({
        color: color,
        depthTest: false,
      }),
    );
    this.icon.scale.set(0.25, 0.25, 0.25);
    this.add(this.icon);

    this.labelElem = document.createElement('div');
    this.labelElemChild = document.createElement('div');
    this.labelElem.appendChild(this.labelElemChild);
    this.labelElemChild.className = 'text-base';
    this.labelElemChild.innerHTML = this.oId;
    this.labelElemChild.style.color = color;
    this.labelElemChild.style.transform = 'translate(0, -20px)';

    this.label = new CSS2DObject(this.labelElem);
    this.label.position.setZ(1.5);
    this.icon.add(this.label);
  }
}
