/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { telemetry } from './telemetry';
import { sensor } from './sensor';
import { node } from './node';
import { align } from './align';
import { alerts } from './alerts';
import { agent } from './agent';
import { settings } from './settings';
import { proxy } from './proxy';
import { record } from './record';
import { event, point } from './zone';
import { about } from './about';

export const endpoints = {
  agent,
  about,
  alerts,
  align,
  proxy,
  record,
  sensor,
  node,
  telemetry,
  settings,
  zone: {
    event,
    point,
  },
};
