/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useRef, useState } from 'react';
import { ProfileList } from '../api/settings';
import Select from '../app/components/Select';
import { useAppDispatch, useAppState } from '../Stores';
import { SettingsInstance } from '../types';
import SettingsActions from './SettingsActions';
import SettingsEditor from './SettingsEditor';
import { instanceToDisplayName } from './settingsTypes';
import { endpoints } from '../api/endpoints';
import {
  FETCH_SETTINGS_FAIL_INTERVAL,
  REST,
  SECONDS_TO_MS,
} from '../constants';
import { isAlphaNumeric } from '../util/misc';
import { ActionButton } from '../app/components/actionButton/ActionButton';
import { ActionIcons } from '../app/components/actionButton/ActionIcons';
import { Button } from '../app/components/Button';
import { safeFetch } from '../safeFetch';
const { settings } = endpoints;

export const JSONEditorModes = ['form', 'tree'];
export type JSONEditorMode = typeof JSONEditorModes[number];

const SelectSettingsInstance = (): JSX.Element => {
  const dispatch = useAppDispatch();
  return (
    <div className="flex items-center mt-2 mr-4">
      <p className="text-sm textGreyE mr-4">Settings Type:</p>
      <Select
        activeTransformer={(instance: SettingsInstance) =>
          instanceToDisplayName[instance]
        }
        options={SettingsInstance}
        onChange={(v) => {
          dispatch({
            type: 'setSettingsSelectedInstance',
            value: v,
          });
        }}
      />
    </div>
  );
};

const SelectSettingsProfile = ({
  profile,
  profileOptions,
  setProfile,
  disabled,
}: {
  profile: string;
  profileOptions: string[];
  setProfile: (mode: string) => void;
  disabled: boolean;
}): JSX.Element => {
  return (
    <div className="flex items-center mt-2 mr-4">
      <p className="text-sm textGreyE mr-4">Selected Profile:</p>
      <Select
        disabled={disabled}
        active={profile}
        options={profileOptions}
        onChange={(profile) => {
          setProfile(profile);
        }}
      />
    </div>
  );
};

const SelectSettingsMode = ({
  mode,
  setMode,
  disabled,
}: {
  mode: JSONEditorMode;
  setMode: (mode: JSONEditorMode) => void;
  disabled: boolean;
}): JSX.Element => {
  return (
    <div className="flex items-center mt-2 mr-4">
      <p className="text-sm textGreyE mr-4">View Mode:</p>
      <Select
        disabled={disabled}
        active={mode}
        options={JSONEditorModes}
        onChange={(mode) => {
          setMode(mode);
        }}
      />
    </div>
  );
};

const SettingsProfileSaveAsInput = ({
  value,
  onChange,
  disabled,
}: {
  value: string;
  onChange: (v: string) => void;
  disabled: boolean;
}): JSX.Element => {
  return (
    <div className="flex flex-row items-center mt-2 mr-4">
      <p className="text-sm textGreyE mr-4">Save As:</p>
      <input
        disabled={disabled}
        type="text"
        value={value}
        size={10}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          // Only allow letters, numbers, spaces, and ( ) _ -
          if (
            !isAlphaNumeric(e.key) &&
            !['(', ')', '_', '-'].includes(e.key) &&
            !e.key.includes('Arrow')
          ) {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

const UploadSettingsButton = ({
  setProfileSaveAs,
  profileSaveAs,
  disabled,
  active,
  fetchProfiles,
}: {
  profileSaveAs: string;
  setProfileSaveAs: (profile: string) => void;
  disabled: boolean;
  active: boolean;
  fetchProfiles: () => void;
}) => {
  const dispatch = useAppDispatch();
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className="mt-2">
      <ActionButton
        disabled={disabled}
        onClick={() => {
          // This triggers the hidden file upload button.
          uploadInputRef.current?.click();
        }}
      >
        <ActionIcons icon={'Upload'} active={active} size={15} />
      </ActionButton>
      <input
        id="zoneUploadInput"
        ref={uploadInputRef}
        type="file"
        accept=".json"
        hidden
        onChange={(event) => {
          const fileList = event.currentTarget.files;
          const selectedFile = fileList?.item(0);
          if (selectedFile) {
            const reader = new FileReader();
            reader.readAsText(selectedFile);

            reader.onload = async () => {
              console.log(JSON.parse(reader.result as string));
              const response = await settings.perception.updateProfile(
                profileSaveAs,
                JSON.parse(reader.result as string),
              );

              const success = response !== null;
              dispatch({
                type: 'enqueueFeedbackMessage',
                value: {
                  message: `${
                    success ? 'Success in' : 'There was an error in'
                  } uploading profile ${profileSaveAs}`,
                  type: success ? 'info' : 'error',
                  priority: 10,
                  durationMs: 2000,
                },
              });
              fetchProfiles();
            };

            reader.onerror = function () {
              console.log(reader.error);
            };
          }
          setProfileSaveAs('');
          event.target.value = ''; // This allows us to select the same file again
        }}
      />
    </div>
  );
};

function Settings(): JSX.Element {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const [profileList, setProfileList] = useState<ProfileList>(
    {} as ProfileList,
  );
  const [mode, setMode] = useState<JSONEditorMode>('form');
  const [profileSaveAs, setProfileSaveAs] = useState('');
  const {
    selectedInstance,
    perception: { selectedProfile },
  } = state.settings;

  const fetchTimeout = useRef<number | null>();
  const fetchProfileList = async (shouldSetCurrentProfile = false) => {
    const listResponse = await settings.perception.listProfiles();

    if (listResponse === null) {
      console.error(
        `Failed to fetch perception settings profiles. Retrying in ${
          FETCH_SETTINGS_FAIL_INTERVAL / SECONDS_TO_MS
        } seconds`,
      );
      fetchTimeout.current = setTimeout(
        fetchProfileList,
        FETCH_SETTINGS_FAIL_INTERVAL,
      );
      return;
    }

    setProfileList(listResponse ?? ({} as ProfileList));

    if (shouldSetCurrentProfile) {
      dispatch({
        type: 'setPerceptionSettingsProfile',
        value: listResponse.current_profile,
      });
    }
    fetchTimeout.current = null;
  };

  useEffect(() => {
    selectedInstance === 'perception' && fetchProfileList(true);
  }, [state.settings.selectedInstance]);
  useEffect(() => {
    fetchTimeout.current && clearTimeout(fetchTimeout.current);
    fetchTimeout.current = null;
  }, [state.settings.selectedInstance]);

  const profiles = profileList.profiles ?? [];
  const activeProfile = profileList.current_profile;

  return (
    <div id="Content" className="flex flex-col">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <SelectSettingsInstance />
          <>
            <SelectSettingsProfile
              disabled={selectedInstance !== 'perception'}
              profile={selectedProfile}
              profileOptions={profiles}
              setProfile={(profile: string) => {
                dispatch({
                  type: 'setPerceptionSettingsProfile',
                  value: profile,
                });
              }}
            />
            <SettingsProfileSaveAsInput
              disabled={selectedInstance !== 'perception'}
              value={
                selectedInstance === 'perception' ? profileSaveAs : 'default'
              }
              onChange={setProfileSaveAs}
            />
            <UploadSettingsButton
              fetchProfiles={fetchProfileList}
              active={profileSaveAs !== ''}
              disabled={profileSaveAs === '' || selectedInstance === 'lidarHub'}
              profileSaveAs={profileSaveAs}
              setProfileSaveAs={setProfileSaveAs}
            />
            <Button
              className="action p-2"
              style={{
                flexGrow: 0,
                margin: 'var(--r2) 0 0 var(--r4)',
                marginRight: 'var(--r2)',
                fontSize: '0.75rem',
              }}
              onClick={async () => {
                await safeFetch.remoteDownload(
                  REST.configuration,
                  'configuration.tar',
                );
              }}
            >
              Download Configuration
            </Button>
          </>
        </div>
        <div className="flex items-center">
          <SelectSettingsMode
            disabled={selectedInstance === 'perception'}
            mode={mode}
            setMode={setMode}
          />
          <p className="text-sm textGreyE no-select mr-4 mt-2">
            Active Profile:{' '}
            <span className="textGreyC">
              {selectedInstance === 'perception' && activeProfile
                ? activeProfile
                : 'default'}
            </span>
          </p>
        </div>
      </div>

      <SettingsActions
        profileSaveAsName={profileSaveAs}
        activeProfile={activeProfile}
        fetchProfiles={fetchProfileList}
      />
      <div
        id="Content"
        style={{
          background: 'var(--greyJ)',
        }}
      >
        <SettingsEditor mode={mode} />
      </div>
    </div>
  );
}

export default Settings;
