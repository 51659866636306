/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef } from 'react';
import { Vector3 } from 'three';
import { useAppDispatch, useAppState } from '../../Stores';
import { Context, Section, Sections, Tool } from '../../types';
import { endpoints } from '../../api/endpoints';

const { record } = endpoints;

const shouldIgnoreKeyPress = (e: KeyboardEvent) => {
  /**
   * Ignore repeat events (key held down), and
   * content editable / input field changes.
   */
  return (
    e.repeat ||
    (e.target as HTMLElement)?.isContentEditable ||
    ['INPUT', 'TEXTAREA'].includes(
      (e.target as HTMLElement)?.tagName.toUpperCase(),
    )
  );
};

const modeShortcutMap: Record<string, Section> = {};
let paneShortcut = 1;
Sections.forEach((section) => {
  const numericKeyInput = 'Digit' + paneShortcut;
  modeShortcutMap[numericKeyInput] = section;
  paneShortcut++;
});

// Keyboard events
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useShortcuts = (context: Context): void => {
  const dispatch = useAppDispatch();
  const state = useAppState();
  const { mode } = state.app;
  const lastTool = useRef<Tool>('Camera');
  const isInputPlayback = state.app.inputMode === 'playback';
  const isInputPCAP = state.app.inputMode === 'pcap';
  const isInputLiveOrPcap = !isInputPlayback;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (shouldIgnoreKeyPress(e)) return;

      // eslint-disable-next-line sonarjs/no-small-switch
      switch (e.code) {
        case 'KeyQ': {
          lastTool.current =
            state.app.tool === 'World' ? 'Camera' : state.app.tool;
          dispatch({ type: 'setTool', value: 'Select' });
          break;
        }

        case 'Escape': {
          dispatch({ type: 'setTool', value: 'Camera' });
          dispatch({ type: 'setEscapeFlag', value: true });
          break;
        }
        // Arrow keys are only detected onKeyDown
        case 'ArrowRight': {
          // Live sensors cannot be stepped
          if (isInputPCAP) {
            record.call('step');
            dispatch({ type: 'setPlay', value: false });
          } else if (isInputPlayback) {
            dispatch({
              type: 'setPlaybackTimeIndex',
              offset: 'relative',
              value: e.shiftKey ? 10 : 1,
            });
          }
          return;
        }
        case 'ArrowLeft': {
          if (isInputPlayback) {
            dispatch({
              type: 'setPlaybackTimeIndex',
              offset: 'relative',
              value: e.shiftKey ? -10 : -1,
            });
          }
          return;
        }
        case 'ArrowUp': {
          if (isInputPlayback) {
            dispatch({
              type: 'setPlaybackTimeIndex',
              offset: 'relative',
              value: 10,
            });
          }
          return;
        }
        case 'ArrowDown': {
          if (isInputPlayback) {
            dispatch({
              type: 'setPlaybackTimeIndex',
              offset: 'relative',
              value: -10,
            });
          }
          return;
        }
        case 'PageUp': {
          if (isInputPlayback) {
            dispatch({
              type: 'setPlaybackTimeIndex',
              offset: 'absolute',
              value: 0,
            });
          }
          return;
        }
        case 'PageDown': {
          if (isInputPlayback && state.playback.playing) {
            dispatch({
              type: 'setPlaybackTimeIndex',
              offset: 'absolute',
              value: state.playback.playing.timestamps.length - 1,
            });
          }
          return;
        }

        case 'KeyC': {
          if (state.app.mode !== 'zone') return;
          const id = state.zones.selected;
          if (id === null || !e.ctrlKey) return;
          dispatch({ type: 'setCopiedZone', id });
          return;
        }
        case 'KeyV': {
          if (state.app.mode !== 'zone') return;
          const id = state.zones.copied;
          if (id === null || !e.ctrlKey) return;
          dispatch({ type: 'setPasteZone', value: true });
          return;
        }
      }
    };
    const onKeyUp = (e: KeyboardEvent) => {
      if (shouldIgnoreKeyPress(e)) return;

      // eslint-disable-next-line sonarjs/no-small-switch
      switch (e.code) {
        case 'KeyQ':
          dispatch({ type: 'setTool', value: lastTool.current });
          break;
      }
    };
    const onKeyPress = (e: KeyboardEvent) => {
      if (shouldIgnoreKeyPress(e)) return;

      const modeSelected = modeShortcutMap[e.code];
      if (isInputLiveOrPcap && modeSelected !== undefined) {
        dispatch({ type: 'setMode', value: modeSelected });
        return;
      }

      switch (e.code) {
        case 'KeyF': {
          // Frame selection, allow the user to set the cameras interest to the center of the selected object/s
          const mode = state.app.mode;
          const interest = new Vector3();
          // eslint-disable-next-line sonarjs/no-nested-switch
          switch (mode) {
            case 'viewer': {
              const selected = Object.keys(state.tracked.selected)
                .filter((id) => state.tracked.selected[id])
                .filter(
                  (id) => context.instances.TrackedObject.all[id] !== undefined,
                )
                .map((id) => context.instances.TrackedObject.all[id]);
              if (!selected.length) return;
              for (const tracked of selected) {
                interest.add(tracked.position);
              }
              interest.divideScalar(selected.length);
              break;
            }
            case 'setup': {
              const tool = state.app.tool;
              if (tool === 'World') {
                interest.copy(context.world.position);
              } else {
                const source = context.instances.Source.selectedSource?.pose;
                if (source) interest.copy(source.position);
              }
              break;
            }
            case 'zone': {
              const id = state.zones.selected;
              if (id === null) return;
              const zone = context.instances.Zones.all[id];
              if (zone)
                interest
                  .copy(zone.position)
                  // accommodate for world transform
                  .applyMatrix4(context.world.matrixWorld);
              break;
            }
            default:
              return;
          }
          context.viz.controls.target.copy(interest);
          context.viz.controls.update();
          break;
        }

        case 'KeyL': {
          if (e.ctrlKey && e.shiftKey)
            dispatch({
              type: 'setDeveloperMode',
              value: !state.app.developerMode,
            });
          else
            dispatch({
              type: 'setPitchAndRollVisibility',
              value: !state.app.isPitchAndRollVisible,
            });

          break;
        }

        case 'KeyD': {
          dispatch({
            type: 'setFrameNumberVisibility',
            value: !state.app.isFrameNumberVisible,
          });
          break;
        }

        case 'Minus': {
          context.transformControls.size = Math.max(
            context.transformControls.size - 0.1,
            0.1,
          );
          break;
        }

        case 'Equal':
        case '+': {
          context.transformControls.size = context.transformControls.size + 0.1;
          break;
        }
        case 'Space': {
          e.preventDefault();
          // If a button was clicked previously, it'll be focused (active) and
          // so we'll click it again with space. Blur it instead.
          (document.activeElement as HTMLElement)?.blur();
          isInputPCAP && record.call(state.app.isPlaying ? 'pause' : 'play');
          dispatch({ type: 'setPlay', value: !state.app.isPlaying });
          return;
        }

        case 'KeyC': {
          if (state.app.mode !== 'zone') return;
          const id = state.zones.selected;
          if (id === null || !e.ctrlKey) return;
          dispatch({ type: 'setCopiedZone', id });
          return;
        }
        case 'KeyP': {
          if (state.app.mode !== 'zone') return;
          const id = state.zones.copied;
          if (id === null || !e.ctrlKey) return;
          dispatch({ type: 'setPasteZone', value: true });
          return;
        }
        default: {
        }
      }
    };
    if (mode === 'init') return;

    window.addEventListener('keypress', onKeyPress);
    window.addEventListener('keydown', onKeydown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keypress', onKeyPress);
      window.removeEventListener('keydown', onKeydown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [
    state.app.mode,
    state.app.isPlaying,
    state.app.tool,
    state.zones.selected,
    state.zones.copied,
    state.app.isPitchAndRollVisible,
    state.app.isFrameNumberVisible,
    state.tracked.selected,
    state.setup.selected,
    state.playback.playing,
    state.app.developerMode,
    state.app.inputMode,
  ]);
};
