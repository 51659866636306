/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAppState } from '../Stores';
import { useImageState } from './ImageStore';
import { PanZoomCanvas } from './PanZoomCanvas';
import { useWindowWidth } from './useWindowWidth';

export const Image2D = (): JSX.Element | null => {
  const state = useAppState();
  const imageState = useImageState();

  const [currentSensor, setCurrentSensor] = useState('');
  const [image, setImage] = useState<ImageBitmap | null>(null);
  const prevImage = useRef<ImageBitmap | null>(null);

  // Used to limit the height that the user can resize the canvas to
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [minHeight, setMinHeight] = useState(64);
  const windowWidth = useWindowWidth();

  const sensorIds = Object.keys(state.sensors.addedStates).filter((id) => {
    const isAdded = state.sensors.addedStates[id];
    const hasImage = imageState.images[id];

    return isAdded && hasImage;
  });

  useEffect(() => {
    // List of sensors was emptied, clear current sensor
    if (sensorIds.length === 0 && currentSensor) {
      setCurrentSensor('');
    }

    // List of sensors was changed and we aren't viewing a valid sensor,
    // set current sensor to the first available one
    if (sensorIds.length && !imageState.images[currentSensor]) {
      setCurrentSensor(sensorIds[0]);
    }
  }, [sensorIds]);

  useEffect(() => {
    const updateImage = async () => {
      const webpBytes = imageState.images[currentSensor];

      if (!webpBytes) return;

      const blob = new Blob([webpBytes], {
        type: 'image/webp',
      });

      prevImage.current = image;
      setImage(await createImageBitmap(blob));
    };

    updateImage();
  }, [imageState.images[currentSensor]]);

  // Update min height whenever the image size changes or the width of this container changes
  useLayoutEffect(() => {
    if (!image) return;
    if (!containerRef.current) return;

    // Prevent division by 0
    if (image.width === 0) return;

    const ratio = image.height / image.width;
    const containerWidth = containerRef.current.getBoundingClientRect().width;

    setMinHeight(containerWidth * ratio);
  }, [image?.width, image?.height, windowWidth, state.app.isPaneExpanded]);

  useEffect(() => {
    // This is a safe spot to delete the previous image because when this useEffect
    // runs, the PanZoomCanvas has already rerendered with new image
    prevImage.current?.close();
  }, [image]);

  if (!state.app.visibilities.Image) return null;
  if (!image) return null;

  return (
    <div
      id="Image2D"
      ref={containerRef}
      style={{
        position: 'relative',
        minHeight,
      }}
    >
      {sensorIds.length > 1 && (
        <select
          value={currentSensor}
          onChange={(e) => setCurrentSensor(e.target.value)}
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: 1, // Put it above the image
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          {sensorIds.map((id) => (
            <option key={id} value={id}>
              {id}
            </option>
          ))}
        </select>
      )}
      <PanZoomCanvas image={image} />
    </div>
  );
};
