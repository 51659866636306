/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { HTMLAttributes } from 'react';
import { useAppDispatch, useAppState } from '../../../Stores';
import { Section, SetupTab, ViewerTab } from '../../../types';

const tabNames: Record<SetupTab | ViewerTab, string> = {
  viewer: 'Viewer',
  recording: 'Record',
  preferences: 'Preferences',
  setup: 'Source Management',
  zone: 'Zones',
  map: 'Map',
};

const Tab = ({
  value,
  active,
  disabled,
  onClick,
}: {
  value: Section;
  active: boolean;
  disabled: boolean;
  onClick: (v: Section) => void;
}): JSX.Element => {
  return (
    <button
      className={`Tab text-sm no-select p-2 ${
        active ? 'textGreyB' : 'textGreyD'
      }`}
      style={{
        borderRadius: '0',
        borderTop: 'solid 0.15rem',
        borderColor: active ? 'var(--greyD)' : 'var(--greyI)',
        backgroundColor: active ? 'var(--greyH)' : 'var(--greyI)',
      }}
      value={value}
      onClick={() => {
        onClick(value);
      }}
      disabled={disabled}
      // tooltip={{ text: value, offset: 'bottom' }}
    >
      {tabNames[value as ViewerTab]}
    </button>
  );
};

export const Tabs = ({
  tabs,
  disabled,
  ...rest
}: {
  tabs: Section[];
  disabled: boolean;
} & HTMLAttributes<HTMLDivElement>): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  if (disabled || tabs.length === 0) return null;
  return (
    <div className="flex flex-row mb-4" {...rest}>
      {tabs.map((tab) => (
        <Tab
          key={tab}
          value={tab}
          active={tab === state.app.mode}
          disabled={disabled}
          onClick={(v: Section) => {
            dispatch({ type: 'setMode', value: v });
          }}
        />
      ))}
    </div>
  );
};
