/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import diagnosticsColumns from './diagnosticsColumns';
import DiagnosticsActions from './DiagnosticsActions';
import DiagnosticsTables from './DiagnosticsTables';
import { TableHeader } from './common/Headers';
import DiagnosticsSidebar from './DiagnosticsSidebar';
import './Diagnostics.css';

const Diagnostics = (): JSX.Element => {
  return (
    <div id="diagnostics-layout" className="flex flex-row">
      <div
        className="flex flex-col"
        style={{
          background: 'inherit',
          gridArea: 'content',
        }}
      >
        <DiagnosticsActions />
        <TableHeader className="diagnostics" columns={diagnosticsColumns} />
        <div
          id="Content"
          style={{
            background: 'var(--greyI)',
            overflowX: 'auto',
          }}
        >
          <DiagnosticsTables />
        </div>
      </div>
      <DiagnosticsSidebar />
    </div>
  );
};

export default Diagnostics;
