/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { round } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  BufferGeometry,
  DoubleSide,
  Line,
  LineDashedMaterial,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Raycaster,
  Vector2,
  Vector3,
} from 'three';
import { PointerType, usePointerEvents } from '../app/hooks/usePointerEvents';
import { getAppId } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';
import { Context } from '../types';
import { getGUID, linesIntersect } from '../util/misc';
import { GREY, PINK } from '../util/palettes';
import { getFromVertexPool, putToVertexPool, Vertex3JS } from './Vertex3JS';
import { ZoneParams } from './ZoneStore';

const rc = new Raycaster();
const plane = new Mesh(
  new PlaneBufferGeometry(10000, 10000),
  new MeshBasicMaterial({}),
);

// Test mouse ray intersections with the ground plane
export const usePolyCreate = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const [points, setPoints] = useState<Vector2[]>([]);
  const [intersects, setIntersects] = useState(false);

  const vertices = useRef<Vertex3JS[]>([]);
  const cursor = useRef<Vertex3JS>(new Vertex3JS());
  const lineMat = useRef(
    new LineDashedMaterial({
      color: GREY,
      linewidth: 4,
      side: DoubleSide,
      dashSize: 0.9,
      gapSize: 0.1,
    }),
  );
  const line = useRef(new Line(new BufferGeometry(), lineMat.current));
  const lastSegment = useRef(new Line(new BufferGeometry(), lineMat.current));
  const pointerOutsideCanvas = useRef(true);

  // disable frustum culling for the lines
  useEffect(() => {
    line.current.frustumCulled = false;
    lastSegment.current.frustumCulled = false;
  }, []);

  // Handle intersection of segments
  useEffect(() => {
    if (!intersects) return;
    lineMat.current.color.setStyle(PINK);
    cursor.current.isSelected = true;
    vertices.current.forEach((v) => (v.isSelected = true));

    dispatch({
      type: 'setFeedbackMessage',
      value: { message: 'Avoid crossing edges', type: 'warning' },
    });

    return () => {
      lineMat.current.color.setStyle(GREY);
      cursor.current.isSelected = false;
      vertices.current.forEach((v) => (v.isSelected = false));

      dispatch({
        type: 'setFeedbackMessage',
        value: {
          type: 'info',
          message: 'Click over first vertex to close path',
        },
      });
    };
  }, [intersects]);

  const closePolyline = useCallback(() => {
    const serverId = getGUID();
    const type = state.app.zoneCreationType;
    const appId = getAppId(serverId.toString(), type);
    const name = `Zone-${state.zones.allIds.length}`;
    const zoneParams: ZoneParams = {
      serverId,
      heightMin: 0,
      heightMax: 1,
      name,
      type,
      vertices: [...points],
      metadata: '',
    };

    dispatch({
      type: 'setZone',
      id: appId,
      value: zoneParams,
    });

    dispatch({
      type: 'setZoneRedefined',
      id: appId,
      value: zoneParams,
    });

    dispatch({ type: 'setSelectedZone', id: appId });

    // switch to edit on loop closure
    dispatch({ type: 'setTool', value: 'ZoneEdit' });
  }, [points, state.app.zoneCreationType, state.zones.allIds]);

  const cb = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (pointer: PointerType) => {
      if (pointer.eventType === 'still') return;
      rc.setFromCamera(pointer, context.viz.camera);
      const pointOnGrid = rc.intersectObject(plane)[0]?.point ?? null;
      if (pointOnGrid === null) return;
      const pX = round(pointOnGrid.x, Vertex3JS.PRECISION);
      const pY = round(pointOnGrid.y, Vertex3JS.PRECISION);

      const overFirstVertex =
        vertices.current.length > 2
          ? rc.intersectObject(vertices.current[0]).length > 0
          : false;
      cursor.current.isHighlighted = overFirstVertex;

      switch (pointer.eventType) {
        case 'click': {
          if (intersects) return;
          if (pointerOutsideCanvas.current) return;

          if (overFirstVertex && vertices.current.length > 2) {
            closePolyline();
          } else {
            setPoints((v) => [...v, new Vector2(pX, pY)]);
          }
          break;
        }
        case 'pointermove': {
          if (overFirstVertex)
            cursor.current.position.copy(vertices.current[0].position);
          else cursor.current.position.set(pX, pY, 0);

          if (vertices.current.length > 0)
            vertices.current[0].isHighlighted = overFirstVertex;

          if (points.length > 0) {
            const lastPoint = points[points.length - 1];
            lastSegment.current.geometry.setFromPoints([
              lastPoint,
              cursor.current.position as unknown as Vector2,
            ]);
            lastSegment.current.computeLineDistances();
            line.current.computeLineDistances();

            context.groups3JS.Zone.add(cursor.current);
            context.groups3JS.Zone.add(lastSegment.current);
          }

          if (overFirstVertex) return;

          // test for intersections
          const newPoint = new Vector2(pX, pY);
          const canCheckForIntersections = points.length > 2;
          if (canCheckForIntersections) {
            const upTo = points.length - 2;
            const lastPoint = points[points.length - 1];
            for (let i = 0; i < upTo; i++) {
              const pointA = points[i];
              const pointB = points[i + 1];
              const areCrossing = linesIntersect(
                pointA,
                pointB,
                lastPoint,
                newPoint,
              );
              if (areCrossing) {
                setIntersects(true);
                return;
              }
            }
            setIntersects(false);
          }
          break;
        }
        case 'pointerleave': {
          pointerOutsideCanvas.current = true;
          break;
        }
        case 'pointerenter': {
          pointerOutsideCanvas.current = false;
          break;
        }
        default:
          break;
      }
    },
    [closePolyline, intersects, points],
  );
  const setPointerEventsActive = usePointerEvents(context.canvas, cb);

  useEffect(() => {
    if (points.length === 0) return;

    for (const pnt of points) {
      const v = getFromVertexPool();
      v.set(new Vector3(pnt.x, pnt.y, 0));
      context.groups3JS.Zone.add(v);
      vertices.current.push(v);
    }

    line.current.geometry.setFromPoints(points);
    line.current.computeLineDistances();
    lastSegment.current.geometry.setFromPoints(points);
    lastSegment.current.computeLineDistances();

    context.groups3JS.Zone.add(line.current);
    context.groups3JS.Zone.add(cursor.current);
    context.groups3JS.Zone.add(lastSegment.current);

    return () => {
      for (const vertex3JS of vertices.current) {
        vertex3JS.removeFromParent();
        putToVertexPool(vertex3JS);
      }
      vertices.current.length = 0;
      //
      line.current.geometry.setFromPoints([]);
      lastSegment.current.geometry.setFromPoints([]);
      //
      line.current.removeFromParent();
      lastSegment.current.removeFromParent();
      cursor.current.removeFromParent();
    };
  }, [points]);

  useEffect(() => {
    if (state.app.tool !== 'ZoneCreate') return;
    if (state.zones.selected !== null)
      dispatch({ type: 'setSelectedZone', id: null });
    setPointerEventsActive(true);
    dispatch({ type: 'setViewNavigation', value: false });

    // clean up
    return () => {
      setPointerEventsActive(false);
      dispatch({ type: 'setViewNavigation', value: true });
      setIntersects(false);
      setPoints([]);
      pointerOutsideCanvas.current = true;
    };
  }, [state.app.tool]);
};
