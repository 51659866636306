/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { InputHTMLAttributes, useRef, forwardRef } from 'react';
import './Input.css';

const Input = forwardRef(
  (
    {
      name,
      onChange,
      invalidMessage,
      value,
      disabled = false,
      isValid = true,
      autoComplete = 'off',
      children,
      onSubmit,
      ...rest
    }: InputHTMLAttributes<HTMLInputElement> & {
      onSubmit?: () => void;
      invalidMessage?: string;
      isValid?: boolean;
    },
    forwardedRef,
  ): JSX.Element => {
    const inputRef = (forwardedRef ||
      useRef<HTMLInputElement>(null)) as React.RefObject<HTMLInputElement>;
    const isValidMsgVisible =
      !isValid &&
      inputRef.current?.value.length !== undefined &&
      inputRef.current?.value.length > 0;

    return (
      <>
        <div className="floating-input">
          <input
            style={{
              textAlign: 'left',
            }}
            ref={inputRef}
            id={name}
            className="input text-sm"
            type="text"
            placeholder=" "
            value={value}
            disabled={disabled}
            autoComplete={autoComplete}
            onChange={(e) => {
              onChange && onChange(e);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSubmit && onSubmit();
              }
            }}
            {...rest}
          />
          <label htmlFor={name} className="label placeholder text-sm">
            {children && <p className="mr-1">{children}</p>}
            {name}
          </label>
        </div>
        {isValidMsgVisible && (
          <div className="invalid-text textGreyD text-sm">{invalidMessage}</div>
        )}
      </>
    );
  },
);

Input.displayName = 'Input';

export default Input;
