/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { AppDefaultState } from '../app/AppStore';
import NumberInput from '../app/components/NumberInput/NumberInput';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { ResetButton } from '../app/components/ResetButton';
import { MAX_AUTO_ROTATE_SPEED } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';

const AutoRotateProperties = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  return (
    <>
      <PropertiesSubGroup name="Auto Rotate" expandedInitValue={false}>
        <div className="flex wrap justify-between my-2">
          <p className="text-sm textGreyD">Speed</p>

          <ResetButton
            isVisible={
              AppDefaultState.autoRotateSpeed !== state.app.autoRotateSpeed
            }
            onClick={() =>
              dispatch({
                type: 'setSceneAutoRotateSpeed',
                value: 'default',
              })
            }
          >
            <NumberInput
              isDirty={false}
              disabled={false}
              value={Number(state.app.autoRotateSpeed.toFixed(2))}
              max={MAX_AUTO_ROTATE_SPEED}
              min={-MAX_AUTO_ROTATE_SPEED}
              onChangeNumber={(v) => {
                dispatch({
                  type: 'setSceneAutoRotateSpeed',
                  value: v,
                });
              }}
            />
          </ResetButton>
        </div>
      </PropertiesSubGroup>
    </>
  );
};

export default AutoRotateProperties;
