/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useState } from 'react';
import { endpoints } from '../api/endpoints';
import { useAppState, useAppDispatch } from '../Stores';
import { PropertiesGroup } from '../app/components/pane/PropertiesGroup';
import RecordButton from './RecordButton';
import RecordFileNameInput from './RecordFileNameInput';
import RecordLengthInput from './RecordLengthInput';
import RecordingList from './RecordingList';
import RecordTime from './RecordTime';

const { record } = endpoints;

const NUMBER_OF_MONTHS = 12;
const RecordingProperties = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const [isRecordingLoading, setIsRecordingLoading] = useState(false);
  const recordingState = state.recording;

  const recordingName = recordingState.recordingNamePrefix;
  const recordingLength = recordingState.recordingLengthSeconds;
  const maxRecordingLength = 1800;

  const generateRecordingName = (): string => {
    const date = new Date();
    const timeStampSuffix = `${
      (date.getMonth() + 1) % NUMBER_OF_MONTHS
    }_${date.getDate()}__${date.getHours()}_${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}_${date.getSeconds().toString().padStart(2, '0')}`;

    return recordingName
      ? `${recordingName}_${timeStampSuffix}`
      : timeStampSuffix;
  };

  return (
    <>
      <PropertiesGroup name="Actions" expandedInitValue={true}>
        <RecordFileNameInput
          value={recordingName}
          disabled={recordingState.isRecording}
          onChange={(newFileName: string) =>
            dispatch({ type: 'setRecordingNamePrefix', value: newFileName })
          }
        />
        <RecordLengthInput
          value={recordingLength}
          name={
            recordingState.isValidSettings
              ? `Recording Time (seconds)`
              : `Maximum time is ${maxRecordingLength} seconds`
          }
          disabled={recordingState.isRecording}
          onChange={(newRecordingLength: number) => {
            if (newRecordingLength > maxRecordingLength) {
              recordingState.isValidSettings = false;
            } else {
              recordingState.isValidSettings = true;
            }
            dispatch({
              type: 'setRecordingLengthSeconds',
              value: newRecordingLength,
            });
          }}
        />
        <div className="flex items-center justify-between p-1">
          {recordingState.isValidSettings ? (
            <RecordButton
              isRecording={recordingState.isRecording}
              isLoading={isRecordingLoading}
              onClick={async () => {
                let response;
                // Toggle
                const isNotRecording = !recordingState.isRecording;

                if (isNotRecording && recordingState.isValidSettings) {
                  // Starting a recording

                  const generatedRecordingName = generateRecordingName();

                  // Minimum recording length is 5 seconds; enforcing on frontend so
                  // that shorter pcaps can be made on the backend if needed
                  const recordingLength =
                    recordingState.recordingLengthSeconds < 5
                      ? 5
                      : recordingState.recordingLengthSeconds;

                  setIsRecordingLoading(true);
                  response = await record.recording.start(
                    generatedRecordingName,
                    recordingLength,
                  );
                  setIsRecordingLoading(false);
                  // Name is in state so that we can sync from server between clients
                  dispatch({
                    type: 'setRecordingName',
                    value: generatedRecordingName,
                  });
                } else if (!isNotRecording) {
                  // Ending a recording
                  response = await record.recording.stop();
                }

                if (response !== null && response?.message === 'Success') {
                  dispatch({ type: 'setIsRecording', value: isNotRecording });
                }
              }}
            />
          ) : (
            <> </>
          )}
          {recordingState.isRecording ? (
            <RecordTime isRecording={recordingState.isRecording} />
          ) : recordingState.isValidSettings ? (
            <p className="textGreyC text-sm">Start Recording</p>
          ) : (
            <p className="textError text-sm">Invalid Settings</p>
          )}
        </div>
      </PropertiesGroup>
      <PropertiesGroup name="Recordings" expandedInitValue={true}>
        <RecordingList />
      </PropertiesGroup>
    </>
  );
};

export default RecordingProperties;
