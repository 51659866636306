/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { PropertiesGroup } from '../app/components/pane/PropertiesGroup';
import { useAppDispatch, useAppState } from '../Stores';
import { SourceList } from './SourceList';
import { Context } from '../types';
import { useAlign } from './useAlign';
import { Button } from '../app/components/Button';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { ColorDot } from '../app/components/ColorDot';
import { useSourceSelect } from './useSourceSelect';
import { Separator } from '../app/components/Separator';
import { Palettes } from '../constants';

const Item = ({
  title,
  id,
  color,
}: {
  title: string;
  id: string | null;
  color: string | null;
}): JSX.Element | null => {
  if (id === null) return null;
  return (
    <div className="flex justify-between my-3 text-sm textGreyD">
      <p className="text-base textGreyE">{title}:</p>
      <div className="flex items-center">
        <ColorDot color={color} />
        <p className="text-base textGreyC">{id}</p>
      </div>
    </div>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
function AlignProperties({ context }: { context: Context }): JSX.Element {
  const state = useAppState();
  const dispatch = useAppDispatch();
  useSourceSelect(context);
  useAlign(context);

  const ref = state.setup.reference;
  const sel = state.setup.selected;
  const isSensor = sel !== null && state.sensors.allIds.includes(sel);
  const sources = isSensor ? state.sensors : state.nodes;
  const palette = Palettes[state.app.palette];
  const refClr = ref ? palette[sources.display.colorMin[ref]] : null;
  const selClr = sel ? palette[sources.display.colorMin[sel]] : null;

  const canAlign = ref !== null && sel !== null && ref !== sel;
  const isFetching = state.setup.alignPhase === 'request';
  const isEvaluating = state.setup.alignPhase === 'evaluate';

  return (
    <>
      <PropertiesGroup name="Source List" expandedInitValue={true}>
        <SourceList />
      </PropertiesGroup>

      <PropertiesGroup name="Actions" expandedInitValue={true}>
        <PropertiesSubGroup
          name="Selected Source Actions"
          className="flex flex-col"
        >
          <Button
            id="Reset"
            className="action"
            disabled={
              isEvaluating ||
              sel === null ||
              state.setup.posePhase === 'request' ||
              !sources.transformed[sel]
            }
            onClick={() => {
              if (sel) {
                const pose = sources.extrinsics[sel];
                if (pose === null) {
                  console.error('Trying to reset to null pose. ID:', sel);
                  return;
                }
                if (sources.transformed[sel]) {
                  dispatch({
                    type: isSensor
                      ? 'setSensorTransformed'
                      : 'setNodeTransformed',
                    id: sel,
                    value: false,
                  });
                }
                const source = context.instances.Source.all[sel];
                source.pose.position.copy(pose.pos);
                source.pose.quaternion.copy(pose.rot);
              }
            }}
          >
            Reset
          </Button>
          <Button
            id="Default Pose"
            className="action"
            disabled={
              isEvaluating ||
              sel === null ||
              state.setup.posePhase === 'request'
            }
            onClick={() => {
              if (sel) {
                if (isSensor) {
                  const sensor = context.instances.Source.all[sel];
                  const imu = state.sensors.imus[sel];
                  sensor.pose.position.set(0, 0, 0);
                  sensor.pose.quaternion.copy(imu);
                  if (!state.sensors.transformed[sel]) {
                    dispatch({
                      type: 'setSensorTransformed',
                      id: sel,
                      value: true,
                    });
                  }
                } else {
                  const node = context.instances.Source.all[sel];
                  node.pose.position.set(0, 0, 0);
                  node.pose.quaternion.set(0, 0, 0, 1);
                  if (!state.nodes.transformed[sel]) {
                    dispatch({
                      type: 'setNodeTransformed',
                      id: sel,
                      value: true,
                    });
                  }
                }
              }
            }}
          >
            {isSensor ? `IMU Pose` : `Origin`}
          </Button>
          <Button
            id="Save Pose"
            className="action"
            disabled={
              isEvaluating ||
              sel === null ||
              state.setup.posePhase === 'request' ||
              !sources.transformed[sel]
            }
            onClick={() => {
              if (sel) {
                dispatch({ type: 'setPosePhase', value: 'request' });
              }
            }}
          >
            {state.setup.posePhase === 'request' ? 'saving...' : 'Save Pose'}
          </Button>
        </PropertiesSubGroup>

        <PropertiesSubGroup className="flex flex-col" name="Alignment">
          <section>
            <Item title="Reference" id={ref} color={refClr} />
            <Item title="Unaligned" id={sel} color={selClr} />
          </section>

          {!canAlign ? (
            <p className="text-sm textGreyD mt-1">
              <span style={{ fontWeight: '500' }}>To begin alignment:</span>{' '}
              {ref === null && sel === null
                ? 'set a reference source or select a source.'
                : ref !== null
                ? 'select a source.'
                : ref !== sel
                ? 'set a reference source.'
                : 'Reference and selected sources must be different.'}
            </p>
          ) : (
            <>
              {!isEvaluating ? (
                <Button
                  className="action"
                  disabled={isEvaluating || !canAlign || isFetching}
                  onClick={() => {
                    if (canAlign && ref && sel) {
                      dispatch({ type: 'setPosePhase', value: 'request' });
                      dispatch({ type: 'setAlignPhase', value: 'request' });
                    }
                  }}
                >
                  {isFetching ? 'Calculating...' : 'Align'}
                </Button>
              ) : (
                <p
                  className="text-sm textGreyB my-1"
                  style={{ fontWeight: '500' }}
                >
                  {`Alignment complete.`}
                  <br />
                  {`Click "Accept" to save the positions.`}
                </p>
              )}
              <div className="flex justify-between my-1">
                <Button
                  className="action"
                  disabled={!isEvaluating}
                  onClick={() => {
                    dispatch({ type: 'setAlignPhase', value: 'reject' });
                    dispatch({
                      type: isSensor
                        ? 'setSensorTransformed'
                        : 'setNodeTransformed',
                      id: sel ?? '',
                      value: false,
                    });
                  }}
                >
                  Reject
                </Button>

                <Separator />

                <Button
                  className="action"
                  disabled={!isEvaluating}
                  onClick={() => {
                    dispatch({ type: 'setAlignPhase', value: 'approve' });
                    dispatch({
                      type: isSensor
                        ? 'setSensorTransformed'
                        : 'setNodeTransformed',
                      id: sel ?? '',
                      value: false,
                    });
                  }}
                >
                  Accept
                </Button>
              </div>
            </>
          )}
        </PropertiesSubGroup>
      </PropertiesGroup>
    </>
  );
}

export default AlignProperties;
