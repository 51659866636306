/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { AppDefaultState } from '../app/AppStore';
import { EyeToggle } from '../app/components/pane/eye/Visible';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { ResetButton } from '../app/components/ResetButton';
import Select from '../app/components/Select';
import {
  GRID_GRANULARITY_OPTIONS,
  GRID_RANGE_OPTIONS,
  RANGE_RING_GRANULARITY_OPTIONS,
  RANGE_RING_RANGE_OPTIONS,
} from '../constants';
import { useAppDispatch, useAppState } from '../Stores';

const CoordinateProperties = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  return (
    <>
      <PropertiesSubGroup name="Range Rings" expandedInitValue={false}>
        <div className="flex items-center">
          <p className="text-sm textGreyE grow">Range</p>

          <ResetButton
            isVisible={
              AppDefaultState.gridRange.polar !== state.app.gridRange.polar
            }
            onClick={() =>
              dispatch({
                type: 'setGridRange',
                instance: 'polar',
                value: 'default',
              })
            }
          >
            <Select
              disabled={!state.app.visibilities.RangeRings}
              active={state.app.gridRange.polar}
              options={RANGE_RING_RANGE_OPTIONS}
              onChange={(v) => {
                dispatch({
                  type: 'setGridRange',
                  instance: 'polar',
                  value: v,
                });
              }}
            />
          </ResetButton>
        </div>

        <div className="flex items-center">
          <p className="text-sm textGreyE grow">Granularity</p>
          <ResetButton
            isVisible={
              AppDefaultState.gridSpacing.polar !== state.app.gridSpacing.polar
            }
            onClick={() =>
              dispatch({
                type: 'setGridSpacing',
                instance: 'polar',
                value: 'DEFAULT',
              })
            }
          >
            <Select
              disabled={!state.app.visibilities.RangeRings}
              active={state.app.gridSpacing.polar}
              options={['DEFAULT', ...RANGE_RING_GRANULARITY_OPTIONS]}
              onChange={(v) => {
                dispatch({
                  type: 'setGridSpacing',
                  instance: 'polar',
                  value: v,
                });
              }}
            />
          </ResetButton>
        </div>

        <div className="flex items-center">
          <p className="text-sm textGreyE grow">Labels</p>
          <EyeToggle
            isOn={state.app.visibilities.RangeRingLabels}
            onClick={() => {
              dispatch({
                type: 'setGlobalVisibility',
                instance: 'RangeRingLabels',
                value: !state.app.visibilities.RangeRingLabels,
              });
            }}
          />
        </div>
      </PropertiesSubGroup>

      <PropertiesSubGroup name="Grid" expandedInitValue={false}>
        <div className="flex items-center">
          <p className="text-sm textGreyE grow">Range</p>
          <ResetButton
            isVisible={
              AppDefaultState.gridRange.cartesian !==
              state.app.gridRange.cartesian
            }
            onClick={() =>
              dispatch({
                type: 'setGridRange',
                instance: 'cartesian',
                value: 'default',
              })
            }
          >
            <Select
              disabled={!state.app.visibilities.Grid}
              active={state.app.gridRange.cartesian}
              options={GRID_RANGE_OPTIONS}
              onChange={(v) => {
                dispatch({
                  type: 'setGridRange',
                  instance: 'cartesian',
                  value: v,
                });
              }}
            />
          </ResetButton>
        </div>

        <div className="flex items-center">
          <p className="text-sm textGreyE grow">Granularity</p>
          <ResetButton
            isVisible={
              AppDefaultState.gridSpacing.cartesian !==
              state.app.gridSpacing.cartesian
            }
            onClick={() =>
              dispatch({
                type: 'setGridSpacing',
                instance: 'cartesian',
                value: 'DEFAULT',
              })
            }
          >
            <Select
              disabled={!state.app.visibilities.Grid}
              active={state.app.gridSpacing.cartesian}
              options={GRID_GRANULARITY_OPTIONS}
              onChange={(v) => {
                dispatch({
                  type: 'setGridSpacing',
                  instance: 'cartesian',
                  value: v,
                });
              }}
            />
          </ResetButton>
        </div>
      </PropertiesSubGroup>
    </>
  );
};

export default CoordinateProperties;
