/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { round } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  BufferGeometry,
  DoubleSide,
  Line,
  LineDashedMaterial,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Raycaster,
  Vector2,
} from 'three';
import {
  PointerType,
  usePointerEvents,
} from '../../app/hooks/usePointerEvents';
import { COLOR_IDEAL } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import { Context } from '../../types';
import { linesIntersect } from '../../util/misc';
import { PINK } from '../../util/palettes';
import { getFromVertexPool, putToVertexPool, Vertex3JS } from '../Vertex3JS';

const rc = new Raycaster();
const plane = new Mesh(
  new PlaneBufferGeometry(10000, 10000),
  new MeshBasicMaterial({ side: DoubleSide }),
);

// Test mouse ray intersections with the ground plane
export const useVerticesEdit = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const vertices3JS = useRef<Vertex3JS[]>([]);
  const lastPosition = useRef<Vector2 | null>(null);

  const highlighted = useRef<Vertex3JS | null>(null);
  const [selectedVertex, setSelectedVertex] = useState<Vertex3JS | null>(null);
  const [intersects, setIntersects] = useState(false);

  const line = useRef<Line | null>(null);
  useEffect(() => {
    line.current = new Line(
      new BufferGeometry(),
      new LineDashedMaterial({
        color: COLOR_IDEAL,
        linewidth: 6,
        side: DoubleSide,
        dashSize: 0.9,
        gapSize: 0.1,
      }),
    );
    line.current.frustumCulled = false;
  }, []);

  useEffect(() => {
    if (!intersects || line.current === null) return;
    (line.current.material as LineDashedMaterial).color.setStyle(PINK);

    dispatch({
      type: 'setFeedbackMessage',
      value: {
        type: 'info',
        message: 'Avoid crossing edges',
      },
    });

    return () => {
      (line.current?.material as LineDashedMaterial).color.setStyle(
        COLOR_IDEAL,
      );

      dispatch({
        type: 'setFeedbackMessage',
        value: {
          type: 'info',
          message: 'Drag vertices to desired location',
        },
      });
    };
  }, [intersects]);

  useEffect(() => {
    if (selectedVertex === null) return;
    dispatch({ type: 'setViewNavigation', value: false });

    if (highlighted.current) highlighted.current.isHighlighted = false;
    highlighted.current = selectedVertex;
    selectedVertex.isHighlighted = true;
    selectedVertex.scaleFactor = 1.5;
    selectedVertex.isSelected = true;

    const { x, y } = selectedVertex.position;
    lastPosition.current = new Vector2(x, y);

    return () => {
      dispatch({ type: 'setViewNavigation', value: true });
      selectedVertex.isSelected = false;

      lastPosition.current = null;
    };
  }, [selectedVertex]);

  const cb = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (pointer: PointerType) => {
      if (pointer.eventType === 'still') return;

      rc.setFromCamera(pointer, context.viz.camera);
      const testAgainstVertices = selectedVertex === null;
      const intersections = rc.intersectObjects(
        testAgainstVertices ? vertices3JS.current : [plane],
      );

      if (testAgainstVertices) {
        if (intersections.length) {
          const v = intersections[0].object as Vertex3JS;
          if (pointer.eventType === 'pointerdown') {
            setSelectedVertex(v);
          } else if (pointer.eventType === 'pointermove') {
            highlighted.current = v;
            v.isHighlighted = true;
          }
        } else {
          // no hits
          if (highlighted.current) {
            highlighted.current.isHighlighted = false;
            highlighted.current = null;
          }
        }
      } else {
        if (line.current === null) {
          return;
        }
        // test against plane
        if (pointer.eventType === 'pointermove') {
          if (!selectedVertex || !intersections.length) return;
          const point = intersections[0].point;
          selectedVertex.position.set(
            round(point.x, Vertex3JS.PRECISION),
            round(point.y, Vertex3JS.PRECISION),
            0,
          );

          line.current.geometry.setFromPoints(
            [...vertices3JS.current, vertices3JS.current[0]].map((v) =>
              v.position.clone(),
            ),
          );
          line.current.computeLineDistances();

          // test for intersections
          const index = vertices3JS.current.indexOf(selectedVertex);
          const len = vertices3JS.current.length;
          const prev = (index + len - 1) % len;
          const next = (index + 1) % len;
          const before = vertices3JS.current.slice(0, index);
          const after = vertices3JS.current.slice(index + 1);

          const pCurrent = new Vector2(
            selectedVertex.position.x,
            selectedVertex.position.y,
          );
          const pNext = new Vector2(
            vertices3JS.current[next].position.x,
            vertices3JS.current[next].position.y,
          );
          const pBefore = new Vector2(
            vertices3JS.current[prev].position.x,
            vertices3JS.current[prev].position.y,
          );
          const points = [...after, ...before].map(
            (v) => new Vector2(v.position.x, v.position.y),
          );
          // check prev edge adjacent to vertex
          let prevIntersects = false;
          for (let i = 0; i < points.length - 2; i++) {
            const a = points[i];
            const b = points[i + 1];
            prevIntersects = linesIntersect(a, b, pBefore, pCurrent);
            if (prevIntersects) break;
          }
          // check next edge adjacent to vertex
          let nextIntersects = false;
          for (let i = 1; i < points.length - 1; i++) {
            const a = points[i];
            const b = points[i + 1];
            nextIntersects = linesIntersect(a, b, pNext, pCurrent);
            if (nextIntersects) break;
          }
          setIntersects(prevIntersects || nextIntersects);
        }
      }

      if (pointer.eventType === 'pointerup') {
        if (selectedVertex === null || line.current === null) return;

        // Handle release on invalid location, put the vertex back to its initial position
        if (intersects && lastPosition.current !== null) {
          const { x, y } = lastPosition.current;
          selectedVertex.position.set(x, y, 0);
          line.current.geometry.setFromPoints(
            [...vertices3JS.current, vertices3JS.current[0]].map((v) =>
              v.position.clone(),
            ),
          );
          setSelectedVertex(null);
          (line.current.material as LineDashedMaterial).color.setStyle(
            COLOR_IDEAL,
          );
          return;
        }

        const id = state.zones.selected;
        if (id === null) return;

        const params = state.zones.zoneRedefined[id] || state.zones.params[id];

        const points = vertices3JS.current.map(
          (v) => new Vector2(v.position.x, v.position.y),
        );
        dispatch({
          type: 'setZoneRedefined',
          id,
          value: {
            ...params,
            vertices: points,
          },
        });
        setSelectedVertex(null);
      }
    },
    [
      selectedVertex,
      state.zones.params,
      state.zones.zoneRedefined,
      state.zones.selected,
      lastPosition,
      intersects,
    ],
  );

  const setPointerEvents = usePointerEvents(context.canvas, cb);

  // update logics
  useEffect(() => {
    if (line.current === null) return;
    const id = state.zones.selected;
    if (id === null) return;
    if (!vertices3JS.current.length) return;

    const params = state.zones.zoneRedefined[id] || state.zones.params[id];
    if (params === undefined) return;

    for (let i = 0; i < params.vertices.length; i++) {
      const point = params.vertices[i];
      const vertex3JS = vertices3JS.current[i];
      vertex3JS.position.set(point.x, point.y, 0);
    }
    line.current.geometry.setFromPoints([
      ...params.vertices,
      params.vertices[0],
    ]);
    line.current.computeLineDistances();
  }, [
    state.zones.zoneRedefined,
    state.zones.selected,
    state.app.tool,
    state.zones.params,
  ]);

  // io logic
  useEffect(() => {
    if (state.app.tool !== 'ZoneEdit' || line.current === null) return;
    const id = state.zones.selected;
    if (id === null) return;
    if (state.zones.visibilities[id] === false) {
      dispatch({
        type: 'setFeedbackMessage',
        value: { type: 'warning', message: 'Make zone visible to edit' },
      });
      return;
    }

    const params = state.zones.zoneRedefined[id] || state.zones.params[id];
    if (!params.vertices.length) return;

    const zone = context.instances.Zones.all[id];
    if (zone === undefined) return;

    dispatch({
      type: 'setFeedbackMessage',
      value: {
        type: 'info',
        message:
          'Click and drag the vertices (cubes) to define the desired shape',
      },
    });
    zone.isEdited = true;

    const isNew = vertices3JS.current.length === 0;
    for (let i = 0; i < params.vertices.length; i++) {
      const point = params.vertices[i];
      const vertex3JS = isNew ? getFromVertexPool() : vertices3JS.current[i];
      vertex3JS.position.set(
        round(point.x, Vertex3JS.PRECISION),
        round(point.y, Vertex3JS.PRECISION),
        0,
      );
      if (isNew) {
        vertices3JS.current.push(vertex3JS);
        context.groups3JS.Zone.add(vertex3JS);
      }
    }
    line.current.geometry.setFromPoints([
      ...params.vertices,
      params.vertices[0],
    ]);
    line.current.computeLineDistances();
    context.groups3JS.Zone.add(line.current);

    setPointerEvents(true);

    return () => {
      zone.isEdited = false;

      for (const vertex of vertices3JS.current) {
        putToVertexPool(vertex);
        vertex.removeFromParent();
      }
      vertices3JS.current.length = 0;
      line.current?.removeFromParent();

      setPointerEvents(false);

      dispatch({
        type: 'setFeedbackMessage',
        value: {
          type: 'info',
          message:
            'Save the amends to persist the changes or reset to revert them',
        },
      });
    };
  }, [
    state.app.tool,
    state.zones.params,
    state.zones.selected,
    state.zones.visibilities,
  ]);
};
