/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Alert } from '../api/alerts';

export interface Column<T> {
  title: string;
  key: keyof T;
  class: string;
}

// Columns of Diagnostics tables.
/* Diagnostics.css grid-template-columns: must have an allocated grid cell
   for each column + 2 cells for the selector and extra info blurb. */
export const diagnosticsColumns: Column<Alert>[] = [
  {
    title: 'Alert Type',
    key: 'alertCode',
    class: 'alertCode',
  },
  {
    title: 'Message',
    key: 'message',
    class: 'message',
  },
  {
    title: 'Active Count',
    key: 'activeCount',
    class: 'activeCount',
  },
  {
    title: 'First Occured',
    key: 'firstOccured',
    class: 'alertCode',
  },
  {
    title: 'Last Occured',
    key: 'lastOccured',
    class: 'lastOccured',
  },
  {
    title: 'Level',
    key: 'level',
    class: 'level',
  },
];

export default diagnosticsColumns;
