/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { EventServerWebsocket } from '../../EventServerWebsocket';
import { Occupation } from '../../EventServerWebsocket';
import { endpoints } from '../../api/endpoints';
import { WSDataReceivedSignal, ZoneType } from '../../types';

export interface ZonesSchema {
  zones: ZoneSchema[] | null;
}

export interface Vertex {
  x: number;
  y: number;
}

// Note: inconsistency of ID type; number here, string elsewhere.
export interface ZoneSchema {
  type: ZoneType;
  id: number;
  max_height: number;
  min_height: number;
  name: string;
  vertices: Vertex[];
  metadata: string;
}

const {
  zone: {
    event: { fetchZones, addZone, setZones },
  },
} = endpoints;

/**
 * This class helps connect to the event zone server interfaces
 * This includes the REST interface for 1 time commands
 * and the streaming websocket interface for occupations
 */
export class EventZoneServerInterface {
  private ws?: EventServerWebsocket;

  /**  constructor() {}

   * Connect to to receive new zones
   * @param[out] The event zones
   */
  public onZones?: (eventZones: ZonesSchema) => void;

  /**
   * Connect to to receive new occupations
   */
  public onOccupations?: (occupations: Occupation[]) => void;

  /**
   * Fetch all zones from the event zone server
   */
  public async fetchAllZones(): Promise<void> {
    await fetchZones({
      onSuccess: async (response) => {
        const data = (await response.json()) as ZonesSchema;

        data?.zones?.forEach(function (z) {
          z.type = 'Event';
        });

        if (this.onZones) {
          this.onZones(data ?? []);
        }
      },
      onError: async (response) =>
        console.log(
          'Fetch All Zones request failed with ' + (await response.text()),
        ),
    });
  }

  /**
   * Add a single zone to the event zone server. It will overwrite any
   * zone with the same id
   * @param zone Zone to add
   */
  public addEventZone(zone: ZoneSchema): void {
    addZone(zone, {
      onError: async (response) =>
        console.log(
          'Add EventZone request failed with ' + (await response.text()),
        ),
    }).then(async () => await this.fetchAllZones()); //Get the newest zones
  }

  /**
   * Set the full event zones on the event zone server
   * @param zones Zones to set
   */
  public setZones(zones: ZonesSchema): void {
    setZones(zones, {
      onError: async (response) =>
        console.log(
          'Set EventZones request failed with ' + (await response.text()),
        ),
    }).then(async () => await this.fetchAllZones()); //Get the newest zones
  }

  /**
   * Connect to the event zone server websocket
   * @param wsUrl Ws url of the event zone server
   */
  public connectEventServerWs(
    wsUrl: string,
    dataReceivedSignal: WSDataReceivedSignal,
  ): void {
    this.ws = new EventServerWebsocket(wsUrl, dataReceivedSignal);
    this.ws.onEventZoneOccupations = (occupations: Occupation[]) => {
      if (this.onOccupations) {
        this.onOccupations(occupations);
      }
    };
  }
}
