/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { endpoints } from '../../api/endpoints';
import { SensorResponse, SensorSchema } from '../../api/sensor';
import useInterval from '../../app/hooks/useInterval';
import { SENSOR_POLL_INTERVAL } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import { ObjectEntries } from '../../util/misc';

const { sensor } = endpoints;

const usePollSensors = (): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const pollSensors = async () => {
    const allSensors: SensorResponse =
      (await sensor.all()) ??
      (await sensor.active()) ??
      ({ sensors: [] as SensorSchema[] } as SensorResponse);

    const reachableSensors = {} as Record<string, boolean>;
    const serverSensors = allSensors.sensors ?? [];

    if (serverSensors.filter((sensor) => sensor.active).length === 0) {
      // No active sources found, so clear all tracked objects and clusters
      unstable_batchedUpdates(() => {
        dispatch({
          type: 'setTrackedObjectsAndClusters',
          trackedObjectsAndClusters: [],
        });
      });
    }

    // Add / update discovered sensors
    const adaptedServerSensors = serverSensors.flatMap((sensor) => {
      reachableSensors[sensor.serial_number] = true;

      const isStale = sensor.connection_status === 'stale';

      return {
        id: sensor.serial_number,
        hostname: sensor.hostname ?? sensor.ip_address ?? '',
        ip: sensor.ip_address ?? '',
        // Stale sensors are considered active so they can be removed
        isAdded: sensor.active || isStale,
        isReachable: true,
        isNode: sensor.node_id !== undefined,
        // Non-active sensors don't have a type. PCAP sources are always active
        // so we can assume non-active sensors are of type LIVE
        type: sensor.source_type ?? 'live',
      };
    });

    unstable_batchedUpdates(() => {
      dispatch({ type: 'setSensorsDiscover', sensors: adaptedServerSensors });

      // if a sensor with a type of pcap exists set input mode to pcap
      const inputMode = adaptedServerSensors.some(
        (sensor) => sensor.type === 'pcap',
      )
        ? 'pcap'
        : 'live';
      dispatch({ type: 'setInputMode', value: inputMode });

      // TODO(Matt): Move into discover reducer or post-reducer. There's a desync issue
      // right now since we want to refer to sensors.reachableStates after the reducer.
      // * Do if performance is an issue *
      ObjectEntries(state.sensors.reachableStates).map(([id, wasReachable]) => {
        if (!wasReachable) return;
        // This sensor was reachable

        const isReachable = reachableSensors[id] !== undefined;
        if (!isReachable) {
          // We had this discovered but can longer see it.
          // If we can't see it, even if it was added previously we'll also assume it isn't
          // added anymore since we should be able to reach all added sensors.
          dispatch({ type: 'setSensorReachable', id, value: false });
          // GUI doesn't need anything in the state regarding unreachable sensors, so remove:
          dispatch({ type: 'setRemoveSensor', id });
        }
      });
    });
  };

  const [, setActive] = useInterval(pollSensors, SENSOR_POLL_INTERVAL, false);

  useEffect(() => {
    if (state.app.inputMode === 'playback') return;

    pollSensors();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode]);
};

export default usePollSensors;
