/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { endpoints } from '../../api/endpoints';
import { ZONE_ALERTS_POLL_INTERVAL } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import { ZoneAlertsResponse } from '../../api/alerts';
import useInterval from './useInterval';
import { isZoneAlertsEqual } from '../../util/misc';

const { alerts } = endpoints;

const usePollZoneAlerts = (): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const pollZoneAlerts = async () => {
    const { alerts: alertList }: ZoneAlertsResponse = (await alerts.zone()) ?? {
      alerts: [],
    };

    // Check that some alerts have changed, to avoid unnecessary re-renders
    if (
      alertList.length !== state.diagnostics.allZoneAlerts.length ||
      alertList.some(
        (alert, i) =>
          !isZoneAlertsEqual(alert, state.diagnostics.allZoneAlerts[i]),
      )
    ) {
      dispatch({ type: 'setZoneAlerts', alerts: alertList });
    }
  };

  const [, setActive] = useInterval(
    pollZoneAlerts,
    ZONE_ALERTS_POLL_INTERVAL,
    false,
  );

  useEffect(() => {
    // In the event of a critical alert, we always want to know, so
    // the only time we don't poll alerts is when we're in `playback` mode.
    if (state.app.inputMode === 'playback') return;

    pollZoneAlerts();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode, state.app.mode]);
};

export default usePollZoneAlerts;
