/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { PerceptionClassification } from '../types';
import { Vector3, Quaternion } from 'three';
import {
  MIN_POINTS_SIZE,
  MAX_POINTS_SIZE,
  DEFAULT_TRACKED_POINTS_SIZE,
} from '../constants';
import { loadFromLocalStorage, saveToLocalStorage } from '../util/localStorage';
import { HUES } from '../util/palettes';

export const LOCALSTORAGE_KEY = 'Tracked';

export type PerceptionParams = {
  points: Float32Array;
  position: Vector3;
  dimensions: Vector3;
  velocity: Vector3;
  rotation: Quaternion;
  text: string; // GUI only
  previousPositions: Float32Array;
  creationTs: number;
  updateTs: number;
  colorOffsetIndex: number;
};

export const trackedObjectLabels = [
  'position',
  'dimensions',
  'velocity',
  'heading',
  'lifetime',
  'extra-text',
] as const;
export type TrackedObjectLabels = typeof trackedObjectLabels[number];
export const velocityLabelUnits = ['km/h', 'mph', 'm/s'] as const;
export type VelocityLabelUnits = typeof velocityLabelUnits[number];
// We default to these visibilities if not in developer mode.
export const DEFAULT_TRACKED_LABEL_VISIBILITIES = {
  position: false,
  dimensions: true,
  velocity: true,
  heading: false,
  lifetime: true,
  'extra-text': false,
} as Record<TrackedObjectLabels, boolean>;
export const DEFAULT_VELOCITY_LABEL_UNITS = 'km/h' as VelocityLabelUnits;

export type TrackedObjectsSchema = {
  id: string;
  classification: PerceptionClassification;
  clusterClassification: PerceptionClassification;
  extraText: string;
  params: PerceptionParams;
};

// Component visibilities
export type TrackedGlobalVisibilities =
  | 'cloud'
  | 'labels'
  | 'rings'
  | 'previousPositions'
  | 'previousPositionsPath'
  | 'bbox'
  | 'corners';

// Booleans per instance
type BooleanPropertiesInstance = Record<
  'visible' | 'selected' | 'labelVisible' | 'highlighted',
  Record<string, boolean>
>;

export type TrackedObjectsActions =
  | {
      type: 'setTrackedObjectsAndClusters';
      trackedObjectsAndClusters: TrackedObjectsSchema[];
    }
  | {
      type: 'setClassificationVisibility';
      classification: PerceptionClassification;
      value: boolean;
    }
  | {
      type: 'setClassificationColor';
      classification: PerceptionClassification;
      colorIndex: number;
    }
  | {
      type: 'setClassificationPointSize';
      classification: PerceptionClassification;
      value: number;
    }
  | {
      type: 'setTrackedGlobalVisibility';
      property: TrackedGlobalVisibilities;
      value: boolean;
    }
  | {
      type: 'setTrackedInstanceBoolProp';
      ids: string[];
      property: keyof BooleanPropertiesInstance;
      value: 'show' | 'hide' | 'toggle';
    }
  | {
      type: 'setTrailMode';
      value: 'line' | 'points';
    }
  | {
      type: 'setTrackedVelocityLabelUnits';
      value: VelocityLabelUnits;
    }
  | {
      type: 'setTrackedLabelVisibility';
      property: TrackedObjectLabels;
      value: boolean;
    };

// tracked object type, aggregates perception parameters and application related state
export type TrackedObjectState = PerceptionParams & {
  classification: PerceptionClassification;
  clusterClassification: PerceptionClassification;
  extraText: string;
};

export type TrackedObjectsState = {
  // Global
  globalVisibilities: Record<TrackedGlobalVisibilities, boolean>;
  labelVisibilitiesByType: Record<TrackedObjectLabels, boolean>;
  velocityLabelUnits: VelocityLabelUnits;
  // Per classification
  classificationVisibilities: Record<PerceptionClassification, boolean>;
  classificationColors: Record<PerceptionClassification, number>;
  classificationPointSize: Record<PerceptionClassification, number>;
  // Per instance
  byId: Record<string, TrackedObjectState>;
  allIds: string[];
  // Dependent properties - post evaluation
  updated: string[];
  toRelease: string[];
  newEntries: string[];
} & BooleanPropertiesInstance;

export type TrackedObjectsStored = Pick<
  TrackedObjectsState,
  | 'classificationColors'
  | 'classificationPointSize'
  | 'classificationVisibilities'
  | 'globalVisibilities'
  | 'labelVisibilitiesByType'
  | 'velocityLabelUnits'
>;

const loaded = (loadFromLocalStorage(LOCALSTORAGE_KEY) ??
  {}) as TrackedObjectsStored;
export const TrackedObjectsInitialState: TrackedObjectsState = {
  globalVisibilities: {
    ...({
      rings: true,
      cloud: true,
      labels: true,
      previousPositions: false,
      previousPositionsPath: false,
      bbox: false,
      corners: true,
    } as Record<TrackedGlobalVisibilities, boolean>),
    ...loaded?.globalVisibilities,
  },
  labelVisibilitiesByType: {
    ...DEFAULT_TRACKED_LABEL_VISIBILITIES,
    ...loaded?.labelVisibilitiesByType,
  },
  velocityLabelUnits: loaded.velocityLabelUnits ?? DEFAULT_VELOCITY_LABEL_UNITS,
  classificationVisibilities: {
    ...{
      Prospect: false,
      Unknown: true,
      Person: true,
      Vehicle: true,
      LargeVehicle: true,
      Bicycle: true,
      Cluster: false,
    },
    ...loaded?.classificationVisibilities,
  },
  classificationColors: {
    ...{
      Cluster: HUES.GREEN,
      Prospect: HUES.BLUE,
      Unknown: HUES.MAGENTA,
      Person: HUES.ORANGE,
      Bicycle: HUES.TEAL,
      Vehicle: HUES.DEEP_PURPLE,
      LargeVehicle: HUES.PURPLE,
    },
    ...loaded?.classificationColors,
  },
  classificationPointSize: {
    ...{
      Prospect: DEFAULT_TRACKED_POINTS_SIZE,
      Unknown: DEFAULT_TRACKED_POINTS_SIZE,
      Person: DEFAULT_TRACKED_POINTS_SIZE,
      Vehicle: DEFAULT_TRACKED_POINTS_SIZE,
      LargeVehicle: DEFAULT_TRACKED_POINTS_SIZE,
      Bicycle: DEFAULT_TRACKED_POINTS_SIZE,
      Cluster: DEFAULT_TRACKED_POINTS_SIZE,
    },
    ...loaded?.classificationPointSize,
  },
  byId: {},
  allIds: [],
  visible: {},
  selected: {},
  highlighted: {},
  labelVisible: {},
  updated: [],
  toRelease: [],
  newEntries: [],
};

export const reducer = (
  state: TrackedObjectsState,
  action: TrackedObjectsActions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): TrackedObjectsState => {
  switch (action.type) {
    case 'setTrackedObjectsAndClusters': {
      let newState = { ...state };
      const byId: typeof TrackedObjectsInitialState.byId = {};
      const updated = [];
      const newEntries = [];

      for (const payload of action.trackedObjectsAndClusters) {
        const { id, classification, params } = payload;
        const trackedObjState: TrackedObjectState = {
          ...params,
          classification,
          clusterClassification: payload.clusterClassification,
          extraText: payload.extraText,
        };
        byId[id] = trackedObjState;
        const isNew = state.byId[id] === undefined;
        if (isNew) {
          newEntries.push(id);
        } else updated.push(id);
      }

      // update the allIds array
      newState = { ...newState, allIds: [...newEntries, ...updated], byId };

      // disable performance check till we evict clusters from store
      // update the newEntries and the boolean maps if we have new entries or previous update had new elements
      // if (newEntries.length || state.newEntries.length) {
      const visible = { ...newState.visible };
      const selected = { ...newState.selected };
      // Clear previous selected state for clusters since
      // they don't have persistent ids
      Object.keys(selected).forEach((id) => {
        if (id.startsWith('C')) {
          selected[id] = false;
        }
      });
      const highlighted = { ...newState.highlighted };
      const labelVisible = { ...newState.labelVisible };

      newEntries.forEach((id) => {
        visible[id] = true;
        selected[id] = false;
        highlighted[id] = false;
        labelVisible[id] = true;
      });

      newState = {
        ...newState,
        newEntries,
        visible,
        selected,
        highlighted,
        labelVisible,
      };
      // }
      if (updated.length || state.updated.length) {
        newState = { ...newState, updated };
      }

      // update references if some have gone
      const toRelease = state.allIds.filter((id) => byId[id] === undefined);
      if (toRelease.length || state.toRelease.length) {
        const visible = { ...newState.visible };
        const selected = { ...newState.selected };
        const highlighted = { ...newState.highlighted };
        const labelVisible = { ...newState.labelVisible };
        toRelease.forEach((id) => {
          if (visible[id] !== undefined) delete visible[id];
          if (selected[id] !== undefined) delete selected[id];
          if (highlighted[id] !== undefined) delete highlighted[id];
          if (labelVisible[id] !== undefined) delete labelVisible[id];
        });

        newState = {
          ...newState,
          toRelease,
          visible,
          selected,
          highlighted,
          labelVisible,
        };
      }
      return newState;
    }
    case 'setTrackedGlobalVisibility': {
      if (state.globalVisibilities[action.property] === action.value)
        return state;
      return {
        ...state,
        globalVisibilities: {
          ...state.globalVisibilities,
          [action.property]: action.value,
        },
      };
    }
    case 'setClassificationVisibility': {
      const classificationVisibilities = {
        ...state.classificationVisibilities,
        [action.classification]: action.value,
      };
      return { ...state, classificationVisibilities };
    }
    case 'setClassificationColor': {
      const classificationColors = {
        ...state.classificationColors,
        [action.classification]: action.colorIndex,
      };
      return { ...state, classificationColors };
    }
    case 'setClassificationPointSize': {
      const v = Math.min(
        Math.max(
          MIN_POINTS_SIZE,
          state.classificationPointSize[action.classification] + action.value,
        ),
        MAX_POINTS_SIZE,
      );
      const classificationPointSize = {
        ...state.classificationPointSize,
        [action.classification]: v,
      };
      return { ...state, classificationPointSize };
    }
    case 'setTrackedInstanceBoolProp': {
      let needsUpdate = action.value === 'toggle';
      const prop = action.property;
      const updatedProp = { ...state[prop] };
      for (const id of action.ids) {
        // eslint-disable-next-line sonarjs/no-nested-switch
        switch (action.value) {
          case 'show': {
            const isValueSame = state[prop][id] === true;
            if (isValueSame) continue;
            needsUpdate = true;
            updatedProp[id] = true;
            break;
          }
          case 'hide': {
            const isValueSame = state[prop][id] === false;
            if (isValueSame) continue;
            needsUpdate = true;
            updatedProp[id] = false;
            break;
          }
          case 'toggle': {
            updatedProp[id] = !state[prop][id];
            break;
          }
        }
      }

      return needsUpdate
        ? {
            ...state,
            [prop]: updatedProp,
            newEntries: [],
            toRelease: [],
            updated: action.ids, // Make the changed ids available to the hooks
            // TODO(emmanuel): reflect concept to other stores
          }
        : state;
    }

    case 'setTrackedLabelVisibility': {
      if (state.labelVisibilitiesByType[action.property] === action.value)
        return state;
      return {
        ...state,
        labelVisibilitiesByType: {
          ...state.labelVisibilitiesByType,
          [action.property]: action.value,
        },
      };
    }

    case 'setTrackedVelocityLabelUnits': {
      if (state.velocityLabelUnits === action.value) return state;
      return {
        ...state,
        velocityLabelUnits: action.value,
      };
    }

    default:
      return state;
  }
};

const triggerSaveSet: Set<TrackedObjectsActions['type']> = new Set([
  'setClassificationVisibility',
  'setClassificationColor',
  'setClassificationPointSize',
  'setTrackedGlobalVisibility',
  'setTrackedLabelVisibility',
  'setTrackedVelocityLabelUnits',
]);
export const TrackedObjectsReducer = (
  state: TrackedObjectsState,
  action: TrackedObjectsActions,
): TrackedObjectsState => {
  const newState = reducer(state, action);

  const stored: TrackedObjectsStored = {
    classificationColors: newState.classificationColors,
    classificationPointSize: newState.classificationPointSize,
    classificationVisibilities: newState.classificationVisibilities,
    globalVisibilities: newState.globalVisibilities,
    labelVisibilitiesByType: newState.labelVisibilitiesByType,
    velocityLabelUnits: newState.velocityLabelUnits,
  };
  if (triggerSaveSet.has(action.type))
    saveToLocalStorage(LOCALSTORAGE_KEY, stored);

  return newState;
};
