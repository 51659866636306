/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
import { ExternalLinkIcon } from '../ExternalLinkIcon';

const paths = {
  CheckmarkInCircle:
    'M17.5 28.375 29.792 16l-2.959-3.042-9.333 9.334-4.542-4.5-3 3.041Zm2.5 9.5q-3.708 0-6.979-1.375t-5.709-3.812Q4.875 30.25 3.5 26.979 2.125 23.708 2.125 20T3.5 13.021Q4.875 9.75 7.312 7.312q2.438-2.437 5.709-3.833Q16.292 2.083 20 2.083t6.979 1.396q3.271 1.396 5.709 3.833 2.437 2.438 3.833 5.709 1.396 3.271 1.396 6.979t-1.396 6.979q-1.396 3.271-3.833 5.709-2.438 2.437-5.709 3.812-3.271 1.375-6.979 1.375Z',
  CrossInCircle:
    'M14.208 28.167 20 22.375l5.792 5.792 2.375-2.375L22.375 20l5.792-5.792-2.375-2.375L20 17.625l-5.792-5.792-2.375 2.375L17.625 20l-5.792 5.792ZM20 37.875q-3.708 0-6.979-1.375t-5.709-3.812Q4.875 30.25 3.5 26.979 2.125 23.708 2.125 20T3.5 13.021Q4.875 9.75 7.312 7.312q2.438-2.437 5.709-3.833Q16.292 2.083 20 2.083t6.979 1.396q3.271 1.396 5.709 3.833 2.437 2.438 3.833 5.709 1.396 3.271 1.396 6.979t-1.396 6.979q-1.396 3.271-3.833 5.709-2.438 2.437-5.709 3.812-3.271 1.375-6.979 1.375Z',
  FloppyDisk:
    'M3.792 36.208V3.75h25.125l7.333 7.333v25.125ZM20 29.458q1.875 0 3.229-1.333t1.354-3.208q0-1.917-1.333-3.25-1.333-1.334-3.25-1.334-1.875 0-3.229 1.313-1.354 1.312-1.354 3.229t1.333 3.25q1.333 1.333 3.25 1.333Zm-9.792-13.041h15v-6.209h-15Z',
  Paste:
    'M20 7.75q.667 0 1.167-.479t.5-1.188q0-.666-.5-1.166-.5-.5-1.167-.5t-1.167.5q-.5.5-.5 1.166 0 .709.5 1.188T20 7.75ZM3.792 36.375v-32.5h11q.541-1.542 1.958-2.542 1.417-1 3.25-1 1.792 0 3.208 1 1.417 1 2.042 2.542h11.042v32.5Zm4.416-4.458h23.625V8.292h-2.541v4.791H10.75V8.292H8.208Z',
  Copy: 'M10.208 31.5V1.208h25.25V31.5Zm-7.291 7.208V10.083h4.458v24.25h18.917v4.375Z',
  Trashcan:
    'M6.292 36.208V9.5h-2.5V5.083h10.083V2.875h12.208v2.208h10.084V9.5h-2.5v26.708Zm8.25-7.541h3.625V12.583h-3.625Zm7.333 0H25.5V12.583h-3.625Z',
  CircularArrowWithClock:
    'M19.917 36.208q-6.667 0-11.334-4.562-4.666-4.563-4.791-11.104H8.25q.208 4.75 3.562 8 3.355 3.25 8.105 3.25 4.916 0 8.416-3.48 3.5-3.479 3.5-8.437 0-4.917-3.521-8.292-3.52-3.375-8.395-3.375-2.5 0-4.73 1-2.229 1-3.854 2.792h3.5v3.458H4.667V5.375h3.291v3.708q2.292-2.5 5.396-3.916 3.104-1.417 6.563-1.417 3.333 0 6.312 1.292 2.979 1.291 5.188 3.479 2.208 2.187 3.5 5.125 1.291 2.937 1.291 6.312 0 3.375-1.291 6.334-1.292 2.958-3.5 5.166-2.209 2.209-5.188 3.48-2.979 1.27-6.312 1.27Zm4.875-9-6.459-6.375v-9.291h3.375v7.833l5.5 5.375Z',
  Upload:
    'M22.5 32.35V13.8l-6 6-2.15-2.15L24 8l9.65 9.65-2.15 2.15-6-6v18.55ZM8 40V29.85h3V37h26v-7.15h3V40Z',
  Download:
    'm24 32.35-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15ZM8 40V29.85h3V37h26v-7.15h3V40Z',
};
export type ActionIcon =
  | 'Save'
  | 'Copy'
  | 'Paste'
  | 'Delete'
  | 'Reset'
  | 'Upload'
  | 'Download'
  | 'SelectAll'
  | 'DeselectAll'
  | 'ExternalLink';

const mapPaths: Record<ActionIcon, string> = {
  Save: paths.FloppyDisk,
  Paste: paths.Paste,
  Copy: paths.Copy,
  Delete: paths.Trashcan,
  Reset: paths.CircularArrowWithClock,
  Upload: paths.Upload,
  Download: paths.Download,
  SelectAll: paths.CheckmarkInCircle,
  DeselectAll: paths.CrossInCircle,
  ExternalLink: 'External Link',
};

const VIEWBOX = 40;
export const ActionIcons = ({
  icon,
  active,
  size = 40,
  ...rest
}: {
  icon: ActionIcon;
  active: boolean;
  size?: number;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <>
      {mapPaths[icon] === 'External Link' ? (
        <ExternalLinkIcon active={active} />
      ) : (
        <svg
          width={`${size}px`}
          height={`${size}px`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${VIEWBOX} ${VIEWBOX}`}
          className={`ActionIcons ${active ? 'active' : ''}`}
          {...rest}
        >
          <path d={`M0,0h40v40H0V0z`} fill="none" />
          {
            {
              Save: <path d={mapPaths.Save} />,
              Copy: <path d={mapPaths.Copy} />,
              Paste: <path d={mapPaths.Paste} />,
              Reset: <path d={mapPaths.Reset} />,
              Delete: <path d={mapPaths.Delete} />,
              // Ad-hoc Center
              Upload: (
                <path transform={`translate(-2.5, -1.5)`} d={mapPaths.Upload} />
              ),
              Download: <path d={mapPaths.Download} />,
              SelectAll: <path d={mapPaths.SelectAll} />,
              DeselectAll: <path d={mapPaths.DeselectAll} />,
              ExternalLink: <ExternalLinkIcon active={active} />,
            }[icon]
          }
        </svg>
      )}
    </>
  );
};
