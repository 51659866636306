/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { last } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppState } from '../../Stores';

const DEFAULT_FEEDBACK_MSG_DURATION = 1000;
/**
 * Implements the feedback message queue update logic.
 *
 * @brief There are two message classes: queued messages (with a duration)
 * and static ones. Static messages are only shown once the message queue
 * is empty.
 *
 * The message queue follows a non-preemptive priority scheme with
 * higher priorities being priotized (see enqueue and pop FeedbackMessage
 * in AppStore.ts).
 *
 * For example: messageA enters the queue with priority 1
 * and duration 2s. Immediately after messageA is displayed, messageB
 * enters the queue with priority 2 and then messageC with priority 10.
 * MessageA will remain for its duration of 2s. Afterwards, messageC
 * will be displayed for its allocated duration then messageB.
 */
const useFeedbackMessageQueue = (): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    const queue = state.app.feedbackMessage.queue;
    const poppedMsg = last(queue);
    // Try to unlock, otherwise another message's duration isn't done
    if (
      poppedMsg === undefined &&
      state.app.feedbackMessage.fromQueue === null
    ) {
      return;
    }
    // This predicate will need to be edited to allow
    // preemption + a decision on what to do with the
    // current msg (requeue or drop).
    if (isLocked) return;
    // console.log('[DEBUG] Got in feedbackMsg lock');

    setIsLocked(true);
    dispatch({
      type: 'popFeedbackMessage',
    });
    setTimeout(() => {
      // Refire useEffect in case another message is waiting
      // console.log('[DEBUG] Unlocked feedbackMsg lock');
      setIsLocked(false);
    }, poppedMsg?.durationMs ?? DEFAULT_FEEDBACK_MSG_DURATION);
  }, [
    state.app.feedbackMessage.queue,
    state.app.feedbackMessage.fromQueue,
    isLocked,
  ]);
};

export default useFeedbackMessageQueue;
