/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef } from 'react';
import useInterval from '../../app/hooks/useInterval';
import { SECONDS_TO_MS } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import { HTTPServers, WebSockets } from '../../types';
import { reachabilityRequest } from './reachabilityRequests';
import { ReachabilitySchema } from './ServiceStore';
const SERVICE_REACHABILITY_TIMEOUT = 10 * SECONDS_TO_MS;

const updateHTTPReachabilities = (reachabilitySchema: ReachabilitySchema) =>
  Promise.allSettled(
    HTTPServers.map(
      async (service) =>
        (reachabilitySchema[service] = await reachabilityRequest[service]()),
    ),
  );

const useServiceReachability = (): ((wsHandle: WebSockets) => void) => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const websocketSignalFlags = useRef<Set<WebSockets> | null>(null);
  useEffect(() => {
    websocketSignalFlags.current = new Set<WebSockets>();
  }, []);

  const updateReachabilities = async () => {
    const reachabilitySchema = {} as ReachabilitySchema;
    await updateHTTPReachabilities(reachabilitySchema);

    dispatch({
      type: 'setServiceReachabilities',
      reachabilites: reachabilitySchema,
    });
  };

  const [, setActive] = useInterval(
    updateReachabilities,
    SERVICE_REACHABILITY_TIMEOUT,
    false,
  );

  useEffect(() => {
    if (state.app.inputMode === 'playback') return;

    updateReachabilities();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode]);

  // Signals data received from the specified websocket
  return (wsHandle: WebSockets) => {
    websocketSignalFlags.current?.add(wsHandle);
  };
};

export default useServiceReachability;
