/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from '../app/components/Button';
import './RecordButton.css';

const SVG_SIZE = 24; // px
const RecordButton = ({
  isRecording,
  isLoading,
  onClick,
}: {
  isRecording: boolean;
  isLoading: boolean;
  onClick: () => void;
}): JSX.Element => {
  return (
    <div>
      <Button
        className="bgNone"
        tooltip={{
          text: isLoading
            ? 'Loading...'
            : `${isRecording ? 'Stop' : 'Start'} Recording`,
          offset: 'right',
        }}
        onClick={onClick}
      >
        <div
          className="flex flex-center"
          style={{
            width: `${SVG_SIZE}px`,
            height: `${SVG_SIZE}px`,
          }}
        >
          <div className={`button ${isRecording ? 'active' : ''}`}>
            <div className="inner"></div>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default RecordButton;
