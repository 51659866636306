/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-nested-switch */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  DoubleSide,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Quaternion,
  Vector3,
} from 'three';
import { ORIGIN, UNIT_X, UNIT_Y, UNIT_Z } from '../constants';
import { Axis, Plane, Transform, Space } from '../types';

// Reusable utility variables
const _alignVector = new Vector3(0, 1, 0);
const _identityQuaternion = new Quaternion();
const _dirVector = new Vector3();
const _tempVector = new Vector3();
const _tempMatrix = new Matrix4();

const _v1 = new Vector3();
const _v2 = new Vector3();
const _v3 = new Vector3();

export class TransformControlsPlane extends Mesh {
  space: Space = 'local';
  transform: Transform = 'translate';
  worldQuaternion: Quaternion = new Quaternion();
  cameraQuaternion: Quaternion = new Quaternion();
  worldPosition = new Vector3();
  eye = new Vector3();
  axis: Axis | Plane | null = null;
  readonly TransformControlsPlane = true;

  constructor() {
    super(
      new PlaneGeometry(100000, 100000, 2, 2),
      new MeshBasicMaterial({
        visible: false,
        wireframe: true,
        side: DoubleSide,
        transparent: true,
        opacity: 0.1,
        toneMapped: false,
      }),
    );

    this.type = 'TransformControlsPlane';
    this.name = 'TransformControlsPlane';
  }

  updateMatrixWorld(force: boolean) {
    let space = this.space;

    this.position.copy(this.worldPosition);

    if (this.transform === 'scale') space = 'local'; // scale always oriented to local rotation

    _v1
      .copy(UNIT_X)
      .applyQuaternion(
        space === 'local' ? this.worldQuaternion : _identityQuaternion,
      );
    _v2
      .copy(UNIT_Y)
      .applyQuaternion(
        space === 'local' ? this.worldQuaternion : _identityQuaternion,
      );
    _v3
      .copy(UNIT_Z)
      .applyQuaternion(
        space === 'local' ? this.worldQuaternion : _identityQuaternion,
      );

    // Align the plane for current transform mode, axis and space.
    _alignVector.copy(_v2);
    switch (this.transform) {
      case 'translate':
      case 'scale':
        switch (this.axis) {
          case 'x':
            _alignVector.copy(this.eye).cross(_v1);
            _dirVector.copy(_v1).cross(_alignVector);
            break;
          case 'y':
            _alignVector.copy(this.eye).cross(_v2);
            _dirVector.copy(_v2).cross(_alignVector);
            break;
          case 'z':
            _alignVector.copy(this.eye).cross(_v3);
            _dirVector.copy(_v3).cross(_alignVector);
            break;
          case 'xy':
            _dirVector.copy(_v3);
            break;
          case 'yz':
            _dirVector.copy(_v1);
            break;
          case 'xz':
            _alignVector.copy(_v3);
            _dirVector.copy(_v2);
            break;
          // case 'xyz':
          case 'view':
            _dirVector.copy(ORIGIN);
            break;
        }

        break;
      case 'rotate':
        switch (this.axis) {
          case 'x':
            _dirVector.copy(UNIT_X);
            _alignVector.copy(UNIT_Y);
            break;
          case 'y':
            _dirVector.copy(UNIT_Y);
            _alignVector.copy(UNIT_Z);
            break;
          case 'z':
            _dirVector.copy(UNIT_Z);
            _alignVector.copy(UNIT_X);
            break;
          case 'view':
            _dirVector.copy(ORIGIN);
            break;
        }
        break;
      default:
        _dirVector.copy(ORIGIN);
    }

    if (this.axis === 'view') {
      // make the plane parallel to the view
      this.quaternion.copy(this.cameraQuaternion);
    } else {
      _tempMatrix.lookAt(_tempVector.copy(ORIGIN), _dirVector, _alignVector);
      this.quaternion.setFromRotationMatrix(_tempMatrix);
    }

    super.updateMatrixWorld(force);
  }
}
