/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { WSDataReceivedSignal } from './types';

export interface ObjectInfo {
  id: number;
  x: number;
  y: number;
  z: number;
}

export interface Occupation {
  id: number;
  name: string;
  num_objects: number;
  objects: ObjectInfo[];
}

export interface Occupations {
  occupations: Occupation[];
}

/**
 * This class encapsulates the extra logic for connecting to the event_server websocket.
 */
class EventServerWebsocket {
  public onEventZoneOccupations?: (occupations: Occupation[]) => void;

  ws?: WebSocket;
  url: string;
  dataReceivedSignal: WSDataReceivedSignal;
  decoder: TextDecoder;
  name = 'eventWs' as const;

  constructor(url: string, dataReceivedSignal: WSDataReceivedSignal) {
    this.url = url;
    this.connect();
    this.decoder = new TextDecoder('utf-8');
    this.dataReceivedSignal = dataReceivedSignal;
  }

  /**
   * @brief Connects to a websocket server and sets up handlers
   */
  connect(): void {
    this.ws = new WebSocket(this.url);
    this.ws.binaryType = 'arraybuffer';

    console.log('Trying to connect to ' + this.url);
    this.ws.onopen = () => {
      console.log('Connected to ' + this.url);
    };

    this.ws.onmessage = (message) => {
      this.dataReceivedSignal(this.name);
      const string_message = this.decoder.decode(message.data);

      const json_obj = JSON.parse(string_message);
      const occupationsMessage: Occupations = json_obj;

      if (this.onEventZoneOccupations === undefined) {
        return;
      }

      this.onEventZoneOccupations(occupationsMessage.occupations ?? []);
    };

    this.ws.onclose = (event) => {
      console.log(
        'Socket is closed. Reconnect will be attempted in 1 second.',
        event.reason,
      );
      setTimeout(() => {
        this.connect();
      }, 1000);
    };
  }

  /**
   * Sends data over the websocket if it is connected
   * @param message Message to send over the websocket
   */
  send(message: string): void {
    if (this.ws != undefined && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(message);
    }
  }
}

export { EventServerWebsocket };
