/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useRef, useState } from 'react';
import CSS from 'csstype';
import Tooltip from '../tooltip/Tooltip';

const Color = ({
  color,
  setColor,
}: {
  color: CSS.Property.BackgroundColor;
  setColor: (color: string) => void;
}) => {
  return (
    <label
      style={{
        padding: '4px',
      }}
    >
      <button
        onClick={() => {
          setColor(color);
        }}
        style={{
          display: 'block',
          width: '.75rem',
          height: '.75rem',
          padding: '0.1rem',
          border: 'solid var(--greyE) 1px',
          backgroundColor: color,
          cursor: 'pointer',
        }}
      ></button>
    </label>
  );
};

export const ColorPresetPicker = ({
  initial,
  onChange,
  colors,
  side,
}: {
  initial: string;
  onChange: (colorIndex: number) => void;
  colors: string[];
  side: 'left' | 'right';
}): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Tooltip tooltipText="Set Color" offset="top" closeOnClick>
      <div style={{ position: 'relative' }}>
        <label className="p-1">
          <button
            style={{
              width: '.75rem',
              height: '.75rem',
              backgroundColor: initial,
              cursor: 'pointer',
              borderRadius: '50%',
              border: 'none',
              padding: 0,
            }}
            value={initial}
            onClick={() => {
              setShow((v) => !v);
            }}
          ></button>
        </label>
        <div
          className="drop-shadow-lg px-3 py-2 "
          style={{
            display: show ? 'flex' : 'none',
            flexWrap: 'wrap',
            backgroundColor: 'var(--greyH)',
            width: '17rem',
            position: 'absolute',
            left: side === 'right' ? '0.25rem' : 'unset',
            right: side === 'left' ? '0.25rem' : 'unset',
            bottom: '-50%',
            border: 'solid 2px var(--greyH)',
            zIndex: 5,
          }}
          ref={ref}
          onPointerLeave={() => setShow(false)}
        >
          {colors.map((value, i) => (
            <Color
              key={`${i}-${value}`}
              color={value}
              setColor={(color) => {
                setShow(false);
                onChange(colors.indexOf(color));
              }}
            />
          ))}
        </div>
      </div>
    </Tooltip>
  );
};
