/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppState } from '../Stores';
import { Context, PointDescriptorsInUse } from '../types';
import { Palettes, PRESET_RANGES } from './../constants';
import { Pointcloud3JS } from '../threejs/Pointcloud3JS';
import { Preset } from '../app/components/pane/cloudsDisplay/CloudColorPresets';
import { Quaternion } from 'three';

export const useProcessedCloudData = (context: Context): void => {
  const state = useAppState();
  const palette = Palettes[state.app.palette];

  useEffect(() => {
    for (const descriptor of PointDescriptorsInUse) {
      for (let i = 0; i < state.clouds[descriptor].allIds.length; i++) {
        const id = state.clouds[descriptor].allIds[i];

        // All these clouds should be sensor clouds, not node clouds.
        // Node clouds are in state.nodes.clouds and dealt with elsewhere.
        if (!state.sensors.reachableStates[id]) continue;

        let cloud = context.instances.ProcessedCloud.all[descriptor][id];
        if (!cloud) {
          cloud = new Pointcloud3JS(true);
          cloud.updateBoundingVolumes = false;
          cloud.frustumCulled = false;
          cloud.name = id;
          const { colorMode, colorMin, colorMax } =
            state.clouds[descriptor].display;
          const { pointSize } = state.clouds[descriptor];
          const heightMin = state.clouds.Background.display.heightMin;
          const heightMax = state.clouds.Background.display.heightMax;
          const { min, max } = PRESET_RANGES[colorMode];
          const preset: Preset = {
            name: '',
            colorMin: palette[colorMin[id]],
            colorMax: palette[colorMax[id]],
            min,
            max,
          };
          cloud.setColorMode(colorMode);
          cloud.setPreset(preset);
          cloud.setHeightRange({ min: heightMin, max: heightMax });
          cloud.setPointSize(pointSize[id]);
          const g = context.groups3JS.ProcessedCloud;
          g.add(cloud);
          context.instances.ProcessedCloud.all[descriptor][id] = cloud;
        }
        const {
          translation,
          quaternionRotation,
          points,
          signal,
          reflectivity,
          nearir,
        } = state.clouds[descriptor].params[id];

        cloud.position.fromArray(translation);
        const [w, x, y, z] = quaternionRotation;
        cloud.setRotationFromQuaternion(new Quaternion(x, y, z, w));

        cloud.setAttributes(points, signal, reflectivity, nearir);
      }
    }
  }, [
    state.clouds.Background.display.heightMin,
    state.clouds.Background.display.heightMax,
    state.clouds.Background.params,
    state.clouds.Foreground.params,
    state.clouds.Ground.params,
    state.app.palette,
  ]);
};
