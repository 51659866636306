/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { REST, SECONDS_TO_MS, SENSOR_POLL_INTERVAL } from '../constants';
import { safeFetch } from '../safeFetch';
import { SensorType } from '../source/SensorStore';

export interface SensorSchema {
  firmware_version?: string;
  fw_rev: string;
  hostname?: string;
  ip_address?: string;
  name: string;
  product_number?: string;
  product_line: string;
  serial_number: string;
  active: boolean;
  connection_status?: string;
  source_type: SensorType | null;
  node_id?: string;
}
export interface SensorResponse {
  sensors: SensorSchema[];
  error: { message: string } | null;
}

export const getAllSensors = async (
  timeout = SENSOR_POLL_INTERVAL,
): Promise<SensorResponse | null> =>
  safeFetch.json.get<SensorResponse>(REST.sensor.all, { timeout });

const DEFAULT_SENSOR_MUTATION_TIMEOUT = 120 * SECONDS_TO_MS;
export const addSensor = async (
  hostname: string,
  abortSignal: AbortSignal,
  onSuccess: (response: Response) => void,
  onError: (response: Response) => void,
  onNetworkError: () => void,
  timeout = DEFAULT_SENSOR_MUTATION_TIMEOUT,
): Promise<string | null> =>
  safeFetch.text.put<string>(REST.sensor.add(hostname), undefined, {
    timeout,
    signal: abortSignal,
    onSuccess,
    onError,
    onNetworkError,
  });

export const removeSensor = async (
  serialNumber: string,
  timeout = DEFAULT_SENSOR_MUTATION_TIMEOUT,
): Promise<SensorResponse | null> =>
  safeFetch.text.delete<SensorResponse>(REST.sensor.remove(serialNumber), {
    timeout,
  });

export const getByStatus = async (
  status: 'active' | 'inactive',
  timeout = SENSOR_POLL_INTERVAL,
): Promise<SensorResponse | null> =>
  safeFetch.json.get<SensorResponse>(REST.sensor.getByStatus(status), {
    timeout,
  });

export const sensor = {
  all: getAllSensors,
  add: addSensor,
  remove: removeSensor,
  active: () => getByStatus('active'),
  inactive: () => getByStatus('inactive'),
} as const;
