/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useState } from 'react';
import { useAppState } from '../../../Stores';
import Tooltip from '../tooltip/Tooltip';

export const FrameNumber = (): JSX.Element | null => {
  const state = useAppState();
  const [frameOffset, setFrameOffset] = useState(0);
  const [cloudIdx, setCloudIdx] = useState(0);
  const cloudIds = state.clouds.Background.allIds.filter(
    (id) => state.sensors.addedStates[id],
  );

  const setCloudIdxCircular = () => {
    setCloudIdx((cloudIdx + 1) % cloudIds.length);
  };

  // Account for being idx > 0 for some cloud
  // and it suddenly becomes unavailable.
  const cloudId = cloudIds[cloudIdx] ?? cloudIds[0];
  useEffect(() => {
    if (!state.clouds.Background.params[cloudId] && cloudIdx !== 0) {
      setCloudIdx(0);
    }
  }, [state.sensors.addedStates]);

  useEffect(() => {
    // Set state functionally to avoid setting
    // the frame offset as a dependency. We only
    // want this triggered when we receive objects
    setFrameOffset((frameOffset) => frameOffset + 1);
  }, [
    state.tracked.newEntries,
    state.tracked.updated,
    state.tracked.toRelease,
  ]);

  useEffect(() => {
    setFrameOffset(0);
  }, [state.clouds.Background.params]);

  return cloudId !== undefined && state.app.isFrameNumberVisible ? (
    <div style={{ position: 'absolute', left: '5px', bottom: '25px' }}>
      <Tooltip tooltipText={`Cloud ID: ${cloudId}`} offset="right">
        <p
          id="Time"
          className="text-base textGreyD"
          style={{
            fontWeight: 100,
            userSelect: 'none',
          }}
          onClick={() => setCloudIdxCircular()}
        >
          {state.clouds.Background.params[cloudId].frame + frameOffset}
        </p>
      </Tooltip>
    </div>
  ) : null;
};
