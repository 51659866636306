/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import LoadingSpinner from '../../app/components/LoadingSpinner/LoadingSpinner';
import { ListContainer } from '../../app/components/pane/ListContainer';
import { SelectableListElem } from '../../app/components/pane/SelectableListElem';
import { useAppState } from '../../Stores';
import { NodeState } from '../NodeStore';
import { unixToString } from '../../util/misc';
import { SECONDS_TO_MS } from '../../constants';

interface SourceLicenseInfo {
  nodeLicensesInUse: number;
  nodeLicensedTotal: number;
  nodeLicenseExpirationTime: number;
}

// Licenses only apply for nodes currently
const getNodeLicenseInfo = (id: string, sources: NodeState) => {
  const { licensesExpirationTimes, licensesInUse, licensesTotal } = sources;
  const [nodeLicensesInUse, nodeLicensedTotal, nodeLicenseExpirationTime] = [
    licensesInUse[id],
    licensesTotal[id],
    licensesExpirationTimes[id],
  ];
  const isLicensed = !(
    nodeLicensedTotal === 0 && nodeLicenseExpirationTime <= 0
  );

  return {
    isLicensed,
    sourceLicenseInfo: {
      nodeLicensesInUse,
      nodeLicensedTotal,
      nodeLicenseExpirationTime,
    },
  };
};

export const SourceSelect = ({
  sourceIds,
  selectedSourceId,
  setSelectedSourceId,
  isRequestLoadingById,
}: {
  sourceIds: string[];
  selectedSourceId: string | null;
  setSelectedSourceId: (id: string | null) => void;
  isRequestLoadingById: Record<string, boolean>;
}): JSX.Element => {
  const state = useAppState();

  return (
    <ListContainer>
      {sourceIds.map((id) => {
        // Using node.allIds for isSensor because sensor.allIds doesn't consistently
        // contain sensor ids instead of hostnames or ips.
        const isSensor = !state.nodes.allIds.includes(id);
        const sources = isSensor ? state.sensors : state.nodes;
        // Sensors aren't tied to licenses (so treat sensor sources as licensed by default)
        const { isLicensed, sourceLicenseInfo = {} as SourceLicenseInfo } =
          isSensor
            ? { isLicensed: true }
            : getNodeLicenseInfo(id, sources as NodeState);

        if (isSensor && state.sensors.isNodes[id]) {
          return null;
        }

        const isAdded = sources.addedStates[id];
        const isSetup = sources.allIds.includes(id);
        const isSelected = id === selectedSourceId;

        const alerts = Object.values(state.diagnostics.allAlerts);
        const hasCriticalAlert =
          alerts.findIndex(
            (alert) =>
              alert.active &&
              alert.sourceInfo === id &&
              alert.level === 'CRITICAL',
          ) !== -1;

        let message = isSensor ? 'Sensor' : 'Node';
        let color = !isLicensed ? 'var(--warning)' : 'var(--greyC)';
        let isWarning = false;

        if (!isAdded) {
          message += ' not added';
          color = 'var(--greyE)';
        } else if (!isSetup) {
          message += ' not connected';
          color = 'var(--error)';
          isWarning = true;
        } else if (hasCriticalAlert) {
          message += ' has active critical alert';
          color = 'var(--warning)';
          isWarning = true;
        } else {
          message += ' added';
        }

        const nodeLicenseBlurb = (sourceLicenseInfo: {
          isLicensed: boolean;
          nodeLicensesInUse: number;
          nodeLicensedTotal: number;
          nodeLicenseExpirationTime: number;
        }) => {
          const {
            isLicensed,
            nodeLicensesInUse,
            nodeLicensedTotal,
            nodeLicenseExpirationTime,
          } = sourceLicenseInfo;
          const expirationText =
            nodeLicenseExpirationTime === -1
              ? 'None'
              : unixToString(nodeLicenseExpirationTime * SECONDS_TO_MS, {
                  year: true,
                  time: false,
                });

          const blurbText = !isLicensed
            ? 'Not Licensed'
            : `${nodeLicensesInUse}/${nodeLicensedTotal} in use (Expiry: ${expirationText})`;
          const licenseInfoTextColorClass = !isLicensed
            ? 'textWarning'
            : 'textGreyD';

          return (
            <>
              <br />
              <abbr className={licenseInfoTextColorClass}>
                <h6 className={`text-xs ${licenseInfoTextColorClass}`}>
                  License Info
                </h6>
                <br />
                {blurbText}
              </abbr>
            </>
          );
        };

        const sourceInfoBlurb = (id: string) => {
          return (
            <div className="ml-3 text-xs textGreyD">
              <p>
                {`${
                  isSensor ? state.sensors.types[id].toUpperCase() : 'LIVE'
                } `}
                <abbr
                  className="enabled cursor-pointer"
                  title="Copy Hostname"
                  onClick={() => {
                    navigator.clipboard.writeText(sources.hostnames[id]);
                  }}
                >
                  {sources.hostnames[id]}
                </abbr>
                {` `}
                {sources.ips[id] && (
                  <abbr
                    className="enabled cursor-pointer"
                    title="Copy IP Address"
                    onClick={() => {
                      navigator.clipboard.writeText(sources.ips[id]);
                    }}
                  >
                    {`(${sources.ips[id]})`}
                  </abbr>
                )}
                {!isSensor &&
                  nodeLicenseBlurb({ ...sourceLicenseInfo, isLicensed })}
              </p>
            </div>
          );
        };

        return (
          <div key={id} className="flex flex-col justify-between py-1">
            <div className="flex items-center">
              {isRequestLoadingById[id] && (
                <div className="mr-1">
                  <LoadingSpinner scale={0.6} />
                </div>
              )}

              <SelectableListElem
                disabled={isRequestLoadingById[id]}
                active={isAdded}
                isSelected={isSelected}
                onClick={() => {
                  setSelectedSourceId(isSelected ? null : id);
                }}
                color={color}
                tooltip={{ offset: 'bottom', text: message }}
              >
                {isWarning && '!'}{' '}
                <span style={{ fontSize: 'var(--r4)' }}>
                  {isSensor ? '❂' : '⚛'}&nbsp;
                </span>
                {id}
              </SelectableListElem>
            </div>
            {id === selectedSourceId && (
              <div className="text-xs textGreyD mb1">{sourceInfoBlurb(id)}</div>
            )}
          </div>
        );
      })}
    </ListContainer>
  );
};
