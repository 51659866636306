/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef } from 'react';
import { Color } from 'three';
import {
  COLOR_HIGHLIGHTED,
  COLOR_SELECTED,
  MAX_SENSOR_RESOLUTION,
  Palettes,
  paletteVariationsRBG,
} from '../constants';
import { useAppState } from '../Stores';
import { Context } from '../types';
import { hexToRgb } from '../util/color';
import { TrackedObjectsPointcloud3JS } from './TrackedObjectsPointcloud3JS';

const colorSelected = hexToRgb(
  `#${new Color(COLOR_SELECTED).getHexString()}`,
).map((c) => c / 255);
const colorHighlighted = hexToRgb(
  `#${new Color(COLOR_HIGHLIGHTED).getHexString()}`,
).map((c) => c / 255);

// Logic to update the point clouds associated with each tracked object
export const useTrackedObjectsPointcloudSynch = (context: Context): void => {
  const state = useAppState();
  const data = useRef<{
    xyz: Float32Array;
    rgb: Float32Array;
    size: Float32Array;
  } | null>(null);

  const cloud = useRef<TrackedObjectsPointcloud3JS>();
  const palette = Palettes[state.app.palette];
  const paletteVariationsRGB = paletteVariationsRBG[state.app.palette];

  useEffect(() => {
    cloud.current = new TrackedObjectsPointcloud3JS();
    cloud.current.name = 'TrackedObjectsPointcloud';

    data.current = {
      xyz: new Float32Array(MAX_SENSOR_RESOLUTION * 3),
      rgb: new Float32Array(MAX_SENSOR_RESOLUTION * 3),
      size: new Float32Array(MAX_SENSOR_RESOLUTION),
    };
  }, []);

  // show/hide
  useEffect(() => {
    if (cloud.current === undefined) return;

    const isVisible =
      state.app.inputMode === 'playback' ||
      state.app.mode === 'viewer' ||
      state.app.mode === 'zone' ||
      state.app.mode === 'recording' ||
      state.app.mode === 'preferences' ||
      state.app.mode === 'map';

    isVisible && state.tracked.globalVisibilities.cloud
      ? context.viz.scene.add(cloud.current.points)
      : cloud.current.points.removeFromParent();
  }, [state.app.mode, state.tracked.globalVisibilities.cloud]);

  // update the cloud that aggregates all tracked objects clouds
  useEffect(() => {
    if (cloud.current === undefined) return;
    if (data.current === null) return;
    if (!state.tracked.globalVisibilities.cloud) return;

    // find out total visible points to send to shader
    let totalPointsXYZ = 0;
    for (let i = 0; i < state.tracked.allIds.length; i++) {
      const id = state.tracked.allIds[i];
      const { points, classification, colorOffsetIndex } =
        state.tracked.byId[id];
      if (
        !state.tracked.classificationVisibilities[classification] ||
        !state.tracked.visible[id]
      )
        continue;

      const isSelected = state.tracked.selected[id];
      const isHighlighted = state.tracked.highlighted[id] ?? false;
      const pointSize = state.tracked.classificationPointSize[classification];

      const totalPoints = totalPointsXYZ / 3;
      const thisTotalPoints = points.length / 3;
      for (let j = 0; j < thisTotalPoints; j++) {
        data.current.size[totalPoints + j] =
          isHighlighted || isSelected ? pointSize * 2 : pointSize;

        const colorIndex =
          state.tracked.classificationColors[classification] % palette.length;
        const colorRGB = isHighlighted
          ? colorHighlighted
          : isSelected
          ? colorSelected
          : paletteVariationsRGB[colorIndex][colorOffsetIndex];
        const k = (totalPoints + j) * 3;
        data.current.rgb[k + 0] = colorRGB[0];
        data.current.rgb[k + 1] = colorRGB[1];
        data.current.rgb[k + 2] = colorRGB[2];
      }

      data.current.xyz.set(points, totalPointsXYZ);

      totalPointsXYZ += points.length;
    }

    const upTo = totalPointsXYZ / 3;
    cloud.current.setAttributes(
      upTo,
      data.current.xyz.slice(0, totalPointsXYZ),
      data.current.rgb.slice(0, totalPointsXYZ),
      data.current.size.slice(0, upTo),
    );
  }, [
    state.tracked.allIds,
    state.tracked.classificationVisibilities,
    state.tracked.classificationColors,
    state.tracked.visible,
    state.tracked.classificationPointSize,
    state.tracked.highlighted,
    state.tracked.selected,
    state.app.palette,
    state.tracked.globalVisibilities.cloud,
  ]);
};
