/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';

export const EyeIcon = ({
  isOpen,
  size = '18px',
  ...rest
}: {
  isOpen: boolean;
  size?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 18 18"
      viewBox="0 0 18 18"
      fill={isOpen ? 'var(--greyB)' : 'var(--greyE)'}
      {...rest}
    >
      <path d="M0,0h24v24H0V0z" fill="none" />
      {
        {
          Open: (
            <g>
              <path d="M12.3065,4.29A7.48591,7.48591,0,0,0,9,3.4685c-4.332,0-7.875,4.3125-7.875,5.7115,0,1.5,3.729,5.35,7.843,5.35,4.15,0,7.907-3.853,7.907-5.35C16.875,8,14.768,5.5095,12.3065,4.29ZM9,13.6125A4.6125,4.6125,0,1,1,13.6125,9,4.6125,4.6125,0,0,1,9,13.6125Z" />
              <path d="M10.3335,9.0415A1.3335,1.3335,0,0,1,9,7.7085a1.316,1.316,0,0,1,.675-1.135A2.46964,2.46964,0,0,0,9,6.469a2.5315,2.5315,0,1,0,2.5315,2.5315V9a2.35682,2.35682,0,0,0-.0875-.6A1.3125,1.3125,0,0,1,10.3335,9.0415Z" />
            </g>
          ),
          Shut: (
            <path d="M 12.3065,4.29 C 11.282126,3.765661 10.150658,3.4845478 9,3.4685 4.668,3.4685 1.125,7.781 1.125,9.18 c 0,1.5 3.729,5.35 7.843,5.35 4.15,0 7.907,-3.853 7.907,-5.35 0,-1.18 -2.107,-3.6705 -4.5685,-4.89 z" />
          ),
        }[isOpen ? 'Open' : 'Shut']
      }
    </svg>
  );
};
