/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useAppDispatch, useAppState } from '../../../Stores';
import { Button } from '../Button';
import { VisibilityIcon } from './VisibilityIcon';
import { GlobalVisibility } from '../../../types';
import { ZoneGlobalVisibilities } from '../../../zone/ZoneStore';
import { TrackedGlobalVisibilities } from '../../../trackedObjects/TrackedObjectsStore';
import { VisibilitiesCluster } from './VisibilityTypes';
import { getTip } from './tooltipText';

export const VisibilityButton = ({
  cluster,
  icon,
  isActive,
  disabled = false,
  size,
}: {
  cluster: VisibilitiesCluster;
  icon: GlobalVisibility | ZoneGlobalVisibilities | TrackedGlobalVisibilities;
  isActive: boolean;
  disabled?: boolean;
  size?: string;
}): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  return (
    <Button
      className="bgNone borderNone p-2"
      disabled={disabled}
      onClick={() => {
        switch (cluster) {
          case 'GlobalVis': {
            const instance = icon as GlobalVisibility;
            dispatch({
              type: 'setGlobalVisibility',
              instance,
              value: !state.app.visibilities[instance],
            });
            break;
          }
          case 'Tracked': {
            const property = icon as TrackedGlobalVisibilities;
            dispatch({
              type: 'setTrackedGlobalVisibility',
              property,
              value: !state.tracked.globalVisibilities[property],
            });
            break;
          }
          case 'Zones': {
            const property = icon as ZoneGlobalVisibilities;
            dispatch({
              type: 'setZoneGlobalVisibility',
              property,
              value: !state.zones.globalVisibilities[property],
            });
            break;
          }
        }
      }}
      tooltip={{
        text: `${isActive ? 'Hide' : 'Show'} ${getTip(cluster, icon)}`,
        offset: 'left',
      }}
    >
      <VisibilityIcon
        cluster={cluster}
        icon={icon}
        active={isActive}
        size={size ?? undefined}
      />
    </Button>
  );
};
