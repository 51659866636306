/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

export const AXIS_RED = '#f64171';
export const AXIS_GREEN = '#aee858';
export const AXIS_BLUE = '#5fb9ef';
export const AXIS_ALL = '#85678c';

export const PRIM_MILKYWAY = '#ffffff';
export const PRIM_CRATER = '#e3e3e3';
export const PRIM_GRAVITY = '#c6c6c6';
export const PRIM_GALAXY = '#3c3c3c';
export const PRIM_BLACKHOLE = '#000000';
export const SEC_INDIGO = '#031f50';
export const SEC_BLUE = '#35459c';
export const SEC_MAGENTA = '#b43996';
export const TER_YELLOW = '#fc9e21';
export const TER_ORANGE = '#ff3d45';
export const TER_RED = '#d50032';
export const TER_MAGENTA = '#c724b0';
export const TER_BLUE = '#5e79fb';

// Material Design Palette
export const YELLOW = '#ffeb3b';
export const AMBER = '#ffc107';
export const ORANGE = '#ff9800';
export const DEEP_ORANGE = '#ff5722';
export const RED = '#f44336';
export const PINK = '#e91e63';
export const PURPLE = '#9c27b0';
export const DEEP_PURPLE = '#673ab7';
export const INDIGO = '#3f51b5';
export const BLUE = '#2196f3';
export const LIGHT_BLUE = '#03a9f4';
export const CYAN = '#00bcd4';
export const TEAL = '#009688';
export const GREEN = '#4caf50';
export const LIGHT_GREEN = '#8bc34a';
export const LIME = '#cddc39';
export const BROWN = '#795548';
export const GREY = '#9e9e9e';
export const BLUE_GREY = '#607d8b';

export enum HUES {
  YELLOW,
  AMBER,
  ORANGE,
  RED_ORANGE,

  RED,
  MAGENTA,
  PURPLE,
  DEEP_PURPLE,

  BLUE,
  TEAL,
  GREEN,
  LIME,
}

// Material Design Palette 500s
export const PALETTE_MATERIAL = [
  '#fff176', //YELLOW,
  '#ffc107', //AMBER
  '#ff9800', //ORANGE
  '#ff5722', //RED_ORANGE

  '#f44336', //RED
  '#e91e63', //MAGENTA
  '#9c27b0', //PURPLE
  '#673ab7', //DEEP_PURPLE

  '#2196f3', //BLUE
  '#00bcd4', //TEAL
  '#4caf50', //GREEN
  '#cddc39', //LIME
];

// Material Design Palette 900s
export const PALETTE_MATERIAL_DARK = [
  '#ffeb3b', //YELLOW,
  '#ff8f00', //AMBER
  '#e65100', //ORANGE
  '#bf360c', //RED_ORANGE

  '#b71c1c', //RED
  '#880e4f', //MAGENTA
  '#4a148c', //PURPLE
  '#311b92', //DEEP_PURPLE

  '#0d47a1', //BLUE
  '#004d40', //TEAL
  '#1b5e20', //GREEN
  '#827717', //LIME
];

export const PALETTE_OUSTER = [
  '#fdaf34',
  '#fc9250',
  '#fb8073',
  '#ff3d45',

  '#d50032',
  '#f744b0',
  '#b038c8',
  '#5e41cc',

  '#3347a7',
  '#132a69',
  '#071d4c',
  '#010f2e',
];

export const PALETTE_MONO = [
  '#ffffff',
  '#fafafa',
  '#f5f5f5',
  '#e5e5e5',

  '#d4d4d4',
  '#a3a3a3',
  '#737373',
  '#525252',

  '#404040',
  '#262626',
  '#171717',
  '#000000',
];

// Fluent Palette
// https://github.com/microsoft/fluentui/blob/dab45a2afe609709735b9b6e604a1af40d50e809/packages/theme/src/colors/FluentColors.ts
// yellow10 = '#fce100';
// orangeYellow10 = '#c19c00';
// orange10 = '#ffaa44';
// redOrange10 = '#da3b01';
// red10 = '#d13438';
// pinkRed10 = '#750b1c';
// magentaPink10 = '#e3008c';
// magenta10 = '#c239b3';
// blueMagenta10 = '#8378de';
// blue10 = '#4f6bed';
// cyanBlue10 = '#0078d4';
// cyan10 = '#00b7c3';
// greenCyan10 = '#00ad56';
// green10 = '#498205';
// yellowGreen10 = '#8cbd18';
export const PALETTE_FLUENT = [
  '#fce100', //YELLOW,
  '#ffaa44', //AMBER
  '#da3b01', //ORANGE
  '#d13438', //RED_ORANGE

  '#750b1c', //RED
  '#e3008c', //MAGENTA
  '#8378de', //PURPLE
  '#373277', //DEEP_PURPLE

  '#4f6bed', //BLUE
  '#00b7c3', //TEAL
  '#498205', //GREEN
  '#8cbd18', //LIME
];
// orangeYellow20 = '#986f0b';
// orange20 = '#ca5010';
// redOrange20 = '#603d30';
// red20 = '#a4262c';
// magentaPink20 = '#9b0062';
// magenta20 = '#881798';
// blueMagenta20 = '#8764b8';
// cyanBlue20 = '#004e8c';
// cyan20 = '#038387';
// green20 = '#0b6a0b';

// orange30 = '#8e562e';
// cyan30 = '#005b70';
// blueMagenta30 = '#5c2e91';
// blueMagenta40 = '#373277';
// cyan40 = '#005e50';

export const PALETTE_FLUENT_DARK = [
  '#986f0b', //YELLOW,
  '#ca5010', //AMBER
  '#603d30', //ORANGE
  '#a4262c', //RED_ORANGE

  '#9b0062', //RED
  '#881798', //MAGENTA
  '#5c2e91', //PURPLE
  '#373277', //DEEP_PURPLE

  '#004e8c', //BLUE
  '#038387', //TEAL
  '#005b70', //GREEN
  '#0b6a0b', //LIME
];

// 500s from Tailwind
export const PALETTE_TAILWIND = [
  '#eab308', //YELLOW,
  '#f59e0b', //AMBER
  '#f97316', //ORANGE
  '#ef4444', //RED_ORANGE

  '#f43f5e', //RED
  '#ec4899', //MAGENTA
  '#d946ef', //PURPLE
  '#8b5cf6', //DEEP_PURPLE

  '#3b82f6', //BLUE
  '#14b8a6', //TEAL
  '#22c55e', //GREEN
  '#a3e635', //LIME
];

// Sample from Tailwind
export const PALETTE_TAILWIND_DARK = [
  '#854d0e', //YELLOW,
  '#78350f', //AMBER
  '#7c2d12', //ORANGE
  '#7f1d1d', //RED_ORANGE

  '#831843', //RED
  '#701a75', //MAGENTA
  '#581c87', //PURPLE
  '#4c1d95', //DEEP_PURPLE

  '#1e3a8a', //BLUE
  '#134e4a', //TEAL
  '#14532d', //GREEN
  '#365314', //LIME
];

// Sample from Gruvbox
export const PALETTE_GRUVBOX = [
  '#ebdbb2',
  '#fabd2f',
  '#fe8019',
  '#fb6250',

  '#f2594b',
  '#cc241d',
  '#ac2046',
  '#824a6d',

  '#7daea3',
  '#8ec07c',
  '#b8bb26',
  '#a9b665',
];

// https://rpubs.com/mjvoss/psc_viridis
export const PALETTE_VIRIDIS = [
  '#fde725',
  '#c2df23',
  '#86d549',
  '#52c569',

  '#2ab07f',
  '#1e9b8a',
  '#25858e',
  '#2d708e',

  '#38588c',
  '#433e85',
  '#482173',
  '#440154',
];
export const PALETTE_INFERNO = [
  '#fcffa4',
  '#f5db4c',
  '#fcae12',
  '#f78410',

  '#e65d2f',
  '#cb4149',
  '#a92e5e',
  '#85216b',

  '#5f136e',
  '#390963',
  '#140b34',
  '#000004',
];
export const PALETTE_MAGMA = [
  '#fcfdbf',
  '#fed395',
  '#fea973',
  '#fa7d5e',

  '#e95462',
  '#c83e73',
  '#a3307e',
  '#7e2482',

  '#59157e',
  '#331067',
  '#120d31',
  '#000004',
];
export const PALETTE_PLASMA = [
  '#f0f921',
  '#fcd225',
  '#fdae32',
  '#f68d45',

  '#e76f5a',
  '#d5546e',
  '#c03a83',
  '#a62098',

  '#8606a6',
  '#6300a7',
  '#3e049c',
  '#0d0887',
];

// extracted from data apps palettes
// #5e4fa2
// #3a7eb8
// #56b0ad
// #8cd1a4
// #c3e79f
// #edf8a3
// #fffbb8
// #fee08b
// #fdb567
// #f67f4b
// #e2514a
// #bc2249
export const PALETTE_CAL_REFL = [
  '#fffbb8', //YELLOW,
  '#fee08b', //AMBER
  '#fdb567', //ORANGE
  '#f67f4b', //RED_ORANGE

  '#e2514a', //RED
  '#bc2249', //MAGENTA
  '#5e4fa2', //PURPLE
  '#3a7eb8', //DEEP_PURPLE

  '#56b0ad', //BLUE
  '#8cd1a4', //TEAL
  '#c3e79f', //GREEN
  '#edf8a3', //LIME
];
export const PALETTE_CAL_REFL_DARK = [
  '#fff21f',
  '#f0b100',
  '#cb6a00',
  '#a83300',

  '#be1008',
  '#4c001c',
  '#2d206b',
  '#144a78',

  '#1d504e',
  '#226f3d',
  '#5d9e1c',
  '#b1cb04',
];
