/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef, useState } from 'react';
import {
  Color,
  DoubleSide,
  InstancedMesh,
  Matrix4,
  MeshBasicMaterial,
} from 'three';
import {
  COLOR_HIGHLIGHTED,
  COLOR_SELECTED,
  MAX_TRACKED_OBJS_INSTANCES_COUNT,
  paletteVariations,
  snapTrackedsPosZ,
} from '../constants';
import { useAppState } from '../Stores';
import { Context, PerceptionClassification } from '../types';

const allowedRingTypes = new Set<PerceptionClassification>([
  'Person',
  'Bicycle',
  'Unknown',
]);

const matrix = new Matrix4();
const material = new MeshBasicMaterial({ side: DoubleSide });
const colorSelected = new Color(COLOR_SELECTED);
const colorHighlighted = new Color(COLOR_HIGHLIGHTED);

// Logic to update the tracked object's disk
export const useRingSynch = (context: Context): void => {
  const state = useAppState();

  const rings = useRef<InstancedMesh | null>(null);

  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    rings.current = new InstancedMesh(
      context.assets.personPeripheral.scale(0.4, 0.4, 0.4),
      material,
      MAX_TRACKED_OBJS_INSTANCES_COUNT,
    );

    rings.current.name = 'Rings';
  }, []);

  // should the rings be updated?
  useEffect(() => {
    const isVisibleInMode =
      state.tracked.globalVisibilities.rings &&
      (state.app.inputMode === 'playback' ||
        state.app.mode === 'viewer' ||
        state.app.mode === 'zone' ||
        state.app.mode === 'recording' ||
        state.app.mode === 'preferences' ||
        state.app.mode === 'map');
    setShouldUpdate(isVisibleInMode);
  }, [state.app.mode, state.tracked.globalVisibilities.rings]);

  // show/hide
  useEffect(() => {
    if (rings.current === null) return;
    if (!shouldUpdate) return;

    context.viz.scene.add(rings.current);

    return () => {
      if (rings.current !== null) rings.current.removeFromParent();
    };
  }, [shouldUpdate]);

  // update all tracked objects rings
  useEffect(() => {
    if (rings.current === null) return;
    if (!shouldUpdate) return;

    const paletteVariation = paletteVariations[state.app.palette];
    const ids = state.tracked.allIds.filter((id) => {
      const { classification } = state.tracked.byId[id];
      return (
        allowedRingTypes.has(classification) &&
        state.tracked.visible[id] &&
        state.tracked.classificationVisibilities[classification]
      );
    });

    ids.forEach((id, i) => {
      const params = state.tracked.byId[id];
      const isSelected = state.tracked.selected[id];
      const isHighlighted = state.tracked.highlighted[id] ?? false;

      const colorIndex =
        state.tracked.classificationColors[params.classification];
      const { colorOffsetIndex } = params;
      const color = isHighlighted
        ? colorHighlighted
        : isSelected
        ? colorSelected
        : paletteVariation[colorIndex][colorOffsetIndex];
      const { position, rotation } = params;
      matrix.makeRotationFromQuaternion(rotation);
      matrix.setPosition(position.x, position.y, snapTrackedsPosZ(position.z));
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      if (allowedRingTypes.has(params.classification)) {
        rings.current!.setMatrixAt(i, matrix);
        rings.current!.setColorAt(i, color);
      }
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    });

    if (ids.length > 0 || rings.current.count !== ids.length) {
      rings.current.count = ids.length;
      rings.current.instanceMatrix.needsUpdate = true;
      if (rings.current.instanceColor)
        rings.current.instanceColor.needsUpdate = true;
      material.needsUpdate = true;
    }
  }, [
    state.tracked.allIds,
    state.tracked.byId,
    state.tracked.classificationVisibilities,
    state.tracked.classificationColors,
    state.tracked.visible,
    state.app.palette,
    state.tracked.selected,
    state.tracked.highlighted,
    shouldUpdate,
  ]);
};
