/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useCallback } from 'react';
import {
  Camera,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Raycaster,
  Vector3,
} from 'three';
import { PointerType, usePointerEvents } from './usePointerEvents';

const rc = new Raycaster();
const plane = new Mesh(
  new PlaneBufferGeometry(10000, 10000),
  new MeshBasicMaterial({}),
);

// Test mouse ray intersections with the ground plane
export const usePlaneIntersection = (
  canvas: HTMLCanvasElement,
  camera: Camera,
  handler: (point: Vector3 | null, pointer: PointerType) => void,
): React.Dispatch<React.SetStateAction<boolean>> => {
  const cb = useCallback((pointer: PointerType) => {
    if (pointer.eventType === 'still') return;
    rc.setFromCamera(pointer, camera);
    const p = rc.intersectObject(plane)[0]?.point || null;
    handler(p, pointer);
  }, []);

  return usePointerEvents(canvas, cb);
};
