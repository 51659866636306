/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useAppState } from '../../../Stores';
import { TrackedActions } from '../../../trackedObjects/TrackedActions';
import { ZoneActions } from '../../../zone/ZoneActions';
import { Separator } from '../Separator';

export const ActionsPanel = (): JSX.Element | null => {
  const state = useAppState();

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translate(0,-50%)',
        alignItems: 'center',
        zIndex: 12,
      }}
    >
      <div className="flex flex-col items-center p-4">
        {(() => {
          switch (state.app.mode) {
            case 'viewer':
            case 'recording':
            case 'preferences':
              return <TrackedActions />;
            default:
              return null;
          }
        })()}

        {state.app.mode === 'zone' && (
          <>
            <Separator />
            <ZoneActions />
          </>
        )}
      </div>
    </div>
  );
};
