/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import {
  BufferGeometry,
  EdgesGeometry,
  LineBasicMaterial,
  LineSegments,
  OrthographicCamera,
  Vector3,
} from 'three';
import { COLOR_IDEAL, LAYER_ICONS } from '../constants';

const v3 = new Vector3();

export class Icon3JS extends LineSegments {
  public readonly isIcon3JS = true;

  constructor(iconGeo: BufferGeometry) {
    super(
      new EdgesGeometry(iconGeo),
      new LineBasicMaterial({
        color: COLOR_IDEAL,
      }),
    );
    this.name = `Icon3JS-${iconGeo.name}`;
    this.layers.set(LAYER_ICONS);

    const scale = 1;

    this.onBeforeRender = (renderer, scene, camera) => {
      if ('isOrthographicCamera' in camera) {
        const cam = camera as OrthographicCamera;
        this.scale.setScalar(
          ((cam.top - cam.bottom) / cam.zoom) * 0.05 * scale,
        );
      } else {
        this.scale.setScalar(
          this.getWorldPosition(v3).sub(camera.position).length() *
            0.03 *
            scale,
        );
      }
    };
  }

  public setColor = (color: string): void => {
    (this.material as LineBasicMaterial).color.set(color);
  };
}
