/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppDispatch, useAppState } from '../../Stores';
import { Context } from '../../types';

export const useCameraManipulation = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    context.viz.controls.enabled = state.app.viewNavigation;
  }, [state.app.viewNavigation]);

  // reset to camera when entering new section
  useEffect(() => {
    dispatch({ type: 'setTool', value: 'Camera' });
  }, [state.app.mode]);
};
