/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppState } from '../Stores';
import { Context } from '../types';
import { Preset } from '../app/components/pane/cloudsDisplay/CloudColorPresets';
import { Palettes } from '../constants';
import { PointDescriptorsInUse } from '../types';

export const useProcessedCloudAppearance = (context: Context): void => {
  const state = useAppState();
  const palette = Palettes[state.app.palette];

  // Display
  useEffect(() => {
    for (const descriptor of PointDescriptorsInUse) {
      for (const [id, cloud] of Object.entries(
        context.instances.ProcessedCloud.all[descriptor],
      )) {
        const { colorMode, colorMin, colorMax, colorRange } =
          state.clouds[descriptor].display;

        const { min, max } = colorRange[colorMode];

        cloud.setColorMode(colorMode);
        const preset: Preset = {
          name: 'not used',
          colorMin: palette[colorMin[id]],
          colorMax: palette[colorMax[id]],
          min,
          max,
        };
        cloud.setPreset(preset);
      }
    }
  }, [
    state.clouds.Background.display,
    state.clouds.Foreground.display,
    state.clouds.Ground.display,
    state.app.palette,
  ]);

  // Height Exclusions
  useEffect(() => {
    const min = state.clouds.Background.display.heightMin;
    const max = state.clouds.Background.display.heightMax;

    // NOTE TO CODE REVIEWERS:
    // I'm doing eslint disabling because I couldn't find the syntax
    // to get only `cloud` (not `id` too) from the `Object.entries` call.

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [id, cloud] of Object.entries(
      context.instances.ProcessedCloud.all.Background,
    )) {
      cloud.setHeightRange({ min, max });
    }
  }, [
    state.clouds.Background.display.heightMin,
    state.clouds.Background.display.heightMax,
  ]);

  // Size
  useEffect(() => {
    for (const descriptor of PointDescriptorsInUse) {
      for (const [id, cloud] of Object.entries(
        context.instances.ProcessedCloud.all[descriptor],
      )) {
        const size = state.clouds[descriptor].pointSize[id];
        cloud.setPointSize(size);
      }
    }
  }, [
    state.clouds.Background.pointSize,
    state.clouds.Foreground.pointSize,
    state.clouds.Ground.pointSize,
  ]);

  // Visibility
  useEffect(() => {
    const isInputPlayback = state.app.inputMode === 'playback';
    for (const descriptor of PointDescriptorsInUse) {
      for (const [id, cloud] of Object.entries(
        context.instances.ProcessedCloud.all[descriptor],
      )) {
        const isVisible = state.clouds[descriptor].visibilities[id];
        const isVisibleInMode =
          isInputPlayback ||
          state.app.mode === 'viewer' ||
          state.app.mode === 'zone' ||
          state.app.mode === 'recording' ||
          state.app.mode === 'preferences' ||
          state.app.mode === 'map';
        const isAdded = isInputPlayback
          ? true
          : state.sensors.addedStates[id] !== undefined &&
            state.sensors.addedStates[id];
        cloud.visible = isAdded && isVisibleInMode && isVisible;
      }
    }
  }, [
    state.clouds.Background.visibilities,
    state.clouds.Foreground.visibilities,
    state.clouds.Ground.visibilities,
    state.sensors.addedStates,
    state.app.mode,
  ]);
};
