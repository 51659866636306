/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from '../../Button';
import { TooltipInterface } from '../../tooltip/Tooltip';
import { EyeIcon } from './EyeIcon';

export const EyeToggle = ({
  isOn,
  onClick,
  tooltip,
}: {
  isOn: boolean;
  onClick: () => void;
} & { tooltip?: TooltipInterface }): JSX.Element | null => {
  return (
    <Button className="p-0_5 grow-0 bgNone" tooltip={tooltip} onClick={onClick}>
      <EyeIcon isOpen={isOn} />
    </Button>
  );
};
