/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';

const style = {
  circle: {
    width: '6px',
    height: '6px',
    margin: '4px',
    borderRadius: '100%',
  },
  rect: {
    width: '8px',
    height: '8px',
  },
};
export const ColorDot = ({
  color,
  variant = 'circle',
}: {
  color: string | null;
  variant?: 'circle' | 'rect';
}): JSX.Element | null => {
  if (!color) return null;
  return (
    <div
      style={{
        backgroundColor: color,
        ...style[variant],
      }}
    />
  );
};
