/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { InputHTMLAttributes } from 'react';
import './TextArea.css';

const TextArea = ({
  name,
  onChange,
  invalidMessage,
  value,
  disabled = false,
  isValid = true,
  autoComplete = 'off',
  ...rest
}: InputHTMLAttributes<HTMLTextAreaElement> & {
  invalidMessage?: string;
  isValid?: boolean;
}): JSX.Element => {
  return (
    <>
      <div className="floating-text-area">
        <textarea
          style={{
            textAlign: 'left',
            resize: 'vertical',
            minHeight: '4rem',
          }}
          id={name}
          className="textarea text-sm"
          type="text"
          placeholder=" "
          value={value}
          disabled={disabled}
          autoComplete={autoComplete}
          onChange={(e) => {
            onChange && onChange(e);
          }}
          {...rest}
        />
        <label htmlFor={name} className="label placeholder text-sm">
          {name}
        </label>
      </div>
      {!isValid && (
        <div className="invalid-text textGreyD text-xs">{invalidMessage}</div>
      )}
    </>
  );
};

export default TextArea;
