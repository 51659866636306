/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { ProcessedCloudSchema } from '../processedCloud/ProcessedCloudStore';
import { TrackedObjectsSchema } from '../trackedObjects/TrackedObjectsStore';
import {
  ObjectParameters,
  PopulatedPointCloud,
} from '../FlatbufDeserialization';
import { PerceptionClassifications, PointDescriptors } from '../types';
import { Vector3, Quaternion, Vector2 } from 'three';
import { ZoneSchema as ServerZoneSchema } from '../zone/eventZone/EventZoneServerInterface';
import { OccupationSchema, ZoneSchema } from '../zone/ZoneStore';
import { Occupation as ServerOccupation } from '../EventServerWebsocket';
import { getAppId, totalColorVariations } from '../constants';
import { Alert, ServerAlert } from '../api/alerts';
import { Classification } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/classification';

export const adaptCloud = (
  populatedCloud: PopulatedPointCloud,
): ProcessedCloudSchema => {
  const descriptor = PointDescriptors[populatedCloud.descriptorEnum];

  return {
    descriptor,
    id: populatedCloud.id,
    params: {
      nearir: populatedCloud.nearir,
      points: populatedCloud.points,
      quaternionRotation: populatedCloud.quaternionRotation,
      reflectivity: populatedCloud.reflectivity,
      signal: populatedCloud.signal,
      translation: populatedCloud.translation,
      frame: populatedCloud.frame,
    },
  };
};

export const adaptPerception = (
  objectParameters: ObjectParameters[],
): TrackedObjectsSchema[] => {
  return objectParameters.map((el) => {
    const { id, classification, ...rest } = el;
    // no need to offset cluster colors
    const colorOffsetIndex =
      classification === Classification.CLUSTER
        ? 0
        : parseInt(id) % totalColorVariations;
    const tracked: TrackedObjectsSchema = {
      id,
      classification: PerceptionClassifications[classification],
      clusterClassification:
        PerceptionClassifications[rest.cluster_classification],
      extraText: rest.extra_text,
      params: {
        position: new Vector3(
          rest.position.x,
          rest.position.y,
          rest.position.z,
        ),
        points: rest.points,
        dimensions: new Vector3(
          rest.dimensions.x,
          rest.dimensions.y,
          rest.dimensions.z,
        ),
        rotation: new Quaternion(
          rest.rotation.x,
          rest.rotation.y,
          rest.rotation.z,
          rest.rotation.w,
        ),
        creationTs: rest.creationTs,
        updateTs: rest.updateTs,
        velocity: new Vector3(
          rest.velocity.x,
          rest.velocity.y,
          rest.velocity.z,
        ),
        text: rest.text,
        previousPositions: rest.previousPositions,
        colorOffsetIndex,
      },
    };
    return tracked;
  });
};

export const adaptZones = (
  serverZoneSchemas: ServerZoneSchema[],
): ZoneSchema[] => {
  const out: ZoneSchema[] = [];
  for (const serverZoneSchema of serverZoneSchemas) {
    const {
      id: serverId,
      name,
      type,
      min_height: heightMin,
      max_height: heightMax,
      vertices,
      metadata,
    } = serverZoneSchema;
    // Metadata is json, we use it as a string internally for easy manipulation
    const adaptedMetadata = metadata ? JSON.stringify(metadata) : metadata;
    const zoneSchema: ZoneSchema = {
      id: getAppId(serverId.toString(), type),
      params: {
        serverId,
        name,
        type,
        heightMin,
        heightMax,
        vertices: vertices.map((v) => new Vector2(v.x, v.y)),
        metadata: adaptedMetadata ?? '',
      },
    };

    out.push(zoneSchema);
  }
  return out;
};

export const adaptOccupations = (
  occupations: ServerOccupation[],
): OccupationSchema[] => {
  const out: OccupationSchema[] = [];
  for (const occupation of occupations) {
    const { id, objects: objInfo } = occupation;
    const occupationSchema: OccupationSchema = {
      id: id.toString(),
      trackedObjectIds: objInfo.map((e) => e.id.toString()),
    };
    out.push(occupationSchema);
  }
  return out;
};

export const adaptAlerts = (alerts: ServerAlert[]): Alert[] => {
  return alerts.map((alert) => {
    const sourceInfo = alert.source_info || 'General';

    return {
      id: alert.id.toString(),
      active: alert.active,
      activeCount: alert.active_count,
      alertCode: alert.alert_code,
      category: alert.category,
      firstOccured: alert.first_occurred,
      lastOccured: alert.last_occurred ?? alert.first_occurred,
      level: alert.level,
      message: alert.msg,
      messageVerbose: alert.msg_verbose,
      sourceInfo,
    } as Alert;
  });
};
