/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { AppDefaultState } from '../app/AppStore';
import { Button } from '../app/components/Button';
import { ColorInput } from '../app/components/ColorInput';
import { PaletteSelect } from '../app/components/PaletteSelect';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { ResetButton } from '../app/components/ResetButton';
import { useDebounce } from '../app/hooks/useDebounce';
import CoordinateProperties from '../coordinatePlanes/CoordinateProperties';
import { useAppDispatch, useAppState } from '../Stores';
import AutoRotateProperties from './AutoRotateProperties';
import TrackedObjectLabelProperties from './TrackedObjectLabelProperties';
import RadioButtonRow from '../app/components/radioButtonRow/RadioButtonRow';

export const PreferenceProperties = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const colors = state.app.colors[state.app.theme];
  const nextTheme = state.app.theme === 'Dark' ? 'Light' : 'Dark';

  const handlerViewport = useDebounce((value) => {
    dispatch({
      type: 'setAppColor',
      for: 'viewport',
      value,
    });
  });
  const handlerGrid = useDebounce((value) => {
    dispatch({
      type: 'setAppColor',
      for: 'cartesianGrid',
      value,
    });
  });
  const handlerRangeRings = useDebounce((value) => {
    dispatch({
      type: 'setAppColor',
      for: 'polarGrid',
      value,
    });
  });

  return (
    <>
      <PropertiesSubGroup name="Scene" className="flex flex-col">
        <div className="flex items-center my-2">
          <p className="text-sm textGreyD mr-2">Name</p>
          <input
            type={'text'}
            value={state.app.sceneName}
            onChange={(e) => {
              dispatch({ type: 'setSceneName', value: e.target.value });
            }}
          />
        </div>
      </PropertiesSubGroup>

      <PropertiesSubGroup name="Theme" className="flex flex-col">
        {state.app.developerMode && (
          <div className="flex flex-row justify-between">
            <Button
              className="my-2"
              onClick={() => {
                dispatch({
                  type: 'setTheme',
                  value: nextTheme,
                });
              }}
            >
              {nextTheme}
            </Button>
          </div>
        )}

        <PaletteSelect />

        <div className="flex wrap justify-between my-2">
          <p className="text-sm textGreyD">Viewport</p>
          <ResetButton
            isVisible={
              AppDefaultState.colors[state.app.theme].viewport !==
              state.app.colors[state.app.theme].viewport
            }
            onClick={() =>
              dispatch({
                type: 'setAppColor',
                for: 'viewport',
                value: 'default',
              })
            }
          >
            <ColorInput value={colors.viewport} onChange={handlerViewport} />
          </ResetButton>
        </div>

        <div className="flex wrap justify-between my-2">
          <p className="text-sm textGreyD">Grid</p>
          <ResetButton
            isVisible={
              AppDefaultState.colors[state.app.theme].cartesianGrid !==
              state.app.colors[state.app.theme].cartesianGrid
            }
            onClick={() =>
              dispatch({
                type: 'setAppColor',
                for: 'cartesianGrid',
                value: 'default',
              })
            }
          >
            <ColorInput value={colors.cartesianGrid} onChange={handlerGrid} />
          </ResetButton>
        </div>

        <div className="flex wrap justify-between my-2">
          <p className="text-sm textGreyD">Range Rings</p>
          <ResetButton
            isVisible={
              AppDefaultState.colors[state.app.theme].polarGrid !==
              state.app.colors[state.app.theme].polarGrid
            }
            onClick={() =>
              dispatch({
                type: 'setAppColor',
                for: 'polarGrid',
                value: 'default',
              })
            }
          >
            <ColorInput value={colors.polarGrid} onChange={handlerRangeRings} />
          </ResetButton>
        </div>
      </PropertiesSubGroup>

      <CoordinateProperties />
      <AutoRotateProperties />
      {state.app.developerMode && <TrackedObjectLabelProperties />}

      <RadioButtonRow
        label="Developer Mode"
        onClick={() =>
          dispatch({
            type: 'setDeveloperMode',
            value: !state.app.developerMode,
          })
        }
        selected={state.app.developerMode}
        disabled={false}
      />
    </>
  );
};
