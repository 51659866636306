/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { ActionButton } from '../app/components/actionButton/ActionButton';
import { useAppState, useAppDispatch } from '../Stores';

export const TrackedActions = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const ids = state.tracked.allIds.filter((id) => {
    const { classification } = state.tracked.byId[id];
    return state.tracked.classificationVisibilities[classification];
  });
  const selected = ids.filter((id) => state.tracked.selected[id]);

  return (
    <>
      <ActionButton
        icon="SelectAll"
        tooltip={{ text: 'Select All Tracked' }}
        disabled={ids.length === 0}
        onClick={() => {
          dispatch({
            type: 'setTrackedInstanceBoolProp',
            property: 'selected',
            value: 'show',
            ids,
          });
        }}
      />
      <ActionButton
        icon="DeselectAll"
        tooltip={{ text: 'Deselect All Tracked' }}
        disabled={selected.length === 0}
        onClick={() => {
          dispatch({
            type: 'setTrackedInstanceBoolProp',
            property: 'selected',
            value: 'hide',
            ids: selected,
          });
        }}
      />
    </>
  );
};
