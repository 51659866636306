/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import {
  Points,
  BufferGeometry,
  BufferAttribute,
  Float32BufferAttribute,
  DynamicDrawUsage,
  ShaderMaterial,
  UniformsUtils,
  LineSegments,
} from 'three';
import { MAX_SENSOR_RESOLUTION } from '../constants';

const uniforms = {
  pointSize: {
    value: 2,
  },
};

type Uniform = typeof uniforms;

const vertexShader = `
  uniform float pointSize;
  attribute vec3 color;
  attribute float size;

  varying vec3 colorVarying;
  varying float alphaVarying;

  void main() {
    colorVarying = color;

    gl_PointSize = size * pointSize;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
  `;

const fragmentShader = `
    varying vec3 colorVarying;
    
    void main( void ) {
      gl_FragColor = vec4( colorVarying, 1.0);
    }
`;

export class Material extends ShaderMaterial {
  constructor() {
    super({
      vertexShader,
      fragmentShader,
      uniforms: UniformsUtils.clone(uniforms),
    });
  }
}

type Attributes = {
  position: BufferAttribute;
  color: BufferAttribute;
  size: BufferAttribute;
};

export class TrackedObjectsPointcloud3JS {
  private _points: Points | LineSegments;

  constructor(
    type: 'line' | 'points' = 'points',
    private maxPoints: number = MAX_SENSOR_RESOLUTION,
  ) {
    this._points =
      type === 'line'
        ? new LineSegments(new BufferGeometry(), new Material())
        : new Points(new BufferGeometry(), new Material());
    this._points.name = 'PerceptionObjectsPointcloud3JS';

    const attributes = this._points.geometry.attributes as Attributes;

    attributes.position = new Float32BufferAttribute(this.maxPoints * 3, 3);
    attributes.position.setUsage(DynamicDrawUsage);

    attributes.color = new Float32BufferAttribute(this.maxPoints * 3, 3);
    attributes.color.setUsage(DynamicDrawUsage);

    attributes.size = new Float32BufferAttribute(this.maxPoints, 1);
    attributes.size.setUsage(DynamicDrawUsage);

    this._points.frustumCulled = false;
  }

  /**
   * Update all cloud attributes
   * @param drawUpTo draw up to this value
   * @param position
   * @param color
   * @param size
   */
  setAttributes = (
    drawUpTo: number,
    position: Float32Array,
    color: Float32Array,
    size: Float32Array,
  ): void => {
    this._points.geometry.setDrawRange(0, drawUpTo);
    if (drawUpTo > this.maxPoints) {
      drawUpTo = this.maxPoints - 1;
      console.warn('More values have been supplied than allocated');
    }

    const attributes = this._points.geometry.attributes as Attributes;
    attributes.position.set(position);
    attributes.color.set(color);
    attributes.size.set(size);
    // set dirty flags
    attributes.position.needsUpdate = true;
    attributes.color.needsUpdate = true;
    attributes.size.needsUpdate = true;
  };

  setPoints = (drawUpTo: number, position: Float32Array): void => {
    this._points.geometry.setDrawRange(0, drawUpTo);
    if (drawUpTo > this.maxPoints) {
      drawUpTo = this.maxPoints - 1;
      console.warn('More values have been supplied than allocated');
    }

    const attributes = this._points.geometry.attributes as Attributes;
    attributes.position.set(position);
    attributes.position.needsUpdate = true;
  };

  setColors = (color: Float32Array): void => {
    const attributes = this._points.geometry.attributes as Attributes;
    attributes.color.set(color);
    attributes.color.needsUpdate = true;
  };

  setSizes = (size: Float32Array): void => {
    const attributes = this._points.geometry.attributes as Attributes;
    attributes.size.set(size);
    attributes.size.needsUpdate = true;
  };

  // Uniforms
  setPointSizeFactor = (pointSize: number): void => {
    const m = (this._points.material as Material).uniforms as Uniform;
    m.pointSize.value = pointSize;
  };

  public get points(): LineSegments | Points {
    return this._points;
  }
  public get name(): string {
    return this.points.name;
  }
  public set name(name: string) {
    this.points.name = name;
  }
}
