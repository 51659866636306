/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useState } from 'react';
import { useAppState } from '../Stores';

export const TimePlayback = (): JSX.Element => {
  const state = useAppState();
  const [time, setDate] = useState<Date | null>(null);

  useEffect(() => {
    const recording = state.playback.playing;
    if (recording === null) return;
    const timeIndex = state.playback.timeIndex;
    const time = recording.timestamps[timeIndex];
    const timePrecisionSeconds = time / 1000;
    const parsedDate = new Date(timePrecisionSeconds);
    setDate(parsedDate);
  }, [state.playback.timeIndex, state.playback.playing]);

  const recording = state.playback.playing;

  if (recording === null || time === null) return <></>;
  const [imeFormatted, amPm] = time.toLocaleTimeString().split(' ');
  return (
    <div
      className="TimePlayback flex flex-col no-select items-start"
      style={{
        position: 'absolute',
        left: '1rem',
        bottom: '1rem',
        pointerEvents: 'none',
      }}
    >
      <p className="text-sm textGreyE mb-1">{recording.name}</p>
      <p className="text-lg textGreyD mb-1">{time.toDateString()}</p>
      <p
        className="text-xl textGreyC "
        style={{ fontFamily: 'monospace' }}
      >{`${imeFormatted}.${time
        .getMilliseconds()
        .toString()
        .padStart(3, '0')} ${amPm}`}</p>
    </div>
  );
};
