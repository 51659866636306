/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import {
  Group,
  CircleGeometry,
  EdgesGeometry,
  Vector3,
  Color,
  Vector2,
  Camera,
} from 'three';
import { drop, dropRight, last, zip } from 'lodash';
import { Size } from '../../types';

// CONSTANTS
const RINGS_VERTEX_NUMBER = 120; // Large enough to look like a round ring even up close
const DEFAULT_LINE2_WIDTH = 0.0008;
const DEFAULT_RING_COLOR = 0x444444;

interface RingStyle {
  color?: Color;
  ringWidth?: number;
  opacity?: number;
  dashed?: boolean;
}

/**
 * Formats the specified range (in meters) to
 * a text representation up to the specified amount
 * of decimal places. Trailing zeros are removed.
 * @param rangeInMeters the range to format.
 * @param decimalPlaces the precision to maintain.
 * @returns the formatted range.
 */
const formatRangeLabelText = (rangeInMeters: number, decimalPlaces = 2) => {
  if (rangeInMeters <= 0) {
    throw new Error(
      'formatRangeLabelText(range): range must be strictly positive.',
    );
  }
  // Handle centimeters
  if (rangeInMeters < 1) {
    const roundedCm = Math.round(rangeInMeters * 100);
    return `${roundedCm}cm`;
  }
  // Parsefloat removes trailing zeroes
  const roundedRange = parseFloat(rangeInMeters.toFixed(decimalPlaces));
  return `${roundedRange}m`;
};

/* Implements a 3D circle range ring with its associated label */
class RangeRing {
  private _ring: Line2;
  private _rangeLabel: CSS2DObject;
  private _rangeLabelDiv: HTMLDivElement;
  private _range: number;
  private _rangeLabelDivSize: Size;

  constructor(
    range: number,
    ringGeometry: LineGeometry,
    ringMaterial: LineMaterial,
    ringLabelCSSClass: string,
  ) {
    this._range = range;

    // Use experimental line2 to have control over line width
    this._ring = new Line2(ringGeometry, ringMaterial);
    // Needed for dashed lines (unused at the moment)
    this._ring.computeLineDistances();

    this._rangeLabelDiv = document.createElement('div');
    this._rangeLabelDivSize = { width: 0, height: 0 };
    this._rangeLabelDiv.className = ringLabelCSSClass;
    this._rangeLabel = new CSS2DObject(this._rangeLabelDiv);
    // Position labels across y axis
    this._rangeLabel.position.set(0, 1, 0);
    this._ring.add(this._rangeLabel);
    this.setRange(range);
    this.computeLabelSize();
  }

  /* Scales the ring to appropriate size */
  public setRange(range: number) {
    this._range = range;
    this._ring.scale.set(range, range, range);
    this._rangeLabelDiv.textContent = formatRangeLabelText(range);
    //this.computeLabelSize();
  }

  private computeLabelSize() {
    const isParentNone = this._rangeLabelDiv.offsetParent === null;
    const isDisplayNone = this._rangeLabelDiv.style.display === 'none';

    /* 
      Make the label visible temporarily so we can compute
      its size. This is synchronous so it will take place
      in between rendered frames.
    */
    if (isParentNone) {
      document.body.appendChild(this._rangeLabelDiv);
    }
    if (isDisplayNone) {
      this._rangeLabelDiv.style.display = 'undefined';
    }

    const labelRect = this._rangeLabelDiv.getBoundingClientRect();
    this._rangeLabelDivSize = {
      width: labelRect.width,
      height: labelRect.height,
    };

    /* Return original visibility state */
    if (isParentNone) {
      document.body.removeChild(this._rangeLabelDiv);
    }
    if (isDisplayNone) {
      this._rangeLabelDiv.style.display = 'none';
    }
  }

  /* Returns range label corner coordinates in pixels */
  public labelRectInPixels(camera: Camera, rendererSize: Size) {
    const labelProjection = new Vector3()
      .setFromMatrixPosition(this._rangeLabel.matrixWorld)
      .project(camera);

    const labelCenterInPixels = new Vector2(
      (labelProjection.x * rendererSize.width) / 2,
      (labelProjection.y * rendererSize.height) / 2,
    );

    const topLeft = new Vector2(
      labelCenterInPixels.x - this._rangeLabelDivSize.width / 2,
      labelCenterInPixels.y + this._rangeLabelDivSize.height / 2,
    );
    const bottomRight = new Vector2(
      labelCenterInPixels.x + this._rangeLabelDivSize.width / 2,
      labelCenterInPixels.y - this._rangeLabelDivSize.height / 2,
    );

    return { topLeft, bottomRight, text: this._rangeLabelDiv.textContent };
  }

  public get range() {
    return this._range;
  }
  public get ring() {
    return this._ring;
  }
  public get rangeLabel() {
    return this._rangeLabel;
  }
}

/* Implements a collection of 3D Range Rings */
class RangeRings3JS extends Group {
  private _rings: RangeRing[];
  private _ringMaterial: LineMaterial | null = null;
  private _ringGeometry: LineGeometry | null = null;
  private _maxRange: number;
  private _isLabelsVisible: boolean;
  private _defaultRanges: number[];

  constructor(
    ranges: number[],
    ringLabelCSSClass: string,
    ringStyle: RingStyle = {} as RingStyle,
  ) {
    super();
    this.name = 'RangeRings3JS';
    this.frustumCulled = false;

    this._rings = [];
    this._maxRange = Math.max(...ranges);
    this._isLabelsVisible = true;

    // Initialize a single geometry
    // and material to reuse amongst all rings.
    this.initializeGeometry();
    this.initializeMaterial(ringStyle);

    this._defaultRanges = ranges;
    // Create the rings
    this._rings = this._defaultRanges
      .sort((a, b) => a - b)
      .map((r) => {
        if (!this._ringGeometry || !this._ringMaterial) {
          throw new Error('Range rings not initialized.');
        }

        return new RangeRing(
          r,
          this._ringGeometry,
          this._ringMaterial,
          ringLabelCSSClass,
        );
      });
    this._rings.forEach((rangeRing) => this.add(rangeRing.ring));
  }

  /* Initializes circle outline geometry */
  private initializeGeometry() {
    // EgdeGeometry removes the circle's central
    // vertex and its face to leave us with the outline...
    const circleGeometry = new EdgesGeometry(
      new CircleGeometry(1, RINGS_VERTEX_NUMBER),
    );
    // ...which we'll render with a line geometry.
    this._ringGeometry = new LineGeometry();
    this._ringGeometry.setPositions(
      new Float32Array(circleGeometry.getAttribute('position').array),
    );
  }

  /* Initializes ring material and style */
  private initializeMaterial(ringStyle: RingStyle) {
    this._ringMaterial = new LineMaterial({
      dashScale: 15,
      dashSize: 2,
      gapSize: 1,
    });

    const ringStyleWithDefaults = {
      color: ringStyle.color ?? new Color(DEFAULT_RING_COLOR),
      ringWidth: ringStyle.ringWidth ?? 1,
      opacity: ringStyle.opacity ?? 1,
      dashed: ringStyle.dashed ?? false,
    };

    this.updateStyle(ringStyleWithDefaults);
  }

  /* 
    Hides label's that are overlapping / too close to an
    adjacent ring's label.
  */
  public hideUnreadableLabels(camera: Camera, rendererSize: Size): void {
    // Leave all labels invisible if the rings are hidden
    if (!this.visible || this._rings.length < 1 || !this._isLabelsVisible) {
      return;
    }

    // Always set the most outer ring label as visible if it's in range
    const lastRing = last(this._rings);
    if (lastRing !== undefined) {
      lastRing.rangeLabel.visible = lastRing.range <= this._maxRange;
    }

    // Hide inner ring labels which there are too close to their next
    // closest outer ring label
    const ringLabelRects = this._rings
      .filter((ring) => ring.range <= this._maxRange)
      .map((ring) => [
        ring.rangeLabel,
        ring.labelRectInPixels(camera, rendererSize),
      ]);
    const a = zip(dropRight(ringLabelRects), drop(ringLabelRects));
    a.forEach(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ([[innerLabel, innerLabelRect], [, outerLabelRect]]) => {
        const xOverlap =
          innerLabelRect.topLeft.x <= outerLabelRect.bottomRight.x &&
          outerLabelRect.topLeft.x <= innerLabelRect.bottomRight.x;
        const yOverlap =
          innerLabelRect.topLeft.y >= outerLabelRect.bottomRight.y &&
          outerLabelRect.topLeft.y >= innerLabelRect.bottomRight.y;

        // Visibility of outer labels is prioritized
        innerLabel.visible = !(xOverlap && yOverlap);
      },
    );
  }

  /* Sets the maximum range of rings to render. */
  public setMaxRange(maxRange: number): void {
    this._maxRange = maxRange;
    this._rings.forEach((rangeRing) => {
      const isInRange = rangeRing.range <= maxRange;
      rangeRing.ring.visible = isInRange;
    });
    this.calcRingLabelVisibilities();
  }

  /* 
    Sets the spacing between adjacent rings but 
    maintains the same number of rings.
  */
  public setGranularity(granularity: number | 'DEFAULT', start = 0): void {
    // Create ranges. If default granularity, then this is
    // the range list that these range rings were initialized
    // with. Otherwise create a sequence starting at start
    // (not includign start) with step size of granularity.
    const ranges =
      granularity === 'DEFAULT'
        ? this._defaultRanges
        : this._rings.reduce((acc, unUsed, idx) => {
            acc.push(start + (idx + 1) * granularity);
            return acc;
          }, [] as number[]);

    ranges
      .sort((a, b) => a - b)
      .forEach((range, idx) => {
        this._rings[idx].setRange(range);
      });
  }

  /* Sets the visibility of this range ring group */
  public setVisibility(visbility: boolean): void {
    this.visible = visbility;
    this.calcRingLabelVisibilities();
  }

  /* 
    Sets the label visibility of this range ring group
    which is independent of the actual group's visibility.
  */
  public setLabelVisibility(visbility: boolean): void {
    this._isLabelsVisible = visbility;
    this.calcRingLabelVisibilities();
  }

  /*
    Updates ring label visibilities. Labels are visible
    if the ring group is visible, labels are visible and the label's
    associated ring is within the maximum range.
    This is seperate from the overlap check.
  */
  private calcRingLabelVisibilities() {
    this._rings.forEach((rangeRing) => {
      const isInRange = rangeRing.range <= this._maxRange;

      rangeRing.rangeLabel.visible =
        this.visible && this._isLabelsVisible && isInRange;
    });
  }

  /* Updates this range ring group's styling */
  public updateStyle(ringStyle: RingStyle): void {
    if (this._ringMaterial === null) return;
    const { color, ringWidth, opacity, dashed } = ringStyle;

    if (ringWidth !== undefined) {
      this._ringMaterial.linewidth = DEFAULT_LINE2_WIDTH * ringWidth;
    }
    if (color !== undefined) {
      this._ringMaterial.color = color;
    }
    if (dashed !== undefined) {
      this._ringMaterial.dashed = dashed;
    }
    if (opacity !== undefined) {
      const _opacity = Math.min(opacity, 1);
      if (_opacity === 1) {
        this._ringMaterial.transparent = false;
      } else {
        this._ringMaterial.transparent = true;
      }
      this._ringMaterial.opacity = _opacity;
    }
  }
}

export default RangeRings3JS;
