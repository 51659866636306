/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Alert, SourceType } from '../api/alerts';
import { ObjectEntries } from '../util/misc';
import { Column } from './diagnosticsColumns';
import DiagnosticsTableTypeSection from './DiagnosticsTableTypeSection';
import SourceTable from './SourceTable';

const DiagnosticsTable = ({
  name,
  type,
  data,
  columns,
  isExpanded,
  onExpandChange,
}: {
  name: string;
  type: SourceType;
  data: Record<string, Record<string, Alert>>;
  columns: Column<Alert>[];
  isExpanded: boolean;
  onExpandChange: () => void;
}): JSX.Element => {
  return (
    <div style={{ padding: 'var(--r2)' }}>
      <DiagnosticsTableTypeSection
        type={type}
        onChevronClick={onExpandChange}
        open={isExpanded}
        tableName={name}
      />
      <div
        className="collapsable-type-table"
        style={{
          display: isExpanded ? 'block' : 'none',
          height: isExpanded ? 'auto' : '0',
        }}
      >
        {ObjectEntries(data)
          .sort()
          .map(([source, alerts], idx) => {
            return (
              <SourceTable
                key={`${source}-${idx}`}
                source={source}
                type={type}
                alerts={alerts}
                columns={columns}
              />
            );
          })}
      </div>
    </div>
  );
};

export default DiagnosticsTable;
