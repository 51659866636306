/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useAppDispatch, useAppState } from '../../../Stores';
import { Section, SetupTabsSet, ViewerTabsSet } from '../../../types';

const MenuBtn = ({
  name,
  value,
  active,
  disabled,
  onClick,
}: {
  name: string;
  value: Section;
  active: boolean;
  disabled: boolean;
  onClick: (v: Section) => void;
}): JSX.Element => {
  return (
    <button
      className={`MenuBtn bgNone text-md no-select ml-2 p-1 ${
        active ? 'textGreyB' : 'textGreyD'
      }`}
      style={{
        textTransform: 'uppercase',
        letterSpacing: '.1rem',
      }}
      value={value}
      type="button"
      onClick={() => {
        onClick(value);
      }}
      disabled={disabled}
    >
      {name}
    </button>
  );
};

export const Menu = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  // TODO(emmanuel): kludged for efficiency, add TabularSections entry to store
  const topLevel: Section[] = ['viewer', 'setup', 'diagnostics', 'settings'];
  const isActive = (section: Section): boolean =>
    section === 'viewer' && ViewerTabsSet.has(state.app.mode)
      ? true
      : section === 'setup' && SetupTabsSet.has(state.app.mode)
      ? true
      : section === state.app.mode;

  const name = (section: Section): string =>
    ViewerTabsSet.has(section)
      ? 'Viewer'
      : SetupTabsSet.has(section)
      ? 'Setup'
      : section;

  return (
    <nav className="menu flex">
      {topLevel.map((section) => (
        <MenuBtn
          key={section}
          name={name(section)}
          value={section}
          active={isActive(section)}
          disabled={state.setup.alignPhase !== 'none'}
          onClick={(v: Section) => {
            dispatch({ type: 'setMode', value: v });
          }}
        />
      ))}
    </nav>
  );
};
