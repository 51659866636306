/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { ActionButton } from '../app/components/actionButton/ActionButton';
import { useAppState } from '../Stores';

export const MapActions = (): JSX.Element => {
  const state = useAppState();

  const id = state.zones.selected;
  const isDirty =
    id !== null ? state.zones.zoneRedefined[id] !== undefined : false;

  return (
    <>
      <ActionButton
        icon="Save"
        tooltip={{ text: 'Save Underlay map modifications' }}
        disabled={id === null || !isDirty}
        onClick={() => {
          // save modifications
        }}
      />
      <ActionButton
        icon="Reset"
        tooltip={{ text: ' Reset any transformation applied' }}
        disabled={id === null || !isDirty}
        onClick={() => {
          // reset transformation
        }}
      />
    </>
  );
};
