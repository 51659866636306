/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

export const saveToLocalStorage = (key: string, save: unknown): void => {
  try {
    const serialized = JSON.stringify(save);
    localStorage.setItem(key, serialized);
  } catch (error) {
    console.error('cant save to local storage');
  }
};

export const loadFromLocalStorage = (key: string): unknown | null => {
  try {
    const serialized = localStorage.getItem(key);
    if (serialized === null) return null;
    return JSON.parse(serialized);
  } catch (error) {
    console.error('cant load from local storage');
    return null;
  }
};
