/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { ZoneType } from '../../types';
import { ZoneSchema, ZonesSchema } from '../eventZone/EventZoneServerInterface';
import { endpoints } from '../../api/endpoints';
const {
  zone: {
    point: { fetchZones, addZone, setZones },
  },
} = endpoints;

/**
 * This class helps connect to the perception zone server interfaces
 */
export class PerceptionZoneServerInterface {
  /**
   * Connect to to receive new zones
   * @param[out] The event zones
   */
  public onZones?: (pointZones: ZonesSchema) => void;

  /**
   * Fetch all zones from the perception zone server
   */
  public async fetchAllZones(): Promise<void> {
    await fetchZones({
      onSuccess: async (response) => {
        const data = (await response.json()) as ZonesSchema;
        data?.zones?.forEach(function (z) {
          //In case the specified zones files had capitalization wrong fix it
          z.type = (z.type.charAt(0).toUpperCase() +
            z.type.slice(1).toLowerCase()) as ZoneType;
        });
        if (this.onZones) {
          this.onZones(data ?? []);
        }
      },
      onError: async (response) =>
        console.log(
          'Fetch All Zones request failed with ' + (await response.text()),
        ),
    });
  }

  /**
   * Add a single zone to the perception server. It will overwrite any
   * zone with the same id
   * @param zone Zone to add
   */
  public addPointZone(zone: ZoneSchema): void {
    addZone(zone, {
      onError: async (response) =>
        console.log(
          'Add PointZone request failed with ' + (await response.text()),
        ),
    }).then(async () => await this.fetchAllZones()); //Get the newest zones
  }

  /**
   * Set the point zones on the perception server
   * @param zones Zones to set
   */
  public setZones(zones: ZonesSchema): void {
    setZones(zones, {
      onError: async (response) =>
        console.log(
          'Set PointZones request failed with ' + (await response.text()),
        ),
    }).then(async () => await this.fetchAllZones()); //Get the newest zones
  }
}
