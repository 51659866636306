/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ButtonHTMLAttributes } from 'react';
import { Button } from '../Button';
import { ActionIcon, ActionIcons } from './ActionIcons';
import { TooltipInterface } from '../tooltip/Tooltip';

export const ActionButton = ({
  icon,
  onClick,
  active = true,
  disabled = false,
  tooltip,
  children,
  ...rest
}: {
  icon?: ActionIcon;
  active?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement> & {
    tooltip?: TooltipInterface;
  }): JSX.Element => {
  return (
    <Button
      className="borderNone p-2"
      disabled={disabled}
      onClick={onClick}
      tooltip={tooltip}
      {...rest}
    >
      <div className="flex flex-row items-center">
        {children && (
          <p className={`text-md textGreyD ${children && icon ? 'mr-2' : ''}`}>
            {children}
          </p>
        )}
        {icon && <ActionIcons size={18} icon={icon} active={active} />}
      </div>
    </Button>
  );
};
