/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { LIDAR_HUB_REST, REST } from '../constants';
import { safeFetch } from '../safeFetch';

export interface Recording {
  filename: string;
  start_timestamp: number;
  length_s: number;
}
// File system types
export interface FileSystemNode {
  path: string;
  type: 'file' | 'directory';
}
export type DirectoryResponse = FileSystemNode & {
  [fileOrDirName: string]: DirectoryResponse | FileResponse;
};
export type FileResponse = FileSystemNode & { type: 'file' } & {
  // Files will only have path and type keys, adding the string index to
  // be able to loop through a common interface with directories.
  [_doesNotExist: string]: undefined | null;
};
export interface ListRecordingsResponse {
  [fileName: string]: DirectoryResponse | FileResponse;
}
export type RecordingResponse = { message: string };

const call = (endpoint: 'play' | 'pause' | 'step'): void => {
  const callAsync = async () => {
    safeFetch.post(REST.execution[endpoint], '');
  };
  callAsync().catch((e) => console.warn(e));
};

const startRecording = async (
  filename: string,
  recordingLength: number,
): Promise<RecordingResponse | null> =>
  safeFetch.json.post<RecordingResponse>(REST.recording.start, {
    filename: filename,
    recording_length: recordingLength,
  });

const stopRecording = (): Promise<RecordingResponse | null> =>
  safeFetch.json.get<RecordingResponse>(REST.recording.stop);

const deleteRecording = async (
  fileName: string,
): Promise<RecordingResponse | null> =>
  safeFetch.json.delete<RecordingResponse>(REST.recording.delete(fileName));

const listRecordings = async (): Promise<ListRecordingsResponse | null> =>
  safeFetch.json.get<ListRecordingsResponse>(REST.recording.list);

const resetPerception = async (): Promise<void> => {
  await safeFetch.put(REST.execution.reset);
};
const resetLidarHub = async (): Promise<void> => {
  await safeFetch.put(LIDAR_HUB_REST.reset);
};

export const record = {
  call,
  recording: {
    start: startRecording,
    stop: stopRecording,
    delete: deleteRecording,
    list: listRecordings,
  },
  reset: {
    perception: resetPerception,
    lidarHub: resetLidarHub,
  },
};
