/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { round } from 'lodash';
import {
  Mesh,
  Vector3,
  MeshBasicMaterial,
  BoxGeometry,
  OrthographicCamera,
} from 'three';
import { COLOR_HIGHLIGHTED, COLOR_SELECTED } from '../constants';
import { Highlightable, Selectable } from '../types';
import { BLUE_GREY } from '../util/palettes';

const pool: Vertex3JS[] = [];
export const getFromVertexPool = (): Vertex3JS => {
  let obj: Vertex3JS | undefined;
  if (pool.length > 0) {
    obj = pool.pop();
  }
  if (!obj) obj = new Vertex3JS();
  return obj;
};

export const putToVertexPool = (vertex: Vertex3JS): void => {
  vertex.isHighlighted = false;
  vertex.isSelected = false;
  pool.push(vertex);
};

const v3 = new Vector3();

const material = new MeshBasicMaterial({ color: BLUE_GREY });
const materialSelected = new MeshBasicMaterial({ color: COLOR_SELECTED });
const materialHighlighted = new MeshBasicMaterial({ color: COLOR_HIGHLIGHTED });
const geometry = new BoxGeometry(0.25, 0.25, 0.25);

export class Vertex3JS extends Mesh implements Selectable, Highlightable {
  static PRECISION = 2;
  public readonly isVertex3JS = true;
  private _scaleFactor = 1;
  private _isHighlighted = false;
  private _isSelected = false;

  constructor() {
    super(geometry, material);
    this.name = `Vertex3JS-${pool.length}`;

    this.onBeforeRender = (renderer, scene, camera) => {
      if ('isOrthographicCamera' in camera) {
        const cam = camera as OrthographicCamera;
        const min = Math.min(cam.top - cam.bottom, cam.right - cam.left);
        this.scale.setScalar((min / cam.zoom) * 0.1 * this._scaleFactor);
      } else {
        this.scale.setScalar(
          this.getWorldPosition(v3).sub(camera.position).length() *
            0.02 *
            this._scaleFactor,
        );
      }
    };
  }

  public set = (pos: Vector3): void => {
    this.position.set(
      round(pos.x, Vertex3JS.PRECISION),
      round(pos.y, Vertex3JS.PRECISION),
      round(pos.z, Vertex3JS.PRECISION),
    );
  };

  private calcAppearance = (): void => {
    const mat = this._isSelected
      ? materialSelected
      : this._isHighlighted
      ? materialHighlighted
      : material;
    this.material = mat;

    this._scaleFactor = this._isHighlighted && !this._isSelected ? 1.5 : 1;
  };

  public set isSelected(value: boolean) {
    if (this._isSelected === value) return;
    this._isSelected = value;
    this.calcAppearance();
  }
  public get isSelected(): boolean {
    return this._isSelected;
  }

  public set isHighlighted(value: boolean) {
    if (this._isHighlighted === value) return;
    this._isHighlighted = value;
    this.calcAppearance();
  }
  public get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  public get scaleFactor(): number {
    return this._scaleFactor;
  }
  public set scaleFactor(value: number) {
    this._scaleFactor = value;
    this.calcAppearance();
  }
}
