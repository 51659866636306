/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useRef } from 'react';
import { useAppState } from '../../../Stores';
import { Time } from './Time';
import { TimePlayback } from '../../../playback/TimePlayback';
import { Visibilities } from '../visibilities/Visibilities';
import { Tools } from './tools/Tools';
import { PlayControl } from './PlayControl';
import { CamNav } from './CamNav';
import { Context } from '../../../types';
import CollapseToggle from '../pane/PaneCollapseToggle';
import { FrameNumber } from './FrameNumber';
import AutoRotate from './AutoRotate';
import { ActionsPanel } from './ActionsPanel';
import { VisibilityButton } from '../visibilities/VisibilityButton';
import {
  LABELS_CONTAINER_CLASSNAME,
  SELECTION_DIV_ID,
} from '../../../constants';
import { InputModeIndicator } from './InputModeIndicator';
import FullScreen from './FullScreen';

export const Viewport = ({
  context,
  viewportWrapper,
}: {
  context: Context;
  viewportWrapper: HTMLDivElement | null;
}): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const state = useAppState();

  const { isFullScreen, mode, inputMode } = state.app;

  const isViewer = mode === 'viewer';
  const isZone = mode === 'zone';
  const isSetup = mode === 'setup';
  const isRecording = mode === 'recording';
  const isPreferences = mode === 'preferences';
  const isInputPlayback = inputMode === 'playback';
  const isMain = isViewer || isZone || isSetup || isRecording || isPreferences;

  const isPaneCollapseToggleVisible =
    (isViewer || isPreferences || isRecording || isInputPlayback) &&
    !isFullScreen;

  useEffect(() => {
    if (!ref.current) return;
    context.labelsContainer.className = LABELS_CONTAINER_CLASSNAME;
    context.selectDiv.id = SELECTION_DIV_ID;

    ref.current.insertBefore(context.selectDiv, ref.current.children[0]);
    ref.current.insertBefore(context.labelsContainer, context.selectDiv);
    ref.current.insertBefore(context.canvas, context.labelsContainer);
  }, [ref]);

  return (
    <div id="Content" ref={ref}>
      {/* programmatically, canvas will be inserted here */}
      {/* and labels container here */}
      {/* and selectDiv here */}
      <InputModeIndicator />
      <Visibilities />
      <Tools />
      <ActionsPanel />

      {isMain ? <Time /> : null}
      {isInputPlayback ? <TimePlayback /> : null}

      {isMain ? <FrameNumber /> : null}
      <PlayControl />
      <FullScreen content={viewportWrapper} />

      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          padding: '2.7rem',
          zIndex: 12,
          display: 'flex',
        }}
      >
        <VisibilityButton
          cluster="GlobalVis"
          icon="Image"
          isActive={state.app.visibilities.Image}
        />
        <AutoRotate />
      </div>

      <CamNav context={context} />
      <CollapseToggle side="left" visible={isPaneCollapseToggleVisible} />
      <CollapseToggle side="right" visible={isPaneCollapseToggleVisible} />
    </div>
  );
};
Viewport.displayName = 'Viewport';
