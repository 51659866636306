/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { REST, SECONDS_TO_MS, NODE_POLL_INTERVAL } from '../constants';
import { safeFetch } from '../safeFetch';

export type NodeStatus = {
  hostname: string;
  id: string;
  perception_client: string;
  raw_frame_client: string;
  rest_client: string;
  license_info: {
    expiration_time: number;
    in_use_licenses: number;
    total_licenses: number;
  };
};
export interface NodeResponse {
  nodes: NodeStatus[];
}

export const getAllNodes = async (
  timeout = NODE_POLL_INTERVAL,
): Promise<NodeResponse | null> =>
  safeFetch.json.get<NodeResponse>(REST.node.all, { timeout });

const DEFAULT_NODE_MUTATION_TIMEOUT = 120 * SECONDS_TO_MS;

export const addNode = async (
  id: string,
  hostname: string,
  username: string,
  password: string,
  port: number | null,
  abortSignal: AbortSignal,
  onSuccess: (response: Response) => void,
  onError: (response: Response) => void,
  onNetworkError: () => void,
  timeout = DEFAULT_NODE_MUTATION_TIMEOUT,
): Promise<string | null> =>
  safeFetch.text.put<string>(
    REST.node.add,
    {
      id,
      hostname,
      username,
      password,
      ...(port && { port }),
    },
    {
      timeout,
      signal: abortSignal,
      onSuccess,
      onError,
      onNetworkError,
    },
  );

export const removeNode = async (
  id: string,
  timeout = DEFAULT_NODE_MUTATION_TIMEOUT,
): Promise<NodeResponse | null> =>
  safeFetch.text.delete<NodeResponse>(REST.node.remove(id), {
    timeout,
  });

export const node = {
  all: getAllNodes,
  add: addNode,
  remove: removeNode,
} as const;
