/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import {
  Mesh,
  Vector3,
  MeshBasicMaterial,
  OrthographicCamera,
  ConeBufferGeometry,
} from 'three';
import {
  COLOR_HIGHLIGHTED,
  COLOR_IDEAL,
  COLOR_SELECTED,
  UNIT_Z,
} from '../../constants';
import { Highlightable, Selectable } from '../../types';

const v3 = new Vector3();

const material = new MeshBasicMaterial({ color: COLOR_IDEAL });
const materialSelected = new MeshBasicMaterial({ color: COLOR_SELECTED });
const materialHighlighted = new MeshBasicMaterial({ color: COLOR_HIGHLIGHTED });
const geometry = new ConeBufferGeometry(0.25, 0.5, 4)
  .rotateX(Math.PI / 2)
  .translate(0, 0, 0.25);

export class MinMaxHandle extends Mesh implements Selectable, Highlightable {
  public readonly isMinMaxHandle = true;
  public scaleFactor = 1;
  private _isHighlighted = false;
  private _isSelected = false;

  constructor() {
    super(geometry, material);
    this.name = `MinMaxHandle`;

    this.onBeforeRender = (renderer, scene, camera) => {
      const eye = v3.copy(this.position).sub(camera.position).normalize();
      const rads = Math.acos(eye.dot(UNIT_Z));
      let visibleFactor = 1 - 2 * Math.abs(rads / Math.PI - 0.5);
      visibleFactor = visibleFactor > 0.6 ? 1 : 0;

      if ('isOrthographicCamera' in camera) {
        const cam = camera as OrthographicCamera;
        const max = Math.max(cam.top - cam.bottom, cam.right - cam.left);
        this.scale.setScalar(
          (max / cam.zoom) * 0.05 * this.scaleFactor * visibleFactor,
        );
      } else {
        this.scale.setScalar(
          this.getWorldPosition(v3).sub(camera.position).length() *
            0.02 *
            this.scaleFactor *
            visibleFactor,
        );
      }
    };
  }

  private calcMaterial = (): void => {
    const mat = this._isHighlighted
      ? materialHighlighted
      : this._isSelected
      ? materialSelected
      : material;
    this.material = mat;
  };

  public set isSelected(value: boolean) {
    if (this._isSelected === value) return;
    this._isSelected = value;
    this.calcMaterial();
  }
  public get isSelected(): boolean {
    return this._isSelected;
  }

  public set isHighlighted(value: boolean) {
    if (this._isHighlighted === value) return;
    this._isHighlighted = value;
    this.calcMaterial();
  }
  public get isHighlighted(): boolean {
    return this._isHighlighted;
  }
}
