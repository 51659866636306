/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { Color } from 'three';
import { GridHelper } from 'three/src/helpers/GridHelper';
import { useAppState } from '../../Stores';
import { Context, Themes } from '../../types';

export const usePreferences = (context: Context): void => {
  const state = useAppState();

  // theme class
  useEffect(() => {
    Themes.forEach((c) => document.body.classList.remove(c));
    document.body.classList.add(state.app.theme);
  }, [state.app.theme]);

  // viewport color
  useEffect(() => {
    const colors = state.app.colors[state.app.theme];
    context.canvas.style.backgroundColor = colors.viewport;
  }, [
    state.app.colors.Dark.viewport,
    state.app.colors.Light.viewport,
    state.app.theme,
  ]);

  // Grid color and size
  useEffect(() => {
    const colors = state.app.colors[state.app.theme];
    context.gridCartesian.removeFromParent();
    const grid = new GridHelper(
      2 * state.app.gridRange.cartesian,
      (2 * state.app.gridRange.cartesian) / state.app.gridSpacing.cartesian,
      new Color(colors.cartesianGrid).offsetHSL(0, 0, 0.1),
      colors.cartesianGrid,
    );
    grid.name = 'grid';
    grid.rotateX(Math.PI / 2);
    context.gridCartesian = grid;
    context.viz.scene.add(grid);
  }, [
    state.app.gridSpacing.cartesian,
    state.app.gridRange.cartesian,
    state.app.colors.Dark.cartesianGrid,
    state.app.colors.Light.cartesianGrid,
    state.app.theme,
  ]);

  // Range ring color
  useEffect(() => {
    const colors = state.app.colors[state.app.theme];
    if (colors.polarGrid === undefined) return;
    context.gridPolar.updateStyle({
      color: new Color(colors.polarGrid),
    });
  }, [
    state.app.colors.Dark.polarGrid,
    state.app.colors.Light.polarGrid,
    state.app.theme,
  ]);
};
