/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ReactNode, useState } from 'react';
import './Tooltip.css';

const TooltipOffsets = ['top', 'bottom', 'right', 'left'] as const;
type TooltipOffsets = typeof TooltipOffsets[number];

interface TooltipProps {
  children: ReactNode;
  tooltipText: string;
  offset?: TooltipOffsets;
  closeOnClick?: boolean;
  wrapperStyle?: React.CSSProperties;
  disabled?: boolean;
}
export interface TooltipInterface {
  text: string;
  offset?: TooltipOffsets;
  wrapperStyle?: React.CSSProperties;
  closeOnClick?: boolean;
}

const defaultStyle: React.CSSProperties = {
  visibility: 'visible',
  color: 'var(--greyB)',
  alignItems: 'center',
  borderRadius: '0.25rem',
  zIndex: 3,
};

const Tooltip = ({
  children,
  offset,
  tooltipText,
  closeOnClick,
  wrapperStyle,
  disabled = false,
}: TooltipProps): JSX.Element => {
  const [active, setActive] = useState(true);

  return (
    <div
      className="Tooltip-Wrapper items-center"
      style={{
        ...wrapperStyle,
        display: 'flex',
        position: 'relative',
      }}
      // Support forced dissapearing on click
      onMouseDown={() => closeOnClick && setActive(false)}
      onMouseLeave={() => setTimeout(() => setActive(true), 200)}
    >
      {/* Wrapping */}
      {children}
      <div
        style={{
          ...defaultStyle,
          visibility: active && !disabled ? 'visible' : 'hidden',
        }}
        className={`Tooltip-Tip drop-shadow-md ${offset || 'top'}`}
      >
        {/* Content */}
        {tooltipText}
      </div>
    </div>
  );
};

export default Tooltip;
