/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { debounce, DebouncedFunc } from 'lodash';
import { DependencyList, useEffect, useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any) => any>(
  funct: T,
  wait = 200,
  deps: DependencyList = [],
): DebouncedFunc<T> => {
  const debounceFunc = useMemo(() => debounce(funct, wait), deps);

  useEffect(() => {
    return () => debounceFunc.cancel();
  }, deps);

  return debounceFunc;
};
