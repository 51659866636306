/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ComponentPropsWithoutRef } from 'react';

/**
 * This subheader should be used in favour of
 * side panes for text-only views.
 */
const ActionSubHeader = ({
  children,
  style,
}: ComponentPropsWithoutRef<'div'>): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'var(--sub-header-h)',
        padding: 'var(--r2)',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default ActionSubHeader;
