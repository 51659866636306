/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Quaternion } from 'three';
import {
  DEFAULT_SENSOR_CLOUD_POINTS_SIZE,
  MAX_POINTS_SIZE,
  MIN_POINTS_SIZE,
  PRESET_RANGES,
} from '../constants';
import { loadFromLocalStorage, saveToLocalStorage } from '../util/localStorage';
import { CloudDisplayMode, Pose, InputMode, SourceStateBase } from '../types';
import { HUES } from '../util/palettes';

export const LOCALSTORAGE_KEY = 'Sensor';

export type SensorType = InputMode | 'mock';
export type SensorParams = {
  // CHECK: if this is the level up transform, there is no need for translation and continuously applying it
  translation: number[];
  quaternionRotation: number[];
  points: Float32Array;
  signal: Uint8Array;
  reflectivity: Uint8Array;
  nearir: Uint8Array;
};
export type SensorSchema = {
  id: string;
  params: SensorParams;
};
export type SensorDiscoverSchema = {
  id: string;
  hostname: string;
  ip: string;
  isAdded: boolean;
  type: SensorType;
  isNode: boolean;
  isReachable: boolean;
};

export type SensorActions =
  | { type: 'setSensor'; schema: SensorSchema }
  | { type: 'setRemoveSensor'; id: string }
  | { type: 'setSensorsReset' }
  | {
      type: 'setSensorsDiscover';
      sensors: SensorDiscoverSchema[];
    }
  | { type: 'setSensorsDisplayMode'; value: CloudDisplayMode }
  | {
      type: 'setSensorsDisplayModePreset';
      value: number;
    }
  | { type: 'setSensorVisibility'; id: string; value: boolean }
  | { type: 'setSensorReachable'; id: string; value: boolean }
  | { type: 'setSensorEnable'; id: string; value: boolean }
  | { type: 'setSensorPointSize'; id: string; value: number }
  | {
      type: 'setSensorColor';
      variant: 'colorMin' | 'colorMax';
      id: string;
      colorIndex: number;
    }
  | {
      type: 'setSensorMinMax';
      variant: 'min' | 'max';
      mode: CloudDisplayMode;
      value: number;
    }
  | { type: 'setSensorExtrinsic'; id: string; value: Pose }
  | { type: 'setSensorTransformed'; id: string; value: boolean };

export type SensorState = SourceStateBase & {
  isNodes: { [id: string]: boolean };
  types: { [id: string]: SensorType };
  params: { [id: string]: SensorParams };
  imus: { [id: string]: Quaternion };
};

export type SensorStored = Pick<
  SensorState,
  'display' | 'pointSize' | 'visibilities'
>;

const sensorLoaded = (loadFromLocalStorage(LOCALSTORAGE_KEY) ??
  {}) as SensorStored;

export const SensorInitialState: SensorState = {
  display: {
    ...{
      colorMode: sensorLoaded?.display?.colorMode ?? 'CalRef',
      colorMin: {},
      colorMax: {},
      colorRange: sensorLoaded?.display?.colorRange ?? PRESET_RANGES,
    },
    ...sensorLoaded?.display,
  },
  allIds: [],
  isNodes: {},
  hostnames: {},
  ips: {},
  params: {},
  visibilities: sensorLoaded?.visibilities ?? {},
  addedStates: {},
  types: {},
  reachableStates: {},
  pointSize: sensorLoaded?.pointSize ?? {},
  imus: {},
  extrinsics: {},
  transformed: {},
};

export const baseSensorReducer = (
  state: SensorState,
  action: SensorActions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): SensorState => {
  switch (action.type) {
    case 'setSensor': {
      const { schema } = action;
      const { id } = schema;
      const isNew = state.params[id] === undefined;
      const params = { ...state.params, [id]: schema.params };
      const [w, x, y, z] = schema.params.quaternionRotation;
      const imus = { ...state.imus, [id]: new Quaternion(x, y, z, w) };

      if (isNew) {
        const allIds = [...state.allIds, id];
        const visibilities = {
          ...state.visibilities,
          [id]: sensorLoaded?.visibilities?.[id] ?? true,
        };
        const pointSize = {
          ...state.pointSize,
          [id]:
            sensorLoaded?.pointSize?.[id] ?? DEFAULT_SENSOR_CLOUD_POINTS_SIZE,
        };
        const colorMin = {
          ...state.display.colorMin,
          [id]: sensorLoaded?.display?.colorMin?.[id] ?? HUES.PURPLE,
        };
        const colorMax = {
          ...state.display.colorMax,
          [id]: sensorLoaded?.display?.colorMax?.[id] ?? HUES.MAGENTA,
        };
        const display = {
          ...state.display,
          colorMin,
          colorMax,
        };
        const extrinsics = {
          ...state.extrinsics,
          [id]: null,
        };

        const transformed = { ...state.transformed, [id]: false };

        return {
          ...state,
          allIds,
          params,
          visibilities,
          pointSize,
          display,
          imus,
          extrinsics,
          transformed,
        };
      } else {
        return {
          ...state,
          params,
          imus,
        };
      }
    }
    case 'setRemoveSensor': {
      const { id } = action;

      const allIds = state.allIds.filter((sensorId) => sensorId !== id);
      const addedStates = { ...state.addedStates };
      delete addedStates[id];
      const reachableStates = { ...state.reachableStates };
      delete reachableStates[id];
      const isNodes = { ...state.isNodes };
      delete isNodes[id];
      const hostnames = { ...state.hostnames };
      delete hostnames[id];
      const ips = { ...state.ips };
      delete ips[id];
      const types = { ...state.types };
      delete types[id];
      const params = { ...state.params };
      delete params[id];
      const visibilities = { ...state.visibilities };
      delete visibilities[id];
      // Don't delete pointSize and colorMin/colorMax because they're nice to have saved.
      const imus = { ...state.imus };
      delete imus[id];
      const extrinsics = { ...state.extrinsics };
      delete extrinsics[id];
      const transformed = { ...state.transformed };
      delete transformed[id];

      return {
        ...state,
        addedStates,
        allIds,
        extrinsics,
        hostnames,
        imus,
        ips,
        params,
        reachableStates,
        transformed,
        types,
        visibilities,
      };
    }
    case 'setSensorsDiscover': {
      let globalNeedsUpdate = false;
      const inputMode = action.sensors.some((sensor) => sensor.type === 'pcap')
        ? 'pcap'
        : 'live';

      const addedStates = { ...state.addedStates };
      const reachableStates = { ...state.reachableStates };
      const isNodes = { ...state.isNodes };
      const hostnames = { ...state.hostnames };
      const ips = { ...state.ips };
      const types = { ...state.types };

      action.sensors.forEach((sensor) => {
        if (sensor.type !== inputMode) {
          return;
        }

        const { id, hostname, ip, type, isAdded, isNode, isReachable } = sensor;

        const isNew = addedStates[id] === undefined;
        const nodesChanged = isNodes[id] !== isNode;
        const hostnameChanged = hostnames[id] !== hostname;
        const ipChanged = ips[id] !== ip;
        const addedStatusChanged = addedStates[id] !== isAdded;
        const reachableStatusChanged = reachableStates[id] !== isReachable;
        const typeChanged = types[id] !== type;

        const needsUpdate =
          isNew ||
          nodesChanged ||
          hostnameChanged ||
          addedStatusChanged ||
          reachableStatusChanged ||
          typeChanged ||
          ipChanged;
        if (!needsUpdate) {
          return;
        }
        globalNeedsUpdate = true;

        addedStates[id] = isAdded;
        reachableStates[id] = isReachable;
        isNodes[id] = isNode;
        hostnames[id] = hostname;
        ips[id] = ip;
        types[id] = type;
      });

      return globalNeedsUpdate
        ? {
            ...state,
            addedStates,
            reachableStates,
            isNodes,
            hostnames,
            ips,
            types,
          }
        : state;
    }
    case 'setSensorsReset': {
      const addedStates = {};
      const reachableStates = {};
      const isNodes = {};
      const hostnames = {};
      const ips = {};
      const types = {};

      return {
        ...state,
        addedStates,
        reachableStates,
        isNodes,
        hostnames,
        ips,
        types,
      };
    }
    case 'setSensorsDisplayMode': {
      if (state.display.colorMode === action.value) return state;
      return {
        ...state,
        display: { ...state.display, colorMode: action.value },
      };
    }
    case 'setSensorVisibility': {
      if (state.visibilities[action.id] === action.value) return state;
      const visibilities = { ...state.visibilities, [action.id]: action.value };
      return { ...state, visibilities };
    }
    // No setSensorAdded because setSensorsDiscover takes care of it
    case 'setSensorReachable': {
      if (state.reachableStates[action.id] === action.value) return state;
      const reachableStates = {
        ...state.reachableStates,
        [action.id]: action.value,
      };

      // If a sensor isn't reachable, we assume it isn't added. We should reach all added sensors.
      const addedStates = !action.value
        ? {
            ...state.addedStates,
            [action.id]: action.value,
          }
        : state.addedStates;

      return { ...state, addedStates, reachableStates };
    }
    case 'setSensorColor': {
      if (state.display[action.variant][action.id] === action.colorIndex)
        return state;

      const c = { [action.id]: action.colorIndex };
      return {
        ...state,
        display: {
          ...state.display,
          [action.variant]: { ...state.display[action.variant], ...c },
        },
      };
    }
    case 'setSensorMinMax': {
      if (
        state.display.colorRange[action.mode][action.variant] === action.value
      ) {
        return state;
      }

      return {
        ...state,
        display: {
          ...state.display,
          colorRange: {
            ...state.display.colorRange,
            [action.mode]: {
              ...state.display.colorRange[action.mode],
              [action.variant]: action.value,
            },
          },
        },
      };
    }
    case 'setSensorPointSize': {
      const v = Math.min(
        Math.max(MIN_POINTS_SIZE, state.pointSize[action.id] + action.value),
        MAX_POINTS_SIZE,
      );
      if (state.pointSize[action.id] === v) return state;
      const pointSize = { ...state.pointSize, [action.id]: v };
      return { ...state, pointSize };
    }
    case 'setSensorExtrinsic': {
      if (
        JSON.stringify(state.extrinsics[action.id]) ===
        JSON.stringify(action.value)
      )
        return state;
      const extrinsics = {
        ...state.extrinsics,
        [action.id]: action.value,
      };
      return { ...state, extrinsics };
    }
    case 'setSensorTransformed': {
      if (state.transformed[action.id] === action.value) return state;

      const transformed = {
        ...state.transformed,
        [action.id]: action.value,
      };
      return { ...state, transformed };
    }
    default:
      return state;
  }
};

const triggerSensorSaveSet: Set<SensorActions['type']> = new Set([
  'setSensorsDisplayMode',
  'setSensorsDisplayModePreset',
  'setSensorVisibility',
  'setSensorPointSize',
]);

export const SensorReducer = (
  state: SensorState,
  action: SensorActions,
): SensorState => {
  const newState = baseSensorReducer(state, action);

  const stored: SensorStored = {
    display: newState.display,
    pointSize: newState.pointSize,
    visibilities: newState.visibilities,
  };
  if (triggerSensorSaveSet.has(action.type))
    saveToLocalStorage(LOCALSTORAGE_KEY, stored);

  return newState;
};
