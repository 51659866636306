/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppState } from '../Stores';
import { Context } from '../types';
import { Raycaster, Vector2, Vector4 } from 'three';
import { PointerType, usePointerEvents } from '../app/hooks/usePointerEvents';
import { Zone3JS } from './Zones3JS';
import { round } from 'lodash';
import { Vertex3JS } from './Vertex3JS';

// These hooks allow the user to select zone objects
export const useZoneSelect = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const highlighted = useRef<Zone3JS | null>(null);
  const rayCaster = useRef<Raycaster | null>(null);
  useEffect(() => {
    rayCaster.current = new Raycaster();
  }, []);

  // attach transform controller when selection exists and we are in camera mode
  useEffect(() => {
    const id = state.zones.selected;
    if (id === null) return;
    if (state.app.tool !== 'Camera') return;
    const zoneType = state.zones.params[id].type;
    if (
      !state.zones.visibilities[id] ||
      !state.zones.typeVisibilities[zoneType]
    ) {
      dispatch({
        type: 'setFeedbackMessage',
        value: { type: 'warning', message: 'Make zone visible to transform' },
      });
      return;
    }
    const zone3JS = context.instances.Zones.all[id];

    dispatch({
      type: 'setFeedbackMessage',
      value: {
        type: 'info',
        message:
          'Click and drag the widgets to transform, arrows moves along axis, L shaped corners along plane, disk rotates',
      },
    });
    const dispatchPolyChange = () => {
      const id = state.zones.selected;
      if (id === null) return;

      const params = state.zones.zoneRedefined[id] || state.zones.params[id];

      const m = zone3JS.transformInverse.matrixWorld;

      const transformedPoints = params.vertices
        .map((v2) => new Vector4(v2.x, v2.y, 0, 1).applyMatrix4(m))

        .map(
          (v4) =>
            new Vector2(
              round(v4.x, Vertex3JS.PRECISION),
              round(v4.y, Vertex3JS.PRECISION),
            ),
        );

      dispatch({
        type: 'setZoneRedefined',
        id,
        value: { ...params, vertices: transformedPoints },
      });

      dispatch({
        type: 'setFeedbackMessage',
        value: {
          type: 'info',
          message:
            'Zone has been transformed, click Reset button to revert transformation or Save to store',
        },
      });
    };
    context.transformControls.attach(zone3JS);
    context.viz.scene.add(context.transformControls);
    context.transformControls.active = true;
    context.transformControls.addEventListener('end', dispatchPolyChange);
    context.transformControls.controls.translate.z.visible =
      context.transformControls.controls.translate.yz.visible =
      context.transformControls.controls.translate.xz.visible =
        false;

    return () => {
      context.transformControls.detach();
      context.transformControls.removeFromParent();
      context.transformControls.active = false;
      context.transformControls.removeEventListener('end', dispatchPolyChange);
      context.transformControls.controls.translate.z.visible =
        context.transformControls.controls.translate.yz.visible =
        context.transformControls.controls.translate.xz.visible =
          true;
    };
  }, [
    state.app.tool,
    state.zones.params,
    state.zones.zoneRedefined,
    state.zones.selected,
    state.zones.visibilities,
    state.zones.typeVisibilities,
  ]);

  // Handler for user interaction
  const handler = useCallback(
    (pointer: PointerType) => {
      if (rayCaster.current === null) {
        return;
      }
      const { x, y } = pointer;
      rayCaster.current.setFromCamera({ x, y }, context.viz.camera);

      // filter out only visible instances
      const visible = state.zones.allIds.filter((id) => {
        return (
          state.zones.visibilities[id] &&
          state.zones.typeVisibilities[state.zones.params[id].type]
        );
      });
      const raycastable = visible
        .map((id) => context.instances.Zones.all[id])
        .filter((zone) => zone !== undefined && zone.visible)
        .map((e) => e.raycastMesh);

      const intersections =
        rayCaster.current
          .intersectObjects(raycastable)
          .map((e) => e.object.parent?.parent) ?? [];

      if (intersections.length) {
        // find the closest 'Event' zone type as Inclusion/Exclusion zones are larger than Event zones
        const zoneUnderPointer = (intersections.find(
          (elem) => (elem as Zone3JS).zoneType === 'Event',
        ) ?? intersections[0]) as Zone3JS;

        if (pointer.eventType === 'click') {
          dispatch({
            type: 'setSelectedZone',
            id:
              zoneUnderPointer.oId === state.zones.selected
                ? null
                : zoneUnderPointer.oId,
          });
          // if single selection workflow, as soon the user selects an object go back to navigation
          if (state.app.tool === 'Select')
            dispatch({ type: 'setTool', value: 'Camera' });
          if (highlighted.current) highlighted.current.isHighlighted = false;
          highlighted.current = null;
        } else if (pointer.eventType === 'pointermove') {
          if (highlighted.current === zoneUnderPointer) return;
          if (highlighted.current) highlighted.current.isHighlighted = false;
          zoneUnderPointer.isHighlighted = true;
          highlighted.current = zoneUnderPointer;
        }
      } else {
        if (pointer.eventType === 'still') {
          dispatch({
            type: 'setFeedbackMessage',
            value: {
              type: 'info',
              message: 'Left click to select/deselect a zone',
            },
          });
          return;
        }

        if (highlighted.current) {
          highlighted.current.isHighlighted = false;
          highlighted.current = null;
        }
      }
    },
    [
      state.zones.allIds,
      state.zones.visibilities,
      state.zones.typeVisibilities,
      state.zones.selected,
      state.app.tool,
    ],
  );

  const setPointerEvents = usePointerEvents(context.canvas, handler);

  // Tool logic
  useEffect(() => {
    const selecting = state.app.tool === 'Select';
    if (!selecting) return;
    setPointerEvents(true);
    return () => {
      if (highlighted.current) highlighted.current.isHighlighted = false;
      highlighted.current = null;
      setPointerEvents(false);
    };
  }, [state.app.tool]);

  // Out logic
  useEffect(() => {
    return () => {
      if (highlighted.current) highlighted.current.isHighlighted = false;
      highlighted.current = null;

      dispatch({ type: 'setSelectedZone', id: null });
    };
  }, [state.app.mode]);
};
