/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { HTMLAttributes } from 'react';

export const Row = ({
  blurb,
  disabled = false,
  children,
  ...rest
}: {
  blurb: string;
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div className="flex flex-row justify-between mt-2 mb-2" {...rest}>
    <p
      className={`text-sm mr-2 flex items-center ${
        disabled ? 'textGreyE' : 'textGreyD'
      }`}
      style={{ flexBasis: '50%' }}
    >
      {blurb}
    </p>
    {children}
  </div>
);
