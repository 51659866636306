/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../app/components/Button';
import { FETCH_SETTINGS_FAIL_INTERVAL, SECONDS_TO_MS } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';
import { instanceToDisplayName, SettingsInterface } from './settingsTypes';
import { endpoints } from '../api/endpoints';
import ActionSubHeader from '../app/components/pane/ActionSubHeader';
import { cloneDeep } from 'lodash';
import { Services } from '../types';

const { settings: settingsRest, record: execution } = endpoints;

function RESTButtons({
  profileSaveAsName,
  activeProfile,
  fetchProfiles,
}: {
  profileSaveAsName: string;
  activeProfile: string;
  fetchProfiles: (shouldSetCurrentProfile?: boolean) => void;
}): JSX.Element {
  const state = useAppState();
  const { service } = state;
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState({ base: false, default: false });
  const [previousSettings, setPreviousSettings] = useState(
    {} as SettingsInterface,
  );
  const lastPushProfile = useRef('');
  const fetchTimeout = useRef<NodeJS.Timeout | null>(null);
  const { selectedInstance } = state.settings;
  const serviceName: Services = (selectedInstance + 'Rest') as Services;
  const [isReachable, setIsReachable] = useState(
    service[serviceName].isReachable,
  );
  const [isRestarting, setIsRestarting] = useState(false);
  const {
    settings: settingsJSON,
    valid,
    selectedProfile,
  } = state.settings[selectedInstance];
  const isDirty =
    JSON.stringify(settingsJSON) !== JSON.stringify(previousSettings);

  const fetchSettings = async () => {
    setIsFetching({ ...isFetching, base: true });

    const fetchedSettings =
      (await settingsRest[selectedInstance].fetch(selectedProfile)) ?? {};
    if (Object.keys(fetchedSettings).length === 0) {
      console.error(
        `Failed to fetch settings. Retrying in ${
          FETCH_SETTINGS_FAIL_INTERVAL / SECONDS_TO_MS
        } seconds`,
      );

      fetchTimeout.current = setTimeout(
        fetchSettings,
        FETCH_SETTINGS_FAIL_INTERVAL,
      );
      return;
    }

    dispatch({
      type: 'setSettings',
      instance: selectedInstance,
      value: fetchedSettings,
    });

    setPreviousSettings(cloneDeep(fetchedSettings));
    setIsFetching({ ...isFetching, base: false });
  };

  useEffect(() => {
    fetchSettings();
  }, [
    state.settings.selectedInstance,
    state.settings.perception.selectedProfile,
    state.settings.lidarHub.selectedProfile,
  ]);

  useEffect(() => {
    fetchTimeout.current && clearTimeout(fetchTimeout.current);
    fetchTimeout.current = null;
  }, [state.settings.selectedInstance]);

  useEffect(() => {
    setIsReachable(service[serviceName].isReachable ?? false);
    if (isReachable) setIsRestarting(false);
  }, [service[serviceName].isReachable]);

  return (
    <ActionSubHeader>
      <Button
        className="action p-2"
        style={{ margin: 0, marginRight: 'var(--r2)' }}
        onClick={async () => {
          setIsFetching({ ...isFetching, base: true });
          const settings = await settingsRest[selectedInstance].fetch(
            selectedProfile,
          );
          dispatch({
            type: 'setSettings',
            instance: selectedInstance,
            value: settings,
          });
          setIsFetching({ ...isFetching, base: false });
          setPreviousSettings(cloneDeep(settings));
        }}
      >
        {isFetching.base ? 'Fetching...' : 'Fetch Settings'}
      </Button>
      <Button
        className="action p-2"
        style={{ margin: 0, marginRight: 'var(--r2)' }}
        onClick={async () => {
          setIsFetching({ ...isFetching, default: true });
          let settings;
          if (selectedInstance === 'lidarHub') {
            await settingsRest.lidarHub.fetchDefault();
            settings = await settingsRest.lidarHub.fetch(selectedProfile);
          } else {
            await settingsRest.perception.restoreProfile(selectedProfile);
            settings = await settingsRest.perception.getProfile(
              selectedProfile,
            );
          }
          dispatch({
            type: 'setSettings',
            instance: selectedInstance,
            value: settings,
          });
          // The default changes are already applied
          setPreviousSettings(settings);
          setIsFetching({ ...isFetching, default: false });
        }}
      >
        {isFetching.default ? 'Fetching...' : 'Reset to Default'}
      </Button>
      <Button
        className="action p-2"
        style={{ margin: 0, marginRight: 'var(--r2)' }}
        onClick={async () => {
          let response;
          let settingsName;

          if (selectedInstance === 'perception') {
            const profileName = profileSaveAsName
              ? profileSaveAsName
              : selectedProfile;
            settingsName = profileName;
            response = await settingsRest.perception.updateProfile(
              profileName,
              settingsJSON,
            );

            if (profileSaveAsName) {
              fetchProfiles();
            }
            lastPushProfile.current = profileSaveAsName;
          } else {
            response = await settingsRest[selectedInstance].push(settingsJSON);
            settingsName = 'perception client';
          }

          const success = response !== null;
          dispatch({
            type: 'enqueueFeedbackMessage',
            value: {
              message: `${
                success ? 'Success in' : 'There was an error in'
              } pushing ${settingsName} settings.`,
              type: success ? 'info' : 'error',
              priority: 10,
              durationMs: 2000,
            },
          });
          setPreviousSettings(cloneDeep(settingsJSON));
        }}
        // Disabled if there's an error OR settings aren't changed and we're pushing to the same profile
        disabled={
          !(isDirty || profileSaveAsName !== lastPushProfile.current) || !valid
        }
      >
        Push Settings
      </Button>
      <Button
        className="action p-2"
        disabled={!isReachable}
        style={{
          margin: 0,
          marginRight: 'var(--r2)',
          ...(isReachable ? { color: 'var(--warning)' } : {}),
        }}
        onClick={async () => {
          setIsRestarting(true);
          setIsReachable(false);
          await execution.reset[selectedInstance]();
        }}
      >
        {isReachable
          ? `Restart ${instanceToDisplayName[selectedInstance]} App`
          : isRestarting
          ? `Restarting ${instanceToDisplayName[selectedInstance]}...`
          : `${instanceToDisplayName[selectedInstance]} not running`}
      </Button>
      <Button
        disabled={
          selectedInstance === 'lidarHub' || selectedProfile === activeProfile
        }
        className="action p-2"
        style={{ margin: 0, marginRight: 'var(--r2)' }}
        onClick={async () => {
          await settingsRest.perception.activateProfile(selectedProfile);
          await fetchProfiles();
        }}
      >
        Activate Profile
      </Button>
      <Button
        disabled={
          selectedInstance === 'lidarHub' ||
          selectedProfile === 'default_settings' ||
          selectedProfile === 'pedestrian_settings' ||
          selectedProfile === activeProfile
        }
        className="action p-2"
        style={{ margin: 0 }}
        onClick={async () => {
          await settingsRest.perception.deleteProfile(selectedProfile);
          await fetchProfiles(true);
        }}
      >
        Delete Profile
      </Button>
    </ActionSubHeader>
  );
}

export default RESTButtons;
