/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { useAppState, useAppDispatch } from '../Stores';
import { EyeToggle } from '../app/components/pane/eye/Visible';
import { ListContainer } from '../app/components/pane/ListContainer';
import { Button } from '../app/components/Button';
import { VisibilityIcon } from '../app/components/visibilities/VisibilityIcon';
import { SelectableListElem } from '../app/components/pane/SelectableListElem';
import { ColorDot } from '../app/components/ColorDot';
import { Palettes } from '../constants';
import { trimTo } from '../util/misc';

const Elem = ({
  id,
  name,
  color,
  isVisible,
  isLabelVisible,
  isSelected,
  isDirty = false,
}: {
  id: string;
  name: string;
  color: string;
  isVisible: boolean;
  isLabelVisible: boolean;
  isSelected: boolean;
  isDirty?: boolean;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex items-center justify-between " key={id}>
      <div className="flex justify-center">
        <EyeToggle
          isOn={isVisible}
          onClick={() => {
            dispatch({
              type: 'setZoneVisibility',
              id,
              value: !isVisible,
            });
          }}
        />
        <Button
          className="p-0_5"
          onClick={() => {
            dispatch({
              type: 'setZoneLabelsVisibility',
              id,
              value: !isLabelVisible,
            });
          }}
        >
          <VisibilityIcon
            cluster="Zones"
            icon="labels"
            active={isLabelVisible}
          />
        </Button>
      </div>

      <SelectableListElem
        isSelected={isSelected}
        onClick={() => {
          dispatch({ type: 'setSelectedZone', id: isSelected ? null : id });
        }}
        disabled={false}
        isDirty={isDirty}
      >
        <div className="flex flex-row items-center justify-between">
          <ColorDot color={color} />
          {trimTo(name, 14)}
        </div>
      </SelectableListElem>
    </div>
  );
};
const List = ({ name, ids }: { name: string; ids: string[] }): JSX.Element => {
  const state = useAppState();
  const palette = Palettes[state.app.palette];

  return (
    <PropertiesSubGroup
      name={`${name} -  Total: ${ids.length}`}
      expandedInitValue={name !== 'Point'}
    >
      <ListContainer name={``}>
        {ids.map((id) => {
          const { type } = state.zones.params[id];
          const clrIndex = state.zones.zoneTypeColorIndices[type];
          const color = palette[clrIndex % palette.length];
          const isVisible = state.zones.visibilities[id];
          const isLabelVisible = state.zones.labelVisibilities[id];
          const name = state.zones.params[id].name;
          const isDirty = state.zones.zoneRedefined[id] !== undefined;

          return (
            <Elem
              id={id}
              color={color}
              isLabelVisible={isLabelVisible}
              isSelected={state.zones.selected === id}
              isVisible={isVisible}
              key={id}
              name={name}
              isDirty={isDirty}
            />
          );
        })}
      </ListContainer>
    </PropertiesSubGroup>
  );
};

export const SelectableZoneList = (): JSX.Element => {
  const state = useAppState();

  const event = state.zones.allIds.filter(
    (id) => state.zones.params[id].type === 'Event',
  );

  const points = state.zones.allIds.filter((id) => {
    const type = state.zones.params[id].type;
    return type === 'Inclusion' || type === 'Exclusion';
  });

  return (
    <>
      <List name="Event" ids={event} />
      <List name="Point" ids={points} />
    </>
  );
};
