/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';

const VIEWBOX = 18;
export const SearchIcon = ({
  active,
  size = 18,
  ...rest
}: {
  active?: boolean;
  size?: number;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  const t = (VIEWBOX - size) / 2;
  const conditionalProps = {
    ...(active !== undefined && {
      fill: active ? 'var(--greyB)' : 'var(--greyE)',
    }),
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox={`0 0 ${VIEWBOX} ${VIEWBOX}`}
      {...conditionalProps}
      {...rest}
    >
      <path
        transform={`translate(${t}, ${t})`}
        d="M16.5865,15.107,12.7,11.2215A6.413,6.413,0,1,0,11.2215,12.7l3.886,3.886a1.05,1.05,0,0,0,1.479-1.479ZM3,7.5A4.5,4.5,0,1,1,7.5,12,4.5,4.5,0,0,1,3,7.5Z"
      />
    </svg>
  );
};
