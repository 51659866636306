/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useState } from 'react';
import { activateLicense, getAgentStatus } from '../api/agent';
import { Button } from '../app/components/Button';
import Input from '../app/components/Input/Input';
import LoadingSpinner from '../app/components/LoadingSpinner/LoadingSpinner';
import { PropertiesGroup } from '../app/components/pane/PropertiesGroup';
import { Row } from '../app/components/pane/Row';
import useInterval from '../app/hooks/useInterval';
import { LICENSE_POLL_INTERVAL } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';

type FormStatus = 'normal' | 'waiting' | 'success' | 'error';

export const LicenseProperties = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const [licenseInput, setLicenseInput] = useState('');
  const [totalLicenses, setTotalLicenses] = useState(0);
  const [inUseLicenses, setInUseLicenses] = useState(0);
  const [status, setStatus] = useState<FormStatus>('normal');

  const licenseRegex = /^\s*[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}\s*$/;
  const isLicenseValid = licenseRegex.test(licenseInput);

  const fetchLicenseStatus = async () => {
    const response = await getAgentStatus();
    if (!response) return;

    setTotalLicenses(response.total_licenses);
    setInUseLicenses(response.in_use_licenses);
  };

  const handleSubmitEID = async () => {
    setStatus('waiting');

    try {
      const response = await activateLicense(licenseInput.trim());

      if (response.ok) {
        fetchLicenseStatus(); // Get an updated count of total licenses
        setStatus('success');
      } else {
        const errorMessage = (await response.json())?.message;

        if (errorMessage) {
          dispatch({
            type: 'enqueueFeedbackMessage',
            value: {
              message: errorMessage,
              type: 'error',
              priority: 10,
              durationMs: 10000,
            },
          });
        }

        setStatus('error');
      }
    } catch {
      // If fetch throws it is because of network error
      dispatch({
        type: 'enqueueFeedbackMessage',
        value: {
          message:
            'Failed to reach the Ouster solutions agent. Make sure the agent is running on the same machine as Gemini',
          type: 'error',
          priority: 10,
          durationMs: 10000,
        },
      });

      setStatus('error');
    }

    setLicenseInput('');
  };

  const [, setActive] = useInterval(
    fetchLicenseStatus,
    LICENSE_POLL_INTERVAL,
    false,
  );

  useEffect(() => {
    if (state.app.inputMode !== 'live') return;

    fetchLicenseStatus();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode, state.sensors.addedStates]);

  return (
    <PropertiesGroup name="License">
      <div className="flex flex-col">
        <Row blurb="Activated Sensors:">
          <div className="textGreyD text-base">{inUseLicenses}</div>
        </Row>
        <Row blurb="Remaining Sensors:">
          <div className="textGreyD text-base">
            {totalLicenses - inUseLicenses}
          </div>
        </Row>

        <Input
          name="Entitlement ID"
          value={licenseInput}
          onChange={(e) => {
            setLicenseInput(e.target.value);
            setStatus('normal');
          }}
          disabled={status === 'waiting'}
        />

        {licenseInput && !isLicenseValid && (
          <div className="textError text-sm">Invalid format</div>
        )}

        <Button
          className="action"
          disabled={!isLicenseValid || status === 'waiting'}
          onClick={handleSubmitEID}
        >
          {status === 'waiting' ? <LoadingSpinner /> : <div>Submit</div>}
        </Button>

        {status === 'success' && (
          <div className="textSuccess text-sm">
            Success in activating license
          </div>
        )}

        {status === 'error' && (
          <div className="textError text-sm">Error in activating license</div>
        )}
      </div>
    </PropertiesGroup>
  );
};
