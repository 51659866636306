/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useCallback, useEffect } from 'react';
import {
  BufferGeometry,
  DoubleSide,
  Line,
  LineDashedMaterial,
  Raycaster,
  Vector3,
} from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { COLOR_IDEAL } from '../constants';
import { Measure3JS } from './Measure3JS';
import { useAppDispatch, useAppState } from '../Stores';
import { Context } from '../types';
import { usePlaneIntersection } from '../app/hooks/usePlaneIntersection';
import { PointerType } from '../app/hooks/usePointerEvents';

const v = new Vector3();
const rc = new Raycaster();
const vertexA = new Measure3JS('vertexA');
const vertexB = new Measure3JS('vertexB');
const vertices = [vertexA, vertexB];
const line = new Line(
  new BufferGeometry(),
  new LineDashedMaterial({
    color: COLOR_IDEAL,
    linewidth: 2,
    side: DoubleSide,
    dashSize: 0.9,
    gapSize: 0.1,
  }),
);
line.frustumCulled = false;
let drag = false;
let highlighted: Measure3JS | null = null;
let selected: Measure3JS | null = null;
const length = new CSS2DObject(document.createElement('div'));
length.element.className = 'text-xl px-4 py-2 drop-shadow-md';
length.element.innerHTML = `<div style='color: var(--ideal); position:relative; top:-40px;'></div>`;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const lengthElem = length.element.querySelector('div')!;

const cursorInfo = new CSS2DObject(document.createElement('div'));
cursorInfo.element.className = 'text-sm px-4 py-2 drop-shadow-md';
cursorInfo.element.innerHTML = `<div style='position:relative; top:40px;'></div>`;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const cursorInfoElem = cursorInfo.element.querySelector('div')!;

const updateInfo = (x: number, y: number, z: number): void => {
  const msg = `< ${x.toFixed(1)},   ${y.toFixed(1)},   0 >`;
  cursorInfo.position.set(x, y, z);
  cursorInfoElem.innerHTML = msg;
};

export const useMeasure = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const cb = useCallback((point: Vector3 | null, pointer: PointerType) => {
    if (point === null) return;

    rc.setFromCamera(pointer, context.viz.camera);
    const intersections = rc.intersectObjects(vertices);
    if (highlighted) {
      highlighted.isHighlighted = false;
      highlighted = null;
    }
    const overVertex = intersections.length > 0;
    if (overVertex && !drag) {
      highlighted = intersections[0].object as Measure3JS;
      highlighted.isHighlighted = true;
    }

    const { x, y, z } = point;

    // dispatch({
    //   type: 'setFeedbackMessage',
    //   value: { type: 'info', message: msg },
    // });

    switch (pointer.eventType) {
      case 'pointerdown':
      case 'pointerleave': {
        if (pointer.ctrlKey) {
          dispatch({ type: 'setViewNavigation', value: false });
          const { x, y, z } = point;
          vertexA.position.set(x, y, z);
          vertexB.position.set(x, y, z);
          length.position.set(x, y, z);
          line.geometry.setFromPoints([]);
          vertexA.lookAt(vertexB.position);
          vertexB.lookAt(vertexA.position);

          context.viz.scene.add(vertexA);
          context.viz.scene.add(vertexB);
          vertexA.showLabel(true);
          vertexB.showLabel(true);
          context.viz.scene.add(line);

          vertexA.setLabel();
          vertexB.setLabel();
          lengthElem.innerHTML = `0`;

          context.viz.scene.add(length);

          drag = true;
          selected = vertexB;

          dispatch({
            type: 'setFeedbackMessage',
            value: {
              type: 'info',
              message: 'LEFT CLICK RELEASE: to set the end point ',
            },
          });
        } else if (overVertex && highlighted) {
          selected = highlighted;
          highlighted.isSelected = true;
          drag = true;
          highlighted.position.set(x, y, z);
          dispatch({ type: 'setViewNavigation', value: false });
        }

        cursorInfo.removeFromParent();
        break;
      }
      case 'pointermove': {
        if (drag && selected) {
          selected.position.set(x, y, z);
          line.geometry.setFromPoints([vertexA.position, vertexB.position]);
          line.computeLineDistances();

          selected.setLabel();
          lengthElem.innerHTML =
            v
              .subVectors(vertexA.position, vertexB.position)
              .length()
              .toFixed(2) + ' m';
          //position length label
          v.addVectors(vertexA.position, vertexB.position).multiplyScalar(0.5);
          length.position.copy(v);

          vertexA.lookAt(vertexB.position);
          vertexB.lookAt(vertexA.position);
        }

        updateInfo(x, y, z);

        break;
      }
      case 'pointerenter':
      case 'pointerup': {
        dispatch({ type: 'setViewNavigation', value: true });
        drag = false;
        if (selected) {
          selected.isSelected = false;
          selected = null;
        }

        context.viz.scene.add(cursorInfo);

        if (vertexA.parent)
          dispatch({
            type: 'setFeedbackMessage',
            value: {
              type: 'info',
              message:
                'DRAG RECTS: Adjust measurement, CTRL-CLICK: Start a new measurement',
            },
          });
        break;
      }
    }
  }, []);

  const setIntersectActive = usePlaneIntersection(
    context.canvas,
    context.viz.camera,
    cb,
  );

  useEffect(() => {
    if (state.app.tool !== 'Measure') return;
    setIntersectActive(true);

    context.viz.scene.add(cursorInfo);

    return () => {
      setIntersectActive(false);
      vertexA.removeFromParent();
      vertexB.removeFromParent();
      vertexA.showLabel(false);
      vertexB.showLabel(false);
      line.removeFromParent();
      length.removeFromParent();
      cursorInfo.removeFromParent();
      dispatch({ type: 'setFeedbackMessage', value: null });
      dispatch({ type: 'setViewNavigation', value: true });
    };
  }, [state.app.tool]);
};
