/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-nested-switch */
import {
  Group,
  Object3D,
  Vector3,
  Quaternion,
  Camera,
  Raycaster,
  OrthographicCamera,
  PerspectiveCamera,
  Line,
  BufferGeometry,
  DoubleSide,
  LineDashedMaterial,
} from 'three';
import { Axes, Axis, Context, Plane, Planes, Transform } from '../types';
import { toNDC } from '../util/misc';
import { TransformControlsGizmo } from './TransformControlsGizmo';
import { Space } from '../types';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControlsPlane } from './TransformControlsPlane';
import {
  COLOR_HIGHLIGHTED,
  LAYER_ICONS,
  ORIGIN,
  UNIT_X,
  UNIT_Y,
  UNIT_Z,
} from '../constants';

const rayCaster = new Raycaster();
rayCaster.layers.enable(LAYER_ICONS);
const _tempQuaternion = new Quaternion();
const _tempVector = new Vector3();
const _tempVector2 = new Vector3();
export const unitVectors: Record<Axis | Plane, Vector3> = {
  x: UNIT_X,
  y: UNIT_Y,
  z: UNIT_Z,
  yz: UNIT_X,
  xz: UNIT_Y,
  xy: UNIT_Z,
  view: new Vector3(0, 0, 0), //not used
};
const isLookAtRotation = true;

type PartialControls = Record<
  Partial<Transform>,
  Record<Partial<Axis | Plane>, TransformControlsGizmo>
>;

const line = new Line(
  new BufferGeometry(),
  new LineDashedMaterial({
    color: COLOR_HIGHLIGHTED,
    linewidth: 3,
    side: DoubleSide,
    dashSize: 0.2,
    gapSize: 0.6,
  }),
);

export class TransformControls extends Group {
  public readonly isGizmo3JS = true;
  private _active = false;
  private _space: Space = 'local';
  private _size = 1;
  private _attached: Object3D | null = null;

  private plane: TransformControlsPlane;
  private offset = new Vector3();
  private _startNorm = new Vector3();
  private _endNorm = new Vector3();

  private _parentPosition = new Vector3();
  private _parentQuaternion = new Quaternion();
  private _parentQuaternionInv = new Quaternion();
  private _parentScale = new Vector3();

  private _worldScaleStart = new Vector3();
  private _worldQuaternionInv = new Quaternion();
  private _worldScale = new Vector3();

  private _positionStart = new Vector3();
  private _quaternionStart = new Quaternion();
  private _scaleStart = new Vector3();

  private _changeEvent = { type: 'change' };
  private _endEvent = { type: 'end' };

  private pointStart: Vector3 = new Vector3();
  private pointEnd: Vector3 = new Vector3();
  private rotationAxis: Vector3 = new Vector3();
  private rotationAngle = 0;

  private camera: Camera;
  private eye: Vector3 = new Vector3();
  private dragging = false;
  private axis: Axis | Plane | null = null;
  private worldPosition = new Vector3();
  private worldQuaternion = new Quaternion();
  private worldPositionStart = new Vector3();
  private worldQuaternionStart = new Quaternion();
  private cameraPosition = new Vector3();
  private cameraQuaternion = new Quaternion();

  public controls = { translate: {}, rotate: {} } as PartialControls;
  private allGizmos: TransformControlsGizmo[] = [];
  private canvas: HTMLCanvasElement;
  private orbitControls: OrbitControls;
  private transform: Transform = 'translate';

  constructor(
    assets: Context['assets'],
    canvas: HTMLCanvasElement,
    camera: PerspectiveCamera | OrthographicCamera,
    orbitControls: OrbitControls,
  ) {
    super();
    this.name = 'TransformControls';
    this.canvas = canvas;
    this.camera = camera;
    this.orbitControls = orbitControls;

    this.plane = new TransformControlsPlane();
    this.add(this.plane);

    // Create Rotation Controls
    for (const axis of Axes) {
      const rotate = new TransformControlsGizmo(
        assets.gizmoTransformBuffers.rotation,
        axis,
        'rotate',
      );
      rotate.isActive = !(axis === 'x' || axis === 'y');
      this.controls.rotate[axis as Axis] = rotate;
      this.add(rotate);
      this.allGizmos.push(rotate);
    }

    // Create Translation Controls
    for (const axis of Axes) {
      const translate = new TransformControlsGizmo(
        assets.gizmoTransformBuffers[axis],
        axis,
        'translate',
      );
      this.controls.translate[axis] = translate;
      this.add(translate);
      this.allGizmos.push(translate);
    }
    // Create Planes Controls
    for (const planeT of Planes) {
      const plane = new TransformControlsGizmo(
        planeT === 'view'
          ? assets.gizmoTransformBuffers.view
          : assets.gizmoTransformBuffers[planeT],
        planeT,
        'translate',
      );
      if (planeT === 'view') continue;
      this.controls.translate[planeT] = plane;
      this.add(plane);
      this.allGizmos.push(plane);
    }
  }

  // updateMatrixWorld  updates key transformation variables
  // eslint-disable-next-line sonarjs/cognitive-complexity
  updateMatrixWorld(): void {
    if (this._attached) {
      this._attached.updateMatrixWorld();

      if (!this._attached.parent) {
        console.error(
          'TransformControls: The attached 3D object must be a part of the scene graph.',
        );
      } else {
        this._attached.parent.matrixWorld.decompose(
          this._parentPosition,
          this._parentQuaternion,
          this._parentScale,
        );
      }

      this._attached.matrixWorld.decompose(
        this.worldPosition,
        this.worldQuaternion,
        this._worldScale,
      );

      this._parentQuaternionInv.copy(this._parentQuaternion).invert();
      this._worldQuaternionInv.copy(this.worldQuaternion).invert();
    }

    this.camera.updateMatrixWorld();
    this.camera.matrixWorld.decompose(
      this.cameraPosition,
      this.cameraQuaternion,
      _tempVector,
    );

    this.eye.copy(this.cameraPosition).sub(this.worldPosition).normalize();

    let scaleFactor: number;
    if ('isOrthographicCamera' in this.camera) {
      const cam = this.camera as OrthographicCamera;
      scaleFactor = (cam.top - cam.bottom) / cam.zoom;
    } else {
      const cam = this.camera as PerspectiveCamera;
      scaleFactor =
        this.worldPosition.distanceTo(this.cameraPosition) *
        Math.min((1.9 * Math.tan((Math.PI * cam.fov) / 360)) / cam.zoom, 7);
    }
    this.allGizmos.forEach((gizmo) => {
      gizmo.worldPosition = this.worldPosition;
      gizmo.worldQuaternion = this.worldQuaternion;
      gizmo.eye = this.eye;
      gizmo.scaleFactor = scaleFactor * this.size * 0.05;
    });
    this.plane.worldPosition = this.worldPosition;
    this.plane.worldQuaternion = this.worldQuaternion;
    this.plane.eye = this.eye;

    super.updateMatrixWorld(this._active);
  }

  onPointerHover = (event: PointerEvent): void => {
    if (!this._active) return;

    switch (event.pointerType) {
      case 'mouse':
      case 'pen':
        if (this._attached === undefined || this.dragging) return;

        // clear all highlights
        this.allGizmos.forEach((gizmo) => {
          gizmo.isHighlighted = false;
        });

        const coord = toNDC(event.clientX, event.clientY, this.canvas);
        rayCaster.setFromCamera(coord, this.camera);
        const intersections = rayCaster.intersectObjects(
          this.allGizmos.filter((g) => g.isActive).map((g) => g.raycastMesh),
          false,
        );
        if (intersections.length) {
          const closestGizmo = intersections[0].object
            .parent as TransformControlsGizmo;
          closestGizmo.isHighlighted = true;
          switch (closestGizmo.axis) {
            case 'xy':
              this.controls.translate.x.isHighlighted = true;
              this.controls.translate.y.isHighlighted = true;
              break;
            case 'xz':
              this.controls.translate.x.isHighlighted = true;
              this.controls.translate.z.isHighlighted = true;
              break;
            case 'yz':
              this.controls.translate.y.isHighlighted = true;
              this.controls.translate.z.isHighlighted = true;
              break;
            case 'view':
              this.controls.translate.x.isHighlighted = true;
              this.controls.translate.y.isHighlighted = true;
              this.controls.translate.z.isHighlighted = true;
              break;
          }
        }
        break;
    }
  };

  onPointerDown = (event: PointerEvent): void => {
    if (event.button !== 0) return;
    if (!this._active || !this._attached || this.dragging) return;

    if (!document.pointerLockElement) {
      this.canvas.setPointerCapture(event.pointerId);
    }

    const coord = toNDC(event.clientX, event.clientY, this.canvas);
    rayCaster.setFromCamera(coord, this.camera);
    const intersections = rayCaster.intersectObjects(
      this.allGizmos.filter((g) => g.isActive).map((g) => g.raycastMesh),
    );
    if (intersections.length === 0) return;
    const closestGizmo = intersections[0].object
      .parent as TransformControlsGizmo;
    this.axis = this.plane.axis = closestGizmo.axis;
    this.transform = this.plane.transform = closestGizmo.transform;

    this.plane.updateMatrixWorld(true);
    this._attached.updateMatrixWorld();
    this._attached.parent?.updateMatrixWorld();

    this._positionStart.copy(this._attached.position);
    this._quaternionStart.copy(this._attached.quaternion);
    this._scaleStart.copy(this._attached.scale);

    this._attached.matrixWorld.decompose(
      this.worldPositionStart,
      this.worldQuaternionStart,
      this._worldScaleStart,
    );

    const planeIntersect = rayCaster.intersectObject(this.plane, false);
    if (planeIntersect.length === 0) return;
    this.pointStart.copy(planeIntersect[0].point).sub(this.worldPositionStart);

    if (this.transform === 'rotate') {
      this.add(line);
      line.position.copy(this.worldPositionStart);
      line.geometry.setFromPoints([ORIGIN, this.pointStart]);
      line.computeLineDistances();
    }
    this.dragging = true;
    this.orbitControls.enabled = false;
    this.canvas.removeEventListener('pointerdown', this.onPointerDown);
    this.canvas.removeEventListener('pointermove', this.onPointerHover);
    this.canvas.addEventListener('pointerup', this.onPointerUp);
    this.canvas.addEventListener('pointermove', this.onPointerMove);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  onPointerMove = (event: PointerEvent): void => {
    if (
      !this._active ||
      !this._attached ||
      !this.axis ||
      !this.dragging ||
      event.button !== -1
    )
      return;

    if (this.transform === 'scale') {
      this.space = 'local';
    } else if (
      // this.axis === 'view' ||
      // this.axis === 'XYZE' ||
      this.axis === 'view'
    ) {
      this.space = 'world';
    }

    const coord = toNDC(event.clientX, event.clientY, this.canvas);
    rayCaster.setFromCamera(coord, this.camera);
    const planeIntersect = rayCaster.intersectObject(this.plane, false);

    if (!planeIntersect.length) return;
    this.pointEnd.copy(planeIntersect[0].point).sub(this.worldPositionStart);

    switch (this.transform) {
      case 'translate':
        {
          // Apply translate
          this.offset.copy(this.pointEnd).sub(this.pointStart);
          if (this._space === 'local' && this.axis !== 'view') {
            this.offset.applyQuaternion(this._worldQuaternionInv);
          }
          switch (this.axis) {
            case 'x':
              this.offset.y = 0;
              this.offset.z = 0;
              break;
            case 'y':
              this.offset.x = 0;
              this.offset.z = 0;
              break;
            case 'z':
              this.offset.x = 0;
              this.offset.y = 0;
              break;
            case 'xy':
              this.offset.z = 0;
              break;
            case 'xz':
              this.offset.y = 0;
              break;
            case 'yz':
              this.offset.x = 0;
              break;
          }

          if (this._space === 'local' && this.axis !== 'view') {
            this.offset
              .applyQuaternion(this._quaternionStart)
              .divide(this._parentScale);
          } else {
            this.offset
              .applyQuaternion(this._parentQuaternionInv)
              .divide(this._parentScale);
          }
          this._attached.position.copy(this.offset).add(this._positionStart);
        }
        break;
      case 'scale':
        {
          if (this.axis.search('XYZ') !== -1) {
            let d = this.pointEnd.length() / this.pointStart.length();
            if (this.pointEnd.dot(this.pointStart) < 0) d *= -1;
            _tempVector2.set(d, d, d);
          } else {
            _tempVector.copy(this.pointStart);
            _tempVector2.copy(this.pointEnd);
            _tempVector.applyQuaternion(this._worldQuaternionInv);
            _tempVector2.applyQuaternion(this._worldQuaternionInv);
            _tempVector2.divide(_tempVector);
            if (this.axis.search('x') === -1) {
              _tempVector2.x = 1;
            }
            if (this.axis.search('y') === -1) {
              _tempVector2.y = 1;
            }
            if (this.axis.search('z') === -1) {
              _tempVector2.z = 1;
            }
          }
          // Apply scale
          this._attached.scale.copy(this._scaleStart).multiply(_tempVector2);
        }
        break;
      case 'rotate':
        {
          line.geometry.setFromPoints([ORIGIN, this.pointEnd]);
          line.computeLineDistances();

          this.offset.copy(this.pointEnd).sub(this.pointStart);
          const ROTATION_SPEED =
            20 /
            this.worldPosition.distanceTo(
              _tempVector.setFromMatrixPosition(this.camera.matrixWorld),
            );

          switch (this.axis) {
            case 'view': {
              this.rotationAxis.copy(this.eye);
              this.rotationAngle = this.pointEnd.angleTo(this.pointStart);
              this._startNorm.copy(this.pointStart).normalize();
              this._endNorm.copy(this.pointEnd).normalize();
              this.rotationAngle *=
                this._endNorm.cross(this._startNorm).dot(this.eye) < 0 ? 1 : -1;
              break;
            }
            // case 'XYZE': {
            //   this.rotationAxis
            //     .copy(this._offset)
            //     .cross(this.eye)
            //     .normalize();
            //   this.rotationAngle =
            //     this._offset.dot(
            //       _tempVector.copy(this.rotationAxis).cross(this.eye),
            //     ) * ROTATION_SPEED;
            //   break;
            // }
            case 'x':
            case 'y':
            case 'z': {
              this.rotationAxis.copy(unitVectors[this.axis]);
              _tempVector.copy(unitVectors[this.axis]);
              if (this._space === 'local') {
                _tempVector.applyQuaternion(this.worldQuaternion);
              }
              if (isLookAtRotation) {
                this.rotationAngle = this.pointEnd.angleTo(this.pointStart);
                this._startNorm.copy(this.pointStart).normalize();
                this._endNorm.copy(this.pointEnd).normalize();
                this.rotationAngle *=
                  this._endNorm.cross(this._startNorm).dot(this.eye) < 0
                    ? 1
                    : -1;
                this.rotationAngle *=
                  this.eye.dot(unitVectors[this.axis]) > 0 ? 1 : -1;
              } else {
                this.rotationAngle =
                  this.offset.dot(_tempVector.cross(this.eye).normalize()) *
                  ROTATION_SPEED;
              }

              break;
            }
            default:
              break;
          }
          // Apply rotate
          switch (this._space) {
            // case 'view':
            // case 'XYZE':
            case 'local': {
              this._attached.quaternion.copy(this._quaternionStart);
              this._attached.quaternion
                .multiply(
                  _tempQuaternion.setFromAxisAngle(
                    this.rotationAxis,
                    this.rotationAngle,
                  ),
                )
                .normalize();
              break;
            }
            case 'world': {
              this.rotationAxis.applyQuaternion(this._parentQuaternionInv);
              this._attached.quaternion.copy(
                _tempQuaternion.setFromAxisAngle(
                  this.rotationAxis,
                  this.rotationAngle,
                ),
              );
              this._attached.quaternion
                .multiply(this._quaternionStart)
                .normalize();
              break;
            }
          }
        }
        break;
    }
    this.dispatchEvent(this._changeEvent);
  };

  onPointerUp = (event: PointerEvent): void => {
    if (event.button !== 0) return;
    this.canvas.releasePointerCapture(event.pointerId);
    this.canvas.removeEventListener('pointermove', this.onPointerMove);
    this.canvas.removeEventListener('pointerup', this.onPointerUp);
    this.canvas.addEventListener('pointermove', this.onPointerHover);
    this.canvas.addEventListener('pointerdown', this.onPointerDown);
    this.cleanup();
  };
  private cleanup = (): void => {
    if (this.transform === 'rotate') line.removeFromParent();

    if (this.axis) {
      const gizmo = this.controls[this.transform][this.axis];
      if (gizmo) gizmo.isSelected = gizmo.isHighlighted = false;
      this.axis = this.plane.axis = null;
    }

    const wasDragging = this.dragging;
    this.dragging = false;
    this.orbitControls.enabled = true;

    if (wasDragging) this.dispatchEvent(this._endEvent);
  };

  // Set current object
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  attach(object: Object3D) {
    this._attached = object;
    this.visible = true;
    return this;
  }

  // Detatch from object
  detach(): void {
    this._attached = null;
    this.visible = false;
    this.axis = this.plane.axis = null;
  }

  reset(): void {
    if (!this._active) return;
    if (this.dragging) {
      this._attached?.position.copy(this._positionStart);
      this._attached?.quaternion.copy(this._quaternionStart);
      this._attached?.scale.copy(this._scaleStart);
      this.pointStart.copy(this.pointEnd);
    }
  }

  public get active(): boolean {
    return this._active;
  }
  public set active(value: boolean) {
    if (this._active === value) return;
    this._active = value;
    if (value) {
      this.canvas.addEventListener('pointerdown', this.onPointerDown);
      this.canvas.addEventListener('pointermove', this.onPointerHover);
    } else {
      this.canvas.removeEventListener('pointerdown', this.onPointerDown);
      this.canvas.removeEventListener('pointermove', this.onPointerMove);
      this.canvas.removeEventListener('pointermove', this.onPointerHover);
      this.canvas.removeEventListener('pointerup', this.onPointerUp);
      this.cleanup();
    }
  }

  get size(): number {
    return this._size;
  }
  set size(value: number) {
    this._size = value;
  }

  get space(): Space {
    return this._space;
  }
  set space(value: Space) {
    this._space = value;
    this.plane.space = value;
    this.allGizmos.forEach((gizmo) => (gizmo.space = value));
  }
}
