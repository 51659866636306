/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/prefer-immediate-return */
import {
  DEFAULT_PROCESSED_CLOUD_POINTS_SIZE,
  MAX_POINTS_SIZE,
  MIN_POINTS_SIZE,
  PRESET_RANGES,
  PRESET_COLORS,
  HEIGHT_RANGE,
} from '../constants';
import { loadFromLocalStorage, saveToLocalStorage } from '../util/localStorage';
import {
  CloudDisplayMode,
  MinMax,
  PointDescriptor,
  PointDescriptorInUse,
  PointDescriptorsInUse,
} from '../types';
import { ObjectEntries } from '../util/misc';
import { PopulatedPointCloud } from '../FlatbufDeserialization';

export const LOCALSTORAGE_KEY = 'ProcessedCloud';

export type ProcessedCloudParams = Omit<
  PopulatedPointCloud,
  'id' | 'descriptorEnum'
>;
export type ProcessedCloudSchema = {
  id: string;
  descriptor: PointDescriptor;
  params: ProcessedCloudParams;
};
export type ProcessedNodeSchema = {
  id: string;
  clouds: ProcessedCloudSchema[];
};

export type ProcessedCloudActions =
  | { type: 'setProcessedClouds'; schemas: ProcessedCloudSchema[] }
  | {
      type: 'setProcessedCloudsDisplayMode';
      descriptor: PointDescriptorInUse;
      value: CloudDisplayMode;
    }
  | {
      type: 'setProcessedCloudsDisplayModePreset';
      descriptor: PointDescriptorInUse;
      value: number;
    }
  | {
      type: 'setProcessedCloudVisibility';
      descriptor: PointDescriptorInUse;
      id: string;
      value: boolean;
    }
  | {
      type: 'setProcessedCloudPointSize';
      descriptor: PointDescriptorInUse;
      id: string;
      value: number;
    }
  | {
      type: 'setProcessedCloudColor';
      descriptor: PointDescriptorInUse;
      variant: 'colorMin' | 'colorMax';
      id: string;
      value: number;
    }
  | {
      type: 'setProcessedCloudMinMax';
      descriptor: PointDescriptorInUse;
      mode: CloudDisplayMode;
      variant: 'max' | 'min';
      value: number;
    }
  | {
      type: 'setProcessedCloudHeightExclusion';
      variant: 'heightMin' | 'heightMax';
      value: number;
    }
  | {
      type: 'setProcessedCloudUnReachability';
      reachedCloudIds: Record<PointDescriptorInUse, string[] | undefined>;
    };

type PointDescriptorState = {
  allIds: string[];
  display: {
    colorMode: CloudDisplayMode;
    colorMax: { [id: string]: number };
    colorMin: { [id: string]: number };
    colorRange: Record<CloudDisplayMode, MinMax>;
    heightMax: number;
    heightMin: number;
    heightRange: MinMax;
  };
  descriptors: { [id: string]: PointDescriptor };
  visibilities: { [id: string]: boolean };
  reachabilities: { [id: string]: boolean };
  params: { [id: string]: ProcessedCloudParams };
  pointSize: { [id: string]: number };
};
export type ProcessedCloudState = Record<
  PointDescriptorInUse,
  PointDescriptorState
>;
export type ProcessedCloudStored = Record<
  PointDescriptorInUse,
  Pick<PointDescriptorState, 'display' | 'visibilities' | 'pointSize'>
>;

const loaded = (loadFromLocalStorage(LOCALSTORAGE_KEY) ??
  {}) as ProcessedCloudStored;
export const ProcessedCloudInitialState: ProcessedCloudState = {
  Background: {
    display: {
      ...{
        colorMode: loaded?.Background?.display?.colorMode ?? 'CalRef',
        colorMin: {},
        colorMax: {},
        colorRange: loaded?.Background?.display?.colorRange ?? PRESET_RANGES,
        heightMax: HEIGHT_RANGE.default.max,
        heightMin: HEIGHT_RANGE.default.min,
      },
      ...loaded?.Background?.display,
      heightRange: HEIGHT_RANGE.limit,
    },
    allIds: [],
    descriptors: {},
    params: {},
    visibilities: loaded?.Background?.visibilities ?? {},
    reachabilities: {},
    pointSize: loaded?.Background?.pointSize ?? {},
  },
  Foreground: {
    display: {
      ...{
        colorMode: loaded?.Foreground?.display?.colorMode ?? 'CalRef',
        colorMin: {},
        colorMax: {},
        colorRange: loaded?.Background?.display?.colorRange ?? PRESET_RANGES,
        heightMin: -Infinity,
        heightMax: Infinity,
      },
      ...loaded?.Foreground?.display,
      heightRange: HEIGHT_RANGE.limit,
    },
    allIds: [],
    descriptors: {},
    params: {},
    visibilities: loaded?.Foreground?.visibilities ?? {},
    reachabilities: {},
    pointSize: loaded?.Foreground?.pointSize ?? {},
  },
  Ground: {
    display: {
      ...{
        colorMode: loaded?.Ground?.display?.colorMode ?? 'CalRef',
        colorMin: {},
        colorMax: {},
        colorRange: loaded?.Background?.display?.colorRange ?? PRESET_RANGES,
        heightMin: -Infinity,
        heightMax: Infinity,
      },
      ...loaded?.Ground?.display,
      heightRange: HEIGHT_RANGE.limit,
    },
    allIds: [],
    descriptors: {},
    params: {},
    visibilities: loaded?.Ground?.visibilities ?? {},
    reachabilities: {},
    pointSize: loaded?.Ground?.pointSize ?? {},
  },
  Raw: {
    display: {
      ...{
        colorMode: loaded?.Raw?.display?.colorMode ?? 'CalRef',
        colorMin: {},
        colorMax: {},
        colorRange: loaded?.Background?.display?.colorRange ?? PRESET_RANGES,
        heightMin: -Infinity,
        heightMax: Infinity,
      },
      ...loaded?.Raw?.display,
      heightRange: HEIGHT_RANGE.limit,
    },
    allIds: [],
    descriptors: {},
    params: {},
    visibilities: loaded?.Raw?.visibilities ?? {},
    reachabilities: {},
    pointSize: loaded?.Raw?.pointSize ?? {},
  },
};

const reducer = (
  state: ProcessedCloudState,
  action: ProcessedCloudActions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ProcessedCloudState => {
  switch (action.type) {
    case 'setProcessedClouds': {
      let newState: ProcessedCloudState = { ...state };
      for (const schema of action.schemas) {
        const { id } = schema;
        const descriptor = schema.descriptor as PointDescriptorInUse;

        const isNew = newState[descriptor].params[id] === undefined;

        const params = { ...newState[descriptor].params, [id]: schema.params };
        const wasUnreachable = !newState[descriptor].reachabilities[id];
        const reachabilities = wasUnreachable
          ? {
              ...newState[descriptor].reachabilities,
              [id]: true,
            }
          : newState[descriptor].reachabilities;
        if (isNew) {
          const allIds = [...newState[descriptor].allIds, id];
          const visibilities = {
            ...newState[descriptor].visibilities,
            [id]: loaded[descriptor]?.visibilities?.[id] ?? true,
          };
          const pointSize = {
            ...newState[descriptor].pointSize,
            [id]:
              loaded[descriptor]?.pointSize?.[id] ??
              DEFAULT_PROCESSED_CLOUD_POINTS_SIZE,
          };
          const colorMin = {
            ...newState[descriptor].display.colorMin,
            [id]:
              loaded[descriptor]?.display?.colorMin?.[id] ??
              PRESET_COLORS[schema.descriptor].min,
          };
          const colorMax = {
            ...newState[descriptor].display.colorMax,
            [id]:
              loaded[descriptor]?.display?.colorMax?.[id] ??
              PRESET_COLORS[schema.descriptor].max,
          };
          const display = {
            ...newState[descriptor].display,
            colorMin,
            colorMax,
          };
          newState = {
            ...newState,
            [descriptor]: {
              allIds,
              params,
              visibilities,
              reachabilities,
              pointSize,
              display,
            },
          };
        } else {
          newState = {
            ...newState,
            [descriptor]: {
              ...newState[descriptor],
              params,
              reachabilities,
            },
          };
        }
      }
      return newState;
    }
    case 'setProcessedCloudsDisplayMode': {
      if (state[action.descriptor].display.colorMode === action.value)
        return state;
      const display = {
        ...state[action.descriptor].display,
        colorMode: action.value,
      };
      const o: ProcessedCloudState = {
        ...state,
        [action.descriptor]: {
          ...state[action.descriptor],
          display,
        },
      };
      return o;
    }
    case 'setProcessedCloudVisibility': {
      if (state[action.descriptor].visibilities[action.id] === action.value)
        return state;

      const visibilities = {
        ...state[action.descriptor].visibilities,
        [action.id]: action.value,
      };
      const o: ProcessedCloudState = {
        ...state,
        [action.descriptor]: {
          ...state[action.descriptor],
          visibilities,
        },
      };
      return o;
    }
    case 'setProcessedCloudPointSize': {
      const v = Math.min(
        Math.max(
          MIN_POINTS_SIZE,
          state[action.descriptor].pointSize[action.id] + action.value,
        ),
        MAX_POINTS_SIZE,
      );

      if (state[action.descriptor].pointSize[action.id] === v) return state;

      const pointSize = {
        ...state[action.descriptor].pointSize,
        [action.id]: v,
      };
      const o = {
        ...state,
        [action.descriptor]: {
          ...state[action.descriptor],
          pointSize,
        },
      };
      return o;
    }
    case 'setProcessedCloudColor': {
      const color = state[action.descriptor].display[action.variant][action.id];
      if (color === action.value) return state;

      const c = { [action.id]: action.value };
      const o: ProcessedCloudState = {
        ...state,
        [action.descriptor]: {
          ...state[action.descriptor],
          display: {
            ...state[action.descriptor].display,
            [action.variant]: {
              ...state[action.descriptor].display[action.variant],
              ...c,
            },
          },
        },
      };
      return o;
    }
    case 'setProcessedCloudMinMax': {
      const minmax =
        state[action.descriptor].display.colorRange[action.mode][
          action.variant
        ];
      if (minmax === action.value) return state;

      const o: ProcessedCloudState = {
        ...state,
        [action.descriptor]: {
          ...state[action.descriptor],
          display: {
            ...state[action.descriptor].display,
            colorRange: {
              ...state[action.descriptor].display.colorRange,
              [action.mode]: {
                ...state[action.descriptor].display.colorRange[action.mode],
                [action.variant]: action.value,
              },
            },
          },
        },
      };
      return o;
    }
    case 'setProcessedCloudHeightExclusion': {
      const minmax = state.Background.display[action.variant];
      if (minmax === action.value) return state;

      const o: ProcessedCloudState = {
        ...state,
        Background: {
          ...state.Background,
          display: {
            ...state.Background.display,
            [action.variant]: action.value,
          },
        },
      };
      return o;
    }
    // We only flag unreachable clouds here. If we receive data
    // from a sensor, it'll be set to reachable again in setProcessedCloud.
    case 'setProcessedCloudUnReachability': {
      const newReachabilities: Record<
        PointDescriptorInUse,
        { [id: string]: boolean }
      > = { Background: {}, Foreground: {}, Ground: {} } as Record<
        PointDescriptorInUse,
        { [id: string]: boolean }
      >;
      const needsUpdateByDescriptor = {} as Record<
        PointDescriptorInUse,
        boolean
      >;

      PointDescriptorsInUse.forEach((descriptor) => {
        needsUpdateByDescriptor[descriptor] = false;

        const receivedInIntervalIds = action.reachedCloudIds[descriptor];
        const wasReachable = Object.keys(
          state[descriptor].reachabilities,
        ).filter((id) => state[descriptor].reachabilities[id]);

        const noLongerReachable =
          receivedInIntervalIds === undefined
            ? wasReachable
            : wasReachable.filter((id) => !receivedInIntervalIds.includes(id));

        noLongerReachable.forEach((id) => {
          needsUpdateByDescriptor[descriptor] = true;
          newReachabilities[descriptor][id] = false;
        });
      });

      const needsUpdate = ObjectEntries(needsUpdateByDescriptor).some(
        ([, needsUpdateDescriptor]) => needsUpdateDescriptor,
      );
      const o = needsUpdate
        ? {
            ...state,
            Background: needsUpdateByDescriptor.Background
              ? {
                  ...state.Background,
                  reachabilities: {
                    ...state.Background.reachabilities,
                    ...newReachabilities.Background,
                  },
                }
              : state.Background,
            Foreground: needsUpdateByDescriptor.Foreground
              ? {
                  ...state.Foreground,
                  reachabilities: {
                    ...state.Foreground.reachabilities,
                    ...newReachabilities.Foreground,
                  },
                }
              : state.Foreground,
            Ground: needsUpdateByDescriptor.Ground
              ? {
                  ...state.Ground,
                  reachabilities: {
                    ...state.Ground.reachabilities,
                    ...newReachabilities.Ground,
                  },
                }
              : state.Ground,
          }
        : state;
      return o;
    }
    default:
      return state;
  }
};

const triggerSaveSet: Set<ProcessedCloudActions['type']> = new Set([
  'setProcessedCloudsDisplayMode',
  'setProcessedCloudsDisplayModePreset',
  'setProcessedCloudVisibility',
  'setProcessedCloudPointSize',
  'setProcessedCloudColor',
  'setProcessedCloudHeightExclusion',
]);
export const ProcessedCloudReducer = (
  state: ProcessedCloudState,
  action: ProcessedCloudActions,
): ProcessedCloudState => {
  const newState = reducer(state, action);

  const stored: ProcessedCloudStored = {
    Background: {
      display: newState.Background.display,
      visibilities: newState.Background.visibilities,
      pointSize: newState.Background.pointSize,
    },
    Foreground: {
      display: newState.Foreground.display,
      visibilities: newState.Foreground.visibilities,
      pointSize: newState.Foreground.pointSize,
    },
    Ground: {
      display: newState.Ground.display,
      visibilities: newState.Ground.visibilities,
      pointSize: newState.Ground.pointSize,
    },
    Raw: {
      display: newState.Raw.display,
      visibilities: newState.Raw.visibilities,
      pointSize: newState.Raw.pointSize,
    },
  };
  if (triggerSaveSet.has(action.type))
    saveToLocalStorage(LOCALSTORAGE_KEY, stored);

  return newState;
};
