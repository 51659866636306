/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useAppState } from '../Stores';
import RecordTime from './RecordTime';

const RecordingFeedback = (): JSX.Element | null => {
  const state = useAppState();
  const { recording } = state;

  return (
    <div
      style={{
        float: 'left',
      }}
    >
      <RecordTime isRecording={recording.isRecording} />
    </div>
  );
};

export default RecordingFeedback;
