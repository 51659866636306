/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import {
  DEFAULT_NODE_CLOUD_POINTS_SIZE,
  MAX_POINTS_SIZE,
  MIN_POINTS_SIZE,
  PRESET_RANGES,
} from '../constants';
import { loadFromLocalStorage, saveToLocalStorage } from '../util/localStorage';
import { CloudDisplayMode, Pose, SourceStateBase } from '../types';
import { HUES } from '../util/palettes';
import { ProcessedCloudSchema } from '../processedCloud/ProcessedCloudStore';

export const LOCALSTORAGE_KEY = 'Node';

export type NodeSchema = {
  id: string;
  clouds: ProcessedCloudSchema[];
};
export type NodeLicenseInfoSchema = {
  expirationTime: number;
  inUseLicenses: number;
  totalLicenses: number;
};

export type NodeDiscoverSchema = {
  id: string;
  hostname: string;
  isAdded: boolean;
  isReachable: boolean;
  licenseInfo: NodeLicenseInfoSchema;
};

export type NodeActions =
  | { type: 'setNode'; schema: NodeSchema }
  | { type: 'setNodeReset' }
  | { type: 'setRemoveNode'; id: string }
  | {
      type: 'setNodesDiscover';
      nodes: NodeDiscoverSchema[];
    }
  | { type: 'setNodesDisplayMode'; value: CloudDisplayMode }
  | { type: 'setNodesDisplayModePreset'; value: number }
  | { type: 'setNodeVisibility'; id: string; value: boolean }
  | { type: 'setNodeAdded'; id: string; value: boolean }
  | { type: 'setNodeReachable'; id: string; value: boolean }
  | { type: 'setNodeEnable'; id: string; value: boolean }
  | { type: 'setNodePointSize'; id: string; value: number }
  | {
      type: 'setNodeColor';
      variant: 'colorMin' | 'colorMax';
      id: string;
      colorIndex: number;
    }
  | {
      type: 'setNodeMinMax';
      variant: 'min' | 'max';
      mode: CloudDisplayMode;
      value: number;
    }
  | { type: 'setNodeExtrinsic'; id: string; value: Pose }
  | { type: 'setNodeTransformed'; id: string; value: boolean };

export type NodeState = SourceStateBase & {
  // Each node has a list of clouds, accessible like this:
  // state.clouds[nodeId][cloudId]
  clouds: { [id: string]: ProcessedCloudSchema[] };
  // Nodes have license information
  licensesExpirationTimes: { [id: string]: number };
  licensesInUse: { [id: string]: number };
  licensesTotal: { [id: string]: number };
};

export type NodeStored = Pick<
  NodeState,
  'display' | 'pointSize' | 'visibilities'
>;

const nodeLoaded = (loadFromLocalStorage(LOCALSTORAGE_KEY) ?? {}) as NodeStored;

export const NodeInitialState: NodeState = {
  display: {
    ...{
      colorMode: nodeLoaded?.display?.colorMode ?? 'CalRef',
      colorMin: {},
      colorMax: {},
      colorRange: nodeLoaded?.display?.colorRange ?? PRESET_RANGES,
    },
    ...nodeLoaded?.display,
  },
  allIds: [],
  hostnames: {},
  ips: {},
  clouds: {},
  visibilities: nodeLoaded?.visibilities ?? {},
  addedStates: {},
  reachableStates: {},
  pointSize: nodeLoaded?.pointSize ?? {},
  extrinsics: {},
  transformed: {},
  licensesExpirationTimes: {},
  licensesInUse: {},
  licensesTotal: {},
};

export const baseNodeReducer = (
  state: NodeState,
  action: NodeActions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): NodeState => {
  switch (action.type) {
    case 'setNode': {
      const { schema } = action;
      const { id } = schema;
      const isNew = !state.allIds.includes(id) || !state.clouds[id];

      const clouds = {
        ...state.clouds,
        [id]: schema.clouds,
      };

      if (isNew) {
        const allIds = !state.allIds.includes(id)
          ? [...state.allIds, id]
          : state.allIds;
        const visibilities = {
          ...state.visibilities,
          [id]: nodeLoaded?.visibilities?.[id] ?? true,
        };
        const pointSize = {
          ...state.pointSize,
          [id]: nodeLoaded?.pointSize?.[id] ?? DEFAULT_NODE_CLOUD_POINTS_SIZE,
        };
        const colorMin = {
          ...state.display.colorMin,
          [id]: nodeLoaded?.display?.colorMin?.[id] ?? HUES.PURPLE,
        };
        const colorMax = {
          ...state.display.colorMax,
          [id]: nodeLoaded?.display?.colorMax?.[id] ?? HUES.MAGENTA,
        };
        const display = {
          ...state.display,
          colorMin,
          colorMax,
        };
        const extrinsics = {
          ...state.extrinsics,
          [id]: null,
        };

        const transformed = { ...state.transformed, [id]: false };

        return {
          ...state,
          allIds,
          visibilities,
          pointSize,
          display,
          extrinsics,
          transformed,
          clouds,
        };
      } else {
        return {
          ...state,
          clouds,
        };
      }
    }
    case 'setRemoveNode': {
      const { id } = action;

      const allIds = state.allIds.filter((nodeId) => nodeId !== id);
      const addedStates = { ...state.addedStates };
      delete addedStates[id];
      const reachableStates = { ...state.reachableStates };
      delete reachableStates[id];
      const hostnames = { ...state.hostnames };
      delete hostnames[id];
      const ips = { ...state.ips };
      delete ips[id];
      const licensesTotal = { ...state.licensesTotal };
      delete licensesTotal[id];
      const licensesInUse = { ...state.licensesInUse };
      delete licensesInUse[id];
      const licensesExpirationTimes = { ...state.licensesExpirationTimes };
      delete licensesExpirationTimes[id];
      const visibilities = { ...state.visibilities };
      delete visibilities[id];
      // Don't delete pointSize and colorMin/colorMax because they're nice to have saved.
      const extrinsics = { ...state.extrinsics };
      delete extrinsics[id];
      const transformed = { ...state.transformed };
      delete transformed[id];

      return {
        ...state,
        addedStates,
        allIds,
        extrinsics,
        hostnames,
        ips,
        licensesTotal,
        licensesInUse,
        licensesExpirationTimes,
        reachableStates,
        transformed,
        visibilities,
      };
    }
    case 'setNodesDiscover': {
      let globalNeedsUpdate = false;

      const allIds = [...state.allIds];
      const addedStates = { ...state.addedStates };
      const reachableStates = { ...state.reachableStates };
      const hostnames = { ...state.hostnames };
      const licensesExpirationTimes = { ...state.licensesExpirationTimes };
      const licensesInUse = { ...state.licensesInUse };
      const licensesTotal = { ...state.licensesTotal };

      action.nodes.forEach((node) => {
        const {
          id,
          hostname,
          isAdded,
          isReachable,
          licenseInfo: { inUseLicenses, expirationTime, totalLicenses },
        } = node;

        const isNew = !allIds.includes(id) || !state.clouds[id];
        const hostnameChanged = hostnames[id] !== hostname;
        const addedStatusChanged = addedStates[id] !== isAdded;
        const reachableStatusChanged = reachableStates[id] !== isReachable;
        const licensesTotalChanged = licensesTotal[id] !== totalLicenses;
        const licensesInUseChanged = licensesInUse[id] !== inUseLicenses;
        const licenseExpirationTimeChanged =
          licensesExpirationTimes[id] !== expirationTime;

        const needsUpdate =
          isNew ||
          hostnameChanged ||
          addedStatusChanged ||
          reachableStatusChanged ||
          licensesTotalChanged ||
          licensesInUseChanged ||
          licenseExpirationTimeChanged;
        if (!needsUpdate) {
          return;
        }
        globalNeedsUpdate = true;

        if (!allIds.includes(id)) allIds.push(id);
        addedStates[id] = isAdded;
        reachableStates[id] = isReachable;
        hostnames[id] = hostname;
        licensesExpirationTimes[id] = expirationTime;
        licensesInUse[id] = inUseLicenses;
        licensesTotal[id] = totalLicenses;
      });

      return globalNeedsUpdate
        ? {
            ...state,
            allIds,
            addedStates,
            reachableStates,
            hostnames,
            licensesTotal,
            licensesInUse,
            licensesExpirationTimes,
          }
        : state;
    }
    case 'setNodeReset': {
      const allIds: string[] = [];
      const addedStates = {};
      const hostnames = {};

      return {
        ...state,
        allIds,
        addedStates,
        hostnames,
      };
    }
    case 'setNodesDisplayMode': {
      if (state.display.colorMode === action.value) return state;
      return {
        ...state,
        display: { ...state.display, colorMode: action.value },
      };
    }
    case 'setNodeVisibility': {
      if (state.visibilities[action.id] === action.value) return state;
      const visibilities = { ...state.visibilities, [action.id]: action.value };
      return { ...state, visibilities };
    }
    case 'setNodeAdded': {
      if (state.addedStates[action.id] === action.value) return state;
      const addedStates = { ...state.addedStates, [action.id]: action.value };
      return { ...state, addedStates };
    }
    case 'setNodeReachable': {
      if (state.reachableStates[action.id] === action.value) return state;
      const reachableStates = {
        ...state.reachableStates,
        [action.id]: action.value,
      };
      return { ...state, reachableStates };
    }
    case 'setNodeColor': {
      if (state.display[action.variant][action.id] === action.colorIndex)
        return state;

      const c = { [action.id]: action.colorIndex };
      return {
        ...state,
        display: {
          ...state.display,
          [action.variant]: { ...state.display[action.variant], ...c },
        },
      };
    }
    case 'setNodeMinMax': {
      if (
        state.display.colorRange[action.mode][action.variant] === action.value
      ) {
        return state;
      }

      return {
        ...state,
        display: {
          ...state.display,
          colorRange: {
            ...state.display.colorRange,
            [action.mode]: {
              ...state.display.colorRange[action.mode],
              [action.variant]: action.value,
            },
          },
        },
      };
    }
    case 'setNodePointSize': {
      const v = Math.min(
        Math.max(MIN_POINTS_SIZE, state.pointSize[action.id] + action.value),
        MAX_POINTS_SIZE,
      );
      if (state.pointSize[action.id] === v) return state;
      const pointSize = { ...state.pointSize, [action.id]: v };
      return { ...state, pointSize };
    }
    case 'setNodeExtrinsic': {
      if (
        JSON.stringify(state.extrinsics[action.id]) ===
        JSON.stringify(action.value)
      )
        return state;
      const extrinsics = {
        ...state.extrinsics,
        [action.id]: action.value,
      };
      return { ...state, extrinsics };
    }
    case 'setNodeTransformed': {
      if (state.transformed[action.id] === action.value) return state;

      const transformed = {
        ...state.transformed,
        [action.id]: action.value,
      };
      return { ...state, transformed };
    }
    default:
      return state;
  }
};

const triggerNodeSaveSet: Set<NodeActions['type']> = new Set([
  'setNodesDisplayMode',
  'setNodesDisplayModePreset',
  'setNodeVisibility',
  'setNodePointSize',
]);

export const NodeReducer = (
  state: NodeState,
  action: NodeActions,
): NodeState => {
  const newState = baseNodeReducer(state, action);

  const stored: NodeStored = {
    display: newState.display,
    pointSize: newState.pointSize,
    visibilities: newState.visibilities,
  };
  if (triggerNodeSaveSet.has(action.type))
    saveToLocalStorage(LOCALSTORAGE_KEY, stored);

  return newState;
};
