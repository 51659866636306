/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/cognitive-complexity */

import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppState } from './Stores';
import { useImageDispatch } from './image2d/ImageStore';
import { PerceptionWebsocket } from './PerceptionWebsocket';
import { adaptCloud, adaptPerception } from './util/adapters';
import { align_wss_url, node_wss_url, perception_wss_url } from './constants';
import { PointDescriptorInUse, WSDataReceivedSignal } from './types';
import useSensorCloudReachability from './source/useSensorCloudReachability';
import {
  ProcessedCloudSchema,
  ProcessedNodeSchema,
} from './processedCloud/ProcessedCloudStore';
import { unstable_batchedUpdates } from 'react-dom';
import { ObjectParameters, populatePointCloud } from './FlatbufDeserialization';

import { throttle } from 'lodash';
import { PointClouds } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/point-clouds';

export const usePerceptionSocket = (
  dataReceivedSignal: WSDataReceivedSignal,
): void => {
  const perceptionWS = useRef<PerceptionWebsocket>();
  const alignWS = useRef<PerceptionWebsocket>();
  const nodeWS = useRef<PerceptionWebsocket>();
  const imageDispatch = useImageDispatch();
  const dispatch = useAppDispatch();
  const state = useAppState();
  const signalSensorCloudId = useSensorCloudReachability();
  const lastProcessedClouds = useRef<Record<string, ProcessedCloudSchema>>({});
  // Raw clouds are stored here temporarily, so their processing can be throttled
  const lastRawSensorSchemas = useRef<Record<string, ProcessedCloudSchema>>({});
  const lastRawNodeSchemas = useRef<Record<string, ProcessedNodeSchema>>({});

  useEffect(() => {
    if (state.app.inputMode === 'playback') return;
    if (perceptionWS.current !== undefined && perceptionWS.current.enabled)
      return;
    if (alignWS.current !== undefined && alignWS.current.enabled) return;
    if (nodeWS.current !== undefined && nodeWS.current.enabled) return;

    perceptionWS.current = new PerceptionWebsocket(
      perception_wss_url,
      'perceptionWs',
      dataReceivedSignal,
    );
    alignWS.current = new PerceptionWebsocket(
      align_wss_url,
      'alignWs',
      dataReceivedSignal,
    );
    nodeWS.current = new PerceptionWebsocket(
      node_wss_url,
      'nodeWs',
      dataReceivedSignal,
    );
    return () => {
      disablePerceptionWebSocket();
      disableAlignmentWebSocket();
      disableNodeWebSocket();
    };
  }, [state.app.inputMode]);

  useEffect(() => {
    const isLive = state.app.inputMode === 'live';
    const isPaused = !state.app.isPlaying;

    if (isLive && isPaused) {
      disablePerceptionWebSocket();
      disableAlignmentWebSocket();
      disableNodeWebSocket();
      return;
    }

    setupPerceptionWebSocket();
    setupAlignmentWebSocket();
    setupNodeWebSocket();
  }, [state.app.mode, state.app.inputMode, state.app.isPlaying]);

  function setupPerceptionWebSocket() {
    if (!perceptionWS.current) return;

    const shouldEnable =
      state.app.mode === 'viewer' ||
      state.app.mode === 'zone' ||
      state.app.mode === 'recording' ||
      state.app.mode === 'preferences' ||
      state.app.mode === 'map';

    if (shouldEnable) {
      perceptionWS.current.enable();
      perceptionWS.current.onPointCloud = handlePointCloud;
      perceptionWS.current.onObjects = handleObjects;
      perceptionWS.current.onImage = handleImage;
    } else {
      perceptionWS.current.disable();
    }
  }

  function disablePerceptionWebSocket() {
    if (perceptionWS.current) perceptionWS.current.disable();
  }

  function setupAlignmentWebSocket() {
    if (!alignWS.current) return;

    const shouldEnable = state.app.mode === 'setup';

    if (shouldEnable) {
      alignWS.current.enable();
      alignWS.current.onPointCloud = handleAlignmentPointCloud;
      alignWS.current.onImage = handleImage;
    } else {
      alignWS.current.disable();
    }
  }

  function disableAlignmentWebSocket() {
    if (alignWS.current) alignWS.current.disable();
  }

  function setupNodeWebSocket() {
    if (!nodeWS.current) return;

    const shouldEnable = state.app.mode === 'setup';

    if (shouldEnable) {
      nodeWS.current.enable();
      nodeWS.current.onPointCloud = handleNodePointCloud;
    } else {
      nodeWS.current.disable();
    }
  }

  function disableNodeWebSocket() {
    if (nodeWS.current) nodeWS.current.disable();
  }

  function handlePointCloud(pc: PointClouds) {
    const clouds = populatePointCloud(pc);
    for (const cloud of clouds) {
      const schema = adaptCloud(cloud);
      lastProcessedClouds.current[schema.id + '_' + schema.descriptor] = schema;
    }
  }

  function handleObjects(
    trackedObjectParameters: ObjectParameters[],
    clusterParameters: ObjectParameters[],
  ) {
    const trackedObjects = adaptPerception(trackedObjectParameters);
    const clusters = adaptPerception(clusterParameters);
    unstable_batchedUpdates(() => {
      dispatch({
        type: 'setTrackedObjectsAndClusters',
        trackedObjectsAndClusters: [...trackedObjects, ...clusters],
      });
      const schemas = Object.values(lastProcessedClouds.current);
      if (schemas.length > 0) {
        dispatch({ type: 'setProcessedClouds', schemas });
        for (const schema of schemas) {
          delete lastProcessedClouds.current[
            schema.id + '_' + schema.descriptor
          ];
          signalSensorCloudId(
            schema.id,
            schema.descriptor as PointDescriptorInUse,
          );
        }
      }
    });
  }

  function handleAlignmentPointCloud(pc: PointClouds) {
    const clouds = populatePointCloud(pc);
    for (const cloud of clouds) {
      const schema = adaptCloud(cloud);
      lastRawSensorSchemas.current[schema.id] = schema;
    }
    throttledSourceUpdates();
  }

  function handleNodePointCloud(pc: PointClouds) {
    const clouds = populatePointCloud(pc);
    const nodeId = pc.nodeId();
    const nodeSchema: ProcessedNodeSchema = {
      id: nodeId ?? 'nodeIdWasNull',
      clouds: [],
    };

    for (const cloud of clouds) {
      const schema = adaptCloud(cloud);
      nodeSchema.clouds.push(schema);
    }

    lastRawNodeSchemas.current[nodeSchema.id] = nodeSchema;
    throttledSourceUpdates();
  }

  // We often get multiple raw clouds in a short period of time, we don't want each one
  // to trigger a re-render, so we throttle the updates.
  const throttledSourceUpdates = throttle(handleSourceUpdates, 1000, {
    leading: false,
  });

  function handleSourceUpdates() {
    unstable_batchedUpdates(() => {
      const sensorSchemas = Object.values(lastRawSensorSchemas.current);
      for (const schema of sensorSchemas) {
        dispatch({ type: 'setSensor', schema: schema });
        delete lastRawSensorSchemas.current[schema.id];
        signalSensorCloudId(
          schema.id,
          schema.descriptor as PointDescriptorInUse,
        );
      }
      const nodeSchemas = Object.values(lastRawNodeSchemas.current);
      for (const schema of nodeSchemas) {
        dispatch({ type: 'setNode', schema: schema });
        for (const cloud of schema.clouds) {
          delete lastRawNodeSchemas.current[cloud.id];
          // TODO(josiah)(OD-1698): Signal the node cloud id here,
          // once the useNodeCloudReachability hook is made.
          // https://ouster.atlassian.net/browse/OD-1698
        }
      }
    });
  }

  function handleImage(buffer: Uint8Array, sensorId: string) {
    imageDispatch({
      type: 'setImageBuffer',
      sensorId,
      value: buffer,
    });
  }
};
