/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ComponentPropsWithoutRef } from 'react';
import { Button } from '../Button';
import { RadioIcon } from '../RadioIcon';
import { TooltipInterface } from '../tooltip/Tooltip';

export const SelectableListElem = ({
  children,
  isSelected,
  disabled,
  onClick,
  color = 'var(--greyC)',
  backgroundColor,
  fontSize = 'var(--r4)',
  isDirty = false,
  active = true,
  tooltip,
  grow = true,
  size,
  style,
}: {
  isSelected: boolean;
  disabled: boolean;
  onClick: () => void;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  active?: boolean;
  isDirty?: boolean;
  grow?: boolean;
  size?: string;
  style?: React.CSSProperties;
} & {
  tooltip?: TooltipInterface;
} & ComponentPropsWithoutRef<'div'>): JSX.Element => {
  return (
    <Button
      tooltip={
        tooltip && {
          ...tooltip,
          ...(grow && { wrapperStyle: { flexGrow: 1 } }),
        }
      }
      className={`justify-between p-1 ${isDirty ? 'textWarning' : ''}`}
      style={{
        ...(backgroundColor && { backgroundColor }),
        flexGrow: '0',
        ...style,
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <RadioIcon
        size={size}
        style={{
          ...(children !== undefined && { marginRight: '1rem' }),
        }}
        selected={isSelected}
        disabled={disabled}
      />
      {isDirty && <div className="mx-2">!</div>}
      {children && (
        <div
          className="flex items-center text-sm ml-2 mr-1"
          style={{
            fontSize,
            color: isDirty
              ? 'var(--warning)'
              : disabled || !active
              ? 'var(--greyE)'
              : color,
          }}
        >
          {children}
        </div>
      )}
    </Button>
  );
};
