/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useRef, useState } from 'react';
import { VizScene, CameraType } from '@ouster/webviz';
import {
  BufferGeometry,
  Color,
  DefaultLoadingManager,
  GridHelper,
  Group,
  BoxBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  PlaneBufferGeometry,
  WebGLRenderer,
} from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { Splash } from './app/components/splash/Splash';
import Layout from './app/Layout';
import {
  GroupNames,
  Groups,
  PerceptionClassification,
  Context,
  GizmoTransformBuffers,
  ZoneType,
  Extrinsic,
} from './types';
import { useAppDispatch, useAppState } from './Stores';
import {
  ATTRIBUTE_NAME,
  LAYER_DEFAULT,
  LAYER_ICONS,
  RANGE_RING_RANGE_OPTIONS,
} from './constants';
import { TransformControls } from './gizmo/TransformControls';
import { AXIS_BLUE, AXIS_GREEN, AXIS_RED } from './util/palettes';
import { getExtrinsics } from './api/align';
import RangeRings3JS from './coordinatePlanes/rangeRings/RangeRings3JS';
import { ObjectKeys } from './util/misc';
import { BoxSelection } from './BoxSelection';
import { World3JS } from './threejs/World3JS';
import { getAllSensors } from './api/sensor';
import { RecordingParams } from './playback/PlaybackStore';
import { ParsePlayback as parsePlayback } from './playback/ParsePlayback';
import { ungzip } from 'pako';
import { AppDefaultState } from './app/AppStore';

// debug playback - to test set DEBUG_PLAYBACK = true and start 'mock_playback_server package' or start the app with "npm run start-with-playback-server"
const DEBUG_PLAYBACK = process.env.REACT_APP_DEBUG === 'playback';

// Prerequisites for bootstrapping the app
// eslint-disable-next-line sonarjs/cognitive-complexity
export const Bootstrap = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const state = useAppState();
  const assets = useRef<{
    sensorGeometry: BufferGeometry | null;
    nodeGeometry: BufferGeometry | null;
    gizmoTransform: GizmoTransformBuffers | null;
    carPeripheral: BufferGeometry | null;
    personPeripheral: BufferGeometry | null;
    cornerTrident: BufferGeometry | null;
  }>({
    sensorGeometry: null,
    nodeGeometry: null,
    gizmoTransform: null,
    carPeripheral: null,
    personPeripheral: null,
    cornerTrident: null,
  });
  const [assetsComplete, setAssetsComplete] = useState(false);
  const [context, setContext] = useState<Context | null>(null);
  const [canvas] = useState(document.createElement('canvas'));
  const [labelsContainer] = useState(document.createElement('div'));
  const [selectDiv] = useState(document.createElement('div'));
  const [extrinsics, setExtrinsics] = useState<Extrinsic[] | null>(null);
  const [bootstrapComplete, setComplete] = useState<boolean>(false);
  const [splashTimerCompleted, setSplashTimerCompleted] = useState(false);
  const [recording, setRecording] = useState<RecordingParams | null>(null);

  //a timer to show the splash screen for a second
  useEffect(() => {
    const timer = setTimeout(() => {
      setSplashTimerCompleted(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // init Loaders
  useEffect(() => {
    DefaultLoadingManager.onStart = (url, itemsLoaded, itemsTotal) => {
      console.log(
        `Started loading file: ${url}. Loaded ${itemsLoaded}/${itemsTotal}`,
      );
    };

    DefaultLoadingManager.onLoad = () => {
      console.log('Asset loading Complete!');
      setAssetsComplete(true);
    };

    DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      console.log(`Loading file: ${url} Loaded  ${itemsLoaded}/${itemsTotal}`);
    };

    DefaultLoadingManager.onError = (url) => {
      console.error(`There was an error loading ${url}`);
    };

    const loader = new OBJLoader(DefaultLoadingManager);

    ObjectKeys(assets.current).forEach((filename) => {
      loader.load(`${process.env.PUBLIC_URL}/${filename}.obj`, (loaded) => {
        switch (filename) {
          case 'gizmoTransform': {
            const geometries = {} as GizmoTransformBuffers;
            loaded.children.forEach((o) => {
              // extract meshes
              const geo = (o as Mesh).geometry.clone();
              const fixName = o.name.split('_')[0];
              geo.name = fixName;
              geo.computeBoundingSphere();
              geo.computeBoundingBox();
              geometries[fixName as keyof GizmoTransformBuffers] = geo;
            });
            assets.current.gizmoTransform = geometries;
            break;
          }
          case 'sensorGeometry':
          case 'nodeGeometry':
          case 'personPeripheral':
          case 'carPeripheral':
          case 'personPeripheral':
          case 'cornerTrident': {
            const obj = loaded.children[0];
            if (!obj) break;
            const geometry = (obj as Mesh).geometry.clone();
            geometry.computeBoundingSphere();
            geometry.computeBoundingBox();
            assets.current[filename] = geometry;
            break;
          }
        }
      });
    });
  }, []);

  // Initialize context
  useEffect(() => {
    if (!assetsComplete) return;

    // Init 3js wrapper
    const viz = new VizScene(canvas, CameraType.ORTHO, labelsContainer);
    viz.renderer = new WebGLRenderer({
      canvas,
      antialias: true,
      // Force high performance mode
      powerPreference: 'high-performance',
    });
    viz.camera.position.set(500, -500, 500);
    viz.controls.update();
    viz.scene.background = null;

    // No tangible difference beyond 2
    viz.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    viz.renderer.setClearAlpha(0);

    // TODO(emmanuel): remove dependency from web-viz.VizScene
    viz.renderer.autoClear = false;
    viz.renderLoop = () => {
      viz.renderer.clear();
      viz.camera.layers.set(LAYER_DEFAULT);
      viz.render();
      // Get the UI elements render on top of the 3D scene
      if (state.app.mode !== 'viewer') {
        viz.renderer.clearDepth();
        viz.camera.layers.set(LAYER_ICONS);
        viz.renderer.render(viz.scene, viz.camera);
      }
      viz.renderLoopRef = requestAnimationFrame(viz.renderLoop.bind(viz));
    };

    // remove from scene viz's reference axes
    viz.scene.children[0]?.removeFromParent();

    // Hierarchical group structure
    // viz.scene
    //     │── world
    //     │   ├──── ProcessedCloud
    //     │   ├──── TrackedObject
    //     │   ├──── Zone
    //     │   └──── Sensor
    //     │
    //     │── cartesianGrid
    //     │── pointCloud
    //     │── axes
    //     └── ...

    // world offset
    const world = new World3JS();
    world.icon.visible = false;
    viz.scene.add(world);

    // Add all groups to hierarchy and context
    const groups3JS = {} as Groups;
    for (const name of GroupNames) {
      const g = new Group();
      g.name = name;
      groups3JS[name] = g;
      world.add(g);
    }

    // add cartesian grid
    const gridCartesian = new GridHelper(100, 10, '#303030', '#262626');
    gridCartesian.name = 'gridCartesian';
    gridCartesian.rotateX(Math.PI / 2);
    viz.scene.add(gridCartesian);

    // add polar grid
    const gridPolar = new RangeRings3JS(
      RANGE_RING_RANGE_OPTIONS,
      'gui-label polar-grid',
      {
        ringWidth: 1,
        color: new Color('#303030'),
      },
    );
    gridPolar.name = 'gridPolar';
    viz.scene.add(gridPolar);

    const axes = new Group();
    axes.name = 'axes';
    axes.add(
      new Mesh(
        new BoxBufferGeometry(1, 0.05, 0.05).translate(0.5, -0.025, 0.025),
        new MeshBasicMaterial({ color: AXIS_RED }),
      ),
    );
    axes.add(
      new Mesh(
        new BoxBufferGeometry(0.05, 1, 0.05).translate(-0.025, 0.5, 0.025),
        new MeshBasicMaterial({ color: AXIS_GREEN }),
      ),
    );
    axes.add(
      new Mesh(
        new BoxBufferGeometry(0.05, 0.05, 1).translate(-0.025, -0.025, 0.5),
        new MeshBasicMaterial({ color: AXIS_BLUE }),
      ),
    );
    viz.scene.add(axes);
    // Get all CSS classifications rules defined in index.css to control labels
    const getRule = (ruleSelectorString: string): CSSStyleRule | undefined => {
      for (let i = 0; i < document.styleSheets.length; i++) {
        const sheet = document.styleSheets[i];
        for (let j = 0; j < sheet.cssRules.length; j++) {
          const rule = sheet.cssRules[j] as CSSStyleRule;
          if (rule.selectorText === ruleSelectorString) {
            return rule;
          }
        }
      }
    };
    const labelsCSSRules: Record<
      PerceptionClassification | ZoneType,
      CSSStyleRule | undefined
    > = {
      // These rules have to match exactly the rule's name in index.css
      Person: getRule(`.gui-label[${ATTRIBUTE_NAME}="Person"]`),
      Vehicle: getRule(`.gui-label[${ATTRIBUTE_NAME}="Vehicle"]`),
      LargeVehicle: getRule(`.gui-label[${ATTRIBUTE_NAME}="LargeVehicle"]`),
      Prospect: getRule(`.gui-label[${ATTRIBUTE_NAME}="Prospect"]`),
      Bicycle: getRule(`.gui-label[${ATTRIBUTE_NAME}="Bicycle"]`),
      Unknown: getRule(`.gui-label[${ATTRIBUTE_NAME}="Unknown"]`),
      Cluster: getRule(`.gui-label[${ATTRIBUTE_NAME}="Cluster"]`),
      Inclusion: getRule(`.gui-label[${ATTRIBUTE_NAME}="Inclusion"]`),
      Exclusion: getRule(`.gui-label[${ATTRIBUTE_NAME}="Exclusion"]`),
      Event: getRule(`.gui-label[${ATTRIBUTE_NAME}="Event"]`),
    };
    Object.keys(labelsCSSRules).forEach((key) => {
      if (!labelsCSSRules[key as PerceptionClassification | ZoneType])
        console.error(`Can't get ${key} rule`);
    });

    const assetsProp = {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      sensorGeometry: assets.current.sensorGeometry!,
      nodeGeometry: assets.current.nodeGeometry!,
      gizmoTransformBuffers: assets.current.gizmoTransform!,
      carPeripheral: assets.current.carPeripheral!,
      personPeripheral: assets.current.personPeripheral!,
      cornerTrident: assets.current.cornerTrident!,
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    };

    const transformControls = new TransformControls(
      assetsProp,
      canvas,
      viz.camera,
      viz.controls,
    );
    transformControls.space = 'world';

    const selectionBox = new BoxSelection(viz.camera);

    const underlayPlane = new Mesh(
      new PlaneBufferGeometry().translate(0, 0, -0.01),
      new MeshBasicMaterial({
        transparent: true,
      }),
    );
    underlayPlane.name = 'UnderlayPlane';
    underlayPlane.scale.set(
      AppDefaultState.underlayMap.scale,
      AppDefaultState.underlayMap.scale,
      1,
    );

    const r: Context = {
      canvas,
      labelsContainer,
      selectDiv,
      viz,
      world,
      groups3JS,
      gridCartesian,
      gridPolar,
      axes,
      selectionBox,
      assets: assetsProp,
      labelsCSSRules,
      instances: {
        Offender: undefined,
        ProcessedCloud: {
          all: {
            Foreground: {},
            Background: {},
            Ground: {},
            Raw: {},
          },
        },
        TrackedObject: { all: {} },
        TrackedLabel: { all: {} },
        Zones: {
          all: {},
        },
        Source: {
          all: {},
          playback: {},
          referenceSource: null,
          selectedSource: null,
        },
      },
      transformControls,
      underlayPlane,
    };
    setContext(r);
  }, [assetsComplete]);

  // BOOTSTRAP >>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // Logic for Playback or Perception modes
  useEffect(() => {
    if (DEBUG_PLAYBACK) {
      const debugPlayback = async () => {
        // the url the files are hosted at
        const recordingURL = 'http://localhost:9999/';
        const file = 'data (8).gz';
        const response = await fetch(recordingURL + file);

        let arrayBuffer = await response.arrayBuffer();
        try {
          arrayBuffer = await ungzip(arrayBuffer).buffer;
        } catch (error) {
          console.log(error);
        }
        const recording = await parsePlayback(arrayBuffer);

        //Clouds won't show unless a sensor is reachable
        recording.clouds.map((cloud) => {
          dispatch({ type: 'setSensorReachable', id: cloud.id, value: true });
        });

        setRecording(recording);
      };
      debugPlayback();
      return;
    }

    const initPerception = async () => {
      const extrinsics = (await getExtrinsics()) ?? [];
      setExtrinsics(extrinsics);
    };

    const initPlaybackRecording = async (eventId: string) => {
      const path = `https://connectrecordings-dev.ouster.dev/api/v1/recordings/${eventId}`;
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      try {
        const responseRecordingUrl = await fetch(path, {
          mode: 'cors',
          headers,
        });
        const recordingJson = await responseRecordingUrl.json();
        const recordingURL = recordingJson['url'];
        const response = await fetch(recordingURL, { mode: 'cors' });

        let arrayBuffer = await response.arrayBuffer();
        try {
          arrayBuffer = await ungzip(arrayBuffer).buffer;
        } catch (error) {
          console.log(error);
        }
        const recording = await parsePlayback(arrayBuffer);
        recording.clouds.map((cloud) => {
          dispatch({ type: 'setSensorReachable', id: cloud.id, value: true });
        });
        setRecording(recording);
      } catch (error) {
        console.error(error);
        dispatch({
          type: 'setFeedbackMessage',
          value: {
            message: 'Failed to parse data',
            type: 'error',
          },
        });
      }
    };

    // Check if a query parameter exist on the URL to determine if we should init playback mode
    // else continue with perception mode as normal
    const eventId = new URLSearchParams(window.location.search).get('eventid');
    if (eventId !== null) initPlaybackRecording(eventId);
    else initPerception();
  }, []);

  // Determine if playing back pcap
  useEffect(() => {
    (async () => {
      const response = await getAllSensors();
      const servers = response?.sensors ?? [];
      const isPcap = servers.some((server) => server.source_type === 'pcap');
      dispatch({
        type: 'setInputMode',
        value: isPcap ? 'pcap' : 'live',
      });
    })();
  }, []);

  // bootstrap complete
  useEffect(() => {
    if (!splashTimerCompleted) return;
    if (context === null) return;
    // Extrinsics will be null and a recording will be present if in playback inputMode
    // the opposite is true for perception mode (live || pcap )
    if (extrinsics === null && recording === null) return;

    if (recording !== null) {
      dispatch({
        type: 'setInputMode',
        value: 'playback',
      });
      dispatch({
        type: 'setPlayback',
        value: recording,
      });
      dispatch({
        type: 'setMode',
        value: 'viewer',
      });
    } else if (extrinsics !== null) {
      // When no extrinsics exist switch to setup mode
      if (extrinsics.length === 0) {
        dispatch({
          type: 'setFeedbackMessage',
          value: {
            message: 'Can NOT find any extrinsic poses, creating one from IMU',
            type: 'warning',
          },
        });
        dispatch({
          type: 'setMode',
          value: 'setup',
        });
      } else {
        dispatch({
          type: 'setMode',
          value: 'viewer',
        });
      }
    }
    setComplete(true);
  }, [context, extrinsics, recording, splashTimerCompleted]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return bootstrapComplete ? <Layout context={context!} /> : <Splash />;
};
