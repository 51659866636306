/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useState } from 'react';
import { SECONDS_TO_MS } from '../constants';
import { useAppState, useAppDispatch } from '../Stores';
import './RecordButton.css';
import { MAX_FILENAME_SIZE } from './RecordingList';

const RecordingName = ({
  displayTime,
  recordingName,
}: {
  displayTime: string;
  recordingName: string;
}) => {
  const fullRecordingName = recordingName + '.pcap';
  const Marquee = React.useMemo(
    () => (
      <span className="flex items-center justify-between">
        [
        <div className="marquee">
          <div>
            <span>{(recordingName + '.pcap').toUpperCase()}</span>
          </div>
        </div>
        ]
      </span>
    ),
    [recordingName],
  );

  return (
    <p
      id="Time"
      className="flex items-center justify-between text-sm"
      style={{
        color: 'var(--error)',
        fontWeight: 300,
        userSelect: 'none',
        pointerEvents: 'none',
      }}
    >
      <span className="mr-1">{displayTime}</span>
      {fullRecordingName.length >= MAX_FILENAME_SIZE.LIST ? (
        Marquee
      ) : (
        <span>[{(recordingName + '.pcap').toUpperCase()}]</span>
      )}
    </p>
  );
};

const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const elapsed = (initial: Date, final: Date) => {
  const secondsDiff = Math.floor(
    (final.getTime() - initial.getTime()) / SECONDS_TO_MS,
  );

  const seconds = secondsDiff % SECONDS_IN_MINUTE;
  const minutesDiff = Math.floor(secondsDiff / SECONDS_IN_MINUTE);
  const minutes = minutesDiff % MINUTES_IN_HOUR;
  const hoursDiff = Math.floor(minutesDiff / MINUTES_IN_HOUR);
  const hours = hoursDiff % HOURS_IN_DAY;

  return (
    `${hours.toFixed(0).padStart(2, '0')}:` +
    `${minutes.toFixed(0).padStart(2, '0')}:` +
    `${seconds.toFixed(0).padStart(2, '0')}`
  );
};

const DEFAULT_DISPLAY = '00:00:00';
const RecordTime = ({
  isRecording,
}: {
  isRecording: boolean;
}): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const { recording } = state;
  const [displayTime, setDisplayTime] = useState<string>(DEFAULT_DISPLAY);

  // Reset the start time at every recording started
  useEffect(() => {
    if (isRecording) {
      !recording.recordingStartedTS &&
        dispatch({ type: 'setRecordingStartedTS', value: new Date() });
    } else {
      setDisplayTime(DEFAULT_DISPLAY);
    }
  }, [isRecording]);

  useEffect(() => {
    const id =
      isRecording && recording.recordingStartedTS
        ? setInterval(() => {
            recording.recordingStartedTS &&
              setDisplayTime(
                elapsed(recording.recordingStartedTS, new Date(Date.now())),
              );
          })
        : null;

    return () => {
      id && clearInterval(id);
    };
  }, [recording.recordingStartedTS, isRecording]);

  return isRecording ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        className="recording-blinker"
        style={{
          width: '4px',
          height: '4px',
          borderRadius: '100%',
          backgroundColor: 'var(--error)',
          marginRight: '3px',
        }}
      ></div>

      <RecordingName
        displayTime={displayTime}
        recordingName={recording.recordingName}
      />
    </div>
  ) : null;
};

export default RecordTime;
