/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import TextArea from '../app/components/TextArea/TextArea';
import { useAppDispatch, useAppState } from '../Stores';

const ZoneMetaDataTextArea = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const id = state.zones.selected ?? '';

  const paramsRedefined = state.zones.zoneRedefined?.[id];
  const paramsStores = state.zones.params[id];
  const params = paramsRedefined || paramsStores;
  const { metadata } = params ?? {};

  return (
    <TextArea
      name="Zone Metadata"
      disabled={state.zones.selected === null}
      value={metadata ?? ''}
      autoCorrect="off"
      spellCheck={false}
      onChange={(e) => {
        if (id === '') return;
        const paramsRedefined = state.zones.zoneRedefined?.[id];
        const paramsStores = state.zones.params[id];
        const params = paramsRedefined || paramsStores;
        dispatch({
          type: 'setZoneRedefined',
          id,
          value: { ...params, metadata: e.target.value },
        });
      }}
    />
  );
};

export default ZoneMetaDataTextArea;
