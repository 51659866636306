/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppState } from '../../Stores';
import { endpoints } from '../../api/endpoints';
import { ABOUT_POLL_INTERVAL } from '../../constants';
import { HTTPServersWithAbout } from '../../types';
import useInterval from './useInterval';

const { about } = endpoints;

const useGetAbout = (): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const gotAboutSet = useRef<Set<HTTPServersWithAbout> | null>(null);
  const [gotAbout, setGotAbout] = useState<boolean>(false);

  useEffect(() => {
    gotAboutSet.current = new Set<HTTPServersWithAbout>();
  }, []);

  const getAbout = async (): Promise<boolean> => {
    if (gotAboutSet.current === null) {
      return false;
    }

    for (const service of HTTPServersWithAbout) {
      if (gotAboutSet.current.has(service)) continue;

      const response = await about[service]();
      if (response !== null) {
        gotAboutSet.current.add(service);
        let version = {
          commit: '',
          count: '',
          tag: '',
          commit_timestamp: {
            iso: '',
            unix: '',
          },
        };
        if (response.version) {
          const { major, minor, patch } = response.version;
          version = { ...response.version, tag: `${major}.${minor}.${patch}` };

          // Append the prerelease tag if it was sent by the backend and it isn't the empty string.
          if (response.version.pre_release_tag) {
            version.tag += `-${response.version.pre_release_tag}`;
          }

          // The perception client returns a different response interface, we'll
          // adapt it to fit our store's consistent version info interface. UNIX and count will
          // remain empty and not be used.
        } else {
          version.tag = response.software_version;
          version.commit = response.id;
          version.commit_timestamp.iso = new Date().toISOString();
        }

        dispatch({
          type: 'setServiceVersion',
          service,
          version,
        });
      }
    }

    // The effect at the bottom depends on this return value. When it changes,
    // the interval is cleared and on re-entry, we check if this return value
    // is true -- in which case, we don't start the interval again. In short,
    // once we got the about info from all endpoints, we stop polling since the info won't change.
    return HTTPServersWithAbout.every((service) =>
      gotAboutSet.current?.has(service),
    );
  };

  const [, setActive] = useInterval(
    async () => setGotAbout(await getAbout()),
    ABOUT_POLL_INTERVAL,
    false,
  );

  useEffect(() => {
    if (state.app.inputMode === 'playback') return;
    // About serves static info, we only need to get it once.
    if (gotAbout) {
      setActive(false);
      return;
    }

    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode, gotAbout]);
};

export default useGetAbout;
