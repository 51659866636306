/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import './PanZoomCanvas.css';
import React, { useEffect, useRef } from 'react';

const MIN_SCALE = 1;
const MAX_SCALE = 3;
const SCROLL_SENSITIVITY = 1.1; // Scale by this factor every scroll "tick"

// eslint-disable-next-line react/display-name
export const PanZoomCanvas = React.memo(
  ({ image }: { image: HTMLImageElement | ImageBitmap }): JSX.Element => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const ctxRef = useRef<CanvasRenderingContext2D | null>(null);

    // Variables for controlling panning and zooming
    const isDragging = useRef(false);
    const position = useRef({ x: 0, y: 0 });
    const scale = useRef(1);

    // Even though the canvas has a set width and height, it may appear as a different size on
    // screen because of CSS. We keep track of how scaled it is because when panning we have to
    // calculate how many pixels the mouse moved in *canvas space* rather than *screen space*
    // i.e. if the canvas is scaled by 2x, then a mouse movement of 100px in screen space is a
    // movement of 50px in canvas space
    const canvasScale = useRef({ x: 1, y: 1 });

    useEffect(() => {
      if (canvasRef.current) {
        ctxRef.current = canvasRef.current.getContext('2d');
      }
    }, []);

    useEffect(() => {
      renderImage();
    }, [image]);

    // Renders the image onto the canvas (tiled horizontally)
    const renderImage = () => {
      if (!canvasRef.current) return;
      if (!ctxRef.current) return;

      // Resize canvas if image size is different
      if (canvasRef.current.width != image.width) {
        canvasRef.current.width = image.width;
      }
      if (canvasRef.current.height != image.height) {
        canvasRef.current.height = image.height;
      }

      const { width, height } = canvasRef.current.getBoundingClientRect();
      canvasScale.current.x = width / image.width;
      canvasScale.current.y = height / image.height;

      moveImageInBounds();

      ctxRef.current.setTransform(
        scale.current,
        0,
        0,
        scale.current,
        position.current.x,
        position.current.y,
      );

      // Centre
      ctxRef.current.drawImage(image, 0, 0);

      // Left
      if (position.current.x > 0) {
        ctxRef.current.drawImage(image, -image.width, 0);
      }

      // Right
      const realWidth = image.width * scale.current;
      if (position.current.x + realWidth < canvasRef.current.width) {
        ctxRef.current.drawImage(image, image.width, 0);
      }
    };

    // Move the image so it is inside canvas bounds
    const moveImageInBounds = () => {
      if (!canvasRef.current) return;

      if (position.current.y > 0) {
        position.current.y = 0;
      }

      const realHeight = image.height * scale.current;
      if (position.current.y + realHeight < canvasRef.current.height) {
        position.current.y = canvasRef.current.height - realHeight;
      }

      // Keep image in bounds horizontally because it gets tiled infinitely
      position.current.x %= image.width * scale.current;
    };

    const onMouseDown = () => {
      isDragging.current = true;
    };
    const onMouseUp = () => {
      isDragging.current = false;
    };
    const onMouseOut = () => {
      isDragging.current = false;
    };

    const onMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
      if (isDragging.current) {
        position.current.x += e.movementX / canvasScale.current.x;
        position.current.y += e.movementY / canvasScale.current.y;

        renderImage();
      }
    };

    const onWheel = (e: React.WheelEvent<HTMLCanvasElement>) => {
      if (!isDragging.current) {
        const zoomIn = e.deltaY < 0;

        if (zoomIn && scale.current === MAX_SCALE) return;
        if (!zoomIn && scale.current === MIN_SCALE) return;
        if (e.deltaY === 0) return;

        const oldScale = scale.current;

        if (zoomIn) {
          scale.current *= SCROLL_SENSITIVITY;
        } else {
          scale.current /= SCROLL_SENSITIVITY;
        }

        // Clamp scale between minimum and maximum
        scale.current = Math.min(Math.max(scale.current, MIN_SCALE), MAX_SCALE);

        // Translate the image so the scale will be done towards the cursor
        // See https://stackoverflow.com/a/45068045
        const ratio = 1 - scale.current / oldScale;
        position.current.x +=
          (e.nativeEvent.offsetX / canvasScale.current.x - position.current.x) *
          ratio;
        position.current.y +=
          (e.nativeEvent.offsetY / canvasScale.current.y - position.current.y) *
          ratio;

        renderImage();
      }
    };

    return (
      <canvas
        className="PanZoomCanvas"
        width={0}
        height={0}
        ref={canvasRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseOut={onMouseOut}
        onMouseMove={onMouseMove}
        onWheel={onWheel}
      ></canvas>
    );
  },
);
