/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ReactNode } from 'react';

const Value = ({
  isDirty = false,
  children,
}: {
  isDirty?: boolean;
  children: ReactNode;
}): JSX.Element => {
  return (
    <div className={`flex items-center ${isDirty ? 'textWarning' : ''}`}>
      {isDirty && <div className="mx-2">!</div>}
      <div className="flex items-center text-base textGreyC">{children}</div>
    </div>
  );
};

export default Value;
