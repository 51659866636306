/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useEffect, useRef } from 'react';
import { Alert } from '../api/alerts';
import { useAppDispatch, useAppState } from '../Stores';
import diagnosticsColumns from './diagnosticsColumns';
import DiagnosticsTable from './DiagnosticsTable';

const DiagnosticsTables = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const diagnostics = state.diagnostics;
  const systemAlerts = useRef({} as Record<string, Record<string, Alert>>);
  const sensorAlerts = useRef({} as Record<string, Record<string, Alert>>);

  useEffect(() => {
    const formatAlerts = (alertIds: string[]) => {
      return alertIds.reduce((acc, alertId) => {
        const alert = diagnostics.allAlerts[alertId];
        if (alert === undefined) console.log(alertId);
        const { sourceInfo } = alert;

        if (acc[sourceInfo] === undefined) {
          acc[sourceInfo] = {};
        }
        acc[sourceInfo][alertId] = alert;
        return acc;
      }, {} as Record<string, Record<string, Alert>>);
    };

    systemAlerts.current = formatAlerts(diagnostics.systemAlertIds);
    sensorAlerts.current = formatAlerts(diagnostics.sensorAlertIds);
  }, [
    diagnostics.systemAlertIds,
    diagnostics.sensorAlertIds,
    diagnostics.allAlerts,
  ]);

  return (
    <div
      className="diagnostics diagnostics-tables"
      style={{
        userSelect: 'none',
        overflow: 'auto',
        // Reserve scrollbar padding to prevent layout shift
        scrollbarGutter: 'stable',
        width: '100%',
      }}
    >
      <div className="alert-tables">
        <DiagnosticsTable
          data={systemAlerts.current}
          columns={diagnosticsColumns}
          isExpanded={!diagnostics.isCollapsed.outerSystem}
          onExpandChange={() =>
            dispatch({
              type: 'setCollapsedState',
              instance: 'outerSystem',
              value: !diagnostics.isCollapsed.outerSystem,
            })
          }
          name="System"
          type="system"
        />
        <DiagnosticsTable
          data={sensorAlerts.current}
          columns={diagnosticsColumns}
          isExpanded={!diagnostics.isCollapsed.outerSensor}
          onExpandChange={() =>
            dispatch({
              type: 'setCollapsedState',
              instance: 'outerSensor',
              value: !diagnostics.isCollapsed.outerSensor,
            })
          }
          name="Sensors"
          type="sensor"
        />
      </div>
    </div>
  );
};

export default DiagnosticsTables;
