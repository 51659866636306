/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useState } from 'react';

const timeNow = () => new Date().toTimeString().split(' ')[0];

export const Time = (): JSX.Element => {
  const [time, setTime] = useState<string>(timeNow());

  useEffect(() => {
    const id = setInterval(() => {
      setTime(timeNow());
    });
    return () => {
      clearInterval(id);
    };
  }, []);
  return (
    <p
      id="Time"
      className="text-base textGreyD"
      style={{
        position: 'absolute',
        left: '5px',
        bottom: '5px',
        fontWeight: 100,
        userSelect: 'none',
        pointerEvents: 'none',
      }}
    >
      {time}
    </p>
  );
};
