/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable sonarjs/no-nested-switch */
import {
  Color,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshBasicMaterialParameters,
  Quaternion,
  Vector3,
} from 'three';
import { Group, BufferGeometry } from 'three';
import {
  COLOR_HIGHLIGHTED,
  COLOR_IDEAL,
  COLOR_SELECTED,
  ORIGIN,
  LAYER_ICONS,
  UNIT_X,
  UNIT_Y,
  UNIT_Z,
} from '../constants';
import {
  Activatable,
  Highlightable,
  Plane,
  Raycastable,
  Selectable,
  Space,
  Transform,
} from '../types';
import { Axis } from '../types';
import { AXIS_ALL, AXIS_BLUE, AXIS_GREEN, AXIS_RED } from '../util/palettes';
import { unitVectors } from './TransformControls';

// const _alignVector = new Vector3(0, 1, 0);
const _identityQuaternion = new Quaternion();
const _lookAtMatrix = new Matrix4();

const matOpt: MeshBasicMaterialParameters = {};

const materials: Record<Axis | Plane, MeshBasicMaterial> = {
  x: new MeshBasicMaterial({
    ...matOpt,
    color: AXIS_RED,
  }),
  y: new MeshBasicMaterial({
    ...matOpt,
    color: AXIS_GREEN,
  }),
  z: new MeshBasicMaterial({
    ...matOpt,
    color: AXIS_BLUE,
  }),
  xy: new MeshBasicMaterial({
    ...matOpt,
    color: new Color().setStyle(AXIS_BLUE).offsetHSL(0, 0.75, -0.2),
  }),
  xz: new MeshBasicMaterial({
    ...matOpt,
    color: new Color().setStyle(AXIS_GREEN).offsetHSL(0, 0.75, -0.2),
  }),
  yz: new MeshBasicMaterial({
    ...matOpt,
    color: new Color().setStyle(AXIS_RED).offsetHSL(0, 0.75, -0.2),
  }),
  view: new MeshBasicMaterial({
    ...matOpt,
    color: AXIS_ALL,
  }),
};
const materialHighlighted = new MeshBasicMaterial({
  ...matOpt,
  color: COLOR_HIGHLIGHTED,
});
const materialSelected = new MeshBasicMaterial({
  ...matOpt,
  color: COLOR_SELECTED,
});
const GEO_SCALE = 3;
export class TransformControlsGizmo
  extends Group
  implements Selectable, Highlightable, Raycastable, Activatable
{
  private readonly icon: Mesh;
  private _isSelected = false;
  private _isHighlighted = false;
  private idealColor = COLOR_IDEAL;
  private _isActive = true;
  private isActiveFromPOV = true;

  public readonly isGizmoHandle3JS = true;
  public readonly raycastMesh: Mesh;
  public axis: Axis | Plane;
  public transform: Transform;
  public space: Space = 'local';
  public eye = new Vector3();
  public worldPosition = new Vector3();
  public worldQuaternion = new Quaternion();
  public scaleFactor = 1;

  constructor(
    geometryBuffer: BufferGeometry,
    axis: Axis | Plane,
    transform: Transform,
  ) {
    super();
    this.name = `GizmoHandle3JS-${transform}-${axis}`;
    this.axis = axis;
    this.transform = transform;

    // position accordingly
    const m = new Matrix4();
    const halfPi = Math.PI / 2;
    if (transform === 'rotate') {
      switch (axis) {
        case 'y': {
          m.makeRotationZ(halfPi);
          break;
        }
        case 'z': {
          m.makeRotationY(-halfPi);
          break;
        }
      }
    }
    const geo = geometryBuffer
      .clone()
      .scale(GEO_SCALE, GEO_SCALE, GEO_SCALE)
      .applyMatrix4(m);
    this.icon = new Mesh(geo, materials[axis]);
    this.icon.layers.set(LAYER_ICONS);

    this.add(this.icon);

    this.raycastMesh = this.icon;
  }

  updateMatrixWorld(force?: boolean): void {
    if (!this._isActive) return;

    const space = this.transform === 'scale' ? 'local' : this.space; // scale always oriented to local rotation

    const quaternion =
      space === 'local' ? this.worldQuaternion : _identityQuaternion;

    this.icon.rotation.set(0, 0, 0);
    this.icon.quaternion.copy(quaternion);
    this.icon.position.copy(this.worldPosition);
    this.icon.scale.set(1, 1, 1).multiplyScalar(this.scaleFactor);

    // Align handles to current local or world rotation
    if (this.axis === 'view') {
      this.icon.quaternion.setFromRotationMatrix(
        _lookAtMatrix.lookAt(this.eye, ORIGIN, unitVectors.z),
      );
    }
    super.updateMatrixWorld(force);

    if (this.transform === 'rotate') {
      let rads = 0;
      switch (this.axis) {
        case 'x': {
          rads = Math.acos(this.eye.dot(UNIT_X));
          break;
        }
        case 'y': {
          rads = Math.acos(this.eye.dot(UNIT_Y));
          break;
        }
        case 'z': {
          rads = Math.acos(this.eye.dot(UNIT_Z));
          break;
        }

        default:
          break;
      }
      this.isActiveFromPOV = this.visible =
        rads < Math.PI * 0.4 || rads > Math.PI * 0.6;
    }
  }

  private calcMaterial = (): void => {
    if (this._isHighlighted) {
      this.icon.material = materialHighlighted;
    } else if (this._isSelected) {
      this.icon.material = materialSelected;
    } else {
      this.icon.material = materials[this.axis];
    }
  };

  public set isHighlighted(value: boolean) {
    if (this._isHighlighted === value) return;
    this._isHighlighted = value;
    this.calcMaterial();
  }
  public get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  public set isSelected(value: boolean) {
    if (this._isSelected === value) return;
    this._isSelected = value;
    this.calcMaterial();
  }
  public get isSelected(): boolean {
    return this._isSelected;
  }

  public get isActive(): boolean {
    return this._isActive && this.isActiveFromPOV;
  }
  public set isActive(value: boolean) {
    this._isActive = this.visible = value;
  }
}
