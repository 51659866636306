/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { createContext, ReactNode, useContext, useReducer } from 'react';

export type ImageActions = {
  type: 'setImageBuffer';
  sensorId: string;
  value: Uint8Array;
};

type ImageState = {
  images: { [id: string]: Uint8Array };
};

const ImageInitialState: ImageState = {
  images: {},
};

const reducer = (state: ImageState, action: ImageActions) => {
  if (action.type === 'setImageBuffer') {
    return {
      ...state,
      images: { ...state.images, [action.sensorId]: action.value },
    };
  }

  return state;
};

type Dispatch = (action: ImageActions) => void;

const ImageContext = createContext<ImageState | undefined>(undefined);
const ImageDispatchContext = createContext<Dispatch | undefined>(undefined);

export const ImageProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, ImageInitialState);

  return (
    <ImageContext.Provider value={state}>
      <ImageDispatchContext.Provider value={dispatch}>
        {children}
      </ImageDispatchContext.Provider>
    </ImageContext.Provider>
  );
};

export const useImageState = (): ImageState => {
  const ctx = useContext(ImageContext);
  if (ctx) return ctx;
  else throw new Error('useImageState must be within an ImageProvider');
};

export const useImageDispatch = (): Dispatch => {
  const ctx = useContext(ImageDispatchContext);
  if (ctx) return ctx;
  else throw new Error('useImageDispatch must be within an ImageProvider');
};
