/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppState } from '../../Stores';
import { Context } from '../../types';
import { ObjectKeys } from '../../util/misc';

export const useGlobalVisibilities = (context: Context): void => {
  const state = useAppState();

  // instances visibility
  useEffect(() => {
    const groups = context.groups3JS;
    for (const elem of ObjectKeys(state.app.visibilities)) {
      const value = state.app.visibilities[elem];
      switch (elem) {
        case 'Grid': {
          context.gridCartesian.visible = value;
          break;
        }
        case 'Axes': {
          context.axes.visible = value;
          break;
        }
        case 'Clouds': {
          groups.ProcessedCloud.visible = value;
          break;
        }
        case 'TrackedObjects': {
          groups.TrackedObject.visible = value;
          break;
        }
        case 'Zones': {
          groups.Zone.visible = value;
          break;
        }
      }
    }
  }, [state.app.visibilities]);
};
