/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Alert, SourceType } from '../api/alerts';
import { useAppDispatch, useAppState } from '../Stores';
import { ObjectEntries } from '../util/misc';
import { Column } from './diagnosticsColumns';
import ExpandableRow from './ExpandableRow';
import DiagnosticsTableSourceSubsection from './DiagnosticsTableSourceSubsection';
import {
  matchesFilterCode,
  matchesFilterLevel,
  matchesFilterActivity,
  matchesFilterTime,
} from './DiagnosticsStore';

const AlertsTable = ({
  alerts,
  columns,
  align = 'center',
}: {
  alerts: [string, Alert][];
  columns: Column<Alert>[];
  align?: 'center' | 'left' | 'right';
}) => {
  return (
    <table
      style={{
        width: '100%',
        textAlign: align,
      }}
    >
      <tbody
        style={{
          color: 'var(--greyA)',
          fontSize: 'var(--r4)',
          fontWeight: 'normal',
        }}
      >
        {alerts.map(([id, alert]) => (
          <ExpandableRow
            key={`${alert.alertCode}-${id}`}
            columns={columns}
            rowContent={alert}
          />
        ))}
      </tbody>
    </table>
  );
};

const SourceTable = ({
  source,
  type,
  alerts,
  columns,
}: {
  source: string;
  type: SourceType;
  alerts: Record<string, Alert>;
  columns: Column<Alert>[];
}): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const isCollapsed = state.diagnostics.isCollapsed[source];
  const { filterCode, filterLevel, filterActivity, filterTime } =
    state.diagnostics;

  const emptyFilter =
    filterCode.includes('All') &&
    filterLevel.includes('All') &&
    filterActivity.includes('All') &&
    filterTime.includes('All');

  const filteredAlerts: [string, Alert][] = emptyFilter
    ? ObjectEntries(alerts)
    : ObjectEntries(alerts).filter(
        ([, alert]) =>
          matchesFilterCode(filterCode, alert.alertCode) &&
          matchesFilterLevel(filterLevel, alert.level) &&
          matchesFilterActivity(filterActivity, alert.active) &&
          matchesFilterTime(filterTime, alert.lastOccured),
      );
  /* Sort so the latest alerts show up first */
  filteredAlerts.sort(
    (alertA, alertB) => alertB[1].firstOccured - alertA[1].firstOccured,
  );

  return filteredAlerts.length > 0 ? (
    <>
      <DiagnosticsTableSourceSubsection
        name={source}
        type={type}
        onClick={() =>
          dispatch({
            type: 'setCollapsedState',
            instance: source,
            value: !isCollapsed,
          })
        }
        open={!isCollapsed}
      />
      <div
        className="collapsable-source-table"
        style={{
          display: isCollapsed ? 'none' : 'block',
          height: isCollapsed ? '0' : 'auto',
        }}
      >
        <AlertsTable alerts={filteredAlerts} align="left" columns={columns} />
      </div>
    </>
  ) : null;
};

export default SourceTable;
