/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef } from 'react';
import { PerceptionZoneServerInterface } from './PerceptionZoneServerInterface';
import { useAppDispatch, useAppState } from '../../Stores';
import { adaptZones } from '../../util/adapters';
import { ZonesSchema } from '../eventZone/EventZoneServerInterface';
import useInterval from '../../app/hooks/useInterval';

const RETRY_INTERVAL_MS = 2000;

export const usePointZoneSocket = (): void => {
  const state = useAppState();
  const perceptionRestClient = useRef<PerceptionZoneServerInterface>();
  const prevZonesPayload = useRef('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    perceptionRestClient.current = new PerceptionZoneServerInterface();

    perceptionRestClient.current.onZones = (pointZones: ZonesSchema) => {
      const zones = pointZones.zones;
      if (zones === null) return;
      const adapted = adaptZones(zones);
      const serialized = JSON.stringify(adapted);
      const isSame = serialized === prevZonesPayload.current;
      if (!isSame) {
        dispatch({
          type: 'setZones',
          value: adapted,
          isEventZone: false,
        });

        prevZonesPayload.current = serialized;
      }
    };
  }, []);

  const [, setActive] = useInterval(
    () => {
      if (perceptionRestClient.current)
        perceptionRestClient.current.fetchAllZones.bind(
          perceptionRestClient.current,
        )();
    },
    RETRY_INTERVAL_MS,
    false,
  );

  useEffect(() => {
    const shouldFetch =
      state.app.inputMode === 'live' || state.app.inputMode === 'pcap';
    if (!shouldFetch) return;

    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode]);
};
