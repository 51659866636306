/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { ColorPresetPicker } from '../app/components/pane/ColorPresetPicker';
import { EyeToggle } from '../app/components/pane/eye/Visible';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { UpDownButtons } from '../app/components/pane/UpDownButtons';
import { MAX_POINTS_SIZE, MIN_POINTS_SIZE, Palettes } from '../constants';
import { useAppState, useAppDispatch } from '../Stores';
import { PerceptionClassification } from '../types';

export const TrackedObjectsList = ({
  label,
  expandedInitValue = true,
  list = [],
}: {
  label: string;
  list: PerceptionClassification[];
  expandedInitValue?: boolean;
}): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const palette = Palettes[state.app.palette];

  return (
    <PropertiesSubGroup name={label} expandedInitValue={expandedInitValue}>
      {list.map((classification) => (
        <div className="flex items-center py-1" key={classification}>
          <p className="grow text-md textGreyD">{classification}</p>
          <UpDownButtons
            isUpEnabled={
              state.tracked.classificationPointSize[classification] <
              MAX_POINTS_SIZE
            }
            isDownEnabled={
              state.tracked.classificationPointSize[classification] >
              MIN_POINTS_SIZE
            }
            onClick={(key) => {
              dispatch({
                type: 'setClassificationPointSize',
                classification,
                value: key === 'up' ? 1 : -1,
              });
            }}
            tooltip={{
              text: 'Adjust Point Size',
              offset: 'top',
            }}
          />
          <EyeToggle
            isOn={state.tracked.classificationVisibilities[classification]}
            onClick={() => {
              dispatch({
                type: 'setClassificationVisibility',
                classification,
                value:
                  !state.tracked.classificationVisibilities[classification],
              });
            }}
            tooltip={{
              text: state.tracked.classificationVisibilities[classification]
                ? 'Hide'
                : 'Display',
              offset: 'top',
            }}
          />
          <ColorPresetPicker
            initial={
              palette[state.tracked.classificationColors[classification]]
            }
            onChange={(colorIndex) => {
              dispatch({
                type: 'setClassificationColor',
                classification,
                colorIndex,
              });
            }}
            colors={palette}
            side="left"
          />
        </div>
      ))}
    </PropertiesSubGroup>
  );
};
