/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { Bootstrap } from './Bootstrap';
import { AppProvider } from './Stores';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ImageProvider } from './image2d/ImageStore';

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <ImageProvider>
        <Bootstrap />
      </ImageProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// disable right click context menu
document.addEventListener('contextmenu', (event) => event.preventDefault());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
