/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import {
  Group,
  LineSegments,
  EdgesGeometry,
  LineBasicMaterial,
  Shape,
  ExtrudeGeometry,
  Mesh,
  Vector3,
  LineBasicMaterialParameters,
  Vector2,
  Line,
} from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import {
  ATTRIBUTE_NAME,
  COLOR_HIGHLIGHTED,
  COLOR_SELECTED,
  COLOR_WARNING,
} from '../constants';
import {
  Context,
  Dirtyable,
  Editable,
  Highlightable,
  OType,
  Raycastable,
  Selectable,
  ZoneType,
} from '../types';
import { convexHull } from '../util/misc';

const pool: Zone3JS[] = [];
export const getFromZonePool = (): Zone3JS => {
  let obj: Zone3JS | undefined;
  if (pool.length > 0) {
    obj = pool.pop();
  }
  if (!obj) obj = new Zone3JS();
  obj.setZoneAndLabelVisible(true);
  obj.isEdited = false;
  return obj;
};

export const putToZonePool = (obj: Zone3JS): void => {
  obj.setZoneAndLabelVisible(false);
  pool.push(obj);
};

const materialParams: LineBasicMaterialParameters = {
  linewidth: 2,
  transparent: true,
  opacity: 0.25,
};
const materialSelected = new LineBasicMaterial({
  ...materialParams,
  linewidth: 3,
  color: COLOR_SELECTED,
  transparent: false,
});
const materialHighlighted = new LineBasicMaterial({
  ...materialParams,
  linewidth: 4,
  color: COLOR_HIGHLIGHTED,
  transparent: false,
});
const materialOccupied = new LineBasicMaterial({
  ...materialParams,
  opacity: 0.6,
  linewidth: 2,
});
const materialDirty = new LineBasicMaterial({
  ...materialParams,
  linewidth: 3,
  color: COLOR_WARNING,
  transparent: false,
});

const wireMaterials: Record<ZoneType, LineBasicMaterial> = {
  Event: new LineBasicMaterial(materialParams),
  Inclusion: new LineBasicMaterial(materialParams),
  Exclusion: new LineBasicMaterial(materialParams),
};

export const setColor = (
  zoneType: ZoneType,
  style: string,
  context: Context,
): void => {
  // wire
  wireMaterials[zoneType].color.setStyle(style);
  // occupied
  if (zoneType === 'Event') materialOccupied.color.setStyle(style);
  // label
  const rule = context.labelsCSSRules[zoneType];
  if (rule !== undefined) rule.style.color = style;
};

export class Zone3JS
  extends Group
  implements OType, Highlightable, Selectable, Raycastable, Editable, Dirtyable
{
  public readonly isZone3JS = true;
  public readonly oType: string;
  public oId: string;
  public transformInverse = new Group();
  public heightMin = 0;
  public heightMax = 1;
  public vertices: Vector2[] = [];
  public zoneType: ZoneType = 'Event';
  private label: CSS2DObject;
  private labelElem: HTMLDivElement;
  private labelParam: {
    name: HTMLSpanElement;
    occupations: HTMLSpanElement;
    type: HTMLSpanElement;
  };

  private wireframe: LineSegments;
  private profile: Line;
  private heightCol: Line;
  private raycastable: Mesh;
  private numOccupations = 0;
  private centroid: Vector3;
  private _isHighlighted = false;
  private _isSelected = false;
  private _isEdited = false;
  private _isDirty = true;

  constructor() {
    super();
    this.oType = 'Zone3JS';
    this.oId = 'none';
    this.name = 'none';
    // Inverse translation to keep vertices world space values and allow root transform be at the center of them
    this.add(this.transformInverse);
    this.transformInverse.name = 'transformInverse';

    this.labelElem = document.createElement('div');
    this.labelElem.className = 'gui-label';
    this.labelElem.innerHTML = `
    <div id='zone' class='flex flex-col items-center drop-shadow-ui'>
      <div id='occupations' class="text-3xl"></div>
      <div id='name' class='text-base'></div>
      <div id='type' class='text-xxs'></div>
    </div>
    `;

    this.labelParam = {
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      name: this.labelElem.querySelector('#name')!,
      occupations: this.labelElem.querySelector('#occupations')!,
      type: this.labelElem.querySelector('#type')!,
      /* eslint-enable  @typescript-eslint/no-non-null-assertion */
    };

    this.label = new CSS2DObject(this.labelElem);
    this.transformInverse.add(this.label);

    this.wireframe = new LineSegments();
    this.wireframe.frustumCulled = false;
    this.profile = new Line();
    this.profile.frustumCulled = false;

    this.heightCol = new LineSegments();
    this.raycastable = new Mesh();
    this.raycastable.name = 'zone.raycastable';
    this.centroid = new Vector3();
    this.isEdited = false;
  }

  public set = (
    id: string,
    zoneType: ZoneType,
    name: string,
    heightMin: number,
    heightMax: number,
    vertices: Vector2[],
  ): void => {
    this.oId = id;
    this.name = name;
    this.zoneType = zoneType;

    this.labelParam.name.innerText = this.name;
    this.labelParam.occupations.innerText =
      zoneType === 'Event' ? `${this.numOccupations}` : '';
    this.labelParam.type.innerText =
      zoneType !== 'Event' ? `${this.zoneType}` : '';

    this.labelElem.setAttribute(ATTRIBUTE_NAME, this.zoneType);

    this.heightMin = heightMin;
    this.heightMax = heightMax;
    this.vertices = vertices;

    // reset the offsets
    this.position.set(0, 0, 0);
    this.transformInverse.position.set(0, 0, 0);
    this.quaternion.identity();
    this.updateMatrixWorld(true);

    const shape = new Shape(vertices);
    const height = heightMax - heightMin;
    const geo = new ExtrudeGeometry(shape, {
      depth: height,
      bevelEnabled: false,
    });
    this.profile.geometry.setFromPoints([
      ...shape.getPoints(),
      shape.getPointAt(0),
    ]);

    this.wireframe.removeFromParent();
    this.wireframe.geometry.dispose();
    this.wireframe = new LineSegments(new EdgesGeometry(geo));

    const zCenter = (heightMax + heightMin) / 2;
    const posZ = zCenter - height / 2;
    this.profile.position.set(0, 0, posZ);
    this.transformInverse.add(this.wireframe);
    this.wireframe.position.set(0, 0, posZ);
    this.transformInverse.add(this.profile);

    this.raycastable.removeFromParent();
    this.raycastable.geometry.dispose();
    this.raycastable = new Mesh(geo);
    this.raycastable.position.set(0, 0, posZ);
    this.transformInverse.add(this.raycastable);
    this.raycastable.visible = false;

    const firstPoint = vertices[0];
    const secondPoint = vertices[1];
    const lastPoint = vertices[shape.getPoints().length - 1];
    const firstVertex = new Vector3(firstPoint.x, firstPoint.y, heightMax);
    const secondVertex = new Vector3(secondPoint.x, secondPoint.y, heightMax);
    const lastVertex = new Vector3(lastPoint.x, lastPoint.y, heightMax);
    const AXIS_LENGTH = 0.4;
    this.heightCol.geometry.setFromPoints([
      firstVertex,
      secondVertex
        .sub(firstVertex)
        .normalize()
        .multiplyScalar(AXIS_LENGTH)
        .add(firstVertex),
      firstVertex,
      lastVertex
        .sub(firstVertex)
        .normalize()
        .multiplyScalar(AXIS_LENGTH)
        .add(firstVertex),
      firstVertex,
      new Vector3(firstVertex.x, firstVertex.y, -1)
        .sub(firstVertex)
        .normalize()
        .multiplyScalar(AXIS_LENGTH)
        .add(firstVertex),
    ]);
    this.transformInverse.add(this.heightCol);

    this.label.position.copy(firstVertex);

    this.calcCentroid3D(zCenter);

    this.position.copy(this.centroid);
    this.quaternion.identity();
    this.transformInverse.position.copy(this.centroid.negate());
    this.updateMatrixWorld(true);

    this.calcMaterial();
    this.calcVisibleObjects();
    this.isDirty = this._isDirty;
  };

  // Control the visibility of the objects
  public setZoneAndLabelVisible = (visible: boolean): void => {
    this.visible = visible;
    this.label.visible = visible;
  };
  public setZoneVisible = (visible: boolean): void => {
    this.visible = visible;
  };
  public setLabelVisible = (visible: boolean): void => {
    this.label.visible = visible;
  };

  private calcMaterial = (): void => {
    const mat = this._isEdited
      ? materialSelected
      : this._isDirty
      ? materialDirty
      : this._isHighlighted
      ? materialHighlighted
      : this._isSelected
      ? materialSelected
      : this.numOccupations > 0
      ? materialOccupied
      : wireMaterials[this.zoneType];
    this.profile.material = mat;
    this.wireframe.material = mat;
    this.heightCol.material = mat;
  };

  private calcVisibleObjects = (): void => {
    const v = this._isEdited || this.isSelected || this._isHighlighted;
    this.wireframe.visible = v;
    this.profile.visible = !v;
  };

  public set isSelected(value: boolean) {
    if (this._isSelected === value) return;
    this._isSelected = value;
    this.calcMaterial();
    this.calcVisibleObjects();
  }
  public get isSelected(): boolean {
    return this._isSelected;
  }

  public set isHighlighted(value: boolean) {
    if (this._isHighlighted === value) return;
    this._isHighlighted = value;
    this.calcMaterial();
    this.calcVisibleObjects();
  }
  public get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  public set isEdited(value: boolean) {
    if (this._isEdited === value) return;
    this._isEdited = value;
    this.profile.visible = !value;
    this.wireframe.visible = value;
    this.calcMaterial();
    this.calcVisibleObjects();
  }
  public get isEdited(): boolean {
    return this._isEdited;
  }

  public set isDirty(value: boolean) {
    if (this._isDirty === value) return;
    this._isDirty = value;
    this.profile.visible = !value;
    this.wireframe.visible = value;
    this.calcMaterial();
    this.calcVisibleObjects();
    this.labelParam.name.classList.toggle('dirty', this._isDirty);
  }
  public get isDirty(): boolean {
    return this._isDirty;
  }

  public setOccupations = (numObjects: number): void => {
    if (this.zoneType !== 'Event') return;
    this.numOccupations = numObjects;
    this.labelParam.occupations.innerText = `${this.numOccupations}`;
    if (this.numOccupations) this.labelParam.occupations.classList.add('show');
    else this.labelParam.occupations.classList.remove('show');
    this.calcMaterial();
  };

  public get raycastMesh(): Mesh {
    return this.raycastable;
  }

  private calcCentroid3D(zCenter: number): void {
    // Use the hull of the polygon to eliminate
    // a group of points on  a single "face" that would bias the centroid.
    const { centroid } = convexHull(this.vertices);
    this.centroid.set(centroid.x, centroid.y, zCenter);
  }
}
