/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { ColorPresetPicker } from '../app/components/pane/ColorPresetPicker';
import { EyeToggle } from '../app/components/pane/eye/Visible';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { Palettes } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';
import { ZoneType } from '../types';

export const ZonesList = ({
  label,
  list,
}: {
  label: string;
  list: ZoneType[];
}): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const palette = Palettes[state.app.palette];

  return state.zones.allIds.length ? (
    <div className="mb-4">
      <PropertiesSubGroup name={label}>
        <div className="flex flex-col">
          {list.map((zoneType) => {
            const colorId = state.zones.zoneTypeColorIndices[zoneType];
            const color = palette[colorId % palette.length];
            return (
              <div
                className="flex items-center mt-1"
                key={zoneType}
                style={{ flexDirection: 'row-reverse' }}
              >
                <ColorPresetPicker
                  initial={color}
                  onChange={(colorIndex) => {
                    dispatch({
                      type: 'setZoneTypeColor',
                      zoneType,
                      colorIndex,
                    });
                  }}
                  colors={Palettes[state.app.palette]}
                  side="left"
                />
                <EyeToggle
                  isOn={state.zones.typeVisibilities[zoneType]}
                  onClick={() => {
                    dispatch({
                      type: 'setZoneTypeVisibility',
                      value: !state.zones.typeVisibilities[zoneType],
                      zoneType,
                    });
                  }}
                />

                <div
                  className="flex flex-col grow"
                  style={{ color: 'var(--greyD)' }}
                >
                  <p className="text-md textGreyD">{zoneType}</p>
                </div>
              </div>
            );
          })}
        </div>
      </PropertiesSubGroup>
    </div>
  ) : null;
};
