/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppState } from '../../Stores';

export const useMisc = (): void => {
  const state = useAppState();
  // Title
  useEffect(() => {
    document.title = state.app.sceneName;
  }, [state.app.sceneName]);
};
