/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ComponentPropsWithoutRef } from 'react';

export const Pane = ({
  children,
  ...rest
}: ComponentPropsWithoutRef<'aside'>): JSX.Element => {
  return (
    <aside className={`Pane flex flex-row overflow-hidden`} {...rest}>
      <div
        className={`no-select overflow-y px-7 py-3`}
        style={{
          width: 'var(--r80)',
        }}
      >
        {children}
      </div>
    </aside>
  );
};
