/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
//import { useAppDispatch, useAppState } from '../../../Stores';
import '../../Layout.css';
import { ActionButton } from '../actionButton/ActionButton';
import { DocsQRCode } from './DocsQRCode';
import { SupportQRCode } from './SupportQRCode';

const HelpIcon = ({
  active,
  onClick,
}: {
  active: boolean;
  onClick: () => void;
}): JSX.Element => {
  return (
    <button
      className={`MenuBtn bgNone text-md no-select textGreyB`}
      type="button"
      onClick={onClick}
      disabled={false}
      style={{
        margin: '0',
        padding: '0',
      }}
    >
      <svg width="16px" height="16px" fill="var(--greyB)" viewBox="0 0 16 16">
        {active ? (
          <>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </>
        ) : (
          <>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
          </>
        )}
      </svg>
    </button>
  );
};

export const HelpButton = (): JSX.Element => {
  const [active, setActive] = React.useState<boolean>(false);

  /////////////////////////////////////////
  // To anyone looking at how the modal is implemented:
  // It's got hacks to make it placed in the exact right spot. Don't copy this code.
  /////////////////////////////////////////

  return (
    <div
      style={{
        padding: '0px',
        margin: '0 calc(var(--r2) * -1) 0 var(--r2_5)',
      }}
    >
      {active ? (
        <>
          <div style={{ width: 'var(--r4)' }} />
          {/* ^ A placeholder for the help button. */}
          <section
            className="modal-wrapper"
            onClick={() => {
              setActive(false);
            }}
          >
            <section
              className="modal"
              onClick={(e) => {
                e.stopPropagation(); // Prevent modal from closing when clicking inside.
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: '0',
                  margin: 'var(--r2)',
                  backgroundColor: 'var(--greyH)',
                  width: 'calc(var(--r80) - var(--r2) * 2)',
                  borderRadius: '2px',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: '0.75rem',
                    top: '0.98rem', // So the button doesn't appear to move.
                  }}
                >
                  <HelpIcon
                    active={true}
                    onClick={() => {
                      setActive(false);
                    }}
                  />
                </div>
                <div className="p-3 mt-3 mb-3">
                  <p
                    className={`text-lg mr-2 textGreyC`}
                    style={{ textAlign: 'center' }}
                  >
                    Documentation
                  </p>
                  <div
                    style={{
                      margin: 'var(--r6) calc((var(--r64) - 232px))', // 232px is the width of the QR code.
                    }}
                  >
                    <DocsQRCode />
                  </div>
                  <ActionButton
                    icon="ExternalLink"
                    disabled={false}
                    onClick={() => {
                      window.open(
                        'https://static.ouster.dev/ouster-detect/index.html',
                      );
                    }}
                    style={{ margin: 'auto' }}
                  >
                    Ouster Detect Documentation
                  </ActionButton>
                </div>
                <div className="p-3 mt-3 mb-3">
                  <p
                    className={`text-lg mr-2 textGreyC`}
                    style={{ textAlign: 'center' }}
                  >
                    Support
                  </p>
                  <div
                    style={{
                      margin: 'var(--r6) calc((var(--r64) - 231px))', // 231px is the width of the QR code.
                    }}
                  >
                    <SupportQRCode />
                  </div>
                  <ActionButton
                    icon="ExternalLink"
                    disabled={false}
                    onClick={() => {
                      window.open(
                        'https://ouster.atlassian.net/servicedesk/customer/portal/8/group/47/create/171',
                      );
                    }}
                    style={{ margin: 'auto' }}
                  >
                    Ouster Support Portal
                  </ActionButton>
                </div>
              </div>
            </section>
          </section>
        </>
      ) : (
        <HelpIcon
          active={false}
          onClick={() => {
            setActive(true);
          }}
        />
      )}
    </div>
  );
};
