/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { endpoints } from '../../api/endpoints';
import { SECONDS_TO_MS, TELEMETRY_POLL_INTERVAL } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import {
  RecordingFromTelemetrySchema,
  RecordingInitialState,
} from '../../record/RecordingStore';
import useInterval from './useInterval';
import { unstable_batchedUpdates } from 'react-dom';

const { telemetry } = endpoints;

const usePollTelemetry = (): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const pollTelemetry = async () => {
    const { is_paused, recording } = { ...(await telemetry.get()) };
    const {
      filename,
      start_timestamp: newRecordingStartTimestamp,
      length_s,
    } = { ...RecordingInitialState, ...recording };

    // default is playing unless is_paused is true, handles the undefined case
    const serverIsPlaying = is_paused !== true;

    // In live mode isPlaying matches the current gui state
    // In pcap mode it matchs the server state
    const isPlaying =
      state.app.inputMode === 'live' ? state.app.isPlaying : serverIsPlaying;

    const newIsRecording = recording !== undefined && recording !== null;
    const newRecordingName = newIsRecording && filename ? filename : '';
    const newRecordingStartedTS =
      newIsRecording && newRecordingStartTimestamp
        ? // Backend timestamp is in microseconds, we use milli
          new Date(newRecordingStartTimestamp / SECONDS_TO_MS)
        : null;
    const newRecordingLengthSeconds = length_s
      ? length_s
      : RecordingInitialState.recordingLengthSeconds;

    const inputSchema: RecordingFromTelemetrySchema = {
      isRecording: newIsRecording,
      isValidSettings: true,
      recordingName: newRecordingName,
      recordingStartedTS: newRecordingStartedTS,
      recordingLengthSeconds: newRecordingLengthSeconds,
    };

    unstable_batchedUpdates(() => {
      dispatch({
        type: 'setPlay',
        value: isPlaying,
      });
      dispatch({
        type: 'setInputModeFromTelemetry',
        schema: inputSchema,
      });
    });
  };

  const [, setActive] = useInterval(
    pollTelemetry,
    TELEMETRY_POLL_INTERVAL,
    false,
  );

  useEffect(() => {
    if (state.app.inputMode === 'playback') return;

    pollTelemetry();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode]);
};

export default usePollTelemetry;
