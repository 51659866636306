/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { CloudDisplayMode } from '../../../../types';
import { SelectCloudsDisplay } from './SelectCloudsDisplay';

export const DisplayOptions = ({
  colorMode,
  onChange,
  modes,
}: {
  onChange: (mode: CloudDisplayMode) => void;
  colorMode: CloudDisplayMode;
  modes?: readonly CloudDisplayMode[] | CloudDisplayMode[];
}): JSX.Element => {
  return (
    <div className="flex items-center">
      <p className="text-sm textGreyD grow"> Display Mode</p>
      <SelectCloudsDisplay
        active={colorMode}
        onChange={(colorMode) => onChange(colorMode)}
        modes={modes}
      />
    </div>
  );
};
