/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useRef } from 'react';
import {
  JSONEditorRef,
  JSONEditorTarget,
  SettingsInterface,
  SettingEditorError,
  ValidationPayload,
  LidarHubNodes,
} from './settingsTypes';
import { useAppDispatch, useAppState } from '../Stores';
import { filterInPlace, jsonValueFromPath, ObjectEntries } from '../util/misc';
import { isEqual, last } from 'lodash';
import { JSONEditorMode } from './Settings';

// The editor doesn't have type definitions
// Here are the docs: https://github.com/josdejong/jsoneditor/blob/master/docs/api.md#configuration-options
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import './JSONEditor.css';

/**
 * Returns true if the specified path points to an array element
 * in the supplied json. False otherwise.
 */
type isArrayEntryReturn =
  | {
      isArrayEntry: false;
      value: undefined;
      path: undefined;
      nestedLevel: undefined;
    }
  | {
      isArrayEntry: boolean;
      value: unknown;
      path: string[];
      nestedLevel: number;
    };

/**
 * Checks if the specified path resolves to an
 * array entry in the specified json. Will also fallback
 * to check parent fields within the path,
 * if one resolves to an array then we'll return true
 * along with nesting level according to the value
 * specfied by the supplied path.
 * @param path the path to resolve.
 * @returns an object with the following entries:
 *      - isArrayEntry: boolean, true if something in the
 *      path resolves to an array, false otherwise.
 *      - value: Starting from highest specifity, the
 *      first array entry that resolves from the specified path.
 *      - path: The path to the array entry.
 *      - nestedLevel: Levels of additional nesting, following
 *      "value" for which the value in the specified path resolves.
 *
 *      For example: Consider the following field
 *      a: [{b: value}].
 *
 *      The path ['a', 0, 'b'] would return 2 level of nesting
 *      since, starting from 'b', the first field that resolves to an
 *      array is 'a'.
 */
const isArrayEntry = (
  json: SettingsInterface,
  path: string[],
): isArrayEntryReturn => {
  if (path.length < 2) {
    return {
      isArrayEntry: false,
      value: undefined,
      path: undefined,
      nestedLevel: undefined,
    };
  }
  for (let i = 1; i < path.length; i++) {
    const parentPath = path.slice(0, -i);
    const value = jsonValueFromPath(json, parentPath);

    if (Array.isArray(value)) {
      return {
        isArrayEntry: Array.isArray(value),
        value,
        path: parentPath,
        nestedLevel: i,
      };
    }
  }
  return {
    isArrayEntry: false,
    value: undefined,
    path: undefined,
    nestedLevel: undefined,
  };
};

function SettingsEditor({ mode }: { mode: JSONEditorMode }): JSX.Element {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const editorRef = useRef<JSONEditorRef>();
  // We need refs for almost everything since the JSONEditor is non-reactive.

  // The server-synced settings, used to determine if current settings are dirty.
  const originalSettings = useRef<SettingsInterface>();
  // The editor mode.
  const modeRef = useRef<JSONEditorMode>(mode);

  /* == Settings validation error maintenance == */
  // Array containing objects with the path to the field with an error +
  // a message explaining the error (as defined by the JSON editor API).
  const errors = useRef<SettingEditorError[]>([]);
  // Maps the field where an error exists to an object containing
  // the path to that field (to support nested keys with the same name) and the
  // reason why the error exists (what the mistmatched type is). This is used
  // to clear old errors when they are resolved / changed to a new error.
  const errorsMap = useRef<Record<string, { path: string[]; type: string }>>(
    {},
  );

  /* == Hacky refs to add ability to edit arrays in "form" mode == */
  // The current settings being edited. We have this in state and as a ref:
  // state since the "push" buttons are in an another component and a ref
  // to avoid stale state in the JSONEditor controlled component.
  const settingsRef = useRef<SettingsInterface>();
  // The path to the appended array entry, if any. We use this to
  // clear the "invalid" appended entry if it was left blank.
  const appendedArrayEntryPath = useRef<string[] | null>(null);
  // The path to the array entry being edited, if any. We use this to
  // clear the entry on blur if it is left as an empty string.
  const emptyArrayEntryPath = useRef<string[] | null>(null);
  const { settings: settingsJSON } =
    state.settings[state.settings.selectedInstance];

  /**
   * Checks if the specified array entry is invalid for
   * the given array field.
   * @param value The array entry to check.
   * @param arrayName The name of field that contains
   * the array which contains the entry.
   * @returns true if the entry is invalid, false otherwise.
   */
  const hasInvalidValue = (value: unknown, arrayName: string) => {
    switch (arrayName) {
      case 'mqtt_publishers': {
        return ObjectEntries(value as Record<string, unknown>).some(
          ([k, v]) =>
            v === '' && !LidarHubNodes[arrayName].optionalFields.has(k),
        );
      }
      case 'tcp_servers': {
        return ObjectEntries(value as Record<string, unknown>).some(
          ([, v]) => v === '',
        );
      }
      default: {
        return value === '';
      }
    }
  };

  /**
   * Returns the default array entry node to
   * push for the specified array field.
   * @param arrayName The array field name.
   * @returns unknown, the node to push.
   */
  const nodeToPush = (arrayName: string) => {
    switch (arrayName) {
      case 'mqtt_publishers':
      case 'tcp_servers': {
        return LidarHubNodes[arrayName].empty;
      }
      default: {
        return '';
      }
    }
  };

  /* Clears array entries marked for deletion by 
     appendedArrayEntryPath or emptyArrayEntryPath */
  const removeMarkedArrayEntries = (
    jsonRef: SettingsInterface,
    type: 'empty' | 'appended' | 'both' = 'both',
  ) => {
    let needsUpdate = false;
    if (jsonRef === undefined) return needsUpdate;

    if (
      emptyArrayEntryPath.current !== null &&
      ['empty', 'both'].includes(type)
    ) {
      // This grabs a reference to the array in the json ref so
      // we want to edit in place.
      const value = jsonValueFromPath(jsonRef, emptyArrayEntryPath.current);
      const arrayName = last(emptyArrayEntryPath.current) ?? '';
      filterInPlace(value, (entry) => !hasInvalidValue(entry, arrayName));
      cleanArrayErrors(arrayName, (value as Array<unknown>).length);
      needsUpdate = true;
      emptyArrayEntryPath.current = null;
    }
    if (
      appendedArrayEntryPath.current !== null &&
      ['appended', 'both'].includes(type)
    ) {
      const value = jsonValueFromPath(jsonRef, appendedArrayEntryPath.current);
      const arrayName = last(appendedArrayEntryPath.current) ?? '';

      if (hasInvalidValue(last(value), arrayName)) {
        value.pop();
      }
      cleanArrayErrors(arrayName, (value as Array<unknown>).length);
      needsUpdate = true;
      appendedArrayEntryPath.current = null;
    }

    if (needsUpdate) {
      editorRef.current?.jsonEditor?.update(jsonRef);
    }
    return needsUpdate;
  };

  /**
   * Cleans tracked errors pertaining to array entries
   * that have been removed.
   * @param arrayName The name of the array within which an
   * entry was removed.
   * @param arrayLength The length of the array after the
   * entry was removed.
   */
  const cleanArrayErrors = (arrayName: string, arrayLength: number) => {
    // The idea is that all entries being tracked with a path
    // contain arrayName must specify an index less than the array's
    // length after entry removal. Otherwise, the error being
    // specified pertains to a removed entry.
    errors.current = errors.current.filter((error) => {
      if (error.path.includes(arrayName)) {
        // The specified index of the error is immediately after
        // the array's name in the error path.
        const arrayFieldIdx = error.path.indexOf(arrayName);
        return (
          error.path[arrayFieldIdx + 1] &&
          Number(error.path[arrayFieldIdx + 1]) < arrayLength
        );
      }

      return true;
    });
    ObjectEntries(errorsMap.current).forEach(([key, error]) => {
      if (error.path.includes(arrayName)) {
        // The specified index of the error is immediately after
        // the array's name in the error path.
        const arrayFieldIdx = error.path.indexOf(arrayName);
        if (
          !(
            error.path[arrayFieldIdx + 1] &&
            Number(error.path[arrayFieldIdx + 1]) < arrayLength
          )
        ) {
          delete errorsMap.current[key];
        }
      }
    });
  };

  /**
   * Validates the currently edited field against the original
   * settings, ensuring that value types match.
   * @param payload the onChange payload. Contains the event that
   * fired the change and the target for this event. The target
   * contains the json field's name, path and new value.
   * @returns An array containing all the errors in the json.
   */
  const validateTypes = async (
    payload: ValidationPayload,
  ): Promise<SettingEditorError[] | null> => {
    /* Checks if the path of the key we just got matches the one in the errorMap */
    const isPreviousError = (field: string, path: string[]): boolean =>
      errorsMap.current[field]?.path.toString() === path.toString();

    /* Clears a previous error from being tracked */
    const clearPreviousError = (field: string): void => {
      errors.current = errors.current.filter(
        (error) =>
          error.path[error.path.length - 1] !== field &&
          // The last entry in the error path will be the index for an array entry
          // but we use <arrayname>_<index> for the field since array entries have
          // undefined fields by default. So we use .endsWith() here instead of
          // equality to handle array entry errors.
          !field.endsWith(error.path[error.path.length - 1].toString()),
      );
      delete errorsMap.current[field];
    };

    // Returns null for no errors, else returns errors.
    const returnErrors = (): null | SettingEditorError[] => {
      const valid = errors.current.length === 0;
      dispatch({
        type: 'setSettingsValid',
        instance: state.settings.selectedInstance,
        value: valid,
      });
      return valid ? null : errors.current;
    };

    const json = originalSettings.current;
    // Only validate on input mutating events,
    // otherwise return current errors
    if (
      json === undefined ||
      payload.event === undefined ||
      !(
        payload.event instanceof InputEvent ||
        payload.event instanceof KeyboardEvent
      )
    ) {
      return returnErrors();
    }

    const newErrors = [] as SettingEditorError[];
    const { value: newValue, path } = payload.target;
    // Field is undefined for array entries, use the array's name +
    // _ + index (last entry in path).
    const field =
      payload.target.field ?? `${path[path.length - 2]}_${last(path)}`;

    // Get original value to compare the type of the new value with.
    let originalValue;
    const isArrayEntryResult = isArrayEntry(json, path);
    if (isArrayEntryResult.isArrayEntry) {
      // For arrays, enforce that all entries must match
      // the type of the first entry.
      const firstIndexPath = [...path];
      firstIndexPath[path.length - isArrayEntryResult.nestedLevel] = '0';
      originalValue = jsonValueFromPath(json, firstIndexPath);
    } else {
      originalValue = jsonValueFromPath(json, path);
    }

    // If the type is undefined, we log an info message. This will likely
    // happen if an "optional" field is entered which was not previously present.
    if (typeof originalValue === 'undefined') {
      console.info(
        `Settings Validation: Could not find type of original value for field \'${field}\' ignoring potential error.`,
      );
      return returnErrors();
    }

    if (typeof newValue === typeof originalValue) {
      // The types match, clear if this was previously an error.
      if (isPreviousError(field, path)) {
        clearPreviousError(field);
      }
    } else {
      // The types don't match, this field is an error.
      // Disregard if we already have the error.
      if (isPreviousError(field, path)) {
        if (errorsMap.current[field]?.type !== typeof newValue) {
          // Was an error, but different type. Add the new error (type) but remove the old one.
          clearPreviousError(field);
        } else {
          return returnErrors();
        }
      } // We didn't have this error, push it into error refs.

      newErrors.push({
        path,
        message: `Invalid type, got ${typeof newValue} but expected ${typeof originalValue}`,
      });
      errorsMap.current[field] = { path, type: typeof newValue };
      errors.current.push(...newErrors);
    }

    return returnErrors();
  };

  /**
   * JSONEditor event handler. Triggers validation and array
   * manipulation.
   */
  const handleEvent = (target: JSONEditorTarget, event: Event) => {
    let needsUpdate = false;
    if (
      !settingsRef.current ||
      !editorRef.current?.jsonEditor ||
      modeRef.current === 'tree'
    )
      return;
    const newJson = settingsRef.current;
    const { path } = target;

    // If the current field being edited is an array entry and it becomes
    // empty, flag it for deletion if the entry loses focus (and its still empty).
    const entry = isArrayEntry(newJson, path);
    if (event instanceof KeyboardEvent && entry.isArrayEntry) {
      emptyArrayEntryPath.current =
        target.value === '' ? target.path.slice(0, -entry.nestedLevel) : null;
    }

    if (event instanceof FocusEvent && event.type === 'blur') {
      // If we were editing an array entry, and it was left as
      // an empty string then delete it.
      // We can't remove the appended entry here since this
      // will also fire when a user clicks its field to edit it.
      needsUpdate = removeMarkedArrayEntries(newJson, 'empty');
    }
    if (event instanceof PointerEvent) {
      // Clear the last appended array entry if it's still empty
      // on focus of any new field
      const isEditingAppendedArrayField =
        (event.target as HTMLElement)?.isContentEditable &&
        (isEqual(path.slice(0, -1), appendedArrayEntryPath.current) ||
          isEqual(path.slice(0, -2), appendedArrayEntryPath.current));

      if (!isEditingAppendedArrayField) {
        removeMarkedArrayEntries(newJson);
      }

      /* Support addition of array entries */
      // Check if the current focus target is an array or an array
      // entry. If so, append an entry to that array to be filled in.
      let value = jsonValueFromPath(newJson, path);
      let pathBeingEdited = path;
      const isArrayEntryResult = isArrayEntry(newJson, path);

      // If the target isn't an array, check if the parent is (i.e: this
      // is an array entry).
      if (!Array.isArray(value) && isArrayEntryResult.isArrayEntry) {
        value = isArrayEntryResult.value;
        pathBeingEdited = isArrayEntryResult.path;
      }

      if (Array.isArray(value)) {
        const arrayName = last(isArrayEntryResult.path) ?? last(path) ?? '';

        if (hasInvalidValue(last(value), arrayName)) {
          return;
        }
        const appendNode = nodeToPush(arrayName);
        value.push(appendNode);
        appendedArrayEntryPath.current = pathBeingEdited;
        editorRef.current?.jsonEditor.update(newJson);
        // We could .expandAll here to expand the appended object
        // entries but that will cause the current field to lose focus.
      }
    }

    editorRef.current?.props.onValidate({ event, target });
    if (needsUpdate) {
      dispatch({
        type: 'setSettings',
        instance: state.settings.selectedInstance,
        value: newJson,
      });
    }
  };

  const editorProps = {
    ref: editorRef,
    value: settingsJSON,
    indentation: 4,
    history: true,
    onChange: (newJson: SettingsInterface) => {
      settingsRef.current = newJson;
      dispatch({
        type: 'setSettings',
        instance: state.settings.selectedInstance,
        value: newJson,
      });
    },
    onModeChange: (mode: JSONEditorMode) => {
      modeRef.current = mode;
    },
    navigationBar: false,
    statusBar: false,
    limitDragging: true,
  };

  useEffect(() => {
    // Expand the json on component mount
    editorRef.current?.jsonEditor?.expandAll();

    // Remove marked array entries when the mouse
    // leaves the editor so they're never pushed.
    const cleanSettings = () => {
      const json = settingsRef.current;

      if (json !== undefined) {
        const needsUpdate = removeMarkedArrayEntries(json);
        if (needsUpdate) {
          dispatch({
            type: 'setSettings',
            instance: state.settings.selectedInstance,
            value: json,
          });
        }
      }
    };
    editorRef.current?.jsonEditor?.frame.addEventListener(
      'mouseleave',
      cleanSettings,
    );
    return () => {
      editorRef.current?.jsonEditor?.frame.removeEventListener(
        'mouseleave',
        cleanSettings,
      );
    };
  }, [editorRef.current]);

  // Update the editor with fetched settings
  useEffect(() => {
    // Only set the editor contents if they are empty (not loaded)
    if (
      JSON.stringify(editorRef.current?.jsonEditor?.get() ?? {}) ===
      JSON.stringify(settingsJSON)
    )
      return;

    // JsonEditor is a controlled component so contents
    // need to be set explicitly to be reactive.
    editorRef.current?.jsonEditor?.update(settingsJSON);
    editorRef.current?.jsonEditor?.expandAll();
    originalSettings.current = settingsJSON;
    settingsRef.current = settingsJSON;
  }, [settingsJSON]);

  useEffect(() => {
    editorRef.current?.jsonEditor?.setName(state.settings.selectedInstance);
  }, [state.settings.selectedInstance]);

  // Set the editor mode (tree ~ unconstrained and form ~ constrained, limited to values).
  useEffect(() => {
    editorRef.current?.jsonEditor?.setMode(mode);
    editorRef.current?.jsonEditor?.expandAll();
  }, [mode]);

  return (
    <div className="perception-settings-editor">
      <Editor
        {...editorProps}
        ref={editorRef}
        modes={['form', 'tree']}
        onValidate={validateTypes}
        onEvent={handleEvent}
      />
    </div>
  );
}

export default SettingsEditor;
