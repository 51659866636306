/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ComponentPropsWithoutRef } from 'react';

export const ListContainer = ({
  children,
  name,
}: { name?: string } & ComponentPropsWithoutRef<'div'>): JSX.Element => {
  return (
    <div
      className="ListContainer flex flex-col overflow-y bgGreyI px-3 py-1 my-4"
      style={{
        minHeight: 'var(--r32)',
        maxHeight: 'var(--r64)',
        overflowX: 'hidden',
      }}
    >
      {name && (
        <div
          className="textGreyE mt-2 mb-1 text-sm "
          style={{
            textTransform: 'capitalize',
            alignSelf: 'end',
          }}
        >
          {name}
        </div>
      )}
      {children}
    </div>
  );
};
