/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef } from 'react';
import useInterval from '../app/hooks/useInterval';
import { SECONDS_TO_MS } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';
import { PointDescriptorInUse } from '../types';

const CLOUD_REACHABILITY_TIMEOUT = 70 * SECONDS_TO_MS;

const useSensorCloudReachability = (): ((
  id: string,
  descriptor: PointDescriptorInUse,
) => void) => {
  const dispatch = useAppDispatch();
  const state = useAppState();

  const idsReceivedInInterval = useRef(
    {} as Record<PointDescriptorInUse, string[]>,
  );

  const updateReachabilities = () => {
    // Don't set clouds unreachable if we're paused
    if (state.app.isPlaying) {
      dispatch({
        type: 'setProcessedCloudUnReachability',
        reachedCloudIds: idsReceivedInInterval.current,
      });
    }
    idsReceivedInInterval.current = {} as Record<
      PointDescriptorInUse,
      string[]
    >;
  };

  const [, setActive] = useInterval(
    updateReachabilities,
    CLOUD_REACHABILITY_TIMEOUT,
    false,
  );

  useEffect(() => {
    if (state.app.inputMode === 'playback') return;

    updateReachabilities();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode]);

  // The hook returns a callback to signal clouds
  return (id: string, descriptor: PointDescriptorInUse) => {
    const isNew = !(
      idsReceivedInInterval.current[descriptor] &&
      idsReceivedInInterval.current[descriptor].includes(id)
    );
    if (isNew) {
      if (idsReceivedInInterval.current[descriptor] === undefined) {
        idsReceivedInInterval.current[descriptor] = [];
      }
      idsReceivedInInterval.current[descriptor].push(id);
    }
  };
};

export default useSensorCloudReachability;
