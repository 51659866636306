/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useAppState } from '../../../Stores';

export const InputModeIndicator = (): JSX.Element | null => {
  const state = useAppState();
  const { inputMode } = state.app;
  if (inputMode === null) return null;

  return (
    <div
      className="no-select text-xxs textGreyC p-2"
      style={{
        position: 'absolute',
        left: '50%',
        top: 0,
        transform: 'translate(-50%,0)',
        alignItems: 'center',
        letterSpacing: '0.3rem',
        textTransform: 'uppercase',
      }}
    >
      {`${inputMode}`}
    </div>
  );
};
