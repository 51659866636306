/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppState } from '../Stores';
import { Context } from '../types';
import { TrackedObject3JS } from './TrackedObject3JS';
import { ObjectKeys, toNDC } from '../util/misc';
import { Vector2 } from 'three';

const startPoint = new Vector2();
const pTopLeft = new Vector2();
const pBottomRight = new Vector2();
let isDown = false;

const constrainPoint = (
  clientX: number,
  clientY: number,
  canvas: HTMLCanvasElement,
) => {
  const { left, top, right, bottom } = canvas.getBoundingClientRect();
  const x = Math.max(Math.min(clientX, right), left);
  const y = Math.min(Math.max(clientY, top), bottom);
  return { x, y };
};

// These hooks allow the user to select perception objects
export const useTrackedObjectsSelect = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const handlerDown = (e: PointerEvent) => {
    isDown = true;

    context.selectDiv.style.display = 'block';

    context.selectDiv.style.left = e.clientX + 'px';
    context.selectDiv.style.top = e.clientY + 'px';
    context.selectDiv.style.width = '0px';
    context.selectDiv.style.height = '0px';

    startPoint.x = e.clientX;
    startPoint.y = e.clientY;

    const { x, y } = toNDC(e.clientX, e.clientY, context.canvas);
    context.selectionBox.startPoint.set(x, y, 0.5);
  };

  const handlerMove = useCallback(
    (e: PointerEvent) => {
      if (!isDown) return;

      const { x, y } = constrainPoint(e.clientX, e.clientY, context.canvas);
      pTopLeft.x = Math.min(startPoint.x, x);
      pTopLeft.y = Math.min(startPoint.y, y);
      pBottomRight.x = Math.max(startPoint.x, x);
      pBottomRight.y = Math.max(startPoint.y, y);

      context.selectDiv.style.left = pTopLeft.x + 'px';
      context.selectDiv.style.top = pTopLeft.y + 'px';
      context.selectDiv.style.width = pBottomRight.x - pTopLeft.x + 'px';
      context.selectDiv.style.height = pBottomRight.y - pTopLeft.y + 'px';

      const ndc = toNDC(x, y, context.canvas);
      context.selectionBox.endPoint.set(ndc.x, ndc.y, 0.5);

      // highlight intersected objects
      const objs = state.depTrackedUserVisible.map(
        (id) => context.instances.TrackedObject.all[id],
      );
      const highlighted = context.selectionBox
        .select(objs)
        .map((obj) => (obj as TrackedObject3JS).oId);

      dispatch({
        type: 'setTrackedInstanceBoolProp',
        property: 'highlighted',
        ids: highlighted,
        value: 'show',
      });

      // un-highlight rest
      const highSet = new Set(highlighted);
      const unHighlighted = ObjectKeys(
        context.instances.TrackedObject.all,
      ).filter((id) => !highSet.has(id));
      dispatch({
        type: 'setTrackedInstanceBoolProp',
        property: 'highlighted',
        ids: unHighlighted,
        value: 'hide',
      });
    },
    [state.depTrackedUserVisible],
  );

  const handlerUp = (e: PointerEvent) => {
    isDown = false;

    context.selectDiv.style.display = 'none';

    const { x, y } = constrainPoint(e.clientX, e.clientY, context.canvas);
    const ndc = toNDC(x, y, context.canvas);
    context.selectionBox.endPoint.set(ndc.x, ndc.y, 0.5);

    const vis = ObjectKeys(context.instances.TrackedObject.all);

    dispatch({
      type: 'setTrackedInstanceBoolProp',
      property: 'highlighted',
      ids: vis,
      value: 'hide',
    });

    const collection = context.selectionBox.collection;
    const allSelected = collection.map((o) => (o as TrackedObject3JS).oId);

    dispatch({
      type: 'setTrackedInstanceBoolProp',
      property: 'selected',
      ids: vis,
      value: 'hide',
    });
    if (allSelected.length)
      dispatch({
        type: 'setTrackedInstanceBoolProp',
        property: 'selected',
        ids: allSelected,
        value: 'show',
      });

    if (state.app.tool === 'Select')
      dispatch({
        type: 'setTool',
        value: 'Camera',
      });
  };

  // Selection Box
  useEffect(() => {
    if (
      !(
        (state.app.inputMode === 'playback' ||
          state.app.mode === 'viewer' ||
          state.app.mode === 'preferences' ||
          state.app.mode === 'recording') &&
        state.app.tool === 'Select'
      )
    )
      return;

    dispatch({ type: 'setViewNavigation', value: false });

    document.addEventListener('pointerdown', handlerDown);
    document.addEventListener('pointermove', handlerMove);
    document.addEventListener('pointerup', handlerUp);
    return () => {
      isDown = false;

      // If someone switches tools/modes while using the selection tool,
      // we should hide the selection div and unhighlight all objects.
      context.selectDiv.style.display = 'none';
      const vis = ObjectKeys(context.instances.TrackedObject.all);
      dispatch({
        type: 'setTrackedInstanceBoolProp',
        property: 'highlighted',
        ids: vis,
        value: 'hide',
      });

      dispatch({ type: 'setViewNavigation', value: true });

      document.removeEventListener('pointerdown', handlerDown);
      document.removeEventListener('pointermove', handlerMove);
      document.removeEventListener('pointerup', handlerUp);
    };
  }, [state.app.tool, state.app.mode]);
};
