/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from '../Button';
import Tooltip, { TooltipInterface } from '../tooltip/Tooltip';
import { UpDownIcon } from '../UpDownIcon';

const btnStyle = { padding: '0.1rem 0.2rem' };

const BaseUpDownButtons = ({
  isUpEnabled = true,
  isDownEnabled = true,
  onClick,
}: {
  isUpEnabled?: boolean;
  isDownEnabled?: boolean;
  onClick: (key: 'up' | 'down') => void;
}): JSX.Element => {
  return (
    <div className="UpDownButtons flex flex-col">
      <Button
        className="borderNone bgNone"
        style={btnStyle}
        disabled={!isUpEnabled}
        onClick={() => onClick('up')}
      >
        <UpDownIcon variant="up" active={isUpEnabled} />
      </Button>
      <Button
        className="borderNone bgNone"
        style={btnStyle}
        disabled={!isDownEnabled}
        onClick={() => onClick('down')}
      >
        <UpDownIcon variant="down" active={isDownEnabled} />
      </Button>
    </div>
  );
};

export const UpDownButtons = ({
  isUpEnabled = true,
  isDownEnabled = true,
  onClick,
  tooltip,
}: {
  isUpEnabled?: boolean;
  isDownEnabled?: boolean;
  onClick: (key: 'up' | 'down') => void;
} & { tooltip?: TooltipInterface }): JSX.Element => {
  return tooltip !== undefined ? (
    <Tooltip tooltipText={tooltip.text} offset={tooltip.offset}>
      <BaseUpDownButtons
        isUpEnabled={isUpEnabled}
        isDownEnabled={isDownEnabled}
        onClick={onClick}
      />
    </Tooltip>
  ) : (
    <BaseUpDownButtons
      isUpEnabled={isUpEnabled}
      isDownEnabled={isDownEnabled}
      onClick={onClick}
    />
  );
};
