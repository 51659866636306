/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { loadFromLocalStorage, saveToLocalStorage } from '../util/localStorage';
import {
  FeedbackMessage,
  GlobalVisibility,
  InputMode,
  Mode,
  Palette,
  Theme,
  Tool,
  ZoneType,
} from '../types';
import { Vector3 } from 'three';

export const LOCALSTORAGE_KEY = 'App';
export type ColorFor = 'viewport' | 'cartesianGrid' | 'polarGrid';

export type AppState = {
  inputMode: InputMode | null;
  mode: Mode;
  isPlaying: boolean;
  // overrides to hide/show by type
  visibilities: Record<GlobalVisibility, boolean>;
  gridRange: {
    polar: number;
    cartesian: number;
  };
  gridSpacing: {
    polar: number | 'DEFAULT';
    cartesian: number;
  };
  tool: Tool;
  zoneCreationType: ZoneType;
  feedbackMessage: {
    main: FeedbackMessage | null;
    fromQueue: FeedbackMessage | null;
    queue: (FeedbackMessage | null)[];
  };
  // view navigation flag
  viewNavigation: boolean;
  isFrameNumberVisible: boolean;
  isPitchAndRollVisible: boolean;
  theme: Theme;
  colors: Record<Theme, Record<ColorFor, string | 'default'>>;
  isPaneExpanded: {
    left: boolean;
    right: boolean;
  };
  palette: Palette;
  sceneName: string;
  isFullScreen: boolean;
  autoRotate: boolean;
  autoRotateSpeed: number;
  escapeFlag: boolean;
  developerMode: boolean;
  underlayMap: {
    image: string;
    scale: number;
    rotation: number;
    position: Vector3;
    opacity: number;
  };
};

export type AppStored = Pick<
  AppState,
  | 'visibilities'
  | 'tool'
  | 'zoneCreationType'
  | 'isPitchAndRollVisible'
  | 'theme'
  | 'colors'
  | 'palette'
  | 'sceneName'
  | 'isFrameNumberVisible'
  | 'developerMode'
  | 'gridRange'
  | 'gridSpacing'
  | 'underlayMap'
>;

const loaded = (loadFromLocalStorage(LOCALSTORAGE_KEY) ?? {}) as AppStored;

export const AppDefaultState: AppState = {
  inputMode: null,
  mode: 'init',
  isPlaying: true,
  visibilities: {
    Grid: true,
    RangeRings: false,
    RangeRingLabels: true,
    Axes: true,
    Clouds: true,
    Image: true,
    EventLocation: true,
    UnderlayMap: true,
    TrackedObjects: true,
    Zones: true,
  },
  gridRange: {
    polar: 100,
    cartesian: 100,
  },
  gridSpacing: {
    polar: 'DEFAULT',
    cartesian: 10,
  },
  tool: 'Camera',
  zoneCreationType: 'Event',
  feedbackMessage: {
    main: null,
    fromQueue: null,
    queue: [] as (FeedbackMessage | null)[],
  },
  viewNavigation: true,
  isPaneExpanded: {
    left: true,
    right: true,
  },
  isPitchAndRollVisible: false,
  isFrameNumberVisible: false,
  theme: 'Dark',
  colors: {
    Dark: {
      viewport: '#222226',
      cartesianGrid: '#383838',
      polarGrid: '#383838',
    },
    Light: {
      viewport: '#d9d9d9',
      cartesianGrid: '#aaaaaa',
      polarGrid: '#aaaaaa',
    },
  },
  palette: 'Data',
  sceneName: 'Scene',
  isFullScreen: false,
  autoRotate: false,
  autoRotateSpeed: 2,
  escapeFlag: false,
  developerMode: false,
  underlayMap: {
    image: '',
    opacity: 1,
    position: new Vector3(0, 0, 0),
    rotation: 0,
    scale: 10,
  },
};

export const AppInitialState: AppState = {
  ...AppDefaultState,
  visibilities: {
    ...AppDefaultState.visibilities,
    ...loaded?.visibilities,
  },
  gridRange: {
    ...AppDefaultState.gridRange,
    ...loaded?.gridRange,
  },
  gridSpacing: {
    ...AppDefaultState.gridSpacing,
    ...loaded?.gridSpacing,
  },
  tool: loaded?.tool ?? AppDefaultState.tool,
  zoneCreationType:
    loaded?.zoneCreationType ?? AppDefaultState.zoneCreationType,
  isPitchAndRollVisible:
    loaded?.isPitchAndRollVisible ?? AppDefaultState.isPitchAndRollVisible,
  isFrameNumberVisible:
    loaded?.isFrameNumberVisible ?? AppDefaultState.isFrameNumberVisible,
  theme: loaded?.theme ?? AppDefaultState.theme,
  colors: {
    Dark: {
      ...AppDefaultState.colors.Dark,
      ...loaded?.colors?.Dark,
    },
    Light: {
      ...AppDefaultState.colors.Light,
      ...loaded?.colors?.Light,
    },
  },
  palette: loaded?.palette ?? AppDefaultState.palette,
  sceneName: loaded?.sceneName ?? AppDefaultState.sceneName,
  developerMode: loaded?.developerMode ?? AppDefaultState.developerMode,
  underlayMap: {
    image: loaded?.underlayMap?.image ?? AppDefaultState.underlayMap.image,
    opacity:
      loaded?.underlayMap?.opacity ?? AppDefaultState.underlayMap.opacity,
    position: new Vector3(
      loaded?.underlayMap?.position.x ?? AppDefaultState.underlayMap.position.x,
      loaded?.underlayMap?.position.y ?? AppDefaultState.underlayMap.position.y,
      0,
    ),
    rotation:
      loaded?.underlayMap?.rotation ?? AppDefaultState.underlayMap.rotation,
    scale: loaded?.underlayMap?.scale ?? AppDefaultState.underlayMap.scale,
  },
};

export type AppActions =
  | { type: 'setMode'; value: Mode }
  | { type: 'setInputMode'; value: InputMode }
  | {
      type: 'setPlay';
      value: boolean;
    }
  | {
      type: 'setGlobalVisibility';
      instance: GlobalVisibility;
      value: boolean;
    }
  | {
      type: 'setTool';
      value: Tool;
    }
  | {
      type: 'setZoneCreationType';
      value: ZoneType;
    }
  | {
      type: 'setViewNavigation';
      value: boolean;
    }
  | {
      type: 'setFeedbackMessage';
      value: FeedbackMessage | null;
    }
  | {
      type: 'enqueueFeedbackMessage';
      value: FeedbackMessage | null;
    }
  | {
      type: 'popFeedbackMessage';
    }
  | {
      type: 'setPitchAndRollVisibility';
      value: boolean;
    }
  | {
      type: 'setTheme';
      value: AppState['theme'];
    }
  | {
      type: 'setAppColor';
      for: ColorFor;
      value: string | 'default';
    }
  | {
      type: 'setPaneExpanded';
      side: 'left' | 'right';
      value: boolean;
    }
  | {
      type: 'setGridRange';
      instance: 'polar' | 'cartesian';
      value: number | 'default';
    }
  | {
      type: 'setGridSpacing';
      instance: 'polar' | 'cartesian';
      value: number | 'DEFAULT';
    }
  | {
      type: 'setPalette';
      value: Palette;
    }
  | {
      type: 'setSceneName';
      value: string;
    }
  | {
      type: 'setSceneFullScreen';
      value: boolean;
    }
  | {
      type: 'setSceneAutoRotate';
      value: boolean;
    }
  | {
      type: 'setSceneAutoRotateSpeed';
      value: number | 'default';
    }
  | {
      type: 'setFrameNumberVisibility';
      value: boolean;
    }
  | {
      type: 'setEscapeFlag';
      value: boolean;
    }
  | {
      type: 'setPalette';
      value: Palette | 'default';
    }
  | {
      type: 'setSceneName';
      value: string;
    }
  | {
      type: 'setDeveloperMode';
      value: boolean;
    }
  | {
      // TODO(matt/emmanuel): kludge until we have backend API
      type: 'setMapImage';
      value: string;
    }
  | {
      type: 'seMapOpacity';
      value: number;
    }
  | {
      type: 'seMapScale';
      value: number;
    }
  | {
      type: 'seMapRotation';
      value: number;
    }
  | {
      type: 'seMapPosition';
      value: Vector3;
    };

// eslint-disable-next-line sonarjs/cognitive-complexity
const reducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case 'setMode': {
      if (state.mode === action.value) return state;
      return { ...state, mode: action.value };
    }
    case 'setInputMode': {
      if (state.inputMode === action.value) return state;
      return { ...state, inputMode: action.value };
    }
    case 'setPlay': {
      if (state.isPlaying === action.value) return state;
      return { ...state, isPlaying: action.value };
    }
    case 'setGlobalVisibility': {
      if (state.visibilities[action.instance] === action.value) return state;
      const visibilities = {
        ...state.visibilities,
        [action.instance]: action.value,
      };
      return { ...state, visibilities };
    }
    case 'setTool': {
      if (state.tool === action.value) return state;
      return { ...state, tool: action.value };
    }
    case 'setZoneCreationType': {
      if (state.zoneCreationType === action.value) return state;
      return { ...state, zoneCreationType: action.value };
    }
    case 'setViewNavigation': {
      if (state.viewNavigation === action.value) return state;
      return { ...state, viewNavigation: action.value };
    }
    case 'setFeedbackMessage': {
      return {
        ...state,
        feedbackMessage: {
          ...state.feedbackMessage,
          main: action.value,
        },
      };
    }
    case 'enqueueFeedbackMessage': {
      const currentQueue = [action.value, ...state.feedbackMessage.queue];
      currentQueue.sort((a, b) => (a?.priority ?? 0) - (b?.priority ?? 0));

      return {
        ...state,
        feedbackMessage: {
          ...state.feedbackMessage,
          queue: currentQueue,
        },
      };
    }
    case 'popFeedbackMessage': {
      const currentQueue = [...state.feedbackMessage.queue];
      let feedbackMessage = currentQueue.pop();
      if (feedbackMessage === undefined) {
        if (state.feedbackMessage.fromQueue === null) return state;
        feedbackMessage = null;
      }

      return {
        ...state,
        feedbackMessage: {
          ...state.feedbackMessage,
          fromQueue: feedbackMessage,
          queue: currentQueue,
        },
      };
    }
    case 'setPitchAndRollVisibility': {
      if (state.isPitchAndRollVisible === action.value) return state;
      return { ...state, isPitchAndRollVisible: action.value };
    }
    case 'setTheme': {
      if (state.theme === action.value) return state;
      return { ...state, theme: action.value };
    }
    case 'setAppColor': {
      const theme = state.theme;
      const value =
        action.value === 'default'
          ? AppDefaultState.colors[theme][action.for]
          : action.value;
      if (state.colors[theme][action.for] === value) return state;
      const colors = {
        ...state.colors,
        [theme]: { ...state.colors[theme], [action.for]: value },
      };
      return { ...state, colors };
    }
    case 'setPaneExpanded': {
      if (state.isPaneExpanded[action.side] === action.value) return state;
      return {
        ...state,
        isPaneExpanded: {
          ...state.isPaneExpanded,
          [action.side]: action.value,
        },
      };
    }
    case 'setGridRange': {
      const value =
        action.value === 'default'
          ? AppDefaultState.gridRange.polar
          : action.value;
      if (state.gridRange[action.instance] === value) return state;
      return {
        ...state,
        gridRange: {
          ...state.gridRange,
          [action.instance]: value,
        },
      };
    }
    case 'setGridSpacing': {
      const value =
        action.value === 'DEFAULT'
          ? AppDefaultState.gridSpacing[action.instance]
          : action.value;
      if (state.gridSpacing[action.instance] === value) return state;
      return {
        ...state,
        gridSpacing: {
          ...state.gridSpacing,
          [action.instance]: value,
        },
      };
    }
    case 'setPalette': {
      const value =
        action.value === 'default' ? AppDefaultState.palette : action.value;

      if (state.palette === value) return state;
      return {
        ...state,
        palette: value,
      };
    }
    case 'setSceneName': {
      if (state.sceneName === action.value) return state;
      return { ...state, sceneName: action.value };
    }
    case 'setSceneFullScreen': {
      if (state.isFullScreen === action.value) return state;
      return { ...state, isFullScreen: action.value };
    }
    case 'setSceneAutoRotate': {
      if (state.autoRotate === action.value) return state;
      return { ...state, autoRotate: action.value };
    }
    case 'setSceneAutoRotateSpeed': {
      const value =
        action.value === 'default'
          ? AppDefaultState.autoRotateSpeed
          : action.value;
      if (state.autoRotateSpeed === value) return state;
      return { ...state, autoRotateSpeed: value };
    }
    case 'setFrameNumberVisibility': {
      if (state.isFrameNumberVisible === action.value) return state;
      return { ...state, isFrameNumberVisible: action.value };
    }
    case 'setEscapeFlag': {
      if (state.escapeFlag === action.value) return state;
      return { ...state, escapeFlag: action.value };
    }
    case 'setDeveloperMode': {
      if (state.developerMode === action.value) return state;
      return { ...state, developerMode: action.value };
    }
    case 'setMapImage': {
      const underlayMap = state.underlayMap;
      if (underlayMap.image === action.value) return state;
      return {
        ...state,
        underlayMap: { ...underlayMap, image: action.value },
      };
    }
    case 'seMapOpacity': {
      const underlayMap = state.underlayMap;
      if (underlayMap.opacity === action.value) return state;
      return {
        ...state,
        underlayMap: { ...underlayMap, opacity: action.value },
      };
    }
    case 'seMapRotation': {
      const underlayMap = state.underlayMap;
      if (underlayMap.rotation === action.value) return state;
      return {
        ...state,
        underlayMap: { ...underlayMap, rotation: action.value },
      };
    }
    case 'seMapScale': {
      const underlayMap = state.underlayMap;
      if (underlayMap.scale === action.value) return state;
      return {
        ...state,
        underlayMap: { ...underlayMap, scale: action.value },
      };
    }
    case 'seMapPosition': {
      const underlayMap = state.underlayMap;
      if (underlayMap.position.equals(action.value)) return state;
      return {
        ...state,
        underlayMap: { ...underlayMap, position: action.value.clone() },
      };
    }
    default:
      return state;
  }
};

const triggerSaveSet: Set<AppActions['type']> = new Set([
  'setGlobalVisibility',
  'setTool',
  'setPitchAndRollVisibility',
  'setFrameNumberVisibility',
  'setTheme',
  'setAppColor',
  'setPalette',
  'setDeveloperMode',
  'setGridRange',
  'setGridSpacing',
  'setMapImage',
  'seMapOpacity',
  'seMapScale',
  'seMapRotation',
  'seMapPosition',
]);

export const AppReducer = (state: AppState, action: AppActions): AppState => {
  const newState = reducer(state, action);

  const stored: AppStored = {
    isPitchAndRollVisible: newState.isPitchAndRollVisible,
    isFrameNumberVisible: newState.isFrameNumberVisible,
    tool: newState.tool,
    visibilities: newState.visibilities,
    zoneCreationType: newState.zoneCreationType,
    theme: newState.theme,
    colors: newState.colors,
    palette: newState.palette,
    sceneName: newState.sceneName,
    gridRange: {
      polar: newState.gridRange.polar,
      cartesian: newState.gridRange.cartesian,
    },
    gridSpacing: {
      polar: newState.gridSpacing.polar,
      cartesian: newState.gridSpacing.cartesian,
    },
    developerMode: newState.developerMode,
    underlayMap: newState.underlayMap,
  };
  if (triggerSaveSet.has(action.type))
    saveToLocalStorage(LOCALSTORAGE_KEY, stored);

  return newState;
};
