/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppDispatch, useAppState } from '../../Stores';

const useEscapeSynch = (): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!state.app.escapeFlag) return;
    dispatch({ type: 'setEscapeFlag', value: false });

    // Clear all selections when "esc" is pressed
    switch (state.app.mode) {
      case 'viewer':
      case 'recording':
      case 'preferences': {
        const selectedIds = Object.keys(state.tracked.selected).filter(
          (id) => state.tracked.selected[id],
        );
        dispatch({
          type: 'setTrackedInstanceBoolProp',
          property: 'selected',
          value: 'hide',
          ids: selectedIds,
        });
        break;
      }
      case 'diagnostics': {
        dispatch({
          type: 'selectAllAlertsByType',
          sourceType: 'all',
          value: false,
        });
        break;
      }
      case 'setup': {
        dispatch({
          type: 'setSelectedSource',
          id: null,
        });
        dispatch({
          type: 'setReferenceSource',
          id: null,
        });
        break;
      }
      case 'zone': {
        dispatch({
          type: 'setSelectedZone',
          id: null,
        });
        break;
      }
      default:
        break;
    }
  }, [state.app.escapeFlag, state.app.mode]);
};

export default useEscapeSynch;
