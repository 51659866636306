/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import {
  Group,
  LineSegments,
  BoxGeometry,
  EdgesGeometry,
  LineBasicMaterial,
  LineBasicMaterialParameters,
  MeshBasicMaterial,
  Mesh,
  Quaternion,
  Vector3,
} from 'three';
import {
  Highlightable,
  OType,
  PerceptionClassification,
  Selectable,
  Raycastable,
  Activatable,
} from '../types';
import {
  COLOR_HIGHLIGHTED,
  COLOR_SELECTED,
  snapTrackedsPosZ,
} from '../constants';

const unitCube = new BoxGeometry(1, 1, 1);
const edge = new EdgesGeometry(unitCube);

const materialParams: LineBasicMaterialParameters = { linewidth: 1 };
const wireMaterials: Record<PerceptionClassification, LineBasicMaterial> = {
  Person: new LineBasicMaterial(materialParams),
  Vehicle: new LineBasicMaterial(materialParams),
  LargeVehicle: new LineBasicMaterial(materialParams),
  Prospect: new LineBasicMaterial(materialParams),
  Bicycle: new LineBasicMaterial(materialParams),
  Unknown: new LineBasicMaterial(materialParams),
  Cluster: new LineBasicMaterial(materialParams),
};
const wireMaterialSelected = new LineBasicMaterial({
  ...materialParams,
  linewidth: 2,
  color: COLOR_SELECTED,
});
const wireMaterialHighlighted = new LineBasicMaterial({
  ...materialParams,
  linewidth: 3,
  color: COLOR_HIGHLIGHTED,
});

const meshParam: LineBasicMaterialParameters = {
  transparent: true,
  // Fixes a problem where the material is no longer transparent
  // from certain angles: https://discourse.threejs.org/t/transparency-changes-at-certain-camera-angles/34445
  depthWrite: false,
  opacity: 0.2,
};
const meshMaterials: Record<PerceptionClassification, MeshBasicMaterial> = {
  Person: new MeshBasicMaterial(meshParam),
  Vehicle: new MeshBasicMaterial(meshParam),
  LargeVehicle: new MeshBasicMaterial(meshParam),
  Prospect: new MeshBasicMaterial(meshParam),
  Unknown: new MeshBasicMaterial(meshParam),
  Bicycle: new MeshBasicMaterial(meshParam),
  Cluster: new MeshBasicMaterial(meshParam),
};
const meshMaterialSelected = new MeshBasicMaterial({
  ...meshParam,
  color: COLOR_SELECTED,
});
const meshMaterialHighlighted = new MeshBasicMaterial({
  ...meshParam,
  color: COLOR_HIGHLIGHTED,
});
const raycastMeshMaterial = new MeshBasicMaterial();

const pool: TrackedObject3JS[] = [];
export const getObjectFromPool = (
  classification: PerceptionClassification,
  id: string,
  position: Vector3,
  dimensions: Vector3,
  rotation: Quaternion,
): TrackedObject3JS => {
  let obj: TrackedObject3JS | undefined;
  if (pool.length > 0) {
    obj = pool.pop();
  }
  if (!obj) obj = new TrackedObject3JS();
  obj.set(classification, id, position, dimensions, rotation);
  obj.isActive = false;
  return obj;
};
export const putObjectToPool = (obj: TrackedObject3JS): void => {
  pool.push(obj);
  obj.isActive = false;
};

export const setColor = (
  classification: PerceptionClassification,
  style: string,
): void => {
  wireMaterials[classification].color.setStyle(style);
  meshMaterials[classification].color.setStyle(style);
};

const visibleBBoxes = new Set<PerceptionClassification>([
  'Cluster',
  'Prospect',
]);

let areAllBBoxesVis = false;
export function setAllBBoxesVis(value: boolean): void {
  areAllBBoxesVis = value;
}

export class TrackedObject3JS
  extends Group
  implements OType, Highlightable, Selectable, Raycastable, Activatable
{
  private wireframe: LineSegments;
  public raycastBox: Mesh;
  public mesh: Mesh;
  private _isActive = false;
  private _isHighlighted = false;
  private _isSelected = false;
  private classification: PerceptionClassification = 'Unknown';

  public readonly oType: string;
  public readonly isTrackedObject3JS = true;
  public oId: string;

  constructor() {
    super();
    this.oType = 'TrackedObject3JS';
    this.oId = 'none';
    //
    this.raycastBox = new Mesh(unitCube, raycastMeshMaterial);
    this.raycastBox.visible = false;
    this.add(this.raycastBox);
    this.raycastBox.userData.TrackedObject3JS = this;
    //
    this.wireframe = new LineSegments(edge, wireMaterials[this.classification]);
    this.add(this.wireframe);
    this.wireframe.visible = false;
    //
    this.mesh = new Mesh(unitCube, meshMaterials[this.classification]);
    this.add(this.mesh);
    this.mesh.visible = false;
  }

  public set = (
    classification: PerceptionClassification,
    id: string,
    position: Vector3,
    dimensions: Vector3,
    rotation: Quaternion,
  ): void => {
    this.name = id.toString();
    this.oId = id;
    this.classification = classification;
    this.calcMaterial();

    this.position.copy(position);
    this.quaternion.copy(rotation);

    // dimensions
    this.scale.copy(dimensions);

    // make raycast mesh snap to ground
    const zz =
      2 * Math.abs(snapTrackedsPosZ(this.position.z) - this.position.z);
    const xx = Math.max(this.scale.x, 1);
    const yy = Math.max(this.scale.y, 1);
    const zzz = Math.max(zz, 1);
    this.raycastBox.scale.set(xx, yy, zzz);

    this.calcVisibility();
  };

  private calcMaterial = (): void => {
    if (this._isHighlighted) {
      this.wireframe.material = wireMaterialHighlighted;
      this.mesh.material = meshMaterialHighlighted;
    } else if (this._isSelected) {
      this.wireframe.material = wireMaterialSelected;
      this.mesh.material = meshMaterialSelected;
    } else {
      this.wireframe.material = wireMaterials[this.classification];
      this.mesh.material = meshMaterials[this.classification];
    }
  };
  private calcVisibility = (): void => {
    const typeVisibility =
      visibleBBoxes.has(this.classification) || areAllBBoxesVis;
    this.mesh.visible = this._isActive && typeVisibility;
    this.wireframe.visible = this._isActive && typeVisibility;
  };

  public set isActive(value: boolean) {
    if (this._isActive === value) return;
    this._isActive = value;
    this.calcVisibility();
  }
  public get isActive(): boolean {
    return this._isActive;
  }

  public set isHighlighted(value: boolean) {
    if (this._isHighlighted === value) return;
    this._isHighlighted = value;
    this.calcMaterial();
    this.calcVisibility();
  }
  public get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  public set isSelected(value: boolean) {
    if (this._isSelected === value) return;
    this._isSelected = value;
    this.calcMaterial();
    this.calcVisibility();
  }
  public get isSelected(): boolean {
    return this._isSelected;
  }

  public get raycastMesh(): Mesh {
    return this.raycastBox;
  }
}
