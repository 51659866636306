/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import {
  Camera,
  Frustum,
  InstancedMesh,
  Matrix4,
  Object3D,
  Quaternion,
  Vector3,
} from 'three';

const _frustum = new Frustum();
const _center = new Vector3();

const _tmpPoint = new Vector3();

const _vecNear = new Vector3();
const _vecTopLeft = new Vector3();
const _vecTopRight = new Vector3();
const _vecDownRight = new Vector3();
const _vecDownLeft = new Vector3();

const _vecFarTopLeft = new Vector3();
const _vecFarTopRight = new Vector3();
const _vecFarDownRight = new Vector3();
const _vecFarDownLeft = new Vector3();

const _vectemp1 = new Vector3();
const _vectemp2 = new Vector3();
const _vectemp3 = new Vector3();

const _matrix = new Matrix4();
const _quaternion = new Quaternion();
const _scale = new Vector3();

/**
 * Adapted from three.js examples to test against a set of Objects3D instead of a Scene's children.
 * Iterates over all the objects in a set and returns the one that are within the frustum.
 */
export class BoxSelection {
  camera: Camera;
  startPoint: Vector3;
  endPoint: Vector3;
  collection: Object3D[];
  instances: Record<string, number[]>;

  constructor(camera: Camera) {
    this.camera = camera;
    this.startPoint = new Vector3();
    this.endPoint = new Vector3();
    this.collection = [];
    this.instances = {};
  }

  select(
    objs: Object3D[],
    startPoint?: Vector3,
    endPoint?: Vector3,
  ): Object3D[] {
    this.startPoint = startPoint || this.startPoint;
    this.endPoint = endPoint || this.endPoint;
    this.collection = [];

    this.updateFrustum(this.startPoint, this.endPoint);
    this.intersectsFrustum(_frustum, objs);

    return this.collection;
  }

  updateFrustum(startPoint: Vector3, endPoint: Vector3): void {
    startPoint = startPoint || this.startPoint;
    endPoint = endPoint || this.endPoint;

    // Avoid invalid frustum

    if (startPoint.x === endPoint.x) {
      endPoint.x += Number.EPSILON;
    }

    if (startPoint.y === endPoint.y) {
      endPoint.y += Number.EPSILON;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();

    if ('isPerspectiveCamera' in this.camera) {
      _tmpPoint.copy(startPoint);
      _tmpPoint.x = Math.min(startPoint.x, endPoint.x);
      _tmpPoint.y = Math.max(startPoint.y, endPoint.y);
      endPoint.x = Math.max(startPoint.x, endPoint.x);
      endPoint.y = Math.min(startPoint.y, endPoint.y);

      _vecNear.setFromMatrixPosition(this.camera.matrixWorld);
      _vecTopLeft.copy(_tmpPoint);
      _vecTopRight.set(endPoint.x, _tmpPoint.y, 0);
      _vecDownRight.copy(endPoint);
      _vecDownLeft.set(_tmpPoint.x, endPoint.y, 0);

      _vecTopLeft.unproject(this.camera);
      _vecTopRight.unproject(this.camera);
      _vecDownRight.unproject(this.camera);
      _vecDownLeft.unproject(this.camera);

      _vectemp1.copy(_vecTopLeft).sub(_vecNear);
      _vectemp2.copy(_vecTopRight).sub(_vecNear);
      _vectemp3.copy(_vecDownRight).sub(_vecNear);
      _vectemp1.normalize();
      _vectemp2.normalize();
      _vectemp3.normalize();

      _vectemp1.add(_vecNear);
      _vectemp2.add(_vecNear);
      _vectemp3.add(_vecNear);

      const planes = _frustum.planes;

      planes[0].setFromCoplanarPoints(_vecNear, _vecTopLeft, _vecTopRight);
      planes[1].setFromCoplanarPoints(_vecNear, _vecTopRight, _vecDownRight);
      planes[2].setFromCoplanarPoints(_vecDownRight, _vecDownLeft, _vecNear);
      planes[3].setFromCoplanarPoints(_vecDownLeft, _vecTopLeft, _vecNear);
      planes[4].setFromCoplanarPoints(
        _vecTopRight,
        _vecDownRight,
        _vecDownLeft,
      );
      planes[5].setFromCoplanarPoints(_vectemp3, _vectemp2, _vectemp1);
      planes[5].normal.multiplyScalar(-1);
    } else if ('isOrthographicCamera' in this.camera) {
      const left = Math.min(startPoint.x, endPoint.x);
      const top = Math.max(startPoint.y, endPoint.y);
      const right = Math.max(startPoint.x, endPoint.x);
      const down = Math.min(startPoint.y, endPoint.y);

      _vecTopLeft.set(left, top, -1);
      _vecTopRight.set(right, top, -1);
      _vecDownRight.set(right, down, -1);
      _vecDownLeft.set(left, down, -1);

      _vecFarTopLeft.set(left, top, 1);
      _vecFarTopRight.set(right, top, 1);
      _vecFarDownRight.set(right, down, 1);
      _vecFarDownLeft.set(left, down, 1);

      _vecTopLeft.unproject(this.camera);
      _vecTopRight.unproject(this.camera);
      _vecDownRight.unproject(this.camera);
      _vecDownLeft.unproject(this.camera);

      _vecFarTopLeft.unproject(this.camera);
      _vecFarTopRight.unproject(this.camera);
      _vecFarDownRight.unproject(this.camera);
      _vecFarDownLeft.unproject(this.camera);

      const planes = _frustum.planes;

      planes[0].setFromCoplanarPoints(
        _vecTopLeft,
        _vecFarTopLeft,
        _vecFarTopRight,
      );
      planes[1].setFromCoplanarPoints(
        _vecTopRight,
        _vecFarTopRight,
        _vecFarDownRight,
      );
      planes[2].setFromCoplanarPoints(
        _vecFarDownRight,
        _vecFarDownLeft,
        _vecDownLeft,
      );
      planes[3].setFromCoplanarPoints(
        _vecFarDownLeft,
        _vecFarTopLeft,
        _vecTopLeft,
      );
      planes[4].setFromCoplanarPoints(
        _vecTopRight,
        _vecDownRight,
        _vecDownLeft,
      );
      planes[5].setFromCoplanarPoints(
        _vecFarDownRight,
        _vecFarTopRight,
        _vecFarTopLeft,
      );
      planes[5].normal.multiplyScalar(-1);
    } else {
      console.error('THREE.SelectionBox: Unsupported camera type.');
    }
  }

  intersectsFrustum(frustum: Frustum, objs: Object3D[]): void {
    for (let x = 0; x < objs.length; x++) {
      const obj = objs[x] as Object3D;
      if (obj === undefined) continue;
      if ('isInstancedMesh' in obj) {
        const iMesh = obj as InstancedMesh;
        this.instances[iMesh.uuid] = [];

        for (let instanceId = 0; instanceId < iMesh.count; instanceId++) {
          iMesh.getMatrixAt(instanceId, _matrix);
          _matrix.decompose(_center, _quaternion, _scale);
          _center.applyMatrix4(iMesh.matrixWorld);

          if (frustum.containsPoint(_center)) {
            this.instances[iMesh.uuid].push(instanceId);
          }
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _center.copy(iMesh.geometry.boundingSphere.center);
        _center.applyMatrix4(iMesh.matrixWorld);
        if (frustum.containsPoint(_center)) {
          this.collection.push(iMesh);
        }
      } else {
        _center.setFromMatrixPosition(obj.matrixWorld);

        if (frustum.containsPoint(_center)) {
          this.collection.push(obj);
        }
      }
    }
  }
}
