/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { ALERTS_POLL_INTERVAL, REST, LIDAR_HUB_REST } from '../constants';
import { safeFetch } from '../safeFetch';

export type SourceType = 'sensor' | 'system';
export interface ServerAlert {
  id: number;
  active: boolean;
  active_count: number;
  alert_code: string;
  category: string;
  first_occurred: number;
  last_occurred?: number;
  level: string;
  msg: string;
  msg_verbose: string;
  source_info: string;
}
export interface ZoneAlert {
  id: string;
  name: string;
  severity: string;
  occupancy: number;
  message: string;
}
export interface Alert {
  id: string;
  active: boolean;
  activeCount: number;
  alertCode: string;
  category: string;
  firstOccured: number;
  lastOccured: number;
  level: string;
  message: string;
  messageVerbose: string;
  sourceInfo: string;
}
export type AlertsResponse = {
  alerts: {
    active: ServerAlert[];
    log: ServerAlert[];
  };
};
export type ZoneAlertsResponse = {
  alerts: ZoneAlert[];
};

export const getAllAlerts = async (
  timeout = ALERTS_POLL_INTERVAL,
): Promise<AlertsResponse | null> =>
  safeFetch.json.get<AlertsResponse>(REST.alerts.all, { timeout });

export const getActiveAlerts = async (): Promise<AlertsResponse | null> =>
  safeFetch.json.get<AlertsResponse>(REST.alerts.active);

export const getLoggedAlerts = async (): Promise<AlertsResponse | null> =>
  safeFetch.json.get<AlertsResponse>(REST.alerts.logged);

export const getZoneAlerts = async (): Promise<ZoneAlertsResponse | null> =>
  safeFetch.json.get<ZoneAlertsResponse>(LIDAR_HUB_REST.alerts);

export const alerts = {
  all: getAllAlerts,
  active: getActiveAlerts,
  logged: getLoggedAlerts,
  zone: getZoneAlerts,
};
