/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Alert } from '../api/alerts';
import { Button } from '../app/components/Button';
import ActionSubHeader from '../app/components/pane/ActionSubHeader';
import Select from '../app/components/Select';
import { logs_prefix_url } from '../constants';
import { useAppDispatch, useAppState } from '../Stores';
import { alertActivities } from '../types';
import { getAlertSourceType, ObjectKeys } from '../util/misc';

const DiagnosticsButton = ({
  onClick,
  children,
  disabled = false,
}: {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <Button
      className="action bgGreyI my-1 ml-4"
      style={{ flexGrow: 0, margin: 0, marginRight: 'var(--r2)' }}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const spaceBetweenButtons = 'var(--r2)';

const ButtonSection = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <section className="flex flex-row justify-start items-center">
      {children}
    </section>
  );
};

const FilterButton = ({
  mr = spaceBetweenButtons,
  children,
}: {
  mr?: string;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      className="flex items-center"
      style={{
        marginRight: mr,
      }}
    >
      {children}
    </div>
  );
};

function DiagnosticsActions(): JSX.Element | null {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const diagnostics = state.diagnostics;
  const allIds = [...diagnostics.sensorAlertIds, ...diagnostics.systemAlertIds];
  const alertTimes = [
    'All Times',
    'Last 10 Minutes',
    'Last 1 Hour',
    'Last 24 Hours',
    'Last 7 Days',
    'Last 30 Days',
    'Before Today',
    'Before This Week',
  ];
  const alertCodes = [
    'All Codes',
    ...new Set(allIds.map((id) => diagnostics.allAlerts[id].alertCode)),
  ];
  const alertLevels = [
    'All Levels',
    ...new Set(allIds.map((id) => diagnostics.allAlerts[id].level)),
  ];

  const totalSelected = ObjectKeys(diagnostics.isSelected).reduce(
    (acc, id) => (diagnostics.isSelected[id] ? ++acc : acc),
    0,
  );
  const haveSelected = totalSelected > 0;
  const allSelected =
    totalSelected === ObjectKeys(diagnostics.isSelected).length;

  if (state.app.mode === 'diagnostics')
    return (
      <>
        <ActionSubHeader
          style={{
            justifyContent: 'space-between',
          }}
        >
          <ButtonSection>
            <DiagnosticsButton
              onClick={() => {
                dispatch({
                  type: 'collapseAll',
                });
              }}
            >
              Collapse All
            </DiagnosticsButton>
            <DiagnosticsButton
              onClick={() => {
                dispatch({
                  type: 'selectAllAlertsByType',
                  sourceType: 'all',
                  value: allSelected ? false : true,
                });
              }}
            >
              {allSelected ? `Deselect` : `Select`} All
            </DiagnosticsButton>
            <DiagnosticsButton
              disabled={allIds.length === 0}
              onClick={() => {
                dispatch({ type: 'clearLoggedAlerts' });
              }}
            >
              Clear Logged
            </DiagnosticsButton>
            <DiagnosticsButton
              disabled={!haveSelected}
              onClick={() => {
                const selectedAlertsJson = {} as Record<
                  string,
                  Record<string, Alert[]>
                >;
                Object.keys(diagnostics.isSelected)
                  .filter((id) => diagnostics.isSelected[id])
                  .forEach((id) => {
                    const alert = diagnostics.allAlerts[id];
                    const { sourceInfo, alertCode } = alert;
                    const type = getAlertSourceType(alertCode);

                    if (selectedAlertsJson[type] === undefined) {
                      selectedAlertsJson[type] = {};
                    }
                    if (selectedAlertsJson[type][sourceInfo] === undefined) {
                      selectedAlertsJson[type][sourceInfo] = [];
                    }
                    selectedAlertsJson[type][sourceInfo].push(alert);
                  });

                const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                  JSON.stringify(selectedAlertsJson, null, 4),
                )}`;

                const link = document.createElement('a');
                link.href = jsonString;
                link.download = 'alerts.json';
                link.click();
                link.remove();
              }}
            >
              Export Selected
            </DiagnosticsButton>
            <DiagnosticsButton
              onClick={() => {
                window.open(logs_prefix_url, '_blank');
              }}
            >
              View Logs
            </DiagnosticsButton>
          </ButtonSection>
          <ButtonSection>
            <FilterButton>
              <Select
                active={diagnostics.filterActivity}
                options={alertActivities}
                name="diag-filter-level"
                onChange={(code) =>
                  dispatch({
                    type: 'setFilterActivity',
                    value: code,
                  })
                }
              />
            </FilterButton>
            <FilterButton>
              <Select
                active={diagnostics.filterTime}
                options={alertTimes}
                name="diag-filter-level"
                onChange={(code) =>
                  dispatch({
                    type: 'setFilterTime',
                    value: code.toString(),
                  })
                }
              />
            </FilterButton>
            <FilterButton>
              <Select
                active={diagnostics.filterCode}
                options={alertCodes}
                name="diag-filter-level"
                onChange={(code) =>
                  dispatch({
                    type: 'setFilterCode',
                    value: code,
                  })
                }
              />
            </FilterButton>
            <FilterButton>
              <Select
                active={diagnostics.filterLevel}
                options={alertLevels}
                name="diag-filter-level"
                onChange={(level) =>
                  dispatch({
                    type: 'setFilterLevel',
                    value: level,
                  })
                }
              />
            </FilterButton>
          </ButtonSection>
        </ActionSubHeader>
      </>
    );
  return null;
}

export default DiagnosticsActions;
