/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import * as flatbuffers from 'flatbuffers';
import { Data } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/data';
import { DataTypes } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/data-types';
import { PointClouds } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/point-clouds';
import { Clusters } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/clusters';
import { TrackedObjects } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/tracked-objects';
import {
  adaptCloud,
  adaptOccupations,
  adaptPerception,
  adaptZones,
} from '../util/adapters';
import {
  createClustersParametersMap,
  createObjectParametersMap,
  ObjectParameters,
  populatePointCloud,
} from '../FlatbufDeserialization';
import { Image as FlatImage } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/image';
import { Offender, RecordingParams } from './PlaybackStore';
import { PALETTE_MATERIAL } from '../util/palettes';
import { parseJSONExtrinsic } from '../util/misc';
import { Vector3 } from 'three';

export const ParsePlayback = async (
  arrayBuffer: ArrayBuffer,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Promise<RecordingParams> => {
  const enc = new TextDecoder('utf-8');
  const data: RecordingParams = {
    name: '',
    framerate: 10,
    zones: [],
    timestamps: [],
    clouds: [],
    frames: {},
    timestampToIndexMap: new Map(),
    sensorsWithImages: new Set(),
    sensorColors: {},
    dataRecorderEvent: {
      primarySensorSerialNo: '',
      latLongAlt: new Vector3(),
      startTimestamp: 0,
      endTimestamp: 0,
    },
    stats: {
      maxOccupancies: 0,
    },
    extrinsics: {},
    offender: null,
  };
  const view = new DataView(arrayBuffer);
  const totalBytes = arrayBuffer.byteLength;
  let offset = 0;
  while (offset < totalBytes) {
    const size = view.getUint32(offset, false);
    offset += 4;
    const bytes = new Uint8Array(arrayBuffer, offset, size);
    offset += size;
    // parse flatbuffer from arraybuffer
    const buffer = new flatbuffers.ByteBuffer(bytes);
    const perceptionData = Data.getRootAsData(buffer);
    const ts = perceptionData.timestamp().toFloat64();
    const enumDataTypes = perceptionData.dataType();
    switch (enumDataTypes) {
      case DataTypes.PointClouds: {
        const point_clouds = perceptionData.data(new PointClouds());
        const populated = populatePointCloud(point_clouds);
        populated.forEach((populatedCloud) => {
          data.clouds.push(adaptCloud(populatedCloud));
        });
        break;
      }
      case DataTypes.TrackedObjects: {
        const tracked = perceptionData.data(new TrackedObjects());
        const oParams: ObjectParameters[] = createObjectParametersMap(tracked);
        const trackedObjects = adaptPerception(oParams);
        if (data.frames[ts] === undefined) data.frames[ts] = {};
        data.frames[ts].tracked = trackedObjects;
        break;
      }
      case DataTypes.Clusters: {
        const flatClusters = perceptionData.data(new Clusters());
        const oParams = createClustersParametersMap(flatClusters);
        const clusters = adaptPerception(oParams);
        if (data.frames[ts] === undefined) data.frames[ts] = {};
        data.frames[ts].clusters = clusters;
        break;
      }
      case DataTypes.Image: {
        const flatImage = perceptionData.data(new FlatImage());
        const jpgBytes = flatImage.dataArray() || new Uint8Array(0);
        const id = flatImage.id();
        if (data.frames[ts] === undefined) data.frames[ts] = {};
        if (data.frames[ts].images === undefined) data.frames[ts].images = {};
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data.frames[ts].images![id] = jpgBytes;
        data.sensorsWithImages.add(id);
        break;
      }
      default: {
        const decoded = enc.decode(bytes);
        try {
          const json = JSON.parse(decoded);
          //
          if ('zones' in json) {
            const parsedZones = json['zones'];
            const adaptedZones = adaptZones(parsedZones);
            adaptedZones.forEach((zone) => {
              data.zones.push(zone);
            });
            //
          } else if ('occupations' in json) {
            const parsedOccupations = json['occupations'];
            // not a flat msg get timestamp from json
            const ts = json['timestamp'];
            const adaptedOccupations = adaptOccupations(parsedOccupations);

            if (data.frames[ts] === undefined) data.frames[ts] = {};
            data.frames[ts].occupations = adaptedOccupations;

            // Are the max number of occupations at this timestamp greater than the current max?
            const totalOccupanciesOccurred = adaptedOccupations.reduce(
              (p, o) => o.trackedObjectIds.length + p,
              0,
            );
            data.stats.maxOccupancies = Math.max(
              data.stats.maxOccupancies,
              totalOccupanciesOccurred,
            );
            // Get the frame rate for recording
          } else if ('frame_rate' in json) {
            data.framerate = json['frame_rate'];
            //
          } else if ('data_recorder_event' in json) {
            const dataRecorderEvent = json['data_recorder_event'];
            // data.dataRecorderEvent.count = dataRecorderEvent['count'];
            data.dataRecorderEvent.primarySensorSerialNo =
              dataRecorderEvent['primary_sensor_serial_no'];
            data.name = dataRecorderEvent['name'];
            data.dataRecorderEvent.startTimestamp =
              dataRecorderEvent['start_timestamp'];
            data.dataRecorderEvent.endTimestamp =
              dataRecorderEvent['end_timestamp'];
            data.dataRecorderEvent.latLongAlt.set(
              dataRecorderEvent['position']['y'],
              dataRecorderEvent['position']['x'],
              dataRecorderEvent['position']['z'],
            );
            //
            if ('offender' in dataRecorderEvent) {
              const offenderJson = dataRecorderEvent['offender'];
              const offender = {} as Offender;
              offender.id = offenderJson['id'];
              offender.position = new Vector3(
                offenderJson['position']['x'],
                offenderJson['position']['y'],
                offenderJson['position']['z'],
              );
              offender.positionUncertainty = new Vector3(
                offenderJson['position_uncertainty']['x'],
                offenderJson['position_uncertainty']['y'],
                offenderJson['position_uncertainty']['z'],
              );
              data.offender = offender;
            }
            console.log('data_recorder_event >>> ', json, data.offender);
            //
          } else if ('transforms' in json) {
            const transforms = json['transforms'];
            transforms.forEach((transform: unknown) => {
              const r = parseJSONExtrinsic(transform);
              data.extrinsics[r.id] = r;
            });

            // unknown json - uncomment to debug
            // } else {
            //   console.log('json >>> ', json);
          }
        } catch (e) {
          console.log('>>> error', e, 'decoded', decoded);
        }
        break;
      }
    }
  }

  // POST PROCESSING

  // sort in case frames are not in order
  const timestamps = Object.keys(data.frames)
    .map((e) => parseInt(e))
    .sort((a, b) => a - b);
  data.timestamps = timestamps;
  timestamps.forEach((t, i) => data.timestampToIndexMap.set(t, i));

  // override extrinsics to include only sensors with images
  Object.keys(data.extrinsics).forEach((id) => {
    const noImage = !data.sensorsWithImages.has(id);
    if (noImage) delete data.extrinsics[id];
  });

  // populate sensor colors
  data.sensorColors = Array.from(data.sensorsWithImages).reduce(
    (prev, curr, i) => {
      prev[curr] = PALETTE_MATERIAL[(i + 4) % PALETTE_MATERIAL.length];
      return prev;
    },
    {} as { [key: string]: string },
  );

  return data;
};
