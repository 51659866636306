/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { SourceType } from '../api/alerts';
import { useAppDispatch, useAppState } from '../Stores';
import TableSelectableSection from './common/TableSelectableSection';

const typeData = {
  sensor: {
    idsList: 'sensorAlertIds',
  },
  system: {
    idsList: 'systemAlertIds',
  },
} as const;

const DiagnosticsTableTypeSection = ({
  type,
  onChevronClick,
  open,
  tableName,
}: {
  type: SourceType;
  onChevronClick: () => void;
  open: boolean;
  tableName: string;
}): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const diagnostics = state.diagnostics;
  const { idsList } = typeData[type];
  const isTypeSelected =
    diagnostics[idsList].length !== 0 &&
    diagnostics[idsList].every((id) => diagnostics.isSelected[id]);

  return (
    <div className="table-type-section">
      <TableSelectableSection
        name={tableName}
        isSelected={isTypeSelected}
        fontSize="1.1rem" // Needs to be 1.1 to visually match
        selectOnClick={() => {
          dispatch({
            type: 'selectAllAlertsByType',
            sourceType: type,
            value: !isTypeSelected,
          });
        }}
        chevronOnClick={() => onChevronClick()}
        chevronDisabled={diagnostics[idsList].length === 0}
        open={open}
      />
    </div>
  );
};

export default DiagnosticsTableTypeSection;
