/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useState } from 'react';
import { Row } from '../pane/Row';
import NumberInput from './NumberInput';

const NumberInputRow = ({
  blurb,
  value,
  min,
  max,
  isDirty = false,
  disabled = false,
  onChangeNumber,
  forceUpdate,
  step,
  precision,
}: {
  blurb: string;
  value: number;
  min?: number;
  max?: number;
  isDirty?: boolean;
  disabled?: boolean;
  forceUpdate?: unknown;
  step?: number;
  precision?: number;
  onChangeNumber: (v: number) => void;
}): JSX.Element => {
  const [isSynced, setIsSynced] = useState(true);
  const [isNotSyncedMsg, setIsNotSyncedMsg] = useState('');

  return (
    <div className="flex flex-col">
      <Row disabled={disabled} blurb={blurb}>
        <NumberInput
          isDirty={isDirty}
          disabled={disabled}
          value={value}
          min={min}
          max={max}
          onChangeNumber={onChangeNumber}
          setIsNotSyncedMsg={setIsNotSyncedMsg}
          setIsSynced={setIsSynced}
          isSynced={isSynced}
          forceUpdate={forceUpdate}
          step={step}
          precision={precision}
        />
      </Row>
      {!isSynced && <p className="text-sm textError">{isNotSyncedMsg}</p>}
    </div>
  );
};

export default NumberInputRow;
