/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import NumberInputRow from '../app/components/NumberInput/NumberRow';
import { useAppDispatch, useAppState } from '../Stores';
import { HEIGHT_RANGE } from '../constants';

export const ProcessedCloudExclusion = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const heightRange =
    state.clouds.Background.display.heightRange ?? HEIGHT_RANGE.limit;
  const heightMin =
    state.clouds.Background.display.heightMin ?? HEIGHT_RANGE.default.min;
  const heightMax =
    state.clouds.Background.display.heightMax ?? HEIGHT_RANGE.default.max;

  return (
    <div className="flex-row">
      <NumberInputRow
        blurb="Point Min Height"
        disabled={false}
        forceUpdate={state.zones.selected}
        value={heightMin}
        min={heightRange.min}
        max={heightMax}
        onChangeNumber={(v) => {
          dispatch({
            type: 'setProcessedCloudHeightExclusion',
            variant: 'heightMin',
            value: v,
          });
        }}
      />

      <NumberInputRow
        blurb="Point Max Height"
        disabled={false}
        forceUpdate={state.zones.selected}
        value={heightMax}
        min={heightMin}
        max={heightRange.max}
        onChangeNumber={(v) => {
          dispatch({
            type: 'setProcessedCloudHeightExclusion',
            variant: 'heightMax',
            value: v,
          });
        }}
      />
    </div>
  );
};
