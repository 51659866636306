/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-identical-functions */

import React, { useEffect } from 'react';
import { PropertiesGroup } from '../app/components/pane/PropertiesGroup';
import { Context } from '../types';
import { ZoneIOActions } from './ZoneIOActions';
import { SelectableZoneList } from './SelectableZoneList';
import { useZoneSelect } from './useZoneSelect';
import { usePolyCreate } from './usePolyCreate';
import { SelectedZoneProperties } from './SelectedZoneProperties';
import { useAppDispatch, useAppState } from '../Stores';
import { useVerticesEdit } from './useZoneEdit/useVerticesEdit';
import { useMinMaxEdit } from './useZoneEdit/useMinMaxEdit';
import { endpoints } from '../api/endpoints';
import { payloadZone } from '../api/zone';
import { getGUID } from '../util/misc';
import { getAppId } from '../constants';
import { ZoneParams } from './ZoneStore';
import { Vector2 } from 'three';
import { ZoneActions } from './ZoneActions';
import { useVertexAddRemove } from './useZoneEdit/useVertexAddRemove';
const {
  zone: {
    event: { addZone },
    point: { addZone: addPointZone },
  },
} = endpoints;

export const ZoneProperties = ({
  context,
}: {
  context: Context;
}): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  useZoneSelect(context);
  usePolyCreate(context);
  useVerticesEdit(context);
  useMinMaxEdit(context);
  useVertexAddRemove(context);

  useEffect(() => {
    const id = state.zones.selected;
    if (id === null) return;
    const params = state.zones.zoneRedefined[id] || state.zones.params[id];

    const zone3JS = context.instances.Zones.all[id];
    if (zone3JS === undefined) return;
    zone3JS.set(
      id,
      params.type,
      params.name,
      params.heightMin,
      params.heightMax,
      params.vertices,
    );
  }, [state.zones.params, state.zones.zoneRedefined, state.zones.selected]);

  // paste logic
  useEffect(() => {
    if (!state.zones.paste) return;
    const copiedId = state.zones.copied;
    if (copiedId === null) return;

    dispatch({ type: 'setPasteZone', value: false });

    const {
      type,
      name: refsName,
      heightMin,
      heightMax,
      vertices: refVertices,
    } = state.zones.zoneRedefined[copiedId] || state.zones.params[copiedId];
    const serverId = getGUID();
    const appId = getAppId(serverId.toString(), type);
    const name = `${refsName}-copy`;
    const offset = new Vector2(Math.random() * 2, Math.random() * 2);
    const vertices = refVertices.map((v) => v.clone().add(offset));
    dispatch({
      type: 'setZone',
      id: appId,
      value: {
        heightMin,
        heightMax,
        type,
        vertices,
        serverId,
        name,
      } as ZoneParams,
    });
    // Post to server
    const payload = payloadZone(
      serverId,
      type,
      name,
      heightMin,
      heightMax,
      vertices,
      '',
    );
    const isModifiedAnEventZone = type === 'Event';
    const response = isModifiedAnEventZone
      ? addZone(payload)
      : addPointZone(payload);
    response.then((r) => {
      if (r?.ok) {
        dispatch({ type: 'setSelectedZone', id: appId });
      }
    });
  }, [
    state.zones.paste,
    state.zones.zoneRedefined,
    state.zones.params,
    state.zones.copied,
  ]);

  return (
    <>
      <PropertiesGroup name="Zones Lists" expandedInitValue={true}>
        <SelectableZoneList />
      </PropertiesGroup>

      <PropertiesGroup name="Selected Properties" expandedInitValue={true}>
        <div className="flex flex-row justify-end my-2">
          <ZoneActions />
        </div>

        <SelectedZoneProperties />
      </PropertiesGroup>

      <PropertiesGroup name="Actions" expandedInitValue={true}>
        <ZoneIOActions />
      </PropertiesGroup>
    </>
  );
};
