/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Column } from '../diagnosticsColumns';

export const Headers = <T extends unknown>({
  columns,
}: {
  columns: Column<T>[];
}): JSX.Element => {
  return (
    <>
      <th
        key="select-elem"
        style={{
          color: 'var(--greyB)',
          fontWeight: 'normal',
          textAlign: 'center',
        }}
      ></th>
      {columns.map((column) => {
        return (
          <th
            key={column.title}
            style={{
              color: 'var(--greyB)',
              fontSize: 'var(--r3_5)',
              fontWeight: 'normal',
              textAlign: 'center',
            }}
          >
            {column.title}
          </th>
        );
      })}
    </>
  );
};

export const TableHeader = <T,>({
  className,
  columns,
}: {
  className?: string;
  columns: Column<T>[];
}): JSX.Element => {
  return (
    <div className="flex">
      <div
        className={className}
        style={{
          padding: `var(--r1) 0`,
          position: 'sticky',
          top: '0',
          backgroundColor: 'var(--greyJ)',
          zIndex: '3',
          width: '100%',
          height: 'var(--r10)',
        }}
      >
        <table
          style={{
            width: '100%',
          }}
        >
          <thead>
            <tr className="table-header">
              <Headers columns={columns} />
            </tr>
          </thead>
        </table>
      </div>
      {/* Account for scrollbar width without including a scrollbar */}
      <div
        style={{ width: 'var(--scrollbar-w)', backgroundColor: 'var(--greyJ)' }}
      ></div>
    </div>
  );
};
