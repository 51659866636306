/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { endpoints } from '../api/endpoints';
import { payloadZone } from '../api/zone';
import { ActionButton } from '../app/components/actionButton/ActionButton';
import { Separator } from '../app/components/Separator';
import { useAppState, useAppDispatch } from '../Stores';

const {
  zone: {
    event: { addZone, deleteZone },
    point: { addZone: addPointZone, deleteZone: deletePointZone },
  },
} = endpoints;

export const ZoneActions = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const id = state.zones.selected;
  const isDirty =
    id !== null ? state.zones.zoneRedefined[id] !== undefined : false;

  return (
    <>
      <ActionButton
        icon="Save"
        tooltip={{ text: 'Save Zone modifications' }}
        disabled={id === null || !isDirty}
        onClick={async () => {
          // save modifications
          if (id === null) return;
          const modifiedParams = state.zones.zoneRedefined[id];
          if (modifiedParams === undefined) return;
          try {
            if (modifiedParams.metadata) JSON.parse(modifiedParams.metadata);
          } catch (e) {
            dispatch({
              type: 'enqueueFeedbackMessage',
              value: {
                message: `Event zone metadata is not a valid JSON - edit the metadata to resave`,
                type: 'error',
                priority: 15,
                durationMs: 2000,
              },
            });
            return;
          }

          dispatch({
            type: 'setZone',
            id,
            value: modifiedParams,
          });
          // Post to server
          const {
            serverId,
            type,
            name,
            heightMin,
            heightMax,
            vertices,
            metadata,
          } = modifiedParams;
          const payload = payloadZone(
            serverId,
            type,
            name,
            heightMin,
            heightMax,
            vertices,
            metadata,
          );
          // populate modified entry
          const isModifiedAnEventZone = modifiedParams.type === 'Event';
          const addRequest = isModifiedAnEventZone ? addZone : addPointZone;
          const res = await addRequest(payload);

          // A successful response is non-null
          if (res === null) {
            dispatch({
              type: 'enqueueFeedbackMessage',
              value: {
                message: `Encountered an issue creating the zone. Please try again.`,
                type: 'error',
                priority: 15,
                durationMs: 2000,
              },
            });
            return;
          } // Successfully created the zone

          // delete from respective server
          const noTypeChange =
            modifiedParams.type === state.zones.params[id].type;
          if (!noTypeChange) {
            modifiedParams.type === 'Event'
              ? deletePointZone(serverId)
              : deleteZone(serverId);
          }

          // reset polygon's dirty flag
          dispatch({
            type: 'setZoneRedefined',
            id: id,
            value: null,
          });
        }}
      />
      <ActionButton
        icon="Reset"
        tooltip={{ text: ' Reset any transformation applied' }}
        disabled={id === null || !isDirty}
        onClick={() => {
          // reset transformation
          if (id === null) return;
          dispatch({
            type: 'setZoneRedefined',
            id,
            value: null,
          });
        }}
      />
      <ActionButton
        icon="Delete"
        tooltip={{ text: 'Delete Selected Zone' }}
        disabled={id === null}
        onClick={() => {
          // clean up
          if (id === null) return;
          dispatch({
            type: 'setZone',
            id,
            value: null,
          });
          dispatch({
            type: 'setZoneRedefined',
            id,
            value: null,
          });
          dispatch({
            type: 'setSelectedZone',
            id: null,
          });
          dispatch({
            type: 'setCopiedZone',
            id: null,
          });
          // rest call
          const { type, serverId } = state.zones.params[id];
          type === 'Event' ? deleteZone(serverId) : deletePointZone(serverId);
        }}
      />

      <Separator />

      <ActionButton
        icon="Copy"
        tooltip={{ text: 'Copy zone' }}
        disabled={id === null}
        onClick={() => {
          // copy zone
          if (id === null) return;
          dispatch({
            type: 'setCopiedZone',
            id,
          });
        }}
      />
      <ActionButton
        icon="Paste"
        tooltip={{ text: 'Paste Zone' }}
        disabled={state.zones.copied === null}
        onClick={() => {
          // paste zone
          dispatch({ type: 'setPasteZone', value: true });
        }}
      />
    </>
  );
};
