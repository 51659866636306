/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { PROXY_REST, proxy_rest_reachable } from '../constants';
import { safeFetch } from '../safeFetch';

export interface ProxyResponse {
  proxyIndex: number;
}

export const reachRestServer = async (): Promise<Response | null> => {
  return safeFetch.get(`${proxy_rest_reachable}`);
};
export const getProxyRoute = async (
  sensorId: string,
  sensorIp: string,
): Promise<ProxyResponse | null> =>
  safeFetch.json.post<ProxyResponse>(PROXY_REST.getProxyRoute, {
    sensor_id: sensorId,
    sensor_ip: sensorIp,
  });

export const proxy = {
  testRestReach: reachRestServer,
  getRoute: getProxyRoute,
};
