/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

interface SafariDocument extends Document {
  webkitFullScreenElement: boolean;
  webkitExitFullscreen: () => Promise<void>;
}
interface SafariHTMLElement extends HTMLElement {
  webkitFullScreenElement: boolean;
  webkitExitFullscreen: () => Promise<void>;
  webkitRequestFullscreen: () => Promise<void>;
}

export const toggleFullScreen = async (
  htmlElem: HTMLElement,
): Promise<void> => {
  // We augment to get "artificial" typing support for the safari DOM api.
  const augmentedDocument = document as unknown as SafariDocument;
  const augmentedHtmlElement = htmlElem as unknown as SafariHTMLElement;

  if (augmentedDocument.fullscreenElement) {
    augmentedDocument.exitFullscreen();
  } else if (augmentedHtmlElement.requestFullscreen) {
    await augmentedHtmlElement.requestFullscreen();
  }
  // 👇 safari -> doesn't support the standard above yet
  else if (augmentedDocument.webkitFullScreenElement) {
    augmentedDocument.webkitExitFullscreen();
  } else if (augmentedHtmlElement.webkitRequestFullscreen) {
    await augmentedHtmlElement.webkitRequestFullscreen();
  }
};
