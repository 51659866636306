/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import RecordingFeedback from '../../../record/RecordingFeedback';
import { FeedbackLine } from './FeedbackLine';

export const Footer = (): JSX.Element => {
  return (
    <footer
      id="Footer"
      className="flex drop-shadow justify-between bgGreyH"
      style={{
        height: 'var(--r8)',
      }}
    >
      <RecordingFeedback />
      <FeedbackLine />
    </footer>
  );
};
