/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps, useCallback } from 'react';
import { useAppDispatch, useAppState } from '../../../Stores';
import { Button } from '../Button';
import { toggleFullScreen } from '../../../util/fullscreen';

const FullScreenSVG = () => (
  <>
    <path
      className="fill"
      d="M16,12.25V15H13.25a.25.25,0,0,0-.25.25v.5a.25.25,0,0,0,.25.25H17V12.25a.25.25,0,0,0-.25-.25h-.5A.25.25,0,0,0,16,12.25Z"
    />
    <path
      className="fill"
      d="M2,15V12.25A.25.25,0,0,0,1.75,12h-.5a.25.25,0,0,0-.25.25V16H4.75A.25.25,0,0,0,5,15.75v-.5A.25.25,0,0,0,4.75,15Z"
    />
    <path
      className="fill"
      d="M13,2.25v.5a.25.25,0,0,0,.25.25H16V5.75a.25.25,0,0,0,.25.25h.5A.25.25,0,0,0,17,5.75V2H13.25A.25.25,0,0,0,13,2.25Z"
    />
    <path
      className="fill"
      d="M2,3H4.75A.25.25,0,0,0,5,2.75v-.5A.25.25,0,0,0,4.75,2H1V5.75A.25.25,0,0,0,1.25,6h.5A.25.25,0,0,0,2,5.75Z"
    />
    <rect className="fill" height="8" rx="0.25" width="10" x="4" y="5" />
  </>
);

const ExitFullScreenSVG = () => (
  <>
    <path
      className="fill"
      d="M3,1.25V4H.25A.25.25,0,0,0,0,4.25v.5A.25.25,0,0,0,.25,5H4V1.25A.25.25,0,0,0,3.75,1h-.5A.25.25,0,0,0,3,1.25Z"
    />
    <path
      className="fill"
      d="M15,4V1.25A.25.25,0,0,0,14.75,1h-.5a.25.25,0,0,0-.25.25V5h3.75A.25.25,0,0,0,18,4.75v-.5A.25.25,0,0,0,17.75,4Z"
    />
    <path
      className="fill"
      d="M0,13.25v.5A.25.25,0,0,0,.25,14H3v2.75a.25.25,0,0,0,.25.25h.5A.25.25,0,0,0,4,16.75V13H.25A.25.25,0,0,0,0,13.25Z"
    />
    <path
      className="fill"
      d="M15,14h2.75a.25.25,0,0,0,.25-.25v-.5a.25.25,0,0,0-.25-.25H14v3.75a.25.25,0,0,0,.25.25h.5a.25.25,0,0,0,.25-.25Z"
    />
    <rect className="fill" height="8" rx="0.25" width="10" x="4" y="5" />
  </>
);

export const IconFullScreenToggle = ({
  active,
  isFullScreen,
  ...rest
}: {
  active: boolean;
  isFullScreen: boolean;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    // We add the transform property to make the icon CW,
    // to match the rotation direction.
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      className={active ? 'active' : ''}
      {...rest}
      transform="scale (-1, 1)"
    >
      {isFullScreen ? <ExitFullScreenSVG /> : <FullScreenSVG />}
    </svg>
  );
};

const FullScreen = ({
  content,
}: {
  content: HTMLDivElement | null;
}): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const toggleFullScreenCallback = useCallback(
    (isFullScreen) => {
      if (content === null) {
        return;
      }

      dispatch({
        type: 'setSceneFullScreen',
        value: isFullScreen,
      });
      toggleFullScreen(content);
    },
    [content],
  );

  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        padding: '2.7rem',
        zIndex: 12,
        display: 'flex',
      }}
    >
      <Button
        className="bgNone"
        onClick={() => {
          toggleFullScreenCallback(!state.app.isFullScreen);
        }}
      >
        <IconFullScreenToggle
          active={false}
          isFullScreen={state.app.isFullScreen}
        />
      </Button>
    </div>
  );
};

export default FullScreen;
