/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable sonarjs/no-nested-switch */

import React from 'react';
import { useAppState } from '../../../Stores';

export const FeedbackLine = (): JSX.Element => {
  const state = useAppState();

  const { message, type } = state.app.feedbackMessage.fromQueue ??
    state.app.feedbackMessage.main ?? {
      message: '...',
      type: 'info',
    };

  const colorRule = {
    info: 'textGreyC',
    warning: 'textWarning',
    error: 'textError',
  }[type];

  return (
    <div
      className={`flex grow flex-center no-select text-md textGreyB ${colorRule}`}
    >
      <p>{message}</p>
    </div>
  );
};
