/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef, useState } from 'react';
import { toNDC } from '../../util/misc';
import { useDebounce } from './useDebounce';

const DEBOUNCE_MILLIS = 5;
const STILL_MILLIS = 100;

export type PointerType = {
  x: number;
  y: number;
  eventType:
    | 'pointermove'
    | 'pointerdown'
    | 'pointerup'
    | 'pointerenter'
    | 'pointerleave'
    | 'wheel'
    | 'click'
    | 'still';
  shiftKey: boolean;
  ctrlKey: boolean;
  deltaX: number;
  deltaY: number;
};

// Unify events to PointerType
const getPointer = (
  e: PointerEvent | MouseEvent | WheelEvent,
  canvas: HTMLCanvasElement,
): PointerType => {
  // client space (origin at lower left corner ) to NDC [-1,+1]
  const { ctrlKey, shiftKey, clientX, clientY, type: eventType } = e;
  // constrain point to canvas
  const { left, top, right, bottom } = canvas.getBoundingClientRect();
  const constrainedX = Math.max(Math.min(clientX, right), left);
  const constrainedY = Math.min(Math.max(clientY, top), bottom);
  // to normalized device coordinates [-1,+1]
  const { x, y } = toNDC(constrainedX, constrainedY, canvas);
  const pointer = {
    eventType,
    x,
    y,
    ctrlKey,
    shiftKey,
    deltaX: 0,
    deltaY: 0,
  } as PointerType;
  if (eventType === 'wheel') {
    pointer.deltaX = (e as WheelEvent).deltaX;
    pointer.deltaY = (e as WheelEvent).deltaY;
  }
  return pointer;
};

export const usePointerEvents = (
  canvas: HTMLCanvasElement,
  handler: (pointer: PointerType) => void,
): React.Dispatch<React.SetStateAction<boolean>> => {
  const [isActive, setActive] = useState(false);
  const timeoutID = useRef(0);

  const still = (pointer: PointerType) => {
    // start timer to send an end of stream
    clearTimeout(timeoutID.current);
    timeoutID.current = window.setTimeout(() => {
      handler({ ...pointer, eventType: 'still' });
    }, STILL_MILLIS);
  };

  const handlerMove = useDebounce(
    (e: PointerEvent | WheelEvent | MouseEvent) => {
      const pointer = getPointer(e, canvas);
      still(pointer);
      handler(pointer);
    },
    DEBOUNCE_MILLIS,
    [handler, isActive],
  );

  useEffect(() => {
    if (!isActive) return;
    const onPointerEvent = (
      e: PointerEvent | WheelEvent | MouseEvent,
    ): void => {
      const pointer = getPointer(e, canvas);
      still(pointer);
      handler(pointer);
    };

    document.addEventListener('pointerup', onPointerEvent);
    document.addEventListener('pointerdown', onPointerEvent);
    document.addEventListener('pointermove', handlerMove);
    document.addEventListener('wheel', onPointerEvent, { passive: true });
    document.addEventListener('click', onPointerEvent);
    canvas.addEventListener('pointerleave', onPointerEvent);
    canvas.addEventListener('pointerenter', onPointerEvent);

    return () => {
      document.removeEventListener('pointerup', onPointerEvent);
      document.removeEventListener('pointerdown', onPointerEvent);
      document.removeEventListener('pointermove', handlerMove);
      document.removeEventListener('wheel', onPointerEvent);
      document.removeEventListener('click', onPointerEvent);
      canvas.removeEventListener('pointerleave', onPointerEvent);
      canvas.removeEventListener('pointerenter', onPointerEvent);
    };
  }, [isActive, handler]);

  return setActive;
};
