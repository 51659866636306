/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { Vertex3JS } from '../zone/Vertex3JS';

export class Measure3JS extends Vertex3JS {
  public readonly isMeasure3JS = true;
  private label: CSS2DObject;
  private labelElem: HTMLDivElement;
  private labelParam: {
    x: HTMLDivElement;
    y: HTMLDivElement;
  };

  constructor(name: string) {
    super();
    this.scaleFactor = 0.2;
    this.name = `Measure3JS-${name}`;

    this.labelElem = document.createElement('div');
    // this.labelElem.className = 'gui-label';
    this.labelElem.innerHTML = `
    <div class='measure text-md flex flex-col items-center px-4 py-2 drop-shadow-md'
         style='color: var(--ideal); position:relative; top:-40px;'
    >
      <div class='flex justify-between mb-1'
           style='width:var(--r16);'>
          <div>X:</div>
          <div class='x'></div>
      </div>
      <div class='flex justify-between'
           style='width:var(--r16);'>
          <div>Y:</div>
          <div class='y'></div>
      </div>
    </div>
    `;

    this.labelParam = {
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      x: this.labelElem.querySelector('.x')!,
      y: this.labelElem.querySelector('.y')!,
      /* eslint-enable  @typescript-eslint/no-non-null-assertion */
    };

    this.label = new CSS2DObject(this.labelElem);
    this.add(this.label);
    this.labelParam.x.innerText = '0';
  }

  public setLabel(): void {
    this.labelParam.x.innerText = `${this.position.x.toFixed(2)}`;
    this.labelParam.y.innerText = `${this.position.y.toFixed(2)}`;
  }

  public showLabel(value: boolean): void {
    if (value) this.add(this.label);
    else this.remove(this.label);
  }
}
