/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Palettes } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import { Palette as PaletteType } from '../../types';
import { AppDefaultState } from '../AppStore';
import { ColorDot } from './ColorDot';
import { ResetButton } from './ResetButton';

export const PaletteSelect = (): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col">
      <ResetButton
        isVisible={AppDefaultState.palette !== state.app.palette}
        onClick={() =>
          dispatch({
            type: 'setPalette',
            value: 'default',
          })
        }
        tooltip={{ offset: 'right', text: 'Reset Palette' }}
      >
        <select
          className="grow"
          name="color-themes"
          value={state.app.palette}
          onChange={(e) => {
            const palette = e.currentTarget.value as PaletteType;
            dispatch({
              type: 'setPalette',
              value: palette,
            });
          }}
        >
          {Object.keys(Palettes).map((palette) => (
            <option key={palette} value={palette}>
              {palette}
            </option>
          ))}
        </select>
      </ResetButton>

      <div className="flex wrap my-2 justify-between">
        {/* 12 = primary+secondary+tertiary -  we define colors of multiple of twelves*/}
        {Palettes[state.app.palette].slice(0, 12).map((color, i) => (
          <ColorDot
            color={color}
            variant="rect"
            key={`palette-${i}-${color}`}
          />
        ))}
      </div>
    </div>
  );
};
