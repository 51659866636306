/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Euler, Quaternion, Vector3 } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { OType, PerceptionClassification, Context } from '../types';
import { ATTRIBUTE_NAME, SECONDS_TO_uS } from '../constants';
import { TrackedObjectLabels, VelocityLabelUnits } from './TrackedObjectsStore';
import { ObjectKeys } from '../util/misc';
import { radToDeg } from 'three/src/math/MathUtils';

// These rows should be hidden for any cluster labels
const nonClusterRows = ['lifetime', 'velocity'] as const;

const pool: TrackedLabel3JS[] = [];

export const getLabelFromPool = (
  classification: PerceptionClassification,
  id: string,
  position: Vector3,
  dimensions: Vector3,
  rotation: Quaternion,
  velocity: Vector3,
  creationTs: number,
  updateTs: number,
  extraText: string,
  labelVisibilitiesByType: Record<TrackedObjectLabels, boolean>,
  velocityUnits: VelocityLabelUnits,
): TrackedLabel3JS => {
  let obj: TrackedLabel3JS | undefined;
  if (pool.length > 0) {
    obj = pool.pop();
  }
  if (!obj) obj = new TrackedLabel3JS();
  obj.set(
    classification,
    id,
    position,
    dimensions,
    rotation,
    velocity,
    creationTs,
    updateTs,
    extraText,
    labelVisibilitiesByType,
    velocityUnits,
  );
  obj.visible = true;
  return obj;
};

export const putLabelToPool = (obj: TrackedLabel3JS): void => {
  obj.visible = false;
  pool.push(obj);
};

export const setColor = (
  classification: PerceptionClassification,
  style: string,
  context: Context,
): void => {
  const rule = context.labelsCSSRules[classification];
  if (rule) rule.style.color = style;
};

const setRowVisible = (
  element: HTMLElement,
  rowName: string,
  isVisible: boolean,
) => {
  const row = element.querySelector(`#${rowName}-row`) as HTMLTableRowElement;
  if (row) {
    row.style.display = isVisible ? '' : 'none';
  }
};

const formatLifetime = (lifetime: number): string => {
  return lifetime.toFixed(1) + ' s';
};

const velocityConversionFactor: Record<VelocityLabelUnits, number> = {
  'km/h': 3.6,
  'm/s': 1,
  mph: 2.23694,
} as const;
const formatVelocity = (
  velocity: Vector3,
  velocityUnits: VelocityLabelUnits,
): string => {
  const vec = velocity.clone().setZ(0); // Ignore z axis velocity
  const magnitude = vec.length() * velocityConversionFactor[velocityUnits]; // Converts from m/s to specified units
  const direction = radToDeg(Math.atan2(vec.y, vec.x)); // Converts from rad to deg in range [-180, 180]
  return (
    magnitude.toFixed(1) + ` ${velocityUnits}, ` + direction.toFixed(0) + '°'
  );
};

const formatVector3 = (position: Vector3): string => {
  return (
    '[' +
    position.x.toFixed(2) +
    ', ' +
    position.y.toFixed(2) +
    ', ' +
    position.z.toFixed(2) +
    '] m'
  );
};

const formatHeading = (rotation: Quaternion): string => {
  // Constructing the entire Euler is somewhat expensive just
  // to extract heading. Ideally, we implement the conversion ourselves but
  // leaving as is for now since heading is only exposed in developer mode.
  const heading = radToDeg(new Euler().setFromQuaternion(rotation).z);
  return heading.toFixed(0) + '°';
};

export class TrackedLabel3JS extends CSS2DObject implements OType {
  private labelVisibilitiesByType: Record<TrackedObjectLabels, boolean>;
  private labelPos: {
    trackedClass: HTMLElement;
    id: HTMLDivElement;
    position: HTMLDivElement;
    velocity: HTMLSpanElement;
    lifetime: HTMLSpanElement;
    dimensions: HTMLSpanElement;
    heading: HTMLSpanElement;
    extraText: HTMLSpanElement;
  };
  private properties: {
    velocity: Vector3;
    position: Vector3;
    lifetime: number;
    dimensions: Vector3;
    rotation: Quaternion;
    extraText: string;
  };
  private classification: PerceptionClassification = 'Unknown';
  private velocityUnits: VelocityLabelUnits = 'km/h';

  public readonly oType: string;
  public readonly isTrackedLabel3JS = true;
  public oId: string;

  constructor() {
    super(document.createElement('div'));
    this.oType = 'TrackedLabel3JS';
    this.oId = 'none';
    this.properties = {
      velocity: new Vector3(0, 0, 0),
      position: new Vector3(0, 0, 0),
      lifetime: 0,
      dimensions: new Vector3(0, 0, 0),
      rotation: new Quaternion(0, 0, 0, 0),
      extraText: '',
    };
    this.labelVisibilitiesByType = {
      position: false,
      dimensions: false,
      velocity: false,
      heading: false,
      lifetime: false,
      'extra-text': false,
    };

    // TODO(emmanuel): fade out when far away, factor out to template
    this.element.className = 'gui-label';
    this.element.innerHTML = `
    <div id="details">
      <div class="gui-row gui-header">
        <div id="trackedClass"></div>
        <div id="id"></div>
      </div>
      <div class="gui-row" id="position-row">
        <div class="gui-key mr-1">Position:</div>
        <div id="gui-position" class="gui-value"></div>
      </div>
      <div class="gui-row" id="velocity-row">
        <div class="gui-key mr-1">Velocity:</div>
        <div id="gui-velocity" class="gui-value"></div>
      </div>
      <div class="gui-row" id="lifetime-row">
        <div class="gui-key mr-1">Lifetime:</div>
        <div id="gui-lifetime" class="gui-value"></div>
      </div>
      <div class="gui-row" id="dimensions-row">
        <div class="gui-key mr-1">Dimensions:</div>
        <div id="gui-dimensions" class="gui-value"></div>
      </div>
      <div class="gui-row" id="heading-row">
        <div class="gui-key mr-1">Heading:</div>
        <div id="gui-heading" class="gui-value"></div>
      </div>
      <div class="gui-row" id="extra-text-row">
        <div class="gui-key mr-1">Text:</div>
        <div id="gui-extra-text" class="gui-value">''</div>
      </div>
    </div>
    `;

    this.labelPos = {
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      trackedClass: this.element.querySelector('#trackedClass')!,
      id: this.element.querySelector('#id')!,
      position: this.element.querySelector('#gui-position')!,
      velocity: this.element.querySelector('#gui-velocity')!,
      dimensions: this.element.querySelector('#gui-dimensions')!,
      lifetime: this.element.querySelector('#gui-lifetime')!,
      heading: this.element.querySelector('#gui-heading')!,
      extraText: this.element.querySelector('#gui-extra-text')!,
      /* eslint-enable  @typescript-eslint/no-non-null-assertion */
    };
  }

  public set = (
    classification: PerceptionClassification,
    id: string,
    position: Vector3,
    dimensions: Vector3,
    rotation: Quaternion,
    velocity: Vector3,
    creationTs: number,
    updateTs: number,
    extraText: string,
    labelVisibilitiesByType: Record<TrackedObjectLabels, boolean>,
    velocityUnits: VelocityLabelUnits,
  ): void => {
    this.name = id.toString();
    this.oId = id;
    this.classification = classification;
    this.labelVisibilitiesByType = labelVisibilitiesByType;

    this.setLifetime(creationTs, updateTs);
    this.setPosition(position, dimensions);
    this.velocityUnits = velocityUnits;
    this.properties.extraText = extraText;
    this.properties.rotation = rotation;
    this.properties.velocity = velocity;

    this.setRowVisibilities();
    this.populateLabel();
  };

  private setRowVisibilities = (): void => {
    ObjectKeys(this.labelVisibilitiesByType).forEach((rowName) =>
      setRowVisible(
        this.element,
        rowName,
        this.labelVisibilitiesByType[rowName],
      ),
    );
    const isCluster = this.classification === 'Cluster';
    nonClusterRows.forEach((rowName) =>
      setRowVisible(
        this.element,
        rowName,
        !isCluster && this.labelVisibilitiesByType[rowName],
      ),
    );
    setRowVisible(
      this.element,
      'extra-text',
      this.properties.extraText !== '' &&
        this.labelVisibilitiesByType['extra-text'],
    );
  };

  private populateLabel = (): void => {
    /* Meta */
    this.labelPos.trackedClass.innerText = this.classification;
    this.element.setAttribute(ATTRIBUTE_NAME, this.classification);

    /* Values */
    this.labelPos.id.innerText = this.oId;
    this.labelPos.lifetime.innerHTML = formatLifetime(this.properties.lifetime);
    this.labelPos.dimensions.innerText = formatVector3(
      this.properties.dimensions,
    );
    this.labelPos.position.innerHTML = formatVector3(this.properties.position);
    // Heading and velocity are somewhat expensive to format.
    // Only do it if they are visible.
    if (this.labelVisibilitiesByType.heading) {
      this.labelPos.heading.innerHTML = formatHeading(this.properties.rotation);
    }
    if (this.labelVisibilitiesByType.velocity) {
      this.labelPos.velocity.innerHTML = formatVelocity(
        this.properties.velocity,
        this.velocityUnits,
      );
    }
    this.labelPos.extraText.innerText = this.properties.extraText;
  };

  public setLifetime = (creationTs: number, updateTs: number): void => {
    this.properties.lifetime = (updateTs - creationTs) / SECONDS_TO_uS;
  };

  public setPosition = (position: Vector3, dimensions: Vector3): void => {
    const { x, y, z } = position;
    this.position.set(x, y, z + 0.5 * dimensions.z);
    this.properties.position = position;
    this.properties.dimensions = dimensions;
  };
}
