/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppState } from '../Stores';
import { Context } from '../types';
import { Preset } from '../app/components/pane/cloudsDisplay/CloudColorPresets';
import { Palettes } from '../constants';

export const useSourceAppearance = (context: Context): void => {
  const state = useAppState();
  const palette = Palettes[state.app.palette];

  // Sensor's Cloud Display
  useEffect(() => {
    const { colorMode, colorMin, colorMax, colorRange } = state.sensors.display;
    const { min, max } = colorRange[colorMode];
    for (const [id, sensor] of Object.entries(context.instances.Source.all)) {
      sensor.cloud.setColorMode(colorMode);
      const preset: Preset = {
        name: 'not used',
        colorMin: palette[colorMin[id]],
        colorMax: palette[colorMax[id]],
        min,
        max,
      };
      sensor.cloud.setPreset(preset);
      sensor.setColor(palette[colorMin[id]]);
    }
  }, [
    state.sensors.display.colorRange,
    state.sensors.display.colorMax,
    state.sensors.display.colorMin,
    state.sensors.display.colorMode,
    state.app.palette,
  ]);

  // Node's Cloud Display
  useEffect(() => {
    const { colorMode, colorMin, colorMax, colorRange } = state.nodes.display;
    const { min, max } = colorRange[colorMode];
    for (const [id, node] of Object.entries(context.instances.Source.all)) {
      node.cloud.setColorMode(colorMode);
      const preset: Preset = {
        name: 'not used',
        colorMin: palette[colorMin[id]],
        colorMax: palette[colorMax[id]],
        min,
        max,
      };
      node.cloud.setPreset(preset);
      node.setColor(palette[colorMin[id]]);
    }
  }, [
    state.nodes.display.colorRange,
    state.nodes.display.colorMax,
    state.nodes.display.colorMin,
    state.nodes.display.colorMode,
    state.app.palette,
  ]);

  // Background's Min and Max Height Range
  useEffect(() => {
    const min = state.clouds.Background.display.heightMin;
    const max = state.clouds.Background.display.heightMax;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [id, source] of Object.entries(context.instances.Source.all)) {
      source.cloud.setHeightRange({ min, max });
    }
  }, [
    state.clouds.Background.display.heightMin,
    state.clouds.Background.display.heightMax,
  ]);

  // Source's Point Cloud Size
  useEffect(() => {
    for (const [id, sensor] of Object.entries(context.instances.Source.all)) {
      const size =
        typeof state.sensors.pointSize[id] === 'number'
          ? state.sensors.pointSize[id]
          : state.nodes.pointSize[id];
      sensor.cloud.setPointSize(size);
    }
  }, [state.sensors.pointSize, state.nodes.pointSize]);

  // Source Visibility
  useEffect(() => {
    for (const [id, source] of Object.entries(context.instances.Source.all)) {
      const sources = state.sensors.allIds.includes(id)
        ? state.sensors
        : state.nodes;
      const isAdded =
        sources.addedStates[id] !== undefined && sources.addedStates[id];
      const isVisible = sources.visibilities[id];
      const isSetup = state.app.mode === 'setup';
      const hasExtrinsic = sources.extrinsics[id] !== null;
      source.visible = isAdded && isSetup && isVisible && hasExtrinsic;
    }
  }, [
    state.sensors.addedStates,
    state.sensors.visibilities,
    state.sensors.extrinsics,
    state.nodes.addedStates,
    state.nodes.visibilities,
    state.nodes.extrinsics,
    state.app.mode,
  ]);
};
