/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Matrix4, Quaternion, Vector2, Vector3 } from 'three';
import { SourceType, ZoneAlert } from '../api/alerts';
import { DEFAULT_REQUEST_TIMEOUT } from '../constants';
import { Extrinsic, Pose, TransformPayload, TypedArray } from '../types';

/**
 * Simple utility fn to get a pseudo numerical global Id
 * @returns number
 */
export const getGUID = (): number => Date.now();

/**
 * Checks whether the supplied string only
 * contains letters or numbers.
 * @param s string, the string to check.
 * @returns boolean, true if alpha numeric. False otherwise.
 */
export const isAlphaNumeric = (s: string): boolean => {
  const code = s.charCodeAt(0);
  return (
    (code > 47 && code < 58) || // numeric (0 - 9)
    (code > 64 && code < 91) || // upper alpha (A - Z)
    (code > 96 && code < 123) // lower alpha (a - z)
  );
};

/**
 * Utility fn to convert from viewport space to NDC (Normalized Device Coordinates)
 * Viewport Y axis is top to down and origin is at the top left
 * NDC Y axis points up and origin is at the center of the viewport
 * @param clientX The x value of the pointer relative to the viewport
 * @param clientY The x value of the pointer relative to the viewport
 * @param canvas
 * @returns Normalized coordinates in device space
 */
export const toNDC = (
  clientX: number,
  clientY: number,
  canvas: HTMLCanvasElement,
): { x: number; y: number } => {
  const {
    left: l,
    top: t,
    width: w,
    height: h,
  } = canvas.getBoundingClientRect();
  const x = ((clientX - l) / w) * 2 - 1;
  const y = ((clientY - t) / h) * -2 + 1;
  return { x, y };
};

/**
 * Create a Matrix4 from a position and a quantitation array
 * All array elements must be in alphabetical order w,x,y,z
 * @param translation : [x,y,z]
 * @param quaternionRotation: [w, x, y, z]
 * @returns Matrix4
 */
export const getTransform = (
  translation: [number, number, number],
  quaternionRotation: [number, number, number, number],
): Matrix4 => {
  const pos = new Vector3().fromArray(translation);
  const [w, x, y, z] = quaternionRotation;
  const quart = new Quaternion(x, y, z, w);
  const m = new Matrix4();
  m.setPosition(pos);
  m.makeRotationFromQuaternion(quart);
  return m;
};

/**
 * Extract Pose from matrix
 * @param Matrix4
 * @returns Pose
 */
export const getPose = (m: Matrix4): Pose => {
  const rot = new Quaternion();
  const pos = new Vector3();
  const notUsed = new Vector3();
  m.decompose(pos, rot, notUsed);
  return { pos, rot };
};

/**
 * Returns the value of a json node
 * given it's path from the root.
 * @param json Record<string, any>, the json.
 * @param path string[], path of field to desired node.
 * @returns any, the value at that node.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const jsonValueFromPath = (
  json: Record<string, any>,
  path: string[],
): any => {
  let value = json;

  path.forEach((key) => {
    value = value[key];
    if (value === undefined) return value;
  });

  return value;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
/**
 * Converts a unix timestamp into a formatted
 * date and time.
 * @param timeStamp number, the timestamp.
 * @returns string, the formatted date and time.
 */
const NOON = 12;
export const unixToString = (
  timeStamp: number,
  options: { year?: boolean; time?: boolean } = { year: true, time: true },
): string => {
  const date = new Date(timeStamp);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  let hour = date.getHours();

  // Convert 24H to 12H time
  let amPm = 'AM';
  if (hour >= NOON) {
    hour = hour % NOON || NOON;
    amPm = 'PM';
  } else if (hour === 0) {
    hour = NOON;
  }

  const min = date.getMinutes().toString(10).padStart(2, '0');

  return `${month}. ${day} ${options.year ? ', ' + year : ''}${
    options.time ? ` at ${hour}:${min} ${amPm}` : ''
  }`;
};

/**
 * Converts the specified timestamp into iso string
 * representation without the milliseconds.
 * @param timestamp the timestamp to convert.
 * @returns the iso string without milliseconds
 */
export const toISOStringWithoutMS = (timestamp: number): string =>
  new Date(timestamp).toISOString().split('.')[0] + 'Z';

/**
 * Checks whether the line segments
 * defined by the specified endpoints intersect.
 * @param pA, first point on first segment.
 * @param pB, second point on first segment.
 * @param pC, first point on second segment.
 * @param pD, second point on second segment.
 * @returns true if the line segments intersect, false otherwise.
 */
export const linesIntersect = (
  pA: Vector2,
  pB: Vector2,
  pC: Vector2,
  pD: Vector2,
): boolean => {
  const vAB = pB.clone().sub(pA);
  const vCD = pD.clone().sub(pC);
  const vCA = pA.clone().sub(pC);
  const det = vAB.cross(vCD);
  // Parallel
  if (det === 0) return false;

  /*
    Line 1: pA + t(vAB)
    Line 2: pC + s(vCD), t and s ∈ [0, 1]
    Set pA + t(vAB) = pc + s(vCD) then
    the line segments intersect if t and s
    are both between 0 and 1.
  */
  const rec = 1 / det;
  const t = rec * vAB.cross(vCA);
  const s = rec * vCD.cross(vCA);

  return 0 < s && s < 1 && 0 < t && t < 1;
};

/**
 * Checks the orientation of the ordered
 * triplet p->q->r.
 * @param p the first point.
 * @param q the second point.
 * @param r the third point.
 * @returns the orientation of the points.
 */
const orientation = (p: Vector2, q: Vector2, r: Vector2) => {
  /*
    Find the slope (m) of pq and qr. If the slopes are equal,
    points are collinear. if m_pq > m_qr, they're clockwise. Else counterclockwise.
    m_pq > m_qr <=> m_pq - m_qr > 0 <=> pq_y / pq_x - qr_y / qr_x > 0 <=> pq_y * qr_x - qr_y * pq_x > 0
  */
  const delta_m = (q.y - p.y) * (r.x - q.x) - (r.y - q.y) * (q.x - p.x);
  if (delta_m === 0) return 'collinear';
  return delta_m > 0 ? 'clockwise' : 'counterclockwise';
};

/**
 * Finds the convex hull of the specified points using Jarvis March.
 * @param points the points to find the convex hull of.
 * @returns the points in the convex hull and the hull's centroid.
 */
export const convexHull = (
  points: Vector2[],
): { hull: Vector2[]; centroid: Vector2 } => {
  if (points.length < 3) {
    throw new Error(
      `convexHull(points): Attempted to create hull with ${points.length} < 3 points.`,
    );
  }

  const hull = [] as Vector2[];

  // Find the left most point
  let minXIdx = 0;
  for (let i = 1; i < points.length; i++) {
    if (points[i].x < points[minXIdx].x) {
      minXIdx = i;
    }
  }

  /* Starting with the left most point,
    Iterate through the remaining points and 
    add the most counter clockwise one until
    we're back to where we started
  */
  let pIdx = minXIdx; // The point to add to the hull
  let qIdx: number; // Most ccw we've seen so far
  let sumX = 0;
  let sumY = 0;
  do {
    hull.push(points[pIdx]);
    sumX += points[pIdx].x;
    sumY += points[pIdx].y;

    qIdx = (pIdx + 1) % points.length;
    for (let i = 0; i < points.length; i++) {
      // Check if i is more ccw than q
      if (
        orientation(points[pIdx], points[qIdx], points[i]) ===
        'counterclockwise'
      ) {
        qIdx = i;
      }
    }

    pIdx = qIdx;
  } while (pIdx !== minXIdx);

  return {
    hull,
    centroid: new Vector2(sumX / hull.length, sumY / hull.length),
  };
};

/**
 * Validates whether the specified string is a
 * valid ipv4 address.
 * @param ip string, the ip to validate.
 * @returns boolean, true if valid or false otherwise.
 */
export const isValidIp = (ip: string): boolean => {
  // Regular expression to check if string is a IP address
  const regexExp =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;

  return regexExp.test(ip);
};

/**
 * Validates whether the specified string represents a non-negative integer (used to validate a port).
 * @param numberString  string, the number to validate.
 * @returns boolean, true if valid and false otherwise.
 */
export const isNonNegativeIntegerString = (numberString: string): boolean => {
  const regexExp = /^[0-9]\d*$/;
  return regexExp.test(numberString);
};

/**
 * Validates a valid sensor hostname which satisfies
 * the requirements of a hostname that it only includes only alpha numeric characters, dashes, and periods.
 * @param hostname the hostname to validate.
 * @returns boolean, true if valid or false otherwise.
 */
export const isValidSourceHostname = (hostname: string): boolean => {
  const regexExp =
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])(\.)?$/;

  return regexExp.test(hostname);
};

/**
 * Validates whether the specified string is a
 * link local.
 * @param ip string, the ip to validate.
 * @returns boolean, true if valid or false otherwise.
 */
export const isLinkLocal = (ip: string): boolean => {
  const ipv4LinkLocalRegex = /^169\.254\.\d{1,3}\.\d{1,3}$/;
  const ipv6LinkLocalRegex = /^fe80::\w{0,4}(:\w{0,4}){0,6}%\d{1,2}$/;

  return ipv4LinkLocalRegex.test(ip) || ipv6LinkLocalRegex.test(ip);
};

export const probablySensorAddress = (address: string): boolean => {
  return /^(os-)/.test(address);
};

/**
 * Returns the source type for the specified alert code. Firmware
 * (sensor-specific) alerts begin with "0x01" and system alerts
 * begin with "0x02"
 * @param alertCode string, the alert's code.
 * @returns SourceType, the alert's source type.
 */
export const getAlertSourceType = (alertCode: string): SourceType => {
  return alertCode.charAt(3) === '2' ? 'system' : 'sensor';
};

/**
 * Checks whether the specified alerts are equal. Don't consider the
 * occupancy because that can change and still be the same alert.
 * @param a the first alert.
 * @param b the second alert.
 */
export const isZoneAlertsEqual = (a: ZoneAlert, b: ZoneAlert): boolean =>
  a.id === b.id &&
  a.name === b.name &&
  a.severity === b.severity &&
  a.message === b.message;

/**
 * Filters the specified array in place
 * @param arr the array to filter.
 * @param condition predicate to filter with. Items
 * the predicate fails on are removed.
 */
export const filterInPlace = <T>(
  arr: T[],
  condition: (elem: T, idx?: number, arr?: T[]) => boolean,
): T[] => {
  let i = 0;
  let j = 0;

  while (i < arr.length) {
    if (condition(arr[i], i, arr)) arr[j++] = arr[i];
    i++;
  }
  arr.length = j;
  return arr;
};

/*
 * Returns a promise that resolves after the
 * specified delay. A reason can optionally be
 * specified to make the promise reject, returning the
 * specified reason.
 *
 * You can use this to model a sleep by awaiting it:
 * sleep(5s) <=> await awaitTimeout(5000)
 * @param delay number, the delay in milliseconds.
 * @param reason [optional], the reason the promise will reject with.
 * @returns the promise.
 */
export const awaitTimeout = (
  delay: number,
  reason?: string,
): Promise<string | null> =>
  new Promise((resolve, reject) =>
    setTimeout(
      () => (reason === undefined ? resolve(null) : reject(reason)),
      delay,
    ),
  );

/**
 * Applies a timeout to the specified promise request.
 * @param promise the promise to timeout.
 * @param reason the reason to return if the promise times out.
 * @param delay the timeout delay.
 * @returns the timed out request.
 */
export const timeoutRequest = <T>(
  promise: Promise<T | null>,
  reason: string,
  delay: number = DEFAULT_REQUEST_TIMEOUT,
): Promise<T | string | null> =>
  Promise.race([promise, awaitTimeout(delay, reason)]);

/* Type Utility Fns */
type AnyObject = Record<string, unknown>;

// Type support for object keys
export type Keys<T> = (keyof T)[];
export function ObjectKeys<Obj extends AnyObject>(obj: Obj): Keys<Obj> {
  return Object.keys(obj) as Keys<Obj>;
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
// Type support for object keys in Object.entries()
export function ObjectEntries<Obj extends AnyObject>(obj: Obj): Entries<Obj> {
  return Object.entries(obj) as Entries<Obj>;
}

// Helper to make certain type fields optional
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

/* 
 String keys that are numbers are cast to number, this helper
 will only return the string keys of an object 
 https://stackoverflow.com/questions/51808160/keyof-inferring-string-number-when-key-is-only-a-string
 */
export type StringKeyOf<T extends Record<string, unknown>> = Extract<
  keyof T,
  string
>;

/**
 * truncates a string from to the specified length.
 * @param str the string to truncate if it's longer than maxLength
 * @param maxLength total length of the string with ellipsis
 * @param ellipsesAt where to put the ellipsis
 * @returns formatted string
 */
export const trimTo = (
  str: string,
  maxLength: number,
  ellipsesAt: 'start' | 'end' = 'end',
): string => {
  if (str.length > maxLength) {
    if (ellipsesAt === 'start') {
      return `...${str.slice(str.length - maxLength + 3)}`;
    }
    return `${str.slice(0, maxLength - 3)}...`;
  }
  return str;
};

export const getTransformPayloadFromElements = (
  source_frame: string,
  p_x: number,
  p_y: number,
  p_z: number,
  q_w: number,
  q_x: number,
  q_y: number,
  q_z: number,
): TransformPayload => {
  return {
    source_frame,
    destination_frame: 'world',
    p_x,
    p_y,
    p_z,
    q_w,
    q_x,
    q_y,
    q_z,
  };
};

export const concatenateTypedArrays = (
  a: TypedArray,
  b: TypedArray,
): TypedArray => {
  if (typeof a !== typeof b) {
    throw new Error('Input arrays must have the same type.');
  }
  const result = new (a.constructor as new (length: number) => TypedArray)(
    a.length + b.length,
  );
  result.set(a);
  result.set(b, a.length);
  return result;
};

export const getTransformPayload = (
  source_frame: string,
  position: Vector3,
  quaternion: Quaternion,
): TransformPayload => {
  return {
    source_frame,
    destination_frame: 'world',
    p_x: position.x,
    p_y: position.y,
    p_z: position.z,
    q_w: quaternion.w,
    q_x: quaternion.x,
    q_y: quaternion.y,
    q_z: quaternion.z,
  };
};

export const parseJSONExtrinsic = (json: unknown): Extrinsic => {
  const { p_x, p_y, p_z, q_x, q_y, q_z, q_w, source_frame, destination_frame } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    json as any;
  const id = source_frame.toString();
  const dest = destination_frame.toString();
  const pos = new Vector3(p_x, p_y, p_z);
  const rot = new Quaternion(q_x, q_y, q_z, q_w);
  return { pos, rot, id, dest };
};
