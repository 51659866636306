/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from '../app/components/Button';
import { ColorDot } from '../app/components/ColorDot';
import { IconX } from '../app/components/IconX';
import { EyeToggle } from '../app/components/pane/eye/Visible';
import { ListContainer } from '../app/components/pane/ListContainer';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { VisibilityIcon } from '../app/components/visibilities/VisibilityIcon';
import { Palettes } from '../constants';
import { useAppState, useAppDispatch } from '../Stores';

export const SelectedTrackedObjs = ({
  ids,
}: {
  ids: string[];
}): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const palette = Palettes[state.app.palette];

  return (
    <PropertiesSubGroup name="Selected">
      <ListContainer name={`Total: ${ids.length}`}>
        {ids.map((id) => {
          const params = state.tracked.byId[id];
          const colorIndex =
            state.tracked.classificationColors[params.classification];
          const color = palette[colorIndex];

          return (
            <div className="flex items-center justify-between mb-1" key={id}>
              <div className="flex">
                <EyeToggle
                  tooltip={{
                    text: state.tracked.visible[id] ? 'Hide' : 'Display',
                    offset: 'right',
                  }}
                  isOn={state.tracked.visible[id]}
                  onClick={() => {
                    dispatch({
                      type: 'setTrackedInstanceBoolProp',
                      ids: [id],
                      property: 'visible',
                      value: 'toggle',
                    });
                  }}
                />

                <Button
                  className="p-0_5 borderNone"
                  disabled={!state.tracked.visible[id]}
                  tooltip={{
                    text: state.tracked.labelVisible[id]
                      ? 'Hide Label'
                      : 'Display Label',
                    offset: 'right',
                  }}
                  onClick={() => {
                    dispatch({
                      type: 'setTrackedInstanceBoolProp',
                      ids: [id],
                      property: 'labelVisible',
                      value: 'toggle',
                    });
                  }}
                >
                  <VisibilityIcon
                    cluster="Tracked"
                    icon="labels"
                    active={state.tracked.labelVisible[id]}
                  />
                </Button>
              </div>

              <p className="text-xs textGreyC">{params.classification}</p>

              <Button
                name={id}
                disabled={false}
                onClick={() =>
                  dispatch({
                    type: 'setTrackedInstanceBoolProp',
                    ids: [id],
                    property: 'selected',
                    value: 'toggle',
                  })
                }
                tooltip={{
                  text: 'Deselect',
                  offset: 'left',
                }}
                style={{ padding: 0 }}
              >
                <ColorDot color={color} />
                <p className="text-md mr-1">{id}</p>
                <IconX active />
              </Button>
            </div>
          );
        })}
      </ListContainer>
    </PropertiesSubGroup>
  );
};
