/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

export type RecordingState = {
  isRecording: boolean;
  isValidSettings: boolean;
  recordingStartedTS: Date | null;
  recordingName: string;
  recordingNamePrefix: string;
  recordingLengthSeconds: number;
};

export const RecordingInitialState: RecordingState = {
  isRecording: false,
  isValidSettings: true,
  recordingStartedTS: null,
  recordingName: '',
  recordingNamePrefix: '',
  recordingLengthSeconds: 300,
};

export type RecordingFromTelemetrySchema = Omit<
  RecordingState,
  'recordingNamePrefix'
>;

export type RecordingActions =
  | {
      type: 'setIsRecording';
      value: boolean;
    }
  | {
      type: 'setRecordingStartedTS';
      value: Date | null;
    }
  | {
      type: 'setRecordingName';
      value: string;
    }
  | {
      type: 'setRecordingNamePrefix';
      value: string;
    }
  | {
      type: 'setRecordingLengthSeconds';
      value: number;
    }
  | {
      type: 'setInputModeFromTelemetry';
      schema: RecordingFromTelemetrySchema;
    };

export const RecordingReducer = (
  state: RecordingState,
  action: RecordingActions,
): RecordingState => {
  switch (action.type) {
    case 'setIsRecording': {
      if (state.isRecording === action.value) return state;
      return { ...state, isRecording: action.value };
    }

    case 'setRecordingStartedTS': {
      if (state.recordingStartedTS === action.value) return state;
      return { ...state, recordingStartedTS: action.value };
    }

    case 'setRecordingName': {
      if (state.recordingName === action.value) return state;
      return { ...state, recordingName: action.value };
    }

    case 'setRecordingNamePrefix': {
      if (state.recordingNamePrefix === action.value) return state;
      return { ...state, recordingNamePrefix: action.value };
    }

    case 'setRecordingLengthSeconds': {
      if (state.recordingLengthSeconds === action.value) return state;
      return { ...state, recordingLengthSeconds: action.value };
    }

    case 'setInputModeFromTelemetry': {
      const { isRecording, recordingStartedTS, recordingName } = action.schema;

      const needsUpdate =
        isRecording !== state.isRecording ||
        recordingStartedTS?.getTime() !== state.recordingStartedTS?.getTime() ||
        recordingName !== state.recordingName;

      return needsUpdate
        ? {
            ...state,
            isRecording,
            recordingStartedTS,
            recordingName,
          }
        : state;
    }

    default:
      return state;
  }
};
