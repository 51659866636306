/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

// One interval but updating function

/* 
  setInterval goes against the React programming model. We want our callback
  to have updated dependencies but recreating our interval will reset its
  timer -- if dependencies update at a higher frequency than the interval, then
  it just won't work.
  Solution: Make one interval and maintain a ref of the callback, the callback
  changes and the interval will call the latest callback whenever it fires.
  Source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
*/

import { useEffect, useRef, useState } from 'react';
/**
 * useInterval hook that allows you to set an interval that can be toggled on and off
 * @param callback
 * @param delay
 * @param activeByDefault
 * @returns [active, setActive]:[boolean, React.Dispatch<React.SetStateAction<boolean>>]
 */
const useInterval = (
  callback: (...args: unknown[]) => unknown,
  delay: number,
  activeByDefault: boolean,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const savedCallback = useRef<(...args: unknown[]) => unknown>();
  const [active, setActive] = useState(activeByDefault);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!active) return;

    const tick = () => {
      savedCallback.current && savedCallback.current();
    };

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, active]);

  return [active, setActive];
};

export default useInterval;
