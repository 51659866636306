/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';

export const RadioIcon = ({
  selected,
  disabled,
  size = '18px',
  ...rest
}: {
  selected: boolean;
  disabled: boolean;
  size?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new -2 -2 26 26"
      viewBox="-2 -2 26 26"
      fill={
        disabled ? (selected ? 'var(--greyD)' : 'var(--greyE)') : 'var(--greyB)'
      }
      {...rest}
      style={{
        backgroundColor: 'transparent',
      }}
    >
      <path d="M0,0h24v24H0V0z" fill="none" />
      {
        {
          on: (
            <g>
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
              <circle cx="12" cy="12" r="5" />
            </g>
          ),
          off: (
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
          ),
        }[selected ? 'on' : 'off']
      }
    </svg>
  );
};
