/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

export type AlignPhase = 'none' | 'request' | 'evaluate' | 'approve' | 'reject';
export type PosePhase = 'none' | 'request';

export type SetupState = {
  selected: string | null;
  reference: string | null;
  alignPhase: AlignPhase;
  posePhase: PosePhase;
};

export const SetupInitialState: SetupState = {
  selected: null,
  reference: null,
  alignPhase: 'none',
  posePhase: 'none',
};

export type SetupActions =
  | { type: 'setSetupReset' }
  | { type: 'setSelectedSource'; id: string | null }
  | { type: 'setReferenceSource'; id: string | null }
  // Alignment async request
  | {
      type: 'setAlignPhase';
      value: AlignPhase;
    }
  // Set source's Pose async request
  | {
      type: 'setPosePhase';
      value: PosePhase;
    };

export const SetupReducer = (
  state: SetupState,
  action: SetupActions,
): SetupState => {
  switch (action.type) {
    case 'setSetupReset': {
      return SetupInitialState;
    }
    case 'setSelectedSource': {
      if (state.selected === action.id) return state;
      const reference = state.reference === action.id ? null : state.reference;
      return { ...state, selected: action.id, reference };
    }
    case 'setReferenceSource': {
      if (state.reference === action.id) return state;
      const selected = state.selected === action.id ? null : state.selected;
      return { ...state, reference: action.id, selected };
    }
    case 'setAlignPhase': {
      if (state.alignPhase === action.value) return state;
      return { ...state, alignPhase: action.value };
    }
    case 'setPosePhase': {
      if (state.posePhase === action.value) return state;
      return { ...state, posePhase: action.value };
    }
    default:
      return state;
  }
};
