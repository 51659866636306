/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-duplicate-string */

import React from 'react';
import { endpoints } from '../../../api/endpoints';
import { useAppState, useAppDispatch } from '../../../Stores';
import { Mode } from '../../../types';
import { Button } from '../Button';
import { PlayControlIcon } from './PlayControlIcon';
const { record } = endpoints;

const isViewer = new Set<Mode>([
  'viewer',
  'zone',
  'setup',
  'recording',
  'preferences',
  'map',
]);

export const PlayControl = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const isPlaying = state.app.isPlaying;
  const isInputPlayback = state.app.inputMode === 'playback';
  const isPCAP = state.app.inputMode === 'pcap';

  if (!(isViewer.has(state.app.mode) || isInputPlayback)) return <></>;
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        bottom: 0,
        transform: 'translate(-50%,0)',
        padding: '1rem',
        display: 'flex',
        alignItems: 'center',
        zIndex: 12,
      }}
    >
      <div className="flex items-center">
        {isInputPlayback && (
          <>
            <Button
              className="bgNone p-2 mr-4"
              tooltip={{ text: 'Beginning of Sequence', offset: 'top' }}
              onClick={() => {
                dispatch({
                  type: 'setPlaybackTimeIndex',
                  offset: 'absolute',
                  value: 0,
                });
              }}
            >
              <PlayControlIcon icon="Start" size="16px" />
            </Button>
            <Button
              className="bgNone p-2"
              tooltip={{ text: 'Ten Frames Backward', offset: 'top' }}
              onClick={() => {
                dispatch({
                  type: 'setPlaybackTimeIndex',
                  offset: 'relative',
                  value: -10,
                });
              }}
            >
              <PlayControlIcon icon="Backward" size="16px" />
            </Button>

            <Button
              className="bgNone p-2"
              tooltip={{ text: 'Previous Frame', offset: 'top' }}
              onClick={() => {
                dispatch({
                  type: 'setPlaybackTimeIndex',
                  offset: 'relative',
                  value: -1,
                });
              }}
            >
              <PlayControlIcon icon="Prev" size="16px" />
            </Button>
          </>
        )}

        <Button
          className="bgNone p-2"
          tooltip={{ text: isPlaying ? 'Pause' : 'Play', offset: 'top' }}
          onClick={() => {
            isPCAP && record.call(isPlaying ? 'pause' : 'play');
            dispatch({ type: 'setPlay', value: !isPlaying });
          }}
        >
          <PlayControlIcon icon={isPlaying ? 'Pause' : 'Play'} />
        </Button>

        {isPCAP && (
          <Button
            className="bgNone p-2"
            tooltip={{ text: 'Step', offset: 'top' }}
            onClick={() => {
              record.call('step');
              dispatch({ type: 'setPlay', value: false });
            }}
          >
            <PlayControlIcon icon="Next" size="16px" />
          </Button>
        )}
        {isInputPlayback && (
          <>
            <Button
              className="bgNone p-2"
              tooltip={{ text: 'Next Frame', offset: 'top' }}
              onClick={() => {
                dispatch({
                  type: 'setPlaybackTimeIndex',
                  offset: 'relative',
                  value: 1,
                });
              }}
            >
              <PlayControlIcon icon="Next" size="16px" />
            </Button>

            <Button
              className="bgNone p-2"
              tooltip={{ text: 'Ten Frames Forward', offset: 'top' }}
              onClick={() => {
                dispatch({
                  type: 'setPlaybackTimeIndex',
                  offset: 'relative',
                  value: 10,
                });
              }}
            >
              <PlayControlIcon icon="Forward" size="16px" />
            </Button>
            <Button
              className="bgNone p-2 ml-4"
              tooltip={{ text: 'End of Sequence', offset: 'top' }}
              onClick={() => {
                const recording = state.playback.playing;
                if (recording === null) return;
                const end = recording.timestamps.length - 1;
                dispatch({
                  type: 'setPlaybackTimeIndex',
                  offset: 'absolute',
                  value: end,
                });
              }}
            >
              <PlayControlIcon icon="End" size="16px" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
