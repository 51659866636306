/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import {
  BoxBufferGeometry,
  BufferGeometry,
  CircleBufferGeometry,
  DoubleSide,
  Group,
  Line,
  LineDashedMaterial,
  Mesh,
  MeshBasicMaterial,
  Vector3,
} from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { COLOR_SELECTED } from '../constants';

export class Offender3JS extends Group {
  public readonly isMeasure3JS = true;
  private label: CSS2DObject;
  private labelElem: HTMLDivElement;

  constructor(
    id: string,
    lat: number,
    lon: number,
    radius: number,
    ts: number,
  ) {
    super();
    this.name = `Offender3JS-${id}`;

    const d = new Date(ts / 1000);
    this.labelElem = document.createElement('div');
    // this.labelElem.className = 'gui-label';
    this.labelElem.innerHTML = `
    <div class='measure text-xxs flex flex-col items-center px-4 py-2 drop-shadow-md bgTransparent'
         style='border: dashed 3px var(--selected); color: var(--selected); position:relative; top:-30px;'
    >
      <div class='flex justify-between mb-1' style='width: var(--r20);'>
          <div>Time:&nbsp</div>
          <div>${d.toLocaleTimeString().split(' ')[0]}</div>
      </div>
      <div class='flex justify-between mb-1' style='width: var(--r20);'>
        <div>Id:&nbsp</div>
        <div>${id}</div>
      </div>
      <div class='flex justify-between mb-1' style='width: var(--r20);'>
          <div>Lat:&nbsp</div>
          <div>${lat.toFixed(6)}</div>
      </div>
      <div class='flex justify-between' style='width: var(--r20);'>
          <div>Lon:&nbsp</div>
          <div>${lon.toFixed(6)}</div>
      </div>
    </div>
    `;

    this.label = new CSS2DObject(this.labelElem);
    const labelHeight = 4;
    this.label.position.setZ(labelHeight);
    this.add(this.label);

    const dot = new Mesh(
      new CircleBufferGeometry(0.1, 16),
      new MeshBasicMaterial({ color: COLOR_SELECTED }),
    );
    this.add(dot);

    const minR = Math.max(radius, 0.3);
    const geometryCircle = new BufferGeometry();
    const segments = 32;
    const vertices = [...new Array(segments)].map((_, i) => {
      const theta: number = (i / segments) * Math.PI * 2;
      return new Vector3(Math.cos(theta) * minR, Math.sin(theta) * minR, 0);
    });
    vertices.push(vertices[0]);
    geometryCircle.setFromPoints(vertices);

    const matLine = new LineDashedMaterial({
      color: COLOR_SELECTED,
      linewidth: 3,
      side: DoubleSide,
      dashSize: (Math.PI * 2) / segments - 0.1,
      gapSize: 0.1,
    });
    const circle = new Line(geometryCircle, matLine);
    circle.computeLineDistances();
    this.add(circle);

    const line = new Mesh(
      new BoxBufferGeometry().translate(0, 0, 0.5),
      new MeshBasicMaterial({ color: COLOR_SELECTED }),
    );
    line.scale.set(0.01, 0.01, labelHeight);
    this.add(line);
  }

  removeLabel(): void {
    this.labelElem.remove();
    this.label.remove();
  }
}
