/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef, useState } from 'react';
import {
  Color,
  DoubleSide,
  InstancedMesh,
  Matrix4,
  MeshBasicMaterial,
  Vector3,
} from 'three';
import {
  COLOR_HIGHLIGHTED,
  COLOR_SELECTED,
  MAX_TRACKED_OBJS_INSTANCES_COUNT,
  paletteVariations,
} from '../constants';
import { useAppState } from '../Stores';
import { Context, PerceptionClassification } from '../types';

const allowedRectTypes = new Set<PerceptionClassification>([
  'Vehicle',
  'LargeVehicle',
]);

const MAX_DIM = new Vector3(0.45, 0.45, 0.45);
const v3 = new Vector3();
const m = new Matrix4();
const mTrackedRot = new Matrix4();
const mTrackedTrans = new Matrix4();
const mRot90 = new Matrix4().makeRotationZ(Math.PI / 2);
const mRot180 = new Matrix4().makeRotationZ(Math.PI);
const mRot270 = new Matrix4().makeRotationZ(Math.PI * 1.5);
const mInstancePos = new Matrix4();
const material = new MeshBasicMaterial({ side: DoubleSide });
const colorSelected = new Color(COLOR_SELECTED);
const colorHighlighted = new Color(COLOR_HIGHLIGHTED);

// Hook to update the vehicle's top vertex corners
export const useCornersSynch = (context: Context): void => {
  const state = useAppState();

  const instances = useRef<InstancedMesh | null>(null);

  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    instances.current = new InstancedMesh(
      context.assets.cornerTrident,
      material,
      MAX_TRACKED_OBJS_INSTANCES_COUNT,
    );
    instances.current.name = 'Rects';
  }, []);

  // should the rings be updated?
  useEffect(() => {
    const isVisibleInMode =
      state.app.inputMode === 'playback' ||
      state.app.mode === 'viewer' ||
      state.app.mode === 'zone' ||
      state.app.mode === 'recording' ||
      state.app.mode === 'preferences';
    setShouldUpdate(
      isVisibleInMode && state.tracked.globalVisibilities.corners,
    );
  }, [state.app.mode, state.tracked.globalVisibilities.corners]);

  // show/hide
  useEffect(() => {
    if (instances.current === null) return;
    if (!shouldUpdate) return;

    context.viz.scene.add(instances.current);

    return () => {
      if (instances.current !== null) instances.current.removeFromParent();
    };
  }, [shouldUpdate]);

  // update all vehicles rects
  useEffect(() => {
    if (instances.current === null) return;
    if (!shouldUpdate) return;

    const paletteVariation = paletteVariations[state.app.palette];
    const ids = state.tracked.allIds.filter((id) => {
      const { classification } = state.tracked.byId[id];
      return (
        allowedRectTypes.has(classification) &&
        state.tracked.visible[id] &&
        state.tracked.classificationVisibilities[classification]
      );
    });

    const numCornersPerRender = 4;

    ids.forEach((id, i) => {
      const params = state.tracked.byId[id];
      if (allowedRectTypes.has(params.classification)) {
        const isSelected = state.tracked.selected[id];
        const isHighlighted = state.tracked.highlighted[id] ?? false;

        const colorIndex =
          state.tracked.classificationColors[params.classification];
        const { colorOffsetIndex } = params;
        const color = isHighlighted
          ? colorHighlighted
          : isSelected
          ? colorSelected
          : paletteVariation[colorIndex][colorOffsetIndex];
        const { position, rotation, dimensions } = params;
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        const dimVertex = v3.copy(dimensions).multiplyScalar(0.5).max(MAX_DIM);

        const index = i * numCornersPerRender;
        mTrackedRot.makeRotationFromQuaternion(rotation);
        mTrackedTrans
          .makeTranslation(position.x, position.y, position.z)
          .multiply(mTrackedRot);

        mInstancePos.makeTranslation(dimVertex.x, dimVertex.y, dimVertex.z);
        m.copy(mTrackedTrans).multiply(mInstancePos);
        instances.current!.setMatrixAt(index, m);
        instances.current!.setColorAt(index, color);

        mInstancePos
          .makeTranslation(-dimVertex.x, dimVertex.y, dimVertex.z)
          .multiply(mRot90);
        m.copy(mTrackedTrans).multiply(mInstancePos);
        instances.current!.setMatrixAt(index + 1, m);
        instances.current!.setColorAt(index + 1, color);

        mInstancePos
          .makeTranslation(-dimVertex.x, -dimVertex.y, dimVertex.z)
          .multiply(mRot180);
        m.copy(mTrackedTrans).multiply(mInstancePos);
        instances.current!.setMatrixAt(index + 2, m);
        instances.current!.setColorAt(index + 2, color);

        mInstancePos
          .makeTranslation(dimVertex.x, -dimVertex.y, dimVertex.z)
          .multiply(mRot270);
        m.copy(mTrackedTrans).multiply(mInstancePos);
        instances.current!.setMatrixAt(index + 3, m);
        instances.current!.setColorAt(index + 3, color);
      }
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    });

    if (
      ids.length > 0 ||
      instances.current.count !== ids.length * numCornersPerRender
    ) {
      instances.current.count = ids.length * numCornersPerRender;
      instances.current.instanceMatrix.needsUpdate = true;
      if (instances.current.instanceColor)
        instances.current.instanceColor.needsUpdate = true;
      material.needsUpdate = true;
    }
  }, [
    state.tracked.allIds,
    state.tracked.byId,
    state.tracked.classificationVisibilities,
    state.tracked.classificationColors,
    state.tracked.visible,
    state.app.palette,
    state.tracked.selected,
    state.tracked.highlighted,
    shouldUpdate,
  ]);
};
