/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect } from 'react';
import { useAppDispatch, useAppState } from '../../../../Stores';
import { Mode, Tool, ZoneTypes } from '../../../../types';
import { Button } from '../../Button';
import { Separator } from '../../Separator';
import { ToolIcon, ToolIcons } from './ToolIcons';

const feedbackMap: Record<Tool, string> = {
  Select: 'Single Select',
  Camera: 'View Navigation',
  Measure: 'Coordinates / Measure ',
  ZoneCreate: 'Zone Creation',
  ZoneEdit: 'Zone Editing',
  ZoneAddRemoveVertex: 'Add Remove Vertex',
  World: 'Transform World',
  TransformMap: 'Image Reference Editing',
};

const feedbackMessage: Record<Tool, string> = {
  Camera: 'Left Click: Orbit - Middle Click: Zoom - Right Click: Pan',
  Select: 'Single Select Mode: Click items to select them, [esc] to exit.',
  Measure:
    'Coordinates: Move pointer, Measure: Ctrl Click and drag, [esc] to exit.',
  ZoneCreate: 'Create Zone: Click to add vertex at location, [esc] to exit.',
  ZoneEdit: 'Edit Zone: Move vertices around, [esc] to exit.',
  ZoneAddRemoveVertex:
    'Add/Remove Vertices: Add vertices near edges. Click a vertex to delete it. [esc] to exit.',
  World:
    'Transform World: Reposition all sources and zones, [esc] to exit and save.',
  TransformMap: 'Edit Image Reference: Move vertices around, [esc] to exit.',
};

const selectableType: Partial<Record<Mode, string>> = {
  viewer: 'Tracked Object`s',
  zone: 'Zone`s',
  setup: 'Sensor`s',
};

const ToolButton = ({
  tool,
  icon,
  disabled = false,
  visible = true,
}: {
  tool: Tool;
  icon: ToolIcon;
  disabled?: boolean;
  visible?: boolean;
}): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  return visible ? (
    <Button
      className="borderNone bgNone p-2"
      disabled={disabled}
      onClick={() => {
        dispatch({
          type: 'setTool',
          // act like a toggle
          value: state.app.tool === tool ? 'Camera' : tool,
        });
      }}
      tooltip={{
        text: `${state.app.tool === tool ? 'Disable' : 'Enable'} ${
          tool === 'Select' ? selectableType[state.app.mode] : ''
        } ${feedbackMap[tool]} Tool`,
        offset: 'bottom',
      }}
    >
      <ToolIcons icon={icon} active={state.app.tool === tool} />
    </Button>
  ) : null;
};
export const Tools = (): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({
      type: 'setFeedbackMessage',
      value: { message: feedbackMessage[state.app.tool], type: 'info' },
    });
  }, [state.app.tool]);

  const isSelectToolVisible =
    state.app.inputMode === 'playback' ||
    state.app.mode === 'viewer' ||
    state.app.mode === 'preferences' ||
    state.app.mode === 'recording' ||
    state.app.mode === 'setup';

  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: 0,
        transform: 'translate(-50%,0)',
        alignItems: 'center',
        zIndex: 12,
      }}
    >
      <div className="flex flex-col items-center p-4">
        <p
          className="no-select text-xs textGreyB p-2"
          style={{
            textTransform: 'uppercase',
            letterSpacing: '3px',
            whiteSpace: 'nowrap',
          }}
        >{`[ ${feedbackMap[state.app.tool]} ]`}</p>
        <div className="flex flex-row items-center">
          <ToolButton
            tool="Select"
            icon={'SelectTracked'}
            visible={isSelectToolVisible}
            disabled={state.setup.alignPhase === 'approve'}
          />
          <ToolButton
            tool="Select"
            icon={'SelectZones'}
            visible={state.app.mode === 'zone'}
            disabled={state.setup.alignPhase === 'approve'}
          />
          {state.app.mode === 'zone' && (
            <>
              <ToolButton tool="ZoneCreate" icon="Pencil" />
              <ToolButton
                tool="ZoneEdit"
                icon="SelectVertex"
                disabled={state.zones.selected === null}
              />
              <ToolButton
                tool="ZoneAddRemoveVertex"
                icon="ZoneAddRemoveVertex"
                disabled={state.zones.selected === null}
              />

              <Separator />
            </>
          )}

          <ToolButton tool="Measure" icon="Ruler" />

          {state.app.mode === 'map' && (
            <ToolButton tool="TransformMap" icon="Map" />
          )}

          {state.app.mode === 'setup' && (
            <ToolButton tool="World" icon="World" />
          )}
        </div>

        {state.app.tool === 'ZoneCreate' && (
          <div className="flex flex-row m-2">
            {[...ZoneTypes].reverse().map((type) => (
              <Button
                className={`bgGreyF px-3 py-1 mx-2 ${
                  state.app.zoneCreationType === type
                    ? 'textGreyB'
                    : 'textGreyD'
                }`}
                key={type}
                onClick={() =>
                  dispatch({ type: 'setZoneCreationType', value: type })
                }
              >
                <p>{type}</p>
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
