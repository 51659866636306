/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useCallback, useEffect, useRef } from 'react';
import {
  BufferGeometry,
  DoubleSide,
  Line,
  LineDashedMaterial,
  Mesh,
  MeshBasicMaterial,
  PlaneBufferGeometry,
  Raycaster,
  Vector2,
} from 'three';
import {
  PointerType,
  usePointerEvents,
} from '../../app/hooks/usePointerEvents';
import { COLOR_IDEAL } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import { Context } from '../../types';
import { linesIntersect } from '../../util/misc';
import { getFromVertexPool, putToVertexPool, Vertex3JS } from '../Vertex3JS';

// Max distance from a zone line to allow creating of new vertex
const DIST_ACTIVATION_THRESHOLD = 5;
// Min distance from vertex to create a new one
const MIN_DIST_FROM_VERTEX = 0.5;
const c = new Vector2();
const ab = new Vector2();
const ac = new Vector2();
const ap = new Vector2();
const perp = new Vector2();
const perpMax = new Vector2();
const newVertexPos = new Vector2();
const rc = new Raycaster();
const plane = new Mesh(
  new PlaneBufferGeometry(10000, 10000),
  new MeshBasicMaterial({}),
);
const newVertex = new Vertex3JS();

export const useVertexAddRemove = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const newVertices = useRef<Vector2[]>([]);
  const usedVertices3JS = useRef<Vertex3JS[]>([]);
  const overIndex = useRef<number | null>(null);

  const canAdd = useRef<boolean>(false);
  const isDrag = useRef<boolean>(false);
  const isInbound = useRef<boolean>(true);
  const line = useRef<Line | null>(null);

  useEffect(() => {
    line.current = new Line(
      new BufferGeometry(),
      new LineDashedMaterial({
        color: COLOR_IDEAL,
        linewidth: 6,
        side: DoubleSide,
        dashSize: 0.9,
        gapSize: 0.1,
      }),
    );
    line.current.frustumCulled = false;
  }, []);

  const cb = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (pointer: PointerType) => {
      if (pointer.eventType === 'still') return;

      const id = state.zones.selected;
      if (id === null) return;

      rc.setFromCamera(pointer, context.viz.camera);

      const intersections = rc.intersectObject(plane);
      if (intersections.length === 0) return;

      if (pointer.eventType === 'pointerenter') {
        isInbound.current = true;
      } else if (pointer.eventType === 'pointerleave') {
        isInbound.current = false;
      } else if (pointer.eventType === 'pointermove') {
        if (isDrag.current) return;

        if (overIndex.current !== null)
          usedVertices3JS.current[overIndex.current].isHighlighted = false;

        const { vertices } =
          state.zones.zoneRedefined[id] || state.zones.params[id];

        // Deleting a vertex ---------------------------------------------
        // check if path not a triangle and the pointer is over a vertex
        if (usedVertices3JS.current.length > 3) {
          const verticesIntersections = rc.intersectObjects(
            usedVertices3JS.current,
          );
          overIndex.current = verticesIntersections.length
            ? usedVertices3JS.current.indexOf(
                verticesIntersections[0].object as Vertex3JS,
              )
            : null;

          // check if the new edges after the vertex deletion will intersect with any existing ones
          if (overIndex.current !== null) {
            const prevIndex =
              overIndex.current === 0
                ? vertices.length - 1
                : overIndex.current - 1;
            const nextIndex =
              overIndex.current === vertices.length - 1
                ? 0
                : overIndex.current + 1;
            const prevVertex = vertices[prevIndex];
            const nextVertex = vertices[nextIndex];
            let newEdgeWillIntersectExistingOnes = false;
            for (let i = 0; i < vertices.length; i++) {
              //skip left and right edges
              if (i === prevIndex || i === overIndex.current || i === nextIndex)
                continue;
              const aa = vertices[i];
              const bb = vertices[i === vertices.length - 1 ? 0 : i + 1];
              newEdgeWillIntersectExistingOnes = linesIntersect(
                prevVertex,
                nextVertex,
                aa,
                bb,
              );
              if (newEdgeWillIntersectExistingOnes) break;
            }

            if (newEdgeWillIntersectExistingOnes) {
              overIndex.current = null;
              dispatch({
                type: 'setFeedbackMessage',
                value: {
                  type: 'warning',
                  message:
                    'Cannot delete vertex, would result in self intersecting polygon',
                },
              });
            } else {
              usedVertices3JS.current[overIndex.current].isHighlighted = true;
              dispatch({
                type: 'setFeedbackMessage',
                value: {
                  type: 'info',
                  message: 'Click to delete vertex.',
                },
              });
            }

            //
            newVertices.current = vertices;
            update();
            return;
          }
        }

        // Adding a vertex ---------------------------------------------
        c.set(intersections[0].point.x, intersections[0].point.y);
        let closestIndex = -1;
        let closestAdjacentIndex = -1;
        let closesValue = DIST_ACTIVATION_THRESHOLD;

        for (let i = 0; i < vertices.length; i++) {
          const a = vertices[i];
          const adjacentIndex = i === vertices.length - 1 ? 0 : i + 1;
          const b = vertices[adjacentIndex];
          ab.copy(b).sub(a);
          ac.copy(c).sub(a);
          const abLen = ab.length();
          const prjFactor = ab.dot(ac) / abLen;

          // don't allow adding vertices too close to each other
          if (
            prjFactor < MIN_DIST_FROM_VERTEX ||
            prjFactor > abLen - MIN_DIST_FROM_VERTEX
          )
            continue;
          // ap is the projection of ac onto ab
          ap.copy(ab).normalize().multiplyScalar(prjFactor);
          perp.copy(ac).sub(ap);

          const perpMag = perp.length();
          if (perpMag < closesValue) {
            // check if the new edges will intersect with any existing ones
            let intersectsExistingEdge = false;
            for (let j = 0; j < vertices.length; j++) {
              if (j === i) continue; //skip current possible dissected edge
              const aa = vertices[j];
              const bb = vertices[j === vertices.length - 1 ? 0 : j + 1];
              intersectsExistingEdge =
                linesIntersect(a, c, aa, bb) || linesIntersect(b, c, aa, bb);
              if (intersectsExistingEdge) {
                break;
              }
            }
            if (intersectsExistingEdge) continue;
            closesValue = perpMag;
            closestIndex = i;
            closestAdjacentIndex = adjacentIndex;

            const maxLen = Math.min(perp.length(), DIST_ACTIVATION_THRESHOLD);
            perpMax.copy(perp).normalize().multiplyScalar(maxLen);
            newVertexPos.copy(ap).add(perpMax).add(a);
            newVertex.position.set(newVertexPos.x, newVertexPos.y, 0);
          }
        }

        const canAddVertex =
          closestIndex !== -1 &&
          closesValue < DIST_ACTIVATION_THRESHOLD &&
          isInbound.current;
        if (canAddVertex) {
          canAdd.current = true;
          dispatch({ type: 'setViewNavigation', value: false });

          // are we testing the edge between last and first?
          if (closestAdjacentIndex === 0) {
            newVertices.current = [
              ...vertices,
              new Vector2(newVertex.position.x, newVertex.position.y),
            ];
          } else
            newVertices.current = [
              ...vertices.slice(0, closestIndex + 1),
              new Vector2(newVertex.position.x, newVertex.position.y),
              ...vertices.slice(closestAdjacentIndex),
            ];
          update();
          dispatch({
            type: 'setFeedbackMessage',
            value: {
              type: 'info',
              message: 'Click to add a vertex to the desired location.',
            },
          });
        } else {
          canAdd.current = false;
          dispatch({ type: 'setViewNavigation', value: true });

          newVertices.current = vertices;
          update();

          dispatch({
            type: 'setFeedbackMessage',
            value: {
              type: 'info',
              message:
                'Hover over an edge to add a vertex or a vertex to delete it.',
            },
          });
        }
      } else if (pointer.eventType === 'pointerup') {
        isDrag.current = false;
      } else if (pointer.eventType === 'pointerdown') {
        isDrag.current = true;

        const id = state.zones.selected;
        if (id === null) return;

        const params = state.zones.zoneRedefined[id] || state.zones.params[id];

        if (overIndex.current !== null) {
          const a = params.vertices.filter((_, i) => i !== overIndex.current);
          overIndex.current = null;

          dispatch({
            type: 'setZoneRedefined',
            id,
            value: {
              ...params,
              vertices: a,
            },
          });
        } else if (canAdd.current) {
          dispatch({
            type: 'setZoneRedefined',
            id,
            value: {
              ...params,
              vertices: newVertices.current,
            },
          });
        }
      }
    },
    [state.zones.selected, state.zones.zoneRedefined],
  );

  const setPointerEvents = usePointerEvents(context.canvas, cb);

  // populate vertices and line
  const update = (): void => {
    if (line.current === null) return;
    //vertices3JS state
    for (let i = 0; i < usedVertices3JS.current.length; i++) {
      const vertex3JS = usedVertices3JS.current[i];
      vertex3JS.isSelected = i === overIndex.current;
    }

    line.current.geometry.setFromPoints([
      ...newVertices.current,
      newVertices.current[0],
    ]);
    line.current.computeLineDistances();
    context.groups3JS.Zone.add(line.current);

    if (newVertices.current.length === usedVertices3JS.current.length) {
      newVertex.removeFromParent();
    } else {
      context.viz.scene.add(newVertex);
    }
  };

  // io logic
  useEffect(() => {
    if (state.app.tool !== 'ZoneAddRemoveVertex' || line.current === null)
      return;
    const id = state.zones.selected;
    if (id === null) return;
    if (state.zones.visibilities[id] === false) {
      dispatch({
        type: 'setFeedbackMessage',
        value: { type: 'warning', message: 'Make zone visible to edit' },
      });
      return;
    }

    const params = state.zones.zoneRedefined[id] || state.zones.params[id];
    if (!params.vertices.length) return;

    const zone = context.instances.Zones.all[id];
    if (zone === undefined) return;

    zone.isEdited = true;

    setPointerEvents(true);

    for (let i = 0; i < params.vertices.length; i++) {
      const vertex3JS = getFromVertexPool();
      vertex3JS.position.set(params.vertices[i].x, params.vertices[i].y, 0);
      usedVertices3JS.current.push(vertex3JS);
      context.groups3JS.Zone.add(vertex3JS);
    }
    line.current.geometry.setFromPoints([
      ...params.vertices,
      params.vertices[0],
    ]);
    line.current.computeLineDistances();
    context.groups3JS.Zone.add(line.current);

    return () => {
      zone.isEdited = false;

      setPointerEvents(false);

      dispatch({
        type: 'setFeedbackMessage',
        value: {
          type: 'info',
          message:
            'Save the amends to persist the changes or reset to revert them',
        },
      });

      // clean up
      for (const vertex3JS of usedVertices3JS.current) {
        putToVertexPool(vertex3JS);
        vertex3JS.removeFromParent();
      }
      usedVertices3JS.current.length = 0;
      //
      line.current?.removeFromParent();
      newVertex.removeFromParent();
    };
  }, [
    state.app.tool,
    state.zones.params,
    state.zones.selected,
    state.zones.visibilities,
    state.zones.zoneRedefined,
  ]);
};
