/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/
import React, { useRef } from 'react';
import { SelectableListElem } from '../app/components/pane/SelectableListElem';
import { useAppDispatch, useAppState } from '../Stores';
import { Column } from './diagnosticsColumns';
import { unixToString } from '../util/misc';
import { SECONDS_TO_MS } from '../constants';
import { Alert } from '../api/alerts';
import './Diagnostics.css';

const parseAlertTimestamp = (timestamp?: number) =>
  timestamp
    ? unixToString(timestamp / SECONDS_TO_MS, {
        year: false,
      })
    : 'N/A';

type AlertCode =
  | 'Timeout'
  | 'LidarDrop'
  | 'ClusterDrop'
  | 'ClusterDelta'
  | 'WebsocketBehind'
  | 'TCPBehind'
  | 'InvalidPacket'
  | 'LidarMismatch'
  | 'ObjectPipelineSlow'
  | 'UnsupportedMode'
  | 'FailedConnection';

const ALERT_CODES: Record<string, AlertCode> = {
  '0x02000001': 'Timeout',
  '0x02000002': 'LidarDrop',
  '0x02000003': 'ClusterDrop',
  '0x02000004': 'ClusterDelta',
  '0x02000005': 'WebsocketBehind',
  '0x02000006': 'TCPBehind',
  '0x02000007': 'InvalidPacket',
  '0x02000008': 'LidarMismatch',
  '0x02000009': 'ObjectPipelineSlow',
  '0x0200000A': 'UnsupportedMode',
  '0x0200000B': 'FailedConnection',
};

const parseAlertCode = (code?: string): AlertCode | undefined => {
  if (code === undefined) {
    return undefined;
  }
  return ALERT_CODES[code] ?? code;
};

const MainRow = ({
  columns,
  rowContent,
}: {
  columns: Column<Alert>[];
  rowContent: Alert;
}) => {
  return (
    <>
      {columns.map((value) => {
        return (
          <td
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            key={`${rowContent.id}-${value.key}`}
          >
            <div
              className={`${value.class} ${
                value.key === 'level'
                  ? rowContent[value.key].toString().toLowerCase()
                  : ''
              }`}
              style={{
                textAlign: 'center',
                textTransform: value.key === 'level' ? 'uppercase' : 'none',
              }}
            >
              {value.key === 'lastOccured' || value.key === 'firstOccured'
                ? parseAlertTimestamp(rowContent[value.key])
                : value.key === 'alertCode'
                ? parseAlertCode(rowContent[value.key])
                : rowContent[value.key]}
            </div>
          </td>
        );
      })}
    </>
  );
};

const RowSelect = ({
  alertId,
  active,
}: {
  alertId: string;
  active: boolean;
}) => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const diagnostics = state.diagnostics;

  return (
    <td
      style={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <SelectableListElem
        backgroundColor={active ? 'var(--greyH)' : undefined}
        disabled={false}
        isSelected={diagnostics.isSelected[alertId]}
        grow={false}
        onClick={() => {
          dispatch({
            type: 'selectAlert',
            id: alertId,
            value: !diagnostics.isSelected[alertId],
          });
        }}
      />
    </td>
  );
};

const ExtraAlertInfo = ({ alert }: { alert: Alert }) => {
  return (
    <td
      className="expanded-row-content hide-row"
      style={{
        width: '100%',
        display: 'block',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          borderRadius: '3px',
          padding: 'var(--r2)',
          margin: 'var(--r2) 0',
        }}
      >
        <div
          className="more-info-header"
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <p style={{ fontSize: 'var(--r3)' }}>
            Alert received on {parseAlertTimestamp(alert.lastOccured)}
            &mdash;
            <span
              style={{
                color: alert.active ? 'var(--success)' : 'var(--info)',
                paddingLeft: '0.25rem',
              }}
            >
              {`${alert.active ? 'ACTIVE' : 'LOGGED'} `}
            </span>
            {`(Count: ${alert.activeCount ?? 'N/A'})`}
          </p>
        </div>
        <div
          className="first-seen"
          style={{ width: '100%', padding: '0.1rem 0' }}
        >
          <p style={{ fontSize: 'var(--r3)' }}>
            First Seen: {parseAlertTimestamp(alert.firstOccured)}
          </p>
        </div>
        <div
          className="first-seen"
          style={{
            width: '100%',
            padding: 'var(--r5) 0 var(--r2) 0',
          }}
        >
          <p style={{ fontSize: 'var(--r3)' }}>{alert.messageVerbose}</p>
        </div>
        <div
          className="more-info-footer"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div className="category">
            <p style={{ fontSize: 'var(--r3)' }}>Category: {alert.category}</p>
          </div>
          <div className="alertID">
            <p style={{ fontSize: 'var(--r3)' }}>Alert ID: {alert.id}</p>
          </div>
        </div>
      </div>
    </td>
  );
};

const ignoreClick = new Set(['BUTTON', 'path', 'svg', 'select', 'circle']);
const ExpandableRow = ({
  rowContent,
  columns,
}: {
  rowContent: Alert;
  columns: Column<Alert>[];
}): JSX.Element => {
  const trRef = useRef<HTMLTableRowElement>(null);

  return (
    <tr
      className={`expandable-row ${rowContent.active ? 'active' : ''}`}
      style={{
        padding: '0.05rem 0',
        borderBottom: 'solid var(--greyH) 1px',
      }}
      ref={trRef}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (ignoreClick.has(target.tagName)) return;
        trRef.current
          ?.getElementsByClassName('expanded-row-content')[0]
          .classList.toggle('hide-row');
      }}
    >
      <RowSelect alertId={rowContent.id} active={rowContent.active} />
      <MainRow columns={columns} rowContent={rowContent} />
      <ExtraAlertInfo alert={rowContent} />
    </tr>
  );
};

export default ExpandableRow;
