/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
import IconPaths24px from './IconPaths24px';

export const ResetIcon = ({
  size = '14px',
  ...rest
}: { size?: string } & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="M0,0h24v24H0V0z" fill="none" />
      {IconPaths24px.reset}
    </svg>
  );
};
