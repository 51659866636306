/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { createContext, useContext, useReducer, ReactNode } from 'react';

import {
  AppActions,
  AppInitialState,
  AppReducer,
  AppState,
} from './app/AppStore';
import {
  ProcessedCloudActions,
  ProcessedCloudInitialState,
  ProcessedCloudReducer,
  ProcessedCloudState,
} from './processedCloud/ProcessedCloudStore';
import {
  TrackedObjectsActions,
  TrackedObjectsInitialState,
  TrackedObjectsReducer,
  TrackedObjectsState,
} from './trackedObjects/TrackedObjectsStore';

import {
  SensorActions,
  SensorInitialState,
  SensorReducer,
  SensorState,
} from './source/SensorStore';
import {
  NodeActions,
  NodeInitialState,
  NodeReducer,
  NodeState,
} from './source/NodeStore';
import {
  SetupActions,
  SetupInitialState,
  SetupReducer,
  SetupState,
} from './source/SetupStore';
import {
  SettingActions,
  SettingInitialState,
  SettingReducer,
  SettingState,
} from './settingsEditor/SettingStore';
import {
  DiagnosticsActions,
  DiagnosticsInitialState,
  DiagnosticsReducer,
  DiagnosticsState,
} from './diagnostics/DiagnosticsStore';
import {
  RecordingActions,
  RecordingInitialState,
  RecordingReducer,
  RecordingState,
} from './record/RecordingStore';
import {
  ZoneActions,
  ZoneInitialState,
  ZoneReducer,
  ZoneState,
} from './zone/ZoneStore';
import {
  ServiceActions,
  ServiceInitialState,
  ServiceReducer,
  ServiceState,
} from './diagnostics/serviceReachability/ServiceStore';
import {
  PlaybackActions,
  PlaybackInitialState,
  PlaybackReducer,
  PlaybackState,
} from './playback/PlaybackStore';

type Actions =
  | AppActions
  | TrackedObjectsActions
  | ProcessedCloudActions
  | ZoneActions
  | SensorActions
  | NodeActions
  | SetupActions
  | DiagnosticsActions
  | SettingActions
  | RecordingActions
  | ServiceActions
  | PlaybackActions;

export type Dispatch = (action: Actions) => void;

export type States = {
  app: AppState;
  clouds: ProcessedCloudState;
  tracked: TrackedObjectsState;
  zones: ZoneState;
  sensors: SensorState;
  nodes: NodeState;
  setup: SetupState;
  diagnostics: DiagnosticsState;
  settings: SettingState;
  recording: RecordingState;
  service: ServiceState;
  // Dependent properties, calculated from the above
  depTrackedUserVisible: string[];
  depTrackedUserInvisible: string[];
  playback: PlaybackState;
};

const initialAppState: States = {
  app: AppInitialState,
  clouds: ProcessedCloudInitialState,
  tracked: TrackedObjectsInitialState,
  zones: ZoneInitialState,
  sensors: SensorInitialState,
  nodes: NodeInitialState,
  setup: SetupInitialState,
  settings: SettingInitialState,
  diagnostics: DiagnosticsInitialState,
  recording: RecordingInitialState,
  service: ServiceInitialState,
  depTrackedUserVisible: [],
  depTrackedUserInvisible: [],
  playback: PlaybackInitialState,
};

const reducer = (state: States, action: Actions): States => {
  const app = AppReducer(state.app, action as AppActions);
  const clouds = ProcessedCloudReducer(
    state.clouds,
    action as ProcessedCloudActions,
  );
  const tracked = TrackedObjectsReducer(
    state.tracked,
    action as TrackedObjectsActions,
  );
  const zones = ZoneReducer(state.zones, action as ZoneActions);
  const sensors = SensorReducer(state.sensors, action as SensorActions);
  const nodes = NodeReducer(state.nodes, action as NodeActions);
  const setup = SetupReducer(state.setup, action as SetupActions);
  const settings = SettingReducer(state.settings, action as SettingActions);
  const diagnostics = DiagnosticsReducer(
    state.diagnostics,
    action as DiagnosticsActions,
  );
  const recording = RecordingReducer(
    state.recording,
    action as RecordingActions,
  );
  const service = ServiceReducer(state.service, action as ServiceActions);
  const playback = PlaybackReducer(state.playback, action as PlaybackActions);

  const needsUpdate =
    state.app !== app ||
    state.clouds !== clouds ||
    state.tracked !== tracked ||
    state.zones !== zones ||
    state.sensors !== sensors ||
    state.nodes !== nodes ||
    state.setup !== setup ||
    state.settings !== settings ||
    state.diagnostics !== diagnostics ||
    state.recording !== recording ||
    state.service !== service ||
    state.playback !== playback;
  return needsUpdate
    ? {
        ...state,
        app,
        clouds,
        tracked,
        zones,
        sensors,
        nodes,
        setup,
        settings,
        diagnostics,
        recording,
        service,
        playback,
      }
    : state;
};

// Post reducer action that modify the store's state, usually we will update here dependent properties or edge case logic
const postReducer = (
  prevState: States,
  reducedState: States,
  action: Actions,
): States => {
  let postState = { ...reducedState };

  // if any tracked object is deselected,
  // clear the atomic 'visible'||'labelVisible' flags as well
  /////////////////////////////////////////
  if (
    action.type === 'setTrackedInstanceBoolProp' &&
    action.property === 'selected'
  ) {
    const visible = {
      ...reducedState.tracked.visible,
    };
    const labelVisible = {
      ...reducedState.tracked.labelVisible,
    };
    for (const id of action.ids) {
      const isUnselected = !reducedState.tracked.selected[id];
      if (isUnselected) {
        visible[id] = labelVisible[id] = true;
      }
    }
    postState = {
      ...postState,
      tracked: { ...postState.tracked, visible, labelVisible },
    };
  }

  // if developer mode is toggled off, clear flags relative to them,
  /////////////////////////////////////////
  if (action.type === 'setDeveloperMode' && !action.value) {
    const globalVisibilities = {
      ...reducedState.tracked.globalVisibilities,
      previousPositionsPath: false,
    };
    postState = {
      ...postState,
      tracked: { ...postState.tracked, globalVisibilities },
      app: { ...postState.app, theme: 'Dark' },
    };
  }

  // Calculate visible tracked objects
  /////////////////////////////////////////
  if (
    prevState.app.visibilities.TrackedObjects !==
      reducedState.app.visibilities.TrackedObjects ||
    prevState.app.mode !== reducedState.app.mode ||
    prevState.tracked.classificationVisibilities !==
      reducedState.tracked.classificationVisibilities ||
    prevState.tracked.visible !== reducedState.tracked.visible ||
    prevState.tracked.byId !== reducedState.tracked.byId ||
    prevState.tracked.selected !== reducedState.tracked.selected
  ) {
    const depTrackedUserVisible: string[] = [];
    const depTrackedUserInvisible: string[] = [];
    // Check if tracked objects are visible at all
    const isCalcAtomicProps =
      reducedState.app.visibilities.TrackedObjects &&
      (reducedState.app.inputMode === 'playback' ||
        reducedState.app.mode === 'viewer' ||
        reducedState.app.mode === 'zone' ||
        reducedState.app.mode === 'recording' ||
        reducedState.app.mode === 'preferences' ||
        reducedState.app.mode === 'map');
    if (isCalcAtomicProps) {
      // Check class/atomic visibilities
      for (const id of reducedState.tracked.allIds) {
        const { classification } = reducedState.tracked.byId[id];
        const isVisible =
          reducedState.tracked.classificationVisibilities[classification] &&
          reducedState.tracked.visible[id];
        if (isVisible) depTrackedUserVisible.push(id);
        else depTrackedUserInvisible.push(id);
      }
      postState = {
        ...postState,
        depTrackedUserVisible,
        depTrackedUserInvisible,
      };
    } else {
      postState = {
        ...postState,
        depTrackedUserVisible: [],
        depTrackedUserInvisible: [...reducedState.tracked.allIds],
      };
    }
  }

  return postState;
};

/**
 * Reduce that takes care of the pre/post reducer logic
 * Pre-reducer: updates the store state from the new action
 * Post-reducer: updates the store's dependent states
 * @param state Current state
 * @param action The action to be applied
 * @returns
 */
const prePostWare = (state: States, action: Actions): States => {
  let newState: States;

  const isDebug = false;
  if (isDebug) {
    console.log('----');
    console.log('ACTION: ', action);
    newState = reducer(state, action);
  } else {
    newState = reducer(state, action);
  }

  // Post updates here, dependent properties appended to the state
  newState = postReducer(state, newState, action);

  return newState;
};

const AppContext = createContext<States | undefined>(undefined);
const AppDispatchContext = createContext<Dispatch | undefined>(undefined);

export const AppProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(prePostWare, initialAppState);

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};

export const useAppState = (): States => {
  const ctx = useContext(AppContext);
  if (ctx) return ctx;
  else throw new Error('useAppState must be within a AppStateProvider');
};

export const useAppDispatch = (): Dispatch => {
  const ctx = useContext(AppDispatchContext);
  if (ctx) return ctx;
  else throw new Error('useAppDispatch must be within a AppDispatchProvider');
};
