/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { endpoints } from '../../api/endpoints';
import { ALERTS_POLL_INTERVAL } from '../../constants';
import { useAppDispatch, useAppState } from '../../Stores';
import { adaptAlerts } from '../../util/adapters';
import useInterval from './useInterval';

const { alerts } = endpoints;

const usePollAlerts = (): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const pollAlerts = async () => {
    const {
      alerts: { active: serverActive, log: serverLogged },
    } = (await alerts.all()) ?? {
      alerts: {
        active: [],
        log: [],
      },
    };
    const active = adaptAlerts(serverActive);
    const log = adaptAlerts(serverLogged);

    dispatch({ type: 'setAlerts', alerts: [...active, ...log] });
  };

  const [, setActive] = useInterval(pollAlerts, ALERTS_POLL_INTERVAL, false);

  useEffect(() => {
    // In the event of a critical alert, we always want to know, so
    // the only time we don't poll alerts is when we're in `playback` mode.
    if (state.app.inputMode === 'playback') return;

    pollAlerts();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode, state.app.mode]);
};

export default usePollAlerts;
