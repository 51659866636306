/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { ZoneType, ZoneTypes } from '../types';

export const SelectZoneType = ({
  zoneType,
  onChange,
  disabled = false,
  isDirty = false,
}: {
  zoneType: ZoneType;
  onChange: (mode: ZoneType) => void;
  disabled?: boolean;
  isDirty?: boolean;
}): JSX.Element => {
  return (
    <div className={`flex items-center ${isDirty ? 'textWarning' : ''}`}>
      {isDirty && <div>!</div>}
      <select
        disabled={disabled}
        className="text-base"
        name="zone-type"
        value={zoneType}
        onChange={(e) => {
          const mode = e.currentTarget.value as ZoneType;
          onChange(mode);
        }}
      >
        {ZoneTypes.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
    </div>
  );
};
