/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';

export default {
  hash: (
    <path d="M20,10V8h-4V4h-2v4h-4V4H8v4H4v2h4v4H4v2h4v4h2v-4h4v4h2v-4h4v-2h-4v-4H20z M14,14h-4v-4h4V14z" />
  ),
  concentric: (
    <g transform="translate(3, 3)">
      <path d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1ZM9,14.1A5.1,5.1,0,1,1,14.1,9,5.1,5.1,0,0,1,9,14.1Z" />
      <circle cx="9" cy="9" r="2" />
    </g>
  ),
  trident: (
    <path d="M11 3v7.2L10 12l-6.3 3.8 1 1.7L11 14l2-.3 6.4 3.7 1-1.7-6.2-3.6-1.2-1.6V3z" />
  ),
  cloud: (
    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4s1.79-4 4-4h.71C7.37 7.69 9.48 6 12 6c3.04 0 5.5 2.46 5.5 5.5v.5H19c1.66 0 3 1.34 3 3s-1.34 3-3 3z" />
  ),
  image: (
    <path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5H5v14Zm1-2h12l-3.75-5-3 4L9 13Zm-1 2V5v14Z" />
  ),
  eventLocation: (
    <g transform="translate(4, 0)">
      <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
      <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
    </g>
  ),
  cylinder: (
    <path d="m 12.040463,1.1777343 c -2.4678424,0 -4.7092761,0.4748115 -6.4116191,1.3016958 C 3.9315611,3.3038566 2.6362642,4.56421 2.6285651,6.2026108 h -0.00709 c -0.076615,3.8776132 -0.08842,7.9732492 -0.05906,11.7991282 -10e-8,1.645549 1.297936,2.691946 3.0002787,3.518831 1.7023429,0.826884 3.943776,1.301696 6.4116192,1.301696 2.467844,0 4.709277,-0.474812 6.411619,-1.301696 1.547373,-0.751612 2.749687,-2.086505 2.957756,-3.518831 0.02649,-3.687932 0.108667,-7.857044 0.108667,-11.7849538 0,-1.6455482 -1.300301,-2.9104707 -3.00264,-3.7373551 C 16.747386,1.6525458 14.508307,1.1777344 12.040463,1.1777343 Z m 0,1.8143418 c 2.229703,2e-7 4.241747,0.4513719 5.617846,1.1197892 1.3761,0.6684171 1.979711,1.4687151 1.979711,2.1049199 0,0.6362051 -0.603611,1.4365028 -1.979711,2.1049201 -1.376099,0.6684173 -3.388143,1.1197889 -5.617846,1.1197892 -2.2297016,0 -4.2441069,-0.4513719 -5.6202069,-1.1197892 C 5.044156,7.6532879 4.4405447,6.8529903 4.4405445,6.2167852 c 0,-0.6362051 0.6036115,-1.4365029 1.9797116,-2.1049199 1.3761,-0.6684176 3.3905053,-1.1197892 5.6202069,-1.1197892 z M 4.4358197,9.2477756 c 0.3627271,0.2621696 0.765281,0.4985962 1.1930242,0.706365 1.702343,0.8268844 3.9437767,1.3016964 6.4116191,1.3016964 2.467844,0 4.706915,-0.474812 6.409259,-1.3016964 0.425582,-0.2067212 0.827003,-0.4410615 1.188298,-0.7016401 0.06597,4.1107255 0.04219,5.2366975 -0.06615,8.7492385 0,0.636205 -0.603612,1.220341 -1.979712,1.888759 -1.376099,0.668416 -3.388143,1.117426 -5.617844,1.117426 -2.2297037,0 -4.2417461,-0.44901 -5.6178461,-1.117426 C 5.156398,19.30758 4.5728994,18.58941 4.4358207,18.001739 c -0.041353,-3.081035 -0.0014,-6.133251 -1e-6,-8.7539634 z" />
  ),
  textBubble: (
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17l-.59.59-.58.58V4h16v12zM6 12h2v2H6zm0-3h2v2H6zm0-3h2v2H6zm4 6h5v2h-5zm0-3h8v2h-8zm0-3h8v2h-8z" />
  ),
  topRightArrow: (
    <path d="m 16.585183,12.771892 -7.0697085,7.068197 c -0.1969269,0.196867 -0.5161453,0.196867 -0.7130724,0 L 5.2385496,16.276238 c -0.1968676,-0.196927 -0.1968676,-0.516147 0,-0.713074 L 12.306746,8.4934547 9.8135125,5.9992122 C 9.6543775,5.8404793 9.7667585,5.5685925 9.9915285,5.568545 h 9.5185645 v 9.518565 c -4.8e-5,0.224768 -0.271934,0.337151 -0.430667,0.178016 z" />
  ),
  cube: (
    <path d="M20.11 6.268 12.973 2.14a1.934 1.934 0 0 0-1.946 0L3.89 6.268c-.597.35-.974.986-.974 1.687v8.24c0 .7.377 1.337.974 1.687l7.137 4.127c.597.35 1.35.35 1.946 0l7.137-4.127c.597-.35.974-.986.974-1.687v-8.24c0-.7-.377-1.337-.974-1.687zm-14.598 9.55V9.825l5.19 3.01v5.982zM12 10.59 6.81 7.58 12 4.58l5.19 2.998Zm1.298 8.227v-5.982l5.19-3.01v5.995z" />
  ),
  zaggedPoints: (
    <g>
      <path d="m 2.2584982,15.944556 c -0.4754357,5e-5 -0.8608406,0.400513 -0.8608881,0.894526 v 2.102141 c 4.75e-5,0.494012 0.3854524,0.894475 0.8608881,0.894526 h 2.0230863 c 0.4754356,-5.2e-5 0.8608405,-0.400515 0.8608873,-0.894526 V 16.839081 C 5.1424249,16.345069 4.75702,15.944606 4.2815844,15.944556 Z" />
      <path d="m 5.9046571,11.148621 c -0.4754358,5.2e-5 -0.8608405,0.400516 -0.860888,0.894528 v 2.102141 c 4.75e-5,0.494012 0.3854524,0.894475 0.860888,0.894526 h 2.0230862 c 0.4754356,-5.1e-5 0.8608406,-0.400514 0.8608874,-0.894526 V 12.043149 C 8.7885839,11.549137 8.4031789,11.148673 7.9277433,11.148621 Z" />
      <path d="m 11.303458,9.7323505 c -0.475436,5.2e-5 -0.860841,0.4005155 -0.860888,0.8945285 v 2.102141 c 4.7e-5,0.494012 0.385452,0.894475 0.860888,0.894526 h 2.023086 c 0.475434,-5.1e-5 0.86084,-0.400514 0.860888,-0.894526 v -2.102141 c -4.8e-5,-0.494012 -0.385454,-0.8944755 -0.860888,-0.8945285 z" />
      <path d="m 16.465587,8.4896415 c -0.475435,5.1e-5 -0.860841,0.4005143 -0.86089,0.8945276 V 11.48631 c 4.9e-5,0.494012 0.385455,0.894475 0.86089,0.894527 h 2.023085 c 0.475434,-5.3e-5 0.86084,-0.400516 0.860888,-0.894528 V 9.3841681 C 19.349512,8.8901558 18.964106,8.4896925 18.488672,8.4896415 Z" />
      <path d="m 19.87096,3.8591664 c -0.475435,5.02e-5 -0.860841,0.4005145 -0.860888,0.8945265 v 2.102141 c 4.7e-5,0.494012 0.385453,0.8944751 0.860888,0.8945271 h 2.023084 c 0.475435,-5.1e-5 0.860841,-0.4005141 0.860888,-0.8945271 v -2.102141 c -4.7e-5,-0.494012 -0.385453,-0.8944763 -0.860888,-0.8945265 z" />{' '}
    </g>
  ),
  rings: (
    <path d="M10 18.958q-1.833 0-3.469-.698-1.635-.698-2.864-1.927-1.229-1.229-1.927-2.864-.698-1.636-.698-3.469 0-1.854.698-3.479t1.927-2.854Q4.896 2.438 6.531 1.74 8.167 1.042 10 1.042q1.854 0 3.479.698t2.854 1.927q1.229 1.229 1.927 2.854T18.958 10q0 1.833-.698 3.469-.698 1.635-1.927 2.864-1.229 1.229-2.854 1.927T10 18.958Zm0-4.583q1.833 0 3.104-1.281 1.271-1.282 1.271-3.094 0-1.833-1.271-3.104Q11.833 5.625 10 5.625q-1.812 0-3.094 1.271Q5.625 8.167 5.625 10q0 1.812 1.281 3.094Q8.188 14.375 10 14.375Z" />
  ),
  ZoneLabels: (
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17l-.59.59-.58.58V4h16v12zM6 12h2v2H6zm0-3h2v2H6zm0-3h2v2H6zm4 6h5v2h-5zm0-3h8v2h-8zm0-3h8v2h-8z" />
  ),
  RangeRingLabels: (
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17l-.59.59-.58.58V4h16v12zM6 12h2v2H6zm0-3h2v2H6zm0-3h2v2H6zm4 6h5v2h-5zm0-3h8v2h-8zm0-3h8v2h-8z" />
  ),
  corners: (
    <path d="M 9.3093786,2.8706908 9.2871464,2.9095945 6.3396114,4.8626897 6.2535654,8.2100378 9.8866744,9.8075528 5.0294623,11.869298 4.9987131,15.7891 8.040535,17.395622 12.921181,15.065143 12.945646,19.523134 15.852329,21.129309 18.842373,19.256363 C 18.914091,15.534987 18.993362,11.814174 19.001287,8.0921484 15.627093,6.076043 12.716458,4.8304187 9.3093786,2.8706908 Z" />
  ),
  zigzag: (
    <path d="M 20.41079,3.8076718 17.114744,9.7634948 11.817038,9.3921093 4.0698254,11.765153 0.23581644,18.237092 3.5318634,20.192329 6.6094469,14.995662 12.259424,13.26435 19.28298,13.755888 23.764184,5.6645997 Z" />
  ),
  reset: (
    <path d="M 10.714819,23.95904 Q 6.8952604,23.458676 4.3060488,20.585011 1.7185507,17.711346 1.7185507,13.74956 1.7185507,11.571607 2.5393534,9.696955 3.3601549,7.8223041 4.7892769,6.4308813 L 6.6090936,8.2506978 Q 5.5381096,9.2874103 4.9143681,10.714819 4.2889133,12.142227 4.2889133,13.74956 4.2889133,16.640361 6.1275788,18.746345 7.9662448,20.854043 10.714819,21.352693 Z M 13.28518,23.95904 V 21.352693 Q 16.033755,20.888314 17.872421,18.765195 19.711086,16.640361 19.711086,13.74956 19.711086,10.536607 17.462876,8.2866828 15.212953,6.0384726 12,6.0384726 H 11.749818 L 13.391423,7.6800774 11.571606,9.4656227 6.8592759,4.7532914 11.571606,0.04096032 13.391423,1.8607768 11.785803,3.4681101 H 12 Q 16.283937,3.4681101 19.282693,6.4668663 22.281449,9.4656227 22.281449,13.74956 22.281449,17.711346 19.693951,20.60386 17.104739,23.494661 13.28518,23.95904 Z" />
  ),
  map: (
    <path d="M 16.679462,0.80641718 11.290991,3.4999435 5.9358431,0.82201539 A 0.70900932,0.70900932 0 0 0 5.3019888,0.82201539 L 0.33892347,3.303548 A 0.70900932,0.70900932 0 0 0 -0.05315869,3.9381113 V 22.205028 A 0.70900932,0.70900932 0 0 0 0.97277781,22.838882 L 5.6189159,20.516168 10.974064,23.193387 A 0.70900932,0.70900932 0 0 0 11.607918,23.193387 L 16.963066,20.516168 23.081107,22.962959 A 0.70900932,0.70900932 0 0 0 24.053159,22.304998 V 3.9799429 A 0.70900932,0.70900932 0 0 0 23.607192,3.3212732 L 17.258723,0.78231086 A 0.70900932,0.70900932 0 0 0 16.679462,0.80641718 Z M 11.290991,21.817909 5.6189159,18.981872 V 2.1492808 L 11.290991,4.9853181 Z M 22.635141,21.313803 16.963066,19.098149 V 2.0819249 L 22.635141,4.297579 Z" />
  ),
};
