/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Color, SphereBufferGeometry, Group } from 'three';
import { COLOR_IDEAL, COLOR_HIGHLIGHTED, COLOR_SELECTED } from '../constants';
import { Icon3JS } from './Icon3JS';
import { OType, Selectable } from '../types';

export class World3JS extends Group implements OType, Selectable {
  static ID = 'World';
  public readonly isWorld3JS = true;
  public readonly oType: string;
  public oId: string;
  public icon: Icon3JS;

  private idealColor = COLOR_IDEAL;
  private _isHighlighted = false;
  private _isSelected = false;

  constructor() {
    super();
    this.oType = 'world3JS';
    this.oId = World3JS.ID;
    this.name = World3JS.ID;

    const sphere = new SphereBufferGeometry(0.2, 8, 8).rotateX(Math.PI / 2);
    this.icon = new Icon3JS(sphere);
    this.add(this.icon);
  }

  public setColor = (iconColor: string): void => {
    const c = new Color().setStyle(iconColor).offsetHSL(0, 0.75, 0.3);
    this.idealColor = c.getStyle();
    this.icon.setColor(c.getStyle());
  };

  private calcMaterial = (): void => {
    const clr = this._isHighlighted
      ? COLOR_HIGHLIGHTED
      : this._isSelected
      ? COLOR_SELECTED
      : this.idealColor;
    this.icon.setColor(clr);
  };

  public set isSelected(value: boolean) {
    if (this._isSelected === value) return;
    this._isSelected = value;
    this.calcMaterial();
  }
  public get isSelected(): boolean {
    return this._isSelected;
  }
}
