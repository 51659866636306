/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
export const up =
  'm 13.962954,11.002742 a 1,1 0 0 1 -1.7055,0.7055 l -3.2890001,-3.2859997 -3.289,3.2859997 a 1,1 0 0 1 -1.437,-1.3865 l 0.0245,-0.0245 3.996,-3.9919997 a 1,1 0 0 1 1.4125,0 l 3.9945001,3.9914997 a 0.9945,0.9945 0 0 1 0.293,0.706 z';
export const down =
  'M4,7.01a1,1,0,0,1,1.7055-.7055l3.289,3.286,3.289-3.286a1,1,0,0,1,1.437,1.3865l-.0245.0245L9.7,11.7075a1,1,0,0,1-1.4125,0L4.293,7.716A.9945.9945,0,0,1,4,7.01Z';
export const left =
  'M6,9a.994.994,0,0,0,.2925.7045l3.9915,3.99a1,1,0,1,0,1.4355-1.386l-.0245-.0245L8.4095,9l3.286-3.285A1,1,0,0,0,10.309,4.28l-.0245.0245L6.293,8.2945A.994.994,0,0,0,6,9Z';
export const right =
  'M12,9a.994.994,0,0,1-.2925.7045l-3.9915,3.99a1,1,0,1,1-1.4355-1.386l.0245-.0245L9.5905,9,6.3045,5.715A1,1,0,0,1,7.691,4.28l.0245.0245,3.9915,3.99A.994.994,0,0,1,12,9Z';

export const ChevronIcon = ({
  icon,
  size = '18px',
  ...rest
}: {
  icon: 'up' | 'down' | 'left' | 'right';
  size?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      fill={'var(--greyB)'}
      {...rest}
    >
      {
        {
          down: <path d={`${down}`} />,
          up: <path d={`${up}`} />,
          left: <path d={`${left}`} />,
          right: <path d={`${right}`} />,
        }[icon]
      }
    </svg>
  );
};
