/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import * as flatbuffers from 'flatbuffers';
import { Data } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/data';

import { PointClouds as FlatPointClouds } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/point-clouds';
import { DataTypes } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/data-types';
import { Clusters } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/clusters';
import { Image as FlatImage } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/image';

import {
  createObjectParametersMap,
  createClustersParametersMap,
  ObjectParameters,
  populateSensorImageData,
} from './FlatbufDeserialization';

import { TrackedObjects } from '@ouster/perception_flatbuf/build/ouster/perception/flatbuf/tracked-objects';
import { SECONDS_TO_MS, WS_RECONNECT_INTERVAL } from './constants';
import { WSDataReceivedSignal } from './types';

/**
 * This class encapsulates the extra logic for the perception websocket. It
 * currently calls point decoding which will likely be moved elsewhere but is
 * in here for now for simplicity.
 */
class PerceptionWebsocket {
  public onPointCloud?: (populatedClouds: FlatPointClouds) => void;
  public onObjects?: (
    objectParameters: ObjectParameters[],
    clusterParameters: ObjectParameters[],
  ) => void;
  public onImage?: (buffer: Uint8Array, sensorId: string) => void;

  ws?: WebSocket;
  url: string;
  enabled: boolean;
  reconnectInterval: number;
  cachedClusters: ObjectParameters[];
  dataReceivedSignal: WSDataReceivedSignal;
  name: 'alignWs' | 'perceptionWs' | 'nodeWs';
  lastMessagePromise: Promise<void>;

  constructor(
    url: string,
    name: 'alignWs' | 'perceptionWs' | 'nodeWs',
    dataReceivedSignal: WSDataReceivedSignal,
    reconnectInterval?: number,
  ) {
    this.url = url;
    this.enabled = true;
    this.reconnectInterval = reconnectInterval ?? WS_RECONNECT_INTERVAL;
    this.cachedClusters = [] as ObjectParameters[];
    this.name = name;
    this.dataReceivedSignal = dataReceivedSignal;
    // We save the promise associated with processing the last message
    // so that the current message can "await" it which ensures that we handle
    // all ws messages in order.
    this.lastMessagePromise = new Promise<void>((resolve) => resolve());
    this.connect();
  }

  /**
   * @brief Decodes perception data, signals out points or objects depending on what is available
   * @param buffer Buffer containing flatbuf format bytes of perception data
   */
  handlePerceptionData(buffer: ArrayBuffer): void {
    const perceptionData = Data.getRootAsData(
      new flatbuffers.ByteBuffer(new Uint8Array(buffer)),
    );

    switch (perceptionData.dataType()) {
      case DataTypes.PointClouds: {
        const point_clouds = perceptionData.data(new FlatPointClouds());
        this.onPointCloud?.(point_clouds);
        break;
      }
      case DataTypes.TrackedObjects: {
        const tracked_objects = perceptionData.data(new TrackedObjects());
        const object_parameters_map =
          createObjectParametersMap(tracked_objects);
        this.onObjects?.(object_parameters_map, this.cachedClusters);
        this.cachedClusters = [];
        break;
      }
      case DataTypes.Clusters: {
        const clusters = perceptionData.data(new Clusters());
        const object_parameters_map = createClustersParametersMap(clusters);
        // Clusters arrive before objects. Cache them and dispatch everything with
        // handle objects.
        this.cachedClusters = object_parameters_map;
        break;
      }
      case DataTypes.Image: {
        const flatImage = perceptionData.data(new FlatImage());
        const sensorImageData = populateSensorImageData(flatImage);
        if (sensorImageData === null) return;

        const { id, buffer } = sensorImageData;
        this.onImage?.(buffer, id);
        break;
      }
      default:
        break;
    }
  }

  /**
   * @brief Connects to a websocket server and sets up handlers
   */
  connect(): void {
    this.ws = new WebSocket(this.url);

    console.log('Trying to connect to ' + this.url);
    this.ws.onopen = () => {
      console.log('Connected to', this.url);
    };

    this.ws.onmessage = (message) => {
      this.dataReceivedSignal(this.name);
      if (this.onPointCloud === undefined && this.onObjects == undefined) {
        return;
      }

      if (message.data === '') {
        console.log('Received empty ws message');
        return;
      }

      this.lastMessagePromise = this.lastMessagePromise.then(() => {
        return message.data
          .arrayBuffer()
          .then((buffer: ArrayBuffer) => this.handlePerceptionData(buffer));
      });
    };

    this.ws.onclose = (event) => {
      const closedMsg = `Socket is closed (code: ${
        event.code
      }). Reconnect will be attempted in ${(
        this.reconnectInterval / SECONDS_TO_MS
      ).toFixed(0)} seconds.`;

      if (this.enabled) {
        console.log(closedMsg, event.reason);

        setTimeout(() => {
          /**
           * Only attempt reconnect if the socket wasn't manually disabled.
           * Socket may have been disabled by the time the timeout hits so
           * need this additional check
           */
          if (this.enabled && this.ws?.readyState === WebSocket.CLOSED) {
            console.log('Trying to reconnect');
            this.connect();
          }
        }, this.reconnectInterval);
      } else {
        console.log('Closed socket to ' + this.url);
      }
    };

    this.ws.onerror = (error) => {
      console.error('Socket encountered an error:', error, 'Closing socket');

      this.ws?.close();
    };
  }

  /**
   * Sends data over the websocket if it is connected
   * @param message Message to send over the websocket
   */
  send(message: string): void {
    if (this.ws != undefined && this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(message);
    }
  }

  isEnabled(): boolean {
    return this.enabled;
  }

  setEnable(enableState: boolean): void {
    this.enabled = enableState;
  }

  /**
   * Manually disables the socket. Closes
   * the connection and blocks reconnects.
   * @returns boolean, true if socket was
   * disabled successfully. False otherwise.
   */
  disable(): boolean {
    if (this.ws === undefined) {
      return false;
    }

    if (!this.isEnabled()) {
      return true;
    }

    this.setEnable(false);
    this.ws.close();

    return true;
  }

  /**
   * Manually enables the socket. Reopens
   * the connection on a new socket connection instance.
   * @returns boolean, true if socket was
   * enabled successfully. False otherwise.
   */
  enable(): boolean {
    if (this.ws === undefined) {
      return false;
    }

    if (this.isEnabled()) {
      return true;
    }

    this.setEnable(true);
    this.connect();

    return true;
  }
}

export { PerceptionWebsocket };
