/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { isAlphaNumeric } from '../util/misc';
import Input from '../app/components/Input/Input';

const RecordFileNameInput = ({
  onChange,
  value,
  disabled,
}: {
  value: string;
  onChange: (fileName: string) => void;
  disabled: boolean;
}): JSX.Element => {
  return (
    <Input
      name={'Recording Name Prefix'}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => {
        // Only allow letters, numbers, spaces, and ( ) _ -
        if (
          !isAlphaNumeric(e.key) &&
          !['(', ')', '_', '-'].includes(e.key) &&
          !e.key.includes('Arrow')
        ) {
          e.preventDefault();
        }
      }}
      value={value}
      disabled={disabled}
    />
  );
};

export default RecordFileNameInput;
