/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ComponentPropsWithoutRef, useState } from 'react';
import { Button } from '../Button';
import { ChevronIcon } from '../ChevronIcon';

const lightGrey = 'var(--greyC)';

export const PropertiesSubGroup = ({
  name,
  children,
  className,
  expandedInitValue = true,
  isReachableStatus,
}: {
  name: string;
  expandedInitValue?: boolean;
  isReachableStatus?: boolean;
} & ComponentPropsWithoutRef<'details'>): JSX.Element => {
  const [isExpanded, setExpanded] = useState(expandedInitValue);

  return (
    <div
      className={`PropertiesSubGroup flex flex-col ${
        typeof isReachableStatus === 'undefined' ? 'my-3' : 'my-1'
      }`}
    >
      <div
        className={`flex flex-row grow justify-between items-center ${
          typeof isReachableStatus === 'undefined' ? 'mb-2' : ''
        }`}
        style={{
          borderRadius: '3px',
        }}
      >
        <div
          style={{
            width: '90%',
            color: lightGrey,
            textTransform: 'capitalize',
            wordWrap: 'break-word',
          }}
          className="flex items-center"
        >
          {typeof isReachableStatus !== 'undefined' && (
            <div
              style={{
                width: 'var(--r2)',
                height: 'var(--r2)',
                marginRight: 'var(--r3)',
                borderRadius: '50%',
                backgroundColor: isReachableStatus
                  ? 'var(--active)'
                  : 'var(--inactive)',
              }}
            />
          )}
          {name}
        </div>
        <Button className="bgNone p-0" onClick={() => setExpanded((v) => !v)}>
          <ChevronIcon
            icon={isExpanded ? 'up' : 'down'}
            size="24px"
            style={{ fill: isExpanded ? 'var(--greyE)' : lightGrey }}
          />
        </Button>
      </div>
      {isExpanded && <div className={className}>{children}</div>}
    </div>
  );
};
