/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';

export const SourceTargetIcon = ({
  active,
  disabled = false,
  width = '18px',
  height = '18px',
  ...rest
}: {
  active: boolean;
  disabled: boolean;
  width?: string;
  height?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 18 18"
      viewBox="0 0 18 18"
      fill={
        disabled ? (active ? 'var(--greyD)' : 'var(--greyE)') : 'var(--greyB)'
      }
      {...rest}
    >
      <path d="M0,0h24v24H0V0z" fill="none" />
      {
        {
          on: (
            <path d="M9,.9625a6,6,0,0,0-6,6c0,3.3135,6,10.875,6,10.875s6-7.5615,6-10.875A6,6,0,0,0,9,.9625ZM9,9.325A2.325,2.325,0,1,1,11.325,7,2.325,2.325,0,0,1,9,9.325Z" />
          ),
          off: (
            <path
              d="m 14.061649,15.600358 -3.541936,-3.541935 1.53871,-1.538708 3.541935,3.541933 v -2.090322 h 2.17742 v 5.806452 h -5.806454 v -2.17742 z m -6.0329755,2.17742 v -2.17742 H 5.9383512 L 9.4802865,12.058423 7.9415768,10.519715 4.3996415,14.061648 V 11.971326 H 2.2222222 v 5.806452 z M 12.058423,9.4802852 15.600358,5.9383505 V 8.028673 h 2.17742 V 2.2222222 h -5.806454 v 2.1774192 h 2.090325 L 10.519713,7.9415761 Z M 4.3996415,5.9383505 7.9415768,9.4802852 9.4802865,7.9415761 5.9383512,4.3996414 H 8.0286735 V 2.2222222 H 2.2222222 V 8.028673 h 2.1774193 z"
              // d="m 14.644991,15.522021 -2.361291,-2.361289 1.025807,-1.025806 2.36129,2.361289 v -1.393548 h 1.451613 v 3.870968 H 13.251441 V 15.522021 Z M 7.6600446,16.973635 V 15.522021 H 6.2664964 L 8.6277865,13.160732 7.6019801,12.134926 5.24069,14.496215 V 13.102667 H 3.7890772 v 3.870968 z M 13.309507,8.4790102 15.670797,6.1177204 V 7.5112687 H 17.12241 V 3.6403015 h -3.870969 v 1.4516128 h 1.39355 L 12.2837,7.4532042 Z M 5.24069,6.1177204 7.6019801,8.4790102 8.6277865,7.4532042 6.2664964,5.0919143 H 7.6600446 V 3.6403015 H 3.7890772 V 7.5112687 H 5.24069 Z"
            />
          ),
        }[active ? 'on' : 'off']
      }
    </svg>
  );
};
