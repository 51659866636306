/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { SettingsInstance } from '../types';
import { JSONEditorMode } from './Settings';

export type SettingsInterface = Record<string, unknown>;
export interface SettingEditorError {
  path: string[];
  message: string;
}
export interface JSONEditorRef {
  jsonEditor: JSONEditor | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
}
export interface JSONEditor {
  get: () => Record<string, unknown>;
  update: (json: Record<string, unknown>) => void;
  setMode: (mode: JSONEditorMode) => void;
  expandAll: () => void;
  setName: (name: string) => void;
  frame: HTMLDivElement;
}
export interface JSONEditorTarget {
  field: string;
  path: string[];
  value: string | number | boolean;
}
export interface ValidationPayload {
  event: Event;
  target: JSONEditorTarget;
}
export const TCPServerEmptyNode = {
  source: '',
  port: '',
  data_hertz: '',
  transmit_hertz: '',
};
export const MqttPublisherEmptyNode = {
  source: '',
  host: '',
  port: '',
  user_name: '',
  password: '',
  tls: '',
  topic: '',
  qos: '',
  data_hertz: '',
  transmit_hertz: '',
};

export const instanceToDisplayName: Record<SettingsInstance, string> = {
  perception: 'Perception',
  lidarHub: 'Lidar Hub',
};

export const LidarHubNodes = {
  tcp_servers: {
    empty: TCPServerEmptyNode,
    optionalFields: new Set(),
  },
  mqtt_publishers: {
    empty: MqttPublisherEmptyNode,
    optionalFields: new Set(['user_name', 'password']),
  },
};
