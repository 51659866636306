/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import {
  Color,
  DoubleSide,
  InstancedMesh,
  Matrix4,
  MeshBasicMaterial,
  PlaneGeometry,
  Vector3,
} from 'three';
import {
  COLOR_SELECTED,
  MAX_TRACKED_OBJS_INSTANCES_COUNT,
  paletteVariations,
  snapTrackedsPosZ,
  UNIT_Z,
} from '../constants';
import { useAppState } from '../Stores';
import { Context } from '../types';

// Total number of previous positions to render per tracked object
const MAX_NUMBER_POINTS = 30;
// The minimum size of the rectangle side
const RECT_SIZE = 0.25;
const RECT_SIZE_RECIP = 1 / RECT_SIZE;
// The maximum displayed percentage of the rect/line from one point to the other
const LINE_LENGTH_RATIO = 0.667;

const dots = new InstancedMesh(
  new PlaneGeometry(RECT_SIZE, RECT_SIZE)
    .translate(0, RECT_SIZE / 2, 0)
    .rotateX(Math.PI / 2),
  new MeshBasicMaterial({ side: DoubleSide }),
  MAX_TRACKED_OBJS_INSTANCES_COUNT * MAX_NUMBER_POINTS * 3,
);
dots.name = 'Dots';
const mTran = new Matrix4();
const mScl = new Matrix4();
const mRot = new Matrix4();
const posPrev = new Vector3();
const posCurr = new Vector3();
const v3 = new Vector3();
const selectedMat = new Color(COLOR_SELECTED);

// Logic to update the tracked object's trails
export const useTrackedObjectsDotsSynch = (context: Context): void => {
  const state = useAppState();

  // show/hide
  useEffect(() => {
    const isVisible =
      state.tracked.globalVisibilities.previousPositions &&
      (state.app.inputMode === 'playback' ||
        state.app.mode === 'viewer' ||
        state.app.mode === 'zone' ||
        state.app.mode === 'recording' ||
        state.app.mode === 'preferences' ||
        state.app.mode === 'map');
    isVisible ? context.viz.scene.add(dots) : dots.removeFromParent();
  }, [state.app.mode, state.tracked.globalVisibilities.previousPositions]);

  // update all tracked objects trails
  useEffect(() => {
    const paletteVariation = paletteVariations[state.app.palette];
    let i = 0;
    state.depTrackedUserVisible.forEach((id) => {
      const { colorOffsetIndex, position, classification, previousPositions } =
        state.tracked.byId[id];
      const colorIndex = state.tracked.classificationColors[classification];
      const isSelected = state.tracked.selected[id];
      const colorO = isSelected
        ? selectedMat
        : paletteVariation[colorIndex][colorOffsetIndex];
      const z = snapTrackedsPosZ(position.z);
      const totalXYZs = Math.min(
        previousPositions.length,
        MAX_NUMBER_POINTS * 3,
      );
      let totalPointsCounter = totalXYZs - 1;
      // iterate over the previous positions,
      // array in reverse order with the most recent position at the end
      let j = previousPositions.length - 1;
      while (totalPointsCounter > 0) {
        let x = previousPositions[j - 2];
        let y = previousPositions[j - 1];
        posCurr.set(x, y, z);
        x = previousPositions[j - 5];
        y = previousPositions[j - 4];
        posPrev.set(x, y, z);
        mRot.lookAt(posPrev, posCurr, UNIT_Z);
        //
        mTran.makeTranslation(posCurr.x, posCurr.y, posCurr.z);
        // scale down to square as previous position gets older ( people have rects no matter  )
        if (classification !== 'Person') {
          let scl = totalPointsCounter / totalXYZs;
          scl *= 0.75;
          scl += 0.25;
          let ss =
            scl *
            v3.subVectors(posPrev, posCurr).length() *
            RECT_SIZE_RECIP *
            LINE_LENGTH_RATIO;
          ss = Math.max(ss, RECT_SIZE); //cant be smaller than the width of the line
          mScl.makeScale(RECT_SIZE, 1, ss);
        } else {
          mScl.makeScale(RECT_SIZE, 1, RECT_SIZE);
        }
        //
        dots.setMatrixAt(i, mTran.multiply(mRot).multiply(mScl));
        dots.setColorAt(i, colorO);
        i++;
        totalPointsCounter -= 3;
        j -= 3;
      }
    });
    dots.count = i;
    dots.instanceMatrix.needsUpdate = true;
    if (dots.instanceColor) dots.instanceColor.needsUpdate = true;
    dots.material.needsUpdate = true;
  }, [
    state.depTrackedUserVisible,
    state.tracked.byId,
    state.tracked.classificationVisibilities,
    state.tracked.classificationColors,
    state.app.palette,
  ]);
};
