/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { Palettes } from '../constants';
import { useAppState } from '../Stores';
import { Context, PerceptionClassifications } from '../types';
import {
  putObjectToPool,
  getObjectFromPool,
  setColor,
  TrackedObject3JS,
  setAllBBoxesVis,
} from './TrackedObject3JS';
import { TrackedObjectState } from './TrackedObjectsStore';

const hide = (context: Context, id: string) => {
  const tracked = context.instances.TrackedObject.all[id];
  if (!tracked) return;
  putObjectToPool(tracked);
  tracked.isActive = false;
  delete context.instances.TrackedObject.all[id];
  tracked.removeFromParent();
};
const show = (
  context: Context,
  id: string,
  byId: Record<string, TrackedObjectState>,
) => {
  const params: TrackedObjectState | undefined = byId[id];
  if (params === undefined) return;
  const { dimensions, position, classification, rotation } = params;

  let tracked: TrackedObject3JS | undefined =
    context.instances.TrackedObject.all[id];
  if (tracked === undefined) {
    tracked = getObjectFromPool(
      classification,
      id,
      position,
      dimensions,
      rotation,
    );
    context.instances.TrackedObject.all[id] = tracked;
    context.groups3JS.TrackedObject.add(tracked);
    tracked.isActive = true;
  } else {
    tracked.set(classification, id, position, dimensions, rotation);
  }
};

// Synchronize all active TrackedObject3JS instances with the Store's values.
// Use TrackedObject3JS's pool and reflect those changes to the
// Context.instances.TrackedObject.all reference object
export const useTrackedObjectsSynch = (context: Context): void => {
  const state = useAppState();
  const palette = Palettes[state.app.palette];

  // Release
  useEffect(() => {
    state.tracked.toRelease.forEach((id) => {
      hide(context, id);
    });
  }, [state.tracked.toRelease]);

  // Invisible
  useEffect(() => {
    state.depTrackedUserInvisible.forEach((id) => {
      hide(context, id);
    });
  }, [state.depTrackedUserInvisible]);

  // Visible changes
  useEffect(() => {
    for (const id of state.depTrackedUserVisible) {
      show(context, id, state.tracked.byId);
    }
  }, [state.depTrackedUserVisible]);

  // BBox changes
  useEffect(() => {
    setAllBBoxesVis(state.tracked.globalVisibilities.bbox);
    for (const id of state.depTrackedUserVisible) {
      show(context, id, state.tracked.byId);
    }
  }, [state.tracked.globalVisibilities.bbox]);

  // PerceptionClassifications Colors
  useEffect(() => {
    for (let i = 0; i < PerceptionClassifications.length; i++) {
      const trackedClass = PerceptionClassifications[i];
      const colorIndex = state.tracked.classificationColors[trackedClass];
      setColor(trackedClass, palette[colorIndex]);
    }
  }, [state.tracked.classificationColors, palette]);

  // handle selection changes
  useEffect(() => {
    for (const [id, isSelected] of Object.entries(state.tracked.selected)) {
      const trackObj = context.instances.TrackedObject.all[id];
      if (trackObj !== undefined) trackObj.isSelected = isSelected;
    }
  }, [state.tracked.selected]);

  // handle highlights
  useEffect(() => {
    for (const [id, isHigh] of Object.entries(state.tracked.highlighted)) {
      const trackObj = context.instances.TrackedObject.all[id];
      if (trackObj !== undefined) trackObj.isHighlighted = isHigh;
    }
  }, [state.tracked.highlighted]);
};
