/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ComponentPropsWithoutRef, useState } from 'react';
import { Button } from '../Button';
import { ChevronIcon } from '../ChevronIcon';

export const PropertiesGroup = ({
  name,
  visible = true,
  children,
  expandedInitValue,
}: {
  name: string;
  visible?: boolean;
  expandedInitValue?: boolean;
} & ComponentPropsWithoutRef<'details'>): JSX.Element | null => {
  const [isExpanded, setExpanded] = useState(expandedInitValue);

  return visible ? (
    <div className="PropertiesGroup flex flex-col mb-4">
      <div
        className="text-md textGreyB my-2 flex items-center"
        style={{
          borderBottom: 'solid 1px var(--greyE)',
        }}
      >
        <p
          style={{
            width: '90%',
            lineHeight: 'var(--r9)',
            textTransform: 'capitalize',
          }}
        >
          {name}
        </p>
        {isExpanded !== undefined && (
          <Button className="bgNone p-0" onClick={() => setExpanded((v) => !v)}>
            <ChevronIcon
              icon={isExpanded ? 'up' : 'down'}
              size="24px"
              style={{ fill: isExpanded ? 'var(--greyE)' : 'var(--greyC)' }}
            />
          </Button>
        )}
      </div>
      {isExpanded !== false && <div>{children}</div>}
    </div>
  ) : null;
};
