/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { DISPLAY_MODE_ABSOLUTES } from '../../../../constants';
import { useAppDispatch, useAppState } from '../../../../Stores';
import { PointDescriptorInUse } from '../../../../types';
import NumberInputRow from '../../NumberInput/NumberRow';

type CloudProps = {
  type: 'clouds';
  descriptor: PointDescriptorInUse;
};
type SensorProps = {
  type: 'sensors';
  descriptor?: undefined;
};

const ColorModeMinMaxInput = ({
  type,
  descriptor,
}: CloudProps | SensorProps): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const { display } =
    type === 'sensors' ? state.sensors : state.clouds[descriptor];
  // Step by 1 for 8 bit channels
  const is8Bit = ['Signal', 'Reflectivity', 'Nearir', 'CalRef'].includes(
    display.colorMode,
  );
  const step = is8Bit ? 1 : 0.1;
  const precision = is8Bit ? 0 : 2;

  return (
    <div className="flex flex-col">
      <NumberInputRow
        blurb="Color Mode Min"
        isDirty={false}
        disabled={['CalRef', 'Fixed'].includes(display.colorMode)}
        forceUpdate={display.colorRange[display.colorMode].min}
        value={Number(
          display.colorRange[display.colorMode].min.toFixed(precision),
        )}
        max={display.colorRange[display.colorMode].max}
        min={DISPLAY_MODE_ABSOLUTES[display.colorMode].min}
        step={step}
        precision={precision}
        onChangeNumber={(value) => {
          if (type === 'sensors') {
            dispatch({
              type: 'setSensorMinMax',
              mode: display.colorMode,
              variant: 'min',
              value,
            });
          } else {
            dispatch({
              type: 'setProcessedCloudMinMax',
              descriptor,
              mode: display.colorMode,
              variant: 'min',
              value,
            });
          }
        }}
      />
      <NumberInputRow
        blurb="Color Mode Max"
        isDirty={false}
        disabled={['CalRef', 'Fixed'].includes(display.colorMode)}
        forceUpdate={display.colorRange[display.colorMode].max}
        value={Number(
          display.colorRange[display.colorMode].max.toFixed(precision),
        )}
        min={display.colorRange[display.colorMode].min}
        max={DISPLAY_MODE_ABSOLUTES[display.colorMode].max}
        step={step}
        precision={precision}
        onChangeNumber={(value) => {
          if (type === 'sensors') {
            dispatch({
              type: 'setSensorMinMax',
              mode: display.colorMode,
              variant: 'max',
              value,
            });
          } else {
            dispatch({
              type: 'setProcessedCloudMinMax',
              descriptor,
              mode: display.colorMode,
              variant: 'max',
              value,
            });
          }
        }}
      />
    </div>
  );
};

export default ColorModeMinMaxInput;
