/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { LIDAR_HUB_REST, REST } from '../constants';
import { safeFetch } from '../safeFetch';
import { SettingsInterface } from '../settingsEditor/settingsTypes';
import { ServerMessageResponse, SettingsInstance } from '../types';

export type ProfileList = {
  current_profile: string;
  profiles: string[];
};

const restInterface = {
  perception: REST,
  lidarHub: LIDAR_HUB_REST,
};

// Default settings to empty object
export const fetchSettings = async (
  instance: SettingsInstance,
): Promise<SettingsInterface> =>
  safeFetch.json.get<SettingsInterface, SettingsInterface>(
    restInterface[instance].settings.settings,
    undefined,
    {} as SettingsInterface,
  );

export const fetchDefaultLidarHubSettings =
  async (): Promise<SettingsInterface> => {
    await safeFetch.post(
      restInterface.lidarHub.settings.getDefault,
      undefined,
      {} as SettingsInterface,
    );

    return await fetchSettings('lidarHub');
  };

// Sets all settings / all profiles
export const pushSettings = async (
  newSettings: SettingsInterface,
  instance: SettingsInstance,
): Promise<void> => {
  await safeFetch.post(restInterface[instance].settings.settings, newSettings);
};

/** Perception Profile API **/
export const setProfileActive = async (
  profile: string,
): Promise<ServerMessageResponse | null> => {
  return safeFetch.json.put<ServerMessageResponse>(
    REST.settings.setProfile(profile),
  );
};

export const getProfile = async (
  profile: string,
): Promise<SettingsInterface> => {
  return profile === 'all'
    ? fetchSettings('perception')
    : safeFetch.json.get<SettingsInterface, SettingsInterface>(
        REST.settings.profile(profile),
        undefined,
        {} as SettingsInterface,
      );
};

// Updates the specified profile or
// creates it if it does not exist.
export const writeProfile = async (
  profile: string,
  newSettingsProfile: SettingsInterface,
): Promise<void | ServerMessageResponse | null> => {
  return profile === 'all'
    ? pushSettings(newSettingsProfile, 'perception')
    : safeFetch.json.put<ServerMessageResponse>(
        REST.settings.profile(profile),
        newSettingsProfile,
      );
};

export const deleteProfile = async (
  profile: string,
): Promise<ServerMessageResponse | null> => {
  return safeFetch.json.delete<ServerMessageResponse>(
    REST.settings.profile(profile),
  );
};

export const listProfiles = async (): Promise<ProfileList | null> => {
  return safeFetch.json.get<ProfileList>(REST.settings.listProfiles);
};

export const restoreProfile = async (
  profile: string,
): Promise<ServerMessageResponse | null> => {
  return safeFetch.json.put<ServerMessageResponse>(
    REST.settings.restoreProfile(profile),
  );
};

export const settings = {
  perception: {
    fetch: (profile: string) => getProfile(profile),
    push: (newSettings: SettingsInterface) =>
      pushSettings(newSettings, 'perception'),
    activateProfile: (profile: string) => setProfileActive(profile),
    getProfile: (profile: string) => getProfile(profile),
    updateProfile: (profile: string, newProfile: SettingsInterface) =>
      writeProfile(profile, newProfile),
    deleteProfile: (profile: string) => deleteProfile(profile),
    listProfiles: () => listProfiles(),
    restoreProfile: (profile: string) => restoreProfile(profile),
  },
  lidarHub: {
    // Profile is unused but kept here to respect the common interface
    // with fetch above in perception.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetch: (profile: string) => fetchSettings('lidarHub'),
    fetchDefault: () => fetchDefaultLidarHubSettings(),
    push: (newSettings: SettingsInterface) =>
      pushSettings(newSettings, 'lidarHub'),
  },
} as const;
