/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Vector2 } from 'three';
import { REST, EVENT_REST } from '../constants';
import { FetchOptions, safeFetch } from '../safeFetch';
import { ZoneType } from '../types';
import {
  ZoneSchema,
  ZonesSchema,
} from '../zone/eventZone/EventZoneServerInterface';

export const event = {
  fetchZones: async (options?: FetchOptions): Promise<Response | null> =>
    await safeFetch.get(EVENT_REST.eventZones, options),

  addZone: async (
    zone: ZoneSchema,
    options?: FetchOptions,
  ): Promise<Response | null> => {
    const { id, metadata } = zone;
    if (metadata) {
      // Enforce JSON format. We parse to avoid escaping the string.
      zone = { ...zone, metadata: JSON.parse(metadata) };
    }
    return await safeFetch.put(EVENT_REST.eventZone(id), zone, options);
  },

  setZones: async (
    zones: ZonesSchema,
    options?: FetchOptions,
  ): Promise<Response | null> => {
    return await safeFetch.put(EVENT_REST.eventZones, zones, options);
  },
  deleteZone: async (
    id: number,
    options?: FetchOptions,
  ): Promise<Response | null> => {
    return await safeFetch.delete(EVENT_REST.eventZone(id), options);
  },
};

export const point = {
  fetchZones: async (options?: FetchOptions): Promise<Response | null> =>
    await safeFetch.get(REST.pointZones, options),

  addZone: async (
    zone: ZoneSchema,
    options?: FetchOptions,
  ): Promise<Response | null> => {
    const { id, metadata } = zone;
    if (metadata) {
      // Enforce JSON format. We parse to avoid escaping the string.
      zone = { ...zone, metadata: JSON.parse(metadata) };
    }
    return await safeFetch.put(REST.pointZone(id), zone, options);
  },

  setZones: async (
    zones: ZonesSchema,
    options?: FetchOptions,
  ): Promise<Response | null> => {
    return await safeFetch.put(REST.pointZones, zones, options);
  },
  deleteZone: async (
    id: number,
    options?: FetchOptions,
  ): Promise<Response | null> => {
    return await safeFetch.delete(REST.pointZone(id), options);
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const payloadZone = (
  id: number,
  type: ZoneType,
  name: string,
  min: number,
  max: number,
  vertices: Vector2[],
  metadata: string,
): ZoneSchema => {
  const payload: ZoneSchema = {
    id,
    type,
    name,
    max_height: max,
    min_height: min,
    vertices,
    metadata,
  };
  return payload;
};
