/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/cognitive-complexity */

import { useCallback, useEffect, useRef } from 'react';
import { Raycaster } from 'three';
import { PointerType, usePointerEvents } from '../app/hooks/usePointerEvents';
import { useAppDispatch, useAppState } from '../Stores';
import { Context } from '../types';
import { Source3JS } from './Source3JS';

// TODO(emmanuel): tmp solution upgrade to picking with state machine or rx
export const useSourceSelect = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const rayCaster = useRef(new Raycaster());
  const highlighted = useRef<Source3JS | null>(null);

  // manage reference instance state
  useEffect(() => {
    const id = state.setup.reference;
    if (id === null) return;
    const sources = state.sensors.allIds.includes(id)
      ? state.sensors
      : state.nodes;
    const sourceContext = context.instances.Source;
    if (sourceContext.referenceSource?.oId !== id) {
      if (sourceContext.all[id]) {
        sourceContext.referenceSource = context.instances.Source.all[id];
        const isVisible = sources.visibilities[id];
        sourceContext.referenceSource.setReferenceFrame(isVisible);
      } else {
        console.error(`Source context ${id} doesn't exist.`);
      }
    }

    return () => {
      sourceContext.referenceSource?.setReferenceFrame(false);
      sourceContext.referenceSource = null;
    };
  }, [
    state.setup.reference,
    state.sensors.visibilities,
    state.nodes.visibilities,
  ]);

  // manage selected instance state
  useEffect(() => {
    const id = state.setup.selected;
    if (id === null) return;
    const sourceContext = context.instances.Source;
    const isSensor = state.sensors.allIds.includes(id);
    const setTransformed = () => {
      dispatch({
        type: isSensor ? 'setSensorTransformed' : 'setNodeTransformed',
        id,
        value: true,
      });
    };
    if (sourceContext.selectedSource?.oId !== id) {
      if (sourceContext.all[id]) {
        sourceContext.selectedSource = sourceContext.all[id];
        sourceContext.selectedSource.isSelected = true;
        if (state.app.tool !== 'Select') {
          context.transformControls.attach(sourceContext.selectedSource.pose);
          context.viz.scene.add(context.transformControls);
          context.transformControls.active = true;
          context.transformControls.addEventListener('change', setTransformed);
        }
      } else {
        console.error(`Source context ${id} doesn't exist.`);
      }
    }

    return () => {
      if (sourceContext.selectedSource)
        sourceContext.selectedSource.isSelected = false;
      sourceContext.selectedSource = null;
      context.transformControls.detach();
      context.transformControls.removeFromParent();
      context.transformControls.removeEventListener('change', setTransformed);
    };
  }, [state.setup.selected, state.app.tool]);

  const handler = useCallback(
    (pointer: PointerType) => {
      const pointerType = pointer.eventType;
      const { x, y } = pointer;
      rayCaster.current.setFromCamera({ x, y }, context.viz.camera);

      const proxies = context.groups3JS.Source.children.map(
        (e) => (e as Source3JS).raycastMesh,
      );
      if (proxies.length === 0) return;
      const intersects = rayCaster.current
        .intersectObjects(proxies)
        .map((e) => e.object);

      // there is at least one intersection
      if (intersects.length) {
        // get actual Source3JS instance as raycast test against proxy
        const source = intersects[0].userData.actual as Source3JS;

        if (pointerType === 'pointermove') {
          if (
            context.instances.Source.selectedSource == source ||
            highlighted.current == source
          )
            return;
          if (highlighted.current) highlighted.current.isHighlighted = false;
          source.isHighlighted = true;
          highlighted.current = source;
        } else if (pointerType === 'click') {
          if (source.isSelected) {
            dispatch({ type: 'setSelectedSource', id: null });
            source.isHighlighted = true;
            highlighted.current = source;
          } else if (highlighted.current) {
            highlighted.current.isHighlighted = false;
            highlighted.current = null;
            dispatch({ type: 'setSelectedSource', id: source.oId });
            if (state.setup.reference === source.oId) {
              dispatch({ type: 'setReferenceSource', id: null });
            }
          }
          // single selection workflow, as soon the user selects a source go back to navigation
          if (state.app.tool === 'Select')
            dispatch({ type: 'setTool', value: 'Camera' });
        }
        // no intersections
      } else {
        if (pointerType === 'pointermove') {
          if (highlighted.current) highlighted.current.isHighlighted = false;
          highlighted.current = null;
        }
      }
    },
    [dispatch, state.setup.reference, state.app.tool],
  );

  const setActive = usePointerEvents(context.canvas, handler);

  // Select
  useEffect(() => {
    if (state.app.tool === 'Select') {
      setActive(true);
    } else {
      if (highlighted.current) highlighted.current.isHighlighted = false;
      highlighted.current = null;
      setActive(false);
    }
  }, [state.app.tool]);

  // In/Out logic
  useEffect(() => {
    return () => {
      setActive(false);
      if (highlighted.current) highlighted.current.isHighlighted = false;
      highlighted.current = null;
      dispatch({ type: 'setSelectedSource', id: null });
      dispatch({ type: 'setReferenceSource', id: null });
    };
  }, []);
};
