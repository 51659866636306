/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
export const Logo = ({
  width = '0.75rem',
  height,
  ...rest
}: {
  width: string;
  height?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      className="Logo"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="30 30 77 46"
      fill="var(--greyA)"
      {...rest}
    >
      <g>
        <path
          d="M68.7,56.7c12.9,0,25.6,0.8,38.1,2.4V47.9c-12.5,1.6-25.2,2.4-38.1,2.4S43,49.5,30.6,47.9V59
				C43,57.5,55.8,56.7,68.7,56.7z"
        ></path>
        <path
          d="M106.8,30.6C95.6,37,82.6,40.8,68.7,40.8S41.8,37,30.6,30.6v11.8c12.2,3.1,25,4.8,38.1,4.8
					s25.9-1.7,38.1-4.8V30.6z"
        ></path>
        <path
          d="M106.8,76.4V64.6c-12.2-3.1-25-4.8-38.1-4.8s-25.9,1.7-38.1,4.8v11.8c11.2-6.5,24.2-10.2,38.1-10.2
					S95.6,69.9,106.8,76.4z"
        ></path>
      </g>
    </svg>
  );
};
