/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-duplicate-string */
import { useEffect, useRef } from 'react';
import { Quaternion, Vector3 } from 'three';
import { Context, Pose } from '../types';
import { useAppDispatch, useAppState } from '../Stores';
import { endpoints } from '../api/endpoints';
import { getPose } from '../util/misc';
import { unstable_batchedUpdates } from 'react-dom';

const {
  align: { callICP, putExtrinsic },
} = endpoints;

type LastPose = Pose & { id: string | null };

export const useAlign = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const unalignedLastPose = useRef<LastPose | null>(null);

  useEffect(() => {
    unalignedLastPose.current = {
      id: null,
      pos: new Vector3(),
      rot: new Quaternion(),
    };
  }, []);

  // In/Out logic
  useEffect(() => {
    if (state.app.mode !== 'setup') return;
    return () => {
      dispatch({ type: 'setTool', value: 'Camera' });
    };
  }, [state.app.mode]);

  // fetch
  useEffect(() => {
    const reference = state.setup.reference;
    const unaligned = state.setup.selected;

    if (
      state.setup.alignPhase === 'request' &&
      reference &&
      unaligned &&
      unalignedLastPose.current !== null
    ) {
      const ref3JS = context.instances.Source.all[reference];
      const unAligned3JS = context.instances.Source.all[unaligned];
      if (!ref3JS || !unAligned3JS) return;

      unalignedLastPose.current.id = unaligned;
      unalignedLastPose.current.pos.copy(unAligned3JS.pose.position);
      unalignedLastPose.current.rot.copy(unAligned3JS.pose.quaternion);

      const notUsed = new Vector3();
      // get world matrix of reference
      ref3JS.pose.updateWorldMatrix(true, false);
      const refWorldQuart = new Quaternion();
      const refWorldPos = new Vector3();
      ref3JS.pose.matrixWorld.decompose(refWorldPos, refWorldQuart, notUsed);
      // get world matrix of unaligned
      unAligned3JS.pose.updateWorldMatrix(true, false);
      const unalignedWorldQuart = new Quaternion();
      const unalignedWorldPos = new Vector3();
      unAligned3JS.pose.matrixWorld.decompose(
        unalignedWorldPos,
        unalignedWorldQuart,
        notUsed,
      );

      const gizmo = context.transformControls;
      gizmo.active = false;
      gizmo.detach();
      gizmo.reset();
      gizmo.removeFromParent();

      callICP(
        unAligned3JS.oId,
        unalignedWorldPos,
        unalignedWorldQuart,
        ref3JS.oId,
        refWorldPos,
        refWorldQuart,
        (id, pos, rot) => {
          const unaligned = context.instances.Source.all[id];
          unaligned.pose.quaternion.copy(rot);
          unaligned.pose.position.copy(pos);
          unaligned.updateMatrix();
          unstable_batchedUpdates(() => {
            dispatch({ type: 'setAlignPhase', value: 'evaluate' });
            dispatch({
              type: state.sensors.allIds.includes(id)
                ? 'setSensorTransformed'
                : 'setNodeTransformed',
              id: id,
              value: true,
            });
          });
        },
        () => {
          dispatch({ type: 'setAlignPhase', value: 'reject' });
        },
      );
    }
  }, [state.setup.alignPhase, state.setup.selected, state.setup.reference]);

  // approve
  useEffect(() => {
    if (unalignedLastPose.current === null) {
      return;
    }
    const referenceId = state.setup.reference;
    const unalignedId = unalignedLastPose.current.id;

    if (
      state.setup.alignPhase !== 'approve' ||
      unalignedId === null ||
      referenceId === null
    ) {
      return;
    }
    const cloud = context.instances.Source.all[unalignedId];
    cloud.cloud.updateWorldMatrix(true, false);
    const pose = getPose(cloud.cloud.matrixWorld);
    putExtrinsic(
      unalignedId,
      pose,
      () => {
        dispatch({
          type: state.sensors.allIds.includes(unalignedId)
            ? 'setSensorExtrinsic'
            : 'setNodeExtrinsic',
          id: unalignedId,
          value: pose,
        });
        dispatch({
          type: state.sensors.allIds.includes(unalignedId)
            ? 'setSensorTransformed'
            : 'setNodeTransformed',
          id: unalignedId,
          value: false,
        });
        dispatch({ type: 'setAlignPhase', value: 'none' });
        dispatch({ type: 'setSelectedSource', id: null });
      },
      () => {
        console.error('useAlign: Something went wrong in approval.');
      },
    );

    unalignedLastPose.current.id = null;
  }, [state.setup.alignPhase, state.sensors.allIds, state.nodes.allIds]);

  // reject
  useEffect(() => {
    if (unalignedLastPose.current === null) {
      return;
    }
    const id = unalignedLastPose.current.id;
    if (state.setup.alignPhase !== 'reject' || !id) return;
    const unaligned = context.instances.Source.all[id];
    // restore previous values
    unaligned.pose.position.copy(unalignedLastPose.current.pos);
    unaligned.pose.quaternion.copy(unalignedLastPose.current.rot);
    unaligned.pose.updateMatrix();
    unalignedLastPose.current.id = null;

    const gizmo = context.transformControls;
    gizmo.attach(unaligned.pose);
    gizmo.active = true;
    context.viz.scene.add(gizmo);

    dispatch({ type: 'setAlignPhase', value: 'none' });
  }, [state.setup.alignPhase]);

  // usePose
  useEffect(() => {
    if (state.setup.posePhase === 'none') return;
    const id = state.setup.selected;
    if (!id) return;
    const source = context.instances.Source.all[id];
    source.cloud.updateWorldMatrix(true, false);
    const pose = getPose(source.cloud.matrixWorld);
    putExtrinsic(id, pose, () => {
      dispatch({ type: 'setPosePhase', value: 'none' });
      dispatch({
        type: state.sensors.allIds.includes(id)
          ? 'setSensorExtrinsic'
          : 'setNodeExtrinsic',
        id,
        value: pose,
      });
      dispatch({
        type: state.sensors.allIds.includes(id)
          ? 'setSensorTransformed'
          : 'setNodeTransformed',
        id,
        value: false,
      });
      source.pose.quaternion.copy(pose.rot);
      source.pose.position.copy(pose.pos);
    });
  }, [state.setup.posePhase, state.setup.selected]);
};
