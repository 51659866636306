/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Alert, SourceType, ZoneAlert } from '../api/alerts';
import { AlertActivities } from '../types';
import { getAlertSourceType, ObjectKeys } from '../util/misc';
import { REST } from '../constants';
import { safeFetch } from '../safeFetch';
import { DiagnosticsResponse } from '../api/diagnostics';

export type DiagnosticsState = {
  filterCode: string;
  filterLevel: string;
  filterActivity: AlertActivities;
  filterTime: string;
  allAlerts: Record<string, Alert>;
  allZoneAlerts: ZoneAlert[];
  systemAlertIds: string[];
  sensorAlertIds: string[];
  isCollapsed: Record<string, boolean>;
  isSelected: Record<string, boolean>;
  hardwareDiagnostics: DiagnosticsResponse;
};

export const DiagnosticsInitialState: DiagnosticsState = {
  filterCode: 'All Codes',
  filterLevel: 'All Levels',
  filterActivity: 'All Activities',
  filterTime: 'All Times',
  allAlerts: {},
  allZoneAlerts: [],
  systemAlertIds: [],
  sensorAlertIds: [],
  isCollapsed: { outerSystem: false, outerSensor: false },
  isSelected: {},
  hardwareDiagnostics: {
    hardware_id: '',
    latitude: null,
    longitude: null,
    compute: {
      last_upgrade_timestamp: null,
      total_cpu_cores: null,
      cpu_utilization: null,
      cpu_temperature_deg_c: null,
      total_memory: null,
      available_memory: null,
      boot_timestamp: null,
    },
    perception_server: {
      is_paused: null,
      num_objects: null,
      num_tcp_clients: null,
      timestamp: null,
    },
    event_zone_server: {
      num_occupations: null,
      num_tcp_clients: null,
      timestamp: null,
    },
    lidar_hub: {
      mqtt_publishers_connected: null,
    },
    timestamp: null,
  },
};

export type DiagnosticsActions =
  | {
      type: 'setAlerts';
      alerts: Alert[];
    }
  | {
      type: 'setZoneAlerts';
      alerts: ZoneAlert[];
    }
  | {
      type: 'setFilterCode';
      value: string;
    }
  | {
      type: 'setFilterLevel';
      value: string;
    }
  | {
      type: 'setFilterActivity';
      value: AlertActivities;
    }
  | {
      type: 'setFilterTime';
      value: string;
    }
  | {
      type: 'setCollapsedState';
      instance: string;
      value: boolean;
    }
  | {
      type: 'collapseAll';
    }
  | {
      type: 'expandAll';
    }
  | {
      type: 'selectAlert';
      id: string;
      value: boolean;
    }
  | {
      type: 'selectAllAlertsBySource';
      sourceType: SourceType;
      sourceId: string;
      value: boolean;
    }
  | {
      type: 'selectAllAlertsByType';
      sourceType: SourceType | 'all';
      value: boolean;
    }
  | {
      type: 'clearLoggedAlerts';
    }
  | {
      type: 'setHardwareDiagnostics';
      value: DiagnosticsResponse;
    };

export const matchesFilterCode = (filterCode: string, code: string): boolean =>
  filterCode === 'All Codes' || filterCode === code;
export const matchesFilterLevel = (
  filterLevel: string,
  level: string,
): boolean => filterLevel === 'All Levels' || filterLevel === level;
export const matchesFilterActivity = (
  filterActivity: string,
  activity: boolean,
): boolean =>
  filterActivity === 'All Activities' ||
  activity === (filterActivity === 'active');
export const matchesFilterTime = (
  filterTime: string,
  time_us: number,
): boolean => {
  if (filterTime === 'All Times') return true;

  const now_ms = Date.now();
  const diff_s = (now_ms - time_us / 1000) / 1000;

  switch (filterTime) {
    case 'Last 10 Minutes':
      return diff_s < 60 * 10;
    case 'Last 1 Hour':
      return diff_s < 60 * 60;
    case 'Last 24 Hours':
      return diff_s < 60 * 60 * 24;
    case 'Last 7 Days':
      return diff_s < 60 * 60 * 24 * 7;
    case 'Last 30 Days':
      return diff_s < 60 * 60 * 24 * 30;
    case 'Before Today':
      return diff_s > 60 * 60 * 24;
    case 'Before This Week':
      return diff_s > 60 * 60 * 24 * 7;
  }
  return true;
};

export const DiagnosticsReducer = (
  state: DiagnosticsState,
  action: DiagnosticsActions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): DiagnosticsState => {
  const { type } = action;
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (type) {
    case 'setAlerts': {
      const { alerts } = action;

      const allAlerts = {} as Record<string, Alert>;
      const systemAlertIds = [] as string[];
      const sensorAlertIds = [] as string[];
      const isCollapsed = { ...state.isCollapsed } as Record<string, boolean>;
      const isSelected = { ...state.isSelected } as Record<string, boolean>;

      if (alerts.length === 0) {
        return state;
      }

      alerts.forEach((alert) => {
        const { id, sourceInfo } = alert;
        const type = getAlertSourceType(alert.alertCode);
        if (type === 'system') {
          systemAlertIds.push(id);
        } else {
          sensorAlertIds.push(id);
        }

        allAlerts[id] = alert;
        isCollapsed[sourceInfo] = isCollapsed[sourceInfo] ?? false;
        isSelected[id] = isSelected[id] ?? false;
      });

      return {
        ...state,
        allAlerts,
        systemAlertIds,
        sensorAlertIds,
        isCollapsed,
        isSelected,
      };
    }
    case 'setZoneAlerts': {
      const { alerts } = action;
      const allZoneAlerts = alerts;
      return {
        ...state,
        allZoneAlerts,
      };
    }
    case 'setFilterCode': {
      const { value } = action;
      if (state.filterCode === value) {
        return state;
      }
      const isSelected = { ...state.isSelected };
      !value.includes('All') &&
        ObjectKeys(isSelected)
          .filter((id) => isSelected[id])
          .forEach((id) => {
            isSelected[id] = matchesFilterCode(
              value,
              state.allAlerts[id].alertCode,
            );
          });
      return { ...state, filterCode: value, isSelected };
    }

    case 'setFilterLevel': {
      const { value } = action;
      if (state.filterLevel === value) {
        return state;
      }
      const isSelected = { ...state.isSelected };
      !value.includes('All') &&
        ObjectKeys(isSelected)
          .filter((id) => isSelected[id])
          .forEach((id) => {
            isSelected[id] = matchesFilterLevel(
              value,
              state.allAlerts[id].level,
            );
          });
      return { ...state, filterLevel: value, isSelected };
    }

    case 'setFilterActivity': {
      const { value } = action;
      if (state.filterActivity === value) {
        return state;
      }
      const isSelected = { ...state.isSelected };
      !value.includes('All') &&
        ObjectKeys(isSelected)
          .filter((id) => isSelected[id])
          .forEach((id) => {
            isSelected[id] = matchesFilterActivity(
              value,
              state.allAlerts[id].active,
            );
          });
      return { ...state, filterActivity: value, isSelected };
    }

    case 'setFilterTime': {
      const { value } = action;
      if (state.filterTime === value) {
        return state;
      }
      const isSelected = { ...state.isSelected };
      !value.includes('All') &&
        ObjectKeys(isSelected)
          .filter((id) => isSelected[id])
          .forEach((id) => {
            isSelected[id] = matchesFilterTime(
              value,
              state.allAlerts[id].lastOccured,
            );
          });
      return { ...state, filterTime: value, isSelected };
    }

    case 'setCollapsedState': {
      const { value, instance } = action;
      if (state.isCollapsed[instance] === value) {
        return state;
      }
      return {
        ...state,
        isCollapsed: {
          ...state.isCollapsed,
          [instance]: value,
        },
      };
    }

    case 'collapseAll': {
      const isCollapsed = { ...state.isCollapsed };
      ObjectKeys(isCollapsed).map((sourceId) => (isCollapsed[sourceId] = true));
      return {
        ...state,
        isCollapsed,
      };
    }

    case 'expandAll': {
      const isCollapsed = { ...state.isCollapsed };
      ObjectKeys(isCollapsed).map(
        (sourceId) => (isCollapsed[sourceId] = false),
      );
      return {
        ...state,
        isCollapsed,
      };
    }

    case 'selectAlert': {
      const { id, value } = action;
      if (state.isSelected[id] === value) {
        return state;
      }

      return {
        ...state,
        isSelected: {
          ...state.isSelected,
          [id]: value,
        },
      };
    }

    case 'selectAllAlertsBySource': {
      const { sourceId, sourceType, value } = action;

      const isSelected = { ...state.isSelected };
      const idsList =
        sourceType === 'sensor' ? 'sensorAlertIds' : 'systemAlertIds';

      state[idsList]
        .filter((id) => state.allAlerts[id].sourceInfo === sourceId)
        .forEach((id) => {
          isSelected[id] = value;
        });

      return {
        ...state,
        isSelected,
      };
    }

    case 'selectAllAlertsByType': {
      const { sourceType, value } = action;
      const isSelected = { ...state.isSelected };

      const selectAllInSourceType = (type: 'system' | 'sensor') => {
        const idsList = type === 'sensor' ? 'sensorAlertIds' : 'systemAlertIds';

        state[idsList].forEach((id) => {
          isSelected[id] = value;
        });
      };

      if (sourceType === 'all') {
        selectAllInSourceType('system');
        selectAllInSourceType('sensor');
      } else {
        selectAllInSourceType(sourceType);
      }

      return {
        ...state,
        isSelected,
      };
    }

    case 'clearLoggedAlerts': {
      const allAlerts = { ...state.allAlerts };

      const idsToDelete = ObjectKeys(state.allAlerts).filter((id) =>
        allAlerts[id] ? !allAlerts[id].active : false,
      );

      safeFetch.json.delete(REST.alerts.clearLogged);

      idsToDelete.forEach((id) => {
        delete allAlerts[id];
      });
      const systemAlertIds = state.systemAlertIds.filter((id) =>
        allAlerts[id] ? !allAlerts[id].active : false,
      );
      const sensorAlertIds = state.sensorAlertIds.filter((id) =>
        allAlerts[id] ? !allAlerts[id].active : false,
      );

      return {
        ...state,
        allAlerts,
        systemAlertIds,
        sensorAlertIds,
      };
    }

    case 'setHardwareDiagnostics': {
      const { value } = action;
      const hardwareDiagnostics = value;

      return {
        ...state,
        hardwareDiagnostics,
      };
    }
    default:
      return state;
  }
};
