/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from '../app/components/Button';
import { PropertiesGroup } from '../app/components/pane/PropertiesGroup';
import { useAppDispatch, useAppState } from '../Stores';
import { SelectedTrackedObjs } from './SelectedTrackedObjs';
import { TrackedAllList } from './TrackedAllList';

function TrackedProperties(): JSX.Element {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const ids = state.tracked.allIds.filter((id) => {
    const { classification } = state.tracked.byId[id];
    return state.tracked.classificationVisibilities[classification];
  });
  const selected = ids.filter((id) => state.tracked.selected[id]);

  return (
    <>
      <PropertiesGroup name="Tracked Objects Lists" expandedInitValue={true}>
        <SelectedTrackedObjs ids={selected} />
        <TrackedAllList ids={ids} />
      </PropertiesGroup>

      <PropertiesGroup name="Actions" expandedInitValue={true}>
        <div className="flex justify-end my-1">
          <Button
            className="action"
            disabled={ids.length === 0}
            onClick={() => {
              dispatch({
                type: 'setTrackedInstanceBoolProp',
                property: 'selected',
                value: 'show',
                ids,
              });
            }}
          >
            Select All
          </Button>
          <Button
            className="action"
            disabled={selected.length === 0}
            onClick={() => {
              dispatch({
                type: 'setTrackedInstanceBoolProp',
                property: 'selected',
                value: 'hide',
                ids: selected,
              });
            }}
          >
            Deselect All
          </Button>
        </div>
      </PropertiesGroup>
    </>
  );
}

export default TrackedProperties;
