/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef } from 'react';
import {
  EventZoneServerInterface,
  ZonesSchema,
} from './EventZoneServerInterface';
import { Occupation } from '../../EventServerWebsocket';
import { useAppDispatch, useAppState } from '../../Stores';
import { event_wss_url } from '../../constants';
import { adaptOccupations, adaptZones } from '../../util/adapters';
import { WSDataReceivedSignal } from '../../types';
import useInterval from '../../app/hooks/useInterval';

const RETRY_INTERVAL_MS = 2000;

export const useEventZoneSocket = (
  dataReceivedSignal: WSDataReceivedSignal,
): void => {
  const state = useAppState();
  const eventRestClient = useRef<EventZoneServerInterface>();
  const dispatch = useAppDispatch();
  const prevZonesPayload = useRef('');
  const prevOccupationsPayload = useRef('');

  useEffect(() => {
    eventRestClient.current = new EventZoneServerInterface();

    eventRestClient.current.onZones = (eventZones: ZonesSchema) => {
      const zones = eventZones.zones;
      if (zones === null) return;
      const adapted = adaptZones(zones);
      const serialized = JSON.stringify(adapted);
      const isSame = serialized === prevZonesPayload.current;
      if (!isSame) {
        dispatch({
          type: 'setZones',
          value: adapted,
          isEventZone: true,
        });

        prevZonesPayload.current = serialized;
      }
    };

    eventRestClient.current.onOccupations = (occupations: Occupation[]) => {
      const adapted = adaptOccupations(occupations);
      const serialized = JSON.stringify(adapted);
      const isSame = serialized === prevOccupationsPayload.current;
      if (!isSame) {
        dispatch({ type: 'setOccupations', value: adapted });
        prevOccupationsPayload.current = serialized;
      }
    };

    // Currently we fetch all zones every 2 seconds, or whether our client makes a change
    // since we have no way of knowing whether some other client changed the zones.
    // In the future may use a dirty flag somewhere

    eventRestClient.current.connectEventServerWs(
      event_wss_url,
      dataReceivedSignal,
    );
  }, []);

  const [, setActive] = useInterval(
    () => {
      if (eventRestClient.current)
        eventRestClient.current?.fetchAllZones.bind(eventRestClient.current)();
    },
    RETRY_INTERVAL_MS,
    false,
  );

  useEffect(() => {
    const shouldFetch =
      state.app.inputMode === 'live' || state.app.inputMode === 'pcap';
    if (!shouldFetch) return;

    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode]);
};
