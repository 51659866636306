/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect } from 'react';
import { SECONDS_TO_MS } from '../../../constants';
import { useAppDispatch, useAppState } from '../../../Stores';
import { ServiceVersionInfo } from '../../../types';
import { toISOStringWithoutMS } from '../../../util/misc';
import Tooltip from '../tooltip/Tooltip';

export const VersionElem = ({
  versionVerbose,
  onClick,
  versionTag,
  disabled = false,
}: {
  versionVerbose: string;
  versionTag: string;
  onClick: () => void;
  disabled?: boolean;
}): JSX.Element => {
  return (
    <Tooltip tooltipText={versionVerbose} disabled={disabled} offset="right">
      <div
        className={`ml-3 text-xs textGreyD ${disabled ? '' : 'cursor-pointer'}`}
        style={{
          userSelect: 'none',
        }}
        onClick={() => {
          !disabled && onClick();
        }}
      >
        <p className="version-text">
          <abbr className={disabled ? 'disabled' : 'enabled'} title="Copy">
            {versionTag}
          </abbr>
        </p>
      </div>
    </Tooltip>
  );
};

const formatVersionInfo = (version: ServiceVersionInfo) => {
  const tag = version.tag;
  const versionVerbose = `v${tag}-${version.count}-${version.commit} @ ${version.commit_timestamp.iso}`;
  return { tag, versionVerbose };
};

export const Version = (): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const {
    perceptionRest: { version: perceptionVersion },
    reactClient: { version: reactAppVersion },
  } = state.service;

  // Place in effect to only log once on mount
  useEffect(() => {
    if (reactAppVersion.commit_timestamp.unix === undefined) {
      console.error(
        "Version info was not found. Make sure to run 'npm run version' prior to building.",
      );
    }
  }, []);

  // This should never happen in production. It can happen
  // if you build without having ever run "npm run version" locally.
  if (reactAppVersion.commit_timestamp.unix === undefined) {
    return null;
  }

  const { tag: perceptionTag, versionVerbose: perceptionVersionVerbose } =
    formatVersionInfo(perceptionVersion);
  const receivedPerceptionVersionInfo = perceptionVersion.commit !== '';

  const reactAppIso = toISOStringWithoutMS(
    Number(reactAppVersion.commit_timestamp.unix) * SECONDS_TO_MS,
  );
  const reactAppVersionVerbose = `v${reactAppVersion.tag}-${reactAppVersion.count}-${reactAppVersion.commit} @ ${reactAppIso}`;

  return (
    <div className="flex flex-col">
      <VersionElem
        versionTag={`Client: v${reactAppVersion.tag}`}
        versionVerbose={reactAppVersionVerbose}
        onClick={() => {
          navigator.clipboard.writeText(reactAppVersionVerbose);
          dispatch({
            type: 'enqueueFeedbackMessage',
            value: {
              message: `Copied client version.`,
              type: 'info',
              priority: 1,
              durationMs: 500,
            },
          });
        }}
      />
      <VersionElem
        versionTag={`Perception: ${
          receivedPerceptionVersionInfo ? `v${perceptionTag}` : 'OFFLINE'
        }`}
        versionVerbose={perceptionVersionVerbose}
        disabled={!receivedPerceptionVersionInfo}
        onClick={() => {
          if (receivedPerceptionVersionInfo) {
            navigator.clipboard.writeText(perceptionVersionVerbose);
            dispatch({
              type: 'enqueueFeedbackMessage',
              value: {
                message: `Copied perception version.`,
                type: 'info',
                priority: 1,
                durationMs: 500,
              },
            });
          }
        }}
      />
    </div>
  );
};
