/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useState } from 'react';
import { PanZoomCanvas } from '../image2d/PanZoomCanvas';
import { useAppState } from '../Stores';

export const ImagePlayback = (): JSX.Element => {
  const state = useAppState();
  const [image, setImage] = useState<ImageBitmap | null>(null);
  const [currentSensor, setCurrentSensor] = useState<string | null>(null);

  // set the triggering sensor for this recording the default image sensor to view
  useEffect(() => {
    if (state.playback.playing === null) return;
    const serial =
      state.playback.playing.dataRecorderEvent.primarySensorSerialNo;
    if (serial) setCurrentSensor(serial);
  }, [state.playback.playing]);

  // update the image to display on current frame
  useEffect(() => {
    if (recording === null) return;
    const timeIndex = state.playback.timeIndex;
    const timestamp = recording.timestamps[timeIndex];
    if (timestamp === undefined) return;
    if (currentSensor === null) return;
    const images = recording.frames[timestamp].images;
    if (images === undefined) return;
    const jpgBytes = images[currentSensor];
    if (jpgBytes === undefined) return;
    const blob = new Blob([jpgBytes], { type: 'image/jpeg' });
    createImageBitmap(blob).then((image) => setImage(image));
  }, [state.playback.timeIndex, state.playback.playing]);

  const recording = state.playback.playing;

  if (recording === null || image === null || currentSensor === null)
    return <></>;
  return (
    <div id="Image2D" style={{ position: 'relative' }}>
      <PanZoomCanvas image={image} />

      <select
        value={currentSensor}
        onChange={(e) => setCurrentSensor(e.target.value)}
        className="p-2"
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          color: recording.sensorColors[currentSensor],
        }}
      >
        {Array.from(recording.sensorsWithImages).map((id) => (
          <option
            key={id}
            value={id}
            style={{
              color: recording.sensorColors[id],
            }}
          >
            {id}
          </option>
        ))}
      </select>
    </div>
  );
};
