/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ButtonHTMLAttributes } from 'react';
import Tooltip, { TooltipInterface } from './tooltip/Tooltip';

function BaseButton({
  children,
  onClick,
  style,
  disabled,
  className,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <button
      type="button"
      className={className}
      style={style}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}

export function Button({
  children,
  onClick,
  style,
  disabled,
  tooltip,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & {
  tooltip?: TooltipInterface;
}): JSX.Element {
  return tooltip !== undefined ? (
    <Tooltip
      tooltipText={tooltip.text}
      offset={tooltip.offset}
      wrapperStyle={tooltip.wrapperStyle}
      closeOnClick={tooltip.closeOnClick}
    >
      <BaseButton style={style} onClick={onClick} disabled={disabled} {...rest}>
        {children}
      </BaseButton>
    </Tooltip>
  ) : (
    <BaseButton style={style} onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </BaseButton>
  );
}
