/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useAppDispatch, useAppState } from '../../../Stores';
import { Button } from '../Button';
import { ChevronIcon } from '../ChevronIcon';

const PaneCollapseToggle = ({
  onClick,
  side,
  isExpanded,
  style,
}: {
  onClick: () => void;
  side: 'right' | 'left';
  isExpanded: boolean;
  style: React.CSSProperties;
}) => {
  return (
    <Button
      className="flex flex-center bgGreyH"
      style={{
        width: 'var(--r8)',
        height: 'var(--r8)',
        padding: 0,
        ...style,
      }}
      onClick={onClick}
    >
      <ChevronIcon
        size="36"
        icon={
          (isExpanded && side === 'right') || (!isExpanded && side === 'left')
            ? 'right'
            : 'left'
        }
      />
    </Button>
  );
};

const CollapseToggle = ({
  side,
  visible,
}: {
  side: 'left' | 'right';
  visible: boolean;
}): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const isExpanded = state.app.isPaneExpanded[side];

  if (!visible) {
    return null;
  }

  return (
    <PaneCollapseToggle
      style={{
        position: 'absolute',
        zIndex: 15,
        ...(side === 'left'
          ? {
              left: 0,
              borderRadius: '0 var(--border-radius) var(--border-radius) 0',
            }
          : {
              right: 0,
              borderRadius: 'var(--border-radius) 0 0 var(--border-radius)',
            }),
        top: '0',
      }}
      isExpanded={isExpanded}
      onClick={() => {
        dispatch({
          type: 'setPaneExpanded',
          side,
          value: !isExpanded,
        });
      }}
      side={side}
    />
  );
};

export default CollapseToggle;
