/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useRef } from 'react';
import { MAX_SENSOR_RESOLUTION, Palettes } from '../constants';
import { useAppState } from '../Stores';
import { Context, PerceptionClassification } from '../types';
import { hexToRgb } from '../util/color';
import { TrackedObjectsPointcloud3JS } from './TrackedObjectsPointcloud3JS';

type Trails3JS = {
  points: TrackedObjectsPointcloud3JS;
  line: TrackedObjectsPointcloud3JS;
};
type TrailsData = {
  classifications: PerceptionClassification[]; // per point
  xyz: Float32Array;
  rgb: Float32Array;
  size: Float32Array;
};

const setPosToBase = (positionArr: Float32Array, objHeight: number) => {
  positionArr[2] -= objHeight / 2;
  return positionArr;
};
const trailMode: keyof Trails3JS = 'line';

// Logic to update the point clouds associated with each tracked object
export const useTrackedObjectsPreviousPositionsSynch = (
  context: Context,
): void => {
  const state = useAppState();
  const trails = useRef({} as Trails3JS);
  const data = useRef({} as TrailsData);

  useEffect(() => {
    trails.current.line = new TrackedObjectsPointcloud3JS('line');
    trails.current.line.name = 'TrackedObjectspreviousPositionsPath';

    trails.current.points = new TrackedObjectsPointcloud3JS('points');
    trails.current.points.name = 'TrackedObjectsPreviousPositionsPoint';

    data.current = {
      classifications: new Array<PerceptionClassification>(
        MAX_SENSOR_RESOLUTION,
      ),
      xyz: new Float32Array(MAX_SENSOR_RESOLUTION * 3),
      rgb: new Float32Array(MAX_SENSOR_RESOLUTION * 3),
      size: new Float32Array(MAX_SENSOR_RESOLUTION),
    };
  }, []);

  // show/hide
  useEffect(() => {
    if (trails.current[trailMode] === undefined) {
      return;
    }
    trails.current[trailMode].points.visible = true;
    trails.current[trailMode === 'line' ? 'points' : 'line'].points.visible =
      false;

    const isVisibleInMode =
      state.app.inputMode === 'playback' ||
      state.app.mode === 'viewer' ||
      state.app.mode === 'zone' ||
      state.app.mode === 'recording' ||
      state.app.mode === 'preferences' ||
      state.app.mode === 'map';

    state.app.developerMode &&
    isVisibleInMode &&
    state.tracked.globalVisibilities.previousPositionsPath
      ? context.viz.scene.add(trails.current[trailMode].points)
      : trails.current[trailMode].points.removeFromParent();
  }, [
    state.app.mode,
    state.tracked.globalVisibilities.previousPositionsPath,
    state.app.developerMode,
  ]);

  // update the cloud that aggregates all tracked object's previous positions
  useEffect(() => {
    if (
      trails.current[trailMode] === undefined ||
      !state.tracked.globalVisibilities.previousPositionsPath
    ) {
      return;
    }
    trails.current[trailMode].points.visible = true;
    trails.current[trailMode === 'line' ? 'points' : 'line'].points.visible =
      false;

    if (state.tracked.allIds.length === 0) return;

    let accumulator = 0;
    for (let i = 0; i < state.tracked.allIds.length; i++) {
      const id = state.tracked.allIds[i];
      const { classification, previousPositions } = state.tracked.byId[id];
      if (
        !state.tracked.classificationVisibilities[classification] ||
        !state.tracked.visible[id]
      )
        continue;

      const objHeight = state.tracked.byId[id].dimensions.z;

      const totalPoints = previousPositions.length / 3;
      if (totalPoints < 1) continue;
      // Line A->B->C consists of segments AB-BC
      // so we'll have two points for each point except the endpoints.
      // The exception is a line with a single point in which case we duplicate that point.
      const totalSegmentPoints = totalPoints == 1 ? 2 : (totalPoints - 1) * 2;

      for (let j = 0; j < totalSegmentPoints; j++) {
        data.current.classifications[accumulator / 3 + j] = classification;
      }
      const positions = new Float32Array(totalSegmentPoints * 3);
      positions.set(setPosToBase(previousPositions.slice(0, 3), objHeight));
      let offset = 1;
      let j = 1;
      while (j < totalPoints - 1) {
        const next = setPosToBase(
          previousPositions.slice(j * 3, (j + 1) * 3),
          objHeight,
        );
        positions.set([...next, ...next], offset * 3);
        j++;
        offset += 2;
      }

      // If we only have one point, we skip the while above
      // and want to duplicate that single point in the positions array
      // instead of accessing the next point which doesn't exist.
      j = totalPoints === 1 ? 0 : j;
      positions.set(
        setPosToBase(previousPositions.slice(j * 3, (j + 1) * 3), objHeight),
        offset++ * 3,
      );
      data.current.xyz.set(positions, accumulator);
      accumulator += totalSegmentPoints * 3;
    }
    const upTo = accumulator / 3;
    // set color attributes
    for (let i = 0; i < upTo; i++) {
      const classification = data.current.classifications[i];
      const color = hexToRgb(
        Palettes[state.app.palette][
          state.tracked.classificationColors[classification]
        ],
      ).map((e) => e / 255);
      data.current.rgb.set(color, i * 3);
    }
    // set point size attributes
    for (let i = 0; i < upTo; i++) {
      const classification = data.current.classifications[i];
      // add points for only visible tracked objects
      data.current.size.set(
        [state.tracked.classificationPointSize[classification]],
        i,
      );
    }

    trails.current[trailMode].setAttributes(
      upTo,
      data.current.xyz.slice(0, accumulator),
      data.current.rgb.slice(0, accumulator),
      data.current.size.slice(0, upTo),
    );
  }, [
    state.tracked.allIds,
    state.tracked.classificationVisibilities,
    state.tracked.classificationColors,
    state.tracked.visible,
    state.tracked.classificationPointSize,
  ]);
};
