/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppState } from '../../Stores';
import { Context } from '../../types';

export const useAllowPitchAndRoll = (context: Context): void => {
  const state = useAppState();

  useEffect(() => {
    const { x, y } = context.transformControls.controls.rotate;
    x.isActive = y.isActive =
      state.app.mode === 'setup' && state.app.isPitchAndRollVisible;
  }, [state.app.mode, state.app.isPitchAndRollVisible]);
};
