/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { ServiceVersionInfo } from '../../types';
import { ActionButton } from '../../app/components/actionButton/ActionButton';

const VersionCopyButton = ({
  version,
}: {
  version: ServiceVersionInfo | undefined;
}): JSX.Element => {
  const disabled = version === undefined || version.commit === '';
  // For temporarily disabling "copy" button, to indicate to user they copied
  const [blocked, setBlocked] = React.useState(false);

  return (
    <ActionButton
      icon="Copy"
      disabled={disabled || blocked}
      onClick={() => {
        navigator.clipboard.writeText(
          `v${version?.tag}-${version?.count}-${version?.commit} @ ${version?.commit_timestamp.iso}`,
        );
        // Block button for 500ms to prevent multiple copies
        setBlocked(true);
        setTimeout(() => {
          setBlocked(false);
        }, 500);
      }}
      style={{
        ...(disabled || blocked ? { padding: 'var(--r_5)' } : {}),
      }}
    >
      <span className="grow text-sm textGreyC">
        {disabled || blocked ? 'Version' : `v${version?.tag}`}
      </span>
    </ActionButton>
  );
};

const APILinkButton = ({ url }: { url: string | null }): JSX.Element => {
  return (
    <ActionButton
      icon="ExternalLink"
      disabled={url === null}
      onClick={() => {
        window.open(url ?? undefined);
      }}
      style={{
        ...(url === null ? { padding: 'var(--r_5)' } : {}),
      }}
    >
      <span className="grow text-sm textGreyC">API</span>
    </ActionButton>
  );
};

const ServiceReachability = ({
  url,
  version,
}: {
  url: string | null;
  version?: ServiceVersionInfo;
}): JSX.Element => {
  return (
    <div
      className="flex items-center"
      style={{
        padding: 'var(--r2) var(--r3) var(--r1) var(--r1_5)',
        justifyContent: 'space-between',
      }}
    >
      <VersionCopyButton version={version} />
      <div style={{ width: 'var(--r2)' }}></div>
      <APILinkButton url={url} />
    </div>
  );
};

export default ServiceReachability;
