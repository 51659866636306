/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-duplicate-string */

import React from 'react';
import { Context } from '../../../types';
import { Button } from '../Button';

export const CamNav = ({
  context,
}: {
  context: Context;
}): JSX.Element | null => {
  return (
    <div
      className="CamNav no-select"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '2.7rem',
        display: 'flex',
        alignItems: 'center',
        zIndex: 12,
        fontFamily: 'monospace',
      }}
    >
      <div className="flex items-center">
        <Button
          className="bgNone"
          onClick={() => {
            const { y, z } = context.viz.controls.target;
            context.viz.camera.position.set(100, y, z);
            context.viz.controls.update();
          }}
        >
          X
        </Button>
        <Button
          className="bgNone"
          onClick={() => {
            const { x, z } = context.viz.controls.target;
            context.viz.camera.position.set(x, 100, z);
            context.viz.controls.update();
          }}
        >
          Y
        </Button>
        <Button
          className="bgNone"
          onClick={() => {
            const { x, y } = context.viz.controls.target;
            context.viz.camera.position.set(x, y, 100);
            context.viz.controls.update();
          }}
        >
          Z
        </Button>
      </div>
    </div>
  );
};
