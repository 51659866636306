/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { useAppDispatch, useAppState } from '../Stores';
import RadioButtonRow from '../app/components/radioButtonRow/RadioButtonRow';
import {
  VelocityLabelUnits,
  trackedObjectLabels,
  velocityLabelUnits,
} from '../trackedObjects/TrackedObjectsStore';
import Select from '../app/components/Select';

const SelectVelocityUnits = ({
  velocityUnits,
  setUnits,
}: {
  velocityUnits: VelocityLabelUnits;
  setUnits: (units: VelocityLabelUnits) => void;
}): JSX.Element => {
  return (
    <div className="flex justify-between items-center mt-2 mr-4">
      <p className="text-sm textGreyD grow-0">Velocity Units</p>
      <Select
        active={velocityUnits}
        options={velocityLabelUnits}
        onChange={setUnits}
      />
    </div>
  );
};

const TrackedObjectLabelProperties = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  return (
    <>
      <PropertiesSubGroup
        name="Tracked Object Labels"
        expandedInitValue={false}
      >
        <SelectVelocityUnits
          velocityUnits={state.tracked.velocityLabelUnits}
          setUnits={(units) => {
            dispatch({
              type: 'setTrackedVelocityLabelUnits',
              value: units,
            });
          }}
        />
        <p className="text-sm textGreyD mt-4" style={{ fontWeight: 600 }}>
          Label Fields
        </p>
        <div
          style={{
            overflowY: 'scroll',
            maxHeight: '10em',
          }}
        >
          {trackedObjectLabels.map((label, idx) => (
            <RadioButtonRow
              key={`${idx}-${label}`}
              label={label}
              onClick={() =>
                dispatch({
                  type: 'setTrackedLabelVisibility',
                  property: label,
                  value: !state.tracked.labelVisibilitiesByType[label],
                })
              }
              selected={state.tracked.labelVisibilitiesByType[label]}
            />
          ))}
        </div>
      </PropertiesSubGroup>
    </>
  );
};

export default TrackedObjectLabelProperties;
