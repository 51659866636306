/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
export const LogoIconTextVert = ({
  width = '0.75rem',
  height,
  ...rest
}: {
  width: string;
  height?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      className="LogoIconTextVert"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 278.5 152.1"
      fill="var(--greyA)"
      {...rest}
    >
      <g transform="translate(-45.7,-45.7)" id="g38">
        <path d="M 185,93.4 C 208.6,93.4 231.8,94.9 254.7,97.7 V 77.5 C 231.9,80.3 208.6,81.8 185,81.8 161.4,81.8 138.2,80.3 115.3,77.5 V 97.8 C 138.2,94.9 161.4,93.4 185,93.4 Z" />
        <path d="M 254.6,45.7 C 234.1,57.6 210.3,64.4 184.9,64.4 159.5,64.4 135.7,57.6 115.2,45.7 V 67.2 C 137.5,72.9 160.8,76 184.9,76 209,76 232.3,73 254.6,67.2 Z" />
        <path d="M 254.6,129.5 V 108 C 232.3,102.3 209,99.2 184.9,99.2 160.8,99.2 137.5,102.2 115.2,108 V 129.5 C 135.7,117.6 159.5,110.8 184.9,110.8 210.3,110.8 234.1,117.6 254.6,129.5 Z" />
        <path d="M 45.7,174.1 C 45.7,160.1 56.3,150.5 70,150.5 83.6,150.5 94.2,160.1 94.2,174.1 94.2,188 83.6,197.7 70,197.7 56.2,197.7 45.7,188 45.7,174.1 Z M 83,174.1 C 83,166.4 78,160.3 69.9,160.3 61.9,160.3 56.8,166.4 56.8,174.1 56.8,181.9 61.9,187.9 69.9,187.9 78,187.9 83,181.8 83,174.1 Z" />
        <path d="M 130.9,177.9 V 151.2 H 141.8 V 178.6 C 141.8,189.3 134.8,197.8 121.5,197.8 107.9,197.8 101.2,189.4 101.2,178.6 V 151.2 H 112.1 V 177.9 C 112.1,183.8 115.4,187.9 121.5,187.9 127.5,187.9 130.9,183.8 130.9,177.9 Z" />
        <path d="M 160.1,163.4 C 160.1,166 162.4,167.3 166.1,167.8 L 170.7,168.4 C 181,169.7 187.8,173.1 187.8,182.9 187.8,191.6 181.2,197.7 169.1,197.7 154.6,197.7 149.1,189.7 147.7,183.6 H 159.3 C 160.1,185.7 163,188.7 169,188.7 173.9,188.7 176.4,186.8 176.4,183.7 176.4,180.4 173.8,179.3 170,178.7 L 165.4,178.1 C 155.3,176.7 149,172.8 149,163.8 149,155.2 156.3,150.3 166.8,150.3 180.5,150.3 185.4,157.5 186.7,162.9 H 175.5 C 174.5,161.3 172.1,159.1 166.8,159.1 162.5,159.2 160.1,160.9 160.1,163.4 Z" />
        <path d="M 190.4,151.2 H 232.6 V 160.7 H 217 V 196.9 H 206.1 V 160.7 H 190.5 V 151.2 Z" />
        <path d="M 238.7,151.2 H 274.2 V 160.2 H 249.6 V 168.7 H 266.3 V 177.9 H 249.6 V 187.4 H 275.8 V 196.9 H 238.6 V 151.2 Z" />
        <path d="M 282.1,151.2 H 305.5 C 315.6,151.2 321.6,156.1 321.6,165.4 321.6,171.6 318.7,176 313.5,178 L 324.2,196.9 H 312.5 L 302.5,179.4 H 293 V 196.9 H 282.1 Z M 293,160.6 V 170.3 H 304.5 C 308.4,170.3 310.5,168.6 310.5,165.5 310.5,162.2 308.4,160.7 304.5,160.7 H 293 Z" />
      </g>
    </svg>
  );
};
