/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { endpoints } from '../api/endpoints';
import { DirectoryResponse, ListRecordingsResponse } from '../api/record';
import { REST, data_prefix_url } from '../constants';
import { safeFetch } from '../safeFetch';
import { ObjectKeys, StringKeyOf } from '../util/misc';
import { Button } from '../app/components/Button';

const {
  record: { recording },
} = endpoints;

const RecordingButtons = ({
  isDeveloperMode,
  selectedRecording,
  setSelectedRecording,
  filesJSON,
}: {
  isDeveloperMode: boolean;
  selectedRecording: string;
  setSelectedRecording: (value: string) => void;
  filesJSON: ListRecordingsResponse;
}): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isDeveloperMode && (
        <Button
          className="action"
          disabled={selectedRecording === ''}
          onClick={async () => {
            await safeFetch.put(REST.recording.play, {
              name: `${selectedRecording}`,
            });
          }}
        >
          Replay
        </Button>
      )}
      <Button
        className="action"
        disabled={selectedRecording === ''}
        onClick={async () => {
          // The backend stores recordings within directories, so
          // anything within root should be a directory.
          const children = filesJSON[selectedRecording] as DirectoryResponse;

          // String keys that are numbers are cast to number, tell TS we
          // only expect strings
          const fileNamesToDownload = (
            ObjectKeys(children) as StringKeyOf<DirectoryResponse>[]
          ).filter(
            (childName) =>
              typeof childName === 'string' &&
              children[childName]?.type === 'file' &&
              (childName.endsWith('.pcap') || childName.endsWith('.tar')),
          );

          fileNamesToDownload.forEach(async (fileName) => {
            await safeFetch.remoteDownload(
              `${data_prefix_url}/${selectedRecording}/${fileName}`,
              fileName,
            );
          });

          setSelectedRecording('');
        }}
      >
        Download
      </Button>
      <Button
        className="action"
        disabled={selectedRecording === ''}
        onClick={async () => {
          // Download from server
          await recording.delete(selectedRecording);
          setSelectedRecording('');
        }}
      >
        Delete
      </Button>
    </div>
  );
};

export default RecordingButtons;
