/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useRef } from 'react';
import { ActionButton } from '../app/components/actionButton/ActionButton';
import {
  EventZoneServerInterface,
  ZonesSchema,
} from './eventZone/EventZoneServerInterface';
import { PerceptionZoneServerInterface } from './pointZone/PerceptionZoneServerInterface';

const DEFAULT_EVENT_FILENAME = 'zone_filters.json';

const zonesButtons = (
  zoneInterface: EventZoneServerInterface | PerceptionZoneServerInterface,
  defaultFilename: string,
  text: string,
): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className="flex justify-between items-baseline">
      <p className="text-sm textGreyD pr-4">{text}</p>

      <div className="flex items-center">
        {/* Import */}
        <ActionButton
          id="loadZonesJSON"
          icon="Upload"
          tooltip={{ text: 'Import zone', offset: 'left' }}
          value="Upload Zones"
          onClick={() => {
            // This triggers the hidden file upload button.
            inputRef.current?.click();
          }}
        />
        <input
          id="zoneUploadInput"
          ref={inputRef}
          type="file"
          accept=".json"
          hidden
          onChange={(event) => {
            const fileList = event.currentTarget.files;
            const selectedFile = fileList?.item(0);
            if (selectedFile) {
              const reader = new FileReader();
              reader.readAsText(selectedFile);

              reader.onload = function () {
                if (typeof reader.result === 'string') {
                  const jsonZones = JSON.parse(reader.result);
                  zoneInterface.setZones(jsonZones);
                }

                console.log(reader.result);
              };

              reader.onerror = function () {
                console.log(reader.error);
              };
            }
            event.target.value = ''; // This allows us to select the same file again
          }}
        />

        {/* Export */}
        <ActionButton
          icon="Download"
          tooltip={{ text: 'Export zones', offset: 'left' }}
          value="Export Zones"
          onClick={() => {
            zoneInterface.onZones = (eventZones: ZonesSchema) => {
              const zonesString = JSON.stringify(eventZones, null, 4);
              const elem = window.document.createElement('a');
              const blob = new Blob([zonesString], { type: 'text/json' });
              elem.href = window.URL.createObjectURL(blob);
              elem.download = defaultFilename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            };

            zoneInterface.fetchAllZones();
          }}
        />
      </div>
    </div>
  );
};

export const ZoneIOActions = (): JSX.Element => {
  const eventZoneServerInterface = new EventZoneServerInterface();
  const perceptionZoneServerInterface = new PerceptionZoneServerInterface();

  return (
    <>
      {zonesButtons(
        eventZoneServerInterface,
        'event_zones.json',
        ' Import/Export Event Zones',
      )}
      {zonesButtons(
        perceptionZoneServerInterface,
        DEFAULT_EVENT_FILENAME,
        'Import/Export Point Zones',
      )}
    </>
  );
};
