/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-identical-functions */

import React, { useRef } from 'react';
import { PropertiesGroup } from '../app/components/pane/PropertiesGroup';
import { useAppDispatch, useAppState } from '../Stores';
import { MapActions } from './MapActions';
import NumberInputRow from '../app/components/NumberInput/NumberRow';
import { ActionButton } from '../app/components/actionButton/ActionButton';
import { unstable_batchedUpdates } from 'react-dom';
import { AppDefaultState } from '../app/AppStore';

export const MapProperties = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const isMapToolActive = state.app.tool === 'TransformMap';

  return (
    <>
      <PropertiesGroup name="Map Properties" expandedInitValue={true}>
        <div className="flex flex-row my-2">
          <MapActions />
        </div>
        <div className="flex my-2 p-4">
          <img src={state.app.underlayMap.image} />
        </div>

        <NumberInputRow
          blurb="Position X"
          disabled={isMapToolActive}
          step={1}
          min={-1000}
          max={1000}
          isDirty={false}
          onChangeNumber={(value) => {
            dispatch({
              type: 'seMapPosition',
              value: state.app.underlayMap.position.clone().setX(value),
            });
          }}
          value={state.app.underlayMap.position.x}
        />

        <NumberInputRow
          blurb="Position Y"
          disabled={isMapToolActive}
          step={1}
          min={-1000}
          max={1000}
          isDirty={false}
          onChangeNumber={(value) => {
            dispatch({
              type: 'seMapPosition',
              value: state.app.underlayMap.position.clone().setY(value),
            });
          }}
          value={state.app.underlayMap.position.y}
        />

        <NumberInputRow
          blurb="Rotation"
          disabled={isMapToolActive}
          step={1}
          min={-359}
          max={359}
          isDirty={false}
          onChangeNumber={(value) => {
            dispatch({
              type: 'seMapRotation',
              value,
            });
          }}
          value={state.app.underlayMap.rotation}
        />

        <NumberInputRow
          blurb="Scale"
          disabled={isMapToolActive}
          step={1}
          min={1}
          max={1000}
          isDirty={false}
          onChangeNumber={(value) => {
            dispatch({
              type: 'seMapScale',
              value,
            });
          }}
          value={state.app.underlayMap.scale}
        />

        <NumberInputRow
          blurb="Opacity"
          disabled={false}
          step={0.1}
          min={0.01}
          max={1}
          isDirty={false}
          onChangeNumber={(value) => {
            dispatch({
              type: 'seMapOpacity',
              value,
            });
          }}
          value={state.app.underlayMap.opacity}
        />
      </PropertiesGroup>
      <PropertiesGroup name="Map Actions" expandedInitValue={true}>
        <input
          type="file"
          accept=".png"
          ref={inputRef}
          hidden
          onChange={(event) => {
            const fileList = event.currentTarget.files;
            const selectedFile = fileList?.item(0);
            if (selectedFile) {
              const reader = new FileReader();
              reader.readAsDataURL(selectedFile);
              reader.onload = function () {
                const dataUrl = reader.result as string;
                dispatch({ type: 'setMapImage', value: dataUrl });
              };
              reader.onerror = function () {
                console.log(reader.error);
              };
            }
            event.target.value = ''; // This allows us to select the same file again
          }}
        />

        <div className="flex justify-between items-baseline">
          <p className="text-sm textGreyD pr-4">Upload Map</p>

          <ActionButton
            id="load-map"
            icon="Upload"
            tooltip={{ text: 'Import Map', offset: 'left' }}
            value="Upload Map"
            onClick={() => {
              // This triggers the hidden file upload button.
              inputRef.current?.click();
            }}
          />
        </div>

        <div className="flex justify-between items-baseline">
          <p className="text-sm textGreyD pr-4">Discard Map</p>

          <ActionButton
            id="load-map"
            icon="Reset"
            tooltip={{ text: 'Discard Map', offset: 'left' }}
            value="Discard Map"
            onClick={() => {
              unstable_batchedUpdates(() => {
                dispatch({
                  type: 'setMapImage',
                  value: AppDefaultState.underlayMap.image,
                });
                dispatch({
                  type: 'seMapOpacity',
                  value: AppDefaultState.underlayMap.opacity,
                });
                dispatch({
                  type: 'seMapScale',
                  value: AppDefaultState.underlayMap.scale,
                });
                dispatch({
                  type: 'seMapRotation',
                  value: AppDefaultState.underlayMap.rotation,
                });
                dispatch({
                  type: 'seMapPosition',
                  value: AppDefaultState.underlayMap.position,
                });
              });
            }}
          />
        </div>
      </PropertiesGroup>
    </>
  );
};
