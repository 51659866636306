/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { AGENT_REST, agent_url } from '../constants';
import { safeFetch } from '../safeFetch';

export interface AgentStatusResponse {
  total_licenses: number;
  in_use_licenses: number;
  expiration_time: number;
}

export interface ActivateLicenseResponse {
  message: string;
}

export const getAgentStatus = (): Promise<AgentStatusResponse | null> =>
  safeFetch.json.get<AgentStatusResponse>(AGENT_REST.licenseStatus);

export const reachRestServer = async (): Promise<Response | null> =>
  safeFetch.get(agent_url);

// Can't use safeFetch because it uses JSON.stringify which adds extra quotes to eid
export const activateLicense = async (eid: string): Promise<Response> =>
  fetch(AGENT_REST.activateLicense, {
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: eid,
  });

export const agent = {
  testRestReach: reachRestServer,
};
