/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { CloudDisplayMode, CloudDisplayModes } from '../../../../types';

export const SelectCloudsDisplay = ({
  active,
  onChange,
  modes = CloudDisplayModes,
}: {
  active: CloudDisplayMode;
  onChange: (mode: CloudDisplayMode) => void;
  modes?: readonly CloudDisplayMode[] | CloudDisplayMode[];
}): JSX.Element => {
  return (
    <select
      name="color-modes"
      value={active}
      onChange={(e) => {
        const mode = e.currentTarget.value as CloudDisplayMode;
        onChange(mode);
      }}
    >
      {modes.map((mode) => (
        <option key={mode} value={mode}>
          {mode}
        </option>
      ))}
    </select>
  );
};
