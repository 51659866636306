/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

/* eslint-disable sonarjs/no-duplicate-string */

import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppState } from '../../Stores';
import Input from '../../app/components/Input/Input';
import { SearchIcon } from '../../app/components/SearchIcon';
import { SourceSelect } from './SourceSelect';
import { Button } from '../../app/components/Button';
import { endpoints } from '../../api/endpoints';
import { NodeResponse } from '../../api/node';
import { proxy } from '../../api/proxy';
import { HTTP_STATUS, proxy_server_url } from '../../constants';
import {
  isValidIp,
  isValidSourceHostname,
  isLinkLocal,
  ObjectEntries,
  probablySensorAddress,
  isNonNegativeIntegerString,
} from '../../util/misc';
import LoadingSpinner from '../../app/components/LoadingSpinner/LoadingSpinner';
import { unstable_batchedUpdates } from 'react-dom';
import { SensorResponse } from '../../api/sensor';

const { sensor, node } = endpoints;

// Can't use safeFetch since it returns a failure for both 401 and 404s.
// (Unauthorized: 401) is not the same as an error (400, 404)
// in this case as 401 still connotes a reachable address.
const isAddressReachable = (address: string, abortSignal: AbortSignal) =>
  fetch(address, { signal: abortSignal });

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SourceProperties = (): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  // Async requests should be aborted once a component unmounts. Most
  // requests are fast enough that this isn't a problem. Adding sources isn't.
  // https://stackoverflow.com/questions/61743905/how-to-prevent-react-state-update-for-asynchronous-request-on-unmounted-componen
  const addSensorAbortController = useRef<AbortController | null>(null);
  const addNodeAbortController = useRef<AbortController | null>(null);
  const isNodeReachableAbortController = useRef<AbortController | null>(null);

  const [inputIP, setInputIP] = useState('');
  const [inputId, setInputId] = useState('');
  const [inputUsername, setInputUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [inputPort, setInputPort] = useState('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [isInputIPValid, setIsInputIPValid] = useState(false);
  const [isInputIPLinkLocal, setIsInputIPLinkLocal] = useState(false);
  const [isAddSensorByIPLoading, setIsAddSensorByIPLoading] = useState(false);
  const [isAddNodeByIPLoading, setIsAddNodeByIPLoading] = useState(false);
  const [isSelectedSourceReachable, setIsSelectedSourceReachable] =
    useState(false);

  // Indicates whether the extra text boxes for nodes is open.
  const [isAddNodeOpen, setIsAddNodeOpen] = useState(false);
  const inputUsernameRef = useRef<HTMLInputElement | null>(null);

  const [selectedSourceId, setSelectedSourceId] = useState<string | null>(null);

  const isSensor = selectedSourceId
    ? !state.nodes.allIds.includes(selectedSourceId)
    : true;
  const sensors = state.sensors;
  const nodes = state.nodes;
  const sources = isSensor ? sensors : nodes;

  const [isRequestLoadingById, setIsRequestLoadingById] = useState(
    {} as Record<string, boolean>,
  );

  const [sourceIds, setSourceIds] = useState([] as string[]);

  const sourceIdToLocalAddress = (selectedSourceId: string) =>
    `https://${sources.hostnames[selectedSourceId]}`;

  const attemptAddSensor = (hostname: string, abortSignal: AbortSignal) =>
    sensor.add(
      hostname,
      abortSignal,
      () => {
        // 2xx response (success)
        dispatch({
          type: 'enqueueFeedbackMessage',
          value: {
            message: `Success in adding sensor with hostname ${inputIP}`,
            type: 'info',
            priority: 10,
            durationMs: 2000,
          },
        });
      },
      async (response: Response) => {
        // Non 2xx response (error)
        const errorMessage =
          (await response.json())?.message ||
          `Error in adding sensor with hostname ${inputIP}`;

        dispatch({
          type: 'enqueueFeedbackMessage',
          value: {
            message: errorMessage,
            type: 'error',
            priority: 10,
            durationMs: 10000,
          },
        });
      },
      () => {
        // Network error
        dispatch({
          type: 'enqueueFeedbackMessage',
          value: {
            message: 'Network error while adding sensor.',
            type: 'error',
            priority: 10,
            durationMs: 10000,
          },
        });
      },
    );

  const attemptAddNode = (
    id: string,
    hostname: string,
    username: string,
    password: string,
    port: number | null,
    abortSignal: AbortSignal,
  ) => {
    node.add(
      id,
      hostname,
      username,
      password,
      port,
      abortSignal,
      () => {
        // 2xx response (success)
        unstable_batchedUpdates(() => {
          dispatch({
            type: 'setNodeAdded',
            id: id,
            value: true,
          });
          dispatch({
            type: 'enqueueFeedbackMessage',
            value: {
              message: `Success in adding node with hostname ${inputIP}`,
              type: 'info',
              priority: 10,
              durationMs: 2000,
            },
          });
        });
      },
      async (response: Response) => {
        // Non 2xx response (error)
        const errorMessage =
          (await response.json())?.message ||
          `Error in adding node with hostname ${inputIP}`;

        dispatch({
          type: 'enqueueFeedbackMessage',
          value: {
            message: errorMessage,
            type: 'error',
            priority: 10,
            durationMs: 10000,
          },
        });
      },
      () => {
        // Network error
        dispatch({
          type: 'enqueueFeedbackMessage',
          value: {
            message: 'Network error while adding node.',
            type: 'error',
            priority: 10,
            durationMs: 10000,
          },
        });
      },
    );
  };

  const sourceIsAdded = (selectedId: string): boolean => {
    return sources.addedStates[selectedId];
  };

  useEffect(() => {
    if (selectedSourceId === null) {
      setIsSelectedSourceReachable(false);
      return;
    }

    if (isSensor) {
      setIsSelectedSourceReachable(true);
      return;
    }

    isNodeReachableAbortController.current = new AbortController();

    isAddressReachable(
      sourceIdToLocalAddress(selectedSourceId),
      isNodeReachableAbortController.current.signal,
    )
      .then((res) => {
        const isReachable =
          res.status === HTTP_STATUS.UNAUTHORIZED ||
          res.status === HTTP_STATUS.SUCCESS;
        setIsSelectedSourceReachable(isReachable);
      })
      .catch(() => {
        // Unfortunately, the error doesn't hold any info
        // as to why the request failed. We assume the host is unreachable.
        setIsSelectedSourceReachable(false);
      });

    return () => {
      setIsSelectedSourceReachable(false);
      // Abort the request so it doesn't resolve
      // after we switch selections, causing a desync
      isNodeReachableAbortController.current &&
        isNodeReachableAbortController.current.abort();
    };
  }, [selectedSourceId]);

  // Focus on the Username field when opening the node add inputs
  useEffect(() => {
    if (isAddNodeOpen) {
      inputUsernameRef.current?.focus();
    }
  }, [isAddNodeOpen]);

  // Abort pending add requests on unmount (the backend is unaffected by this)
  useEffect(() => {
    return () => {
      addSensorAbortController.current &&
        addSensorAbortController.current.abort();
      addNodeAbortController.current && addNodeAbortController.current.abort();
      isNodeReachableAbortController.current &&
        isNodeReachableAbortController.current.abort();
    };
  }, []);

  // Sort so that added sources come first
  useEffect(() => {
    const addedIds = [] as string[];
    const unaddedIds = [] as string[];
    // Iterate over added state keys since discovery doesn't update allIds.
    ObjectEntries({ ...sensors.addedStates, ...nodes.addedStates }).map(
      ([id, isAdded]) => {
        if (isAdded) addedIds.push(id);
        else unaddedIds.push(id);
      },
    );
    setSourceIds([...addedIds, ...unaddedIds]);
  }, [nodes.addedStates, sensors.addedStates]);

  // The selected source is likely not available following a mode switch.
  // Using setState in a useEffect that listens to inputMode will be one render behind
  // in the event loop so we'd still try to access a missing id for one frame. Instead
  // check if our selected source was removed and if so, skip this render.
  if (
    selectedSourceId &&
    ((state.sensors.allIds.includes(selectedSourceId) &&
      state.sensors.addedStates[selectedSourceId] === undefined) ||
      (state.nodes.allIds.includes(selectedSourceId) &&
        state.nodes.addedStates[selectedSourceId] === undefined))
  ) {
    setSelectedSourceId(null);
    return null;
  }

  async function addNewSource(sourceType: 'sensor' | 'node') {
    if (
      !isInputIPValid ||
      isInputIPLinkLocal ||
      (sourceType === 'sensor' && isAddSensorByIPLoading) ||
      (sourceType === 'node' && isAddNodeByIPLoading)
    ) {
      return;
    }

    if (sourceType === 'sensor') {
      setIsAddSensorByIPLoading(true);
      addSensorAbortController.current = new AbortController();
      await attemptAddSensor(inputIP, addSensorAbortController.current.signal);

      // If the controller aborts, our component must have unmounted
      if (addSensorAbortController.current?.signal.aborted) return;
      addSensorAbortController.current = null;
      setIsAddSensorByIPLoading(false);
    } else {
      setIsAddNodeByIPLoading(true);
      addNodeAbortController.current = new AbortController();
      await attemptAddNode(
        inputId,
        inputIP,
        inputUsername,
        inputPassword,
        isNonNegativeIntegerString(inputPort) ? Number(inputPort) : null,
        addNodeAbortController.current.signal,
      );

      if (addNodeAbortController.current?.signal.aborted) return;
      addNodeAbortController.current = null;
      setIsAddNodeByIPLoading(false);
      setIsAddNodeOpen(false);
    }

    setInputIP('');
    setIsInputIPValid(false);
  }

  async function putNodeWithCredentials(
    id: string,
    ip: string,
    abortSignal: AbortSignal,
  ) {
    // Get username and password from the user. We have to do them one at a time,
    // unless we use our own custom interface, which isn't currently worth it.
    const username = prompt('Enter Username:');
    if (username) {
      const password = prompt('Enter Password:');
      if (password) {
        const port = prompt('Enter port (leave blank for 443):');
        if (port !== null /* Didn't cancel the prompt */) {
          await attemptAddNode(
            id,
            ip,
            username,
            password,
            isNonNegativeIntegerString(port) ? Number(port) : null,
            abortSignal,
          );
        }
      }
    }
  }

  const searchFilteredSources = sourceIds.filter((id) =>
    `${id}-${sensors.ips[id]}-${nodes.ips[id]}`.includes(searchInput.trim()),
  );

  return (
    <section style={{ margin: 'var(--r6) 0 var(--r6) 0' }}>
      <Input
        name="Search"
        value={searchInput ?? ''}
        onChange={(e) => setSearchInput(e.target.value)}
      >
        <SearchIcon size={14} />
      </Input>
      <SourceSelect
        sourceIds={searchFilteredSources}
        selectedSourceId={selectedSourceId}
        setSelectedSourceId={setSelectedSourceId}
        isRequestLoadingById={isRequestLoadingById}
      />
      {selectedSourceId !== null && (
        <>
          <Button
            id="Connect Source"
            className="action"
            disabled={
              selectedSourceId === null ||
              (!sources.hostnames[selectedSourceId] &&
                !sources.ips[selectedSourceId]) ||
              isLinkLocal(sources.ips[selectedSourceId]) ||
              state.recording.isRecording
            }
            // eslint-disable-next-line sonarjs/cognitive-complexity
            onClick={async () => {
              setSelectedSourceId(null);

              if (selectedSourceId !== null) {
                // Update isRequestLoadingById functionally to
                // avoid stale closure in async context
                setIsRequestLoadingById((isRequestLoadingById) => ({
                  ...isRequestLoadingById,
                  [selectedSourceId]: true,
                }));
                const isRemove = sourceIsAdded(selectedSourceId);
                let response: string | SensorResponse | NodeResponse | null;

                const abortController = isSensor
                  ? addSensorAbortController
                  : addNodeAbortController;

                if (isRemove) {
                  if (isSensor) {
                    response = await sensor.remove(selectedSourceId);

                    // "Delete Sensor" endpoint might need hostname or IP, so try them.
                    if (response === null) {
                      // Remove by hostname (is probably a stale sensor)
                      if (sensors.hostnames[selectedSourceId]) {
                        response = await sensor.remove(
                          sensors.hostnames[selectedSourceId],
                        );
                      }
                      if (response === null && sensors.ips[selectedSourceId]) {
                        // Remove by IP (is probably a stale sensor)
                        response = await sensor.remove(
                          sensors.ips[selectedSourceId],
                        );
                      }
                    }
                  } else {
                    response = await node.remove(selectedSourceId);
                  }
                } else {
                  abortController.current = new AbortController();
                  if (isSensor) {
                    await attemptAddSensor(
                      sensors.hostnames[selectedSourceId] ||
                        sensors.ips[selectedSourceId],
                      abortController.current.signal,
                    );
                  } else {
                    await putNodeWithCredentials(
                      selectedSourceId,
                      nodes.hostnames[selectedSourceId] ||
                        nodes.ips[selectedSourceId],
                      abortController.current.signal,
                    );
                  }
                  response = null;
                }

                // If the controller aborts, our component must have unmounted
                if (abortController.current?.signal.aborted) return;

                abortController.current = null;
                setIsRequestLoadingById((isRequestLoadingById) => ({
                  ...isRequestLoadingById,
                  [selectedSourceId]: false,
                }));

                if (isRemove) {
                  const success = response !== null;
                  if (success) {
                    // Un-select and un-reference the source
                    if (selectedSourceId === state.setup.selected) {
                      dispatch({
                        type: 'setSelectedSource',
                        id: null,
                      });
                    }
                    if (selectedSourceId === state.setup.reference) {
                      dispatch({
                        type: 'setReferenceSource',
                        id: null,
                      });
                    }
                    dispatch({
                      type: isSensor ? 'setRemoveSensor' : 'setRemoveNode',
                      id: selectedSourceId,
                    });
                  }
                  dispatch({
                    type: 'enqueueFeedbackMessage',
                    value: {
                      message: `${
                        success ? 'Success in' : 'There was an error in'
                      } ${isRemove ? 'removing' : 'adding'} ${
                        isSensor ? 'sensor' : 'node'
                      } ${selectedSourceId}`,
                      type: success ? 'info' : 'error',
                      priority: 10,
                      durationMs: success ? 2000 : 10000,
                    },
                  });
                }
              }
            }}
            style={{
              width: 'calc(100% - var(--r3))',
            }}
          >
            {sourceIsAdded(selectedSourceId) ? 'Remove ' : 'Add '}
            {isSensor ? 'Sensor' : 'Node'}
          </Button>
          {isSensor && (
            <Button
              id="Configure Sensor"
              className="action"
              onClick={async () => {
                if (selectedSourceId === null) return;

                const proxyRoute = await proxy.getRoute(
                  selectedSourceId,
                  sources.ips[selectedSourceId],
                );
                if (proxyRoute === null) {
                  dispatch({
                    type: 'enqueueFeedbackMessage',
                    value: {
                      message: `There was an error in acquiring sensor configuration proxy for sensor ${selectedSourceId}`,
                      type: 'error',
                      priority: 10,
                      durationMs: 2000,
                    },
                  });
                  return;
                }

                window.open(
                  `${proxy_server_url}/${proxyRoute.proxyIndex}/`,
                  '_blank',
                );
              }}
              style={{
                marginBottom: 'var(--r6)',
                width: 'calc(100% - var(--r3))',
              }}
            >
              Configure {isSensor ? 'Sensor' : 'Node'}
            </Button>
          )}
          {!isSensor && isSelectedSourceReachable && (
            <Button
              id="Configure Node"
              className="action"
              onClick={async () => {
                if (selectedSourceId === null) return;
                window.open(sourceIdToLocalAddress(selectedSourceId), '_blank');
              }}
              style={{
                marginBottom: 'var(--r6)',
                width: 'calc(100% - var(--r3))',
              }}
            >
              Configure Node
            </Button>
          )}
        </>
      )}
      {!state.recording.isRecording && (
        <>
          <Input
            name={'Add new source by hostname'}
            onChange={(e) => {
              const value = e.target.value;
              setInputIP(value);
              setIsInputIPValid(
                isValidIp(value) || isValidSourceHostname(value),
              );
              setIsInputIPLinkLocal(isLinkLocal(value));
            }}
            isValid={isInputIPValid && isInputIPLinkLocal}
            value={inputIP}
            disabled={
              (isAddSensorByIPLoading && isAddNodeByIPLoading) ||
              state.recording.isRecording
            }
            invalidMessage={
              isInputIPLinkLocal
                ? 'Link Local addresses are not supported. Please use DHCP or static IP.'
                : !isInputIPValid
                ? 'Input a valid ipv4 address or hostname'
                : ''
            }
            onSubmit={() => {
              // This logic is here so pressing [enter] in the text box
              // either does what you want it to, or does nothing.
              if (isAddNodeByIPLoading || probablySensorAddress(inputIP)) {
                addNewSource('sensor');
              } else if (isAddSensorByIPLoading) {
                setInputId('');
                setIsAddNodeOpen(true);
              }
            }}
          />
          {isInputIPValid && (
            <div style={isAddNodeOpen ? {} : { display: 'flex' }}>
              {isAddNodeOpen ? (
                <>
                  <div style={{ width: '100%', marginBottom: 'var(--r4)' }}>
                    <Input
                      ref={inputUsernameRef}
                      name={'New node name'}
                      onChange={(e) => setInputId(e.target.value)}
                      isValid={inputId !== ''}
                      value={inputId}
                      invalidMessage={'Input a custom node name'}
                    />
                    <Input
                      name={'Node username'}
                      onChange={(e) => setInputUsername(e.target.value)}
                      isValid={inputUsername !== ''}
                      value={inputUsername}
                      invalidMessage={'Input a username'}
                    />
                    <Input
                      name={'Node password'}
                      onChange={(e) => setInputPassword(e.target.value)}
                      isValid={inputPassword !== ''}
                      value={inputPassword}
                      invalidMessage={'Input a password'}
                      type={'password'}
                      onSubmit={() => {
                        if (
                          !(
                            isAddNodeByIPLoading ||
                            isInputIPLinkLocal ||
                            inputId === '' ||
                            inputUsername === '' ||
                            inputPassword === ''
                          )
                        ) {
                          addNewSource('node');
                          setIsAddNodeOpen(false);
                        }
                      }}
                      style={{ border: 'none' }}
                    />
                    <Input
                      name={'Node port (leave blank for 443)'}
                      onChange={(e) => setInputPort(e.target.value)}
                      isValid={isNonNegativeIntegerString(inputPort)}
                      value={inputPort}
                      invalidMessage={'Ports must be non-negative integers'}
                    />
                  </div>
                  <Button
                    id="Back to Add Source"
                    className="action"
                    onClick={() => setIsAddNodeOpen(false)}
                    style={{
                      width: 'calc(20% - var(--r3))',
                      display: 'inline-block',
                      textAlign: 'center',
                    }}
                    tabIndex={-1}
                  >
                    {`←`}
                  </Button>
                  <Button
                    id="Add New Node"
                    className="action"
                    onClick={() => {
                      addNewSource('node');
                    }}
                    disabled={
                      isAddNodeByIPLoading ||
                      isInputIPLinkLocal ||
                      inputId === '' ||
                      inputUsername === '' ||
                      inputPassword === ''
                      // inputPort is optional
                    }
                    style={{
                      width: 'calc(80% - var(--r3))',
                      display: 'inline-block',
                    }}
                  >
                    <div
                      className="flex flex-center"
                      style={{ position: 'relative', width: '100%' }}
                    >
                      {isAddNodeByIPLoading && (
                        <div
                          className="loading-wrapper"
                          style={{
                            position: 'absolute',
                            left: 0,
                          }}
                        >
                          <LoadingSpinner />
                        </div>
                      )}
                      <p>
                        {isAddNodeByIPLoading ? `Connecting...` : 'New Node'}
                      </p>
                    </div>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    id="Add New Sensor"
                    className="action"
                    onClick={() => addNewSource('sensor')}
                    disabled={isAddSensorByIPLoading || isInputIPLinkLocal}
                    style={{
                      width: 'calc(50% - var(--r4))',
                      display: 'inline-block',
                    }}
                  >
                    <div
                      className="flex flex-center"
                      style={{ position: 'relative', width: '100%' }}
                    >
                      {isAddSensorByIPLoading && (
                        <div
                          className="loading-wrapper"
                          style={{
                            position: 'absolute',
                            left: 0,
                          }}
                        >
                          <LoadingSpinner />
                        </div>
                      )}
                      <p>
                        {isAddSensorByIPLoading
                          ? `Connecting...`
                          : 'New Sensor'}
                      </p>
                    </div>
                  </Button>
                  <Button
                    id="Add New Node"
                    className="action"
                    onClick={() => {
                      setInputId('');
                      setIsAddNodeOpen(true);
                    }}
                    disabled={isAddNodeByIPLoading || isInputIPLinkLocal}
                    style={{
                      width: 'calc(50% - var(--r4))',
                      display: 'inline-block',
                    }}
                  >
                    <div
                      className="flex flex-center"
                      style={{ position: 'relative', width: '100%' }}
                    >
                      {isAddNodeByIPLoading && (
                        <div
                          className="loading-wrapper"
                          style={{
                            position: 'absolute',
                            left: 0,
                          }}
                        >
                          <LoadingSpinner />
                        </div>
                      )}
                      <p>
                        {isAddNodeByIPLoading ? `Connecting...` : 'New Node'}
                      </p>
                    </div>
                  </Button>
                </>
              )}
            </div>
          )}
        </>
      )}
    </section>
  );
};
