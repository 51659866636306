/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useAppState } from '../../../Stores';
import { VisibilityButton } from './VisibilityButton';

export const Visibilities = (): JSX.Element => {
  const state = useAppState();

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translate(0,-50%)',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 12,
      }}
    >
      <VisibilityButton
        cluster="GlobalVis"
        icon="Grid"
        isActive={state.app.visibilities.Grid}
      />
      <VisibilityButton
        cluster="GlobalVis"
        icon="RangeRings"
        isActive={state.app.visibilities.RangeRings}
      />
      <VisibilityButton
        cluster="GlobalVis"
        icon="Axes"
        isActive={state.app.visibilities.Axes}
      />
      <VisibilityButton
        cluster="GlobalVis"
        icon="UnderlayMap"
        disabled={
          state.app.mode === 'map' || state.app.underlayMap.image === ''
        }
        isActive={state.app.visibilities.UnderlayMap}
      />
      {state.app.inputMode === 'playback' && (
        <VisibilityButton
          cluster="GlobalVis"
          icon="EventLocation"
          size="24px"
          isActive={state.app.visibilities.EventLocation}
        />
      )}
    </div>
  );
};
