/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Vector3 } from 'three';
import { ProcessedCloudSchema } from '../processedCloud/ProcessedCloudStore';
import { TrackedObjectsSchema } from '../trackedObjects/TrackedObjectsStore';
import { Extrinsic } from '../types';
import { ZoneSchema, OccupationSchema } from '../zone/ZoneStore';

export type DataRecorderEvent = {
  primarySensorSerialNo: string;
  startTimestamp: number;
  endTimestamp: number;
  latLongAlt: Vector3;
};
export type Offender = {
  id: number;
  position: Vector3;
  positionUncertainty: Vector3;
};
export type RecordingParams = {
  name: string;
  framerate: number;
  timestamps: number[];
  timestampToIndexMap: Map<number, number>;
  zones: ZoneSchema[];
  clouds: ProcessedCloudSchema[];
  stats: {
    maxOccupancies: number;
  };
  frames: {
    [timestamp: number]: {
      occupations?: OccupationSchema[];
      tracked?: TrackedObjectsSchema[];
      clusters?: TrackedObjectsSchema[];
      images?: Record<string, Uint8Array>;
    };
  };
  sensorsWithImages: Set<string>;
  sensorColors: Record<string, string>;
  extrinsics: Record<string, Extrinsic>;
  dataRecorderEvent: DataRecorderEvent;
  offender: null | Offender;
};

export type PlaybackActions =
  | {
      type: 'setPlayback';
      value: RecordingParams;
    }
  | {
      type: 'setPlaybackTimeIndex';
      offset: 'relative' | 'absolute';
      value: number;
    }
  | {
      type: 'setPlaybackFrameRate';
      value: number;
    };

export type PlaybackState = {
  playing: RecordingParams | null;
  timeIndex: number;
  isPlaying: boolean;
  frameRate: number;
};

export const PlaybackInitialState: PlaybackState = {
  playing: null,
  timeIndex: 0,
  isPlaying: false,
  frameRate: 10,
};

const reducer = (
  state: PlaybackState,
  action: PlaybackActions,
): PlaybackState => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'setPlayback': {
      const newState = { ...state };
      newState.playing = action.value;
      newState.frameRate = action.value.framerate;
      return newState;
    }
    case 'setPlaybackTimeIndex': {
      if (state.playing === null) return state;
      const rec = state.playing;
      const timestampsLen = rec.timestamps.length;
      const timestampsMaxIndex = timestampsLen - 1;
      const newState = { ...state };

      const newTimeIndex =
        action.offset === 'relative'
          ? state.timeIndex + action.value
          : action.value;

      // clamp
      // newState.timeIndex = Math.min(Math.max(newTimeIndex, 0), timestampsMaxIndex);
      // loop
      newState.timeIndex =
        newTimeIndex < 0
          ? timestampsMaxIndex + (newTimeIndex % timestampsLen)
          : newTimeIndex > timestampsMaxIndex
          ? newTimeIndex % timestampsLen
          : newTimeIndex;

      return newState;
    }
    case 'setPlaybackFrameRate': {
      const newState = { ...state };
      newState.frameRate = action.value;
      return newState;
    }
    default:
      return state;
  }
};

export const PlaybackReducer = (
  state: PlaybackState,
  action: PlaybackActions,
): PlaybackState => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const newState = reducer(state, action);
  return newState;
};
