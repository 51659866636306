/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useState } from 'react';
import { endpoints } from '../api/endpoints';
import { ListRecordingsResponse } from '../api/record';
import { FETCH_FILES_POLL_INTERVAL } from '../constants';
import { useAppState } from '../Stores';
import { ObjectEntries } from '../util/misc';
import useInterval from '../app/hooks/useInterval';
import { ListContainer } from '../app/components/pane/ListContainer';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { SelectableListElem } from '../app/components/pane/SelectableListElem';
import RecordingButtons from './RecordingButtons';

const {
  record: { recording },
} = endpoints;

export const MAX_FILENAME_SIZE = {
  LIST: 25,
  SELECT: 15,
};
const RecordingElem = ({
  fileName,
  selectedRecording,
  setSelectedRecording,
}: {
  fileName: string;
  selectedRecording: string;
  setSelectedRecording: (value: string) => void;
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: '0.3rem 0',
    }}
    key={fileName}
  >
    <SelectableListElem
      size="14px"
      disabled={false}
      active={true}
      isSelected={fileName === selectedRecording}
      onClick={() => {
        setSelectedRecording(fileName === selectedRecording ? '' : fileName);
      }}
    >
      {fileName.length > MAX_FILENAME_SIZE.LIST
        ? `${fileName.substring(0, MAX_FILENAME_SIZE.LIST)}...`
        : fileName}
    </SelectableListElem>
  </div>
);

const RecordingList = (): JSX.Element => {
  const state = useAppState();
  const [selectedRecording, setSelectedRecording] = useState('');
  const recordingState = state.recording;
  const [fileNamesJSON, setFileNamesJSON] = useState<ListRecordingsResponse>(
    {},
  );

  const fetchFiles = async () => {
    setFileNamesJSON((await recording.list()) ?? {});
  };

  const [, setActive] = useInterval(
    fetchFiles,
    FETCH_FILES_POLL_INTERVAL,
    false,
  );

  useEffect(() => {
    if (state.app.inputMode !== 'live') return;
    fetchFiles();
    setActive(true);

    return () => setActive(false);
  }, [state.app.inputMode]);

  // Filter out the file being recording currently, if there is one
  const files = ObjectEntries(fileNamesJSON).filter(
    ([nodeName]) => nodeName !== recordingState.recordingName,
  );

  return (
    <PropertiesSubGroup name={`Actions`}>
      <ListContainer>
        {files.map(([fileName], idx) => {
          return (
            <RecordingElem
              key={`${idx}-fileName`}
              fileName={fileName}
              selectedRecording={selectedRecording}
              setSelectedRecording={setSelectedRecording}
            />
          );
        })}
      </ListContainer>
      <RecordingButtons
        isDeveloperMode={state.app.developerMode}
        selectedRecording={selectedRecording}
        setSelectedRecording={setSelectedRecording}
        filesJSON={fileNamesJSON}
      />
    </PropertiesSubGroup>
  );
};

export default RecordingList;
