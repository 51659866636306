/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';

// reusable paths
const paths = {
  select:
    'M 3.4654223,2.0804255 C 3.3533092,2.1111273 3.2867767,2.2289002 3.3168163,2.3434798 L 6.8828379,15.944849 C 6.9128839,16.059423 7.0281177,16.127424 7.1402295,16.096721 7.1936872,16.082091 7.2391326,16.04609 7.2663335,15.996816 L 10.140132,10.901846 15.418091,9.4565086 C 15.599327,9.4072339 15.631445,9.1584261 15.468907,9.0628014 L 3.6250272,2.1016472 C 3.5768451,2.0727411 3.5192384,2.0650816 3.4654223,2.0804255 Z',
  selectSml:
    'm 7.6388074,6.5160931 c -0.087874,0.023546 -0.1400222,0.113869 -0.1164764,0.201743 l 2.79504,10.4312329 c 0.02355,0.08787 0.11387,0.140022 0.201743,0.116475 0.0419,-0.01122 0.07752,-0.03883 0.09884,-0.07662 l 2.252477,-3.907461 4.136852,-1.108466 c 0.142053,-0.03779 0.167227,-0.228607 0.03983,-0.301944 L 7.7639059,6.5323686 C 7.7261402,6.5101998 7.6809883,6.5043255 7.6388074,6.5160931 Z',
  selectPlus:
    'M 7.1549867,5.9407605 H 8.9829104 V 7.606944 H 10.520958 V 9.1968271 H 8.9829104 V 10.908249 H 7.1549867 V 9.1968271 H 5.5511896 V 7.606944 H 7.1549867 Z M 3.431645,2.286404 C 3.3207762,2.317037 3.2557324,2.431748 3.2863654,2.542616 L 6.9227532,15.703542 C 6.9533863,15.81441 7.068097,15.879454 7.1789656,15.848821 7.2318354,15.834222 7.2766015,15.798946 7.3031808,15.750976 L 10.11261,10.788554 11.900652,10.294515 15.332014,9.346423 C 15.511245,9.297247 15.541194,9.055747 15.379404,8.96428 L 3.5899695,2.305751 C 3.5420007,2.278095 3.4848592,2.271112 3.431645,2.286404 Z',
  cylinder:
    'm 5.646515,0.8173325 c -1.1890649,0 -2.2742883,0.2243208 -3.1256302,0.6378456 -0.764402,0.3712962 -1.4597993,0.9944488 -1.591859,1.7975647 -0.010827,1.9048095 -0.04374,3.8100388 -0.031746,5.7144222 0,0.4756207 0.201524,0.890189 0.4975569,1.204612 0.2960329,0.314423 0.6787277,0.548243 1.0942516,0.750077 0.8513419,0.413522 1.9384323,0.637845 3.1275008,0.637845 1.1890649,0 2.2742871,-0.224323 3.1256302,-0.637845 0.8013188,-0.38923 1.4492337,-1.075346 1.5693617,-1.9023141 0.01547,-1.90773 0.05298,-3.8530971 0.05423,-5.5086664 0,-0.9515836 -0.7403495,-1.6412041 -1.5936787,-2.0556957 C 7.6815722,1.0361966 6.660798,0.8434975 5.6876734,0.8192016 c -0.013666,-0.00141 -0.027416,-0.00203 -0.041154,-0.00186 z m 0,1.5581389 c 0.9840981,0 1.8741515,0.2054278 2.4447623,0.4825927 0.5730011,0.2783256 0.7145378,0.5856712 0.7145378,0.6528096 0,0.06714 -0.1415367,0.3726138 -0.7145378,0.6509393 C 7.5206665,4.4389777 6.6306131,4.6444058 5.646515,4.6444058 4.6624157,4.6444058 3.7722191,4.4389074 3.2017526,4.161813 2.6287515,3.8834875 2.4872161,3.5780131 2.4872161,3.5108737 c 0,-0.067138 0.1415354,-0.3744839 0.7145365,-0.6528096 C 3.7722191,2.5809692 4.6624157,2.3754714 5.646515,2.3754714 Z M 2.4853455,5.5441233 c 0.012451,0.00618 0.023025,0.014495 0.035536,0.020576 0.8513431,0.4135262 1.9365653,0.6397161 3.1256302,0.6397161 1.1890661,0 2.2744049,-0.2262472 3.1256301,-0.6397161 0.010466,-0.00506 0.019548,-0.011693 0.02993,-0.016834 0.014965,1.291837 0.016192,2.0363468 -0.026189,3.4099485 -4.09e-5,0.00313 -4.09e-5,0.00626 0,0.00938 0,0.033575 -0.00385,0.071974 -0.1084839,0.1720873 C 8.5628127,9.2393947 8.3615594,9.373177 8.0613523,9.5189986 7.4910398,9.796016 6.6009406,10.001591 5.6165899,10.001591 4.6322368,10.001591 3.7421376,9.796016 3.1718263,9.5189986 2.6861813,9.2831034 2.5160765,9.0143708 2.4853479,8.8961164 c -0.014772,-1.1755664 -0.00397,-2.2981287 0,-3.3519625 z',
  cube: 'M 7.1171442,2.0180915 H 10.458969 V 5.0642233 H 13.270842 V 7.9708623 H 10.458969 V 11.099699 H 7.1171442 V 7.9708623 H 4.1850682 V 5.0642233 H 7.1171442 Z',
  plus: 'M 7.5309346,2.0180915 H 10.87276 V 5.0642233 H 13.684633 V 7.9708623 H 10.87276 V 11.099699 H 7.5309346 V 7.9708623 H 4.5988586 V 5.0642233 H 7.5309346 Z',
  minus: 'M 13.684633,12.974981 V 15.88162 H 4.5988586 V 12.974981 Z',
  plusSml:
    'M 12.911374,1.2004993 H 15.049778 V 3.1496916 H 16.84907 V 5.0096237 H 15.049778 V 7.0117382 H 12.911374 V 5.0096237 H 11.035165 V 3.1496916 H 12.911374 Z',
  ring: 'M 5.9218752,10.80175 Q 4.9233475,10.80175 4.0321356,10.421515 3.1414685,10.041278 2.4719701,9.3717804 1.8024718,8.7022817 1.4222359,7.8116148 1.042,6.9204029 1.042,5.9218752 1.042,4.9119077 1.4222359,4.0266881 1.8024718,3.1414685 2.4719701,2.4719701 3.1414685,1.8024718 4.0321356,1.4222359 4.9233475,1.042 5.9218752,1.042 6.9318427,1.042 7.8170622,1.4222359 8.7022817,1.8024718 9.3717804,2.4719701 10.041278,3.1414685 10.421515,4.0266881 10.80175,4.9119077 10.80175,5.9218752 10.80175,6.9204029 10.421515,7.8116148 10.041278,8.7022817 9.3717804,9.3717804 8.7022817,10.041278 7.8170622,10.421515 6.9318427,10.80175 5.9218752,10.80175 Z M 5.9218752,8.3051587 Q 6.9204029,8.3051587 7.6127808,7.6073333 8.3051587,6.9089632 8.3051587,5.9218752 8.3051587,4.9233475 7.6127808,4.2309697 6.9204029,3.5385916 5.9218752,3.5385916 4.9347873,3.5385916 4.236417,4.2309697 3.5385916,4.9233475 3.5385916,5.9218752 3.5385916,6.9089632 4.236417,7.6073333 4.9347873,8.3051587 5.9218752,8.3051587 Z',
  handWithIndex:
    'M 7.6257286,16.846028 1.3490218,14.362548 1.755439,13.07556 C 1.8305812,12.774631 1.9810455,12.55643 2.206833,12.420957 2.4326205,12.285485 2.7261437,12.187583 3.0874036,12.127253 L 5.910106,11.63034 2.2973286,3.547515 C 2.1166989,3.0959405 2.1017064,2.6631512 2.2523518,2.2491476 2.4026356,1.8351439 2.7035653,1.5302407 3.1551398,1.3344379 3.6067138,1.1538081 4.0432963,1.1350225 4.4648864,1.2780814 4.8864765,1.4211403 5.1949922,1.7260435 5.3904339,2.192791 L 7.4003025,6.7533339 8.1226413,6.4146529 C 8.2880981,6.3546836 8.4347697,6.3057331 8.5626557,6.2678009 8.6909031,6.23023 8.8377551,6.2190308 9.0032119,6.2342037 L 12.796439,6.3696761 C 13.277998,6.384849 13.676829,6.5504868 13.992931,6.8665889 14.309033,7.1826909 14.542407,7.5665298 14.693053,8.0181043 L 15.731855,12.01454 C 15.867327,12.511272 15.814584,12.989038 15.573623,13.447838 15.332663,13.906999 14.97899,14.249473 14.512603,14.475261 L 9.2064205,16.801051 C 8.9506486,16.936523 8.6872903,17.004259 8.4163455,17.004259 8.1454007,17.004259 7.8818619,16.951516 7.6257286,16.846028 Z',
  arrowStroke:
    'M 3.6792911,2.6626159 14.543219,9.2939873 9.6834148,10.469697 6.7361388,15.298109 Z M 2.4863848,0.92129354 A 0.25,0.25 0 0 0 2.3021798,1.2230693 L 6.1561594,17.15351 A 0.25,0.25 0 0 0 6.457935,17.337716 0.245,0.245 0 0 0 6.6107453,17.225126 L 10.173209,11.38005 16.490957,9.8516263 A 0.25,0.25 0 0 0 16.562589,9.3949803 L 2.6755868,0.95062654 A 0.245,0.245 0 0 0 2.4863848,0.92129354 Z',
  world:
    'M 9,17.00625 Q 7.4,17.00625 5.9,16.40625 4.4,15.80625 3.3,14.70625 2.2,13.60625 1.6,12.00625 0.99999995,10.60625 0.99999995,9.0062502 0.99999995,7.3062502 1.6,5.9062502 2.2,4.4062502 3.3,3.3062502 4.4,2.3062502 5.9,1.6062502 7.4,0.90625012 9,1.0062502 10.7,1.0062502 12.1,1.6062502 13.6,2.2062502 14.7,3.3062502 15.7,4.4062502 16.4,5.9062502 17,7.3062502 17,9.0062502 17,10.60625 16.4,12.10625 15.8,13.60625 14.7,14.70625 13.6,15.80625 12.1,16.40625 10.7,17.00625 9,17.00625 Z M 9,15.40625 Q 9.4,15.10625 9.7,14.10625 10.1,13.10625 10.3,12.00625 H 7.7 Q 7.8999999,13.10625 8.3,14.10625 8.6,15.10625 9,15.40625 Z M 7.1,15.20625 6.6,13.80625 6.2,12.00625 H 3.2 Q 4,13.20625 5,14.00625 6,14.90625 7.2,15.20625 Z M 10.9,15.20625 Q 12.1,14.90625 13.1,14.00625 14.1,13.20625 14.7,12.00625 H 11.7 Q 11.7,13.00625 11.4,13.80625 Z M 11.9,10.50625 H 15.3 L 15.5,9.7062502 V 8.2062502 L 15.3,7.5062502 H 12 V 10.50625 Z M 7.6,10.50625 H 10.4 V 9.7062502 L 10.5,9.0062502 V 7.5062502 H 7.5 V 10.50625 Z M 2.6,10.50625 H 6 V 7.5062502 H 2.7 L 2.5,8.3062502 V 9.7062502 L 2.7,10.50625 Z M 11.9,6.0062502 H 14.9 Q 14,4.8062502 13,4.0062502 12,3.1062502 10.8,2.8062502 L 11.3,4.2062502 11.7,6.0062502 Z M 7.8999999,6.0062502 H 10.3 Q 10.2,4.9062502 9.8000001,3.9062502 9.5,2.9062502 9.1,2.6062502 8.7,2.9062502 8.4,3.9062502 8,4.9062502 7.7999999,6.0062502 Z M 3.2,6.0062502 H 6.2 L 6.6,4.2062502 7.1,2.8062502 Q 5.9,3.0062502 4.9,4.0062502 3.9,4.8062502 3.3,6.0062502 Z',
  map: 'M 12.3,1.1061809 8.4999999,3.0056809 4.7234999,1.1171809 A 0.5,0.5 0 0 0 4.2765,1.1171809 L 0.77649997,2.8671809 A 0.5,0.5 0 0 0 0.49999997,3.3146809 V 16.196681 A 0.5,0.5 0 0 0 1.2235,16.643681 L 4.4999999,15.005681 8.2764999,16.893681 A 0.5,0.5 0 0 0 8.7234999,16.893681 L 12.5,15.005681 16.8145,16.731181 A 0.5,0.5 0 0 0 17.5,16.267181 V 3.3441809 A 0.5,0.5 0 0 0 17.1855,2.8796809 L 12.7085,1.0891809 A 0.5,0.5 0 0 0 12.3,1.1061809 Z M 8.4999999,15.923681 4.4999999,13.923681 V 2.0531809 L 8.4999999,4.0531808 Z M 16.5,15.568181 12.5,14.005681 V 2.0056809 L 16.5,3.5681809 Z',
};

export type ToolIcon =
  | 'Select'
  | 'SelectMulti'
  | 'SelectTracked'
  | 'SelectZones'
  | 'Camera'
  | 'Ruler'
  | 'Pencil'
  | 'SelectVertex'
  | 'ZoneAddRemoveVertex'
  | 'World'
  | 'Map';

export const ToolIcons = ({
  icon,
  active,
  size = '24px',
  ...rest
}: {
  icon: ToolIcon;
  active: boolean;
  size?: string;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 18 18"
      viewBox="0 0 18 18"
      className={`ToolIcons ${active ? 'active' : ''}`}
      {...rest}
    >
      <path d="M0,0h24v24H0V0z" fill="none" />
      {
        {
          Select: <path d={paths.select} />,
          SelectMulti: (
            <g>
              <path d={paths.selectSml} />
              <path d={paths.plus} />
            </g>
          ),
          SelectTracked: (
            <g>
              <path d={paths.select} />
            </g>
          ),
          SelectZones: (
            <g>
              <path d={paths.arrowStroke} />
            </g>
          ),
          Camera: (
            <path d="M16.5,4H13.475L11.8,2.163A.5.5,0,0,0,11.4295,2H6.5705a.5.5,0,0,0-.3695.163L4.525,4H1.5a.5.5,0,0,0-.5.5v10a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5V4.5A.5.5,0,0,0,16.5,4ZM9,13.1A4.1,4.1,0,1,1,13.1,9,4.1,4.1,0,0,1,9,13.1Z" />
          ),
          Ruler: (
            <path d="M12.5355,1.222,1.222,12.5355a.5.5,0,0,0,0,.707L4.7575,16.778a.5.5,0,0,0,.707,0L7.232,15.0105l-2.65-2.65a.25.25,0,0,1,0-.3535l.3535-.3535a.25.25,0,0,1,.3535,0l2.65,2.65,2.8285-2.8285L8.8225,9.5305a.25.25,0,0,1,0-.3535l.354-.354a.25.25,0,0,1,.3535,0l1.9445,1.945L14.303,7.9395l-2.65-2.65a.25.25,0,0,1,0-.3535l.3535-.3535a.25.25,0,0,1,.35355-.00045l.00045.00045,2.65,2.65L16.778,5.465a.5.5,0,0,0,0-.707L13.2425,1.222A.5.5,0,0,0,12.5355,1.222Z" />
          ),
          Pencil: (
            <g>
              <path d="M10.227,4,2.542,11.686a.496.496,0,0,0-.1255.2105L1.0275,16.55c-.057.188.2295.425.3915.425a.15587.15587,0,0,0,.031-.003c.138-.032,3.9335-1.172,4.6555-1.389a.492.492,0,0,0,.2075-.125L14,7.772ZM5.7,14.658c-1.0805.3245-2.431.7325-3.3645,1.011L3.34,12.304Z" />
              <path d="M16.7835,4.1,13.9,1.216a.60751.60751,0,0,0-.433-.1765H13.45a.686.686,0,0,0-.4635.2035l-2.05,2.05L14.708,7.0645l2.05-2.05a.686.686,0,0,0,.2-.4415A.612.612,0,0,0,16.7835,4.1Z" />
            </g>
          ),
          SelectVertex: (
            <g>
              <path d="M3.7257 1.6516c-.2756-.0016-.498.2138-.4994.481v6.7766c0 .258.2238.4854.4994.481l2.53-.0407.0816-4.005c.0136-.6693.5517-1.2125 1.2698-1.2172.3534-.0006.6935.1371.9362.3804l2.5304 2.5221.14-4.8972c-.0013-.2672-.227-.4825-.5026-.481-2.3483.0915-4.91-.034-6.9854 0z" />
              <path d="m8.0051 6.3247 7.8102 7.339h-4.6005l-3.3885 3.3536Z" />
            </g>
          ),
          ZoneAddRemoveVertex: (
            <g>
              <path d={paths.plus} />
              <path d={paths.minus} />
            </g>
          ),
          World: <path d={paths.world} />,
          Map: <path d={paths.map} />,
        }[icon]
      }
    </svg>
  );
};
