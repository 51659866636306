/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Color, Quaternion, Vector3 } from 'three';
import { randFloat, randFloatSpread } from 'three/src/math/MathUtils';
import {
  Axis,
  CloudDisplayMode,
  ColorGradient,
  MinMax,
  PerceptionClassification,
  Palette,
  Palettes as PaletteNames,
  PointDescriptor,
  ServerType,
} from './types';
import { hexToRgb } from './util/color';
import { ObjectKeys } from './util/misc';

import {
  BLUE_GREY,
  DEEP_ORANGE,
  ORANGE,
  PALETTE_MATERIAL,
  PALETTE_FLUENT,
  PALETTE_TAILWIND,
  HUES,
  PALETTE_GRUVBOX,
  PALETTE_OUSTER,
  PALETTE_VIRIDIS,
  PALETTE_MONO,
  PALETTE_MATERIAL_DARK,
  PALETTE_TAILWIND_DARK,
  PALETTE_MAGMA,
  PALETTE_FLUENT_DARK,
  PALETTE_CAL_REFL,
  PALETTE_CAL_REFL_DARK,
} from './util/palettes';

export const IDENTITY_POSE = {
  pos: new Vector3(),
  rot: new Quaternion(),
};

export const TWO_PI = 2 * Math.PI;
export const PI_HALF = 0.5 * Math.PI;
export const UNIT_X = new Vector3(1, 0, 0);
export const UNIT_Y = new Vector3(0, 1, 0);
export const UNIT_Z = new Vector3(0, 0, 1);
export const ORIGIN = new Vector3(0, 0, 0);
export const V3_ALL_ONES = new Vector3(1, 1, 1);
export const QUATERNION_IDENTITY = new Quaternion();
export const UNIT: Record<Axis, Vector3> = { x: UNIT_X, y: UNIT_Y, z: UNIT_Z };
export const HAS_VELOCITY_VECTORS = new Set<PerceptionClassification>([
  'Vehicle',
  'LargeVehicle',
  'Bicycle',
]);
export const RANGE_RING_RANGE_OPTIONS = [
  0.25, 0.5, 1, 5, 10, 25, 50, 100, 200, 300, 400, 500,
];
export const RANGE_RING_GRANULARITY_OPTIONS = [
  0.25, 0.5, 1, 2.5, 5, 10, 25, 50, 100,
];
export const GRID_RANGE_OPTIONS = [100, 200, 300, 400, 500];
export const GRID_GRANULARITY_OPTIONS = [0.25, 0.5, 1, 2.5, 5, 10, 25, 50, 100];

export const FULL_ROTATION_DEGREES = 360 as number;

export const MAX_CLOUDS_IN_NODE = 8;
export const MAX_SENSOR_RESOLUTION = 4096 * 128;
export const MAX_NODE_RESOLUTION = MAX_SENSOR_RESOLUTION * MAX_CLOUDS_IN_NODE;
export const MAX_TRACKED_OBJS_INSTANCES_COUNT = 1000;
export const MAX_AUTO_ROTATE_SPEED = 10;

export const ATTRIBUTE_NAME = `data-classification`;
export const LABELS_CONTAINER_CLASSNAME = `labelsContainer`;
export const SELECTION_DIV_ID = `selectionDiv`;

export const MAX_VELOCITY_SCALE = 5;
export const VELOCITY_REF = 20;

export const COLOR_IDEAL = BLUE_GREY;
export const COLOR_HIGHLIGHTED = ORANGE;
export const COLOR_SELECTED = DEEP_ORANGE;
// reflecting the colors from index.css
export const COLOR_INFO = '#838ec2';
export const COLOR_SUCCESS = '#a9b1a9';
export const COLOR_WARNING = '#e0ac5d';
export const COLOR_ERROR = '#b67584';
export const COLOR_FATAL = '#69313e';

const dpi = window.devicePixelRatio;
export const DEFAULT_PROCESSED_CLOUD_POINTS_SIZE = Math.round(dpi);
export const DEFAULT_TRACKED_POINTS_SIZE = Math.round(1.25 * dpi);
export const DEFAULT_SENSOR_CLOUD_POINTS_SIZE = Math.round(1.5 * dpi);
export const DEFAULT_NODE_CLOUD_POINTS_SIZE = Math.round(1.5 * dpi);

// Constants to identify Three.Layer bitmask values by name instead of the actual values
export const LAYER_DEFAULT = 0;
export const LAYER_ICONS = 1;

export const MIN_POINTS_SIZE = 1;
export const MAX_POINTS_SIZE = 5;

export const PRESET_COLORS: Record<PointDescriptor, ColorGradient> = {
  Background: {
    min: HUES.DEEP_PURPLE,
    max: HUES.BLUE,
  },
  Raw: {
    min: HUES.TEAL,
    max: HUES.GREEN,
  },
  Foreground: {
    min: HUES.PURPLE,
    max: HUES.BLUE,
  },
  Ground: {
    min: HUES.GREEN,
    max: HUES.YELLOW,
  },
  // Types below here don't actually get sent
  NoReturn: {
    min: HUES.RED,
    max: HUES.RED,
  },
  FailedReturn: {
    min: HUES.RED,
    max: HUES.RED,
  },
  ZoneFiltered: {
    min: HUES.RED,
    max: HUES.RED,
  },
};

export const PRESET_RANGES: Record<CloudDisplayMode, MinMax> = {
  Height: {
    min: -2,
    max: 2,
  },
  Range: {
    min: 0,
    max: 30,
  },
  Signal: {
    min: 0,
    max: 255,
  },
  Nearir: {
    min: 0,
    max: 255,
  },
  Reflectivity: {
    min: 0,
    max: 255,
  },

  // Unused
  CalRef: {
    min: 0,
    max: 0,
  },
  Fixed: {
    min: 0,
    max: 0,
  },
};

export const HEIGHT_RANGE: { default: MinMax; limit: MinMax } = {
  default: { min: -10, max: 10 },
  limit: { min: -500, max: 500 },
};

export const DISPLAY_MODE_ABSOLUTES: Record<CloudDisplayMode, MinMax> = {
  Height: {
    min: -500,
    max: 500,
  },
  Range: {
    min: -500,
    max: 500,
  },
  Signal: {
    min: 0,
    max: 255,
  },
  Nearir: {
    min: 0,
    max: 255,
  },
  Reflectivity: {
    min: 0,
    max: 255,
  },

  // Unused
  CalRef: {
    min: 0,
    max: 0,
  },
  Fixed: {
    min: 0,
    max: 0,
  },
};

export const Palettes: Record<Palette, string[]> = {
  Material: [...PALETTE_MATERIAL, ...PALETTE_MATERIAL_DARK, ...PALETTE_MONO],
  Fluent: [...PALETTE_FLUENT, ...PALETTE_FLUENT_DARK, ...PALETTE_MONO],
  Tailwind: [...PALETTE_TAILWIND, ...PALETTE_TAILWIND_DARK, ...PALETTE_MONO],
  Gruvbox: [...PALETTE_GRUVBOX, ...PALETTE_MONO],
  Ouster: [...PALETTE_OUSTER, ...PALETTE_MAGMA, ...PALETTE_MONO],
  Ouster2: [...PALETTE_MAGMA, ...PALETTE_VIRIDIS, ...PALETTE_MONO],
  Data: [...PALETTE_CAL_REFL, ...PALETTE_CAL_REFL_DARK, ...PALETTE_MONO],
};

export const totalColorVariations = 100;
// Cached color variations of all palette's scheme
export const paletteVariations: Record<Palette, Color[][]> =
  PaletteNames.reduce((acc, paletteName) => {
    acc[paletteName as Palette] = Palettes[paletteName].map((color: string) =>
      [...new Array(totalColorVariations)].map((_, i) => {
        const r = i / totalColorVariations;
        return new Color(color).offsetHSL(
          r * 0.1 - 0.05,
          randFloat(-0.4, 0.1),
          randFloatSpread(0.2),
        );
      }),
    );
    return acc;
  }, {} as Record<Palette, Color[][]>);

// Color variations of the palette's scheme as a tuple
export const paletteVariationsRBG = ObjectKeys(paletteVariations).reduce(
  (acc, theme) => {
    const o = paletteVariations[theme].map((colors) => {
      return colors.map((color) => {
        const ccc = '#' + color.getHexString();
        return hexToRgb(ccc).map((e) => e / 255);
      });
    });
    acc[theme] = o;
    return acc;
  },
  {} as Record<Palette, number[][][]>,
);

/** REST & Websockets */
// set REACT_APP_ENV = production to use proxies in production (docker),
// production-test to use proxies locally (local, non-ssl, nginx) or
// development to bind to services directly.

// Authentication Credentials for production-test
export const AUTH_USERNAME = process.env.REACT_APP_TEST_AUTH_USERNAME ?? '';
export const AUTH_PASSWORD = process.env.REACT_APP_TEST_AUTH_PASSWORD ?? '';

// True if developing locally -- some urls
// use different protocols / ports in production.
export const IS_LOCAL = process.env.REACT_APP_ENV === 'development';
// Set to simulate a production environment locally
// where service proxies are used instead of connecting to the services directly.
// We assume the local nginx server is not using ssl and is running on port 80.
export const USING_PROXIES = process.env.REACT_APP_ENV === 'production-test';

// Ports
export const PERCEPTION_WS_PORT = 3001 as number;
export const EVENT_WS_PORT = 3004 as number;
export const ALIGN_WS_PORT = 3005 as number;
export const NODE_WS_PORT = 3006 as number;
export const PROXY_REST_PORT = 4000 as number;
export const PROXY_SERVER_PORT = 4005 as number;
export const REST_PORT = 8000 as number;
export const EVENT_REST_PORT = 8001 as number;
export const DISCOVERY_REST_PORT = 8002 as number;
export const LIDAR_HUB_REST_PORT = 8003 as number;
export const AGENT_REST_PORT = 4443 as number;
export const NGINX_LOCAL_PORT = 443 as number;

// Docker expects the files to be served on the same port as the gui
export const NGINX_PORT =
  IS_LOCAL || USING_PROXIES ? NGINX_LOCAL_PORT : window.location.port;
export const HTTP_PROTOCOL = IS_LOCAL ? 'http' : 'https';
export const WS_PROTOCOL = IS_LOCAL ? 'ws' : 'wss';

// Intervals
export const SECONDS_TO_MS = 1e3 as number;
export const SECONDS_TO_uS = 1e6 as number;
export const WS_RECONNECT_INTERVAL = (1 * SECONDS_TO_MS) as number;
export const FETCH_SETTINGS_FAIL_INTERVAL = (1 * SECONDS_TO_MS) as number;
export const TELEMETRY_POLL_INTERVAL = (1 * SECONDS_TO_MS) as number;
export const ABOUT_POLL_INTERVAL = (5 * SECONDS_TO_MS) as number;
export const SENSOR_POLL_INTERVAL = (1 * SECONDS_TO_MS) as number;
export const NODE_POLL_INTERVAL = (1 * SECONDS_TO_MS) as number;
export const ALERTS_POLL_INTERVAL = (5 * SECONDS_TO_MS) as number;
export const ZONE_ALERTS_POLL_INTERVAL = (1 * SECONDS_TO_MS) as number;
export const FETCH_FILES_POLL_INTERVAL = (1 * SECONDS_TO_MS) as number;
export const LICENSE_POLL_INTERVAL = (2 * SECONDS_TO_MS) as number;
export const DIAGNOSTICS_POLL_INTERVAL = (10 * SECONDS_TO_MS) as number;
export const DEFAULT_REQUEST_TIMEOUT = (30 * SECONDS_TO_MS) as number;

export const http_nginx_url = `${HTTP_PROTOCOL}://${window.location.hostname}:${NGINX_PORT}`;
export const ws_nginx_url =
  `${WS_PROTOCOL}://` + window.location.hostname + ':' + NGINX_PORT;
export const ws_local_url = 'wss://' + window.location.hostname;

export const align_wss_url = IS_LOCAL
  ? ws_local_url + ':' + ALIGN_WS_PORT
  : ws_nginx_url + '/align_stream';
export const perception_wss_url = IS_LOCAL
  ? ws_local_url + ':' + PERCEPTION_WS_PORT
  : ws_nginx_url + '/perception_stream';
export const node_wss_url = IS_LOCAL
  ? ws_local_url + ':' + NODE_WS_PORT
  : ws_nginx_url + '/node_stream';
export const event_wss_url = IS_LOCAL
  ? ws_local_url + ':' + EVENT_WS_PORT
  : ws_nginx_url + '/event_stream';
export const logs_prefix_url = `${http_nginx_url}/logs`;
export const data_prefix_url = `${http_nginx_url}/data`;

export const perception_url = IS_LOCAL
  ? `https://${window.location.hostname}:${REST_PORT}/perception/api/v1`
  : http_nginx_url + '/perception/api/v1';
export const event_url = IS_LOCAL
  ? `https://${window.location.hostname}:${EVENT_REST_PORT}/event/api/v1`
  : http_nginx_url + '/event/api/v1';
export const discovery_url = IS_LOCAL
  ? `https://${window.location.hostname}:${DISCOVERY_REST_PORT}/discovery/api/v1`
  : http_nginx_url + '/discovery/api/v1';
export const proxy_rest_url = IS_LOCAL
  ? `https://${window.location.hostname}:${PROXY_REST_PORT}`
  : http_nginx_url + '/api/sensorproxyrest/api/v1';
export const proxy_server_url = IS_LOCAL
  ? `http://${window.location.hostname}:${PROXY_SERVER_PORT}`
  : http_nginx_url + '/api/sensorproxy';
export const proxy_rest_reachable = IS_LOCAL
  ? `http://${window.location.hostname}:${PROXY_SERVER_PORT}`
  : http_nginx_url + '/api/sensorproxyrest';
export const lidar_hub_url = IS_LOCAL
  ? `https://${window.location.hostname}:${LIDAR_HUB_REST_PORT}/lidar-hub/api/v1`
  : http_nginx_url + '/lidar-hub/api/v1';
export const agent_url = IS_LOCAL
  ? `http://${window.location.hostname}:${AGENT_REST_PORT}/agent/api/v1`
  : http_nginx_url + '/agent/api/v1';

export const PERCEPTION_SWAGGER_URL = IS_LOCAL
  ? `https://${window.location.hostname}:${REST_PORT}/perception/api/v1/`
  : http_nginx_url + '/perception/api/v1/';
export const EVENT_SWAGGER_URL = IS_LOCAL
  ? `https://${window.location.hostname}:${EVENT_REST_PORT}/event/api/v1/`
  : http_nginx_url + '/event/api/v1/';
export const DISCOVERY_SWAGGER_URL = IS_LOCAL
  ? `https://${window.location.hostname}:${DISCOVERY_REST_PORT}/discovery/api/v1/swagger/ui`
  : http_nginx_url + '/discovery/api/v1/swagger/ui';
export const LIDAR_HUB_SWAGGER_URL = IS_LOCAL
  ? `https://${window.location.hostname}:${LIDAR_HUB_REST_PORT}/lidar-hub/api/v1/`
  : http_nginx_url + '/lidar-hub/api/v1/';
export const AGENT_SWAGGER_URL = IS_LOCAL
  ? `https://${window.location.hostname}:${AGENT_REST_PORT}/agent/api/v1/swagger/ui/index.html`
  : http_nginx_url + '/agent/api/v1/swagger/ui/index.html';

export const REST = {
  url: `${perception_url}/`,
  settings: {
    settings: `${perception_url}/settings`,
    setProfile: (profile: string): string =>
      `${perception_url}/set_profile/${profile}`,
    profile: (profile: string): string =>
      `${perception_url}/profile/${profile}`,
    listProfiles: `${perception_url}/profiles`,
    restoreProfile: (profile: string): string =>
      `${perception_url}/restore_profile/${profile}`,
  },
  icp: `${perception_url}/extrinsics/icp`,
  extrinsic: (sensorId?: string, frame?: string): string =>
    `${perception_url}/extrinsics${sensorId ? '/' + sensorId : ''}${
      frame ? '/' + frame : ''
    }`,
  pointZones: `${perception_url}/point_zones`,
  pointZone: (id: number): string => `${perception_url}/point_zones/${id}`,
  addZone: (zoneId: string): string => `${event_url}/point_zones/${zoneId}`,
  sensor: {
    all: `${perception_url}/sensor`,
    add: (hostname: string): string => `${perception_url}/sensor/${hostname}`,
    remove: (serialNumber: string): string =>
      `${perception_url}/sensor/${serialNumber}`,
    getByStatus: (status: 'active' | 'inactive'): string =>
      `${perception_url}/sensor/${status}`,
  },
  node: {
    all: `${perception_url}/node`,
    add: `${perception_url}/node`,
    remove: (id: string): string => `${perception_url}/node/${id}`,
  },
  alerts: {
    all: `${perception_url}/alerts`,
    active: `${perception_url}/active`,
    logged: `${perception_url}/logged`,
    clearLogged: `${perception_url}/alerts/clear_logged`,
  },
  execution: {
    play: `${perception_url}/execution/play`,
    pause: `${perception_url}/execution/pause`,
    step: `${perception_url}/execution/step`,
    reset: `${perception_url}/execution/reset`,
  },
  recording: {
    play: `${perception_url}/recording/play`,
    upload: (status: string): string =>
      `${perception_url}/recording/upload/${status}`,
    start: `${perception_url}/recording/start`,
    stop: `${perception_url}/recording/stop`,
    delete: (fileName: string): string =>
      `${perception_url}/recording/delete/${fileName}`,
    list: `${perception_url}/recording/list`,
  },
  diagnostics: `${perception_url}/diagnostics`,
  telemetry: `${perception_url}/telemetry`,
  configuration: `${perception_url}/configuration`,
  about: `${perception_url}/about`,
};

export const EVENT_REST = {
  url: `${event_url}/`,
  eventZones: `${event_url}/event_zones/`,
  eventZone: (zoneId: number): string => `${event_url}/event_zones/${zoneId}`,
  about: `${event_url}/about`,
};

export const LIDAR_HUB_REST = {
  url: `${lidar_hub_url}`,
  about: `${lidar_hub_url}/about`,
  telemetry: `${lidar_hub_url}/telemetry`,
  diagnostics: `${lidar_hub_url}/diagnostics`,
  settings: {
    settings: `${lidar_hub_url}/settings`,
    getDefault: `${lidar_hub_url}/default_settings`,
  },
  alerts: `${lidar_hub_url}/event_zones/alerts`,
  reset: `${lidar_hub_url}/reset`,
};

export const PROXY_REST = {
  url: `${proxy_rest_url}/`,
  getProxyRoute: `${proxy_rest_url}/proxy/route`,
};

export const DISCOVERY_REST = {
  about: `${discovery_url}/about`,
};

export const AGENT_REST = {
  licenseStatus: `${agent_url}/license_status`,
  activateLicense: `${agent_url}/activate_license`,
};

// Tech debt: reuse from /common if we bring it to the GUI since
// sensor-proxy also has this.
export const HTTP_STATUS = {
  SUCCESS: 200,
  REDIRECT: 301,
  REQUEST_ERROR: 400, // our backend usually sends 400s
  ERROR: 404,
  UNAUTHORIZED: 401,
  SERVER_ERROR: 500,
} as const;

// TODO(emmanuel): we are not using all these patterns in the code
// Apps Ids patterns. Prepend server's Ids with a string based on the type
// Avoids collisions with polymorphism of structures and possible duplicate ids from different sources
export const getAppId = (serverId: string, serverType: ServerType): string => {
  const map: Record<ServerType, string> = {
    Cluster: 'CLTR',
    ProcessedCloud: 'PCLD',
    Sensor: 'SNSR',
    TrackedObject: 'TOBJ',
    Exclusion: 'EXCL',
    Inclusion: 'INCL',
    Event: 'EVNT',
  };
  return `${map[serverType]}-${serverId}`;
};
export const getServerId = (appId: string): string => appId.split('-')[1];

// Ephemeral - snap tracked's position z value to avoid bounce effect
export const snapTrackedsPosZ = (positionZ: number): number =>
  Math.floor(positionZ / 2) * 2;
