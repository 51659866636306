/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppState } from '../../Stores';
import { Context } from '../../types';

export const useCursorStyle = (context: Context): void => {
  const state = useAppState();

  useEffect(() => {
    switch (state.app.tool) {
      case 'Select':
      case 'Measure':
        context.canvas.style.cursor = 'pointer';
        break;
      case 'ZoneAddRemoveVertex':
      case 'ZoneCreate':
        context.canvas.style.cursor = 'crosshair';
        break;
      case 'ZoneEdit':
        context.canvas.style.cursor = 'move';
        break;

      default:
        context.canvas.style.cursor = 'default';
        break;
    }

    return () => {
      context.canvas.style.cursor = 'default';
    };
  }, [state.app.tool]);
};
