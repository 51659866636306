/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { useAppState } from '../../../Stores';
import { WarningBanner } from './WarningBanner';
import { Logo } from '../Logo';
import { Menu } from './Menu';
import { Version } from './Version';
import { HelpButton } from './HelpButton';

export const Header = (): JSX.Element => {
  const state = useAppState();

  return (
    <header
      id="Header"
      className="flex drop-shadow bgGreyH px-7 py-1 items-center"
      style={{ borderBottom: 'solid 1px var(--greyH)', height: 'var(--r16)' }}
    >
      <div className="flex items-center justify-center">
        <Logo width="2rem" />
        <div className="textGreyC text-xxs ml-2">© 2023 Ouster, Inc.</div>

        <Version />
        <p className="textGreyE px-3">|</p>
        <p className="text-sm textGreyB no-select">{state.app.sceneName}</p>
      </div>
      <div className="grow flex items-center ml-6">
        <div
          className="flex"
          style={{ width: 'calc(100% - var(--r4))', height: 'var(--r10)' }}
        >
          <WarningBanner />
        </div>
      </div>
      <div className="flex p-4" style={{ paddingRight: '0px' }}>
        {state.app.inputMode !== 'playback' && <Menu />}
        <HelpButton />
      </div>
    </header>
  );
};
