/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { DISCOVERY_REST, EVENT_REST, LIDAR_HUB_REST, REST } from '../constants';
import { safeFetch } from '../safeFetch';
import { HTTPServersWithAbout, ServiceVersionInfoResponse } from '../types';

export interface AboutResponse {
  version: ServiceVersionInfoResponse;
}
export interface LidarHubAboutResponse {
  name: string;
  id: string;
  software_version: string;
  settings_version: string;
  version: undefined;
}

export const getPerceptionAbout = (): Promise<AboutResponse | null> =>
  safeFetch.json.get<AboutResponse>(REST.about);
export const getEventAbout = (): Promise<AboutResponse | null> =>
  safeFetch.json.get<AboutResponse>(EVENT_REST.about);
export const getDiscoveryAbout = (): Promise<AboutResponse | null> =>
  safeFetch.json.get<AboutResponse>(DISCOVERY_REST.about);
export const getLidarHubAbout = (): Promise<LidarHubAboutResponse | null> =>
  safeFetch.json.get<LidarHubAboutResponse>(LIDAR_HUB_REST.about);

export type FetchAbout = () => Promise<
  AboutResponse | LidarHubAboutResponse | null
>;
export const about: Record<HTTPServersWithAbout, FetchAbout> = {
  perceptionRest: getPerceptionAbout,
  eventRest: getEventAbout,
  discoveryRest: getDiscoveryAbout,
  lidarHubRest: getLidarHubAbout,
};
