/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import NumberInputRow from '../app/components/NumberInput/NumberRow';
import { Row } from '../app/components/pane/Row';
import { useAppDispatch, useAppState } from '../Stores';
import { SelectZoneType } from './SelectZoneType';
import ZoneMetaDataTextArea from './ZoneMetaDataTextArea';

const placeholderParams = {
  heightMin: 0,
  heightMax: 1,
  name: 'Zone-X',
  type: 'Event',
  serverId: 1,
};
export const SelectedZoneProperties = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const id = state.zones.selected ?? '';
  const paramsRedefined = state.zones.zoneRedefined?.[id];
  const paramsStores = state.zones.params[id];
  const params = paramsRedefined ?? paramsStores ?? placeholderParams;
  const { heightMin, heightMax, name, type, serverId } = params;

  const isDirty =
    paramsRedefined !== undefined && state.zones.selected !== null;
  const isNameDirty = isDirty && paramsStores.name !== paramsRedefined.name;
  const isMinDirty =
    isDirty && paramsStores.heightMin !== paramsRedefined.heightMin;
  const isMaxDirty =
    isDirty && paramsStores.heightMax !== paramsRedefined.heightMax;
  const isTypeDirty = isDirty && paramsStores.type !== paramsRedefined.type;

  return (
    <>
      <div className="flex flex-col ">
        <Row blurb="ID:">
          <div className="textGreyD text-base">{serverId}</div>
        </Row>
        <Row blurb="Name">
          <div className="flex flex-row items-center">
            {isNameDirty && <div>!</div>}
            <input
              disabled={state.zones.selected === null}
              type="text"
              value={name}
              size={Math.max(4, name.length)}
              onChange={(e) => {
                const name = e.target.value;
                dispatch({
                  type: 'setZoneRedefined',
                  id,
                  value: { ...params, name },
                });
              }}
            />
          </div>
        </Row>

        <Row blurb="Type">
          <SelectZoneType
            disabled={state.zones.selected === null}
            zoneType={type}
            onChange={(type) => {
              dispatch({
                type: 'setZoneRedefined',
                id,
                value: { ...params, type },
              });
            }}
            isDirty={isTypeDirty}
          />
        </Row>

        <NumberInputRow
          blurb="Height Max"
          isDirty={isMaxDirty}
          disabled={state.zones.selected === null}
          forceUpdate={state.zones.selected}
          value={Number(heightMax.toFixed(2))}
          min={heightMin}
          onChangeNumber={(v) => {
            dispatch({
              type: 'setZoneRedefined',
              id,
              value: { ...params, heightMax: v },
            });
          }}
        />

        <NumberInputRow
          blurb="Height Min"
          isDirty={isMinDirty}
          disabled={state.zones.selected === null}
          forceUpdate={state.zones.selected}
          value={Number(heightMin.toFixed(2))}
          max={heightMax}
          onChangeNumber={(v) => {
            dispatch({
              type: 'setZoneRedefined',
              id,
              value: { ...params, heightMin: v },
            });
          }}
        />

        <ZoneMetaDataTextArea />
      </div>

      {isDirty && (
        <p className="text-sm textWarning">
          Zone has been edited but not saved.
        </p>
      )}
    </>
  );
};
