/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { VizScene } from '@ouster/webviz';
import {
  BufferGeometry,
  GridHelper,
  Group,
  Line,
  Mesh,
  Quaternion,
  Vector3,
} from 'three';
import { TransformControls } from './gizmo/TransformControls';
import RangeRings3JS from './coordinatePlanes/rangeRings/RangeRings3JS';
import { Source3JS } from './source/Source3JS';
import { Pointcloud3JS } from './threejs/Pointcloud3JS';
import { TrackedLabel3JS } from './trackedObjects/TrackedLabel3JS';
import { TrackedObject3JS } from './trackedObjects/TrackedObject3JS';
import { Keys } from './util/misc';
import { Zone3JS } from './zone/Zones3JS';
import { BoxSelection } from './BoxSelection';
import { World3JS } from './threejs/World3JS';
import { SensorPlayback3JS } from './playback/SensorPlayback3JS';
import { Offender3JS } from './playback/Offender3JS';

export const InputModes = ['live', 'pcap', 'playback'] as const;
export type InputMode = typeof InputModes[number];

export const SetupTabs = ['setup', 'zone', 'map'] as const;
export type SetupTab = typeof SetupTabs[number];
export const SetupTabsSet = new Set<Mode>(SetupTabs);

export const ViewerTabs = ['viewer', 'recording', 'preferences'] as const;
export const ViewerTabsPlayback = ['viewer', 'preferences', 'map'] as const;
export type ViewerTab = typeof ViewerTabs[number];
export const ViewerTabsSet = new Set<Mode>(ViewerTabs);

// User's navigable sections, that would appear on top menu or as a tab
export const Sections = [
  ...ViewerTabs,
  ...SetupTabs,
  'diagnostics',
  'settings',
] as const;
export type Section = typeof Sections[number];

// Different modes/phases the app will be in
export const Modes = ['init', 'exit', ...Sections] as const;
export type Mode = typeof Modes[number];

// Instance Type
export const Instances = [
  'ProcessedCloud',
  'TrackedObject',
  'Zone',
  'Source',
] as const;
export type Instance = typeof Instances[number];

// Settings Instance Type
export const SettingsInstance = ['perception', 'lidarHub'] as const;
export type SettingsInstance = typeof SettingsInstance[number];

// Scene global visibility flags, these flags should hide or show the following structures:
export const GlobalVisibilities = [
  'Grid',
  'RangeRings',
  'RangeRingLabels',
  'Axes',
  'Clouds',
  'Image',
  'EventLocation',
  // we are not exposing these anymore
  'TrackedObjects',
  'Zones',
  'UnderlayMap',
] as const;
export type GlobalVisibility = typeof GlobalVisibilities[number];

// Diagnostic mutation flags
export const DiagnosticMutations = [
  'SensorActivate',
  'SensorUnreachable',
] as const;
export type DiagnosticMutations = typeof DiagnosticMutations[number];

// Tools
export const Tools = [
  'Camera',
  'Select',
  'Measure',
  'ZoneCreate',
  'ZoneEdit',
  'ZoneAddRemoveVertex',
  'World',
  'TransformMap',
] as const;
export type Tool = typeof Tools[number];

// The following definitions are in the order of the enums declared in flatbuffers
// do NOT change order as we use them as a map, the enum value as an index for relative literal
// eg. enum ColorizationMode.SIGNAL = 2 we can use 2 as an index to retrieve 'Signal'

// Display modes
export const CloudDisplayModes = [
  'Height',
  'Range',
  'Signal',
  'Reflectivity',
  'CalRef',
  'Nearir',
  'Fixed',
] as const;
export type CloudDisplayMode = typeof CloudDisplayModes[number];

// Perception Object's classes
export const PerceptionClassifications = [
  'Prospect',
  'Unknown',
  'Person',
  'Vehicle',
  'Bicycle',
  'LargeVehicle',
  'Cluster',
] as const;
export type PerceptionClassification = typeof PerceptionClassifications[number];

// Zones types
export const ZoneTypes = ['Inclusion', 'Exclusion', 'Event'] as const;
export type ZoneType = typeof ZoneTypes[number];

// Cloud types [do not change order]
export const PointDescriptorsInUse = [
  'Raw',
  'Background',
  'Foreground',
  'Ground',
] as const;
export type PointDescriptorInUse = typeof PointDescriptorsInUse[number];

export const PointDescriptors = [
  ...PointDescriptorsInUse,
  'ZoneFiltered',
  'NoReturn',
  'FailedReturn',
] as const;
export type PointDescriptor = typeof PointDescriptors[number];

// Server Types, taxonomy of different payload types
//  that may share same schema but come from different servers
//  with different or colliding ids
export const ServerTypes = [
  'ProcessedCloud',
  'Sensor',
  'TrackedObject',
  'Cluster',
  'Inclusion',
  'Exclusion',
  'Event',
] as const;
export type ServerType = typeof ServerTypes[number];

// Group Definitions
export const GroupNames: Instance[] = [
  'ProcessedCloud',
  'TrackedObject',
  'Zone',
  'Source',
];
export type Groups = Record<Instance, Group>;

// Diagnostics
export const alertActivities = [
  'All Activities',
  'active',
  'inactive',
] as const;
export type AlertActivities = typeof alertActivities[number];

// Themes
export const Themes = ['Dark', 'Light'] as const;
export type Theme = typeof Themes[number];

// Service Info
export const WebSockets = [
  'perceptionWs',
  'alignWs',
  'eventWs',
  'nodeWs',
] as const;
export type WebSockets = typeof WebSockets[number];
export const HTTPServersWithAbout = [
  'perceptionRest',
  'eventRest',
  'discoveryRest',
  'lidarHubRest',
] as const;
export type HTTPServersWithAbout = typeof HTTPServersWithAbout[number];
export const HTTPServers = [
  ...HTTPServersWithAbout,
  'sensorProxyRest',
  'agentRest',
] as const;
export type HTTPServers = typeof HTTPServers[number];
export const Services = [...HTTPServers] as const;
export type Services = typeof Services[number];
export type WSDataReceivedSignal = (wsHandle: WebSockets) => void;

// Perception Version
export const perceptionVersionInfoFields: Keys<ServiceVersionInfoResponse> = [
  'commit',
  'count',
  'major',
  'minor',
  'patch',
  'commit_timestamp',
];

export type ServiceVersionInfoResponse = {
  commit: string;
  commit_timestamp: {
    iso: string;
    unix: string;
  };
  count: string;
  major: string;
  minor: string;
  patch: string;
  pre_release_tag?: string;
};
export type ServiceVersionInfo = {
  commit: string;
  count: string;
  tag: string;
  commit_timestamp: {
    iso: string;
    unix: string;
  };
};

// TypeScript array types
export type TypedArray =
  | Int8Array
  | Uint8Array
  | Int16Array
  | Uint16Array
  | Int32Array
  | Uint32Array
  | Float32Array
  | Float64Array;

// Type for sending the GPU the extrinsics for nodes
export type Transformation = {
  translation: Float32Array; // [x, y, z]
  rotation: Float32Array; // [w, x, y, z]
};

// Shapes needed for constructing a transform gizmo
export type GizmoTransformBuffers = Record<
  'x' | 'y' | 'z' | 'xy' | 'xz' | 'yz' | 'rotation' | 'view',
  BufferGeometry
>;

// All refs that need to be accessible
export type Context = {
  canvas: HTMLCanvasElement;
  labelsContainer: HTMLDivElement;
  selectDiv: HTMLDivElement;
  viz: VizScene;
  world: World3JS;
  groups3JS: Groups;
  axes: Group;
  gridCartesian: GridHelper;
  gridPolar: RangeRings3JS;
  selectionBox: BoxSelection;
  assets: {
    sensorGeometry: BufferGeometry;
    nodeGeometry: BufferGeometry;
    carPeripheral: BufferGeometry;
    personPeripheral: BufferGeometry;
    gizmoTransformBuffers: GizmoTransformBuffers;
    cornerTrident: BufferGeometry;
  };
  labelsCSSRules: Record<
    PerceptionClassification | ZoneType,
    CSSStyleRule | undefined
  >;
  // References to all threeJs instances
  instances: {
    Offender: Offender3JS | undefined;
    ProcessedCloud: {
      all: Record<PointDescriptorInUse, Record<string, Pointcloud3JS>>;
    };
    TrackedObject: {
      all: Record<string, TrackedObject3JS>;
    };
    TrackedLabel: {
      all: Record<string, TrackedLabel3JS>;
    };
    Zones: {
      all: Record<string, Zone3JS>;
    };
    Source: {
      all: Record<string, Source3JS>;
      playback: Record<string, SensorPlayback3JS>;
      selectedSource: Source3JS | null;
      referenceSource: Source3JS | null;
    };
  };
  transformControls: TransformControls;
  underlayPlane: Mesh;
};

/**
 * Properties to be included on each perception structure.
 * Defined as an interface for classes to extend
 * @type oType The name of the class
 * @type oId The unique id as defined by perception
 */
export interface OType {
  oType: string;
  oId: string;
}

export interface Activatable {
  isActive: boolean;
}
export interface Highlightable {
  isHighlighted: boolean;
}
export interface Selectable {
  isSelected: boolean;
}
export interface Editable {
  isEdited: boolean;
}
export interface Dirtyable {
  isDirty: boolean;
}
// TODO(emmanuel): add property to reference back actual component
export interface Raycastable {
  raycastMesh: Mesh;
}

export type MinMax = {
  min: number;
  max: number;
};

export type ColorGradient = {
  min: number;
  max: number;
};

export type Size = {
  width: number;
  height: number;
};

export type SourceStateBase = {
  display: {
    colorMode: CloudDisplayMode;
    colorMin: { [id: string]: number };
    colorMax: { [id: string]: number };
    colorRange: Record<CloudDisplayMode, MinMax>;
  };
  allIds: string[];
  hostnames: { [id: string]: string };
  ips: { [id: string]: string };
  visibilities: { [id: string]: boolean };
  addedStates: { [id: string]: boolean };
  pointSize: { [id: string]: number };
  extrinsics: { [id: string]: Pose | null };
  transformed: { [id: string]: boolean };
  reachableStates: { [id: string]: boolean };
};

export type Space = 'world' | 'local';
export const Axes = ['x', 'y', 'z'] as const;
export type Axis = typeof Axes[number];
export const Planes = ['xy', 'xz', 'yz', 'view'] as const;
export type Plane = typeof Planes[number];
export const Transforms = ['translate', 'rotate', 'scale'] as const;
export type Transform = typeof Transforms[number];

export interface VectorMesh {
  shaft: Line;
  point: Mesh;
  group: Group;
}

export type Pose = { pos: Vector3; rot: Quaternion };
export type Extrinsic = Pose & { id: string; dest: string };
export type TransformPayload = {
  source_frame: string;
  destination_frame: 'world';
  p_x: number;
  p_y: number;
  p_z: number;
  q_w: number;
  q_x: number;
  q_y: number;
  q_z: number;
};

export type FeedbackMessage = {
  message: string;
  type: 'info' | 'warning' | 'error';
  durationMs?: 500 | 1000 | 2000 | 10000;
  priority?: number;
};

export type ServerMessageResponse = {
  message: string;
};

export const Palettes = [
  'Material',
  'Fluent',
  'Tailwind',
  'Gruvbox',
  'Ouster',
  'Ouster2',
  'Data',
] as const;
export type Palette = typeof Palettes[number];
