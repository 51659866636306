/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect, useLayoutEffect, useRef } from 'react';
import { useAppDispatch, useAppState } from '../Stores';
import { Context } from '../types';
import { SensorPlayback3JS } from './SensorPlayback3JS';
import { Offender3JS } from './Offender3JS';

export const usePlaybackData = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  // ProcessedClouds
  useEffect(() => {
    const recording = state.playback.playing;
    if (recording === null) return;

    const schemas = recording.clouds;
    dispatch({ type: 'setProcessedClouds', schemas: schemas });
  }, [state.playback.playing, state.app.palette]);

  // Zones
  useEffect(() => {
    const recording = state.playback.playing;
    if (recording === null) return;

    const eventZones = recording.zones.filter((z) => z.params.type === 'Event');
    if (eventZones.length)
      dispatch({
        type: 'setZones',
        value: eventZones,
        isEventZone: true,
      });
    const pointZones = recording.zones.filter((z) => z.params.type !== 'Event');
    if (pointZones.length)
      dispatch({
        type: 'setZones',
        value: pointZones,
        isEventZone: false,
      });
  }, [state.playback.playing]);

  // Occupations
  useEffect(() => {
    const recording = state.playback.playing;
    if (recording === null) return;
    const timeIndex = state.playback.timeIndex;
    const timestamp = recording.timestamps[timeIndex];
    const occupations = recording.frames[timestamp]?.occupations;
    if (occupations === undefined) return;

    dispatch({ type: 'setOccupations', value: occupations });
  }, [state.playback.playing, state.playback.timeIndex]);

  // timer
  const raf = useRef(0);
  useLayoutEffect(() => {
    if (state.app.inputMode !== 'playback') return;
    if (!state.app.isPlaying) return;
    const recordedData = state.playback.playing;
    if (recordedData === null) return;

    const frameRateMillis = 1000 / state.playback.frameRate;
    const { timeIndex } = state.playback;

    let frameDuration = frameRateMillis;
    if (timeIndex + 1 < recordedData.timestamps.length) {
      const currentFrameInstance = new Date(
        recordedData.timestamps[timeIndex],
      ).getTime();
      const nextFrameInstance = new Date(
        recordedData.timestamps[timeIndex + 1],
      ).getTime();
      frameDuration = (nextFrameInstance - currentFrameInstance) / 1000;
    }

    let start = 0;
    const onRaf = (timestamp: number) => {
      if (start === 0) start = timestamp;
      const elapsed = timestamp - start;

      if (elapsed >= frameDuration) {
        start = timestamp;
        dispatch({
          type: 'setPlaybackTimeIndex',
          offset: 'relative',
          value: 1,
        });
      } else raf.current = requestAnimationFrame(onRaf);
    };
    raf.current = requestAnimationFrame(onRaf);
    return () => {
      cancelAnimationFrame(raf.current);
    };
  }, [
    state.app.isPlaying,
    state.app.mode,
    state.playback.frameRate,
    state.playback.timeIndex,
  ]);

  // Sensors [for playback, so can't be done with nodes *yet*]
  useEffect(() => {
    const recording = state.playback.playing;
    if (recording === null) return;
    for (const extrinsic of Object.values(recording.extrinsics)) {
      const { id, pos, rot } = extrinsic;
      const sensor = new SensorPlayback3JS(
        context.assets.sensorGeometry,
        id,
        recording.sensorColors[id],
      );

      context.instances.Source.playback[id] = sensor;
      context.groups3JS.Source.add(sensor);
      sensor.position.copy(pos);
      sensor.quaternion.copy(rot);
    }
  }, [state.playback.playing]);

  // Offender
  useEffect(() => {
    if (state.app.visibilities.EventLocation === false) {
      if (context.instances.Offender !== undefined) {
        context.instances.Offender.removeLabel();
        context.viz.scene.remove(context.instances.Offender);
      }
      return;
    }
    const recording = state.playback.playing;
    if (recording === null) return;
    const offender = recording.offender;
    if (offender === null) return;
    delete context.instances.Offender;
    context.instances.Offender = new Offender3JS(
      offender.id.toString(),
      recording.dataRecorderEvent.latLongAlt.x,
      recording.dataRecorderEvent.latLongAlt.y,
      offender.positionUncertainty.length(),
      recording.dataRecorderEvent.startTimestamp,
    );
    context.instances.Offender.position.copy(offender.position);
    context.instances.Offender.position.z = 0;
    context.viz.scene.add(context.instances.Offender);

    context.viz.controls.target.copy(offender.position);
    context.viz.controls.update();
  }, [state.playback.playing, state.app.visibilities.EventLocation]);

  // Tracked Objects
  useEffect(() => {
    const recording = state.playback.playing;
    if (recording === null) return;
    const timeIndex = state.playback.timeIndex;
    const timestamp = recording.timestamps[timeIndex];
    if (timestamp === undefined) return;

    const tracked = recording.frames[timestamp].tracked ?? [];
    const clusters = recording.frames[timestamp].clusters ?? [];
    const all = [...tracked, ...clusters];
    if (all.length)
      dispatch({
        type: 'setTrackedObjectsAndClusters',
        trackedObjectsAndClusters: all,
      });
  }, [state.playback.playing, state.playback.timeIndex]);
};
