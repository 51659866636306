/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { Color, Mesh } from 'three';
import { Group, BufferGeometry } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { COLOR_HIGHLIGHTED, COLOR_IDEAL, COLOR_SELECTED } from '../constants';
import { Icon3JS } from '../threejs/Icon3JS';
import { Pointcloud3JS } from '../threejs/Pointcloud3JS';
import { Highlightable, OType, Raycastable, Selectable } from '../types';

export class Source3JS
  extends Group
  implements OType, Highlightable, Selectable, Raycastable
{
  public readonly isSource3JS = true;
  public readonly oType: string;
  public pose: Group;
  public cloud: Pointcloud3JS;
  public isReferenceFrame = false;
  public oId: string;
  public raycastMesh: Mesh; // raycast proxy representation
  public label: CSS2DObject;
  public icon: Icon3JS;

  private labelElem: HTMLDivElement;
  private iconPathElem: SVGPathElement;
  private idealColor = COLOR_IDEAL;
  private _isHighlighted = false;
  private _isSelected = false;

  constructor(iconGeo: BufferGeometry, isSensor = true) {
    super();
    this.oType = 'Source3JS';
    this.oId = 'none';
    this.name = 'none';

    // Transform Controls will manipulate this
    this.pose = new Group();
    this.pose.name = 'pose';
    this.add(this.pose);

    this.cloud = new Pointcloud3JS(isSensor);
    this.cloud.updateBoundingVolumes = false;
    this.cloud.frustumCulled = false;
    this.pose.add(this.cloud);

    this.icon = new Icon3JS(iconGeo);
    this.pose.add(this.icon);

    this.raycastMesh = new Mesh(iconGeo);
    this.raycastMesh.userData.actual = this; // get 'this' from proxy
    this.raycastMesh.visible = false;
    this.icon.add(this.raycastMesh);

    this.labelElem = document.createElement('div');
    this.labelElem.className = 'frameOfReference';
    this.labelElem.innerHTML = `
    <div id='offset'>
      <svg xmlns="http://www.w3.org/2000/svg"  width="25" height="25" viewBox="0 0 18 18">
        <path fill="#aaa" class="drop-shadow-ui"
        d="M9,.9625a6,6,0,0,0-6,6c0,3.3135,6,10.875,6,10.875s6-7.5615,6-10.875A6,6,0,0,0,9,.9625ZM9,9.325A2.325,2.325,0,1,1,11.325,7,2.325,2.325,0,0,1,9,9.325Z" />
      </svg>
    </div>
    `;
    this.iconPathElem = this.labelElem.querySelector(
      '#offset svg path',
    ) as SVGPathElement;

    this.label = new CSS2DObject(this.labelElem);
    this.icon.add(this.label);
    this.label.visible = false;
  }

  public set = (id: string): void => {
    this.name = id;
    this.oId = id;
  };
  public setColor = (iconColor: string): void => {
    const c = new Color().setStyle(iconColor).offsetHSL(0, 0.75, 0.3);
    this.idealColor = this.iconPathElem.style.fill = c.getStyle();
    this.icon.setColor(c.getStyle());
  };

  private calcMaterial = (): void => {
    const clr = this._isHighlighted
      ? COLOR_HIGHLIGHTED
      : this._isSelected
      ? COLOR_SELECTED
      : this.idealColor;
    this.icon.setColor(clr);
  };

  public set isSelected(value: boolean) {
    if (this._isSelected === value) return;
    this._isSelected = value;
    this.calcMaterial();
  }
  public get isSelected(): boolean {
    return this._isSelected;
  }

  public set isHighlighted(value: boolean) {
    if (this._isHighlighted === value) return;
    this._isHighlighted = value;
    this.calcMaterial();
  }
  public get isHighlighted(): boolean {
    return this._isHighlighted;
  }

  setReferenceFrame(value: boolean): void {
    if (this.isReferenceFrame === value) return;
    this.isReferenceFrame = value;
    this.label.visible = value;
  }
}
