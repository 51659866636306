/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { ComponentPropsWithoutRef, useEffect } from 'react';
import { Pane } from '../app/components/pane/Pane';
import { PropertiesGroup } from '../app/components/pane/PropertiesGroup';
import './Diagnostics.css';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { diagnostics, DiagnosticsResponse } from '../api/diagnostics';
import {
  AGENT_SWAGGER_URL,
  DIAGNOSTICS_POLL_INTERVAL,
  DISCOVERY_SWAGGER_URL,
  EVENT_SWAGGER_URL,
  LIDAR_HUB_SWAGGER_URL,
  PERCEPTION_SWAGGER_URL,
  SECONDS_TO_MS,
} from '../constants';
import useInterval from '../app/hooks/useInterval';
import { useAppDispatch, useAppState } from '../Stores';
import { unixToString } from '../util/misc';
import { Services } from '../types';
import ServiceReachability from './serviceReachability/ServiceReachability';

interface ServiceInfo {
  displayName: string;
  serviceName: Services;
  url: string | null;
}
const serviceConstants: ServiceInfo[] = [
  {
    displayName: 'Perception',
    serviceName: 'perceptionRest',
    url: PERCEPTION_SWAGGER_URL,
  },
  {
    displayName: 'Discovery',
    serviceName: 'discoveryRest',
    url: DISCOVERY_SWAGGER_URL,
  },
  {
    displayName: 'Event',
    serviceName: 'eventRest',
    url: EVENT_SWAGGER_URL,
  },
  {
    displayName: 'Lidar Hub',
    serviceName: 'lidarHubRest',
    url: LIDAR_HUB_SWAGGER_URL,
  },
  {
    displayName: 'Agent',
    serviceName: 'agentRest',
    url: AGENT_SWAGGER_URL,
  },
  {
    displayName: 'Sensor Proxy',
    serviceName: 'sensorProxyRest',
    url: null,
  },
];
const DataRow = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}): JSX.Element => (
  <div className="flex items-center" style={{ marginTop: 'var(--r1_5)' }}>
    <p className="grow text-sm textGreyD">{title}</p>
    <h5 className="text-sm textGreyC">{value}</h5>
  </div>
);

const DiagnosticsSidebar =
  ({}: ComponentPropsWithoutRef<'div'>): JSX.Element => {
    const state = useAppState();
    const { service } = state;
    const dispatch = useAppDispatch();

    const fetchCPUDiagnostics = async () => {
      const data: DiagnosticsResponse | null = await diagnostics.get();

      if (data !== null) {
        dispatch({ type: 'setHardwareDiagnostics', value: data });
      } else {
        console.warn("Couldn't fetch hardware diagnostics.");
      }
    };

    const [, setActive] = useInterval(
      fetchCPUDiagnostics,
      DIAGNOSTICS_POLL_INTERVAL,
      true,
    );

    useEffect(() => {
      if (state.app.inputMode === 'playback') return;
      if (state.app.mode !== 'diagnostics') return;

      fetchCPUDiagnostics();
      setActive(true);

      return () => setActive(false);
    }, [state.app.inputMode, state.app.mode]);

    // Define the info items to display in the sidebar.
    const diagState: DiagnosticsResponse =
      state.diagnostics.hardwareDiagnostics;
    const cpuInfo = [
      {
        title: 'CPU temperature',
        value:
          diagState.compute.cpu_temperature_deg_c === null
            ? 'no data'
            : diagState.compute.cpu_temperature_deg_c + '°C',
      },
      {
        title: 'Total memory usage',
        value:
          diagState.compute.total_memory === null ||
          diagState.compute.available_memory === null
            ? 'no data'
            : Math.floor(
                (diagState.compute.total_memory -
                  diagState.compute.available_memory) /
                  10 ** 8,
              ) /
                10 +
              'GB/' +
              Math.floor(diagState.compute.total_memory / 10 ** 8) / 10 +
              'GB',
      },
      {
        title: 'Total CPU usage',
        value:
          diagState.compute.cpu_utilization === null
            ? 'no data'
            : diagState.compute.cpu_utilization + '%',
      },
    ];
    const clientInfo = [
      {
        title: 'Perception TCP clients',
        value:
          diagState.perception_server.num_tcp_clients === null
            ? 'no data'
            : diagState.perception_server.num_tcp_clients,
      },
      {
        title: 'Event zone TCP clients',
        value:
          diagState.event_zone_server.num_tcp_clients === null
            ? 'no data'
            : diagState.event_zone_server.num_tcp_clients,
      },
      {
        title: 'Connected mqtt publishers',
        value:
          diagState.lidar_hub.mqtt_publishers_connected === null
            ? 'no data'
            : diagState.lidar_hub.mqtt_publishers_connected,
      },
    ];
    const timestampInfo = [
      {
        title: 'Main',
        value:
          diagState.timestamp === null
            ? 'no data'
            : unixToString(diagState.timestamp / SECONDS_TO_MS, {
                year: false,
              }),
      },
      {
        title: 'Last restart',
        value:
          diagState.compute.boot_timestamp === null
            ? 'no data'
            : unixToString(diagState.compute.boot_timestamp / SECONDS_TO_MS, {
                year: false,
              }),
      },
      {
        title: 'Perception',
        value:
          diagState.perception_server.timestamp === null
            ? 'no data'
            : unixToString(
                diagState.perception_server.timestamp / SECONDS_TO_MS,
                { year: false },
              ),
      },
      {
        title: 'Event zone',
        value:
          diagState.event_zone_server.timestamp === null
            ? 'no data'
            : unixToString(
                diagState.event_zone_server.timestamp / SECONDS_TO_MS,
                { year: false },
              ),
      },
    ];
    const otherInfo = [
      {
        title: 'Perception is paused',
        value:
          diagState.perception_server.is_paused === null
            ? 'no data'
            : diagState.perception_server.is_paused.toString(),
      },
      {
        title: 'Perception total objects',
        value:
          diagState.perception_server.num_objects === null
            ? 'no data'
            : diagState.perception_server.num_objects,
      },
      {
        title: 'Event zone total occupations',
        value:
          diagState.event_zone_server.num_occupations === null
            ? 'no data'
            : diagState.event_zone_server.num_occupations,
      },
      {
        title: 'Latitude',
        value: diagState.latitude === null ? 'no data' : diagState.latitude,
      },
      {
        title: 'Longitude',
        value: diagState.longitude === null ? 'no data' : diagState.longitude,
      },
      {
        title: 'Hardware ID',
        value: diagState.hardware_id == '' ? 'no data' : diagState.hardware_id,
      },
    ];

    return (
      <Pane id="diagnostics-pane">
        <PropertiesGroup name="Telemetry" expandedInitValue={true}>
          <div className="flex items-center" style={{ marginTop: 'var(--r2)' }}>
            <p className="grow text-sm textGreyD">
              This data is pulled from the Lidar Hub.
            </p>
          </div>
          <PropertiesSubGroup name="CPU Info" expandedInitValue={true}>
            {cpuInfo.map((info) => (
              <DataRow {...info} key={info.title} />
            ))}
          </PropertiesSubGroup>
          <PropertiesSubGroup
            name="Server Client Info"
            expandedInitValue={false}
          >
            {clientInfo.map((info) => (
              <DataRow {...info} key={info.title} />
            ))}
          </PropertiesSubGroup>
          <PropertiesSubGroup name="Timestamps" expandedInitValue={false}>
            {timestampInfo.map((info) => (
              <DataRow {...info} key={info.title} />
            ))}
          </PropertiesSubGroup>
          <PropertiesSubGroup name="Other Info" expandedInitValue={false}>
            {otherInfo.map((info) => (
              <DataRow {...info} key={info.title} />
            ))}
          </PropertiesSubGroup>
        </PropertiesGroup>
        <PropertiesGroup name="Service Reachability" expandedInitValue={true}>
          {serviceConstants.map((type) => (
            <PropertiesSubGroup
              key={type.displayName}
              name={type.displayName}
              expandedInitValue={false}
              isReachableStatus={service[type.serviceName].isReachable}
            >
              <ServiceReachability
                url={type.url}
                version={service[type.serviceName].version}
              />
            </PropertiesSubGroup>
          ))}
        </PropertiesGroup>
      </Pane>
    );
  };

export default DiagnosticsSidebar;
