/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppState } from '../../../Stores';
import { Alert, ZoneAlert } from '../../../api/alerts';

type Warning = {
  type: string;
  id: string;
  // Differentiator is used to determine if the alert has changed
  differentiator: number;
  severity: string;
  message: string;
  open: boolean;
};

const createWarning = (alert: Alert | ZoneAlert): Warning => {
  if ('alertCode' in alert) {
    return {
      type: 'source',
      id: alert.id,
      differentiator: alert.firstOccured,
      severity: alert.level,
      message:
        alert.alertCode === '0x02000001'
          ? "View isn't live; lost connection with sensor " +
            alert.sourceInfo +
            '.'
          : alert.sourceInfo + ' - ' + alert.message,
      open: true,
    };
  } else {
    return {
      type: 'zone',
      id: alert.id,
      differentiator: alert.occupancy,
      severity: alert.severity,
      message: alert.name + ' - ' + alert.message,
      open: true,
    };
  }
};

/* eslint-disable sonarjs/cognitive-complexity */
export const WarningBanner = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  const [warnings, setWarnings] = useState<Warning[]>([]);

  const sourceAlerts = state.diagnostics.allAlerts;
  const zoneAlerts = state.diagnostics.allZoneAlerts;

  // Check for new critical source alerts
  useEffect(() => {
    const newAlerts: Alert[] = Object.values(sourceAlerts).filter(
      (alert: Alert) => alert.active && alert.level === 'CRITICAL',
    );

    if (newAlerts.length === 0) {
      setWarnings((warnings) =>
        warnings.filter((warning) => warning.type !== 'source'),
      );
    } else {
      // Check whether we should update any existing source alerts
      for (const alert of newAlerts) {
        const index = warnings.findIndex(
          (warning) => warning.type === 'source' && warning.id === alert.id,
        );
        if (index === -1) {
          // This alert hasn't been seen before; add to front of list because
          // source alerts are more important than zone alerts
          setWarnings((warnings) => [createWarning(alert), ...warnings]);
        } else if (
          !warnings[index].open &&
          warnings[index].differentiator !== alert.firstOccured
        ) {
          // This alert was seen before, but it is newer; reopen it
          setWarnings((warnings) => {
            const newWarnings = [...warnings];
            newWarnings[index].open = true;
            return newWarnings;
          });
        }
      }
    }
  }, [sourceAlerts]);

  // Check for new zone alerts severe enough to display
  useEffect(() => {
    const newAlerts: ZoneAlert[] = zoneAlerts.filter(
      (alert: ZoneAlert) => alert.severity !== 'NOTICE',
    );

    if (newAlerts.length === 0) {
      setWarnings((warnings) =>
        warnings.filter((warning) => warning.type !== 'zone'),
      );
    } else {
      // Remove zone alerts that don't need to exist anymore
      setWarnings((warnings) =>
        warnings.filter(
          (warning) =>
            warning.type !== 'zone' ||
            newAlerts.findIndex((alert) => alert.id === warning.id) !== -1,
        ),
      );
      // Check whether we should update any existing zone alerts
      for (const alert of newAlerts) {
        const index = warnings.findIndex(
          (warning) => warning.type === 'zone' && warning.id === alert.id,
        );
        if (index === -1) {
          // This alert hasn't been seen before; add to back of list
          setWarnings((warnings) => [...warnings, createWarning(alert)]);
        } else if (!warnings[index].open) {
          // This alert was seen before, but it is newer; reopen it
          setWarnings((warnings) => {
            const newWarnings = [...warnings];
            newWarnings[index].open = true;
            return newWarnings;
          });
        }
      }
    }
  }, [zoneAlerts]);

  useEffect(() => {
    // Ensure the banner is ready to open if no alerts are loaded yet.
    if (Object.values(sourceAlerts).length === 0 && zoneAlerts.length === 0) {
      setWarnings([]);
    }
  }, []);

  const warningIndex = warnings.findIndex((warning) => warning.open);

  return warningIndex === -1 ? (
    <></>
  ) : (
    <div
      className="warningBanner"
      onClick={() => {
        if (
          warnings[warningIndex].type === 'source' &&
          state.app.mode !== 'diagnostics'
        ) {
          dispatch({ type: 'setMode', value: 'diagnostics' });
        } else if (
          warnings[warningIndex].type === 'zone' &&
          state.app.mode !== 'viewer'
        ) {
          dispatch({ type: 'setMode', value: 'viewer' });
        }
      }}
    >
      <div
        style={{
          width: 'calc(100% - var(--r8))',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <span style={{ fontWeight: '600' }}>
          {warnings[warningIndex].severity
            .toLowerCase()
            .replace(/^\w/, (firstChar) => firstChar.toUpperCase())}
          :{' '}
        </span>
        {warnings[warningIndex].message}
      </div>
      <button
        type="button"
        className="close"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setWarnings((warnings) => {
            const newWarnings = [...warnings];
            newWarnings[warningIndex].open = false;
            return newWarnings;
          });
        }}
        style={{
          position: 'absolute',
          right: 'var(--r2)',
          top: 'var(--r2)',
        }}
      >
        x
      </button>
    </div>
  );
};
