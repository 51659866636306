/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { useEffect } from 'react';
import { useAppDispatch, useAppState } from '../../Stores';
import { Context } from '../../types';

const useOrbitControlsSync = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    context.viz.controls.autoRotate = state.app.autoRotate;
    if (state.app.autoRotate) {
      dispatch({
        type: 'setTool',
        value: 'Camera',
      });
    }
  }, [state.app.autoRotate]);

  useEffect(() => {
    context.viz.controls.autoRotateSpeed = state.app.autoRotateSpeed;
  }, [state.app.autoRotateSpeed]);

  useEffect(() => {
    if (state.app.tool !== 'Camera') {
      context.viz.controls.autoRotate = false;
      dispatch({
        type: 'setSceneAutoRotate',
        value: false,
      });
    }
  }, [state.app.tool]);
};

export default useOrbitControlsSync;
