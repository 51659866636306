/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import Input from '../app/components/Input/Input';

const RecordLengthInput = ({
  onChange,
  value,
  name,
  disabled,
}: {
  value: number;
  name: string;
  onChange: (lengthInSeconds: number) => void;
  disabled: boolean;
}): JSX.Element => {
  return (
    <Input
      name={name}
      onChange={(e) => onChange(+e.target.value)}
      onKeyDown={(e) => {
        // Only allow numbers, arrows, backspace, and delete
        const code: number = e.key.charCodeAt(0);
        if (
          !(code > 47 && code < 58 && value < 10000000000) &&
          !e.key.includes('Arrow') &&
          !e.key.includes('Backspace') &&
          !e.key.includes('Delete')
        ) {
          e.preventDefault();
        }
      }}
      value={value}
      disabled={disabled}
    />
  );
};

export default RecordLengthInput;
