/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { endpoints } from '../../api/endpoints';
import { HTTPServers, HTTPServersWithAbout } from '../../types';
const { about, proxy, agent } = endpoints;

const checkReachability = async (instance: HTTPServersWithAbout) => {
  const res = await about[instance]();
  return res !== null;
};

const checkSensorProxyRestReachability = async () => {
  const res = await proxy.testRestReach();
  return (await res?.text()) !== undefined;
};

const checkAgentRestReachability = async () => {
  const res = await agent.testRestReach();
  return (await res?.text()) !== undefined;
};

export const reachabilityRequest: Record<HTTPServers, () => Promise<boolean>> =
  {
    perceptionRest: async () => await checkReachability('perceptionRest'),
    eventRest: async () => await checkReachability('eventRest'),
    discoveryRest: async () => await checkReachability('discoveryRest'),
    lidarHubRest: async () => await checkReachability('lidarHubRest'),
    sensorProxyRest: async () => await checkSensorProxyRestReachability(),
    agentRest: async () => await checkAgentRestReachability(),
  };
