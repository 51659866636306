/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { Button } from './Button';
import { ResetIcon } from './ResetIcon';
import { TooltipInterface } from './tooltip/Tooltip';

export const ResetButton = ({
  children,
  tooltip = {
    text: 'Reset',
    offset: 'left',
  },
  onClick,
  isVisible = true,
}: {
  children: React.ReactNode;
  isVisible?: boolean;
  tooltip?: TooltipInterface;
  onClick: () => void;
}): JSX.Element => (
  <div className="flex flex-row">
    {isVisible && (
      <Button
        className="bgNone p-0 mr-2"
        disabled={false}
        onClick={onClick}
        tooltip={tooltip}
      >
        <ResetIcon />
      </Button>
    )}
    {children}
  </div>
);
