/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';
import { useAppDispatch, useAppState } from '../../../Stores';
import { Button } from '../Button';

export const IconSpin = ({
  active,
  ...rest
}: {
  active: boolean;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    // We add the transform property to make the icon CW,
    // to match the rotation direction.
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      className={active ? 'active' : ''}
      {...rest}
      transform="scale (-1, 1)"
    >
      <path d="M12,7.5V9.027a26.86888,26.86888,0,0,1-4.824-.0195C5.6075,8.782,3.7575,8.0235,3.7,7.0235,3.6285,6,4.735,4.959,6.2585,4.447A8.07447,8.07447,0,0,1,8,4.0665V8h2V1.5A.5.5,0,0,0,9.5,1h-1a.5.5,0,0,0-.5.5V3.011a9.32,9.32,0,0,0-2.0835.336c-1.845.541-3.624,1.816-3.6105,3.747.0375,2.025,2.5935,3.1455,4.5825,3.566A27.53,27.53,0,0,0,12,10.9725V12.5a.25.25,0,0,0,.4.2L16,10,12.4,7.3a.25.25,0,0,0-.4.2Z" />
      <circle cx="11.45" cy="3.45" r="0.55" />
      <circle cx="13.484" cy="3.6855" r="0.55" />
      <circle cx="15.45" cy="4.45" r="0.55" />
      <path d="M8,16.5a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V12H8Z" />
    </svg>
  );
};

const AutoRotate = (): JSX.Element => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  return (
    <Button
      className="bgNone"
      onClick={() => {
        dispatch({
          type: 'setSceneAutoRotate',
          value: !state.app.autoRotate,
        });
      }}
    >
      <IconSpin active={state.app.autoRotate} />
    </Button>
  );
};

export default AutoRotate;
