/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { LogoIconTextVert } from '../LogoIconTextVert';
import './Splash.css';

export const Splash = (): JSX.Element => {
  return (
    <section
      id="splash"
      className="flex"
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        padding: '0.25rem 1rem',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--greyG)',
      }}
    >
      <div id="container" className="drop-shadow-lg">
        <LogoIconTextVert width="7.5rem" />
        <div className="flex flex-col items-center justify-between">
          <div id="app-name" className="drop-shadow-sm">
            Detect
          </div>
          <div id="app-ouster-inc">© 2023 Ouster, Inc.</div>
        </div>
        <div id="loader-container">
          <div id="loader" />
        </div>
      </div>
    </section>
  );
};
