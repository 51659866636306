/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React, { SVGProps } from 'react';

export const UpDownIcon = ({
  variant,
  active,
  ...rest
}: {
  variant: 'up' | 'down';
  active: boolean;
} & SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="18px"
      height="8px"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 18 8"
      viewBox="0 0 18 8"
      className={`UpDownIcon ${active ? 'active' : ''}`}
      {...rest}
    >
      <path d="M0,0h18v8H0V0z" fill="none" />
      {
        {
          up: (
            <path d="m 13.962954,6.0032046 a 1,1 0 0 1 -1.7055,0.7055 L 8.9684537,3.422705 5.6794543,6.7087046 a 1,1 0 0 1 -1.437,-1.3865 l 0.0245,-0.0245 3.9959994,-3.9919996 a 1,1 0 0 1 1.4125,0 l 3.9945003,3.9914996 a 0.9945,0.9945 0 0 1 0.293,0.706 z" />
          ),
          down: (
            <path d="m 4.0000006,2.0104625 a 1,1 0 0 1 1.7055,-0.7055 l 3.289,3.286 3.2889994,-3.286 a 1,1 0 0 1 1.437,1.3865 l -0.0245,0.0245 -3.9959994,3.992 a 1,1 0 0 1 -1.4125,0 l -3.9945,-3.9915 a 0.9945,0.9945 0 0 1 -0.293,-0.706 z" />
          ),
        }[variant]
      }
    </svg>
  );
};
