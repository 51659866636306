/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { round } from 'lodash';
import { useEffect } from 'react';
import { Vector2, Vector4 } from 'three';
import { putExtrinsics } from '../../api/align';
import { endpoints } from '../../api/endpoints';
import { payloadZone } from '../../api/zone';
import { useAppState, useAppDispatch } from '../../Stores';
import { Context, Extrinsic } from '../../types';
import { getPose } from '../../util/misc';
import { ZoneSchema } from '../../zone/eventZone/EventZoneServerInterface';
import { Vertex3JS } from '../../zone/Vertex3JS';
import { ZoneParams } from '../../zone/ZoneStore';

const {
  zone: {
    event: { setZones: setEventZones },
    point: { setZones: setPointZones },
  },
} = endpoints;

// Logic to transform nodes and zones to a new location
export const useWorld = (context: Context): void => {
  const state = useAppState();
  const dispatch = useAppDispatch();

  // Add / remove
  useEffect(() => {
    const isSetup = state.app.mode === 'setup';
    if (!isSetup) return;

    const w = context.world;
    w.icon.visible = true;

    return () => {
      w.icon.visible = false;
    };
  }, [state.app.mode]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const isSetup = state.app.mode === 'setup';
    const isWorld = state.app.tool === 'World';
    const isActive = isSetup && isWorld;
    if (!isActive) return;

    // Check for any dirty nodes/zones and exit if any are found
    const numDirtyZones = Object.values(state.zones.zoneRedefined).length;
    const countTrues = (acc: number, cur: boolean) => (cur ? acc + 1 : acc);
    const numDirtySources =
      Object.values(state.sensors.transformed).reduce(countTrues, 0) +
      Object.values(state.nodes.transformed).reduce(countTrues, 0);
    if (numDirtyZones > 0 || numDirtySources > 0) {
      dispatch({
        type: 'setFeedbackMessage',
        value: {
          type: 'warning',
          message: `${numDirtyZones} Zones and ${numDirtySources} Sources has been transformed, click Reset button to revert transformation or Save to store before editing world`,
        },
      });
      return;
    }

    // Inform functionality
    dispatch({
      type: 'setFeedbackMessage',
      value: {
        type: 'info',
        message:
          'Click and drag the widgets to transform, arrows moves along axis, L shaped corners along plane, disk rotates',
      },
    });

    context.world.isSelected = true;
    context.transformControls.attach(context.world);
    context.viz.scene.add(context.transformControls);
    context.transformControls.active = true;
    context.transformControls.controls.translate.z.visible =
      context.transformControls.controls.translate.yz.visible =
      context.transformControls.controls.translate.xz.visible =
        true;

    return () => {
      context.world.isSelected = false;
      context.transformControls.detach();
      context.transformControls.removeFromParent();
      context.transformControls.active = false;

      const w = context.world;
      w.updateWorldMatrix(false, true);
      const m = w.matrixWorld;
      const v4 = new Vector4(0, 0, 0, 1);
      const setZoneActionArgs: {
        type: 'setZone';
        id: string;
        value: ZoneParams;
      }[] = [];
      const eventSchemas: ZoneSchema[] = [];
      const pointSchemas: ZoneSchema[] = [];
      const eventSchemasBackUp: ZoneSchema[] = [];
      const pointSchemasBackUp: ZoneSchema[] = [];
      for (const id of state.zones.allIds) {
        const param = state.zones.params[id];

        // Transform zones: accommodate for the worldOffset transform propagated to the vertices
        const vertices = param.vertices.map((v2) => {
          v4.set(v2.x, v2.y, 0, 1).applyMatrix4(m);
          return new Vector2(
            round(v4.x, Vertex3JS.PRECISION),
            round(v4.y, Vertex3JS.PRECISION),
          );
        });

        // Store action arguments to reflect changes locally
        const zoneParam: ZoneParams = { ...param, vertices };
        setZoneActionArgs.push({ type: 'setZone', id, value: zoneParam });

        // Store zone schemas to send to backend
        let payload = payloadZone(
          zoneParam.serverId,
          zoneParam.type,
          zoneParam.name,
          zoneParam.heightMin,
          zoneParam.heightMax,
          zoneParam.vertices,
          zoneParam.metadata,
        );
        let a = zoneParam.type === 'Event' ? eventSchemas : pointSchemas;
        a.push(payload);

        // Keep a backup of the original zone schemas in case of failure
        payload = payloadZone(
          param.serverId,
          param.type,
          param.name,
          param.heightMin,
          param.heightMax,
          param.vertices,
          param.metadata,
        );
        a = param.type === 'Event' ? eventSchemasBackUp : pointSchemasBackUp;
        a.push(payload);
      }

      // Transform Sources
      const extrinsics: Extrinsic[] = [];
      for (const id of [...state.sensors.allIds, ...state.nodes.allIds]) {
        const sensor = context.instances.Source.all[id];
        if (!sensor) continue;
        const { pos, rot } = getPose(sensor.cloud.matrixWorld);
        extrinsics.push({ id, pos, rot, dest: 'world' });
      }

      // Backup the original extrinsics in case of failure
      const extrinsicsBackUp: Extrinsic[] = [];
      for (const id of state.sensors.allIds) {
        const pose = state.sensors.extrinsics[id];
        if (pose === null) continue;
        extrinsicsBackUp.push({
          id,
          pos: pose.pos,
          rot: pose.rot,
          dest: 'world',
        });
      }
      for (const id of state.nodes.allIds) {
        const pose = state.nodes.extrinsics[id];
        if (pose === null) continue;
        extrinsicsBackUp.push({
          id,
          pos: pose.pos,
          rot: pose.rot,
          dest: 'world',
        });
      }

      // Post
      Promise.allSettled([
        (async () => {
          const response = await setEventZones({ zones: eventSchemas });
          if (response?.ok) return response;
          else throw new Error('Event Zones failed');
        })(),
        (async () => {
          const response = await setPointZones({ zones: pointSchemas });
          if (response?.ok) return response;
          else throw new Error('Point Zones failed');
        })(),
        (async () => {
          const response = await putExtrinsics(extrinsics);
          if (response?.ok) return response;
          else throw new Error('World Transforms failed');
        })(),
      ])
        .then((results) => {
          // Check if all promises fulfilled
          const allFulfilled = results.every(
            (result) => result.status === 'fulfilled',
          );

          if (allFulfilled) {
            // local update
            setZoneActionArgs.forEach((e) => {
              dispatch(e);
            });

            dispatch({
              type: 'setFeedbackMessage',
              value: {
                type: 'info',
                message: 'Successfully saved world transform',
              },
            });
          } else {
            // Revert changes for individual promises
            const [eventResult, pointResult, extrinsicsResult] = results;
            let errorMessage = 'Failed to save:';

            if (extrinsicsResult.status === 'rejected') {
              errorMessage += ' World Transforms.';
              putExtrinsics(extrinsicsBackUp);
              setEventZones({ zones: eventSchemasBackUp });
              setPointZones({ zones: pointSchemasBackUp });
            } else {
              // The reverting is broken up because the extrinsics should
              // not be reverted if the zones fail to save
              if (eventResult.status === 'rejected') {
                errorMessage += ' Event Zones and Point Zones.';
                setEventZones({ zones: eventSchemasBackUp });
              }
              if (pointResult.status === 'rejected') {
                errorMessage += ' Point Zones.';
                setPointZones({ zones: pointSchemasBackUp });
              }
            }

            dispatch({
              type: 'setFeedbackMessage',
              value: {
                type: 'error',
                message: errorMessage,
              },
            });
          }
        })
        .finally(() => {
          context.world.position.set(0, 0, 0);
          context.world.quaternion.identity();
        });
    };
  }, [
    state.app.tool,
    state.app.mode,
    state.zones.allIds,
    state.zones.params,
    state.sensors.allIds,
    state.sensors.extrinsics,
    state.nodes.allIds,
    state.nodes.extrinsics,
  ]);
};
