/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import { ChevronIcon } from './ChevronIcon';

const Select = <T extends string | number>({
  active,
  options,
  onChange,
  name = '',
  disabled = false,
  activeTransformer,
}: {
  active?: T;
  options: readonly T[];
  onChange: (filter: T) => void;
  name?: string;
  disabled?: boolean;
  activeTransformer?: (name: T) => string;
}): JSX.Element => {
  const value = { value: active };
  return (
    <section
      className="select"
      style={{
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <select
        className={'new-select'}
        {...value}
        name={name}
        disabled={disabled}
        onChange={(e) => {
          const mode = e.currentTarget.value as T;
          onChange(mode);
        }}
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {activeTransformer ? activeTransformer(option) : option}
          </option>
        ))}
      </select>
      <span className="select-focus"></span>
      <div className="select-arrow">
        <ChevronIcon
          icon="down"
          size="16px"
          style={{
            fill: disabled
              ? 'var(--greyF)'
              : active
              ? 'var(--greyE)'
              : 'var(--greyC)',
          }}
        />
      </div>
    </section>
  );
};

export default Select;
