/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import { isEqual } from 'lodash';
import { Vector2 } from 'three';
import { getAppId } from '../constants';
import { loadFromLocalStorage, saveToLocalStorage } from '../util/localStorage';
import { ZoneType } from '../types';
import { HUES } from '../util/palettes';

export const LOCALSTORAGE_KEY = 'Zone';

export type OccupationSchema = { id: string; trackedObjectIds: string[] };

export type ZoneParams = {
  serverId: number;
  name: string;
  type: ZoneType;
  heightMax: number;
  heightMin: number;
  vertices: Vector2[];
  metadata: string;
};
export type ZoneSchema = {
  id: string;
  params: ZoneParams;
};

// Component visibilities
export type ZoneGlobalVisibilities = 'boundaries' | 'labels';

export type ZoneActions =
  | {
      type: 'setZoneGlobalVisibility';
      property: ZoneGlobalVisibilities;
      value: boolean;
    }
  | { type: 'setSelectedZone'; id: string | null }
  | { type: 'setCopiedZone'; id: string | null }
  | { type: 'setPasteZone'; value: boolean }
  | {
      type: 'setZoneTypeVisibility';
      zoneType: ZoneType;
      value: boolean;
    }
  | {
      type: 'setZoneTypeColor';
      zoneType: ZoneType;
      colorIndex: number;
    }
  | { type: 'setZones'; value: ZoneSchema[]; isEventZone: boolean }
  | { type: 'setOccupations'; value: OccupationSchema[] }
  | {
      type: 'setZoneVisibility';
      id: string;
      value: boolean;
    }
  | {
      type: 'setZoneLabelsVisibility';
      id: string;
      value: boolean;
    }
  // update store's values immediately to reflect user changes
  | { type: 'setZone'; id: string; value: ZoneParams | null }
  | { type: 'setZoneMetadata'; id: string; value: string }
  | {
      type: 'setZoneRedefined';
      id: string;
      value: ZoneParams | null;
    };

export type ZoneState = {
  globalVisibilities: Record<ZoneGlobalVisibilities, boolean>;
  typeVisibilities: Record<ZoneType, boolean>;
  zoneTypeColorIndices: Record<ZoneType, number>;
  selected: string | null;
  copied: string | null;
  paste: boolean;

  // local user changes not invalidating rest
  zoneRedefined: { [id: string]: ZoneParams };

  allIds: string[];
  params: { [id: string]: ZoneParams };
  visibilities: { [id: string]: boolean };
  labelVisibilities: { [id: string]: boolean };
  // TODO(emmanuel): dependent properties, consider updating on post store update
  updated: string[];
  toRelease: string[];
  newEntries: string[];
  // Event Zone have only occupations
  occupations: { [id: string]: OccupationSchema };
};

export type ZoneStored = Pick<
  ZoneState,
  'typeVisibilities' | 'zoneTypeColorIndices' | 'globalVisibilities'
>;

const loaded = (loadFromLocalStorage(LOCALSTORAGE_KEY) ?? {}) as ZoneStored;
export const ZoneInitialState: ZoneState = {
  globalVisibilities: {
    boundaries: true,
    labels: true,
  },
  typeVisibilities: {
    ...{ Event: true, Exclusion: true, Inclusion: true },
    ...loaded.typeVisibilities,
  },
  zoneTypeColorIndices: {
    ...{ Event: HUES.YELLOW, Exclusion: HUES.RED, Inclusion: HUES.TEAL },
    ...loaded.zoneTypeColorIndices,
  },
  selected: null,
  copied: null,
  paste: false,
  zoneRedefined: {},
  allIds: [],
  params: {},
  visibilities: {},
  labelVisibilities: {},
  occupations: {},
  updated: [],
  toRelease: [],
  newEntries: [],
};

export const reducer = (
  state: ZoneState,
  action: ZoneActions,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ZoneState => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case 'setZoneGlobalVisibility': {
      if (state.globalVisibilities[action.property] === action.value)
        return state;
      return {
        ...state,
        globalVisibilities: {
          ...state.globalVisibilities,
          [action.property]: action.value,
        },
      };
    }

    case 'setSelectedZone': {
      if (state.selected === action.id) return state;
      return { ...state, selected: action.id };
    }
    case 'setCopiedZone': {
      if (state.copied === action.id) return state;
      return { ...state, copied: action.id };
    }
    case 'setPasteZone': {
      if (state.paste === action.value) return state;
      return { ...state, paste: action.value };
    }
    case 'setZoneTypeVisibility': {
      if (state.typeVisibilities[action.zoneType] === action.value)
        return state;

      const typeVisibilities = {
        ...state.typeVisibilities,
        [action.zoneType]: action.value,
      };
      return { ...state, typeVisibilities };
    }
    case 'setZoneTypeColor': {
      if (state.zoneTypeColorIndices[action.zoneType] === action.colorIndex)
        return state;

      const zoneTypeColors = {
        ...state.zoneTypeColorIndices,
        [action.zoneType]: action.colorIndex,
      };
      return { ...state, zoneTypeColorIndices: zoneTypeColors };
    }
    case 'setZones': {
      const params2Keep: typeof ZoneInitialState.params = {};
      const noChange = state.allIds.filter((id) =>
        action.isEventZone
          ? state.params[id].type !== 'Event'
          : state.params[id].type === 'Event',
      );
      noChange.forEach((id) => {
        params2Keep[id] = state.params[id];
      });

      const newEntries = [];
      const updated = [];
      // New State to output
      let newState: ZoneState = { ...state };
      for (let i = 0; i < action.value.length; i++) {
        const schema = action.value[i];
        const { id } = schema;
        const isNew = newState.visibilities[id] === undefined;
        if (isNew) {
          newEntries.push(id);
          const params = { ...newState.params, [id]: schema.params };
          const visibilities = { ...newState.visibilities, [id]: true };
          const labelVisibilities = {
            ...newState.labelVisibilities,
            [id]: true,
          };

          newState = {
            ...newState,
            params,
            visibilities,
            labelVisibilities,
          };
        } else {
          if (!isEqual(state.params[id], schema.params)) {
            updated.push(id);
            const params = {
              ...state.params,
              [id]: schema.params,
            };

            newState = {
              ...newState,
              params,
            };
          }
        }
      }

      // Invalidate only what needed
      // if newer is not empty or we have previous new ids
      if (newEntries.length > 0 || state.newEntries.length) {
        newState = { ...newState, newEntries };
      }
      if (updated.length > 0 || state.updated.length) {
        newState = { ...newState, updated };
      }

      const idsSet = new Set(action.value.map((e) => e.id));
      const noChangeSet = new Set(noChange);
      const toRelease = state.allIds
        .filter((id) => !idsSet.has(id))
        .filter((id) => !noChangeSet.has(id));

      if (toRelease.length > 0) {
        newState = { ...newState, toRelease };
      }

      // invalidate allIds only if we have new or gone elements
      if (newEntries.length > 0 || toRelease.length > 0) {
        newState = {
          ...newState,
          params: { ...newState.params, ...params2Keep },
          allIds: [...newState.newEntries, ...newState.updated, ...noChange],
        };
      }
      return newState;
    }

    case 'setZone': {
      let newState: ZoneState = { ...state };
      const { id, value: actionParamsNullable } = action;
      const status: 'new' | 'modified' | 'typeChange' | 'delete' =
        actionParamsNullable === null
          ? 'delete'
          : state.params[id] === undefined
          ? 'new'
          : actionParamsNullable.type === state.params[id].type
          ? 'modified'
          : 'typeChange';
      // eslint-disable-next-line sonarjs/no-nested-switch
      switch (status) {
        case 'delete': {
          const params = { ...newState.params };
          delete params[id];
          const visibilities = { ...newState.visibilities };
          delete visibilities[id];
          const labelVisibilities = { ...newState.labelVisibilities };
          delete labelVisibilities[id];
          const occupations = { ...newState.occupations };
          delete occupations[id];

          newState = {
            ...newState,
            params,
            visibilities,
            labelVisibilities,
            occupations,
            allIds: state.allIds.filter((v) => v !== id),
            newEntries: [],
            updated: [],
            toRelease: [id],
          };
          return newState;
        }
        case 'new': {
          // actionParams would never be null here, bypassing
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const actionParams: ZoneParams = actionParamsNullable!;
          const params = { ...newState.params, [id]: actionParams };
          const visibilities = { ...newState.visibilities, [id]: true };
          const labelVisibilities = {
            ...newState.labelVisibilities,
            [id]: true,
          };

          newState = {
            ...newState,
            params,
            visibilities,
            labelVisibilities,
            allIds: [...state.allIds, id],
            newEntries: [id],
          };

          // Invalidate only what needed
          if (state.toRelease.length > 0) {
            newState = { ...newState, toRelease: [] };
          }
          return newState;
        }
        case 'modified': {
          // actionParams would never be null here, bypassing
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const actionParams: ZoneParams = actionParamsNullable!;
          const params = {
            ...state.params,
            [id]: actionParams,
          };
          const updated = [id];
          newState = {
            ...newState,
            params,
            updated,
          };

          if (state.toRelease.length > 0) {
            newState = { ...newState, toRelease: [] };
          }
          if (state.newEntries.length) {
            newState = { ...newState, newEntries: [] };
          }
          return newState;
        }
        case 'typeChange': {
          // actionParams would never be null here, bypassing
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const actionParams: ZoneParams = actionParamsNullable!;
          const updatedId = getAppId(
            actionParams.serverId.toString(),
            actionParams.type,
          );
          // add as a new entry
          const params = {
            ...newState.params,
            [updatedId]: actionParams,
          };
          const visibilities = { ...newState.visibilities, [updatedId]: true };
          const labelVisibilities = {
            ...newState.labelVisibilities,
            [updatedId]: true,
          };
          // and remove the old references
          delete params[id];
          delete visibilities[id];
          delete labelVisibilities[id];

          newState = {
            ...newState,
            params,
            visibilities,
            labelVisibilities,
            allIds: [...state.allIds.filter((aId) => aId !== id), updatedId],
            newEntries: [updatedId],
            toRelease: [id],
            updated: [],
            selected: updatedId,
          };
          return newState;
        }
      }
    }

    // prop should NOT update the store
    case 'setZoneRedefined': {
      let newState: ZoneState = { ...state };

      if (action.value === null) {
        const zoneRedefined = {
          ...state.zoneRedefined,
        };
        delete zoneRedefined[action.id];
        newState = {
          ...newState,
          zoneRedefined,
        };
        return newState;
      } else {
        const zoneRedefined = {
          ...state.zoneRedefined,
          [action.id]: action.value,
        };
        newState = {
          ...newState,
          zoneRedefined,
        };
        return newState;
      }
    }

    case 'setZoneVisibility': {
      if (state.visibilities[action.id] === action.value) return state;

      const visibilities = {
        ...state.visibilities,
        [action.id]: action.value,
      };
      return {
        ...state,
        visibilities,
      };
    }
    case 'setZoneLabelsVisibility': {
      if (state.labelVisibilities[action.id] === action.value) return state;

      const labelVisibilities = {
        ...state.labelVisibilities,
        [action.id]: action.value,
      };
      return {
        ...state,
        labelVisibilities,
      };
    }
    case 'setOccupations': {
      const occupations = { ...state.occupations };

      for (const occupation of action.value) {
        occupations[occupation.id] = occupation;
      }
      return {
        ...state,
        occupations,
      };
    }
    default:
      return state;
  }
};

const triggerSaveSet: Set<ZoneActions['type']> = new Set([
  'setZoneTypeVisibility',
  'setZoneTypeColor',
  'setZoneVisibility',
  'setZoneLabelsVisibility',
  'setZoneGlobalVisibility',
]);
export const ZoneReducer = (
  state: ZoneState,
  action: ZoneActions,
): ZoneState => {
  const newState = reducer(state, action);

  const stored: ZoneStored = {
    typeVisibilities: newState.typeVisibilities,
    zoneTypeColorIndices: newState.zoneTypeColorIndices,
    globalVisibilities: newState.globalVisibilities,
  };
  if (triggerSaveSet.has(action.type))
    saveToLocalStorage(LOCALSTORAGE_KEY, stored);

  return newState;
};
