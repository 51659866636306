/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/

import React from 'react';
import ColorModeMinMaxInput from '../app/components/pane/cloudsDisplay/ColorModeMinMaxInput';
import { DisplayOptions } from '../app/components/pane/cloudsDisplay/DisplayOptions';
import { ColorPresetPicker } from '../app/components/pane/ColorPresetPicker';
import { EyeToggle } from '../app/components/pane/eye/Visible';
import { PropertiesSubGroup } from '../app/components/pane/PropertiesSubGroup';
import { UpDownButtons } from '../app/components/pane/UpDownButtons';
import Tooltip from '../app/components/tooltip/Tooltip';
import { MAX_POINTS_SIZE, MIN_POINTS_SIZE, Palettes } from '../constants';
import DiagnosticIcon from '../diagnostics/DiagnosticsIcon';
import { useAppDispatch, useAppState } from '../Stores';
import { CloudDisplayModes, PointDescriptorInUse } from '../types';
import { ProcessedCloudExclusion } from './ProcessedCloudExclusion';

export const ProcessedCloudList = ({
  descriptor,
}: {
  descriptor: PointDescriptorInUse;
}): JSX.Element | null => {
  const state = useAppState();
  const dispatch = useAppDispatch();
  const palette = Palettes[state.app.palette];

  const isSignalActive = state.clouds[descriptor].allIds.some(
    (id) => state.clouds[descriptor].params[id].signal.length,
  );
  const modes = isSignalActive
    ? CloudDisplayModes
    : CloudDisplayModes.filter((mode) => mode !== 'Signal');

  return state.clouds[descriptor].allIds.length ? (
    <PropertiesSubGroup name={`${descriptor}:`}>
      {descriptor === 'Background' && <ProcessedCloudExclusion />}
      <DisplayOptions
        modes={modes}
        colorMode={state.clouds[descriptor].display.colorMode}
        onChange={(mode) => {
          dispatch({
            type: 'setProcessedCloudsDisplayMode',
            descriptor,
            value: mode,
          });
        }}
      />
      <ColorModeMinMaxInput type="clouds" descriptor={descriptor} />
      <div className="flex flex-col">
        {state.clouds[descriptor].allIds
          .filter((id) => state.sensors.addedStates[id])
          .map((id) => (
            <div className="flex items-center mt-1" key={id}>
              <div className="flex grow textGreyD">
                {!state.clouds[descriptor].reachabilities[id] && (
                  <Tooltip
                    tooltipText="Lost connection to this sensor"
                    offset="right"
                  >
                    <DiagnosticIcon
                      style={{
                        margin: '0 auto',
                        display: 'block',
                      }}
                      icon={'SensorUnreachable'}
                      active
                    />
                  </Tooltip>
                )}
                <p className="text-md textGreyD">{id}</p>
              </div>
              <UpDownButtons
                isUpEnabled={
                  state.clouds[descriptor].pointSize[id] < MAX_POINTS_SIZE
                }
                isDownEnabled={
                  state.clouds[descriptor].pointSize[id] > MIN_POINTS_SIZE
                }
                onClick={(key) => {
                  dispatch({
                    type: 'setProcessedCloudPointSize',
                    id,
                    value: key === 'up' ? 0.25 : -0.25,
                    descriptor,
                  });
                }}
                tooltip={{
                  text: 'Adjust Point Size',
                  offset: 'top',
                }}
              />
              <EyeToggle
                isOn={state.clouds[descriptor].visibilities[id]}
                onClick={() => {
                  dispatch({
                    type: 'setProcessedCloudVisibility',
                    value: !state.clouds[descriptor].visibilities[id],
                    id,
                    descriptor,
                  });
                }}
                tooltip={{
                  text: state.clouds[descriptor].visibilities[id]
                    ? 'Hide'
                    : 'Display',
                  offset: 'top',
                }}
              />
              {!['Fixed', 'CalRef'].includes(
                state.clouds[descriptor].display.colorMode,
              ) && (
                <ColorPresetPicker
                  initial={
                    palette[state.clouds[descriptor].display.colorMax[id]]
                  }
                  onChange={(color) => {
                    dispatch({
                      type: 'setProcessedCloudColor',
                      variant: 'colorMax',
                      id,
                      value: color,
                      descriptor,
                    });
                  }}
                  colors={palette}
                  side="left"
                />
              )}
              {state.clouds[descriptor].display.colorMode !== 'CalRef' && (
                <ColorPresetPicker
                  initial={
                    palette[state.clouds[descriptor].display.colorMin[id]]
                  }
                  onChange={(color) => {
                    dispatch({
                      type: 'setProcessedCloudColor',
                      variant: 'colorMin',
                      id,
                      value: color,
                      descriptor,
                    });
                  }}
                  colors={Palettes[state.app.palette]}
                  side="left"
                />
              )}
            </div>
          ))}
      </div>
    </PropertiesSubGroup>
  ) : null;
};
