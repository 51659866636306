/*******************************************************************
 **                                                               **
 **  Copyright(C) 2023 Ouster Inc. All Rights Reserved.           **
 **  Contact: https://ouster.io                                   **
 **                                                               **
 *******************************************************************/
import React, { useEffect, useRef } from 'react';
import { useProcessedCloudData } from '../processedCloud/useProcessedCloudData';
import { useProcessedCloudAppearance } from '../processedCloud/useProcessedCloudAppearance';
import { useSourceData } from '../source/useSourceData';
import { useSourceAppearance } from '../source/useSourceAppearance';
import { useTrackedObjectsSynch } from '../trackedObjects/useTrackedObjectsSynch';
import {
  Context,
  Section,
  SetupTabs,
  SetupTabsSet,
  ViewerTabs,
  ViewerTabsPlayback,
  ViewerTabsSet,
  ZoneType,
} from '../types';
import { usePerceptionSocket } from '../usePerceptionSocket';
import { useEventZoneSocket } from '../zone/eventZone/useZoneSocket';
import { usePointZoneSocket } from '../zone/pointZone/useZoneSocket';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { useCameraManipulation } from './hooks/useCameraManipulation';
import { useGlobalVisibilities } from './hooks/useGlobalVisibilities';
import { useShortcuts } from './hooks/useShortcuts';
import { useTrackedObjectsPointcloudSynch } from '../trackedObjects/useTrackedObjectsPointcloudSynch';
import { useCursorStyle } from './hooks/useCursorStyle';
import { useTrackedObjectsPreviousPositionsSynch } from '../trackedObjects/useTrackedObjectsPreviousPositionsSynch';
import { useAppState } from '../Stores';
import Settings from '../settingsEditor/Settings';
import { Viewport } from './components/viewport/Viewport';
import { Pane } from './components/pane/Pane';
import { PropertiesGroup } from './components/pane/PropertiesGroup';
import { ProcessedCloudList } from '../processedCloud/ProcessedCloudList';
import { TrackedObjectsList } from '../trackedObjects/TrackedObjectsList';
import { ZonesList } from '../zone/ZonesList';
import { useZonesSync } from '../zone/useZonesSync';
import { SourceProperties } from '../source/sourceDiscovery/SourceProperties';
import usePollSensors from '../source/sourceDiscovery/usePollSensors';
import usePollNodes from '../source/sourceDiscovery/usePollNodes';
import usePollAlerts from './hooks/usePollAlerts';
import usePollZoneAlerts from './hooks/usePollZoneAlerts';
import Diagnostics from '../diagnostics/Diagnostics';
import usePollTelemetry from './hooks/usePollTelemetry';
import { useAllowPitchAndRoll } from './hooks/useAllowPitchAndRoll';
import { usePreferences } from './hooks/usePreferences';
import useRangeRingSynch from '../coordinatePlanes/rangeRings/useRangeRingSynch';
import useFeedbackMessageQueue from './hooks/useFeedbackMessageQueue';
import useGetAbout from './hooks/useGetAbout';
import { useRingSynch } from '../trackedObjects/useRingSynch';
import { useRectsSynch } from '../trackedObjects/useRectsSynch';
import { useTrackedLabelsSynch } from '../trackedObjects/useTrackedLabelsSynch';
import { useCornersSynch } from '../trackedObjects/useCornersSynch';
import useEscapeSynch from './hooks/useEscapeSynch';
import { AUTH_PASSWORD, AUTH_USERNAME, USING_PROXIES } from '../constants';
import useServiceReachability from '../diagnostics/serviceReachability/useServiceReachability';
import { VisibilityButton } from './components/visibilities/VisibilityButton';
import { useTrackedObjectsSelect } from '../trackedObjects/useTrackedObjectsSelect';
import { useMeasure } from '../measuringTool/useMeasure';
import { useMisc } from './hooks/useMisc';
import useOrbitControlsSync from './hooks/useOrbitControlsSync';
import './Layout.css';
import { Tabs } from './components/pane/Tabs';
import { PreferenceProperties } from '../preferences/PreferenceProperties';
import AlignProperties from '../source/AlignProperties';
import TrackedProperties from '../trackedObjects/TrackedProperties';
import { ZoneProperties } from '../zone/ZoneProperties';
import RecordingProperties from '../record/RecordingProperties';
import { Image2D } from '../image2d/Image2D';
import { useTrackedObjectsDotsSynch } from '../trackedObjects/useTrackedObjectsDotsSynch';
import { LicenseProperties } from '../source/LicenseProperties';
import { useWorld } from './hooks/useWorld';
import { usePlaybackData } from '../playback/usePlaybackData';
import { Timeline } from '../playback/Timeline';
import { ImagePlayback } from '../playback/ImagePlayback';
import { useUnderlayMap } from '../map/useUnderlayMap';
import { MapProperties } from '../map/MapProperties';

function Layout({ context }: { context: Context }): JSX.Element | null {
  const state = useAppState();
  const viewportWrapperRef = useRef<HTMLDivElement>(null);

  // Global hooks
  useWorld(context);
  const wsDataReceivedSignal = useServiceReachability();
  useCameraManipulation(context);
  useShortcuts(context);
  useGlobalVisibilities(context);
  useCursorStyle(context);
  useMeasure(context);
  usePollTelemetry();
  useAllowPitchAndRoll(context);
  useRangeRingSynch(context);
  usePreferences(context);
  useOrbitControlsSync(context);
  useFeedbackMessageQueue();
  useGetAbout();
  useEscapeSynch();
  useMisc();
  useUnderlayMap(context);
  // Perception hooks
  usePerceptionSocket(wsDataReceivedSignal);
  useProcessedCloudData(context);
  useProcessedCloudAppearance(context);
  useTrackedObjectsSynch(context);
  useTrackedObjectsPointcloudSynch(context);
  useTrackedObjectsPreviousPositionsSynch(context);
  useTrackedObjectsSelect(context);
  useTrackedLabelsSynch(context);
  useRingSynch(context);
  useRectsSynch(context);
  useCornersSynch(context);
  useTrackedObjectsDotsSynch(context);
  // Alert hooks
  usePollAlerts();
  usePollZoneAlerts();
  // Source hooks
  usePollSensors();
  usePollNodes();
  useSourceData(context);
  useSourceAppearance(context);
  // Zone hooks
  useZonesSync(context);
  useEventZoneSocket(wsDataReceivedSignal);
  usePointZoneSocket();
  // Playback hooks
  usePlaybackData(context);

  useEffect(() => {
    console.log(
      'Perception App started in',
      process.env.REACT_APP_ENV,
      'mode.',
    );
    if (USING_PROXIES && (AUTH_PASSWORD == '' || AUTH_USERNAME == '')) {
      console.error(
        'Using proxies in test mode without authentication credentials!',
      );
    }
  }, []);

  const mode = state.app.mode;
  const isSettings = mode === 'settings';
  const isDiagnostics = mode === 'diagnostics';
  const isViewer = mode === 'viewer';
  const isZone = mode === 'zone';
  const isSetup = mode === 'setup';
  const isRecording = mode === 'recording';
  const isPreferences = mode === 'preferences';
  const isMap = mode === 'map';

  const isInputPlayback = state.app.inputMode === 'playback';

  const pointZones: ZoneType[] = ['Inclusion', 'Exclusion'];
  const eventZones: 'Event'[] = ['Event'];

  const tabs = (
    isInputPlayback
      ? ViewerTabsPlayback //If in playback mode always just show Playback viewer tabs
      : ViewerTabsSet.has(state.app.mode)
      ? ViewerTabs
      : SetupTabsSet.has(state.app.mode)
      ? SetupTabs
      : []
  ) as Section[];

  const isLeftPaneVisible =
    isViewer ||
    isRecording ||
    isPreferences ||
    isZone ||
    isMap ||
    (isInputPlayback && !isSetup);
  const isImage2DVis =
    isViewer || isRecording || isPreferences || isMap || isZone || isSetup;
  const isLayoutWithPanes = !(isSettings || isDiagnostics);
  // if in any setup state don't respect collapse flags, always show
  const showPanesOverride = SetupTabsSet.has(state.app.mode);
  const isLeftPaneOpen = showPanesOverride || state.app.isPaneExpanded.left;
  const isRightPaneOpen = showPanesOverride || state.app.isPaneExpanded.right;

  const recording = state.playback.playing;

  return (
    <div id="Layout">
      <Header />
      {isInputPlayback &&
        isViewer &&
        recording &&
        state.app.visibilities.Image && <ImagePlayback />}

      {/* Left Pane */}
      {isLayoutWithPanes && isLeftPaneOpen && (
        <Pane id="Pane-left">
          <PropertiesGroup name="Perception" visible={isLeftPaneVisible}>
            <div className="flex flex-row justify-end">
              <VisibilityButton
                cluster="Tracked"
                icon="rings"
                isActive={state.tracked.globalVisibilities.rings}
              />
              <VisibilityButton
                cluster="Tracked"
                icon="labels"
                isActive={state.tracked.globalVisibilities.labels}
              />
              <VisibilityButton
                cluster="Tracked"
                icon="cloud"
                isActive={state.tracked.globalVisibilities.cloud}
              />
              <VisibilityButton
                cluster="Tracked"
                icon="bbox"
                isActive={state.tracked.globalVisibilities.bbox}
              />
              <VisibilityButton
                cluster="Tracked"
                icon="corners"
                isActive={state.tracked.globalVisibilities.corners}
              />

              <VisibilityButton
                cluster="Tracked"
                icon="previousPositions"
                isActive={state.tracked.globalVisibilities.previousPositions}
              />
              {state.app.developerMode && (
                <VisibilityButton
                  cluster="Tracked"
                  icon="previousPositionsPath"
                  isActive={
                    state.tracked.globalVisibilities.previousPositionsPath
                  }
                />
              )}
            </div>

            <TrackedObjectsList
              label="Classifications"
              list={['Person', 'Bicycle', 'Vehicle', 'LargeVehicle', 'Unknown']}
            />
            {state.app.developerMode && (
              <TrackedObjectsList
                expandedInitValue={true}
                label="Intermediates"
                list={['Cluster', 'Prospect']}
              />
            )}
          </PropertiesGroup>

          <PropertiesGroup name="Zones" visible={isLeftPaneVisible}>
            <div className="flex flex-row justify-end">
              <VisibilityButton
                icon="boundaries"
                cluster="Zones"
                isActive={state.zones.globalVisibilities.boundaries}
              />
              <VisibilityButton
                icon="labels"
                cluster="Zones"
                isActive={state.zones.globalVisibilities.labels}
              />
            </div>
            <ZonesList label="Point Types" list={pointZones} />
            <ZonesList label="Event Types" list={eventZones} />
          </PropertiesGroup>

          <PropertiesGroup name="Clouds" visible={isLeftPaneVisible}>
            <div className="flex flex-row justify-end">
              <VisibilityButton
                cluster="GlobalVis"
                icon="Clouds"
                isActive={state.app.visibilities.Clouds}
              />
            </div>
            <ProcessedCloudList descriptor="Background" />
            <ProcessedCloudList descriptor="Foreground" />
            <ProcessedCloudList descriptor="Ground" />
          </PropertiesGroup>

          <PropertiesGroup
            name="Source Management"
            visible={isSetup}
            expandedInitValue={true}
          >
            <SourceProperties />
            <LicenseProperties />
          </PropertiesGroup>
        </Pane>
      )}

      {/* Content */}
      {(() => {
        switch (mode) {
          case 'diagnostics':
            return <Diagnostics />;
          case 'settings':
            return <Settings />;
          default:
            return (
              <div
                id="ViewportWrapper"
                ref={viewportWrapperRef}
                style={{
                  position: 'relative',
                  gridArea: 'content',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {isImage2DVis && <Image2D />}
                <Viewport
                  context={context}
                  viewportWrapper={viewportWrapperRef.current}
                />
              </div>
            );
        }
      })()}

      {/* Right Pane */}
      {isLayoutWithPanes && isRightPaneOpen && (
        <div id="Pane-right" className="flex flex-col">
          <Tabs disabled={state.setup.alignPhase !== 'none'} tabs={tabs} />
          <Pane>
            {(() => {
              switch (state.app.mode) {
                case 'viewer':
                  return <TrackedProperties />;
                case 'zone':
                  return <ZoneProperties context={context} />;
                case 'setup':
                  return <AlignProperties context={context} />;
                case 'recording':
                  return <RecordingProperties />;
                case 'preferences':
                  return <PreferenceProperties />;
                case 'map':
                  return <MapProperties />;
                default:
                  return null;
              }
            })()}
            {isInputPlayback && <TrackedProperties />}
          </Pane>
        </div>
      )}

      {isInputPlayback && isLayoutWithPanes && recording && (
        <Timeline recording={recording} />
      )}

      <Footer />
    </div>
  );
}

export default Layout;
